import { Spin } from "antd";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";

import { useAuth } from "../../../../contexts/useAuth.context";
import { getStudentGrades } from "../../../../services/student.service";
import { StudentQueryConstants } from "../../constants/studentQuery.constants";
import { studentGradeTableAdapter } from "../../helpers/student.helpers";
import { getGradesSchema, GradesTableHeaderSchema } from "../../tableSchemas/GradesSchema";
import { IStudentGradeTableData } from "../../types/studentGrade.types";
import EmptyDataComponent from "../EmptyData/EmptyDataComponent";
import { StudentTable } from "../Table/StudentTable";


function GradesRenderer() {
  const { user } = useAuth()

  const studentGradesQuery = useQuery(
    [StudentQueryConstants.STUDENT_GRADES],
    () =>
      getStudentGrades(user?.id as number),
    { enabled: Boolean(user) }
  );

  const studentGrades = studentGradesQuery.data;
  const studentGradesNormalized: IStudentGradeTableData[] = studentGradeTableAdapter(studentGrades)

  return (
    <Spin spinning={studentGradesQuery.isLoading}>
      {!isEmpty(studentGradesQuery.data?.exams) ?
        <StudentTable<IStudentGradeTableData>
          tableHeaderSchema={GradesTableHeaderSchema}
          renderSchema={getGradesSchema}
          dataSource={studentGradesNormalized}/>
        : <EmptyDataComponent dataTitle='grades'/>}
    </Spin>
  );
}

export default GradesRenderer;
