import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { FileUpload } from '../../../components/display/FileUpload/FileUpload';
import { Button, Input as FormInput } from '../../../components/general';
import { IFormInput } from '../../../components/general/Input/Input';
import { AttachmentLabelNotes } from '../../../constants/attachment.constants';
import { removeBase64Prefix, toBase64 } from '../../../helpers/base64.helper';
import { withCatch } from '../../../helpers/error.helpers';
import { formatFilename } from '../../../helpers/formatFilename.helper';
import { uploadApplicantAttachments } from '../../../services/applicant.service';
import {
  ApplicantAttachmentRequest,
  AttachmentTypeEnum,
} from '../../../types/attachment.types';
import { InvoiceFormSchema } from '../schemas/invoices.form.schema';

export const AttachmentUpload: FC<IFormInput & { labelNote: string }> = ({ name, labelNote, ...props }) => {
  const formik = useFormikContext();
  const [fileName, setFileName] = useState('');

  const listenReset = () => {
    // @ts-ignore
    if (!formik.values?.attachment) {
      setFileName('')
    }
  }

  // @ts-ignore
  useEffect(listenReset, [formik.values?.attachment])

  const handleUpload = async (file: FileList) => {
    const signatureBase64 = await toBase64(file[0]);
    const signature = removeBase64Prefix(signatureBase64);

    if (!signature) return;

    formik.setFieldValue(name!, signature);

    const newFileName = formatFilename(file[0]);

    setFileName(newFileName);
  };

  return (
    <FileUpload onUpload={handleUpload}>
      <FormInput labelNote={labelNote} value={fileName} readOnly {...props} />
    </FileUpload>
  );
};

const initialValues: Partial<ApplicantAttachmentRequest> = {
  attachment: undefined,
  name: '',
};

export const InvoicesForm = () => {
  const uploadMutation = useMutation(uploadApplicantAttachments);

  const handleAttachmentUpload = async (
    attachment: Partial<ApplicantAttachmentRequest>,
    { resetForm }: any
  ) => {
    const data = {
      applicantId: '',
      attachmentType: AttachmentTypeEnum.INVOICE,
      ...attachment
    }
    const mutationFn = () =>
      uploadMutation.mutateAsync(data as ApplicantAttachmentRequest);
    await withCatch(mutationFn, {
      onSuccess: () => {
        resetForm({ values: initialValues })
      }
    });
  };


  const isScreenLoading = uploadMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik<Partial<ApplicantAttachmentRequest>>
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validationSchema={InvoiceFormSchema}
        onSubmit={handleAttachmentUpload}
      >
        {(props) =>
          (
            <Form>
              <Field
                name="name"
                component={FormInput}
                label="Invoice Name"
                placeholder="e.g. first payment"
              />
              <ErrorMessage name="name" component="span" className="error"/>

              <AttachmentUpload
                name="attachment"
                label="Invoice Picture"
                labelNote={AttachmentLabelNotes.DEFAULT}
                placeholder="Click to select a file"
              />
              <ErrorMessage
                name="attachment"
                component="span"
                className="error"
              />

              <Button
                style={{ marginTop: '30px' }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Upload Invoice
              </Button>
            </Form>
          )
        }
      </Formik>
    </Spin>
  );
};
