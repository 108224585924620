import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Flex, Input } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { createTrack } from '../../services/track.service';
import { IAppForm } from '../../types/form.types';
import { TrackFormSchema } from './schemas/tracks.form.schema';

export const CreateTracksForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();
  const trackCreateMutation = useMutation(createTrack, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.TRACKS),
  });
  const handleCreateTrack = async ({ name }: typeof initialValues) => {
    await withCatch(() => trackCreateMutation.mutateAsync(name), {
      onSuccess: () => closeDrawer(),
    });
  };
  const initialValues = {
    name: '',
  };

  const isScreenLoading = trackCreateMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik initialValues={initialValues} validationSchema={TrackFormSchema} onSubmit={handleCreateTrack}>
        {(props) => (
          <Form
            name="create-course"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="name" label="Track Name" component={Input} />
            <ErrorMessage name="name" component="span" className="error" />
            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={props.handleReset}>
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Create Track
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
