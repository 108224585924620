import { Flex } from '../../general';

type Props = {
  style?: { [key: string]: string | number | undefined };
  children: React.ReactChild | React.ReactChild[];
};

export function Status({ style, children }: Props): JSX.Element {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{ minWidth: 150, padding: 7, borderRadius: 50, backgroundColor: '#F8F8F8', ...style }}
    >
      {children}
    </Flex>
  );
}
