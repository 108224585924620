import { useQuery } from 'react-query';

import Button from '../../../../components/general/Button/Button';
import Flex from '../../../../components/general/Flex/Flex';
import { NotificationBell } from '../../../../components/general/Icons/Icons';
import { GlobalNotificationOptions } from '../../../../constants/notification.constants';
import { UseQueryTypes } from '../../../../constants/useQuery.constants';
import { useNotification } from '../../../../contexts/notification.context';
import { getNotificationCount } from '../../../../services/notification.service';
import { getReminderCount } from '../../../../services/reminder.service';

export const Notification = () => {
  const { toggleNotification, isNotificationModalOpen } = useNotification();

  const handleNotificationBell = () => {
    toggleNotification();
  };

  const remindersCountFn = () =>
    getReminderCount({
      ...GlobalNotificationOptions.reminderFilterOptions,
      dateTo: new Date().toISOString(),
    });
  const notificationCountFn = () => getNotificationCount({
    ...GlobalNotificationOptions.notificationFilterOptions
  });

  const remindersCountQuery = useQuery(
    UseQueryTypes.REMINDER_COUNT,
    remindersCountFn,
  );

  const notificationsCountQuery = useQuery(
    UseQueryTypes.NOTIFICATION_COUNT,
    notificationCountFn,
  );

  const remindersCount = remindersCountQuery.data ?? 0;
  const notificationsCount = notificationsCountQuery.data ?? 0;
  const hasActiveReminders = remindersCount > 0;
  const hasActiveNotifications = notificationsCount > 0;

  const hasGlobalNotifications = hasActiveReminders || hasActiveNotifications;

  return (
    <Flex>
      <Button
        disabled={isNotificationModalOpen}
        onClick={handleNotificationBell}
        style={{ border: '0', padding: 0, width: '32px', height: '32px' }}
      >
        <NotificationBell hasNotification={hasGlobalNotifications}/>
      </Button>
    </Flex>
  )
}
