import { Modal, Table } from 'antd';
import React, { useCallback, useState } from 'react';

import { withCatch } from '../../../../helpers/error.helpers';
import { useTable } from '../../../../hooks/table/useTable';
import { applicationsColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { setApplicantActiveAccount } from '../../../../services/applicantDetail.service';
import { IApplicantDetailApplication } from '../../../../types/applicantDetail.types';

export function ApplicationsTable({ applications }: { applications: IApplicantDetailApplication[] }) {
  const table = useTable<IApplicantDetailApplication>(applicationsColumns);
  const [applicationId, setApplicationId] = useState<number | boolean>(false);

  const handleCLoseModal = () => {
    setApplicationId(false);
  }

  const handleChangeActiveAccount = async () => {
    await withCatch(() => setApplicantActiveAccount(applicationId as number), {
      onSuccess: () => {
        handleCLoseModal();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      onError() {
        handleCLoseModal();
      }
    });
  }

  const setApplicationToChange = useCallback((id: number, hasAccount: boolean) => {
      return () => !hasAccount && setApplicationId(id)
    }, []);

  return (
    <>
        <Table<IApplicantDetailApplication>
          {...table.getTableProps({
            rowKey: ({ id }: IApplicantDetailApplication) => id,
            dataSource: applications.map((item) => ({...item,  selectApplication: setApplicationToChange(item.id, item.hasAccount) })),
          })}
        />
        <Modal
          title="You are about to change the Student Account!"
          visible={!!applicationId}
          onOk={() => handleChangeActiveAccount()}
          okText="Confirm"
          onCancel={handleCLoseModal}
          width={440}
        >
        </Modal>
    </>
  );
}
