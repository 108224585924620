import { MailgunVariables } from '../constants/mailgun.constants';

export const upperFirstLetter = (string: string) => {
  const formattedText = string.replace('_', ' ');
  const firstLetter = formattedText.slice(0, 1).toUpperCase();
  const restPartOfString = formattedText.slice(1).toLocaleLowerCase();
  return firstLetter + restPartOfString;
};

export const isEmptyString = (value: string | number | null): boolean =>
  value !== '';

export const mailTemplateNormalizer = ({
  mailTemplate,
  templateVariables,
  values,
}: {
  mailTemplate: string;
  templateVariables: typeof MailgunVariables;
  values: { [key: string]: string };
}) => {
  const htmlParser = new DOMParser();
  const serializer = new XMLSerializer();
  const templateParsed = htmlParser.parseFromString(mailTemplate, 'text/html');

  Object.keys(templateVariables).forEach((variable) => {
    const domNode = templateParsed.getElementById(variable);

    if (domNode) {
      domNode.innerText = values[variable] ?? '';
    }
  });

  return serializer.serializeToString(templateParsed);
};


export const getFileTypeFromString = (fileString: string) => {
  const fileTypeRegex = /\.\w{3,4}($|\?)/

  return fileString.match(fileTypeRegex)?.[0]

}

export const urlRegex = /^(https?|ftp):\/\//;
export const sheetsLinkPattern = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9_-]+)\/.*/;
