import { Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  addApplicantComment,
  deleteApplicantCommentById,
  getApplicantComments,
  updateApplicantComment,
} from '../../services/applicant.service';
import { IComment } from '../../types/comment.types';
import type { IAppForm } from '../../types/form.types';
import { AddCommentForm } from '../shared/comment.form';

export const AddApplicantCommentForm: IAppForm = () => {
  const cache = useQueryClient();
  const { id: applicantId } = useEntityId();

  const applicantsCache: any = cache.getQueryData(UseQueryTypes.APPLICANTS);

  const applicantQuery = applicantsCache?.applicants?.find((ap: { id: number }) => ap.id === applicantId);

  const initialValues = { comment: '' };

  const commentsQuery = useQuery(UseQueryTypes.APPLICANT_COMMENTS, () => getApplicantComments(applicantId));

  const addApplicantCommentMutation = useMutation(addApplicantComment, {
    onSuccess: async () => {
      await cache.invalidateQueries(UseQueryTypes.APPLICANT_COMMENTS);
      await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
    },
  });

  const handleAddComment = async ({ comment }: typeof initialValues) => {
    const mutationFunc = () => {
      return addApplicantCommentMutation.mutateAsync({
        applicantId: Number(applicantQuery?.id || applicantId),
        comment: {
          content: comment,
        },
      });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        initialValues.comment = '';
        await cache.invalidateQueries(UseQueryTypes.APPLICANT_COMMENTS);
      },
    });
  };
  const applicantComments = (commentsQuery?.data || []).slice().reverse();

  const isScreenLoading = addApplicantCommentMutation.isLoading || commentsQuery.isLoading;

  const deleteApplicantCommentMutation = useMutation(deleteApplicantCommentById);
  const editApplicantCommentMutation = useMutation(updateApplicantComment);

  const removeComment = async (id: number) => {
    const mutationFunc = () => {
      return deleteApplicantCommentMutation.mutateAsync(id);
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.APPLICANT_COMMENTS);
        await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
      },
    });
  };

  const editComment = async (commentId: number, comment: IComment) => {
    const mutationFunc = () => {
      return editApplicantCommentMutation.mutateAsync({ commentId, comment });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.APPLICANT_COMMENTS);
        await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
      },
    });
  };

  return (
    <Spin spinning={isScreenLoading}>
      <AddCommentForm
        comments={applicantComments}
        initialValues={initialValues}
        onSubmit={handleAddComment}
        removeComment={removeComment}
        editComment={editComment}
        entity={{
          name: applicantQuery?.data?.name,
          email: applicantQuery?.data?.email,
        }}
      />
    </Spin>
  );
};
