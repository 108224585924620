import Flex from '../../../../components/general/Flex/Flex';

export function UserDescriptions({
  header,
  content,
  isMobile = false,
}: {
  header?: string;
  content?: string;
  isMobile?: boolean;
}) {
  const styleOfHeader = isMobile
    ? {
        fontSize: '16px',
        lineHeight: '18px',
      }
    : {
        fontSize: '18px',
        lineHeight: '26px',
      };

  return (
    <Flex direction="column">
      <p
        style={{
          margin: 0,
          fontFamily: 'Barlow',
          fontStyle: 'normal',
          fontWeight: '800',
          ...styleOfHeader,
        }}
      >
        {header}
      </p>

      {content && (
        <p
          style={{
            margin: 0,
            color: 'rgba(26, 26, 26, 0.4)',
            fontFamily: "'Barlow'",
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          {content}
        </p>
      )}
    </Flex>
  );
}
