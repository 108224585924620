import { resourceResponseToAdapter } from '../helpers/common.helpers';
import { stringifyObject } from '../helpers/stringifyObj.helper';
import { Sorters } from '../types/general.types';
import { ICreateOrUpdateReminder, IReminder, IReminderFilter, IRemindersResponse } from '../types/reminder.types';
import axios from './config.service';

export function createReminder(data: ICreateOrUpdateReminder) {
  return axios.post('/reminder', data).then((r) => r.data) as Promise<IReminder[]>;
}

export function filterReminders({ filters, sorters = {} }: { filters: IReminderFilter; sorters: Partial<Sorters> }) {
  const queryString = stringifyObject(sorters);

  return axios.post(`/reminder/filter?${queryString}`, filters).then((r) => r.data) as Promise<IRemindersResponse>;
}

export function deleteReminderById(id: number) {
  return axios.delete(`/reminder/${id}`).then((r) => r.data) as Promise<boolean>;
}

export function updateReminder(data: Partial<IReminder>) {
  const reminderWithoutId = resourceResponseToAdapter(data);

  return axios.put(`/reminder/${data.id}`, reminderWithoutId).then((r) => r.data) as Promise<IReminder[]>;
}

export async function bulkUpdateRemindersStatus(reminders: Partial<IReminder>[]) {
  const r = await axios.put(`/reminder/bulk`, reminders);
  return r.data as Promise<IReminder[]>;
}

export function getReminderCount(data: IReminderFilter) {
  return axios.post(`/reminder/count`, data).then((r) => r.data) as Promise<number>;
}
