import { Col, Row, Typography } from 'antd';

import { Button } from '../../../../../../components/general';
import { getButtonVariant } from '../../../../../../components/general/Button/Button';
import { historyTypes } from '../../../../../../constants/applicantDetail.constants';
import { ExcludedApplicationDetailTypes } from '../../../../../../constants/useQuery.constants';

const { Title } = Typography;

type IProps = {
  tables: { [key: string]: JSX.Element };
  state: [ExcludedApplicationDetailTypes, React.Dispatch<React.SetStateAction<ExcludedApplicationDetailTypes>>];
};

export const History = ({ tables, state: [activeDetailsTab, setActiveDetailsTab] }: IProps): JSX.Element => {
  return (
    <Row gutter={16} style={{ height: '100%', marginTop: '20px' }}>
      <Col span={24}>
        <Title style={{ marginBottom: 20 }} level={4}>
          History
        </Title>
        <Row style={{ marginBottom: 20 }}>
          {Object.entries(historyTypes).map(([type, value]) => (
            <Col key={type}>
              <Button
                variant={getButtonVariant<string>(activeDetailsTab, type)}
                onClick={() => {
                  setActiveDetailsTab(type as ExcludedApplicationDetailTypes);
                }}
                style={{ borderRadius: 0 }}
              >
                {value}
              </Button>
            </Col>
          ))}
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col span={20}>{tables[activeDetailsTab]}</Col>
        </Row>
      </Col>
    </Row>
  );
};
