import React, { useEffect } from 'react';

import { saveState } from "../../helpers/localStorage.helpers";
import LoginComponent from '../../modules/shared/pages/Login/Login';
import { AuthGroup } from "../../types/auth.types";

export default function Login() {
  const init = () => {
    saveState('authGroup', AuthGroup.USER);
  };

  useEffect(init, []);

  return (
    <LoginComponent authGroup={AuthGroup.USER}/>
  );
}
