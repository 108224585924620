import { CopyTwoTone, LinkedinFilled } from '@ant-design/icons';
import { Avatar, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { Flex, Icons } from '../../components/general';
import { InstructorRoles } from '../../constants/instructor.constants';
import {getAvatar} from "../../helpers/avatar.helper";
import { RoutesConstants } from '../../helpers/routes.helpers';
import { RenderColumn } from '../../types/column.types';
import { IInstructor, InstructorRoleEnum } from '../../types/instructor.types';

const { Text } = Typography;

export const NameApplicantColumn: RenderColumn<IInstructor> = (_, student) => {
  return (
    <div>
      <Text>{student.name}</Text> <br />
      <Text type="secondary">{student.email}</Text>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(student.email)}
        style={{ marginLeft: 8, fontSize: 16 }}
      />
    </div>
  );
};

export const NameInstructorColumn: RenderColumn<IInstructor> = (
  _,
  instructor,
) => (
  <>
    { instructor.role === InstructorRoleEnum.PRACTISE_LEAD &&
      <Tag style={{ padding: '0 3px', fontSize: '9px' }} color='success'>{InstructorRoles.PRACTISE_LEAD}</Tag>
    }
    <Flex justifyContent='flex-start' style={{ gap: '4px'}} flexWrap='nowrap'>
      {instructor.name}
      { instructor.linkedInProfile &&
        <a rel="noreferrer" target="_blank" href={instructor.linkedInProfile}>
          <LinkedinFilled
            style={{
              color: '#0097d3',
              marginLeft: 7,
              fontSize: 16,
              cursor: 'pointer',
            }}
          />
        </a>
      }
    </Flex>
  </>
);

export const DetailsInstructorColumn: RenderColumn<IInstructor> = (
  _,
  { id },
) => (
  <Link to={RoutesConstants.instructor(id)}>
    <Icons.ShowBottom style={{ transform: 'rotate(-90deg)' }} />
  </Link>
);

export const EmailInstructorColumn: RenderColumn<IInstructor> = (
  _,
  instructor,
) => {
  return (
    <>
      <span>{instructor.email}</span>
      <CopyTwoTone
        onClick={() => {
          navigator.clipboard.writeText(instructor.email);
        }}
        style={{ marginLeft: 8, fontSize: 16 }}
      />
    </>
  );
};

export const AvatarColumn: RenderColumn<IInstructor> = (_, user) => {
    return (
        <>
            <Avatar
                src={getAvatar(user?.avatar)}
                style={{ cursor: 'pointer', marginRight: 7 }}
            />
        </>
    );
};
