import { stringifyObject } from '../helpers/stringifyObj.helper';
import {
  CourseFilterModel,
  CourseFilterResponse,
  ICourse,
  ICourseLevelsCount,
  ICreateCourse,
  IUpdateCourse,
} from '../types/course.types';
import { Sorters } from '../types/general.types';
import axios from './config.service';

export function getCourses() {
  return axios.get('/course').then((r) => r.data) as Promise<ICourse[]>;
}

export function getCourseLevels() {
  return axios.get('course/levels/count').then((r) => r.data) as Promise<
    ICourseLevelsCount[]
  >;
}

export function createCourse(course: ICreateCourse) {
  return axios.post('/course', course).then((r) => r.data) as Promise<ICourse>;
}

export function getCourseById(id: number) {
  return axios.get(`/course/${id}`).then((r) => r.data) as Promise<ICourse>;
}

export function updateCourseById({
  id,
  course,
}: {
  course: IUpdateCourse;
  id: number;
}) {
  return axios
    .put(`/course/${id}`, course)
    .then((r) => r.data) as Promise<ICourse>;
}

export async function deleteCourseById(id: number) {
  const r = await axios.delete(`/course/${id}`);
  return r.data as Promise<boolean>;
}

export function filterCourses(
  filters: Partial<CourseFilterModel>,
  sorters: Partial<Sorters> = {},
) {
  const queryString = stringifyObject(sorters);
  return axios
    .post(`/course/filter?${queryString}`, filters)
    .then((r) => r.data) as Promise<CourseFilterResponse>;
}
