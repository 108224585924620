import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import {
  IBulkCreateStudentsFormData,
  StudentStatusEnum,
} from '../../../types/applicant.types';

const {required} = messages;

const isEmail = (value: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim());

export const BulkCreateStudentsFormSchema: Yup.SchemaOf<IBulkCreateStudentsFormData> =
  Yup.object().shape({
    bulkNames: Yup.string().required(required),
    bulkPhoneNumbers: Yup.string().required(required),
    status: Yup.mixed<StudentStatusEnum>()
      .oneOf(Object.values(StudentStatusEnum))
      .required(required),
    groupId: Yup.number(),
    courseId: Yup.number().required(required),
    bulkEmails: Yup.string()
      .transform((value) => Array.from(new Set(value.split('\n'))).join('\n')) // dedupe - optional step
      .required(required)
      .test(
        'emails',
        'One of them is invalid email',
        (value) => (value && value.split('\n').every(isEmail)) || false,
      ),
  });
