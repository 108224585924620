import { userTypes } from "../modules/shared/constants/forgotPasswordForm.constants";
import { userTypeKey } from "../modules/shared/types/forgetPasswordForm.types";
import { ApiSuccessMassage } from "../modules/shared/types/shared.types";
import axios from "./config.service";

export async function resetPasswordToken({email, authGroup}: {email: string, authGroup: userTypeKey}) {
  const r = await axios.post(`/auth/reset-password-token/${userTypes[authGroup]}`, {email});
  return r.data as Promise<{ token: string; exist: boolean }>;
}

export const checkResetPasswordToken = (token: string): Promise<{ data: any }> => {
  return axios.post('/auth/check-reset-password-token/', {token});
};

const signOut = async (): Promise<ApiSuccessMassage> => {
  return await axios.post(`/auth/signout/`);
};

export default signOut;
