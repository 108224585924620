import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { feedbackColumns } from '../../../../schemas/applicantDetail/schemas/feedbackColumns.schema';
import { IApplicantDetailFeedback } from '../../../../types/applicantDetail.types';

export function FeedbackTable({ feedbacks }: { feedbacks: IApplicantDetailFeedback[] }) {
  const table = useTable<IApplicantDetailFeedback>(feedbackColumns);

  return (
    <Table<IApplicantDetailFeedback>
      {...table.getTableProps({
        rowKey: ({ id }: IApplicantDetailFeedback) => id,
        dataSource: feedbacks,
      })}
    />
  );
}
