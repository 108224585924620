import { OrdinalNumbers } from '../../constants/ordinalNumbers.constatns';
import { AppFormsEnum } from '../../forms';
import type { ColumnSchema } from '../../types/column.types';
import type { IExtendedGroupStudents, IPayment } from '../../types/payment.types';
import {
  CreateApplicantPaymentColumn,
  PaymentGroupMonthsColumn,
  PaymentGroupMonthsTitle,
  StudentDataEditColumn,
  StudentNamePaymentsColumn,
  StudentPaymentsCommentColumn,
  StudentPaymentsDebtColumn,
  StudentPaymentsMethodColumn,
  StudentPaymentsTypeColumn,
  StudentPhonePaymentsColumn,
  StudentStatusPaymentsColumn,
} from './payments.renderer';

export const getDefaultApplicantPaymentColumns = (payments?: IPayment[]): ColumnSchema<IExtendedGroupStudents> => {
  return {
    uuid: 'defaultApplicantPaymentColumns',
    schema: {
      student: {
        title: 'Student',
        fixed: 'left',
        width: 300,
        sorter: () => -1,
        render: StudentNamePaymentsColumn,
      },
      phone: {
        title: 'Phone',
        width: 200,
        render: StudentPhonePaymentsColumn,
      },
      status: {
        title: 'Status',
        width: 200,
        render: StudentStatusPaymentsColumn,
      },
      method: {
        title: 'Method',
        width: 200,
        render: StudentPaymentsMethodColumn,
      },
      months: {
        title: 'Months',
        width: 200,
        children: payments?.map((payment, index) => {
          return {
            title: <PaymentGroupMonthsTitle index={index} payment={payment} form={AppFormsEnum.EditPaymentDateForm} />,
            width: 200,
            dataIndex: OrdinalNumbers[index],
            render: (_, paymentContent) => (
              <PaymentGroupMonthsColumn paymentContent={paymentContent} payment={payment} />
            ),
          };
        }),
      },
      comment: {
        title: 'Comment',
        width: 200,
        render: StudentPaymentsCommentColumn,
      },
      edit: {
        title: 'Edit',
        width: 100,
        render: StudentDataEditColumn,
      },
    },
  };
};

export const getBootcampApplicantPaymentColumns = (payments?: IPayment[]): ColumnSchema<IExtendedGroupStudents> => {
  return {
    uuid: 'bootcampApplicantPaymentColumns',
    schema: {
      student: {
        title: 'Student',
        fixed: 'left',
        width: 300,
        sorter: () => -1,
        render: StudentNamePaymentsColumn,
      },
      phone: {
        title: 'Phone',
        width: 150,
        render: StudentPhonePaymentsColumn,
      },
      method: {
        title: 'Method',
        width: 150,
        render: () => <StudentPaymentsTypeColumn paymentType={payments?.[0]?.paymentType || ''} />,
      },
      dept: {
        title: 'Dept',
        width: 100,
        render: () => <StudentPaymentsDebtColumn student={payments?.[0] || null} />,
      },
      months: {
        title: 'Months',
        width: 200,
        children: payments?.map((payment, index) => {
          return {
            title: (
              <PaymentGroupMonthsTitle
                index={index}
                payment={payment}
                form={AppFormsEnum.EditApplicantPaymentDateForm}
              />
            ),
            width: 200,
            dataIndex: OrdinalNumbers[index],
            render: (_, paymentContent) => (
              <PaymentGroupMonthsColumn paymentContent={paymentContent} payment={payment} />
            ),
          };
        }),
      },
      createApplicantPayment: {
        title: 'Payment',
        width: 200,
        render: () => <CreateApplicantPaymentColumn student={payments?.[0] || null} />,
      },
      comment: {
        title: 'Comment',
        width: 200,
        render: StudentPaymentsCommentColumn,
      },
      edit: {
        title: 'Edit',
        width: 100,
        render: StudentDataEditColumn,
      },
    },
  };
};
