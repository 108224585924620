import { DateService } from '../services/date.service';
import { DayOfWeekEnum, DayOfWeeks } from '../types/general.types';

export const statisticsDate = {
  today: DateService.getServerDate(),
  tomorrow: DateService.getOtherDate(1),
  startOfTheWeek: DateService.getStartOfWeekDate(),
  endOfTheWeek: DateService.getEndOfWeekDate(),
  startOfTheMonth: DateService.getStartOfMonthDate(),
  endOfTheMonth: DateService.getEndOfMonthDate(),
} as const;

export const DayOfWeekOrder = {
  [DayOfWeekEnum.MONDAY]: 0,
  [DayOfWeekEnum.TUESDAY]: 1,
  [DayOfWeekEnum.WEDNESDAY]: 2,
  [DayOfWeekEnum.FRIDAY]: 3,
  [DayOfWeekEnum.THURSDAY]: 4,
  [DayOfWeekEnum.SATURDAY]: 5,
  [DayOfWeekEnum.SUNDAY]: 6,
};

export const DayOfWeekOptions = {
  [DayOfWeekEnum.MONDAY]: {
    label: 'Monday',
    value: DayOfWeekEnum.MONDAY,
  },
  [DayOfWeekEnum.TUESDAY]: {
    label: 'Tuesday',
    value: DayOfWeekEnum.TUESDAY,
  },
  [DayOfWeekEnum.WEDNESDAY]: {
    label: 'Wednesday',
    value: DayOfWeekEnum.WEDNESDAY,
  },
  [DayOfWeekEnum.THURSDAY]: {
    label: 'Thursday',
    value: DayOfWeekEnum.THURSDAY,
  },
  [DayOfWeekEnum.FRIDAY]: {
    label: 'Friday',
    value: DayOfWeekEnum.FRIDAY,
  },
  [DayOfWeekEnum.SATURDAY]: {
    label: 'Saturday',
    value: DayOfWeekEnum.SATURDAY,
  },
  [DayOfWeekEnum.SUNDAY]: {
    label: 'Sunday',
    value: DayOfWeekEnum.SUNDAY,
  },
} as const;

export const DayOfWeeksAm: { [key in DayOfWeeks]: string } = {
  SUNDAY: 'ԿԻՐԱԿԻ',
  MONDAY: 'ԵՐԿՈՒՇԱԲԹԻ',
  TUESDAY: 'ԵՐԵՔՇԱԲԹԻ',
  WEDNESDAY: 'ՉՈՐԵՔՇԱԲԹԻ',
  THURSDAY: 'ՀԻՆԳՇԱԲԹԻ',
  FRIDAY: 'ՈՒՐԲԱԹ',
  SATURDAY: 'ՇԱԲԱԹ',
};
