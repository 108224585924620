import { sorterByStartDate } from "../../helpers/sorting.helpers";
import type { ColumnSchema } from '../../types/column.types';
import type { IGroup } from '../../types/group.types';
import { formatGroup } from '../applicants/applicant.renderer';
import {
  AvatarGroupColumn,
  CourseFormatColumn,
  DetailGroupColumn,
  EditGroupColumn,
  GroupDescriptionColumn,
  LessonGroupColumn,
  ProgressGroupColumn,
  SlackGroupColumn,
  StartDateGroupColumn,
  TutorGroupColumn,
} from './group.renderer';

export const defaultOngoingGroupColumns: ColumnSchema<IGroup> = {
  uuid: 'defaultOngoingGroupColumns',
  schema: {
    projectCoordinator: {
      dataIndex: 'projectCoordinator',
      title: 'Project Coordinator',
      render: (_, group, idx) => AvatarGroupColumn!(null, group, idx),
      width: 200,
    },
    size: {
      title: 'size',
      dataIndex: 'size',
      render: (_, group) => group.size || 'N/A',
    },
    id: {
      title: 'Group',
      dataIndex: 'number',
      render: (_, group) => formatGroup(group),
    },
    tutor: {
      title: 'Tutors',
      dataIndex: 'tutor',
      render: TutorGroupColumn,
    },
    courseFormat: {
      title: 'Course Format',
      dataIndex: 'courseFormat',
      render: CourseFormatColumn,
    },
    startDate: {
      title: 'Start',
      dataIndex: 'startDate',
      render: StartDateGroupColumn,
      sorter: sorterByStartDate,
    },
    description: {
      title: 'Description',
      dataIndex: 'description',
      render: GroupDescriptionColumn,
    },
    completenessPercentage: {
      title: 'Progress',
      dataIndex: 'completenessPercentage',
      render: ProgressGroupColumn,
    },
    lesson: {
      title: 'Lesson',
      dataIndex: 'lesson',
      render: LessonGroupColumn,
    },
    slackLink: {
      title: 'Slack',
      dataIndex: 'slackLink',
      align: 'center',
      width: 30,
      render: SlackGroupColumn,
    },
    edit: {
      title: 'Edit',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: EditGroupColumn,
    },
    details: {
      title: 'Details',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: (_, group) => DetailGroupColumn(_, group),
    },
  },
};
