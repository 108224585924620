import React, { useEffect } from 'react';

import { saveState } from "../../../helpers/localStorage.helpers";
import { AuthGroup } from "../../../types/auth.types";
import LoginComponent from '../../shared/pages/Login/Login';

export default function Login() {
  const init = () => {
    saveState('authGroup', AuthGroup.STUDENT);
  };

  useEffect(init, []);

  return (
    <LoginComponent authGroup={AuthGroup.STUDENT}/>
  );
}

