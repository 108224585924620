import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';

const { required } = messages;

export const MailingFormSchema = Yup.object().shape(
  {
    to: Yup.string().required(required),
    template: Yup.string().when('html', {
      is: (html: string) => !html || html.length === 0,
      then: Yup.string().required(required),
      otherwise: Yup.string(),
    }),
    subject: Yup.string().required(required),
    tags: Yup.array().of(Yup.string().required(required)),
    email: Yup.string().email('').required(required),
    fullName: Yup.string().required(required),
    html: Yup.string().when('template', {
      is: (template: string) => !template || template.length === 0,
      then: Yup.string().required(required),
      otherwise: Yup.string(),
    }),
  },
  [['template', 'html']],
);
