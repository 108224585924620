import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Button, Flex, Input, SingleSelectFormik } from '../../components/general';
import { AttachmentLabelNotes, AttachmentTypeOptions } from '../../constants/attachment.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { createOptions } from '../../helpers/createOptions.helper';
import { getFormattedDate } from '../../helpers/date.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { StudentQueryConstants } from '../../modules/student/constants/studentQuery.constants';
import { CreateStudentAttachmentFormSchema } from '../../modules/student/forms/schemas/createStudentAttachment.schema';
import { createStudentAttachment, getStudentPayments } from '../../services/student.service';
import { AttachmentTypeEnum } from '../../types/attachment.types';
import { IGroup } from '../../types/group.types';
import { AttachmentUpload } from '../applicantForms/public/Invoices.form';

const initialValues = {
  name: '',
  attachmentType: AttachmentTypeEnum.OTHER,
  attachment: '',
};

export const CreateOtherAttachmentForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();
  const { id } = useEntityId();

  const studentAttachment = useMutation(createStudentAttachment, {
    onSuccess: () => {
      cache.invalidateQueries(StudentQueryConstants.STUDENT_ATTACHMENTS);
      cache.invalidateQueries(UseQueryTypes.APPLICANTS);
    },
  });

  const { data: groupData } = useQuery<IGroup>(UseQueryTypes.GROUP_BY_ID);

  const { data: studentPaymentsData } = useQuery([StudentQueryConstants.STUDENT_PAYMENTS], () =>
    getStudentPayments(id),
  );

  const filteredPaymentByGroup = studentPaymentsData?.payments.filter(
    ({ payment }) => payment?.group?.id === groupData?.id,
  );

  const StudentPaymentsOptions = createOptions(filteredPaymentByGroup, 'date', 'id', getFormattedDate);

  const handleAddAttachment = async (newAttachment: {
    name: string;
    attachmentType: AttachmentTypeEnum;
    attachment: string;
  }) => {
    if (id) {
      const newAttachmentWithUserId = {
        ...newAttachment,
        userId: id,
      };

      const mutationFunc = () => studentAttachment.mutateAsync(newAttachmentWithUserId);

      await withCatch(mutationFunc, {
        onSuccess: () => {
          closeDrawer();
        },
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={CreateStudentAttachmentFormSchema}
      onSubmit={handleAddAttachment}
    >
      {(props) => {
        return (
          <Form
            name="create-payment"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="name" label="File name" component={Input} required />
            <ErrorMessage name="name" component="span" className="error" />

            <Field
              name="attachmentType"
              label="Attachment Type"
              component={SingleSelectFormik}
              options={AttachmentTypeOptions}
              required
            />
            <ErrorMessage name="attachmentType" component="span" className="error" />

            {props.values.attachmentType === AttachmentTypeOptions.INVOICE.value && (
              <>
                <Field
                  name="applicantPaymentId"
                  label="Payment"
                  component={SingleSelectFormik}
                  options={StudentPaymentsOptions}
                  required
                />
                <ErrorMessage name="applicantPaymentId" component="span" className="error" />
              </>
            )}

            <AttachmentUpload name="attachment" labelNote={AttachmentLabelNotes.DEFAULT} label="Add File" placeholder="Click to select a file" required />
            <ErrorMessage name="attachment" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                Reset
              </Button>

              <Spin spinning={studentAttachment.isLoading}>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Apply
                </Button>
              </Spin>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};
