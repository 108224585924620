import { Button } from '../../../../../components/general';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { useEntityId } from '../../../../../hooks/useFilter.hooks';
import { ButtonTypeEnum } from '../../../../../types/button.types';
import { DrawerTypes } from '../../../../../types/drawer.types';
import styles from '../../Agreements/AgreementsRenderer/AgreementsRenderer.module.css';
import { AddAttachmentProps } from './AddAttachment.types';
import { initialAttachmentParams } from './constants';

export function AddAttachment(
  {
    attachmentId,
    formName,
    btnTitle=initialAttachmentParams.btnTitle,
    formTitle=initialAttachmentParams.formTitle
  }: AddAttachmentProps) {
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();

  const handleAddAttachment = () => {
    attachmentId && changeId(attachmentId);
    openDrawer(formName, formTitle, DrawerTypes.Form);
  };

  return (
    <div className={styles.agreementButtonWrapper}>
      <Button onClick={handleAddAttachment} variant={ButtonTypeEnum.primary} className={styles.agreementButton}>
        + {btnTitle}
      </Button>
    </div>
  );
}
