import { Outlet, useLocation } from 'react-router-dom';

import { RoutesConstants } from '../../../helpers/routes.helpers';
import { getNormalizedTitle } from '../../../helpers/title.helpers';
import Dashboard from '../../../pages/Dashboard/Dashboard';
import SharedLayout from '../../shared/components/Layout/Layout';

export default function Home() {
  const { pathname } = useLocation();
  const title = getNormalizedTitle(pathname);

  const isPathNameHome = pathname === RoutesConstants.home();

  return (
    <SharedLayout title={title}>
      <>
        <Outlet/>
        {isPathNameHome && <Dashboard/>}
      </>
    </SharedLayout>
  );
}

