export const mailGunTemplateFilter = (
  templates: any[] | undefined,
  templatesToKeep: any[],
) => {
  if (!templates) {
    return [];
  }

  return templates.filter((template) =>
    templatesToKeep.includes(template.name),
  );
};
