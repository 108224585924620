import { ApplicantDropOutMonths, StudentStatusEnum } from './applicant.types';
import { IComment } from './comment.types';
import { Currency } from './currency.types';
import { IExam } from './exam.types';
import { IGroup } from './group.types';
import { ILesson } from './lesson.types';

export enum PaymentEnum {
  PREPAYMENT = 'PREPAYMENT',
  POSTPAYMENT = 'POSTPAYMENT',
}

interface IINVOICE {
  name: string;
  link: string;
}

export interface IPayment {
  amount: number;
  description: string;
  currencyClientType: Currency;
  date: string;
  id: number;
  currency: string;
  applicant: number;
  isPaid: boolean;
  debt?: number;
  paymentType?: string;
  invoice: IINVOICE;
  payment?: { id: number; currency?: string };
  group?: IGroup;
}

export interface ICreatePayment {
  amount: number;
  currency: Currency;
  date: string;
  description: string;
  groupId: number;
}

export interface ICreateApplicantPayment {
  applicantId: number;
  amount: number;
  date: string;
}

export interface IEditPaymentDate {
  applicantId: number;
  date: string;
}

export interface IUpdatePayment extends ICreatePayment {
  id: number;
}

export interface IUpdatePaymentType extends ICreatePayment {
  id: number;
  paymentType: PaymentEnum;
  debt: number;
}

export interface IGroupExamDetails {
  examId: number;
  grade: number;
  id: number;
}

export interface IGroupStudents {
  phoneNumber: string;
  applicantPaymentDetailResponseModels: IPayment[];
  amount: string | number;
  currency: string;
  email: string;
  id: number;
  name: string;
  group: IGroup;
  comments: IComment[];
  status: StudentStatusEnum;
  dropoutMonth?: ApplicantDropOutMonths;
}

export interface IExtendedGroupStudents extends IGroupStudents {
  phoneNumber: string;
  comment: IComment;
  amount: number;
  currency: string;
  method: string;
  comments: IComment[];
  debt?: string;
}

export interface IGroupDetailPayment {
  exams: IExam[];
  groupId: number;
  lessons: ILesson[];
  students: IGroupStudents[];
}

export interface IUpdateGroupPayment {
  id?: number;
  date?: string;
  groupId?: number;
  amount?: number;
  description?: string;
  currency?: string;
  applicantId: number;
  isPaid?: boolean;
  status?: string;
}
