import { ApiSuccessMassage } from '../modules/shared/types/shared.types';
import { IStudentAbsenteesResponse } from '../modules/student/types/studentAbsentees.types';
import {
  IAgreementGenerateRequestWithUserId,
  IAgreementPreviewRequest,
} from '../modules/student/types/studentAgreement.types';
import {
  IStudentAttachment,
  IStudentAttachmentRequest,
  IStudentAttachmentResponse,
  IStudentAttachmentUpdateRequest,
} from '../modules/student/types/studentAttachment.types';
import { StudentFeedbackResponse, StudentGivenFeedbackResponse } from '../modules/student/types/studentFeedback.types';
import { StudentGradeResponse } from '../modules/student/types/studentGrade.types';
import { IStudentPaymentsResponse } from '../modules/student/types/studentPayments.types';
import { IStudent } from '../types/applicant.types';
import { AttachmentTypeEnum } from '../types/attachment.types';
import { ResetPassword } from '../types/auth.types';
import { IStudentFeedback } from '../types/feedback.types';
import { AuthResponse, ICreatePasswordPayload, IUserLogin } from '../types/user.types';
import axios from './config.service';

export const login = async (applicant: IUserLogin) => {
  const { data } = await axios.post<AuthResponse>('/auth/signin/student/', applicant);
  axios.defaults.headers.common = {
    Authorization: `Bearer ${data.accessToken}`,
  };

  return data;
};

const signOut = async (): Promise<ApiSuccessMassage> => {
  return await axios.post(`/auth/signout/student/`);
};

export async function createPassword(data: ICreatePasswordPayload) {
  const r = await axios.put(`/student/${data.id}/create-password/`, data.payload);
  return r.data as Promise<IStudent>;
}

export async function createPasswordToken(ids: number[]) {
  const r = await axios.post('/student/password-token/', { ids });
  return r.data as Promise<{ [key: number]: string }>;
}

export async function checkCreatePassword(data: { token: string; id: string }) {
  const r = await axios.post('/student/check-create-password/', data);
  return r.data as Promise<IStudent>;
}

export const getCurrentUser = (): Promise<{ data: any }> => {
  return axios.get('/student/me');
};

export const createStudentFeedback = async ({ studentId, ...feedbackData }: IStudentFeedback) => {
  const r = await axios.post(`/student/${studentId}/feedback`, feedbackData);
  return r.data as Promise<IStudentFeedback[]>;
};

export const getStudentFeedbacks = async (userId: number) => {
  const r = await axios.get(`/student/${userId}/feedbacks`);
  return r.data as Promise<StudentGivenFeedbackResponse>;
};

export const getStudentFeedbacksByGroup = async (groupId: number) => {
  const r = await axios.get(`/student/feedbacks/${groupId}`);
  return r.data as Promise<StudentGivenFeedbackResponse>;
};

export const getStudentReceivedFeedbacks = async (userId: number) => {
  const r = await axios.get(`/student/${userId}/receivedFeedbacks`);
  return r.data as Promise<StudentFeedbackResponse>;
};

export const getStudentGrades = async (userId: number) => {
  const r = await axios.get(`/student/${userId}/grade`);
  return r.data as Promise<StudentGradeResponse>;
};

export const getStudentAbsentees = async (userId: number) => {
  const r = await axios.get(`/student/${userId}/absentees`);
  return r.data as Promise<IStudentAbsenteesResponse>;
};

export const getStudentPayments = async (userId: number) => {
  const r = await axios.get(`/student/${userId}/payments`);
  return r.data as Promise<IStudentPaymentsResponse>;
};

export const resetPassword = (data: ResetPassword): Promise<{ data: any }> => {
  return axios.put('/student/reset-password/', data);
};

export const createStudentAttachment = async (attachmentData: IStudentAttachmentRequest) => {
  const { userId, ...attachmentRequestData } = attachmentData;

  const r = await axios.post(`/student/${userId}/attachment/`, attachmentRequestData);
  return r.data as Promise<any>;
};

export const getStudentAgreement = async (userId: number) => {
  const r = await axios.get(`/student/${userId}/agreement/`);
  return r.data as Promise<{ attachments: IStudentAttachment[] }>;
};

export const generateStudentAgreement = async (agreementData: IAgreementGenerateRequestWithUserId) => {
  const { userId, ...agreementRequestData } = agreementData;

  const r = await axios.post(`/student/${userId}/generate/agreement`, agreementRequestData);
  return r.data as Promise<any>;
};

export const previewStudentAgreement = async (agreementData: IAgreementPreviewRequest) => {
  const { userId, ...agreementRequestData } = agreementData;

  const r = await axios.post(`/student/${userId}/preview/agreement`, agreementRequestData);
  return r.data as Promise<{ agreementBuff: Buffer }>;
};

export const getStudentAttachments = async (userId: number, attachmentType?: AttachmentTypeEnum) => {
  const r = await axios.get(`/student/${userId}/attachment/?attachmentType=${attachmentType}`);
  return r.data as Promise<IStudentAttachmentResponse>;
};

export const updateStudentAttachment = async (updateParams: IStudentAttachmentUpdateRequest) => {
  const { attachmentId, ...updatedAttachment } = updateParams;
  const r = await axios.put(`/student/attachment/${attachmentId}`, updatedAttachment);
  return r.data as Promise<IStudentAttachment>;
};

export default signOut;
