import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  getCourseById,
  updateCourseById,
} from '../../services/courses.service';
import { ICreateCourse } from '../../types/course.types';
import { IAppForm } from '../../types/form.types';
import { TransformCourseForm } from './shared/transformCourse.form';

export const EditCourseForm: IAppForm = () => {
  const cache = useQueryClient();
  const { id: editId } = useEntityId();
  const { closeDrawer } = useDrawer();

  const courseByIdQuery = useQuery(UseQueryTypes.COURSE, () =>
    getCourseById(editId),
  );

  const updateCourseMutation = useMutation(updateCourseById, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.FILTER_COURSES),
  });

  const handleEditCourse = async (course: ICreateCourse) => {
    const mutationFunc = () => {
      return updateCourseMutation.mutateAsync({
        course,
        id: editId,
      });
    };
    await withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const initialValues = useMemo(() => {
    const course = courseByIdQuery.data;
    if (!course) return {};
    const { id, uuid, track, defaultProjectCoordinator, ...rest } = course;

    return {
      ...rest,
      defaultProjectCoordinatorId: course.defaultProjectCoordinator.id,
      trackId: course.track.id,
    };
  }, [courseByIdQuery.data]);

  const isScreenLoading =
    updateCourseMutation.isLoading || courseByIdQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <TransformCourseForm
        submitText="Edit Course"
        initialValues={initialValues}
        onSubmit={handleEditCourse}
      />
    </Spin>
  );
};
