import { Citizenship } from '../modules/student/types/studentAgreement.types';
import { IStudentAttachment } from "../modules/student/types/studentAttachment.types";
import { ApplicantAttachmentResponse } from './attachment.types';
import { IComment } from './comment.types';
import { ICourse } from './course.types';
import { ExamType, IAutomatedExam, IExam } from './exam.types';
import { IFeedback } from './feedback.types';
import { Branch, FilterOptions, FilterResponse } from './general.types';
import { IGroup } from './group.types';
import { ILeadGen } from './leadGen.types';
import { IUser } from './user.types';

export enum StudentStatusEnum {
  APPLIED_ONLY = 'APPLIED_ONLY',
  ENROLLED = 'ENROLLED',
  EXAM_FAILED = 'EXAM_FAILED',
  EXAM_PASSED = 'EXAM_PASSED',
  EXAM_SCHEDULED = 'EXAM_SCHEDULED',
  EXAM_WAITLIST = 'EXAM_WAITLIST',
  ON_HOLD = 'ON_HOLD',
  REDIRECTED = 'REDIRECTED',
  RESCHEDULED = 'RESCHEDULED',
  INTERVIEW_FAILED = 'INTERVIEW_FAILED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_ABSENT = 'INTERVIEW_ABSENT',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_WAITLIST = 'INTERVIEW_WAITLIST',
  CONSIDER_FOR_NEXT_COURSE = 'CONSIDER_FOR_NEXT_COURSE',
  INVITE_NEXT_COURSE = 'INVITE_NEXT_COURSE',
  NO_ANSWER = 'NO_ANSWER',
  E_MAIL_SENT = 'E_MAIL_SENT',
  DROP_OUT = 'DROP_OUT',
  DROP_OUT_AFTER_ENROLLMENT = 'DROP_OUT_AFTER_ENROLLMENT',
  GRADUATED = 'GRADUATED',
  BLACKLIST = 'BLACKLIST',
  RESCHEDULE_THE_EXAM = 'RESCHEDULE_THE_EXAM',
  INTRO_GRADUATED = 'INTRO_GRADUATED',
  EXAM_PASSED_INFORMED = 'EXAM_PASSED_INFORMED',
  EXAM_FAILED_INFORMED = 'EXAM_FAILED_INFORMED',
  INTERVIEW_PASSED_INFORMED = 'INTERVIEW_PASSED_INFORMED',
  INTERVIEW_FAILED_INFORMED = 'INTERVIEW_FAILED_INFORMED'
}

export enum ApplicantDropOutMonths {
  FIRST_MONTH = 'FIRST_MONTH',
  SECOND_MONTH = 'SECOND_MONTH',
  THIRD_MONTH = 'THIRD_MONTH',
  FORTH_MONTH = 'FORTH_MONTH',
  FIFTH_MONTH = 'FIFTH_MONTH',
  SIXTH_MONTH = 'SIXTH_MONTH',
  SEVENTH_MONTH = 'SEVENTH_MONTH',
  EIGHTH_MONTH = 'EIGHTH_MONTH',
}

export enum RegistrationSource {
  MANUAL = 'MANUAL',
  TILDA = 'TILDA',
  WEBSITE = 'WEBSITE',
  IMPORTED = 'IMPORTED',
}

export enum StudentAddToVariants {
  GROUP = 'GROUP',
  COURSE = 'COURSE',
}

export interface ICreateStudentFormData extends ICreateStudent {
  addTo: StudentAddToVariants;
}

export type StudentStatus = keyof typeof StudentStatusEnum;

export enum PaymentMethod {
  DEFAULT = 'DEFAULT',
  DONATION = 'DONATION',
  AMERIA_CREDIT = 'AMERIA_CREDIT',
}

export enum PaymentDiscount {
  ZERO_PERCENT = 0,
  TEN_PERCENT = 10,
  TWENTY_PERCENT = 20,
  THIRTY_PERCENT = 30,
  FORTY_PERCENT = 40,
  FIFTY_PERCENT = 50,
  SIXTY_PERCENT = 60,
  SEVENTY_PERCENT = 70,
  EIGHTY_PERCENT = 80,
  NINETY_PERCENT = 90,
  HUNDRED_PERCENT = 100,
}

export interface IStudent {
  isOpenToWork: boolean;
  isCertificateGiven: boolean;
  comments: IComment[];
  commentCount: number;
  feedbacks: IFeedback[];
  feedbacksCount: number;
  created_at: string;
  discount: number;
  email: string;
  group: IGroup;
  groupStudent: { id: number; status: StudentStatus; updated_at: string };
  course: ICourse;
  id: number;
  cv?: Pick<IStudentAttachment, 'id' | 'name' | 'link'>
  name: string;
  nameHy: string;
  nameEn: string;
  phoneNumber: string;
  status: StudentStatus;
  applicantExams: IStudentExams[];
  totalAbsentees: number;
  registrationSource: RegistrationSource;
  isRegistrationMailSent: boolean;
  attachmentResponseModels: ApplicantAttachmentResponse[];
  uuid: string;
  scheduleDate: string;
  scheduleTime: string;
  scheduledDate: string | any;
  createdBy: IUser;
  leadGen: ILeadGen;
  studentGroupId?: number;
  admissionExam: IAutomatedExam;
  paymentMethod: PaymentMethod;
}

export interface IStudentBrief extends IStudent {
  applicantExams: IStudentExams[];

  applicantActions: IApplicantActions[];
  hasDebt: boolean;
  finalScore: number;
  attachmentsCount: number;
  introGraduated?: boolean;
}

export interface IStudentExtended extends IStudentBrief {
  passwordToken: string;
}

export interface IUpdateStudent {
  isOpenToWork: boolean;
  isCertificateGiven: boolean;
  groupId: number | null;
  courseId?: number | null;
  discount: number;
  oneTimeDiscount: number;
  email: string;
  name: string;
  id: number;
  nameHy: string;
  nameEn: string;
  registrationSource: RegistrationSource;
  isRegistrationMailSent: boolean;
  phoneNumber: string;
  status?: StudentStatus | null;
  scheduleDate: string;
  scheduleTime: string;
  scheduledDate: string | any;
  paymentMethod: PaymentMethod;
  contactLater: boolean;
}

// @TODO courseId , groupId - make one of the fields required
export interface ICreateStudent {
  email: string;
  name: string;
  nameHy?: string;
  nameEn?: string;
  registrationSource: RegistrationSource;
  phoneNumber: string;
  status?: StudentStatus | null;
  scheduledDate?: string;
  courseId?: number | null;
  groupId?: number | null;
  createdBy?: number;
}

export interface IBulkUpdateStudentsFormData {
  status?: StudentStatus | null | undefined;
  courseId?: number | null;
  groupId?: number | null;
  scheduledDate?: string | null;
}

export interface IBulkCreateStudentsFormData {
  bulkNames: string;
  bulkEmails: string;
  bulkPhoneNumbers: string;
  status: StudentStatus;
  courseId: number;
  groupId?: number;
}

export interface ICreateStudentFormData extends Omit<ICreateStudent, 'courseUuid'> {
  groupId?: number | null;
}

export interface IStudentWithAbsentees {
  id: number;
  name: string;
  email: string;
  totalAbsentees: number;
  absentees: IStudentAbsentees[];
}

export interface IStudentWithExams {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  applicantExams: IStudentExams[];
}

export interface IStudentExams {
  examId: number;
  id: number;
  grade: number | undefined;
}

export interface IApplicantActions {
  updated_at: string | boolean;
  id: number;
  created_at: string;
}

export interface IStudentAbsentees {
  lessonId: number;
  absent: boolean;
}

export interface IStudentExams {
  examId: number;
  id: number;
  grade: number | undefined;
  title: string;
  examType: ExamType;
  applicantId: number;
  exam?: IExam;
}

export interface IStudentByCourse {
  courseName: string;
  courseId: number;
  applicantCount: number;
}

export interface IStudentResponseByCourse {
  applicantsByCourse: IStudentByCourse[];
  totalElements: number;
}

export interface IFilterStudentByCourse {
  courseIds: number[];
  dateFrom: string;
  dateTo: string;
}
export interface IApplicantCountRequest {
  dateFrom?: string,
  dateTo?: string,
  branches?: Branch[],
  courseIds?: number[],
  createdBy?: number[],
  groupIds?: number[],
  registrationSource?: RegistrationSource[],
  scheduledDate?: string,
  statuses?: StudentStatusEnum[],
  paymentMethods?: PaymentMethod[],
  leadGen?: number[],
  contactLater?: boolean,
}

export interface IApplicantCountByCriteriaRequest{
  countBy: string
  criteria: IApplicantCountRequest
}

export interface IFilterStudent extends IFilterStudentByCourse {
  loadExams: boolean;
  calcAttachments: boolean;
  loadActions: boolean;
  calcFeedbacks: boolean;
  calcDebt: boolean;
  email: string;
  groupIds: number[];
  name: string;
  phoneNumber: string;
  statuses: StudentStatus[];
  term: string;
  applicantExams: IStudentExams[];
  scheduledDate: string;
  contactLater: boolean;
}

export type FilterApplicantByCourseOptions = IFilterStudentByCourse & FilterOptions;
export type FilterApplicantOptions = IFilterStudent & FilterOptions;

export interface IStudentFilterResponse extends FilterResponse {
  applicants: IStudentBrief[];
  students: any[];
}

export interface IApplicantPersonalInfo {
  citizenship: Citizenship;
  isUnderage: boolean;
  contractNumber?: string | null,
  contractYear?: string | null,
  address: string;
  nameHy: string;
  passportGivenDate: string;
  passportNumber: string;
  socialCardNumber?: string | null;
  studentNameHy?: string | null;
}

export interface IApplicantAttachment {
  id: number;
  attachmentType: string;
  name: string;
  link: string;
}

export enum ApplicantWidgets {
  'Applied Only' = 'APPLIED_ONLY',
  'Enrolled' = 'ENROLLED',
  'Exam Failed' = 'EXAM_FAILED',
  'Exam Passed' = 'EXAM_PASSED',
  'Exam Scheduled' = 'EXAM_SCHEDULED',
  'Exam waitlist' = 'EXAM_WAITLIST',
  'On hold' = 'ON_HOLD',
  'Redirected' = 'REDIRECTED',
  'Rescheduled' = 'RESCHEDULED',
  'Interview Failed' = 'INTERVIEW_FAILED',
  'Interview Passed' = 'INTERVIEW_PASSED',
  'Interview Absent' = 'INTERVIEW_ABSENT',
  'Interview Scheduled' = 'INTERVIEW_SCHEDULED',
  'Interview Waitlist' = 'INTERVIEW_WAITLIST',
  'Consider for the next course' = 'CONSIDER_FOR_NEXT_COURSE',
  'Invite to the next course' = 'INVITE_NEXT_COURSE',
  'No answer' = 'NO_ANSWER',
  'E-mail sent' = 'E_MAIL_SENT',
  'Drop Out' = 'DROP_OUT',
  'Graduated' = 'GRADUATED',
}

export interface IPaymentsInvoice {
  dateFrom: Date;
  dateTo: Date;
  areLastMonthPayments: boolean;
}
