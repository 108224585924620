import { CurrencyEnum } from '../types/currency.types';

export const CurrencyOptions = {
  [CurrencyEnum.AMD]: {
    label: 'AMD',
    value: CurrencyEnum.AMD,
  },
  [CurrencyEnum.USD]: {
    label: 'USD',
    value: CurrencyEnum.USD,
  },
  [CurrencyEnum.EUR]: {
    label: 'EUR',
    value: CurrencyEnum.EUR,
  },
} as const;
