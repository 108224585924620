import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';

const { required } = messages;

export const SmsFormSchema = Yup.object().shape(
    {
        html: Yup.string(),
        template: Yup.string().required(required),
        to: Yup.string().required(required)
    }
);

