import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick.hooks';
import Flex from '../Flex/Flex';
import styles from './Input.module.css';

interface IInput {
  label?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  field?: React.HTMLProps<HTMLInputElement>;
  required?: boolean;
  labelNote?: string;
  value?: any;
  groupText?: string;
}

export type IFormInput = IInput & React.HTMLProps<HTMLInputElement>;

const Input: FC<IFormInput> = ({
  label = '',
  field,
  style = {},
  placeholder = '',
  required = false,
  labelNote,
  groupText,
  ...props
}) => {
  const requiredClass: string = cn({
    [styles.required]: true,
    [styles.isValid]: field?.value || props.value,
  });

  return (
    <label className={styles.wrapper}>
      <div className={styles.label}>
        <span>{label}</span>
        {required && <span className={requiredClass}>*</span>}
        {labelNote && <span className={requiredClass}>{labelNote}</span>}
      </div>
      <div style={{ display: 'flex' }}>
        <input style={style} className={styles.input} placeholder={placeholder || label} {...field} {...props} />
        {groupText && (
          <Flex alignItems="center" justifyContent="center" style={{ minWidth: 40, padding: '0 10px' }}>
            <p>{groupText}</p>
          </Flex>
        )}
      </div>
    </label>
  );
};

const makeEditableValue = (value: string): string => {
  const parsedString = parseFloat(value);

  const returnValue = Number.isNaN(parsedString) ? 1 : parsedString;

  return String(returnValue);
};

const editedValueNormalizer = (inputValue: string, defaultReturnValue?: string) => {
  const inputValueFirstChar = inputValue[0];
  const isInputNumber = Number(inputValue);

  if (inputValueFirstChar === '0') {
    const inputValueNumber = Number(inputValue);

    return String(inputValueNumber ? inputValueNumber : 0);
  }

  if (!inputValue) {
    return String(0);
  }

  if (!isInputNumber) {
    return defaultReturnValue ?? inputValue;
  }
  return inputValue;
};

export function EditableInput({
  text,
  onSave,
}: {
  text: string;
  onSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, editedValue: string) => Promise<boolean>;
}) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [editedValue, setEditedValue] = useState<string>(text);
  const wrapperRef = useRef(null);
  const beforeInputVal = useRef('');

  const toggleBetweenEdit = () => setIsEditable((prev) => !prev);

  const handleChange = (e: any) => {
    const inputValue = e.target.value;

    setEditedValue((prev) => editedValueNormalizer(inputValue, prev));
    beforeInputVal.current = editedValueNormalizer(inputValue);
  };

  const handleSave = async (e: any) => {
    if (beforeInputVal.current === editedValue) {
      const isSucceed = await onSave(e, editedValue);

      if (!isSucceed) {
        setEditedValue(editedValueNormalizer(text));
      }
    }

    setIsEditable(false);
  };

  const handleKeypress = async (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleSave(e);
    }
  };

  const handleCancel = () => {
    const normalizedDefaultVal = editedValueNormalizer(text);
    setIsEditable(false);

    setEditedValue(normalizedDefaultVal);
  };

  useEffect(() => {
    setEditedValue((prev) => makeEditableValue(prev));
  }, [isEditable]);

  useOutsideClick(wrapperRef, toggleBetweenEdit);

  return (
    <>
      {isEditable ? (
        <div ref={wrapperRef} className={styles.editableInputContainer}>
          <input
            className={styles.editableInput}
            autoFocus
            type="text"
            onChange={handleChange}
            onKeyPress={handleKeypress}
            value={editedValue}
          />

          <div className={styles.editableInputButtonsContainer}>
            <button className={styles.editableInputCheckButton} onClick={handleSave}>
              &#10003;
            </button>
            <button className={styles.editableInputDeleteButton} onClick={handleCancel}>
              X
            </button>
          </div>
        </div>
      ) : (
        <button onClick={toggleBetweenEdit} className={styles.editableInputTextButton}>
          {text}
        </button>
      )}
    </>
  );
}

export default Input;

export function RegularEditableInput({
  text,
  onSave,
}: {
  text: string;
  onSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, editedValue: string) => boolean;
}) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [editedValue, setEditedValue] = useState<string>(text);
  const wrapperRef = useRef(null);

  const toggleBetweenEdit = () => setIsEditable((prev) => !prev);

  const handleChange = (e: any) => {
    const inputValue = e.target.value;

    setEditedValue(inputValue);
  };

  const handleSave = (e: any) => {
    const isSucceed = onSave(e, editedValue);

    if (!isSucceed) {
      setEditedValue(text);
    }

    setIsEditable(false);
  };

  const handleKeypress = async (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave(e);
    }
  };

  const handleCancel = () => {
    setIsEditable(false);

    setEditedValue(text);
  };

  useOutsideClick(wrapperRef, toggleBetweenEdit);

  return (
    <>
      {isEditable ? (
        <div ref={wrapperRef} className={styles.editableInputContainer}>
          <input
            className={styles.regularEditableInput}
            autoFocus
            type="text"
            onChange={handleChange}
            onKeyPress={handleKeypress}
            value={editedValue}
          />

          <div className={styles.regularEditableInputButtonsContainer}>
            <button className={styles.editableInputCheckButton} onClick={handleSave}>
              &#10003;
            </button>
            <button className={styles.editableInputDeleteButton} onClick={handleCancel}>
              X
            </button>
          </div>
        </div>
      ) : (
        <button onClick={toggleBetweenEdit} className={styles.regularEditableInputTextButton}>
          {text}
        </button>
      )}
    </>
  );
}
