import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components/general';
import { ButtonTypeEnum } from '../../../types/button.types';
import { CreatePasswordErrorMassages } from '../constants/responseMassages';
import { MassageKey } from '../types/ExpiredToken';

export const ExpiredToken = ({ massageKey }: { massageKey: MassageKey }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <div>
          <p style={{ fontSize: '24px', color: '#222222' }}>{CreatePasswordErrorMassages[massageKey]}</p>
        </div>
        <div style={{ width: '60%', margin: 'auto' }}>
          <Button onClick={handleBackButtonClick} variant={ButtonTypeEnum.primary}>
            Leave page
          </Button>
        </div>
      </div>
    </div>
  );
};
