import { DateService } from '../../../services/date.service';
import { IApplicantDetailFeedback } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';
import { TitleWithDescriptionColumn } from '../applicantDetail.renderer';

export const feedbackColumns: ColumnSchema<IApplicantDetailFeedback> = {
  uuid: 'absenteesColumns',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      width: 175,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    ownerUsername: {
      title: 'Owner',
      width: 200,
      render: (owner, { ownerEmail }) => TitleWithDescriptionColumn(owner, ownerEmail),
    },
    content: {
      title: 'Content',
      render: (content) => content,
    },
  },
};
