import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SendMail } from '../../../types/mail.types';
import type { RootState } from '../../index';

interface MailingState {
  formValues: Partial<SendMail>;
  agreementMailValues: Partial<SendMail>;
}

export const initialState = {
  formValues: {
    password: '02f7958',
    to: '',
    tags: [] as SendMail['tags'],
  },
  agreementMailValues: {
    password: '02f7958',
    template: 'enroll-template',
    from: 'ACA <info@aca.am>',
    email: 'info@aca.am',
    subject: 'Course Invitation',
    tags: ['agreement', 'student'],
  },
} as MailingState;

export const mailingSlice = createSlice({
  name: 'massMailing',
  initialState,
  reducers: {
    setInitialValues: (
      state,
      action: PayloadAction<Partial<MailingState['formValues']>>,
    ) => {
      state.formValues = {
        ...state.formValues,
        ...action.payload,
      };
    },
    setAgreementMailValues: (
      state,
      action: PayloadAction<Partial<MailingState['agreementMailValues']>>,
    ) => {
      state.agreementMailValues = {
        ...state.agreementMailValues,
        ...action.payload,
      };
    },
  },
});

export const mailingActions = mailingSlice.actions;

export const selectMailingFormValues = (state: RootState) =>
  state.massMailing.formValues;

export const selectAgreementMailFormValues = (state: RootState) =>
  state.massMailing.agreementMailValues;
