import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TASK_TYPE } from '../../constants/task.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { pickChangedValues } from '../../helpers/formValueSerilizer';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { getApplicantById } from '../../services/applicant.service';
import { getGroupById } from '../../services/groups.service';
import { updateTaskById } from '../../services/tasks.service';
import { filterUsers } from '../../services/user.service';
import { IAppForm } from '../../types/form.types';
import { ICreateTask, ITaskGET, RelatedToTypes, TaskSourceEnum } from '../../types/task.types';
import { IUser, UserRole } from '../../types/user.types';
import { TransformTaskForm } from './shared/transformTask.form';

const getRelatedTo = (taskType: ITaskGET['taskType'] | undefined): string => {
  switch (taskType) {
    case TASK_TYPE.GROUP.value:
      return RelatedToTypes.GROUP;
    case TASK_TYPE.FIRST_CONTACT_PHASE.value:
    case TASK_TYPE.EXAM_STAGE.value:
    case TASK_TYPE.INTERVIEW_STAGE.value:
    case TASK_TYPE.APPLICANT.value:
      return RelatedToTypes.APPLICANT;
    case TASK_TYPE.OTHER.value:
      return RelatedToTypes.OTHER;
    default:
      throw new Error('Not a valid type');
  }
};

const isApplicantRelated = (relatedToTye?: string, relatedTo?: number | null) =>
  relatedToTye === RelatedToTypes.APPLICANT && relatedTo !== null;

const isGroupRelated = (relatedTo?: string) => relatedTo === RelatedToTypes.GROUP;

const isOtherRelated = (relatedTo?: string) => relatedTo === RelatedToTypes.OTHER;

const assertionFn = (data: unknown, propertyKey: string): data is any => {
  return typeof data === 'object' && data !== null && propertyKey in data;
};

export const EditTaskForm: IAppForm = () => {
  let taskToEdit: ITaskGET | undefined;
  let currentSource: TaskSourceEnum = TaskSourceEnum.OWNED;
  const cache = useQueryClient();
  const { closeDrawer } = useDrawer();
  const { id: editId } = useEntityId();

  const statesFromCache = cache?.getQueryCache().find([UseQueryTypes.TASKS])?.state.data;

  const createdStatesFromCache = cache?.getQueryCache().find([UseQueryTypes.CREATED_TASKS])?.state.data;

  if (assertionFn(statesFromCache, 'tasks')) {
    taskToEdit = statesFromCache.tasks.find((task: any) => task.id === editId);
  }
  if (assertionFn(createdStatesFromCache, 'tasks')) {
    const task = createdStatesFromCache.tasks.find((task: any) => task.id === editId);
    if (task) {
      currentSource = TaskSourceEnum.CREATED;
      taskToEdit = task;
    }
  }

  const relatedToType = taskToEdit?.taskType && getRelatedTo(taskToEdit?.taskType);

  const applicantByTaskIdQuery = useQuery(
    [UseQueryTypes.APPLICANT_ID, taskToEdit?.applicant?.id],
    () => getApplicantById(taskToEdit!.applicant?.id!),
    {
      enabled: isApplicantRelated(relatedToType, taskToEdit?.applicant?.id),
    },
  );

  const groupId = (() => {
    if (isGroupRelated(relatedToType)) return taskToEdit?.relatedTo?.id;
    if (isApplicantRelated(relatedToType)) return applicantByTaskIdQuery.data?.group?.id;
  })();

  const groupByTaskIdQuery = useQuery(
    [UseQueryTypes.GROUP_BY_ID, taskToEdit?.relatedTo],
    () => getGroupById(groupId!),
    {
      enabled: isGroupRelated(relatedToType),
    },
  );

  const ownersQuery = useQuery(UseQueryTypes.USERS, () => filterUsers({ roles: [UserRole.ROLE_PROJECT_COORDINATOR] }));

  const editTaskMutation = useMutation(updateTaskById, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.TASKS);
      cache.invalidateQueries(UseQueryTypes.CREATED_TASKS);
    },
  });

  const handleSubmit = async (task: Partial<ICreateTask>) => {
    const owner = ownersQuery?.data?.users?.find((user: IUser) => user.id === task.owner);

    if (isOtherRelated(task.taskType)) {
      task = { ...task, ownerUuid: owner?.uuid, relatedTo: null };
    }

    if (!taskToEdit) return;
    const mutationFunc = () => {
      const data = pickChangedValues(initialValues, task);
      data.taskType = task?.taskType;

      return editTaskMutation.mutateAsync({ task: data, id: taskToEdit?.id ?? 0 });
    };

    withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const initialValues = useMemo<Partial<ICreateTask>>(() => {
    if (!taskToEdit) return {};
    return {
      title: taskToEdit?.title,
      dueDate: taskToEdit?.dueDate,
      description: taskToEdit?.description,
      taskStatus: taskToEdit?.taskStatus,
      owner: taskToEdit?.owner.id,
      relatedTo: groupByTaskIdQuery.data?.id,
      applicant: applicantByTaskIdQuery.data?.id,
      taskType: taskToEdit?.taskType,
    };
  }, [applicantByTaskIdQuery.data?.id, groupByTaskIdQuery.data?.id, taskToEdit]);

  const isScreenLoading =
    ownersQuery.isLoading ||
    groupByTaskIdQuery.isLoading ||
    applicantByTaskIdQuery.isLoading ||
    editTaskMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <TransformTaskForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isEditForm={true}
        sourceType={currentSource}
      />
    </Spin>
  );
};
