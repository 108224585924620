import {
  ApplicantDropOutMonths,
  PaymentDiscount,
  PaymentMethod,
  RegistrationSource,
  StudentStatusEnum
} from '../types/applicant.types';

export const ApplicantStatusOptions = {
  [StudentStatusEnum.APPLIED_ONLY]: {
    value: StudentStatusEnum.APPLIED_ONLY,
    label: 'Applied Only',
  },
  [StudentStatusEnum.EXAM_SCHEDULED]: {
    value: StudentStatusEnum.EXAM_SCHEDULED,
    label: 'Exam Scheduled',
  },
  [StudentStatusEnum.RESCHEDULE_THE_EXAM]: {
    value: StudentStatusEnum.RESCHEDULE_THE_EXAM,
    label: 'Reschedule the Exam',
  },
  [StudentStatusEnum.EXAM_PASSED]: {
    value: StudentStatusEnum.EXAM_PASSED,
    label: 'Exam Passed',
  },
  [StudentStatusEnum.EXAM_FAILED]: {
    value: StudentStatusEnum.EXAM_FAILED,
    label: 'Exam Failed',
  },
  [StudentStatusEnum.EXAM_PASSED_INFORMED]: {
    value: StudentStatusEnum.EXAM_PASSED_INFORMED,
    label: 'Exam passed, informed',
  },
  [StudentStatusEnum.EXAM_FAILED_INFORMED]: {
    value: StudentStatusEnum.EXAM_FAILED_INFORMED,
    label: 'Exam failed, informed',
  },
  [StudentStatusEnum.EXAM_WAITLIST]: {
    value: StudentStatusEnum.EXAM_WAITLIST,
    label: 'Exam waitlist',
  },
  [StudentStatusEnum.INTERVIEW_SCHEDULED]: {
    value: StudentStatusEnum.INTERVIEW_SCHEDULED,
    label: 'Interview Scheduled',
  },
  [StudentStatusEnum.INTERVIEW_PASSED]: {
    value: StudentStatusEnum.INTERVIEW_PASSED,
    label: 'Interview Passed',
  },
  [StudentStatusEnum.INTERVIEW_FAILED]: {
    value: StudentStatusEnum.INTERVIEW_FAILED,
    label: 'Interview Failed',
  },
  [StudentStatusEnum.INTERVIEW_PASSED_INFORMED]: {
    value: StudentStatusEnum.INTERVIEW_PASSED_INFORMED,
    label: 'Interview passed, informed',
  },
  [StudentStatusEnum.INTERVIEW_FAILED_INFORMED]: {
    value: StudentStatusEnum.INTERVIEW_FAILED_INFORMED,
    label: 'Interview failed, informed',
  },
  [StudentStatusEnum.INTERVIEW_ABSENT]: {
    value: StudentStatusEnum.INTERVIEW_ABSENT,
    label: 'Interview Absent',
  },
  [StudentStatusEnum.INTERVIEW_WAITLIST]: {
    value: StudentStatusEnum.INTERVIEW_WAITLIST,
    label: 'Interview Waitlist',
  },
  [StudentStatusEnum.ENROLLED]: {
    value: StudentStatusEnum.ENROLLED,
    label: 'Enrolled',
  },
  [StudentStatusEnum.DROP_OUT]: {
    value: StudentStatusEnum.DROP_OUT,
    label: 'Drop Out',
  },
  [StudentStatusEnum.DROP_OUT_AFTER_ENROLLMENT]: {
    value: StudentStatusEnum.DROP_OUT_AFTER_ENROLLMENT,
    label: 'Drop Out After Enrollment',
  },
  [StudentStatusEnum.ON_HOLD]: {
    value: StudentStatusEnum.ON_HOLD,
    label: 'On hold',
  },
  [StudentStatusEnum.REDIRECTED]: {
    value: StudentStatusEnum.REDIRECTED,
    label: 'Redirected',
  },
  [StudentStatusEnum.RESCHEDULED]: {
    value: StudentStatusEnum.RESCHEDULED,
    label: 'Rescheduled',
  },
  [StudentStatusEnum.CONSIDER_FOR_NEXT_COURSE]: {
    value: StudentStatusEnum.CONSIDER_FOR_NEXT_COURSE,
    label: 'Consider for the next course',
  },
  [StudentStatusEnum.INVITE_NEXT_COURSE]: {
    value: StudentStatusEnum.INVITE_NEXT_COURSE,
    label: 'Invite to the next course',
  },
  [StudentStatusEnum.NO_ANSWER]: {
    value: StudentStatusEnum.NO_ANSWER,
    label: 'No answer',
  },
  [StudentStatusEnum.E_MAIL_SENT]: {
    value: StudentStatusEnum.E_MAIL_SENT,
    label: 'E-mail sent',
  },
  [StudentStatusEnum.GRADUATED]: {
    value: StudentStatusEnum.GRADUATED,
    label: 'Graduated',
  },
  [StudentStatusEnum.BLACKLIST]: {
    value: StudentStatusEnum.BLACKLIST,
    label: 'Blacklist',
  },
  [StudentStatusEnum.INTRO_GRADUATED]: {
    value: StudentStatusEnum.INTRO_GRADUATED,
    label: 'Intro graduated',
  },
} as const;

export const ApplicantOptionsByDropOutMonth = {
  [ApplicantDropOutMonths.FIRST_MONTH]: {
    value: ApplicantDropOutMonths.FIRST_MONTH,
    label: 'First Month',
  },
  [ApplicantDropOutMonths.SECOND_MONTH]: {
    value: ApplicantDropOutMonths.SECOND_MONTH,
    label: 'Second Month',
  },
  [ApplicantDropOutMonths.THIRD_MONTH]: {
    value: ApplicantDropOutMonths.THIRD_MONTH,
    label: 'Third Month',
  },
  [ApplicantDropOutMonths.FORTH_MONTH]: {
    value: ApplicantDropOutMonths.FORTH_MONTH,
    label: 'Forth Month',
  },
  [ApplicantDropOutMonths.FIFTH_MONTH]: {
    value: ApplicantDropOutMonths.FIFTH_MONTH,
    label: 'Fifth Month',
  },
  [ApplicantDropOutMonths.SIXTH_MONTH]: {
    value: ApplicantDropOutMonths.SIXTH_MONTH,
    label: 'Sixth Month',
  },
  [ApplicantDropOutMonths.SEVENTH_MONTH]: {
    value: ApplicantDropOutMonths.SEVENTH_MONTH,
    label: 'Seventh Month',
  },
  [ApplicantDropOutMonths.EIGHTH_MONTH]: {
    value: ApplicantDropOutMonths.EIGHTH_MONTH,
    label: 'Eighth Month',
  },
} as const;

export const StatusesPriority = {
  [StudentStatusEnum.ENROLLED]: 1,
  [StudentStatusEnum.GRADUATED]: 2,
  [StudentStatusEnum.DROP_OUT]: 3,
  [StudentStatusEnum.DROP_OUT_AFTER_ENROLLMENT]: 3,
  [StudentStatusEnum.APPLIED_ONLY]: 4,
  [StudentStatusEnum.EXAM_SCHEDULED]: 5,
  [StudentStatusEnum.RESCHEDULE_THE_EXAM]: 7,
  [StudentStatusEnum.EXAM_PASSED]: 8,
  [StudentStatusEnum.EXAM_FAILED]: 9,
  [StudentStatusEnum.EXAM_WAITLIST]: 10,
  [StudentStatusEnum.INTERVIEW_SCHEDULED]: 11,
  [StudentStatusEnum.INTERVIEW_PASSED]: 12,
  [StudentStatusEnum.INTERVIEW_FAILED]: 13,
  [StudentStatusEnum.INTERVIEW_ABSENT]: 14,
  [StudentStatusEnum.INTERVIEW_WAITLIST]: 15,
  [StudentStatusEnum.ON_HOLD]: 16,
  [StudentStatusEnum.REDIRECTED]: 17,
  [StudentStatusEnum.RESCHEDULED]: 18,
  [StudentStatusEnum.CONSIDER_FOR_NEXT_COURSE]: 19,
  [StudentStatusEnum.INVITE_NEXT_COURSE]: 20,
  [StudentStatusEnum.NO_ANSWER]: 21,
  [StudentStatusEnum.E_MAIL_SENT]: 22,
  [StudentStatusEnum.BLACKLIST]: 23,
  [StudentStatusEnum.INTRO_GRADUATED]: 24,
  [StudentStatusEnum.EXAM_PASSED_INFORMED]: 25,
  [StudentStatusEnum.EXAM_FAILED_INFORMED]: 26,
  [StudentStatusEnum.INTERVIEW_PASSED_INFORMED]: 27,
  [StudentStatusEnum.INTERVIEW_FAILED_INFORMED]: 28,
};

export const ApplicantCreateStatusOptions = {
  [StudentStatusEnum.APPLIED_ONLY]: {
    value: StudentStatusEnum.APPLIED_ONLY,
    label: 'Applied Only',
  },
  [StudentStatusEnum.EXAM_SCHEDULED]: {
    value: StudentStatusEnum.EXAM_SCHEDULED,
    label: 'Exam Scheduled',
  },
  [StudentStatusEnum.RESCHEDULE_THE_EXAM]: {
    value: StudentStatusEnum.RESCHEDULE_THE_EXAM,
    label: 'Reschedule the Exam',
  },
  [StudentStatusEnum.EXAM_PASSED]: {
    value: StudentStatusEnum.EXAM_PASSED,
    label: 'Exam Passed',
  },
  [StudentStatusEnum.EXAM_FAILED]: {
    value: StudentStatusEnum.EXAM_FAILED,
    label: 'Exam Failed',
  },
  [StudentStatusEnum.EXAM_PASSED_INFORMED]: {
    value: StudentStatusEnum.EXAM_PASSED_INFORMED,
    label: 'Exam passed, informed',
  },
  [StudentStatusEnum.EXAM_FAILED_INFORMED]: {
    value: StudentStatusEnum.EXAM_FAILED_INFORMED,
    label: 'Exam failed, informed',
  },
  [StudentStatusEnum.EXAM_WAITLIST]: {
    value: StudentStatusEnum.EXAM_WAITLIST,
    label: 'Exam waitlist',
  },

  [StudentStatusEnum.CONSIDER_FOR_NEXT_COURSE]: {
    value: StudentStatusEnum.CONSIDER_FOR_NEXT_COURSE,
    label: 'Consider for the next course',
  },
  [StudentStatusEnum.INTERVIEW_SCHEDULED]: {
    value: StudentStatusEnum.INTERVIEW_SCHEDULED,
    label: 'Interview Scheduled',
  },
  [StudentStatusEnum.INTERVIEW_PASSED]: {
    value: StudentStatusEnum.INTERVIEW_PASSED,
    label: 'Interview Passed',
  },
  [StudentStatusEnum.INTERVIEW_FAILED]: {
    value: StudentStatusEnum.INTERVIEW_FAILED,
    label: 'Interview Failed',
  },
  [StudentStatusEnum.INTERVIEW_PASSED_INFORMED]: {
    value: StudentStatusEnum.INTERVIEW_PASSED_INFORMED,
    label: 'Interview passed, informed',
  },
  [StudentStatusEnum.INTERVIEW_FAILED_INFORMED]: {
    value: StudentStatusEnum.INTERVIEW_FAILED_INFORMED,
    label: 'Interview failed, informed',
  },
  [StudentStatusEnum.INTERVIEW_ABSENT]: {
    value: StudentStatusEnum.INTERVIEW_ABSENT,
    label: 'Interview Absent',
  },
  [StudentStatusEnum.INTERVIEW_WAITLIST]: {
    value: StudentStatusEnum.INTERVIEW_WAITLIST,
    label: 'Interview Waitlist',
  },
  [StudentStatusEnum.ENROLLED]: {
    value: StudentStatusEnum.ENROLLED,
    label: 'Enrolled',
  },
  [StudentStatusEnum.DROP_OUT]: {
    value: StudentStatusEnum.DROP_OUT,
    label: 'Drop Out',
  },
  [StudentStatusEnum.DROP_OUT_AFTER_ENROLLMENT]: {
    value: StudentStatusEnum.DROP_OUT_AFTER_ENROLLMENT,
    label: 'Drop Out After Enrollment',
  },
  [StudentStatusEnum.REDIRECTED]: {
    value: StudentStatusEnum.REDIRECTED,
    label: 'Redirected',
  },
  [StudentStatusEnum.GRADUATED]: {
    value: StudentStatusEnum.GRADUATED,
    label: 'Graduated',
  },
  [StudentStatusEnum.BLACKLIST]: {
    value: StudentStatusEnum.BLACKLIST,
    label: 'Blacklist',
  },
} as const;

export const ApplicantPaymentMethodOptions = {
  [PaymentMethod.DEFAULT]: {
    value: PaymentMethod.DEFAULT,
    label: 'Default payment',
  },
  [PaymentMethod.DONATION]: {
    value: PaymentMethod.DONATION,
    label: 'Artsakh Scholarship',
  },
  [PaymentMethod.AMERIA_CREDIT]: {
    value: PaymentMethod.AMERIA_CREDIT,
    label: 'Ameria credit',
  }
}
export const ApplicantDiscountOptions = {
  [PaymentDiscount.ZERO_PERCENT]: {
    value: 0,
    label: '0%',
  },
  [PaymentDiscount.TEN_PERCENT]: {
    value: 10,
    label: '10%',
  },
  [PaymentDiscount.TWENTY_PERCENT]: {
    value: 20,
    label: '20%',
  },
  [PaymentDiscount.THIRTY_PERCENT]: {
    value: 30,
    label: '30%',
  },
  [PaymentDiscount.FORTY_PERCENT]: {
    value: 40,
    label: '40%',
  },
  [PaymentDiscount.FIFTY_PERCENT]: {
    value: 50,
    label: '50%',
  },
  [PaymentDiscount.SIXTY_PERCENT]: {
    value: 60,
    label: '60%',
  },
  [PaymentDiscount.SEVENTY_PERCENT]: {
    value: 70,
    label: '70%',
  },
  [PaymentDiscount.EIGHTY_PERCENT]: {
    value: 80,
    label: '80%',
  },
  [PaymentDiscount.NINETY_PERCENT]: {
    value: 90,
    label: '90%',
  },
  [PaymentDiscount.HUNDRED_PERCENT]: {
    value: 100,
    label: '100%',
  },
}
export const ApplicantStatusArchive = ['ON_HOLD', 'RESCHEDULED', 'INVITE_NEXT_COURSE', 'NO_ANSWER', 'E-MAIL_SENT'];

export const StudentStatusOptions = {
  [StudentStatusEnum.ENROLLED]: {
    value: StudentStatusEnum.ENROLLED,
    label: 'Enrolled',
  },
  [StudentStatusEnum.DROP_OUT]: {
    value: StudentStatusEnum.DROP_OUT,
    label: 'Drop Out',
  },
} as const;

export const ApplicantRegistrationSource = {
  [RegistrationSource.MANUAL]: {
    value: RegistrationSource.MANUAL,
    label: 'Manual',
  },
  [RegistrationSource.TILDA]: {
    value: RegistrationSource.TILDA,
    label: 'Tilda',
  },
  [RegistrationSource.WEBSITE]: {
    value: RegistrationSource.WEBSITE,
    label: 'Website',
  },
  [RegistrationSource.IMPORTED]: {
    value: RegistrationSource.IMPORTED,
    label: 'Imported',
  },
} as const;

export const defaultUserOption = {
  '-1': {
    label: '--Added By--',
    value: -1,
  },
};

export const ApplicantStatusColors: Partial<{ [key in StudentStatusEnum]: string } & { default: string }> = {
  [StudentStatusEnum.EXAM_PASSED]: '#84D06C',
  [StudentStatusEnum.EXAM_FAILED]: '#EB3223',
  default: '#000',
};

export const ApplicantStages = {
  'APPLICANT': 'Applicant',
  'STUDENT': 'Student'
}
