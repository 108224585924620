import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IScrollToTopProps {
  children: JSX.Element | JSX.Element[];
}

export default function ScrollToTop({ children }: IScrollToTopProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}
