import { dashboardItemTypes } from '../../../../constants/dashboard.constants';
import { IMenuGroups } from '../../../../types/dashboard.types';
import { IDashboardLinkItem } from '../../../../types/path.types';
import DashboardMenu from './menu';
import { SimpleLink } from './simpleLink';

type ISectionProps = {
  item: IDashboardLinkItem;
  menuGroups: IMenuGroups;
  setShowGroups: (name: string) => void
}

export const DashboardSection = ({ item, menuGroups, setShowGroups}: ISectionProps) => {
  const { name, items } = item;
  return (
    <>
      <p className="menu-subtitle">{name}</p>
      {
        Object.values(items || {}).map((item) => {
          switch (item.type) {
            case dashboardItemTypes.MENU: {
              return <DashboardMenu key={item.id} menuGroups={menuGroups} setShowGroups={setShowGroups} item={item}/>
            }
            default: {
              return <SimpleLink key={item.id} item={item}  />
            }
          }
        })
      }
    </>
  )
};
