import {BranchNameColumn} from "../../modules/shared/schemas/shared.renderer";
import type { ColumnSchema } from '../../types/column.types';
import type { ICourse } from '../../types/course.types';
import {
  AvatarCourseColumn,
  DeleteCourseColumn,
  DurationCourseColumn,
  EditCourseColumn,
  FeeCourseColumn,
} from './course.renderer';

export const defaultCourseColumns: ColumnSchema<ICourse> = {
  uuid: 'defaultCourseColumns',
  schema: {
    username: {
      dataIndex: 'username',
      title: 'Project Coordinator',
      render: AvatarCourseColumn,
      width: 200,
    },
    name: {
      dataIndex: 'name',
      title: 'Course',
    },
    level: {
      dataIndex: 'level',
      title: 'Level',
    },
    branch: {
      dataIndex: 'branch',
      title: 'Branch',
      render: BranchNameColumn
    },
    track: {
      title: 'Track',
      dataIndex: ['track.name','relationalSort'],
      render: (_, course) => course.track.name,
      sorter: () => -1,
    },
    duration: {
      dataIndex: ['defaultDuration.durationInDays','relationalSort'],
      title: 'Duration',
      sorter: () => -1,
      render: DurationCourseColumn,
    },
    fee: {
      dataIndex: ['defaultTuitionFee.amount','relationalSort'],
      title: 'Fee',
      sorter: () => -1,
      render: FeeCourseColumn,
    },
    expectedNumberOfApplicants: {
      dataIndex: 'expectedNumberOfApplicants',
      title: 'Size',
    },
    edit: {
      dataIndex: 'edit',
      title: 'Edit',
      width: 30,
      render: EditCourseColumn,
    },
    delete: {
      dataIndex: 'delete',
      title: 'Delete',
      width: 30,
      render: DeleteCourseColumn,
    },
  },
};
