import qs from 'query-string';

export const stringifyObject = (object = {}) => {
  return qs.stringify(object, {
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true,
  });
};

export type LocationSearch = {[key: string]: any}

export const parseLocationSearch = (locationSearch: string): LocationSearch => {
  return qs.parse(locationSearch, {
    parseNumbers: true,
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: '|',
    parseBooleans: true,
  });
};
