import { ApplicantCreateStatusOptions } from '../../../constants/applicant.constants';
import { DateService } from '../../../services/date.service';
import { IApplicantDetailAction } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';
import { AvatarColumn, DescriptionColumn, GroupColumn, OptionsColumn } from '../applicantDetail.renderer';

export const actionColumns: ColumnSchema<IApplicantDetailAction> = {
  uuid: 'actionColumn',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      width: 120,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    description: {
      title: 'Description',
      width: 250,
      render: DescriptionColumn,
    },
    groupName: {
      title: 'Group',
      width: 200,
      render: (group, { groupId, courseName }) => (group ? GroupColumn(group, groupId) : courseName),
    },
    avatar: {
      title: 'Commenter',
      render: (avatar, { username }) => AvatarColumn(avatar || '', username || ''),
      width: 120,
    },
    status: {
      title: 'Status',
      width: 120,
      render: (status) => OptionsColumn(status, ApplicantCreateStatusOptions),
    },
  },
};
