import { Col } from 'antd';
import { useNavigate } from 'react-router-dom';

import Flex from '../Flex/Flex';
import styles from './Widget.module.css';

export enum WidgetTypeEnum {
  primary = 'primary',
  secondary = 'secondary',
}

export default function Widget({
  title,
  bgColor,
  link,
  count,
  style,
  variant = WidgetTypeEnum.primary,
}: {
  title: string;
  bgColor: string;
  link: string;
  count?: number;
  style?: object;
  variant?: WidgetTypeEnum;
}) {
  const navigate = useNavigate();

  const colorTypes: Record<string, string> = {
    blue: '#BADEFF',
    green: '#B3E0A5',
    violet: '#9896FC',
    purple: '#AA82FF',
  };

  const navigateTo = () => {
    navigate(link);
  };

  const renderWidgetVariants = (variant: WidgetTypeEnum) => {
    switch (variant) {
      case WidgetTypeEnum.primary:
        return (
          <Flex
            justifyContent="center"
            direction="column"
            alignItems="flex-start"
            className={styles.widget}
            style={{
              backgroundColor: colorTypes[bgColor],
              ...style,
            }}
          >
            <p className={styles.title}>{title}</p>
            <p className={styles.title}>{count ?? 0}</p>
          </Flex>
        );
      case WidgetTypeEnum.secondary:
        return (
          <Flex
            justifyContent="center"
            direction="column"
            alignItems="center"
            className={styles.widget}
            style={{
              padding: '25px 0',
              backgroundColor: colorTypes[bgColor],
              ...style,
            }}
          >
            <p className={styles.title}>{title}</p>
          </Flex>
        );
      default:
        throw new Error('Provide widget variant');
    }
  };

  return <Col onClick={navigateTo}>{renderWidgetVariants(variant)}</Col>;
}
