import { createContext, ReactChild, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';

import { loadState } from '../helpers/localStorage.helpers';
import { SOCKET_UPDATES } from '../types/socket.types';

export const SocketContext = createContext({
  addSocketEvent: (
    eventName: SOCKET_UPDATES,
    eventCb: (data: any) => void,
  ) => {},
  removeSocketEvent: (eventName: SOCKET_UPDATES) => {},
});

interface ISocketProvider {
  children: ReactChild;
}

const endPoint: string = process.env.REACT_APP_SOCKET_ENDPOINT || '';
const token = loadState('token');
const socketIo = io(endPoint, { query: { token } });

export const SocketProvider = (props: ISocketProvider) => {
  useEffect(() => {
    return () => {
      socketIo.off();
    };
  }, []);

  const addSocketEvent = (
    eventName: SOCKET_UPDATES,
    eventCb: (data: any) => void,
  ) => {
    socketIo.on(eventName, eventCb);
  };

  const removeSocketEvent = (eventName: SOCKET_UPDATES) => {
    socketIo.off(eventName);
  };

  return (
    <SocketContext.Provider value={{ addSocketEvent, removeSocketEvent }}>
      {props.children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
