import { Col, Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, Flex, Input as FormInput, SingleSelectFormik } from '../../../components/general';
import { BranchOptions } from '../../../constants/constants';
import {
  CourseCategoryOptions,
  CourseDurationOptions,
  FeeClientTypes,
  LevelOptions,
} from '../../../constants/courseInfo.constants';
import { CurrencyOptions } from '../../../constants/currency.constants';
import { ExamProcessTypeOptions, ExamSourceOptions } from '../../../constants/exam.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { getMailTemplates } from '../../../services/mail.service';
import { getAllTracks } from '../../../services/track.service';
import { getAllUser } from '../../../services/user.service';
import { ICreateCourse } from '../../../types/course.types';
import { EmailTemplatesEnum } from '../../../types/email.types';
import { ExamProcessType } from '../../../types/exam.types';
import { ITransform } from '../../../types/form.types';
import { TransformCourseFormSchema } from '../schemas/transformCourse.form.schema';

export const TransformCourseForm = ({ onSubmit, initialValues, submitText }: ITransform<ICreateCourse>) => {
  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);
  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);
  const mailTemplates = useQuery(UseQueryTypes.MAIL_TEMPLATE, getMailTemplates);

  const mailTemplateContext = useMemo(() => {
    const items = mailTemplates.data?.templates?.items || [];
    return generateContext(items, (template) => ({
      label: template.name,
      value: template.name,
    }));
  }, [mailTemplates.data]);

  const tracksContext = useMemo(() => {
    return generateContext(tracksQuery.data || [], (track) => ({
      label: track.name,
      value: track.id,
    }));
  }, [tracksQuery.data]);

  const usersContext = useMemo(() => {
    return generateContext(usersQuery.data || [], (user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  }, [usersQuery.data]);

  const isScreenLoading = tracksQuery.isLoading || usersQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        initialValues={initialValues}
        validationSchema={TransformCourseFormSchema}
        enableReinitialize
        onSubmit={(course) => {
          if (course.examProcessType === ExamProcessType.AUTOMATED) {
            course.emailTemplate = EmailTemplatesEnum.AUTOMATION_TEMPLATE;
          }
          onSubmit(course);
        }}
      >
        {({ getFieldProps, dirty, resetForm, isValid }) => {
          const isAutomated = getFieldProps('examProcessType').value === ExamProcessTypeOptions.AUTOMATED.value;

          return (
            <Form
              name="create-course"
              style={{
                width: '100%',
                height: 'calc(100vh)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field name="name" label="Course Name" component={FormInput} required />
              <ErrorMessage name="name" component="span" className="error" />

              <Field
                label="Course Category"
                name="courseCategory"
                component={SingleSelectFormik}
                options={CourseCategoryOptions}
                required
              />
              <ErrorMessage name="courseCategory" component="span" className="error" />

              <Field
                name="branch"
                label="Course Branch"
                component={SingleSelectFormik}
                options={BranchOptions}
                required
              />
              <ErrorMessage name="branch" component="span" className="error" />

              <Row gutter={8}>
                <Col span={12}>
                  <Field label="Level" name="level" component={SingleSelectFormik} options={LevelOptions} required />
                  <ErrorMessage name="level" component="span" className="error" />
                </Col>
                <Col span={12}>
                  <Field name="expectedNumberOfApplicants" label="Size" component={FormInput} required />
                  <ErrorMessage name="expectedNumberOfApplicants" component="span" className="error" />
                </Col>
              </Row>
              <Field label="Track" name="trackId" component={SingleSelectFormik} options={tracksContext} required />
              <ErrorMessage name="trackId" component="span" className="error" />

              <Field
                label="Project Coordinator"
                name="defaultProjectCoordinatorId"
                component={SingleSelectFormik}
                options={usersContext}
                required
              />
              <ErrorMessage name="defaultProjectCoordinatorId" component="span" className="error" />

              <Row style={{ marginTop: '20px' }}>
                <Col span={11}>
                  <Field
                    name="defaultDuration.value"
                    label="Duration"
                    component={FormInput}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    required
                  />
                  <ErrorMessage name="value" component="span" className="error" />
                </Col>
                <Col offset={2} span={11}>
                  <Field
                    name="defaultDuration.timeScale"
                    label="Timescale"
                    component={SingleSelectFormik}
                    options={CourseDurationOptions}
                    required
                  />
                  <ErrorMessage name="timeScale" component="span" className="error" />
                </Col>
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <Col span={11}>
                  <Field
                    component={SingleSelectFormik}
                    options={CurrencyOptions}
                    name="defaultTuitionFee.currency"
                    label="Price"
                    required
                  />
                  <ErrorMessage name="defaultTuitionFee.currency" component="span" className="error" />
                </Col>
                <Col offset={2} span={11}>
                  <Field
                    label="Fee type"
                    component={SingleSelectFormik}
                    options={FeeClientTypes}
                    name="defaultTuitionFee.feeType"
                    required
                  />
                  <ErrorMessage name="defaultTuitionFee.feeType" component="span" className="error" />
                </Col>
                <Col span={24}>
                  <Field name="defaultTuitionFee.amount" label="Amount" component={FormInput} required />
                  <ErrorMessage name="defaultTuitionFee.amount" component="span" className="error" />
                </Col>
                <Col span={24}>
                  <Field
                    label="Exam Source"
                    name="examSource"
                    component={SingleSelectFormik}
                    options={ExamSourceOptions}
                  />
                  <ErrorMessage name="examSource" component="span" className="error" />
                </Col>
                <Col span={24}>
                  <Field
                    label="Exam Process Type"
                    name="examProcessType"
                    component={SingleSelectFormik}
                    options={ExamProcessTypeOptions}
                  />
                  <ErrorMessage name="examProcessType" component="span" className="error" />
                </Col>
                {!isAutomated && (
                  <Col span={24}>
                    <Field
                      name="emailTemplate"
                      label="Email Template"
                      options={mailTemplateContext}
                      component={SingleSelectFormik}
                      required
                    />
                    <ErrorMessage name="emailTemplate" component="span" className="error" />
                  </Col>
                )}
                <Col span={24}>
                  <Field
                    name="examPercentage"
                    label="Exam Passed Percentage"
                    component={FormInput}
                    type="number"
                    required={isAutomated}
                    groupText="%"
                  />
                  <ErrorMessage name="examPercentage" component="span" className="error" />
                </Col>
                <Col span={24}>
                  <Field name="testLink" label="Test Link" component={FormInput} required={isAutomated} />
                  <ErrorMessage name="testLink" component="span" className="error" />
                </Col>
                <Col span={24}>
                  <Field name="testId" label="Test id" component={FormInput} required={isAutomated} />
                  <ErrorMessage name="testId" component="span" className="error" />
                </Col>
              </Row>
              <Flex style={{ marginTop: 'auto', marginBottom: '20px' }} justifyContent="space-between">
                <Button style={{ minWidth: 160, marginRight: 10 }} onClick={resetForm as () => void}>
                  Cancel
                </Button>
                <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary" disabled={!(dirty && isValid)}>
                  {submitText}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
