import { AuthGroup } from '../types/auth.types';
import { InstructorRoutesConstants, RoutesConstants, StudentRoutesConstants } from './routes.helpers';

export const getLoginPathByAuthGroup = (authGroup: AuthGroup) => {
  switch (authGroup) {
    case AuthGroup.INSTRUCTOR:
      return InstructorRoutesConstants.login();
    case AuthGroup.STUDENT:
      return StudentRoutesConstants.login();
    default:
      return RoutesConstants.login();
  }
};

export const getHomePathByAuthGroup = (authGroup?: AuthGroup) => {
  switch (authGroup) {
    case AuthGroup.INSTRUCTOR:
      return InstructorRoutesConstants.dashboard();
    case AuthGroup.STUDENT:
      return StudentRoutesConstants.agreements();
    default:
      return RoutesConstants.dashboard();
  }
};

export const getEditProfilePathByAuthGroup = (authGroup?: AuthGroup) => {
  switch (authGroup) {
    case AuthGroup.INSTRUCTOR:
      return InstructorRoutesConstants.accountInfo();
    case AuthGroup.STUDENT:
      return null;
    default:
      return RoutesConstants.accountInfo();
  }
};
