import { WedgedLevels } from "../constants/wedged.constants";
import { ApplicantDropOutMonths, StudentStatusEnum } from "../types/applicant.types";
import { WedgedTypes } from "../types/general.types";
import { InstructorStatusEnum } from "../types/instructor.types";
import { getDateFormsByWidgetType } from "./date.helpers";

export const setWedgedFilterOptions = (type: string, name: string) => {
  switch (type) {
    case WedgedTypes.DATE: {
      return { ...getDateFormsByWidgetType(name) }
    }
    case WedgedTypes.LEVEL: {
      return { levels: [WedgedLevels[name as keyof typeof WedgedLevels]] }
    }
    case WedgedTypes.APPLICANT_STATUS: {
      return { statuses: [StudentStatusEnum[name as keyof typeof StudentStatusEnum]] }
    }
    case WedgedTypes.APPLICANT_DROPOUT_MONTHS: {
      return { dropoutMonths: [ApplicantDropOutMonths[name as keyof typeof ApplicantDropOutMonths]] }
    }
    case WedgedTypes.INSTRUCTOR_STATUS: {
      return { instructorStatus: [InstructorStatusEnum[name as keyof typeof InstructorStatusEnum]] }
    }
  }
}
