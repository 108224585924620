import axios from 'axios';

import { IStudentAttachment } from "../modules/student/types/studentAttachment.types";

export function getAttachmentById(id: number) {
  return axios.get(`/attachment/${id}`).then((r) => r.data) as Promise<IStudentAttachment>;
}

export function deleteAttachmentById(id: number) {
  return axios.delete(`/attachment/${id}`).then((r) => r.data) as Promise<boolean>;
}
