import { Col, Row } from 'antd';

import { Button } from '../../../components/general';
import { IButton } from '../../../types/components/general.types';

interface IFormControlButtonsProps {
  submitBtnProps: IButton
  resetBtnProps: IButton
}

export default function FormControlButtons({ submitBtnProps, resetBtnProps }: IFormControlButtonsProps) {
  return (
    <Row style={{ width: '100%', columnGap: '8px'}} justify="space-between">
      <Col span={11}>
        <Button htmlType="button" onClick={resetBtnProps.onClick}>
          Reset
        </Button>
      </Col>
      <Col span={11}>
        <Button htmlType="submit" variant="primary" disabled={submitBtnProps.disabled}>
          Save
        </Button>
      </Col>
    </Row>
  )
}
