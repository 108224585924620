import { Spin, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Flex } from '../../../../../components/general';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { useTable } from '../../../../../hooks/table/useTable';
import { getDefaultApplicantPaymentColumns } from '../../../../../schemas/payments/payments.schema';
import {
  getGroupDetailWithPayment,
  getGroupPayments,
} from '../../../../../services/payment.service';
import type {
  IExtendedGroupStudents,
  IGroupStudents,
  IPayment,
} from '../../../../../types/payment.types';

const { Text } = Typography;


export const StudentPayments = () => {
  const { id: groupId } = useParams<{ id: string }>();

  const groupPayments = useQuery([UseQueryTypes.PAYMENT, groupId], () =>
    getGroupPayments(Number(groupId)),
  );

  const groupPaymentDetailInfo = useQuery(
    [UseQueryTypes.GROUP_PAYMENT, groupId],
    () => getGroupDetailWithPayment(Number(groupId)),
  );

  /*const studentsFromPaymentDetailInfo = useQueries(
    groupPaymentDetailInfo.isSuccess
      ? groupPaymentDetailInfo?.data.students.map(({ id }: { id: number }) => {
          return {
            queryKey: [UseQueryTypes.STUDENTS, id],
            queryFn: () => getApplicantById(id),
          };
        })
      : [],
  ).map((studentResponse) => studentResponse.data);*/

  let paymentTable = useTable(
    getDefaultApplicantPaymentColumns(groupPayments.data),
  );

  const groupStudents = (groupPaymentDetailInfo.data?.students || []).map(
    (groupStudent: IGroupStudents): IExtendedGroupStudents => {
      // const studentData = findFromList(
      //   studentsFromPaymentDetailInfo,
      //   'id',
      //   groupStudent.id,
      // );
      const studentLastComment =
        groupStudent?.comments?.[groupStudent?.comments?.length - 1];

      const groupPaymentMethod = groupStudent?.group?.tuitionFee?.feeType;

      const lastItemPaymentsGroup = groupPayments.data?.[
      groupPayments.data.length - 1
        ] as IPayment;

      return {
        ...groupStudent,
        phoneNumber: groupStudent?.phoneNumber,
        comment: studentLastComment,
        amount: lastItemPaymentsGroup?.amount,
        currency: lastItemPaymentsGroup?.currency,
        method: groupPaymentMethod,
      };
    },
  );

  const isScreenLoading =
    groupPaymentDetailInfo.isLoading ||
    groupPayments.isLoading;

  const groupStudentsLength = groupStudents?.length ?? 0;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text type="secondary" style={{ marginBottom: 20 }}>
          {groupStudentsLength} result found showing {groupStudentsLength}
          of {groupStudentsLength}
        </Text>
      </Flex>

      <Spin spinning={isScreenLoading}>
        <Table
          style={{ margin: '20px 0' }}
          {...paymentTable.getTableProps({ bordered: true })}
          dataSource={groupStudents}
          scroll={{ y: 500 }}
          rowKey={(r) => r.id}
        />
      </Spin>
    </>
  );
};
