import React from 'react';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import {
  groupStudentAppRoutes,
  RoutesConstants,
} from '../../../../../helpers/routes.helpers';
import { getGroupById } from '../../../../../services/groups.service';
import { CourseCategoryEnum } from '../../../../../types/course.types';
import { BootcampStudentPayments } from '../pages/BootcampStudentPayments';
import { StudentAbsentees } from '../pages/StudentAbsentees';
import { StudentGrades } from '../pages/StudentExams';
import { StudentFeedbacks } from '../pages/StudentFeedbacks';
import { StudentPayments } from '../pages/StudentPayments/StudentPayments';
import { StudentMainPage } from '../StudentMainPage';

export const StudentAppRoutes: React.FC = () => {
  const { id } = useParams() as { id: string };
  const groupQuery = useQuery(UseQueryTypes.GROUP_BY_ID, () =>
    getGroupById(Number(id)),
  );

  const courseCategory = groupQuery.data?.course.courseCategory;

  return (
    <Routes>
      <Route path={RoutesConstants.home()} element={<StudentMainPage/>}>
        <Route
          path={groupStudentAppRoutes.absentees()}
          element={<StudentAbsentees/>}
        />
        <Route
          path={groupStudentAppRoutes.grades()}
          element={<StudentGrades/>}
        />
        <Route
          path={groupStudentAppRoutes.feedbacks()}
          element={<StudentFeedbacks/>}
        />
        <Route
          path={groupStudentAppRoutes.payments()}
          element={
            courseCategory === CourseCategoryEnum.BOOTCAMP ? (
              <BootcampStudentPayments/>
            ) : (
              <StudentPayments/>
            )
          }
        />
        <Route
          path={RoutesConstants.groupPage(':id')}
          element={<Navigate replace to={RoutesConstants.groupPayments(id)}/>}
        />
      </Route>
    </Routes>
  );
};
