import { useMutation, useQueryClient } from 'react-query';

import { useDrawer } from '../../../../contexts/drawer.context';
import { useAuth } from '../../../../contexts/useAuth.context';
import { withCatch } from '../../../../helpers/error.helpers';
import { useEntityId } from '../../../../hooks/useFilter.hooks';
import { createStudentAttachment } from '../../../../services/student.service';
import { AttachmentTypeEnum } from '../../../../types/attachment.types';
import { IAppForm } from '../../../../types/form.types';
import { StudentQueryConstants } from '../../constants/studentQuery.constants';
import { CreateStudentAttachmentForm } from '../StudentAttachmentForm/CreateStudentAttachmentForm';


export const CreateStudentPaymentForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { user } = useAuth();
  const cache = useQueryClient();
  const { id: paymentId } = useEntityId()


  const studentAttachment = useMutation(createStudentAttachment, {
    onSuccess: () => {
      cache.invalidateQueries(StudentQueryConstants.STUDENT_PAYMENTS);
    }
  });

  const handleCreatePayment = async (
    newAttachment: { name: string, attachment: string },
  ) => {
    if (user?.id) {
      const newAttachmentWithUserId = {
        ...newAttachment,
        attachmentType: AttachmentTypeEnum.INVOICE,
        applicantPaymentId: paymentId,
        userId: user.id
      }

      const mutationFunc = () =>
        studentAttachment.mutateAsync(newAttachmentWithUserId);

      await withCatch(mutationFunc, {
        onSuccess: () => {
          closeDrawer();
        },
      });
    }
  };


  return (
    <CreateStudentAttachmentForm
      attachmentTitle='Payment name'
      attachmentFileTitle='Payment Picture'
      attachmentType={AttachmentTypeEnum.INVOICE}
      isLoading={studentAttachment.isLoading} handleSubmit={handleCreatePayment}
    />
  );
};


