import React from 'react';

export const Tag: React.FC<{ background?: string; style?: React.CSSProperties }> = ({
  children,
  background = '#99DF83',
  style = {},
}) => {
  return (
    <div
      style={{
        background,
        borderRadius: '18px',
        fontSize: 12,
        lineHeight: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
