import { DEFAULT_EMAIL, DEFAULT_PHONE } from "../../constants/courseInfo.constants";
import { IPhone } from "../../types/phone.types";
import { IUserVariables } from "../../types/user.types";

export const replaceAllVariables = (str: string, objectOfVariables: any) => {
  return Object.keys(objectOfVariables).reduce(
    (acc, v) => acc.replaceAll(v, objectOfVariables[v]),
    str,
  );
};

const getPhoneFormat = (phone: string) => {
  const countryCode = phone.slice(0, 4);
  const areaCode = phone.slice(4, 6);
  const LineNumber = phone
    .slice(6)
    .replace(/^(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');

  return `${countryCode} (${areaCode}) ${LineNumber}`;
};

const variableUniqueKeyGetter = (key: string) =>
  `${key.slice(0, key.length - 1)}${+key[key.length - 1] + 1}`;

const generatePhoneA = (phone: string) =>
  `<a href="tel:${phone}"rel="noreferrer">${getPhoneFormat(phone)}</a> ,`;

const generateEmailA = (email: string) =>
  `<a href="mailto:${email}"rel="noreferrer">${email}</a>`;

export const setUserVariables = (phoneNumbers: IPhone[], email: string | undefined) => {
  const phoneVariables: IUserVariables = {
    EMAIL: generateEmailA(email || DEFAULT_EMAIL),
    PHONE_NUMBER1: generatePhoneA(DEFAULT_PHONE)
  };
  if(!phoneNumbers.length) return phoneVariables;
  let uniqueVariableKey = 'PHONE_NUMBER0';

  phoneNumbers.forEach(({ number }) => {
    uniqueVariableKey = variableUniqueKeyGetter(uniqueVariableKey);
    phoneVariables[uniqueVariableKey] = generatePhoneA(number);
  });

  const lastPhoneNumber = phoneVariables[uniqueVariableKey]?.slice(
    0,
    phoneVariables[uniqueVariableKey].length - 1, // remove comma
  );

  return {
    ...phoneVariables,
    [uniqueVariableKey]: lastPhoneNumber,
  };
}

