import { Col, Row } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { ImageUpload } from '../../../components/display/ImageUpload/ImageUpload';
import { Button, Flex, Input } from '../../../components/general';
import { Signature } from '../../../components/general/Signiture/Signature';
import { useAuth } from '../../../contexts/useAuth.context';
import { removeBase64Prefix } from '../../../helpers/base64.helper';
import { withCatch } from '../../../helpers/error.helpers';
import { pickChangedValues } from '../../../helpers/formValueSerilizer';
import { UpdateType } from '../../../helpers/outGroup.helper';
import { IUser } from '../../../types/user.types';
import styles from '../Account.module.css';

type Props = {
  userType: string;
  setEditView: (data: boolean) => void;
  isMobileSize: boolean;
};

export const AccountInfoEdit = ({ userType, setEditView, isMobileSize }: Props) => {
  const { user, refreshUser, isInstructor } = useAuth() as {
    user: IUser;
    refreshUser: () => void;
    isInstructor: () => boolean;
  };

  const editUserMutation = useMutation(UpdateType[userType]);

  const handleEdit = async ({
    tracks,
    password,
    comments,
    authGroup,
    avatar,
    uuid,
    ...genericData
  }: Partial<IUser>) => {
    const updateData = avatar?.includes('https') ? genericData : { ...genericData, avatar };

    await withCatch(() => editUserMutation.mutateAsync({ user: updateData, id: user.id }), {
      onSuccess: () => {
        setEditView(false);
        refreshUser();
      },
    });
  };

  const handleSubmit = (values: IUser) => {
    const { id, ...rest } = values;

    const data = pickChangedValues(user, rest);
    handleEdit(data);
  };

  const handleRemove = () => handleEdit({ avatar: '' });

  return (
    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={user}>
      {(props) => (
        <Form style={{ width: isMobileSize ? '100%' : '77%' }}>
          <div className={styles.mainBar}>
            <Flex
              direction={isMobileSize ? 'column' : 'row'}
              alignItems={isMobileSize ? 'flex-start' : 'center'}
              justifyContent="space-between"
              className={styles.titleBar}
            >
              <p className={styles.title}>Personal Information</p>
              <Flex
                alignItems="center"
                justifyContent={isMobileSize ? 'flex-end' : 'space-between'}
                className={styles.barBtns}
              >
                <Button
                  style={{ minWidth: 115, marginRight: 20 }}
                  htmlType="button"
                  variant="default"
                  onClick={() => setEditView(false)}
                >
                  Cancel
                </Button>
                <Button
                  style={{ minWidth: 130 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Save Changes
                </Button>
              </Flex>
            </Flex>
            <div className={styles.content}>
              <Row>
                {user.hasOwnProperty('firstName') && user.hasOwnProperty('lastName') && (
                  <>
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                      <Field name="firstName" label="Name" component={Input} />
                    </Col>
                    <Col lg={{ span: 7, offset: 1 }} xs={{ span: 24 }}>
                      <Field name="lastName" label="Surname" component={Input} />
                    </Col>
                  </>
                )}
                {user.hasOwnProperty('name') && (
                  <>
                    <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                      <Field name="name" label="Name" component={Input} />
                    </Col>
                  </>
                )}
                <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <ImageUpload name="avatar" placeholder="Upload Avatar" label="Avatar" />
                    <Button
                      style={{
                        maxWidth: 130,
                        height: 48,
                        marginTop: 15,
                        marginLeft: 20,
                      }}
                      htmlType="button"
                      variant="warning"
                      onClick={handleRemove}
                    >
                      Delete Avatar
                    </Button>
                  </Flex>
                </Col>
                {user.hasOwnProperty('linkedInProfile') && (
                  <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                    <Field name="linkedInProfile" label="LinkedIn Profile" component={Input} />
                  </Col>
                )}
                {user.hasOwnProperty('username') && (
                  <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                    <Field name="username" label="Username" component={Input} />
                  </Col>
                )}
                <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                  <Field name="phoneNumber" label="Phone" component={Input} />
                </Col>

                {isInstructor() && (
                  <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                    <div style={{ margin: 'auto' }}>
                      <Signature
                        buttons={{ reset: { text: 'Reset' }, save: { text: 'Apply' } }}
                        label="Signature"
                        onSave={(signatureBase64) => {
                          const signature = removeBase64Prefix(signatureBase64);
                          props.setFieldValue('signatureLink', signature);
                        }}
                      />
                    </div>
                    <ErrorMessage name="signature" component="span" className="error" />
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
