import { IApplicantDetailGeneral } from '../types/applicantDetail.types';
import { ApplicantCreateStatusOptions } from './applicant.constants';
import { ApplicantDetailQueryTypes } from './useQuery.constants';

const {
  APPLICANT_DETAILS_ACTIONS,
  APPLICANT_DETAILS_ATTACHMENTS,
  APPLICANT_DETAILS_COMMENTS,
  APPLICANT_DETAILS_EXAMS,
  APPLICANT_DETAILS_CONSULTATIONS,
  APPLICANT_DETAILS_ABSENTEES,
  APPLICANT_DETAILS_FEEDBACKS,
  APPLICANT_DETAILS_PAYMENTS,
  APPLICANT_DETAILS_PROMO_CODE
} = ApplicantDetailQueryTypes;

export const historyTypes = {
  [APPLICANT_DETAILS_ACTIONS]: 'Actions',
  [APPLICANT_DETAILS_COMMENTS]: 'Comments',
  [APPLICANT_DETAILS_EXAMS]: 'Exams',
  [APPLICANT_DETAILS_ABSENTEES]: 'Absentees',
  [APPLICANT_DETAILS_PAYMENTS]: 'Payments',
  [APPLICANT_DETAILS_FEEDBACKS]: 'Feedbacks',
  [APPLICANT_DETAILS_ATTACHMENTS]: 'Attachments',
  [APPLICANT_DETAILS_CONSULTATIONS]: 'Consultations',
  [APPLICANT_DETAILS_PROMO_CODE]: 'Promo Code',
};

export const generalDefaultData: IApplicantDetailGeneral = {
  name: '',
  groupName: '',
  id: 0,
  created_at: '',
  phoneNumber: '',
  email: '',
  status: ApplicantCreateStatusOptions.APPLIED_ONLY.value,
};
