import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Typography } from 'antd';

import { Flex } from '..';

const { Text } = Typography;

interface IRichEditorValues {
  password: string;
  to: string;
  tags: string[];
  html?: string;
}
interface IRichTextEditorProps {
  onChange: (data: any) => void;
  label: string;
  values?: Partial<IRichEditorValues>;
  required?: boolean;
}

export function RichTextEditor({
  onChange,
  label,
  required = false,
  values = {},
  ...props
}: IRichTextEditorProps) {
  const labelElement = label && (
    <Text
      style={{
        marginBottom: '4px',
      }}
    >
      {label}
    </Text>
  );
  const requiredElement = required && (
    <Text
      style={{
        marginBottom: 4,
        marginLeft: 3,
        color: values.html ? '#646464' : 'red',
      }}
    >
      *
    </Text>
  );
  return (
    <>
      <Flex style={{ marginBottom: '10px' }}>
        {labelElement}
        {requiredElement}
      </Flex>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            '|',
            'undo',
            'redo',
            '|',
            'Italic',
            'Bold',
            'numberedList',
            'bulletedList',
            'blockQuote',
            'link',
          ],
        }}
        data="<p></p>"
        onChange={(event: Event, editor: { getData: () => void }) => {
          onChange(editor.getData());
        }}
        {...props}
      />
    </>
  );
}
