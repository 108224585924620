import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { createUser } from '../../services/user.service';
import { IAppForm } from '../../types/form.types';
import { ICreateUser, IUser } from '../../types/user.types';
import { TransformUserForm } from './shared/transformUser.form';

export const CreateUserForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();

  const createUserMutation = useMutation(createUser);

  const initialValues: Partial<ICreateUser> = {};

  const handleSubmit = async (createdUser: Partial<IUser>) => {
    const mutationFunc = () =>
      createUserMutation.mutateAsync(createdUser as IUser);

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.USERS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={createUserMutation.isLoading}>
      <TransformUserForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isEditForm={false}
      />
    </Spin>
  );
};
