import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from '../../../contexts/useAuth.context';
import {
  RoutesConstants,
  StudentRoutesConstants,
} from '../../../helpers/routes.helpers';
import { isStudentGraduated } from '../helpers/student.helpers';
import Absentees from "../pages/Absentees";
import Agreements from "../pages/Agreements";
import { Attachments } from "../pages/Attachments";
import { Certificate } from '../pages/Certificate';
import { StudentCV } from '../pages/CV';
import { GivenFeedbacks,ReceivedFeedbacks } from "../pages/Feedbacks/";
import Grades from "../pages/Grades";
import Home from '../pages/Home';
import Payments from "../pages/Payments";

const StudentAuthenticatedApp = () => {
  const { user } = useAuth();
  return (
    <Routes>
      <Route path={StudentRoutesConstants.home()} element={<Home/>}>
        <Route
          path={StudentRoutesConstants.absentees()}
          element={<Absentees/>}
        />
        <Route
          path={StudentRoutesConstants.grades()}
          element={<Grades/>}
        />
        <Route
          path={StudentRoutesConstants.receivedFeedbacks()}
          element={<ReceivedFeedbacks/>}
        />
        <Route
          path={StudentRoutesConstants.givenFeedbacks()}
          element={<GivenFeedbacks/>}
        />
        <Route
          path={StudentRoutesConstants.payments()}
          element={<Payments/>}
        />

        <Route
          path={StudentRoutesConstants.agreements()}
          element={<Agreements/>}
        />
        <Route
          path={StudentRoutesConstants.attachments()}
          element={<Attachments/>}
        />
        {
          isStudentGraduated(user) &&
          <Route
            path={StudentRoutesConstants.certificate()}
            element={<Certificate/>}
          />
        }
        <Route
          path={StudentRoutesConstants.cv()}
          element={<StudentCV/>}
        />
      </Route>
      <Route
        path={RoutesConstants.fallback()}
        element={
          <Navigate replace to={StudentRoutesConstants.agreements()}/>
        }
      />
    </Routes>

  );
};

export default StudentAuthenticatedApp;




