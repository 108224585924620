import { Checkbox, Spin, Typography } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { CustomDatePickerFormik, Flex, Input as FormInput, SingleSelectFormik } from '../../../../components/general';
import MessagesConstants from '../../../../constants/messages.constants';
import { useAuth } from '../../../../contexts/useAuth.context';
import { previewStudentAgreement } from '../../../../services/student.service';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  changeAgreementData,
  selectStudentAgreement,
} from '../../../../store/features/student/studentAgreement/studentAgreementSlice';
import { IApplicantPersonalInfo , PaymentMethod } from '../../../../types/applicant.types';
import { SubmitButton } from '../../../shared/components/Buttons/SubmitButton';
import { CitizenshipOptions } from '../../constants/citizenship.constants';
import { handleNameHyError, nameHyErrorGetter, studentNameHyValidator } from '../../helpers/student.helpers';
import { AgreementSchema } from '../schemas/agreement.schema';
import styles from './StudentAgreementForm.module.css';

export interface INameError {
  nameHy: boolean | string,
  studentNameHy: boolean | string,
}


export const StudentAgreementForm = ({ handleNext }: { handleNext: () => void }) => {
  const [nameHyError, setNameHyError] = useState<INameError>({
    nameHy: false,
    studentNameHy: false
  });

  const { Title } = Typography;
  const globalDispatch = useAppDispatch();
  const {
    applicantPersonalInfoRequestModel: {
      citizenship,
      isUnderage,
      contractNumber,
      contractYear,
      address,
      nameHy,
      passportGivenDate,
      passportNumber,
      socialCardNumber,
      studentNameHy,
    },
  } = useAppSelector(selectStudentAgreement);

  const { user  } = useAuth();
  const previewStudentAgreementMutation = useMutation(previewStudentAgreement);

  const initialValues = {
    citizenship,
    isUnderage,
    contractNumber,
    contractYear,
    address,
    nameHy,
    passportGivenDate,
    passportNumber,
    socialCardNumber,
    studentNameHy,
  };

  const handleSubmit = async (applicantPersonalInfoRequestModel: IApplicantPersonalInfo) => {

    const isNameHyValid =  nameHyErrorGetter(applicantPersonalInfoRequestModel.nameHy);
    const isStudentNameHyValid = applicantPersonalInfoRequestModel.isUnderage ?
      nameHyErrorGetter(applicantPersonalInfoRequestModel.studentNameHy || '') : false;

      if(isNameHyValid || isStudentNameHyValid) {
        setNameHyError({
          nameHy: isNameHyValid,
          studentNameHy: isStudentNameHyValid
        });
        return
      }

    const agreementTemplate = await previewStudentAgreementMutation.mutateAsync({
      userId: user?.id as number,
      applicantPersonalInfoRequestModel: {
        ...applicantPersonalInfoRequestModel,
        // @ts-ignore
        paymentMethod: user?.paymentMethod
      },
    });

    globalDispatch(
      changeAgreementData({
        applicantPersonalInfoRequestModel,
        agreementTemplate: agreementTemplate.agreementBuff,
      }),
    );
    handleNext();
  };

  return (
    <Flex justifyContent="center" alignItems="center" direction="column" style={{ width: '100%' }}>
      <div style={{ margin: '10px 0', width: '50%', textAlign: 'center' }}>
        <p style={{ margin: '0' }}>
          {MessagesConstants.agreementAlert} <br />
          {MessagesConstants.agreementAlertArmenian}
        </p>
      </div>

      <Formik<IApplicantPersonalInfo>
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={AgreementSchema}
        initialValues={initialValues}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form className={styles.form}>
            <div style={{ marginTop: 20 }}>
              <label>
                <span style={{ marginRight: 10 }}>Are you an underage?</span>
                <Checkbox
                  name="isUnderage"
                  checked={values.isUnderage}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    if (!isChecked) {
                      setFieldValue('studentNameHy', null);
                    }
                    setFieldValue('isUnderage', isChecked);
                  }}
                />
              </label>
            </div>

            {values.isUnderage && (
              <Title level={4} style={{ margin: '20px 0' }}>
                Trustee
              </Title>
            )}

            {  // @ts-ignore
              user?.paymentMethod === PaymentMethod.AMERIA_CREDIT
              && (
                <>
                  <Field name="contractNumber" label="Ameria Contract Number" component={FormInput}/>
                  <ErrorMessage name="contractNumber" component="span" className="error" />

                  <Field name="contractYear" label="Ameria contract sign date" component={CustomDatePickerFormik}/>
                  <ErrorMessage name="contractYear" component="span" className="error" />
                </>
              )

            }

            <Field label="Citizenship" name="citizenship" component={SingleSelectFormik} options={CitizenshipOptions} />
            <ErrorMessage name="citizenship" component="span" className="error" />

            <FormInput
              name="nameHy"
              placeholder="Name Surname"
              label="Name Surname"
              labelNote="(only armenian letters)"
              value={values.nameHy}
              onChange={(e: any) => {
                let currentValue = e.currentTarget?.value || '';
                setFieldValue('nameHy', studentNameHyValidator(currentValue));
              }}
              onBlur={() => {
                handleNameHyError(values.nameHy, (error) => {
                  setNameHyError({
                    ...nameHyError,
                    nameHy: error
                  })
                });
              }}
            />
            {
              nameHyError.nameHy &&
              <span className={styles.nameHyError}>{nameHyError.nameHy}</span>
            }

            <Field component={FormInput} name="address" label="Registration address" />
            <ErrorMessage name="address" component="span" className="error" />

            <Field
              component={FormInput}
              name="socialCardNumber"
              label="Social card number"
              placeholder="Social card number"
            />
            <ErrorMessage name="socialCardNumber" component="span" className="error" />

            <Field component={FormInput} name="passportNumber" label="Passport number" placeholder="Passport number" />

            <ErrorMessage name="passportNumber" component="span" className="error" />

            <Field
              component={CustomDatePickerFormik}
              name="passportGivenDate"
              label="Passport given date"
              placeholder="Passport given date"
            />
            <ErrorMessage name="passportGivenDate" component="span" className="error" />

            {values.isUnderage && (
              <>
                <Title level={4} style={{ margin: '20px 0' }}>
                  Student
                </Title>
                <FormInput
                  name="studentNameHy"
                  placeholder="Name Surname"
                  label="Name Surname"
                  labelNote="(only armenian letters)"
                  value={values.studentNameHy || ''}
                  onChange={(e: any) => {
                    let currentValue = e.currentTarget?.value || '';
                    setFieldValue('studentNameHy', studentNameHyValidator(currentValue))
                  }}
                  onBlur={() => {
                    handleNameHyError(values.studentNameHy || '', (error) => {
                      setNameHyError({
                        ...nameHyError,
                        studentNameHy: error
                      })
                    });
                  }}
                />
                {
                  nameHyError.studentNameHy &&
                  <span className={styles.nameHyError}>{nameHyError.studentNameHy}</span>
                }
              </>
            )}

            <Flex alignItems="center" direction="column" justifyContent="center" style={{ marginTop: '30px' }}>
              <Spin spinning={previewStudentAgreementMutation.isLoading} wrapperClassName={styles.spinnerWrapper}>
                <SubmitButton text={'Preview'} disabled={!isValid || !!nameHyError.studentNameHy || !!nameHyError.nameHy} />
              </Spin>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
