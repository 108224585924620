import type { ColumnSchema } from '../../types/column.types';
import type { ILesson } from '../../types/lesson.types';
import {
  ActionsLessonColumn,
  DetailsLessonColumn,
  LessonGroupColumn,
  SlackLinkLessonColumn,
  StartDateLessonColumn,
  TimeLessonColumn,
} from './lesson.renderer';

export const defaultLessonColumns: ColumnSchema<ILesson> = {
  uuid: 'defaultLessonColumns',
  schema: {
    startDate: {
      title: 'Date',
      sorter: () => -1,
      render: StartDateLessonColumn,
    },
    id: {
      title: 'Group',
      dataIndex: 'number',
      render: LessonGroupColumn,
    },
    lessonDateTime: {
      title: 'Start Time - End Time',
      render: TimeLessonColumn,
    },
    lessonActions: {
      title: 'Lesson State',
      align: 'center',
      dataIndex: 'id',
      width: 80,
      render: ActionsLessonColumn,
    },
    slackLink: {
      title: 'Slack',
      dataIndex: 'slackLink',
      align: 'center',
      width: 150,
      render: SlackLinkLessonColumn,
    },
    details: {
      title: 'Details',
      width: 50,
      render: DetailsLessonColumn,
    },
  },
};
