import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { absenteesColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IApplicantDetailAbsentees } from '../../../../types/applicantDetail.types';

export function AbsenteesTable({ absentees }: { absentees: IApplicantDetailAbsentees[] }) {
  const table = useTable<IApplicantDetailAbsentees>(absenteesColumns);

  return (
    <Table<IApplicantDetailAbsentees>
      {...table.getTableProps({
        rowKey: ({ id }: IApplicantDetailAbsentees) => id,
        dataSource: absentees,
      })}
    />
  );
}
