export const getExtensionFromString = (str: string) => {
  const lastPartAfterDot = /([^.]*)$/g;
  return lastPartAfterDot.exec(str)?.[0];
};

export const downloadByLink = ({
  link,
  filename = new Date().toString(),
  extension = 'pdf',
}: {
  link: string;
  filename?: string;
  extension?: string;
}) => {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = link;
  anchor.target = '_blank';
  anchor.download = `${filename}.${extension}`;
  document.body.appendChild(anchor);
  anchor.click();
};

export const downloadExcel = (response: any, name: string) => {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
  );
  a.style.display = 'none';
  a.target = '_blank';
  document.body.appendChild(a);
  a.download = `${name}`;
  return a.click();
};
