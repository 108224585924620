export const toBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const removeBase64Prefix = (fileBase64: string) => {
  const prefix = /base64,(.+)/;
  const [, fileWithoutBase64] = fileBase64.match(prefix) || [];

  return fileWithoutBase64;
};
