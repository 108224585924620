import { RouteParam, RoutesConstants } from "../../../../helpers/routes.helpers";
import { AddApplicant } from "./components/actions/addApplicant";
import { ApplicantExport } from "./components/actions/applicantExport";
import { BulkApplicantUpdate } from "./components/actions/bulkApplicantUpdate";
import { MassGroupActions } from "./components/actions/massGroupActions";
import { SendRoomLink } from "./components/actions/sendRoomLink";

export const Widgets = (id: RouteParam) => [
  {
    id: 0,
    title: 'Absentees',
    bgColor: 'blue',
    link: RoutesConstants.groupAbsentees(id),
  },

  {
    id: 1,
    title: 'Exams',
    bgColor: 'violet',
    link: RoutesConstants.groupGrades(id),
  },
  {
    id: 2,
    title: 'Payments',
    bgColor: 'green',
    link: RoutesConstants.groupPayments(id),
  },
  {
    id: 3,
    title: 'Feedbacks',
    bgColor: 'purple',
    link: RoutesConstants.groupFeedbacks(id),
  },
];


export const enrollmentTemplateInitialValues: any = {
  enrollmentModalVisible: false,
  enrollmentModalTemplateBody: '',
  enrollmentModalNewContentView: 'Add content',
};

export const groupActions = [
  {
    id: 0,
    Component: AddApplicant
  },
  {
    id: 1,
    Component: ApplicantExport
  },
  {
    id: 2,
    Component: BulkApplicantUpdate
  },
  {
    id: 3,
    Component: MassGroupActions
  },
  {
    id: 4,
    Component: SendRoomLink
  },
]
