import { RelatedToTypes, TaskStatusEnum, TaskTypeEnum } from '../types/task.types';

export const TASK_TYPE = {
  [TaskTypeEnum.FIRST_CONTACT_PHASE]: {
    label: 'First contact phase',
    value: TaskTypeEnum.FIRST_CONTACT_PHASE,
    relatedTo: RelatedToTypes.APPLICANT,
  },
  [TaskTypeEnum.EXAM_STAGE]: {
    label: 'Exam stage',
    value: TaskTypeEnum.EXAM_STAGE,
    relatedTo: RelatedToTypes.APPLICANT,
  },
  [TaskTypeEnum.INTERVIEW_STAGE]: {
    label: 'Interview stage',
    value: TaskTypeEnum.INTERVIEW_STAGE,
    relatedTo: RelatedToTypes.APPLICANT,
  },
  [TaskTypeEnum.GROUP]: {
    label: 'Group',
    value: TaskTypeEnum.GROUP,
    relatedTo: RelatedToTypes.GROUP,
  },
  [TaskTypeEnum.APPLICANT]: {
    label: 'Applicant',
    value: TaskTypeEnum.APPLICANT,
    relatedTo: RelatedToTypes.APPLICANT,
  },
  [TaskTypeEnum.OTHER]: {
    label: 'Other',
    value: TaskTypeEnum.OTHER,
    relatedTo: RelatedToTypes.OTHER,
  },
} as const;

export const TASK_STATUS = {
  [TaskStatusEnum.DONE]: { label: 'Done', value: TaskStatusEnum.DONE },
  [TaskStatusEnum.TO_DO]: { label: 'To do', value: TaskStatusEnum.TO_DO },
  [TaskStatusEnum.IN_PROGRESS]: {
    label: 'In progress',
    value: TaskStatusEnum.IN_PROGRESS,
  },
  [TaskStatusEnum.WAITING_FOR_INPUT]: {
    label: 'Waiting for input',
    value: TaskStatusEnum.WAITING_FOR_INPUT,
  },
} as const;
