import { ExamProcessType, ExamSource, ExamTypeEnum, IExamGradingType, IGradingType } from '../types/exam.types';

export const EXAM_TYPE_OPTIONS = {
  [ExamTypeEnum.FINAL]: {
    label: 'Final',
    value: ExamTypeEnum.FINAL,
  },
  [ExamTypeEnum.MIDTERM]: {
    label: 'Midterm',
    value: ExamTypeEnum.MIDTERM,
  },
} as const;

export const gradingType: { [key: string]: IGradingType } = {
  [IExamGradingType.IN_PROCESS]: {
    text: 'In process',
    type: IExamGradingType.IN_PROCESS,
  },
  [IExamGradingType.SUCCESS]: {
    text: 'Success',
    type: IExamGradingType.SUCCESS,
  },
  [IExamGradingType.FAIL]: {
    text: 'Fail',
    type: IExamGradingType.FAIL,
  },
} as const;

export const ExamSourceOptions = {
  [ExamSource.CODE_SIGNAL]: {
    label: 'Code Signal',
    value: ExamSource.CODE_SIGNAL,
  },
  [ExamSource.TEST_PORTAL]: {
    label: 'Test Portal',
    value: ExamSource.TEST_PORTAL,
  },
} as const;

export const ExamProcessTypeOptions = {
  [ExamProcessType.MANUAL]: {
    label: 'Manual',
    value: ExamProcessType.MANUAL,
  },
  [ExamProcessType.AUTOMATED]: {
    label: 'Automated',
    value: ExamProcessType.AUTOMATED,
  },
} as const;
