import 'nprogress/nprogress.css';

import axios, { AxiosResponse } from 'axios';
import NProgress from 'nprogress';

import { loadState } from '../helpers/localStorage.helpers';
import { signOutByOutGroup } from "./helper.service";

const logOutOnTokenExpire = async (response: AxiosResponse) => {
  const token = localStorage.getItem('token');
  if (token) {
    const authGroup = loadState('AuthGroup');
    authGroup && await signOutByOutGroup(authGroup);
    localStorage.clear();
    return window.location.reload();
  }
  return response;
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common = {
  Authorization: `Bearer ${loadState('token')}`,
};

const calculatePercentage = (loaded: number, total: number) => {
  return Math.floor(loaded) / total;
};

axios.defaults.onDownloadProgress = (e) => {
  const percentage = calculatePercentage(e.loaded, e.total);
  NProgress.set(percentage);
};

axios.interceptors.response.use(
  (response) => {
    NProgress.done(true);
    return response;
  },
  (error) => {
    NProgress.done(true);
    if (error.response?.status === 401) {
      logOutOnTokenExpire(error);
    }

    return Promise.reject(
      error.response
        ? {
            code: error.response.status,
            data: error.response.data,
          }
        : { message: error },
    );
  },
);

export default axios;
