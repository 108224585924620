import { IBulkUpdateStudentsFormData, IStudentBrief, IUpdateStudent } from '../../types/applicant.types';

export const applicantBulkUpdateNormalizer = (
  formData: Partial<IBulkUpdateStudentsFormData>,
  data: IStudentBrief[],
): Partial<IUpdateStudent>[] =>
  data.map((applicant: any) => {
    const applicantMapping: Partial<IUpdateStudent> = {
      groupId: formData.groupId === -1 ? null : formData.groupId,
      courseId: formData.courseId,
      id: applicant.id,
      scheduledDate: formData.scheduledDate,
      status: formData.status,
    };

    return applicantMapping;
  });
