import { DateService } from "../../../services/date.service";
import { IStudentGradeTableData } from "../types/studentGrade.types";
import { StudentTableBodySchema } from "../types/studentTable.types";

export const GradesTableHeaderSchema = {
  group: {
    title: 'Group',
    key: 'group',
  },
  type: {
    title: 'Type',
    key: 'type',
  },
  grade: {
    title: 'Grade',
    key: 'grade',
  },
  date: {
    title: 'Date',
    key: 'date',
  },
};


export const getGradesSchema = ({ group, type, grade, date }: IStudentGradeTableData): StudentTableBodySchema =>
  ({
      group: { renderer: () => group },
      type: { renderer: () => type },
      grade: { renderer: () => grade },
      date: { renderer: () => DateService.getFullDate(date) }
    }
  )
