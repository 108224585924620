import { Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FieldHookConfig, useField } from 'formik';
import React, { useMemo } from 'react';
import Select, { components } from 'react-select';

import { Flex } from '..';

export type IOption = { label: string; value: string | number };

export interface IMultiselect {
  options: Record<string, IOption>;
  name: string;
  onChange(value: IOption['value'][] | null): void;
  onBlur?(): void;
  label?: string;
  value?: IOption['value'][];
  defaultValue?: IOption;
  required?: boolean;
}

// @TODO: replace any
const Control: React.FC<any> = ({ children, ...props }) => {
  const { onSelectAll, isAllSelected } = props.selectProps;

  const handleChange = (e: CheckboxChangeEvent) => {
    if (onSelectAll) onSelectAll(e.target.checked);
  };

  return (
    <components.Control {...props}>
      <Checkbox
        onChange={handleChange}
        checked={isAllSelected}
        style={{ marginLeft: '8px' }}
      />
      {children}
    </components.Control>
  );
};

export const Multiselect: React.FC<IMultiselect> = ({
  options = {},
  name,
  onChange,
  label,
  value,
  required = false,
  ...props
}) => {
  const { Text } = Typography;
  const defaultValue = useMemo(() => {
    if (!value) return [];
    return value.map((val) => options[val]);
  }, [value, options]);

  const labelElement = label && (
    <Text
      style={{
        marginBottom: '4px',
      }}
    >
      {label}
    </Text>
  );
  const requiredElement = required && (
    <Text
      style={{
        marginBottom: 4,
        marginLeft: 3,
        color: defaultValue.length ? '#646464' : 'red',
      }}
    >
      *
    </Text>
  );

  return (
    <div style={{ margin: '5px 0' }}>
      <Flex>
        {labelElement}
        {requiredElement}
      </Flex>
      <Select
        styles={{
          input: (providedValue) => ({
            ...providedValue,
            padding: '7px 1px',
          }),
          placeholder: (providedValue) => ({
            ...providedValue,
            margin: '0 4px',
          }),
        }}
        name={name}
        options={Object.values(options)}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        value={defaultValue}
        onChange={(selected) => {
          const selectedValues = (selected || []).map(({ value }) => value);
          onChange(selectedValues);
        }}
        isAllSelected={defaultValue.length === Object.values(options).length}
        onSelectAll={(select: boolean) => {
          if (select) {
            const allValues = Object.values(options).map(({ value }) => value);
            onChange(allValues);
          } else {
            onChange([]);
          }
        }}
        components={{ Control }}
        {...props}
      />
    </div>
  );
};

export const MultiselectFormik: React.FC<
  IMultiselect & FieldHookConfig<string>
> = ({ onChange, name, options, ...props }) => {
  // @TODO: figure out type
  // @ts-ignore
  const [field, , helpers] = useField(props.field);
  return (
    <Multiselect
      {...props}
      name={name}
      value={field.value}
      options={options}
      onChange={(selectedValues) => {
        helpers.setValue(selectedValues);
      }}
      onBlur={() => {
        helpers.setTouched(true);
        props.onBlur && props.onBlur();
      }}
    />
  );
};
