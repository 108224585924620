import type { ColumnSchema } from '../../types/column.types';
import { Sms } from "../../types/sms.types";
import { DateSmsColumn } from "./sms.renderer";

export const defaultSmsColumns: ColumnSchema<Sms> = {
  uuid: 'defaultSmsColumns',
  schema: {
    createdAt: {
      dataIndex: 'created_at',
      title: 'Date',
      sorter: () => -1,
      width: 250,
      render: DateSmsColumn
    },
    template: {
      dataIndex: 'template',
      title: 'Template',
    },
    recipients: {
      dataIndex: 'recipients',
      title: 'Recipients',
    },
    sender: {
      dataIndex: 'sender',
      title: 'Sender',
    },
  },
};
