export enum AttachmentTypeEnum {
  AGREEMENT = 'AGREEMENT',
  CERTIFICATE = 'CERTIFICATE',
  IMAGE = 'IMAGE',
  INVOICE = 'INVOICE',
  OTHER = 'OTHER',
  CV = 'CV'
}

export type AttachmentType = keyof typeof AttachmentTypeEnum;

export interface ApplicantAttachmentResponse {
  attachmentId: number;
  attachmentType: AttachmentType;
  name: string;
  link: string;
}

export interface ApplicantAttachmentRequest {
  applicantId: number;
  file: File;
  attachment: File,
  attachmentType: AttachmentType;
  name: string;
}

export type LabelNotes = {
  [key: string]: string
}
