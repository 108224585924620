import { Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import CustomDateAndTimePicker from '../../components/display/CustomTimePicker/CustomTimePicker';
import { Button, Flex, SingleSelectFormik } from '../../components/general';
import { Tag } from '../../components/general/Tag/Tag';
import { ApplicantCreateStatusOptions } from '../../constants/applicant.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { applicantBulkUpdateNormalizer } from '../../helpers/applicants/applicantBulkUpdateNormalizer';
import { withCatch } from '../../helpers/error.helpers';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { bulkUpdateApplicants } from '../../services/applicant.service';
import { getCourses } from '../../services/courses.service';
import { useAppSelector } from '../../store';
import { selectItems } from '../../store/features/sharedSelects/sharedSelectsSlice';
import type { IUpdateStudent } from '../../types/applicant.types';
import { IStudentBrief } from '../../types/applicant.types';
import type { IAppForm } from '../../types/form.types';
import { BulkUpdateStudentsFormSchema } from './schemas/bulkUpdateStudents.form.schema';
import { GroupField } from './shared/GroupField';

const initialValues = { courseId: undefined, groupId: undefined, status: undefined };

export const BulkUpdateApplicantForm: IAppForm = () => {
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();
  const selectedApplicants = useAppSelector(selectItems);
  const cache = useQueryClient();

  const { closeDrawer } = useDrawer();

  const bulkUpdateMutation = useMutation(bulkUpdateApplicants);

  const handleBulkUpdateState = async (
    applicantState: Partial<IUpdateStudent>,
    formikHelpers: FormikHelpers<Partial<IUpdateStudent>>,
  ) => {
    const updateQuery: Partial<IUpdateStudent>[] = applicantBulkUpdateNormalizer(applicantState, selectedApplicants);

    await withCatch(() => bulkUpdateMutation.mutateAsync(updateQuery), {
      onSuccess: async () => {
        formikHelpers.resetForm();
        await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        closeDrawer();
      },
      onError: () => {
        closeDrawer();
      },
    });
    await rowSelectionAdapter.clean();
  };

  const coursesQuery = useQuery(UseQueryTypes.COURSES, getCourses);

  const coursesContext = useMemo(() => {
    return generateContext(coursesQuery.data || [], (course) => ({
      label: course.name,
      value: course.id,
      uuId: course.uuid,
    }));
  }, [coursesQuery.data]);

  const showDate = (props: FormikValues) => {
    return (
      props.values.status === ApplicantCreateStatusOptions.EXAM_SCHEDULED.value ||
      props.values.status === ApplicantCreateStatusOptions.INTERVIEW_SCHEDULED.value
    );
  };

  return (
    <Spin spinning={bulkUpdateMutation.isLoading}>
      <Formik
        initialValues={initialValues}
        validationSchema={BulkUpdateStudentsFormSchema}
        enableReinitialize
        onSubmit={handleBulkUpdateState}
      >
        {({ values, dirty, isValid, handleReset, setFieldValue }) => (
          <Form
            name="bulk-update"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field
              label="Applicant Status"
              name="status"
              component={SingleSelectFormik}
              options={ApplicantCreateStatusOptions}
            />

            {showDate({ values }) && (
              <Row align="middle">
                <CustomDateAndTimePicker
                  label="Enter schedule date"
                  defaultValue={values.scheduledDate}
                  getDate={(date) => {
                    setFieldValue('scheduledDate', date);
                  }}
                />

                <ErrorMessage name="scheduledDate" component="span" className="error" />
              </Row>
            )}

            <Field label="Course" name="courseId" component={SingleSelectFormik} options={coursesContext} />
            <ErrorMessage name="courseId" component="span" className="error" />

            <div style={{ pointerEvents: !values.courseId ? 'none' : 'auto' }}>
              <GroupField name="groupId" />
            </div>
            {selectedApplicants.map((applicant) => (
              <Tag style={{ marginTop: '10px' }} key={applicant.id}>
                {applicant.email}
              </Tag>
            ))}
            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => handleReset()}>
                Reset
              </Button>
              <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary" disabled={!(dirty && isValid)}>
                Save
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
