import { IApplicantPersonalInfo } from '../../../types/applicant.types';

export interface IAgreementProcessRenderer {
  nextProcessState: () => void;
  backProcessState?: () => void;
}

export interface IAgreementProcess {
  id: number;
  state: number;
  text: string;
  renderer: (props: IAgreementProcessRenderer) => JSX.Element;
}

export interface IAgreementGenerateRequest {
  applicantPersonalInfoRequestModel: IApplicantPersonalInfo;
  applicantSignature: string;
}

export interface IAgreementGenerateRequestWithUserId extends IAgreementGenerateRequest {
  userId: number | string;
}

export interface IAgreementPreviewRequest {
  userId: number | string;
  applicantPersonalInfoRequestModel: IApplicantPersonalInfo;
}

export interface IAgreementGlobalState {
  applicantPersonalInfoRequestModel: IApplicantPersonalInfo;
  agreementTemplate: Buffer | Uint8Array;
}

export enum Citizenship {
  ARMENIA = 'ARMENIA',
  RUSSIA = 'RUSSIA',
  USA = 'USA',
  GERMANY = 'GERMANY',
  AUSTRIA = 'AUSTRIA',
  GREECE = 'GREECE',
  NETHERLANDS = 'NETHERLANDS',
  IRAN = 'IRAN',
  GEORGIA = 'GEORGIA',
  LEBANON = 'LEBANON',
  SYRIA = 'SYRIA'
}
