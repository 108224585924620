import React from 'react';

import { Badges } from '../../../../../../../components/display';
import { TBadge } from '../../../../../../../components/display/Badges/Badges';
import { WedgedTypes } from '../../../../../../../types/general.types';

export const Statistics = ({ badges, wedgedType }: { badges: TBadge[], wedgedType: WedgedTypes }) => {
  return (
    <div>
      {badges && (
        <Badges
          wedgedType={wedgedType}
          badges={Object.entries(badges).map((i) => i[1]) as TBadge[]}
        />
      )}
    </div>
  );
}
