import {Button} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";

import styles from "../../ForgetPassword/ForrgetPassword.module.css";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <h2 className={styles.title}>Your password has been changed successfully!</h2>
      <Button type="link" style={{textDecoration: 'underline'}} block onClick={()=> navigate('/')}>
        Continue to login again
      </Button>
    </>
  );
};

export default ResetPasswordSuccess;
