import { Outlet, useLocation } from 'react-router-dom';

import { InstructorRoutesConstants } from '../../../helpers/routes.helpers';
import SharedLayout from '../../shared/components/Layout/Layout';
import { getPathData } from '../../shared/helpers/path.helpers';
import { InstructorDashBoardLinks } from '../constants/tutorDashboardLinks.constants';
import Dashboard from './Dashboard';

export default function Home() {
  const { pathname } = useLocation();

  const title = getPathData({ path: pathname, dashboardLinks: InstructorDashBoardLinks, defaultLink: 'ongoingGroups' });

  const isPathnameEmpty = pathname === InstructorRoutesConstants.home();

  return (
    <SharedLayout title={title}>
      <>
        <Outlet />
        {isPathnameEmpty ? <Dashboard /> : null}
      </>
    </SharedLayout>
  );
}
