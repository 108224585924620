import { Col } from 'antd';
import React, { useState } from 'react';

import { Button } from '../../../../../../components/general';
import AddApplicantToGroupModal from '../../../../../../forms/applicantForms/addApplicantToGroup/addApplicantToGroup';

export const AddApplicant = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleAddApplicantToGroup = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Col>
        <Button variant="primary" onClick={handleAddApplicantToGroup}>
          Add applicants
        </Button>
      </Col>
      {modalOpen && <AddApplicantToGroupModal modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </>
  )
}
