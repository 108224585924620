import { Col, Divider, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ColumnModal } from '../../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../../components/display/ResultSummary/ResultSummary';
import { Button, FilterButton, Flex,Icons } from '../../../components/general';
import { statisticsDate } from '../../../constants/schedule.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useDrawer } from '../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../forms';
import { RoutesConstants } from '../../../helpers/routes.helpers';
import { useTable } from '../../../hooks/table/useTable';
import { useApplicantStatistic } from '../../../hooks/useApplicantStatistic.hooks';
import { useColumnModalAdapter } from '../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { applicantByCourseColumns } from '../../../schemas/applicants/applicantsByCourse.schema';
import {
  filterApplicantByCourse,
  getApplicantCountByDateInterval,
} from '../../../services/applicant.service';
import type {
  FilterApplicantByCourseOptions,
  IStudentByCourse,
} from '../../../types/applicant.types';
import { SortDirectionEnum } from '../../../types/general.types';
import { ApplicantsStatistics } from '../components/applicantsStatistics';

const defaultFilterApplicantByCourseOptions: Partial<FilterApplicantByCourseOptions> =
  {
    dateFrom: statisticsDate.startOfTheMonth,
    dateTo: statisticsDate.endOfTheMonth,
    sortDirection: SortDirectionEnum.DESC,
    sortBy:'applicantCount',
    relationalSort: true
  };

export default function ApplicationsByCourse() {
  const navigate = useNavigate();
  const studentTable = useTable<IStudentByCourse>(applicantByCourseColumns);
  const modalAdapter = useColumnModalAdapter(
    studentTable.getTableColumnSettings(),
  );

  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions, resetOptions } = useFilter(
    defaultFilterApplicantByCourseOptions,
  );

  const { term: filterTerm, secondaryTerm, ...tail } = filterOptions;

  const applicantsByCourse = useQuery(
    [UseQueryTypes.APPLICANTS_BY_COURSE, filterOptions, sorterOptions],
    () => filterApplicantByCourse(filterOptions, sorterOptions),
  );

  const handleOpenApplicantFilterForm = () =>
    openDrawer(AppFormsEnum.FilterApplicantForm, 'Filter Applicant');

  const handleOpenCreateApplicantForm = () => {
    openDrawer(AppFormsEnum.CreateApplicantForm, 'Create Applicant');
  };

  const handleReset = () => {
    resetOptions();
  };

  const { today, thisWeek, thisMonth } = useApplicantStatistic(
    getApplicantCountByDateInterval,
    tail,
  );

  const applicantsTotalSize = applicantsByCourse.data?.totalElements ?? 0;
  const isScreenLoading = applicantsByCourse.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row justify="start" gutter={8}>
              <Col span={13}>
                <Row gutter={[8, 8]} align="middle">
                  <Flex alignItems="center" style={{ gap: '0 10px' }}>
                    <FilterButton onClick={handleOpenApplicantFilterForm}/>
                    <Button onClick={handleReset}>Reset Filters</Button>
                  </Flex>
                  <Col>
                    <Button
                      onClick={() => navigate(RoutesConstants.applicants())}
                    >
                      View applicants
                    </Button>
                  </Col>
                </Row>
              </Col>
              <ApplicantsStatistics
                today={today ?? 0}
                thisWeek={thisWeek ?? 0}
                thisMonth={thisMonth ?? 0}
              />
            </Row>
            <Divider style={{ margin: '16px 0px' }}/>
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between">
              <ResultSummary text={`${applicantsTotalSize} result found`}/>
              <Row>
                <Col>
                  <Button
                    onClick={handleOpenCreateApplicantForm}
                    variant="primary"
                    icon={<Icons.Add/>}
                  >
                    Add a new Applicant
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>

          <Col span={24}>
            <Table<IStudentByCourse>
              {...studentTable.getTableProps()}
              rowKey={(course) => course.courseId}
              dataSource={applicantsByCourse.data?.applicantsByCourse}
              style={{ marginTop: 30 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
