import { DateService } from '../../services/date.service';
import type { ColumnSchema } from '../../types/column.types';
import type { IConsultant } from '../../types/consultation.types';
import {
  CommentConsultantColumn,
  CompleteConsultantColumn,
  EditConsultantColumn,
  NameConsultantColumn,
  PhoneApplicantColumn,
  RegistrationSourceColumn,
} from './consultant.renderer';

export const defaultConsultantColumns: ColumnSchema<IConsultant> = {
  uuid: 'defaultConsultantColumns',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      width: 100,
      sorter: () => -1,
      render: (_, student) => DateService.getFullDate(student.created_at),
    },
    name: {
      title: 'Name',
      width: 200,
      render: NameConsultantColumn,
    },
    phoneNumber: {
      title: 'Phone',
      width: 150,
      render: PhoneApplicantColumn,
    },

    comment: {
      title: 'Comment',
      width: 50,
      render: CommentConsultantColumn,
    },
    isDone: {
      title: 'Completed',
      width: 100,
      render: CompleteConsultantColumn,
    },
    source: {
      title: 'Source',
      width: 150,
      render: RegistrationSourceColumn,
    },
    edit: {
      title: 'Edit',
      width: 50,
      render: EditConsultantColumn,
    },
  },
};
