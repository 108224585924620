import { Badges } from '../../../components/display';
import { TBadge } from '../../../components/display/Badges/Badges';
import { WedgedDates } from '../../../constants/wedged.constants';
import { WedgedTypes } from '../../../types/general.types';

type Props = {
  today: number;
  thisWeek: number;
  thisMonth: number;
}

export const ApplicantsStatistics = ({
  today,
  thisWeek,
  thisMonth,
}: Props) => {

  const badges: TBadge[]  = [
    {
      data: today,
      title: 'Today',
      name: WedgedDates.TODAY
    },
    {
      data: thisWeek,
      title: 'This Week',
      name: WedgedDates.THIS_WEEK
    },
    {
      data: thisMonth,
      title: 'This month',
      name: WedgedDates.THIS_MONTH
    },
  ]
  return (
    <Badges
      wedgedType={WedgedTypes.DATE}
      badges={badges}
    />
  );
};
