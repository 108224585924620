import { updateInstructor } from '../services/instructors.service';
import { updateUser } from '../services/user.service';
import { IUser } from '../types/user.types';

export const UpdateType: {
  [key: string]: ({
    user,
    id,
  }: {
    user: Partial<IUser>;
    id: number;
  }) => Promise<any>;
} = {
  USER: updateUser,
  INSTRUCTOR: updateInstructor,
};
