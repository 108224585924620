import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { useMobileMenu } from '../../../../../../contexts/context';
import { IDashboardLinkItem } from '../../../../types/path.types';

interface ISimpleLinkProps {
  item: IDashboardLinkItem;
  isGroupItem?: boolean;
}

export const SimpleLink = ({ item: { id, name, path }, isGroupItem=false }: ISimpleLinkProps) => {
  const { pathname } = useLocation();
  const [, setIsMobileMenuVisible] = useMobileMenu();

  return <Link
    onClick={() => setIsMobileMenuVisible(false)}
    key={id}
    className={cn({
      active: path === pathname,
      'dropdown-nav-item': isGroupItem,
      'shared-nav-link': !isGroupItem,
    })}
    to={path || '#'}
  >
    {name}
  </Link>
};
