import PaymentsRenderer from "../components/Payments/PaymentsRenderer";

const Payments = () => {
  return (
    <div>
      <PaymentsRenderer/>
    </div>
  );
};

export default Payments;

