import { useQuery, useQueryClient } from 'react-query';

import { statisticsDate } from '../constants/schedule.constants';
import { UseQueryTypes } from '../constants/useQuery.constants';
import { IApplicantCountRequest } from '../types/applicant.types';
import { FilterOptions } from '../types/general.types';

export const useApplicantStatistic = (
  queryFn: (data: IApplicantCountRequest) => void,
  filterOptions: Partial<FilterOptions>,
) => {
  const cache = useQueryClient();

  const applicantsToday = useQuery(
    [UseQueryTypes.APPLICANTS_TODAY, filterOptions],
    () =>
      queryFn({
        ...filterOptions,
        dateFrom: statisticsDate.today,
        dateTo: statisticsDate.tomorrow,
      }),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.APPLICANTS_TODAY, data);
      },
    },
  );

  const applicantsThisWeek = useQuery(
    [UseQueryTypes.APPLICANTS_THIS_WEEK, filterOptions],
    () =>
      queryFn({
        ...filterOptions,
        dateFrom: statisticsDate.startOfTheWeek,
        dateTo: statisticsDate.endOfTheWeek,
      }),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.APPLICANTS_THIS_WEEK, data);
      },
    },
  );

  const applicantsThisMonth = useQuery(
    [UseQueryTypes.APPLICANTS_THIS_MONTH, filterOptions],
    () =>
      queryFn({
        ...filterOptions,
        dateFrom: statisticsDate.startOfTheMonth,
        dateTo: statisticsDate.endOfTheMonth,
      }),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.APPLICANTS_THIS_MONTH, data);
      },
    },
  );

  return {
    today: applicantsToday.data,
    thisWeek: applicantsThisWeek.data,
    thisMonth: applicantsThisMonth.data,
  };
};
