import { stringifyObject } from '../helpers/stringifyObj.helper';
import type { IApplicantAgreement } from '../types/agreement.types';
import type {
  ICreateStudent,
  IFilterStudent,
  IFilterStudentByCourse,
  IStudent,
  IStudentBrief,
  IStudentFilterResponse,
  IStudentResponseByCourse,
  IUpdateStudent,
} from '../types/applicant.types';
import {
  IApplicantCountByCriteriaRequest,
  IApplicantCountRequest,
} from '../types/applicant.types';
import { ApplicantAttachmentRequest } from '../types/attachment.types';
import type { IComment, ICreateComment } from '../types/comment.types';
import type { ICreateFeedback, IFeedback } from '../types/feedback.types';
import type { Sorters } from '../types/general.types';
import axios from './config.service';

export async function createApplicant(applicant: ICreateStudent) {
  const r = await axios.post('/applicant/create', applicant);
  return r.data as Promise<IStudent>;
}

export function deleteApplicantById(id: number) {
  return axios.delete(`/applicant/${id}`).then((r) => r.data) as Promise<boolean>;
}

export async function updateApplicant({ applicant, id }: { id: number; applicant: Partial<IUpdateStudent> }) {
  const r = await axios.put(`/applicant/${id}`, applicant);
  return r.data as Promise<IStudent>;
}

export async function bulkUpdateApplicants(applicants: Partial<IUpdateStudent>[]) {
  const r = await axios.put(`/applicant/bulk`, applicants);
  return r.data as Promise<IStudent[]>;
}

export const filterApplicantByCourse = async (criterias: Partial<IFilterStudentByCourse>, query: Partial<Sorters>) => {
  const queryString = stringifyObject(query);
  const r = await axios.post(`/applicant/course/statistics?${queryString}`, criterias);
  return r.data as Promise<IStudentResponseByCourse>;
};

export async function filterApplicant(criterias: Partial<IFilterStudent>, query: Partial<Sorters>) { // Todo
  const queryString = stringifyObject(query);
  const r = await axios.post(`/applicant/filter?${queryString}`, criterias);
  return r.data as Promise<IStudentFilterResponse>;
}

export const getApplicantById = async (id: number) => {
  const r = await axios.get(`/applicant/${id}`);
  return r.data as Promise<IStudentBrief>;
};

export const getApplicantByUuid = async (uuid: string | undefined) => {
  const r = await axios.get(`/applicant/${uuid}`);
  return r.data as Promise<IStudent>;
};

export function getApplicantCountByDateInterval(data: IApplicantCountRequest) {
  return axios.post(`/applicant/count`, data).then((r) => r.data) as Promise<number>;
}

export function getApplicantCountByCriteriaList(criteriaList :IApplicantCountByCriteriaRequest[]) {
  return axios.post(`/applicant/count/byCriteria`, { criteriaList }).then((r) => r.data) as Promise<{[key: string]: number}>;
}

export async function addApplicantComment({ applicantId, comment }: { applicantId: number; comment: ICreateComment }) {
  const r = await axios.post(`/applicant/${applicantId}/comment`, comment);
  return r.data as Promise<IComment[]>;
}

export async function updateApplicantComment({ commentId, comment }: { commentId: number; comment: ICreateComment }) {
  const r = await axios.put(`/applicant/comment/${commentId}`, comment);
  return r.data as Promise<IComment[]>;
}

type UpdateApplicantAbsentees = {
  applicantId: number;
  lessonId: number;
};

export async function createApplicantAbsentee({ applicantId, lessonId }: UpdateApplicantAbsentees) {
  return axios.post('/absentees', { applicantId, lessonId });
}

export async function deleteApplicantAbsentee({ applicantId, lessonId }: UpdateApplicantAbsentees) {
  return axios.put(`/absentees`, { applicantId, lessonId });
}

type UploadFile = {
  file: File;
};
export const uploadApplicantData = async ({ file }: UploadFile) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);

  const r = await axios.post('/dataImport/applicant/upload/csv', formData);

  return r.data as Promise<IStudent[]>;
};

export const uploadApplicantAttachments = async (applicantAttachmentData: ApplicantAttachmentRequest) => {
  const { applicantId, ...attachmentDataToSend } = applicantAttachmentData;

  const r = await axios.post(`/applicant/${applicantId}/attachment/`, attachmentDataToSend);

  return r.data;
};

type AgreementRequestPayload = {
  agreementPayload: IApplicantAgreement;
  applicantUuid: string;
};

export const generateApplicantAgreement = async ({ agreementPayload, applicantUuid }: AgreementRequestPayload) => {
  const r = await axios.post(`/applicant/generate/agreement/${applicantUuid}`, agreementPayload);
  return r.data.agreementUrl as Promise<string>;
};

export const bulkSendApplicantAgreementLink = (applicants: IStudent[], mapApplicantToAgreement: any) => {
  return Promise.all(applicants.map(mapApplicantToAgreement));
};

export const bulkCreateApplicants = async (applicants: ICreateStudent[]) => {
  const r = await axios.post(`/applicant/create/bulk`, applicants);
  return r.data;
};

export const getApplicantAttachment = async (id: number) => {
  const r = await axios.get(`applicant/${id}/attachments`);
  return r.data;
};

export const exportApplicants = async (ids: number[]) => {
  return await axios.post(
    `applicant/export`,
    { id: ids },
    {
      responseType: 'blob',
    },
  );
};

export const exportApplicantPaymentsInvoice = async (data: { dateFrom?: Date; dateTo?: Date }) => {
  return await axios.post(`applicant/exportInvoice`, data, {
    responseType: 'blob',
  });
};

export function deleteApplicantCommentById(id: number) {
  return axios.delete(`/applicant/comment/${id}/`).then((r) => r.data) as Promise<boolean>;
}

export function getApplicantComments(id: number) {
  return axios.get(`/applicant/${id}/comments/`).then((r) => r.data) as Promise<any>;
}

export async function addApplicantFeedback({
  applicantId,
  feedback,
}: {
  applicantId: number;
  feedback: ICreateFeedback;
}) {
  const r = await axios.post(`/applicant/${applicantId}/feedback`, feedback);
  return r.data as Promise<IFeedback[]>;
}

export function getApplicantFeedbacks(id: number) {
  return axios.get(`/applicant/${id}/feedbacks/`).then((r) => r.data) as Promise<IFeedback[]>;
}
