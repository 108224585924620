import { FilterUserOptions, UserRole, UserTeamPosition } from '../types/user.types';

export const USER_ROLES = {
  [UserRole.ROLE_ADMIN]: {
    label: 'Admin',
    value: UserRole.ROLE_ADMIN,
  },
  [UserRole.ROLE_MODERATOR]: {
    label: 'Moderator',
    value: UserRole.ROLE_MODERATOR,
  },
  [UserRole.ROLE_PROJECT_COORDINATOR]: {
    label: 'Project Coordinator',
    value: UserRole.ROLE_PROJECT_COORDINATOR,
  },
  [UserRole.ROLE_SALES_SUPPORT_SPECIALIST]: {
    label: 'Sales Support Specialist',
    value: UserRole.ROLE_SALES_SUPPORT_SPECIALIST,
  },
  [UserRole.ROLE_PROGRAM_MANAGER]: {
    label: 'Program Manager',
    value: UserRole.ROLE_PROGRAM_MANAGER,
  },
};

export const USER_TEAM_POSITION = {
  [UserTeamPosition.TEAM_MEMBER]: {
    label: 'Team member',
    value: UserTeamPosition.TEAM_MEMBER,
  },
  [UserTeamPosition.TEAM_LEAD]: {
    label: 'Team lead',
    value: UserTeamPosition.TEAM_LEAD,
  },
};

export const defaultFilterUserOptions: Partial<FilterUserOptions> = {
  roles: [],
};
