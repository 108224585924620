import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { consultationColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IConsultant } from '../../../../types/consultation.types';

export function ConsultationTable({ consultations }: { consultations: IConsultant[] }) {
  const table = useTable<IConsultant>(consultationColumns);

  return (
    <Table<IConsultant>
      {...table.getTableProps({
        rowKey: ({ id }: IConsultant) => id,
        dataSource: consultations,
      })}
    />
  );
}
