import { useEffect, useState } from 'react';
import StarRatingComponent from 'react-star-rating-component';

import { Star } from '../Icons/Icons';

interface IProps {
  name: string;
  value: number;
  starCount: number;
  onStarClick(nextValue: number, prevValue: number, name: string): void;
  renderStarIcon?(nextValue: number, prevValue: number, name: string): JSX.Element | string;
}
export default function RatingStars({ name, value, starCount, onStarClick }: IProps): JSX.Element {
  const [rating, setRating] = useState(value);

  const handleClick: IProps['onStarClick'] = (nextValue: number, prevValue: number, name: string) => {
    setRating(nextValue);
  };

  useEffect(() => onStarClick(rating, 0, name), [rating]);

  const renderStarByParam = (index: number, value: number) => {
    const isActive = index <= value;

    return <Star width={isActive ? 50 : 44} color={isActive ? '#5451FF' : '#CFCFCF'} />;
  };

  return (
    <StarRatingComponent
      name={name}
      starCount={starCount}
      value={rating}
      onStarClick={handleClick}
      renderStarIcon={renderStarByParam}
    />
  );
}
