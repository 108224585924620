import { ApplicantCreateStatusOptions } from '../../constants/applicant.constants';
import { DateService } from '../../services/date.service';
import { StudentStatusEnum } from '../../types/applicant.types';
import { IApplicantDetailGeneral, IGeneralSection } from '../../types/applicantDetail.types';

export const generalSectionMapper = ({
  name,
  email,
  phoneNumber,
  created_at,
  groupName,
  status,
}: IApplicantDetailGeneral): IGeneralSection[] => {
  return [
    {
      id: 1,
      title: 'Student',
      content: name,
    },
    {
      id: 2,
      title: 'Mail',
      content: email,
    },
    {
      id: 3,
      title: 'Phone',
      content: phoneNumber,
    },
    {
      id: 4,
      title: 'Registration Date',
      content: DateService.getFullDateByFormat(created_at),
    },
    {
      id: 5,
      title: 'Course - Group',
      content: groupName,
    },
    {
      id: 6,
      title: 'Registration Stage',
      content: ApplicantCreateStatusOptions[status ?? StudentStatusEnum.APPLIED_ONLY].label,
    },
  ];
};
