import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginLogo from '../../../../assets/images/loginLogo.svg';
import { Flex, Input } from '../../../../components/general';
import CustomButton from '../../../../components/general/Button/Button';
import { Loading } from '../../../../components/general/Loading/Loading';
import { FormLoader, ImageLoader } from '../../../../components/general/Loading/LoginPageLoaders';
import { dynamicWelcome } from '../../../../constants/constants';
import { useAuth } from '../../../../contexts/useAuth.context';
import { withCatch } from '../../../../helpers/error.helpers';
import { getHomePathByAuthGroup } from "../../../../helpers/route.helpers";
import { AuthGroup } from "../../../../types/auth.types";
import { IUserLogin } from '../../../../types/user.types';
import styles from './Login.module.css';

enum FormStatus {
  IDLE = 'idle',
  LOADING = 'loading',
}

const initialLoginState = {
  loginTerm: '',
  password: '',
  showPassword: false,
}

export default function Login({ authGroup = AuthGroup.USER }: { authGroup: AuthGroup } = { authGroup: AuthGroup.USER }) {
  const navigate = useNavigate();
  const [formStatus, setFormStatus] = useState(FormStatus.IDLE);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const { logIn } = useAuth();

  const [values, setValues] = React.useState<IUserLogin>(initialLoginState);

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const navigateToDefaultPath = () => {
    return getHomePathByAuthGroup(authGroup);
  }

  const onFinish = async (e: React.FormEvent<HTMLFormElement>) => {
    const { loginTerm, password } = values;
    const userCredentials: IUserLogin = {
      loginTerm,
      password,
    };

    e.preventDefault();
    setFormStatus(FormStatus.LOADING);

    await withCatch(() => logIn(userCredentials, authGroup), {
      onSuccess: () => navigate(navigateToDefaultPath(), { replace: true }),
    });
    setFormStatus(FormStatus.IDLE);
  };

  useEffect(() => {
    let isMounted = true;
    const loaderTimeOut = setTimeout(() => {
      if (isMounted) setLoaderStatus(false);
    }, 500);

    return () => {
      isMounted = false;
      clearTimeout(loaderTimeOut);
    };
  }, []);


  return (
    <>
      {formStatus === FormStatus.LOADING ? (
        <Loading/>
      ) : (
        <div className={styles.wrapper}>
          {loaderStatus ? (
            <ImageLoader/>
          ) : (
            <div
              style={{
                backgroundImage: `url(${dynamicWelcome[authGroup].img})`,
                backgroundSize: 'cover',
              }}
              className={styles.info}
            >
              <img src={LoginLogo} alt='ACA'/>

              <div>
                <h1 className={styles.title}>{dynamicWelcome[authGroup].welcome}</h1>
                <p className={styles.text}>
                  {dynamicWelcome[authGroup].title}
                </p>
              </div>
            </div>
          )}
          <div className={styles.login}>
            {loaderStatus ? (
              <FormLoader/>
            ) : (
              <>
                <h2 className={styles.loginTitle}>Login Account</h2>
                <p className={styles.loginSubtitle}>
                  Enter your username/email and password
                </p>
                <form className={styles.form} name='login' onSubmit={onFinish}>
                  <div>
                    <div>
                      <Input
                        type='text'
                        label='Username or email'
                        placeholder='Enter username or email'
                        id='loginTerm'
                        onChange={handleChange('loginTerm')}
                      />
                    </div>
                    <div style={{ position: 'relative' }}>
                      <Input
                        type={values.showPassword ? 'text' : 'password'}
                        label='Password'
                        placeholder='Enter password'
                        onChange={handleChange('password')}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '2rem',
                          right: '5px',
                        }}
                      >
                        <Button
                          htmlType='button'
                          onClick={handleClickShowPassword}
                          style={{
                            border: 'none',
                            color: '#5451FF',
                          }}
                        >
                          {values.showPassword ? 'Hide' : 'Show'}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Flex
                    style={{ margin: '10px 0' }}
                    alignItems='center'
                    justifyContent='flex-end'
                  >
                    <CustomButton
                      style={{ padding: '16px 24px' }}
                      htmlType='submit'
                      variant='primary'
                    >
                      Sign In
                    </CustomButton>
                  </Flex>
                </form>
                <Button type="link" style={{ textAlign: 'right', textDecoration: 'underline' }} block
                        onClick={() => navigate('/forget-password')}>
                  Forget password?
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
