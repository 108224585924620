import { Col, Modal, Row, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Button, Flex } from '../../../../../../components/general';
import { RegularEditableInput } from '../../../../../../components/general/Input/Input';
import { UseQueryTypes } from '../../../../../../constants/useQuery.constants';
import { useAuth } from '../../../../../../contexts/useAuth.context';
import { handleQueryError, handleQuerySuccess } from '../../../../../../helpers/error.helpers';
import { getPaymentPercentage } from '../../../../../../helpers/group.helpers';
import { createScheduleTable } from '../../../../../../helpers/mail/createScheduleTable.helper';
import { replaceAllVariables } from '../../../../../../helpers/mail/replaceAllVariables.helpers';
import { normalaizeKeys } from '../../../../../../helpers/mail/setMailTemplateVariablesAsObjectKeys.helpers';
import { useRowSelectionAdapter } from '../../../../../../hooks/useRowSelection.adapter';
import {
  EnrollmentTemplateActions,
  enrollmentTemplateReducer,
} from '../../../../../../reducers/enrollmentTemplate/enrollmentTemplate.reducer';
import { bulkSendApplicantAgreementLink } from '../../../../../../services/applicant.service';
import { DateService } from '../../../../../../services/date.service';
import { getGroupById } from '../../../../../../services/groups.service';
import { createEmail, getMailTemplateByName, sendMassMailing } from '../../../../../../services/mail.service';
import { getCourseCardInfo } from '../../../../../../services/static.service';
import { createPasswordToken } from '../../../../../../services/student.service';
import { store, useAppDispatch } from '../../../../../../store';
import { mailingActions } from '../../../../../../store/features/mailing/mailingSlice';
import type { IStudent, IStudentBrief } from '../../../../../../types/applicant.types';
import { IStudentExtended, PaymentMethod } from '../../../../../../types/applicant.types';
import { ITuitionFee } from '../../../../../../types/fee.types';
import { DurationModel } from '../../../../../../types/general.types';
import { GroupStateEnum } from '../../../../../../types/group.types';
import type { SendMail } from '../../../../../../types/mail.types';
import { TemplatesNamesEnum } from '../../../../../../types/mailTemplate.types';
import { getCourseFullPayment } from '../../helper';

const { REACT_APP_ORIGIN } = process.env;

const { Text } = Typography;

const templateConditions = {
  POINT_3_1: /\{\{#if POINT_3_1}}|\{\{\/if}}/g,
  NEW_CONTENT: /\{\{#if NEW_CONTENT}}|\{\{\/if}}/g,
  IS_PAYMENT_DEFAULT: /\{\{#if IS_PAYMENT_DEFAULT}}|\{\{\/if}}/g,
  ELSE: /\{\{else}}|\{\{\/if}}/g
}

const setTemplateVarsValues = (template: string, objOfVars: any) => {
  const clearTemplate = Object.values(templateConditions).reduce((acc, replacer) => acc.replace(replacer, ''), template);

  const normalizedObjOfVars = normalaizeKeys(objOfVars);
  return replaceAllVariables(clearTemplate, normalizedObjOfVars);
};

const replaceBodyNewContent = ({
  body,
  newContent = '',
  courseName = '',
}: {
  body: string;
  newContent?: string;
  courseName?: string;
}): string => {
  const initialContent = '{{NEW_CONTENT}}';
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  const parsedHtmlBody = parser.parseFromString(body, 'text/html');
  const newContentLi = parsedHtmlBody.getElementById('NEW_CONTENT') as HTMLElement;

  const courseContent = parsedHtmlBody.getElementById('COURSE_NAME') as HTMLElement;

  if (newContentLi.innerText === initialContent || newContent === '') {
    newContentLi.style.display = 'none';
  } else {
    newContentLi.style.display = 'list-item';
  }

  newContentLi.innerText = newContent;
  courseContent.innerText = courseName;

  return serializer.serializeToString(parsedHtmlBody);
};
const enrollmentTemplateInitialValues: any = {
  enrollmentModalVisible: false,
  enrollmentModalTemplateBody: '',
  enrollmentModalNewContentView: 'Add content',
};

export const GROUP_DETAIL = 'GROUP_DETAIL';
export const SendRoomLink = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [enrollmentTemplateState, enrollmentTemplateDispatch] = useReducer(
    enrollmentTemplateReducer,
    enrollmentTemplateInitialValues,
  );
  const [enrollmentModalTemplateBody, setEnrollmentModalTemplateBody] = useState('');
  const { enrollmentModalVisible, enrollmentModalNewContentToSend, enrollmentModalNewContentView } =
    enrollmentTemplateState;

  const handleCloseEnrollmentTemplateModal = () => {
    enrollmentTemplateDispatch({
      type: EnrollmentTemplateActions.TOGGLE_MODAL,
    });
  };

  const handleAddNewContent = (e: any, newContent: string) => {
    enrollmentTemplateDispatch({
      type: EnrollmentTemplateActions.CHANGE_NEW_CONTENT,
      data: newContent,
    });

    const updatedBody = replaceBodyNewContent({
      body: enrollmentModalTemplateBody,
      newContent,
      courseName: groupQuery.data?.course.name ?? '',
    });

    setEnrollmentModalTemplateBody(updatedBody);

    return true;
  };

  const dispatch = useAppDispatch();

  const groupQuery = useQuery(UseQueryTypes.GROUP_BY_ID, () => getGroupById(Number(id)));
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const cardQuery = useQuery(
    [UseQueryTypes.CARD_INFO],
    () => groupQuery.data?.course.id && getCourseCardInfo(groupQuery.data?.course.id),
    { enabled: enrollmentModalVisible },
  );

  const courseData = {
    duration: groupQuery.data?.duration,
    courseName: groupQuery.data?.course.name,
    startDate: DateService.getFullDate(groupQuery.data?.startDate),
    slackLink: groupQuery.data?.slackLink,
    contactEmail: groupQuery.data?.projectCoordinator?.email,
    contactPhone: groupQuery.data?.projectCoordinator?.phoneNumber,
    pmName: groupQuery.data?.projectCoordinator?.firstName,
    schedule: createScheduleTable(groupQuery.data?.schedule),
    tuitionFee: {
      amount: groupQuery.data?.tuitionFee.amount,
      currency: groupQuery.data?.tuitionFee.currency,
      feeType: groupQuery?.data?.tuitionFee.feeType,
      prePaymentPercent: groupQuery?.data?.tuitionFee.prePaymentPercent || 0,
    },
    point_3_1: groupQuery?.data?.agreement_3_1_point,
    cardAccount: cardQuery?.data?.CARD?.CARD_ACCOUNT,
    bank: cardQuery.data?.CARD?.BANK,
    brandName: cardQuery?.data?.CARD.BRAND_NAME,
  };

  const mapApplicantToAgreement = ({ email, name, id, passwordToken, paymentMethod }: IStudentExtended) => {
    const fullPayment = getCourseFullPayment(
      courseData?.duration as DurationModel,
      courseData?.tuitionFee as ITuitionFee,
    );
    const agreementMailState: Partial<SendMail> = {
      to: email,
      from: `<${user?.email}>`,
      variables: {
        APPLICANT_NAME: name,
        COURSE_NAME: courseData.courseName,
        START_DATE: courseData.startDate,
        SCHEDULE: courseData.schedule,
        SLACK_LINK: courseData.slackLink,
        CONTACT_EMAIL: courseData.contactEmail,
        CONTACT_PHONE: courseData.contactPhone,
        PM_NAME: courseData.pmName,
        APPLICANT_ROOM_LINK: `${REACT_APP_ORIGIN}student/create-password/${id}/${passwordToken}/`,
        TUITION_FEE: `${getPaymentPercentage(fullPayment, courseData.tuitionFee.prePaymentPercent)} ${
          courseData.tuitionFee.currency
        }`,
        IS_PAYMENT_DEFAULT: paymentMethod === PaymentMethod.DEFAULT,
        POINT_3_1: courseData.point_3_1,
        CARD_ACCOUNT: courseData.cardAccount,
        BANK: courseData.bank,
        BRAND_NAME: courseData.brandName,
        NEW_CONTENT: enrollmentModalNewContentToSend,
      },
      subject: `Course Invitation | ACA | ${courseData.courseName} `,
    };

    dispatch(mailingActions.setAgreementMailValues(agreementMailState));
    const { agreementMailValues } = store.getState().massMailing;

    return sendMassMailing(agreementMailValues) as Promise<any>;
  };

  const handleBulkAgreementUpdateSuccess = useCallback((r: any) => {
    handleQuerySuccess();
    return r;
  }, []);

  const handleBuildAgreementUpdateFailure = useCallback((e: Error) => {
    handleCloseEnrollmentTemplateModal();
    handleQueryError();
    return e;
  }, []);

  const tokensApplicantMutation = useMutation(createPasswordToken);

  const getTokens = (ids: number[]) => {
    return tokensApplicantMutation.mutateAsync(ids);
  };

  const assignTokens = async (applicants: IStudent[]) => {
    const tokens = await getTokens(applicants.map((ap: IStudent) => ap.id));
    return applicants.map((ap: IStudent) => {
      return { ...ap, passwordToken: tokens[ap.id] };
    });
  };

  const handleSaveInHistory = async () => {
    const { agreementMailValues } = store.getState().massMailing;
    const recipients = rowSelectionAdapter.selectedRows?.reduce((acc, applicant) => `${acc},${applicant.email}`, '');
    if (recipients?.length) {
      return createEmail({
        sender: agreementMailValues.from,
        subject: agreementMailValues.subject,
        tags: agreementMailValues.tags || null,
        recipients,
        template: agreementMailValues.template || 'custom',
      });
    }
  };

  const handleBulkSendApplicantAgreementLink = async (applicants: IStudent[]) => {
    return bulkSendApplicantAgreementLink(await assignTokens(applicants), mapApplicantToAgreement)
      .then(handleBulkAgreementUpdateSuccess)
      .then(handleSaveInHistory)
      .catch(handleBuildAgreementUpdateFailure);
  };

  const handleBulkSendApplicantAgreementLinkMutation = useMutation(handleBulkSendApplicantAgreementLink, {
    onSuccess: () => {
      handleCloseEnrollmentTemplateModal();
    },
  });

  const onHandleBulkSendApplicantAgreementLinkMutation = (applicants: IStudentBrief[]) => {
    handleBulkSendApplicantAgreementLinkMutation.mutate(applicants);
  };

  const templateQuery = useQuery(
    [UseQueryTypes.MAIL_TEMPLATE],
    () => getMailTemplateByName(TemplatesNamesEnum.ENROLL_TEMPLATE),
    { enabled: enrollmentModalVisible },
  );

  useEffect(() => {
    const template = templateQuery?.data?.template?.template?.version.template;
    const card = cardQuery.data?.CARD;
    const groupData = groupQuery.data;

    if (card && template && groupData) {
      Object.assign(courseData, {
        cardAccount: card.CARD_ACCOUNT,
        bank: card?.BANK,
        brandName: card.BRAND_NAME,
      });

      const fullPayment = getCourseFullPayment(
        courseData?.duration as DurationModel,
        courseData?.tuitionFee as ITuitionFee,
      );

      let enrollMailTemplateHTMLBody = setTemplateVarsValues(template, {
        courseName: groupData?.course.name,
        slackLink: groupData?.slackLink,
        contactEmail: groupData?.projectCoordinator?.email,
        contactPhone: groupData?.projectCoordinator?.phoneNumber,
        startDate: DateService.getFullDate(groupData?.startDate),
        scheduleHTML: createScheduleTable(groupData?.schedule),
        tuitionFee: `${getPaymentPercentage(fullPayment, courseData.tuitionFee.prePaymentPercent)} ${
          courseData.tuitionFee.currency
        }`,
        ceo: cardQuery.data.CARD.CEO,
        cardAccount: cardQuery.data.CARD.CARD_ACCOUNT,
        bank: cardQuery.data.CARD.BANK,
        brandName: cardQuery.data.CARD.BRAND_NAME,
        newContent: enrollmentModalNewContentView,
      });

      const templateBodyNormalized = replaceBodyNewContent({
        body: enrollMailTemplateHTMLBody,
        courseName: groupData?.course?.name,
      });

      setEnrollmentModalTemplateBody(templateBodyNormalized);
    }
  }, [groupQuery.isSuccess, cardQuery.isSuccess, templateQuery.isSuccess]);

  const isModalLoading = groupQuery?.isLoading || cardQuery?.isLoading || templateQuery.isLoading;

  return (
    <Spin spinning={isModalLoading}>
      <Row>
        <>
          <Modal
            title="Course Invitation"
            visible={enrollmentModalVisible}
            onOk={() => onHandleBulkSendApplicantAgreementLinkMutation(rowSelectionAdapter.selectedRows)}
            okText="Send"
            confirmLoading={handleBulkSendApplicantAgreementLinkMutation.isLoading}
            onCancel={handleCloseEnrollmentTemplateModal}
            width={800}
          >
            <Spin spinning={isModalLoading}>
              <div
                dangerouslySetInnerHTML={{
                  __html: enrollmentModalTemplateBody,
                }}
              />

              <Flex direction="column" style={{ width: '50%', gap: '10px 0' }}>
                <Text>Add new content</Text>

                <RegularEditableInput text={enrollmentModalNewContentView} onSave={handleAddNewContent} />
              </Flex>
            </Spin>
          </Modal>
          {groupQuery.data?.groupState !== GroupStateEnum.TERMINATED && (
            <Col style={{ marginRight: '8px' }}>
              <Button variant="primary" onClick={handleCloseEnrollmentTemplateModal}>
                Send Applicant Room Link
              </Button>
            </Col>
          )}
        </>
      </Row>
    </Spin>
  );
};
