import { IOption } from '../components/general/Multiselect/Multiselect';

export const generateContext = <T>(
  data: T[],
  getOption: (point: T, idx: number) => IOption,
  //defaultValue?: T = {}
): Record<string, IOption> => {
//  data.unshift(defaultValue)
  return (data || []).reduce((acc: Record<string, IOption>, point, idx) => {
    const option = getOption(point, idx);
    acc[option.value] = option;
    return acc;
  }, {});
};
