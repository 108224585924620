export enum UseQueryTypes {
  CONSULTANTS = 'CONSULTANTS',
  CONSULTANT_ID = 'CONSULTANT_ID',
  MAIL_TEMPLATE = 'MAIL_TEMPLATE',
  MAIL_TEMPLATE_LIST = 'MAIL_TEMPLATE_LIST',
  GROUPS = 'GROUPS',
  REMINDER = 'REMINDER',
  FILTER_REMINDER = 'FILTER_REMINDER',
  REMINDER_COUNT = 'REMINDER_COUNT',
  NOTIFICATION = 'NOTIFICATION',
  FILTER_NOTIFICATION = 'FILTER_NOTIFICATION',
  NOTIFICATION_COUNT = 'NOTIFICATION_COUNT',
  GROUP_NAMES = 'GROUP_NAMES',
  GROUP_ABSENTEES = 'GROUP_ABSENTEES',
  FILTER_GROUP_BY_COURSE = 'FILTER_GROUP_BY_COURSE',
  GROUP_INTERVIEW = 'GROUP_INTERVIEW',
  EXAMS = 'EXAMS',
  COURSES = 'COURSES',
  FILTER_COURSES = 'FILTER_COURSES',
  COURSE_LEVELS_COUNT = 'COURSE_LEVELS_COUNT',
  COURSE = 'COURSE',
  CARD_INFO = 'CARD_INFO',
  TRACKS = 'TRACKS',
  TRACK = 'TRACK',
  USERS = 'USERS',
  USER_BY_ID = 'USER_BY_ID',
  INSTRUCTORS = 'INSTRUCTORS',
  INSTRUCTOR = 'INSTRUCTOR',
  INSTRUCTORS_COUNT = 'INSTRUCTORS_COUNT',
  PROJECT_COORDINATORS = 'PROJECT_COORDINATORS',
  TASK_APPLICANTS = 'TASK_APPLICANTS',
  STUDENTS = 'STUDENTS',
  STUDENTS_BY_COURSE = 'STUDENTS_BY_COURSE',
  TASK = 'TASK',
  TASKS = 'TASKS',
  CREATED_TASKS = 'CREATED_TASKS',
  TASKS_COUNT = 'TASKS_COUNT',
  APPLICANTS = 'APPLICANTS',
  APPLICANTS_ALL = 'APPLICANTS_ALL',
  APPLICANTS_SELECTS = 'APPLICANTS_SELECTS',
  APPLICANT_ATTACHMENTS = 'APPLICANT_ATTACHMENTS',
  APPLICANTS_BY_COURSE = 'APPLICANTS_BY_COURSE',
  APPLICANTS_TODAY = 'APPLICANTS_TODAY',
  APPLICANTS_THIS_WEEK = 'APPLICANTS_THIS_WEEK',
  APPLICANTS_THIS_MONTH = 'APPLICANTS_THIS_MONTH',
  APPLICANTS_COUNT_BY_CRITERIA = 'APPLICANTS_COUNT_BY_CRITERIA',
  GROUP_APPLICANTS_COUNT = 'GROUP_APPLICANTS_COUNT',
  FILTER_BY_LVL_0 = 'FILTER_BY_LVL_0',
  FILTER_BY_LVL_1 = 'FILTER_BY_LVL_1',
  FILTER_BY_LVL_2 = 'FILTER_BY_LVL_2',
  ONGOING = 'ONGOING',
  PLANNING = 'PLANNING',
  ON_HOLD = 'ON_HOLD',
  WAITING_FOR_DEMO = 'WAITING_FOR_DEMO',
  TERMINATED = 'TERMINATED',
  LESSONS = 'LESSONS',
  TEACHING_NOW = 'TEACHING_NOW',
  ON_BREAK = 'ON_BREAK',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  GROUP_BY_ID = 'GROUP_BY_ID',
  APPLICANT_ID = 'APPLICANT_ID',
  APPLICANT_UUID = 'APPLICANT_UUID',
  ABSENTEES = 'ABSENTEES',
  PAYMENT = 'PAYMENT',
  GROUP_PAYMENT = 'GROUP_PAYMENT',
  GROUP_DETAIL_EXAM_INFO = 'GROUP_DETAIL_EXAM_INFO',
  GROUP_COUNT_INFO = 'GROUP_COUNT_INFO',
  GROUP_DETAIL = 'GROUP_COUNT_INFO',
  APPLICANT_COMMENTS = 'APPLICANT_COMMENTS',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  APPLICANT_PAYMENT = 'APPLICANT_PAYMENT',
  APPLICANT_FEEDBACKS = 'APPLICANT_FEEDBACKS',
  FILTER_EMAILS = 'FILTER_EMAILS',
  FILTER_SMS = 'FILTER_SMS',
  STUDENT_PASSWORD_TOKEN = 'STUDENT_PASSWORD_TOKEN',
  LEAD_GEN = 'LEAD_GEN',
  APPLICANT_ACTIONS = 'APPLICANT_ACTIONS',
  TEAMS = 'TEAMS',
  TEAM_PHONE = 'TEAM_PHONE',
  TASK_COMMENTS = 'TASK_COMMENTS',
  PHONE = 'PHONE',
  STUDENT_FEEDBACKS = 'STUDENT_FEEDBACKS',
}

export enum ApplicantDetailQueryTypes {
  APPLICANT_DETAILS_EXAMS = 'APPLICANT_DETAILS_EXAMS',
  APPLICANT_DETAILS_CONSULTATIONS = 'APPLICANT_DETAILS_CONSULTATIONS',
  APPLICANT_DETAILS_ABSENTEES = 'APPLICANT_DETAILS_ABSENTEES',
  APPLICANT_DETAILS_FEEDBACKS = 'APPLICANT_DETAILS_FEEDBACKS',
  APPLICANT_DETAILS_PAYMENTS = 'APPLICANT_DETAILS_PAYMENTS',
  APPLICANT_DETAILS_ATTACHMENTS = 'APPLICANT_DETAILS_ATTACHMENTS',
  APPLICANT_DETAILS_COMMENTS = 'APPLICANT_DETAILS_COMMENTS',
  APPLICANT_DETAILS_ACTIONS = 'APPLICANT_DETAILS_ACTIONS',
  APPLICANT_DETAILS_APPLICATIONS = 'APPLICANT_DETAILS_APPLICATIONS',
  APPLICANT_DETAILS_GENERAL = 'APPLICANT_DETAILS_GENERAL',
  APPLICANT_DETAILS_PROMO_CODE = 'APPLICANT_DETAILS_PROMO_CODE',
}

export type ExcludedApplicationDetailTypes = Exclude<
  ApplicantDetailQueryTypes,
  ApplicantDetailQueryTypes.APPLICANT_DETAILS_APPLICATIONS | ApplicantDetailQueryTypes.APPLICANT_DETAILS_GENERAL
>;
