
import { stringifyObject } from "../../../helpers/stringifyObj.helper";
import axios from "../../../services/config.service";
import {
  IFilterStudent,
  IFilterStudentByCourse,
  IStudentFilterResponse,
  IStudentResponseByCourse
} from "../../../types/applicant.types";
import { Sorters } from "../../../types/general.types";

export async function filterStudent(
  criterias: Partial<IFilterStudent>,
  query: Partial<Sorters>,
) {
  const queryString = stringifyObject(query);
  const r = await axios.post(`/student/filter?${queryString}`, criterias);
  return r.data as Promise<IStudentFilterResponse>;
}


export function getStudentCountByDateInterval(data: {
  dateFrom: string;
  dateTo: string;
}) {
  return axios
    .post(`/student/count`, data)
    .then((r) => r.data) as Promise<number>;
}

export const filterStudentByCourse = async (
  criterias: Partial<IFilterStudentByCourse>,
  query: Partial<Sorters>,
) => {
  const queryString = stringifyObject(query);
  const r = await axios.post(
    `/student/course/statistics?${queryString}`,
    criterias,
  );
  return r.data as Promise<IStudentResponseByCourse>;
};
