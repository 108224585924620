import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { withCatch } from '../../helpers/error.helpers';
import { getGroupById, updateGroupById } from '../../services/groups.service';
import { ICreateGroup } from '../../types/group.types';
import { CreateEditGroupForm } from './shared/createEditGroup.form';

export default function EditGroup() {
  const cache = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const groupQuery = useQuery(UseQueryTypes.GROUP_BY_ID, () =>
    getGroupById(Number(id)),
  );

  const handleFormSubmit = async (group: ICreateGroup) => {
    await withCatch(() => updateGroupById({ group, id: Number(id) }), {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.GROUP_BY_ID)
      }
    });
  };

  const initialValues = useMemo<ICreateGroup | {}>(() => {
    if (!groupQuery.data) return {};
    const { course, projectCoordinator, endDate, ...rest } = groupQuery.data;

    return {
      ...rest,
      courseId: course.id,
      projectCoordinatorId: projectCoordinator?.id,
    };
  }, [groupQuery.data]);

  return (
    <CreateEditGroupForm
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      title="Edit Group"
      isEditForm
    />
  );
}
