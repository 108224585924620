import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Flex, Input } from '../../../../components/general';
import { AttachmentLabelNotes } from '../../../../constants/attachment.constants';
import { useDrawer } from '../../../../contexts/drawer.context';
import { useAuth } from '../../../../contexts/useAuth.context';
import { AttachmentUpload } from '../../../../forms/applicantForms/public/Invoices.form';
import { withCatch } from '../../../../helpers/error.helpers';
import { createStudentAttachment } from '../../../../services/student.service';
import { AttachmentTypeEnum } from '../../../../types/attachment.types';
import { StudentQueryConstants } from '../../constants/studentQuery.constants';
import { getStudentAttachmentInitialState } from '../../helpers/student.helpers';
import { CreateStudentAttachmentFormSchema } from '../schemas/createStudentAttachment.schema';
import styles from './CreateStudentAttachmentForm.module.css';

interface CreateStudentAttachmentFormProps {
  children?: JSX.Element;
  handleSubmit: (value:  ReturnType<typeof getStudentAttachmentInitialState>) => Promise<any>;
  attachmentTitle: string;
  attachmentType: AttachmentTypeEnum;
  attachmentFileTitle: string;
  isLoading: boolean;
}


export const CreateStudentAttachmentForm = ({
  children,
  isLoading,
  handleSubmit,
  attachmentTitle,
  attachmentType,
  attachmentFileTitle
}: CreateStudentAttachmentFormProps) => {
  const isCvType = attachmentType === AttachmentTypeEnum.CV

  return (
    <Formik
      initialValues={getStudentAttachmentInitialState(attachmentType)}
      enableReinitialize
      validationSchema={CreateStudentAttachmentFormSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <Form
            name="create-payment"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >

            <Field
              name="name"
              label={attachmentTitle}
              component={Input}
              required
              disabled={isCvType}
              onChange={attachmentType === AttachmentTypeEnum.CV  ? null : props.handleChange}
            />
            <ErrorMessage name="name" component="span" className="error"/>

            <AttachmentUpload
              name="attachment"
              label={attachmentFileTitle}
              labelNote={AttachmentLabelNotes[attachmentType] ?? AttachmentLabelNotes.DEFAULT}
              placeholder="Click to select a file"
            />
            <ErrorMessage
              name="attachment"
              component="span"
              className="error"
            />

            <div style={{ maxWidth: 'max-content', marginTop: '20px' }}>{children}</div>

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">

              <Button
                htmlType="button"
                style={{ minWidth: 160, marginRight: 8 }}
                onClick={() => props.handleReset()}
              >
                Reset
              </Button>

              <Spin spinning={isLoading} wrapperClassName={styles.spin}>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Apply
                </Button>
              </Spin>
            </Flex>
          </Form>
        )
      }}
    </Formik>
  );
};

export const CreateStudentOtherAttachmentForm = () => {
  const { closeDrawer } = useDrawer();
  const { user } = useAuth();
  const cache = useQueryClient();

  // eslint-disable-next-line no-console
  console.log('user ::', user);


  const studentAttachment = useMutation(createStudentAttachment, {
    onSuccess: () => {
      cache.invalidateQueries(StudentQueryConstants.STUDENT_ATTACHMENTS);
    },
  });

  const handleAddAttachment = async (
    newAttachment: { name: string, attachment: string },
  ) => {
    if (user?.id) {
      const newAttachmentWithUserId = {
        ...newAttachment,
        attachmentType: AttachmentTypeEnum.OTHER,
        userId: user.id
      }

      const mutationFunc = () =>
        studentAttachment.mutateAsync(newAttachmentWithUserId);

      await withCatch(mutationFunc, {
        onSuccess: () => {
          closeDrawer();
        },
      });
    }
  };


  return <CreateStudentAttachmentForm
    attachmentTitle='File name'
    attachmentFileTitle='File Picture'
    attachmentType={AttachmentTypeEnum.OTHER}
    isLoading={studentAttachment.isLoading}
    handleSubmit={handleAddAttachment}
  />
}
