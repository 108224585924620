import { useFormikContext } from 'formik';
import { FC, useState } from 'react';

import {
  checkFileValidation,
  FilesConstants,
} from '../../../constants/files.constants';
import { imageTypes } from '../../../constants/image.constants';
import { removeBase64Prefix, toBase64 } from '../../../helpers/base64.helper';
import { formatFilename } from '../../../helpers/formatFilename.helper';
import { Input as FormInput } from '../../general';
import type { IFormInput } from '../../general/Input/Input';
import { FileUpload } from '../FileUpload/FileUpload';

export const ImageUpload: FC<IFormInput> = ({ name, ...props }) => {
  const formik = useFormikContext();
  const [fileName, setFileName] = useState('');

  const handleUpload = async (file: FileList) => {
    const imageBase64 = await toBase64(file[0]);
    const image = removeBase64Prefix(imageBase64);
    const {
      imageMaxSize,
      imageMaxSizeTitle,
      imageMaxSizeMessage,
      imageTypeErrorTitle,
      imageTypeErrorMessage,
    } = FilesConstants;

    const isInvalidImageType = checkFileValidation(
      !imageTypes.includes(file[0].type),
      {
        title: imageTypeErrorTitle,
        message: imageTypeErrorMessage,
      },
    );
    const isInvalidImageSize = checkFileValidation(
      file[0].size >= imageMaxSize,
      {
        title: imageMaxSizeTitle,
        message: imageMaxSizeMessage,
      },
    );

    if (isInvalidImageSize || isInvalidImageType) return;

    formik.setFieldValue(name!, image);

    const newFileName = formatFilename(file[0]);
    setFileName(newFileName);
  };

  return (
    <FileUpload onUpload={handleUpload}>
      <FormInput value={fileName} name={name} readOnly {...props} />
    </FileUpload>
  );
};
