import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, useAppSelector } from "../../index";
import { ACTION_NAMES, IActionKey } from "./actionNames";

const actionNamesArr = Object.keys(ACTION_NAMES);

type IEntityId = number | string;
interface IEntity { id?: IEntityId;  data: any }
interface SetActionPayload { name: IActionKey; data: IEntity }
interface RemoveActionPayload { name: IActionKey }

type EntityAction = { [key in typeof actionNamesArr[number]]: IEntity }

export const initialState: EntityAction = {}

export const entityActionSLice =  createSlice({
  name: 'entityAction',
  initialState,
  reducers: {
    setAction: (
      state,
      action: PayloadAction<SetActionPayload>,
    ) => {
      const { name, data } = action.payload;
      state[name] = data;
    },
    removeAction: (
      state,
      action: PayloadAction<RemoveActionPayload>,
      ) => {
      delete state[action.payload.name];
    }
  },
});

export const lastEntityActions = entityActionSLice.actions;

export const getState = (state: RootState) => state.entityAction


export const selectActionByName = (name: IActionKey): IEntity => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useAppSelector((state) => state.entityAction[name]);
};
