import { Typography } from 'antd';
import generatePicker, {
  PickerDateProps,
} from 'antd/es/date-picker/generatePicker';
import { useField } from 'formik';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React from 'react';

import { DateFnsFormats } from '../../../constants/dateFns.constants';
import { DateService } from '../../../services/date.service';
import { Flex } from '..';
import styles from './Date.module.css';

const AntDatePicker = generatePicker(dateFnsGenerateConfig);

const DatePicker = React.forwardRef<any, PickerDateProps<Date>>(
  (props, ref) => {
    return <AntDatePicker {...props} picker="date" mode={'date'} ref={ref} />;
  },
);

const { Text } = Typography;

interface ICustomPicker {
  label?: string;
  style?: React.CSSProperties;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  formatDate?: (date?: Date) => string;
  parseDate?: (date?: string) => Date;
  onChange: (date: string | undefined) => void;
}

interface ICustomPickerFormik extends ICustomPicker {
  field: string;
  name: string;
}

export const CustomDatePicker = ({
  label,
  style,
  value,
  defaultValue,
  required = false,
  onChange,
  formatDate = DateService.getDateServer,
  parseDate = DateService.getDateFromServer,
  ...props
}: ICustomPicker) => {
  const labelElement = label && (
    <Text
      style={{
        marginBottom: 4,
      }}
    >
      {label}
    </Text>
  );
  const requiredElement = required && (
    <Text
      style={{
        marginBottom: 4,
        marginLeft: 3,
        color: value ? '#646464' : 'red',
      }}
    >
      *
    </Text>
  );

  return (
    <div className={styles.optionsWrapper}>
      <label className={styles.wrapper}>
        <Flex>
          {labelElement}
          {requiredElement}
        </Flex>
        <DatePicker
          size="large"
          style={style}
          className={styles.datePicker}
          format={DateFnsFormats.full}
          value={value ? parseDate(value) : undefined}
          defaultValue={defaultValue ? parseDate(defaultValue) : undefined}
          onChange={(value) => {
            if (value) {
              onChange(formatDate(value));
            }
          }}
          {...props}
        />
      </label>
    </div>
  );
};

export const CustomDatePickerFormik = ({
  field,
  name,
  ...props
}: ICustomPickerFormik) => {
  const [{ value }, , { setValue }] = useField<string | undefined>(field);

  return (
    <CustomDatePicker
      {...props}
      value={value}
      onChange={(date) => setValue(date)}
    />
  );
};
