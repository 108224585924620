import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { actionColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IApplicantDetailAction } from '../../../../types/applicantDetail.types';

export function ActionTable({ actions }: { actions: IApplicantDetailAction[] }) {
  const table = useTable<IApplicantDetailAction>(actionColumns);

  return (
    <Table<IApplicantDetailAction>
      {...table.getTableProps({
        rowKey: ({ id }: IApplicantDetailAction) => id,
        dataSource: actions,
      })}
    />
  );
}
