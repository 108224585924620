import { IStudentAbsenteesTableData } from "../types/studentAbsentees.types";
import { StudentTableBodySchema } from "../types/studentTable.types";


export const AbsenteesTableHeaderSchema = {
  groupName: {
    title: 'Group',
    key: 'groupName',
  },
  absentees: {
    title: 'Absentees',
    key: 'absentees',
  },
  allLessons: {
    title: 'Total Lessons',
    key: 'allLessons',
  },
  completedLessons: {
    title: 'Completed',
    key: 'completedLessons',
  },
};


export const getAbsenteesSchema = ({
  groupName,
  absentees,
  allLessons,
  completedLessons
}: IStudentAbsenteesTableData): StudentTableBodySchema =>
  ({
      groupName: { renderer: () => groupName },
      absentees: { renderer: () => absentees },
      allLessons: { renderer: () => allLessons },
      completedLessons: { renderer: () => completedLessons }
    }
  )
