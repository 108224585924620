import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { CourseTimeScaleEnum } from '../../../types/course.types';
import { CurrencyEnum } from '../../../types/currency.types';
import { ExamProcessType, ExamSource } from '../../../types/exam.types';
import { FeeClientTypeEnum } from '../../../types/fee.types';
import { Branch } from '../../../types/general.types';

const { required } = messages;

const examAutomationValidation = (type: 'string' | 'number') =>
  Yup.mixed().when('examProcessType', {
    is: ExamProcessType.AUTOMATED,
    then: Yup[type]().required(messages.required),
    otherwise: Yup[type](),
  });

export const TransformCourseFormSchema = Yup.object().shape({
  name: Yup.string().required(required),
  branch: Yup.mixed<Branch>().oneOf(Object.values(Branch)).required(required),
  emailTemplate: Yup.string().required(required),
  level: Yup.string().required(required),
  expectedNumberOfApplicants: Yup.number().required(required),
  trackId: Yup.number().required(required),
  defaultProjectCoordinatorId: Yup.number().required(required),
  defaultDuration: Yup.object().shape({
    value: Yup.number().required(required),
    timeScale: Yup.mixed<CourseTimeScaleEnum>().oneOf(Object.values(CourseTimeScaleEnum)).required(required),
  }),
  defaultTuitionFee: Yup.object().shape({
    currency: Yup.mixed<CurrencyEnum>().oneOf(Object.values(CurrencyEnum)).required(required),
    amount: Yup.number().required(required),
    feeType: Yup.mixed<FeeClientTypeEnum>().oneOf(Object.values(FeeClientTypeEnum)).required(required),
  }),
  examSource: Yup.mixed<ExamSource>()
    .oneOf(Object.values(ExamSource))
    .default(ExamSource.CODE_SIGNAL)
    .required(messages.required),
  examProcessType: Yup.mixed<ExamProcessType>().oneOf(Object.values(ExamProcessType)),
  testLink: examAutomationValidation('string'),
  testId: examAutomationValidation('string'),
  examPercentage: examAutomationValidation('number'),
});
