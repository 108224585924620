import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';

import { Badges, ResultSummary } from '../../components/display';
import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import {
  Button,
  FilterButton,
  Flex,
  Icons,
  Search,
} from '../../components/general';
import { ChangeView } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { defaultCourseColumns } from '../../schemas/courses/course.schema';
import { filterCourses, getCourseLevels } from '../../services/courses.service';
import { CourseFilterOptions, ICourse, ICourseLevelsCount } from '../../types/course.types';
import { SortDirectionEnum, WedgedLevels, WedgedTypes } from '../../types/general.types';

const getCoursesByLvl = (levels: ICourseLevelsCount[] = [], lvl: number) =>
  levels?.find((c: ICourseLevelsCount) => c.level === lvl)?.count ?? 0

const defaultCourseFilterOptions: Partial<CourseFilterOptions> = {
  sortBy: 'created_at',
  sortDirection: SortDirectionEnum.DESC,
};

export default function Courses() {
  const { openDrawer } = useDrawer();
  const { handleSearch, term } = useSearchHandler();
  const { filterOptions, sorterOptions, resetOptions } = useFilter(
    defaultCourseFilterOptions,
  );

  const courseTable = useTable<ICourse>(defaultCourseColumns);
  const modalAdapter = useColumnModalAdapter(
    courseTable.getTableColumnSettings(),
  );

  const coursesQuery = useQuery(
    [UseQueryTypes.FILTER_COURSES, sorterOptions, filterOptions],
    () => filterCourses(filterOptions, sorterOptions),
  );

  const courseLevelsQuery = useQuery(UseQueryTypes.COURSE_LEVELS_COUNT, getCourseLevels);

  const coursesTotalSize = !coursesQuery.isLoading
    ? coursesQuery?.data?.totalElements ?? 0
    : 0;

  const handleOpenCreateCourseForm = () =>
    openDrawer(AppFormsEnum.CreateCourseForm, 'Create Course');

  const handleOpenFilterCourseForm = () =>
    openDrawer(AppFormsEnum.FilterCourseForm, 'Filter Course');

  const handleReset = () => {
    resetOptions();
  };

  const isScreenLoading = coursesQuery.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row justify="start" gutter={8}>
              <Col span={13}>
                <Row>
                  <Row>
                    <Search
                      style={{ marginRight: 8 }}
                      placeholder="Search"
                      defaultValue={term}
                      onChange={handleSearch}
                    />
                    <Flex alignItems="center" style={{ gap: '0 10px' }}>
                      <FilterButton onClick={handleOpenFilterCourseForm}/>
                      <Button onClick={handleReset}>Reset Filters</Button>
                    </Flex>
                  </Row>
                </Row>
              </Col>

              <Badges
                wedgedType={WedgedTypes.LEVEL}
                badges={[
                  {
                    data: getCoursesByLvl(courseLevelsQuery.data, 0),
                    title: 'Level 0',
                    name: WedgedLevels.LEVEL_0
                  },
                  {
                    data: getCoursesByLvl(courseLevelsQuery.data, 1),
                    title: 'Level 1',
                    name: WedgedLevels.LEVEL_1
                  },
                  {
                    data: getCoursesByLvl(courseLevelsQuery.data, 2),
                    title: 'Level 2',
                    name: WedgedLevels.LEVEL_2
                  },
                ]}
              />
            </Row>
            <Divider style={{ margin: '16px 0px' }}/>
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between">
              <ResultSummary text={`${coursesTotalSize} result found`}/>
              <Row>
                <Col>
                  <Button
                    onClick={handleOpenCreateCourseForm}
                    variant="primary"
                    icon={<Icons.Add/>}
                  >
                    Add a new Course
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row justify="end">
              <ChangeView onClick={modalAdapter.openSettings}/>
            </Row>
          </Col>

          <Col span={24}>
            <Table<ICourse>
              {...courseTable.getTableProps()}
              dataSource={coursesQuery?.data?.courses}
              rowKey={(course) => course.id}
            />
            <Pagination
              {...courseTable.getPaginationProps()}
              total={coursesQuery?.data?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
