import { SalaryTypeEnum } from '../types/salary.types';

export const SalaryTypes = {
  [SalaryTypeEnum.PER_COURSE]: {
    label: 'per course',
    value: SalaryTypeEnum.PER_COURSE,
  },
  [SalaryTypeEnum.MONTHLY]: {
    label: 'per month',
    value: SalaryTypeEnum.MONTHLY,
  },
  [SalaryTypeEnum.HOURLY]: {
    label: 'per hour',
    value: SalaryTypeEnum.HOURLY,
  },
} as const;
