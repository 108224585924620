import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMutation } from "react-query";
import { useNavigate, useParams } from 'react-router-dom';

import { Flex, Input } from '../../../components/general';
import Button from '../../../components/general/Button/Button';
import { Loading } from '../../../components/general/Loading/Loading';
import { FormLoader } from '../../../components/general/Loading/LoginPageLoaders';
import { useAuth } from "../../../contexts/useAuth.context";
import { withCatch } from '../../../helpers/error.helpers';
import { saveState } from "../../../helpers/localStorage.helpers";
import { StudentRoutesConstants } from '../../../helpers/routes.helpers';
import { checkCreatePassword, createPassword } from '../../../services/student.service';
import { AuthGroup } from "../../../types/auth.types";
import { ICreatePasswordPayload,PassShow } from '../../../types/user.types';
import { ExpiredToken } from '../../shared/pages/ExpiredToken'
import styles from '../../shared/pages/Login/Login.module.css';
import {
  resetPasswordInitialValues as initialValues,
  resetPasswordSchema as validationSchema,
} from '../../shared/schemas/forgetPassword.schema';

enum FormStatus {
  IDLE = 'idle',
  LOADING = 'loading',
}

export default function CreatePassword() {
  const navigate = useNavigate();
  const { id = '', token = '' } = useParams<{ id: string, token: string }>();
  const [formStatus, setFormStatus] = useState(FormStatus.IDLE);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [validation, setValidation] = useState<any>({
    token: null,
    password: null,
  });
  const { logIn } = useAuth();

  const [isShow, setIsShow] = React.useState<PassShow>({
    password: false,
    confirmPassword: false
  });

  const createPasswordMutation = useMutation(createPassword);

  const checkCreatePasswordMutation = useMutation(checkCreatePassword);

  const checkCreatePasswordValidation = async () => {
    const mutationFunc = () => checkCreatePasswordMutation.mutateAsync({ id, token });

    await withCatch(mutationFunc, {
      onSuccess: (res: any) => {
        setValidation(res.validationResult);
      },
      onError: () => {
        setValidation({
          token: false,
          password: null,
        });
      },
      hideMessage: true
    });

  };

  const handleClickShowPassword = (field: string) => {
    setIsShow({ ...isShow, [field]: !isShow[field as keyof  typeof isShow] });
  };

  const navigateToDashboard = () => {
    return StudentRoutesConstants.dashboard()
  }

  const onSubmit = async ({ password }: any) => {
    const createData: ICreatePasswordPayload = {
      id,
      payload: {
        token,
        password,
      }
    };
    setFormStatus(FormStatus.LOADING);

    const mutationFunc = () => createPasswordMutation.mutateAsync(createData);

    await withCatch(mutationFunc, {
      onSuccess: async (data) => {
        const userCredentials = { loginTerm: data.email, password };
        await logIn(userCredentials, AuthGroup.STUDENT);
        navigate(navigateToDashboard(), { replace: true });
        setFormStatus(FormStatus.IDLE);
      },
      onError: () => {
        setFormStatus(FormStatus.IDLE);
        navigate(StudentRoutesConstants.login(), { replace: true });
      }
    });

  };

  const init = () => {
    let isMounted = true;
    const loaderTimeOut = setTimeout(() => {
      if (isMounted) setLoaderStatus(false);
    }, 500);

    saveState('authGroup', AuthGroup.STUDENT);

    checkCreatePasswordValidation();

    return () => {
      isMounted = false;
      clearTimeout(loaderTimeOut);
    };
  };

  useEffect(init, []);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    dirty,
    isValid,
    isSubmitting
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  return (
    <>
      {formStatus === FormStatus.LOADING || (validation.token === null && validation.password === null) ? (
        <Loading/>
      ) : (validation.token &&  validation.password ?
        <div className={styles.wrapper}>
          <div className={styles.login}>
            {loaderStatus ? (
              <FormLoader/>
            ) : (
              <>
                <h2 className={styles.loginTitle}>Create Password</h2>
                <form className={styles.form} name='createPassword' onSubmit={handleSubmit}>
                  <div>
                    <div style={{ position: 'relative' }}>
                      <Input
                        id='password'
                        onBlur={handleBlur}
                        label='New Password'
                        onChange={handleChange}
                        value={values.password}
                        type={isShow.password ? 'text' : 'password'}
                      />
                      {errors.password && touched.password && <div className="error">{errors.password}</div>}
                      <div
                        style={{
                          position: 'absolute',
                          top: '2rem',
                          right: '5px',
                        }}
                      >
                        <Button
                          htmlType='button'
                          onClick={() => handleClickShowPassword('password')}
                          style={{
                            border: 'none',
                            color: '#5451FF',
                          }}
                        >
                          {isShow.password ? 'Hide' : 'Show'}
                        </Button>
                      </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <Input
                        type={isShow.confirmPassword ? 'text' : 'password'}
                        id='confirmPassword'
                        value={values.confirmPassword}
                        label='Confirm Password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmPassword && touched.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
                      <div
                        style={{
                          position: 'absolute',
                          top: '2rem',
                          right: '5px',
                        }}
                      >
                        <Button
                          htmlType='button'
                          onClick={() => handleClickShowPassword('confirmPassword')}
                          style={{
                            border: 'none',
                            color: '#5451FF',
                          }}
                        >
                          {isShow.confirmPassword ? 'Hide' : 'Show'}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Flex
                    style={{ margin: '10px 0' }}
                    alignItems='center'
                    justifyContent='flex-end'
                  >
                    <Button
                      style={{ padding: '16px 24px' }}
                      htmlType='submit'
                      variant='primary'
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Submit
                    </Button>
                  </Flex>
                </form>
              </>
            )}
          </div>
        </div>
        : <ExpiredToken massageKey={ validation.password === false ? 'password' : 'token'} />)}
    </>
  );
}
