import { SortDirectionEnum } from "../types/general.types";
import { NotificationStatus } from '../types/notification.types';
import { ReminderStatus } from '../types/reminder.types';

export const GlobalNotificationOptions = {
  reminderFilterOptions: {
    status: ReminderStatus.ACTIVE,
  },
  notificationFilterOptions: {
    status: NotificationStatus.UNREAD,
  },
  sorterOptions: {
    pageSize: 5,
  },
  notificationSorterOptions: {
    sortDirection: SortDirectionEnum.DESC,
    sortBy: 'id',
    pageSize: 5,
  },
};
