import { Spin } from "antd";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";

import { useAuth } from "../../../../contexts/useAuth.context";
import { getStudentPayments } from "../../../../services/student.service";
import { StudentQueryConstants } from "../../constants/studentQuery.constants";
import { studentPaymentsTableAdapter } from "../../helpers/student.helpers";
import { getPaymentsSchema, PaymentsTableHeaderSchema } from "../../tableSchemas/GetPaymentsSchema";
import { IStudentPayment } from "../../types/studentPayments.types";
import EmptyDataComponent from "../EmptyData/EmptyDataComponent";
import { StudentTable } from "../Table/StudentTable";


function PaymentsRenderer() {
  const { user } = useAuth();

  const studentPaymentsQuery = useQuery(
    [StudentQueryConstants.STUDENT_PAYMENTS],
    () =>
      getStudentPayments(user?.id as number),
    { enabled: Boolean(user) }
  );


  const studentPaymentsNormalized = studentPaymentsTableAdapter(studentPaymentsQuery.data)


  return (
    <Spin spinning={studentPaymentsQuery.isLoading}>
      {!isEmpty(studentPaymentsQuery.data?.payments) ?
        <StudentTable<IStudentPayment>
          tableHeaderSchema={PaymentsTableHeaderSchema}
          renderSchema={getPaymentsSchema}
          dataSource={studentPaymentsNormalized}/>
        : <EmptyDataComponent dataTitle='payments'/>}
    </Spin>
  );
}


export default PaymentsRenderer;
