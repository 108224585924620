import { Divider, Modal, ModalProps, Row, Switch } from 'antd';
import React, { useState } from 'react';

import type { ColumnSettingController } from '../../../types/column.types';
import { Flex } from '../../general';

export type ColumnModalProps = ColumnSettingController & ModalProps;

export function ColumnModal({
  settings,
  onSettingSave,
  ...modalProps
}: ColumnModalProps) {
  const [columnSettings, changeSettings] = useState(() => ({ ...settings }));

  return (
    <Modal
      onOk={() => onSettingSave(columnSettings)}
      title="Table View"
      okText="Apply"
      {...modalProps}
    >
      <Row justify="space-between">
        {Object.entries(columnSettings).map(([key, isShowing]) => {
          return (
            <React.Fragment key={key}>
              <Flex justifyContent="space-between" style={{ width: '100%' }}>
                {key}
                <Switch
                  defaultChecked={isShowing}
                  onChange={(isChecked) => {
                    changeSettings((settings) => ({
                      ...settings,
                      [key]: isChecked,
                    }));
                  }}
                />
              </Flex>
              <Divider style={{ margin: '8px 0' }} />
            </React.Fragment>
          );
        })}
      </Row>
    </Modal>
  );
}
