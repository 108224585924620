import { Flex } from "../../../../../components/general";
import { useAuth } from "../../../../../contexts/useAuth.context";
import styles from '../AgreementCreator/AgreementCreator.module.css'

function Greeting() {
  const { user } = useAuth()

  return (
    <Flex direction='column' justifyContent='center' alignItems='center'>
      <h1 className={styles.studentGreetingHeader}>Hi, dear {user?.name}</h1>
      <p>Welcome to your personal account.</p>
    </Flex>
  );
}

export default Greeting;
