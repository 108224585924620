export const WedgedLevels = {
  LEVEL_0: 0,
  LEVEL_1: 1,
  LEVEL_2: 2,
}

export const WedgedDates = {
  TODAY: 'TODAY',
  THIS_WEEK: 'THIS_WEEK',
  THIS_MONTH: 'THIS_MONTH',
}
