import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { sheetsLinkPattern, urlRegex } from '../../../helpers/string.helpers';
import { CourseFormatEnum } from '../../../types/course.types';
import { CurrencyEnum } from '../../../types/currency.types';
import { FeeClientTypeEnum } from '../../../types/fee.types';
import { DayOfWeekEnum, TimeScaleEnum } from '../../../types/general.types';
import { GroupStateEnum, SelectionStatusEnum } from '../../../types/group.types';
import { InstructorTypeEnum } from '../../../types/instructor.types';
import { SalaryTypeEnum } from '../../../types/salary.types';

const { required, number, url, spreadsheetLink } = messages;

export const CreateEditGroupFormSchema = Yup.object().shape({
  courseId: Yup.number().required(required),
  projectCoordinatorId: Yup.number().required(required),
  groupState: Yup.mixed<GroupStateEnum>().oneOf(Object.values(GroupStateEnum)).required(required),
  courseFormat: Yup.mixed<CourseFormatEnum>().oneOf(Object.values(CourseFormatEnum)).required(required),
  evaluationLink: Yup.string().nullable(true).test(
    'is-url',
    url,
    (value) => {
      if (!value) {
        return true;
      }
      return urlRegex.test(value);
    }
  ),
  evaluationFormSpreadsheetLink: Yup.string().nullable(true).test(
    'is-url',
    spreadsheetLink,
    (value) => {
      if (!value) {
        return true;
      }
      return sheetsLinkPattern.test(value);
    }
  ),
  description: Yup.string().nullable(true),
  groupInstructors: Yup.array()
    .of(
      Yup.object().shape({
        instructorId: Yup.number().required(required),
        instructorType: Yup.mixed<InstructorTypeEnum>().oneOf(Object.values(InstructorTypeEnum)),
        salary: Yup.object()
          .shape({
            currency: Yup.mixed<CurrencyEnum>()
              .oneOf([null, ...Object.values(CurrencyEnum)])
              .nullable(true),
            amount: Yup.number().nullable(),
            salaryType: Yup.mixed<SalaryTypeEnum>()
              .oneOf([null, ...Object.values(SalaryTypeEnum)])
              .nullable(true),
          })
          .nullable(),
      }),
    )
    .nullable(true),
  startDate: Yup.string().required(required),
  duration: Yup.object().shape({
    value: Yup.number().required(required),
    timeScale: Yup.mixed<TimeScaleEnum>().oneOf(Object.values(TimeScaleEnum)).required(required),
  }),
  tuitionFee: Yup.object().shape({
    currency: Yup.mixed<CurrencyEnum>().oneOf(Object.values(CurrencyEnum)).required(required),
    amount: Yup.number().required(required),
    feeType: Yup.mixed<FeeClientTypeEnum>().oneOf(Object.values(FeeClientTypeEnum)).required(required),
    prePaymentPercent: Yup.number().typeError(number).nullable(true),
  }),
  schedule: Yup.array().when('groupState', {
    is: (groupState: string) => groupState === GroupStateEnum.ONGOING,
    then: Yup.array()
      .of(
        Yup.object().shape({
          dayOfWeek: Yup.mixed<DayOfWeekEnum>().oneOf(Object.values(DayOfWeekEnum)).required(required),
          startHour: Yup.string().required(required),
          endHour: Yup.string().required(required),
        }),
      )
      .required(required),
    otherwise: Yup.array().of(
      Yup.object().shape({
        dayOfWeek: Yup.mixed<DayOfWeekEnum>().oneOf(Object.values(DayOfWeekEnum)),
        startHour: Yup.string(),
        endHour: Yup.string(),
      }),
    ),
  }),
  isFinal: Yup.boolean().default(false),
  selectionStatus: Yup.mixed<SelectionStatusEnum>()
    .oneOf(Object.values(SelectionStatusEnum))
    .default(SelectionStatusEnum.OPEN),
  size: Yup.number().required(required).max(80),
  agreement_3_1_point: Yup.string().nullable(true),
});
