import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ConfirmModal } from '../../components/display/ConfirmModal/ConfirmModal';
import { Flex } from '../../components/general';
import { Copy, Edit } from '../../components/general/Icons/Icons';
import { ApplicantStatusOptions } from '../../constants/applicant.constants';
import { PaymentOptions } from '../../constants/paymentTypes.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { getFormattedDate } from '../../helpers/date.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { upperFirstLetter } from '../../helpers/string.helpers';
import { useEntityId, useFilter } from '../../hooks/useFilter.hooks';
import { addApplicantComment, updateApplicantComment } from '../../services/applicant.service';
import { updateApplicantPaymentByPaymentId } from '../../services/payment.service';
import { StudentStatusEnum } from '../../types/applicant.types';
import type { RenderColumn } from '../../types/column.types';
import { EventTypes } from '../../types/event.types';
import { ConfirmModalContent } from '../../types/modal.types';
import type { IExtendedGroupStudents, IGroupStudents, IPayment } from '../../types/payment.types';

const { Text } = Typography;

type Props = {
  student: IPayment | null;
};

export const StudentNamePaymentsColumn: RenderColumn<IExtendedGroupStudents> = (_, student) => {
  const handleClick = () => navigator.clipboard.writeText(student.email);

  return (
    <div style={{ overflow: 'auto' }}>
      <Text>{student.name}</Text> <br />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          overflow: 'auto',
        }}
      >
        <Text type="secondary">{student.email}</Text>
        <Button size="small" onClick={handleClick}>
          <Copy />
        </Button>
      </div>
    </div>
  );
};

export const StudentPhonePaymentsColumn: RenderColumn<IExtendedGroupStudents> = (_, student) => (
  <div>
    <Text>{student.phoneNumber}</Text>
  </div>
);
export const StudentPaymentsMethodColumn: RenderColumn<IExtendedGroupStudents> = (_, student) => (
  <div>
    <Text>{upperFirstLetter(student?.method ?? '')}</Text>
  </div>
);
export const StudentPaymentsTypeColumn = ({ paymentType }: { paymentType: string }) => (
  <>
    <div>
      <Text>{PaymentOptions[paymentType].label || ''}</Text>
    </div>
  </>
);

export const StudentStatusPaymentsColumn: RenderColumn<IExtendedGroupStudents> = (
  _,
  { status }: { status: StudentStatusEnum },
) => (
  <div>
    <Text>{ApplicantStatusOptions[status].label}</Text>
  </div>
);

export const StudentPaymentsDebtColumn = ({ student }: Props) => (
  <div>
    <Text>{student?.debt}</Text>
  </div>
);

export const PaymentGroupMonthsColumn = ({
  payment,
  paymentContent,
}: {
  payment: IPayment;
  paymentContent: IGroupStudents;
}) => {
  const paymentData = useMemo(
    () =>
      paymentContent.applicantPaymentDetailResponseModels.find(
        (applicantPayment) => applicantPayment.payment?.id === payment.id,
      ) ?? ({} as IPayment),
    [paymentContent, payment],
  );

  const paymentAmountPerWeek = paymentData.amount;
  const isPaid = paymentData.isPaid;
  const invoice = paymentData.invoice;

  const hasPayment = Object.keys(paymentData).length;

  const initialPaymentState = paymentAmountPerWeek ?? paymentContent.amount;
  const [paymentAmount, setPaymentAmount] = useState<string | number>(initialPaymentState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInvoiceModalVisible, setInvoiceModalVisible] = useState(false);

  const { id: groupId } = useParams();
  const cache = useQueryClient();

  const updateGroupPaymentMutation = useMutation(updateApplicantPaymentByPaymentId, {
    onSuccess: () => {
      cache.invalidateQueries([UseQueryTypes.GROUP_PAYMENT, groupId]);
      cache.invalidateQueries(UseQueryTypes.APPLICANT_PAYMENT);
    },
  });

  useEffect(() => {
    setPaymentAmount(paymentData.amount);
  }, [paymentData]);

  const handleSubmit = async () => {
    if (!invoice) {
      setIsModalVisible(false);
      return setInvoiceModalVisible(true);
    }
    const updateGroupPaymentMutationFn = () => {
      return updateGroupPaymentMutation.mutateAsync({
        id: paymentData?.id,
        payment: {
          applicantId: paymentContent.id,
          isPaid: true,
          amount: Number(paymentAmount),
          status: paymentContent.status,
        },
      });
    };

    await withCatch(updateGroupPaymentMutationFn);

    setIsModalVisible(false);
  };

  const handleSave = (e: any) => {
    const inputLastValidItems = ['', '0'];
    e.preventDefault();
    setPaymentAmount((prev) => {
      if (inputLastValidItems.includes(e.target.value)) {
        return e.target.value;
      }
      return Number(e.target.value) || prev;
    });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === EventTypes.Enter) {
      handleSave(e);
      e.target.blur();
    }
  };

  const optionalButtonStyles = isPaid
    ? {
        backgroundColor: 'green',
      }
    : {
        cursor: 'pointer',
        backgroundColor: 'red',
      };

  const confirmModalTitle = 'Are you sure to mark this payment as paid?';

  const confirmModalContent: ConfirmModalContent = {
    name: paymentContent.name,
    date: getFormattedDate(paymentData.date),
    amount: `${paymentAmount} ${paymentContent.currency}`,
  };

  const checkInvoiceModalTitle = 'Please attach invoice before approving the payment!';

  const confirmInvoiceModalContent: ConfirmModalContent = {
    name: paymentContent.name,
  };

  return (
    <>
      <Flex direction="row" alignItems="center" justifyContent="center">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!hasPayment ? (
            ''
          ) : (
            <button
              onClick={() => setIsModalVisible(true)}
              style={{
                border: '0',
                height: '15px',
                width: '15px',
                borderRadius: '50%',
                ...optionalButtonStyles,
              }}
              disabled={isPaid}
            ></button>
          )}
        </div>
        {!hasPayment ? (
          ''
        ) : (
          <>
            <div style={{ margin: '0 10px' }}>
              <Input
                value={paymentAmount}
                onBlur={handleSave}
                onChange={handleSave}
                onKeyPress={handleKeyPress}
                readOnly={isPaid}
              />
            </div>
            <div>
              <span>{paymentContent.currency}</span>
            </div>
          </>
        )}
      </Flex>

      {!!hasPayment && (
        <Flex style={{ height: 20 }}>
          {invoice ? (
            <a href={invoice.link} rel="noreferrer" target="_blank">
              {invoice.name}
            </a>
          ) : (
            <p>no assigned invoice</p>
          )}
        </Flex>
      )}

      <ConfirmModal
        title={confirmModalTitle}
        content={confirmModalContent}
        isModalVisible={isModalVisible}
        handleSubmit={handleSubmit}
        handleTxt="Confirm"
        onCancel={() => setIsModalVisible(false)}
      />
      <ConfirmModal
        title={checkInvoiceModalTitle}
        content={confirmInvoiceModalContent}
        isModalVisible={isInvoiceModalVisible}
        handleSubmit={() => setInvoiceModalVisible(false)}
        handleTxt="Confirm"
        onCancel={() => setInvoiceModalVisible(false)}
      />
    </>
  );
};

export const PaymentGroupMonthsTitle = ({
  payment,
  form,
}: {
  index: number;
  payment: IPayment;
  form: AppFormsEnum;
}) => {
  const { changeOptions } = useFilter({ applicantId: 0 });
  const { openDrawer } = useDrawer();

  const handleClick = (e: any) => {
    e.preventDefault();

    if (payment) {
      changeOptions({
        editId: payment.id,
        filterOptions: { applicantId: payment.applicant },
      });
    }

    openDrawer(form, 'Edit Payment Date');
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex direction="column">
        <Text>{getFormattedDate(payment.date)}</Text>
      </Flex>
      <Button size="small" onClick={handleClick}>
        <Edit color="#5451FF" />
      </Button>
    </Flex>
  );
};

export const StudentPaymentsCommentColumn: RenderColumn<IExtendedGroupStudents> = (_, student) => {
  const cache = useQueryClient();

  const [valueState, setValueState] = useState(student?.comment?.content);

  const updateApplicantCommentMutation = useMutation(updateApplicantComment);

  const addApplicantCommentMutation = useMutation(addApplicantComment, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.APPLICANT_ID),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueState(e.target.value);
  };

  const handleSave = async (e: any) => {
    const inputValue = e.target.value;

    const commentData = {
      content: inputValue ?? ' ',
    };

    const updateApplicantComment = () => {
      return updateApplicantCommentMutation.mutateAsync({
        commentId: Number(student?.comment?.id),
        comment: commentData,
      });
    };

    const createApplicantComment = () => {
      return addApplicantCommentMutation.mutateAsync({
        applicantId: Number(student?.id),
        comment: commentData,
      });
    };

    const createOrUpdateComment = async () => {
      e.preventDefault();

      if (student?.comment !== undefined) {
        await withCatch(updateApplicantComment);
        return;
      }
      await withCatch(createApplicantComment);
    };

    if (e.key === EventTypes.Enter) {
      await createOrUpdateComment();
      e.target.blur();
      setValueState(inputValue);
      return;
    }
  };

  const handleBlur = async () => {
    setValueState(student?.comment?.content);
  };

  useEffect(() => {
    setValueState(student?.comment?.content);
  }, [student?.comment?.content]);

  return (
    <div>
      <Input value={valueState} onBlur={handleBlur} onChange={handleChange} onKeyPress={handleSave} />
    </div>
  );
};

export const StudentDataEditColumn: RenderColumn<IExtendedGroupStudents> = (_, student) => {
  const [hover, setHover] = useState(false);
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleClick = (e: any) => {
    e.preventDefault();
    changeId(student?.id);
    openDrawer(AppFormsEnum.EditApplicantForm, 'Edit Student info');
  };

  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <button
        onMouseOver={handleMouseIn}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
        style={{
          border: '0',
          background: hover ? '#5451ff33' : 'transparent',
          borderRadius: '2px',
          cursor: 'pointer',
        }}
      >
        <EditOutlined />
      </button>
    </Flex>
  );
};

export const CreateApplicantPaymentColumn = ({ student }: { student: IPayment | null }) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleClick = (e: any) => {
    e.preventDefault();

    if (student?.applicant) {
      changeId(student?.applicant);
    }

    openDrawer(AppFormsEnum.CreateApplicantPaymentForm, 'Create Applicant Payment');
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Button onClick={handleClick} style={{ minWidth: 160 }} disabled={!student?.debt}>
        Create Payment
      </Button>
    </Flex>
  );
};
