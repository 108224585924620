import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { sortBy } from 'lodash';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import ResultSummary from '../../components/display/ResultSummary/ResultSummary';
import {Button, Search} from '../../components/general';
import { PageSizeOptions } from '../../constants/sorters.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { useTable } from '../../hooks/table/useTable';
import { useApplicantStatistic } from '../../hooks/useApplicantStatistic.hooks';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { remindersColumn } from '../../schemas/reminders/reminders.schema';
import { filterReminders, getReminderCount } from '../../services/reminder.service';
import { useAppDispatch } from '../../store';
import { sharedSelectsActions } from '../../store/features/sharedSelects/sharedSelectsSlice';
import { IReminder, IReminderFilter } from '../../types/reminder.types';
import { ApplicantsStatistics } from '../Applicants/components/applicantsStatistics';

export default function Reminder() {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { handleSearch, term } = useSearchHandler();
  const { filterOptions, sorterOptions, resetOptions } = useFilter<IReminderFilter>({});

  const { openDrawer } = useDrawer();

  const rowSelectionAdapter = useRowSelectionAdapter<IReminder>();

  const selectedRemindersCount = rowSelectionAdapter.selectedRows.length;

  const reminderTable = useTable<IReminder>(remindersColumn);

  const remindersQuery = useQuery([UseQueryTypes.REMINDER, sorterOptions, filterOptions], () => {
    const filters = {...filterOptions};
    const reminderId = Number(search.split('=').pop());
    if (reminderId) {
      Object.assign(filters, {reminderIds: [reminderId]})
    }
    return filterReminders({
        filters,
        sorters: sorterOptions,
      })
    }
  );

  const { today, thisWeek, thisMonth } = useApplicantStatistic(getReminderCount, filterOptions);

  const sortedReminders = sortBy(remindersQuery.data?.reminders, ['status']);

  const reminderTotalElements = remindersQuery.data?.totalElements ?? 0;

  const isScreenLoading = remindersQuery.isLoading;

  const handleOpenBulkUpdateReminderForm = async () => {
    dispatch(
      sharedSelectsActions.selectItems(
        rowSelectionAdapter.selectedRows.map(({ id, status }: IReminder) => ({ id, status })),
      ),
    );
    openDrawer(AppFormsEnum.BulkUpdateReminderStatusForm, 'Bulk Update Reminder');
  };

  const handleReset = () => {
    resetOptions();
  };


  useEffect(() => {
    return rowSelectionAdapter.clean;
  }, []);

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row justify="start" gutter={[8, 8]}>
              <Col>
                <Search placeholder="Search" defaultValue={term} onChange={handleSearch} />
              </Col>
              <Col>
                <Button style={{ minHeight: '46px' }} onClick={handleReset}>Reset Filters</Button>
              </Col>
              <ApplicantsStatistics today={today ?? 0} thisWeek={thisWeek ?? 0} thisMonth={thisMonth ?? 0} />
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between" style={{ marginBottom: 5 }}>
              <ResultSummary text={`${reminderTotalElements} result found`} />
            </Row>

            <Row align='bottom' justify="space-between" style={{ width: '100%', paddingBottom: '8px', columnGap: '8px', rowGap: '8px' }}>
              {selectedRemindersCount > 0 && <ResultSummary text={`Selected ${selectedRemindersCount} reminders`} />}
              {rowSelectionAdapter.isSelected && (
                <Col>
                  <Button variant="primary" onClick={handleOpenBulkUpdateReminderForm}>
                    Bulk Status Update
                  </Button>
                </Col>
              )}
            </Row>
          </Col>

          <Col span={24}>
            <Table<IReminder>
              {...reminderTable.getTableProps({
                rowKey: (reminder) => reminder.id,
                dataSource: sortedReminders ?? [],
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />

            <Pagination
              {...reminderTable.getPaginationProps({
                total: reminderTotalElements,
                style: { marginTop: 20 },
                defaultPageSize: PageSizeOptions.defaultPageSize,
              })}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
