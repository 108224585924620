import React, { useRef } from 'react';

interface IFileUpload {
  onUpload: (files: FileList) => void;
}

export const FileUpload: React.FC<IFileUpload> = ({ children, onUpload }) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const childWithClick = React.Children.map(children, (child) => {
    // @TODO: figure out type

    // @ts-ignore
    return React.cloneElement(child, {
      onClick: () => fileUploadRef.current?.click(),
    });
  });

  return (
    <>
      {childWithClick}
      <input
        style={{ display: 'none' }}
        ref={fileUploadRef}
        type="file"
        onChange={(e) => onUpload(e.target.files!)}
      />
    </>
  );
};
