import { NEW_LINE } from '../../constants/constants';
import {
  IBulkCreateStudentsFormData,
  RegistrationSource,
} from '../../types/applicant.types';

const splitBy = (string: string, sign: string) => {
  return string.trim().split(sign);
};

export const parseBulkCreateStudentsFormData = (
  studentsData: Partial<IBulkCreateStudentsFormData>,
): any[] => {
  const { bulkEmails, bulkPhoneNumbers, bulkNames, status } = studentsData;
  const emails = splitBy(bulkEmails!, NEW_LINE);
  const phones = splitBy(bulkPhoneNumbers!, NEW_LINE);
  const names = splitBy(bulkNames!, NEW_LINE);

  const result = names.reduce((acc: any, curr: any, idx: number) => {
    const applicant = {
      name: curr,
      email: emails[idx].trim(),
      phoneNumber: phones[idx],
      registrationSource: RegistrationSource.MANUAL,
      status,
    };
    acc = [...acc, applicant];
    return acc;
  }, []);

  return result;
};
