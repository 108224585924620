export const createOptions = (
  datas: any[] = [],
  labelType: string,
  valueType: string,
  labelFormatter?: (label: any) => {},
  valueFormatter?: (value: any) => {},
) => {
  return datas.reduce((acc, data: { [key: string]: any }) => {
    return {
      ...acc,
      [data[valueType]]: {
        label: typeof labelFormatter === 'function' ? labelFormatter(data[labelType]) : data[labelType],
        value: typeof valueFormatter === 'function' ? valueFormatter(data[valueType]) : data[valueType],
      },
    };
  }, {});
};
