import { IApplicantDetailAbsentees } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';
import { GroupColumn } from '../applicantDetail.renderer';

export const absenteesColumns: ColumnSchema<IApplicantDetailAbsentees> = {
  uuid: 'absenteesColumns',
  schema: {
    name: {
      title: 'Group',
      width: 400,
      render: (groupName, { groupId }) => GroupColumn({groupName}, groupId),
    },
    absentees: {
      title: 'Count of absentees',
      width: 200,
      render: (absentees) => absentees,
    },
  },
};
