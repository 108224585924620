import { useAuth } from '../../../../../../contexts/useAuth.context';
import { CreateGroupBtn } from '../../../../../../pages/Groups/components/createGroupBtn';
import { AuthGroup } from '../../../../../../types/auth.types';
import { IDashboardMenuDynamicItems } from '../../../../types/dashboard.types';

export const dashboardMenuDynamicItems: IDashboardMenuDynamicItems = {
  [AuthGroup.USER]: {
    groups: <CreateGroupBtn />
  },
  [AuthGroup.STUDENT]: {},
  [AuthGroup.INSTRUCTOR]: {},
}

const MenuItem = ({ name }: { name: string }) => {
  const { user } = useAuth();
  const Component = dashboardMenuDynamicItems[user?.authGroup as keyof typeof dashboardMenuDynamicItems]?.[name];

  return Component ? Component : null;
}

export default MenuItem;
