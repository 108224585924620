import type { IGroup } from '../types/group.types';

export const haveGroupSchedule = (group: IGroup) => group.schedule.length !== 0;

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPaymentPercentage = (amount: number, prePaymentPercent?: number) => {
  return prePaymentPercent ? (amount / 100 * prePaymentPercent) : amount
}
