import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import {
  Button,
  Flex,
  Input as FormInput,
  SingleSelectFormik,
} from '../../components/general';
import { PaymentOptions } from '../../constants/paymentTypes.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { bulkUpdatePaymentType } from '../../services/payment.service';
import { useAppSelector } from '../../store';
import { selectItems } from '../../store/features/sharedSelects/sharedSelectsSlice';
import { IStudentBrief } from '../../types/applicant.types';
import type { IAppForm } from '../../types/form.types';
import { IUpdatePaymentType } from '../../types/payment.types';

const initialValues = { groupId: undefined, status: undefined };

export const BulkUpdatePaymentTypeForm: IAppForm = () => {
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();
  const selectedApplicants = useAppSelector(selectItems);
  const cache = useQueryClient();

  const { closeDrawer } = useDrawer();

  const bulkUpdateMutation = useMutation(bulkUpdatePaymentType);

  const handleBulkUpdateState = async (
    applicantState: Partial<IUpdatePaymentType>,
    formikHelpers: FormikHelpers<Partial<IUpdatePaymentType>>,
  ) => {
    const updateQuery: Partial<IUpdatePaymentType>[] = selectedApplicants.map(
      ({ id, applicantPaymentDetailResponseModels }) => {
        const paymentId = applicantPaymentDetailResponseModels[0].id;
        return { id, paymentId, ...applicantState };
      },
    );

    await withCatch(() => bulkUpdateMutation.mutateAsync(updateQuery), {
      onSuccess: async () => {
        formikHelpers.resetForm();
        await cache.invalidateQueries(UseQueryTypes.APPLICANT_PAYMENT);
        closeDrawer();
      },
      onError: () => {
        closeDrawer();
      },
    });

    await rowSelectionAdapter.clean();
  };

  return (
    <Spin spinning={bulkUpdateMutation.isLoading}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleBulkUpdateState}
      >
        {({ dirty, isValid, handleReset }) => (
          <Form
            name="bulk-update"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field
              label="Payments Type"
              name="paymentType"
              component={SingleSelectFormik}
              options={PaymentOptions}
            />

            <Field
              name="debt"
              label="Amount"
              component={FormInput}
              type="number"
              required
            />
            <ErrorMessage name="debt" component="span" className="error"/>

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button
                htmlType="button"
                style={{ minWidth: 160, marginRight: 8 }}
                onClick={() => handleReset()}
              >
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(dirty && isValid)}
              >
                Save
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
