import { Button, Form, Modal, Spin, Table } from 'antd';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Flex, SingleSelectFormik, TextArea } from '../../components/general';
import { Box } from '../../components/general/Box/Box';
import { ApplicantCreateStatusOptions } from '../../constants/applicant.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { parseBulkCreateStudentsFormData } from '../../helpers/applicants/normalizeBulkStudentsData.helper';
import { filterCourseById } from '../../helpers/course.helpers';
import {
  handleQueryError,
  handleQuerySuccess,
  withCatch,
} from '../../helpers/error.helpers';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useTable } from '../../hooks/table/useTable';
import { bulkCreateStudentsColums } from '../../schemas/applicants/applicant.schema';
import {
  bulkCreateApplicants,
  bulkUpdateApplicants,
} from '../../services/applicant.service';
import { getCourses } from '../../services/courses.service';
import {
  IBulkCreateStudentsFormData,
  ICreateStudent,
} from '../../types/applicant.types';
import { ICourse } from '../../types/course.types';
import { IAppForm } from '../../types/form.types';
import { BulkCreateStudentsFormSchema } from './schemas/bulkCreateStudents.form.schema';
import { GroupField } from './shared/GroupField';

type IBulkCreateStudent = Partial<ICreateStudent>;

export const BulkCreateStudentsForm: IAppForm = () => {
  const [applicantsModalVisible, setApplicantsModalVisible] = useState(false);
  const [students, setStudents] = useState<IBulkCreateStudentsFormData[]>();
  const [groupId, setGroupId] = useState<number>();
  const [courseId, setCourseId] = useState<number>();
  const [selectedCourse, setSelectedCourse] = useState<ICourse>();
  const cache = useQueryClient();

  const studentTable = useTable<IBulkCreateStudent>(bulkCreateStudentsColums);
  const { closeDrawer } = useDrawer();

  const coursesQuery = useQuery(UseQueryTypes.COURSES, getCourses);

  const successActions = () => {
    handleQuerySuccess();
    closeDrawer();
    setApplicantsModalVisible(false);
  };

  useEffect(() => {
    const course = filterCourseById(coursesQuery!.data!, courseId!);
    setSelectedCourse(course);
  }, [courseId, coursesQuery]);

  const coursesContext = useMemo(() => {
    return generateContext(coursesQuery.data || [], (course) => ({
      label: course.name,
      value: course.id,
      uuId: course.uuid,
    }));
  }, [coursesQuery.data]);

  const bulkUpdateApplicantsMutation = useMutation(bulkUpdateApplicants, {
    onSuccess: () => {
      successActions();
    },
    onError: () => {
      handleQueryError();
      setApplicantsModalVisible(false);
    },
  });

  const bulkCreateApplicantsMutation = useMutation(bulkCreateApplicants, {
    onSuccess: () => {
      successActions();
    },
    onError: () => {
      handleQueryError();
      setApplicantsModalVisible(false);
    },
  });

  const formatDataForCreateStudents = (student: any): ICreateStudent => {
    const { name, phoneNumber, email, registrationSource, status } = student;

    return {
      name,
      email,
      phoneNumber,
      registrationSource,
      status,
      groupId,
      courseId: selectedCourse!.id,
    };
  };

  const formatDataForModal = (student: Partial<ICreateStudent>) => {
    return {
      ...student,
      course: selectedCourse,
      group: {
        name: selectedCourse!.name,
        id: groupId || 'N/A',
      },
    };
  };

  const handleFormSubmit = (formData: Partial<IBulkCreateStudentsFormData>) => {
    setCourseId(formData.courseId);
    setGroupId(formData.groupId);
    setStudents(parseBulkCreateStudentsFormData(formData));
    setApplicantsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    const studentsData = students!.map(formatDataForCreateStudents);
    const mutationFunc = () =>
      bulkCreateApplicantsMutation.mutateAsync(studentsData);

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        closeDrawer();
      },
    });
  };


  const initialValues: Partial<IBulkCreateStudentsFormData> = {};

  const isLoading =
    bulkCreateApplicantsMutation.isLoading ||
    bulkUpdateApplicantsMutation.isLoading;

  return (
    <Spin spinning={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={BulkCreateStudentsFormSchema}
      >
        {(props) => {
          return (
            <Form style={{ maxWidth: '800px' }}>
              <Flex justifyContent="space-between">
                <Flex>
                  <Box title="Names">
                    <Field
                      placeholder="e.g  Naira Yeghiazaryan"
                      name="bulkNames"
                      component={TextArea}
                      style={{ height: '450px' }}
                      required
                    />
                    <ErrorMessage
                      name="bulkNames"
                      component="span"
                      className="error"
                    />
                  </Box>
                </Flex>
                <Flex style={{ margin: '0 10px' }}>
                  <Box title="Emails">
                    <Field
                      placeholder="e.g  naira@aca.am"
                      name="bulkEmails"
                      component={TextArea}
                      style={{ height: '450px' }}
                      required
                    />
                    <ErrorMessage
                      name="bulkEmails"
                      component="span"
                      className="error"
                    />
                  </Box>
                </Flex>
                <Flex>
                  <Box title="Phones">
                    <Field
                      placeholder="e.g  +37489898989"
                      name="bulkPhoneNumbers"
                      component={TextArea}
                      style={{ height: '450px' }}
                      required
                    />
                    <ErrorMessage
                      name="bulkPhoneNumbers"
                      component="span"
                      className="error"
                    />
                  </Box>
                </Flex>
              </Flex>
              <Field
                name="status"
                label="Status"
                component={SingleSelectFormik}
                options={ApplicantCreateStatusOptions}
                required
              />
              <ErrorMessage name="status" component="span" className="error"/>
              <Field
                label="Course"
                name="courseId"
                component={SingleSelectFormik}
                options={coursesContext}
                required
              />
              <ErrorMessage
                name="courseId"
                component="span"
                className="error"
              />
              <GroupField name="groupId"/>
              <Flex
                justifyContent="space-between"
                style={{ marginTop: '25px' }}
              >
                <Button
                  style={{ minWidth: 250, height: '40px', borderRadius: '5px' }}
                  onClick={() => {
                    closeDrawer();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ minWidth: 250, height: '40px', borderRadius: '5px' }}
                  htmlType="submit"
                  type="primary"
                  onClick={props.submitForm}
                  disabled={!(props.isValid && props.dirty)}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <Modal
        title="Course Invitation"
        visible={applicantsModalVisible}
        onOk={handleModalSubmit}
        okText="Send"
        confirmLoading={isLoading}
        onCancel={() => {
          setApplicantsModalVisible(false);
        }}
        width={800}
      >
        <Table
          {...studentTable.getTableProps({
            rowKey: (student) => student!.email!,
            dataSource: students?.map(formatDataForModal),
          })}
        />
      </Modal>
    </Spin>
  );
};
