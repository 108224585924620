import { useFormik } from 'formik'
import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';

import { Flex, Input } from "../../../../../components/general";
import Button from "../../../../../components/general/Button/Button";
import { handleQueryError } from "../../../../../helpers/error.helpers";
import { loadState } from "../../../../../helpers/localStorage.helpers";
import { resetPasswordToken } from '../../../../../services/auth.service';
import { sendMassMailing } from "../../../../../services/mail.service";
import { SendMail } from "../../../../../types/mail.types";
import { ForgotPasswordResponseMassages } from '../../../constants/responseMassages';
import {
  sendEmailInitialValues as initialValues,
  sendEmailSchema as validationSchema
} from '../../../schemas/forgetPassword.schema';
import { ForgetPasswordViewsEnum } from '../../../types/forgetPasswordForm.types';
import styles from "../../ForgetPassword/ForrgetPassword.module.css";

const { REACT_APP_ORIGIN } = process.env;

interface props {
  changeView: Dispatch<SetStateAction<ForgetPasswordViewsEnum>>
}

export const SendEmailForm = ({ changeView }: props) => {
  const authGroup = loadState('authGroup');

  const resetPasswordTokenMutation = useMutation(resetPasswordToken);

  const onSubmit = async ({ email }: { email: string },props: any) => {
    try {
      const res = await resetPasswordTokenMutation.mutateAsync({email, authGroup});
      if(res.exist === false) {
        return props.setFieldError('email',ForgotPasswordResponseMassages.USER_DOESNT_EXIST)
      }

      const mailState: Partial<SendMail> = {
        to: email,
        password: "02f7958",
        template: 'reset-password',
        from: 'ACA Services Info@aca.am',
        tags: [],
        html: null,
        variables: {
          RESET_PASSWORD_LINK: `${REACT_APP_ORIGIN}reset-password/${email}/${res.token}/${authGroup}`,
        },
        subject: `Reset Password`,
      };

      await sendMassMailing(mailState);
      changeView(ForgetPasswordViewsEnum.FORGET_PASSWORD_SUCCESS)
    } catch (e) {
      handleQueryError()
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    dirty,
    isValid,
    isSubmitting
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  return (
    <>
      <>
        <h2 className={styles.title}>Forget Password</h2>
        <p className={styles.subtitle}>
          We will send you a verification email
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            type='text'
            label='Email'
            name='email'
            id='email'
            value={values.email}
            placeholder='Please enter your email'
            onChange={handleChange}
            onBlur={handleBlur}
          />
          { (errors.email && touched.email) && <div className="error">{errors.email}</div>}
          <Flex
            style={{ margin: '10px 0' }}
            alignItems='center'
            justifyContent='flex-end'
          >
            <Button
              className={styles.button}
              style={{ padding: '16px 24px' }}
              htmlType='submit'
              variant='primary'
              disabled={isSubmitting || !isValid || !dirty}
            >
              Send reset email
            </Button>
          </Flex>
        </form>
      </>
    </>
  );
};
