import { IDashboardLinks } from '../types/path.types';

export const getPathData = ({
  path,
  defaultLink,
  dashboardLinks,
}: {
  path?: string;
  dashboardLinks: IDashboardLinks;
  defaultLink: keyof typeof dashboardLinks;
}): string => {
  const foundPathData = Object.values(dashboardLinks).find((linkData) => linkData.path === path);

  return foundPathData ? foundPathData.name : dashboardLinks[defaultLink].name;
};
