import { ApplicantCreateStatusOptions } from '../constants/applicant.constants';
import { RegistrationSource, StudentStatusEnum } from './applicant.types';
import { IExamColumn } from './exam.types';
import { IFeedback } from './feedback.types';
import { IPayment } from './payment.types';

export enum ApplicantDetailTypes {
  general = 'general',
  applications = 'applications',
  exams = 'exams',
  consultations = 'consultations',
  comments = 'comments',
  feedbacks = 'feedbacks',
  actions = 'actions',
  payments = 'payments',
  attachments = 'attachments',
  absentees = 'absentees',
}

export interface IApplicantDetailGeneral {
  created_at: string;
  email: string;
  id: number;
  name: string;
  phoneNumber: string;
  status: keyof typeof ApplicantCreateStatusOptions;
  groupName: string;
}

export interface IApplicantDetailAbsentees {
  id: number;
  groupId: number;
  name: string;
  absentees: number;
}

export interface IApplicantDetailExamColumn extends IExamColumn {
  groupName: string;
  groupTutor: string;
}

export type OmitApplicantDetailFeedback = 'isPrivate' | 'user' | 'instructor' | 'ownerAvatar';

export interface IApplicantDetailFeedback extends Omit<IFeedback, OmitApplicantDetailFeedback> {
  ownerEmail: string;
}

export type OmitPayment = 'description' | 'currencyClientType' | 'currency' | 'applicant';

export interface IApplicantDetailPayment extends Omit<IPayment, OmitPayment> {
  invoiceLink: string;
  name: string;
  groupName: string;
  groupId: number;
}

export interface IApplicantDetailAction {
  id: number;
  description: string;
  created_at: string;
  status: Partial<StudentStatusEnum>;
  userId: number;
  username: string;
  avatar: string;
  groupId: number;
  groupName: string;
  courseId: number;
  courseName: string;
}

export interface IApplicantDetailApplication {
  id: number;
  uuid: string;
  created_at: string;
  status: StudentStatusEnum;
  registrationSource: RegistrationSource;
  hasAccount: boolean;
  groupName: string;

  courseName: string;
  groupId: number;
  selectApplication: () => void
}

export type IGeneralSection = { id: number; title: string; content: string; span?: number };

export interface IApplicantPromoCode {
  id: number;
  promoCode: string;
  created_at: Date;
}
