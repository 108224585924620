import { Col, Divider, Modal, Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Button,
  Flex,
  Input as FormInput,
  SingleSelectFormik,
  TextArea,
} from '../../components/general';
import { Box } from '../../components/general/Box/Box';
import { getButtonVariant } from '../../components/general/Button/Button';
import { RichTextEditor } from '../../components/general/RichText/RichText';
import { TextAreaVariants } from '../../components/general/TextArea/TextArea';
import { MailgunVariables } from '../../constants/mailgun.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { withCatch } from '../../helpers/error.helpers';
import { generateContext } from '../../helpers/generateContext.helpers';
import { mailTemplateNormalizer } from '../../helpers/string.helpers';
import { useUserAdditionalData } from '../../hooks/useUserAdditionalData.hook';
import {
  createEmail,
  getMailTemplateByName,
  getMailTemplates,
  sendMassMailing,
} from '../../services/mail.service';
import { useAppDispatch, useAppSelector } from '../../store';
import { mailingActions, selectMailingFormValues } from '../../store/features/mailing/mailingSlice';
import { SendMail } from '../../types/mail.types';
import { MailingFormSchema } from './schemas/massMailing.forms.schema';
import {
  EmailField,
  FullNameField,
  TagsField,
} from './shared/mailingForm.fields';

enum MailingEnum {
  MAILING_LIST = 'MAILING_LIST',
  RECIPIENTS_LIST = 'RECIPIENTS_LIST',
}

enum TemplateEnum {
  TEMPLATE_LIST = 'TEMPLATE_LIST',
  CUSTOM_TEMPLATE = 'CUSTOM_TEMPLATE',
}

const getTemplateBodyAndNormalize = async (templateName: string) => {
  const templateBodyResponse = await getMailTemplateByName(templateName);

  const templateBodyResponseContent =
    templateBodyResponse.template.template.version.template;

  return mailTemplateNormalizer({
    mailTemplate: templateBodyResponseContent,
    templateVariables: MailgunVariables,
    values: {},
  });
};

export const MassMailingForm = () => {
  const cache = useQueryClient();
  const variables = useUserAdditionalData();
  const dispatch = useAppDispatch();
  const [templateBody, setTemplateBody] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateLoader, setTemplateLoader] = useState(false);
  const [mailOptions, setMailOptions] = useState<Partial<SendMail>>({});
  const formInitialValues = useAppSelector(selectMailingFormValues);
  const sendEmailMutation = useMutation(sendMassMailing);

  const [mailingField, setMailingField] = useState<MailingEnum>(
    MailingEnum.RECIPIENTS_LIST,
  );

  const [templateField, setTemplateField] = useState<TemplateEnum>(
    TemplateEnum.CUSTOM_TEMPLATE,
  );

  const toggleModal = () => {
    setIsModalVisible((prev) => !prev);
  };

  useEffect(() => {
    dispatch(mailingActions.setInitialValues({ ...formInitialValues, variables  }));
  }, [variables, dispatch]);

  const handleSubmit = async (mailOptions: Partial<SendMail>) => {
    toggleModal();

    switch (templateField) {
      case TemplateEnum.TEMPLATE_LIST:
        setTemplateLoader(true);
        const normalizedTemplateBody = await getTemplateBodyAndNormalize(
          mailOptions.template as string,
        );

        setMailOptions({ ...mailOptions, html: null });
        setTemplateBody(normalizedTemplateBody);
        break;

      default:
        setMailOptions({
          ...mailOptions,
          template: null,
        });

        setTemplateBody(mailOptions?.html ?? '');
        break;
    }

    setTemplateLoader(false);
  };

  const handleOk = async () => {
    await toggleModal();
    await withCatch(() => sendEmailMutation.mutateAsync(mailOptions));

    const emailPost = {
      sender: mailOptions.from,
      subject: mailOptions.subject,
      tags: mailOptions.tags?.length ? mailOptions.tags : null,
      recipients: mailOptions.to,
      template: mailOptions.template || 'custom',
    };

    await createEmail(emailPost);
    await cache.invalidateQueries(UseQueryTypes.FILTER_EMAILS);
  };

  const mailTemplateListQuery = useQuery(
    UseQueryTypes.MAIL_TEMPLATE_LIST,
    getMailTemplates,
  );

  const mailListsContext = useMemo(() => {
    const listItems = mailTemplateListQuery.data?.lists?.items || [];
    return generateContext(listItems, (listItem) => ({
      label: listItem.name,
      value: listItem.address,
    }));
  }, [mailTemplateListQuery.data?.lists?.items]);

  const mailTemplatesContext = useMemo(() => {
    const templateItems = mailTemplateListQuery.data?.templates?.items ?? [];

    return generateContext(templateItems, (template) => ({
      label: template.name,
      value: template.name,
    }));
  }, [mailTemplateListQuery.data?.templates?.items]);

  const mailingFieldRenderer = () => {
    const mailingListElement = (
      <>
        <Field
          component={SingleSelectFormik}
          options={mailListsContext}
          label="Mailing Lists"
          name="to"
          required
        />

        <ErrorMessage name="to" component="span" className="error"/>
      </>
    );

    switch (mailingField) {
      case MailingEnum.MAILING_LIST:
        return mailingListElement;

      case MailingEnum.RECIPIENTS_LIST:
        return (
          <>
            <Field
              component={TextArea}
              variant={TextAreaVariants.InputSearcher}
              rows={7}
              label="Recipients"
              placeholder="e.g  vrezh@aca.am"
              name="to"
              required
            />
          </>
        );
      default:
        return mailingListElement;
    }
  };

  const TemplateField = useMemo(() => {
    const CustomTemplate = () => {
      const { setFieldValue, values } = useFormikContext();
      const value: any = values;

      const handleChangeCustomTemplate = (data: string) => {
        if (templateField !== TemplateEnum.CUSTOM_TEMPLATE) {
          setTemplateField(TemplateEnum.CUSTOM_TEMPLATE);
        }

        setFieldValue('html', data);
      };

      return (
        <RichTextEditor
          label="Custom template"
          onChange={handleChangeCustomTemplate}
          values={value}
          required
        />
      );
    };

    const TemplateList = () => (
      <>
        <Field
          component={SingleSelectFormik}
          options={mailTemplatesContext}
          label="Template List"
          name="template"
          required
        />
        <ErrorMessage name="template" component="span" className="error"/>
      </>
    );

    const template = {
      [TemplateEnum.CUSTOM_TEMPLATE]: CustomTemplate,
      [TemplateEnum.TEMPLATE_LIST]: TemplateList,
    };

    return template[templateField];
  }, [mailTemplatesContext, templateField]);

  return (
    <Spin spinning={sendEmailMutation.isLoading}>
      <Modal
        visible={isModalVisible}
        onCancel={toggleModal}
        onOk={handleOk}
        confirmLoading={templateLoader}
        title="Send Mass Email"
        okText="Send"
        width={1000}
      >
        <Spin spinning={templateLoader}>
          <div
            style={{ marginTop: '30px' }}
            dangerouslySetInnerHTML={{ __html: templateBody }}
          />
        </Spin>
      </Modal>

      <Formik
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        validationSchema={MailingFormSchema}
        enableReinitialize
      >
        {(props) => (
          <Form name="group-filter" style={{ minHeight: '100vh' }}>
            <Row style={{ marginBottom: 32 }} justify="end">
              <Col style={{ marginRight: 16 }}>
                <Button onClick={() => props.resetForm()} htmlType="reset">
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Send Mail
                </Button>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={24} span={24}>
                <Box title="General">
                  <Row justify="space-between">
                    <Col span={12}>
                      <FullNameField/>
                    </Col>
                    <Col span={11}>
                      <EmailField/>
                    </Col>
                  </Row>

                  <Row justify="space-between">
                    <Col span={12}>
                      <Field
                        component={FormInput}
                        label="Subject"
                        name="subject"
                        placeholder="e.g. Dear Students"
                        required
                      />
                      <ErrorMessage
                        name="subject"
                        component="span"
                        className="error"
                      />
                    </Col>
                    <Col span={11}>
                      <TagsField/>
                    </Col>
                  </Row>
                </Box>
              </Col>
              <Col style={{ marginTop: '20px' }} span={12}>
                <Box title="Template">
                  <Flex justifyContent="space-between">
                    <Button
                      htmlType="button"
                      variant={getButtonVariant(
                        templateField,
                        TemplateEnum.TEMPLATE_LIST,
                      )}
                      onClick={() => {
                        setTemplateField(TemplateEnum.TEMPLATE_LIST);
                      }}
                    >
                      Template Lists
                    </Button>
                    <Button
                      htmlType="button"
                      variant={getButtonVariant(
                        templateField,
                        TemplateEnum.CUSTOM_TEMPLATE,
                      )}
                      onClick={() => {
                        setTemplateField(TemplateEnum.CUSTOM_TEMPLATE);
                      }}
                    >
                      Custom Template
                    </Button>
                  </Flex>
                  <Divider/>
                  <TemplateField/>
                </Box>
              </Col>
              <Col style={{ marginTop: '20px' }} span={11}>
                <Box title="Recipients">
                  <Flex justifyContent="space-between">
                    <Button
                      htmlType="button"
                      variant={getButtonVariant(
                        mailingField,
                        MailingEnum.MAILING_LIST,
                      )}
                      onClick={() => setMailingField(MailingEnum.MAILING_LIST)}
                    >
                      Mailing List
                    </Button>
                    <Button
                      htmlType="button"
                      variant={getButtonVariant(
                        mailingField,
                        MailingEnum.RECIPIENTS_LIST,
                      )}
                      onClick={() =>
                        setMailingField(MailingEnum.RECIPIENTS_LIST)
                      }
                    >
                      Recipients
                    </Button>
                  </Flex>
                  <Divider />
                  {mailingFieldRenderer()}
                </Box>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
