import { useQuery } from "react-query";

import { useAuth } from "../../../../contexts/useAuth.context";
import { getStudentFeedbacks } from "../../../../services/student.service";
import FeedbacksRenderer from "../../components/Feedbacks/FeedbacksRenderer";
import { StudentQueryConstants } from "../../constants/studentQuery.constants";

const GivenFeedbacks = () => {
  const { user } = useAuth()
  const studentFeedbacks = useQuery([StudentQueryConstants.STUDENT_FEEDBACKS], () => getStudentFeedbacks(user?.id as number), { enabled: !!user })

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <FeedbacksRenderer feedbacksData={studentFeedbacks}/>
    </div>
  );
};

export default GivenFeedbacks;
