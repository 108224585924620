import classNames from "classnames";

import { Flex } from "../../../../../components/general";
import { IAgreementProcess } from "../../../types/studentAgreement.types";
import styles from './AgreementCreationProcessRenderer.module.css';

const StateRenderer = ({
  stateValue,
  text,
  filled
}: { stateValue: number | string, text: string, filled?: boolean }) => {
  const stateStyles = classNames({
    [styles.stateRenderer]: true,
    [styles.filled]: filled,
    [styles.empty]: !filled
  });

  const stateTextStyles = classNames({
    [styles.text]: true,
    [styles.textFilled]: filled,
    [styles.textEmpty]: !filled,
  });

  const lineStyles = classNames({
    [styles.line]: true,
    [styles.lineFilled]: filled,
    [styles.lineEmpty]: !filled
  })


  return <div className={styles.stateRendererItem}>
    <div className={lineStyles}></div>
    <Flex justifyContent='center' alignItems='center' className={stateStyles}>{stateValue}</Flex>
    <p className={stateTextStyles}>{text}</p>
  </div>
}


function AgreementCreationProcessRenderer({
  currentProcess,
  processList
}: { currentProcess: IAgreementProcess, processList: IAgreementProcess[] }) {
  return (
    <div className={styles.processContainer}>
      <div className={styles.statesWrapper}>
        {processList.map(({ state, id, text }) => (
            <StateRenderer filled={state <= currentProcess.state} text={text} stateValue={state} key={id}/>
          )
        )}
      </div>
    </div>
  );
}

export default AgreementCreationProcessRenderer;
