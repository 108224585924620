import { Typography } from "antd";

import { Flex } from "../../components/general";
import { Star } from "../../components/general/Icons/Icons";
import { IStudentGivenFeedback } from "../../modules/student/types/studentFeedback.types";
import { DateService } from "../../services/date.service";
import { RenderColumn } from "../../types/column.types";

const { Text } = Typography

export const StudentNameColumn: RenderColumn<IStudentGivenFeedback> = (
  applicant
) => {
  return (
    <div>
      <Text>{applicant.name}</Text> <br/>
      <Text type="secondary">{applicant.email}</Text>
    </div>
  );
};

export const RatingColumn: RenderColumn<IStudentGivenFeedback> = (
  rating
) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Text>{rating}</Text> <Star width={10} color={'#5451FF'} style={{ marginLeft: 5 }} />
    </Flex>
  );
};

export const DateColumn: RenderColumn<IStudentGivenFeedback> = (created_at) => {
  return <div>{DateService.getFullDate(created_at)}</div>;
};