import { AttachmentTypeOptions } from '../../../constants/attachment.constants';
import { IApplicantAttachment } from '../../../types/applicant.types';
import { AttachmentType } from '../../../types/attachment.types';
import { ColumnSchema } from '../../../types/column.types';
import { InvoiceColumn } from '../applicantDetail.renderer';

export const attachmentColumns: ColumnSchema<IApplicantAttachment> = {
  uuid: 'attachmentColumn',
  schema: {
    name: {
      title: 'Name',
      render: (name) => name,
    },
    attachmentType: {
      title: 'Type',
      render: (type: AttachmentType) => AttachmentTypeOptions[type]?.label,
    },
    link: {
      title: 'Link',
      width: 175,
      render: InvoiceColumn,
    },
  },
};
