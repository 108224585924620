import { Checkbox, Spin, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import produce from 'immer';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Flex } from '../../../../../components/general';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import {
  createApplicantAbsentee,
  deleteApplicantAbsentee,
} from '../../../../../services/applicant.service';
import { DateService } from '../../../../../services/date.service';
import {
  getDetailedGroupAbsenteeInfoById,
  getGroupById,
} from '../../../../../services/groups.service';
import { IStudentWithAbsentees } from '../../../../../types/applicant.types';
import { IGroupDetailedAbsenteeModel } from '../../../../../types/group.types';

const { Text } = Typography;

const StudentAbsenteesCheckBox: React.FC<{
  checked?: boolean;
  applicantId: number;
  lessonId: number;
  studentIndex: number;
  absentIndex: number;
}> = ({
  checked = false,
  applicantId,
  lessonId,
  studentIndex,
  absentIndex,
}) => {
  const queryClient = useQueryClient();
  const [isChecked, setIsChecked] = useState(checked);
  const deleteAbsenteeMutation = useMutation(() =>
    deleteApplicantAbsentee({ applicantId, lessonId }),
  );

  const createAbsenteeMutation = useMutation(() =>
    createApplicantAbsentee({ applicantId, lessonId }),
  );

  const checkboxDisabled =
    createAbsenteeMutation.isLoading || deleteAbsenteeMutation.isLoading;
  const handleToggle = async () => {
    !isChecked
      ? await createAbsenteeMutation.mutateAsync()
      : await deleteAbsenteeMutation.mutateAsync();
    queryClient.setQueryData<IGroupDetailedAbsenteeModel>(
      UseQueryTypes.GROUP_ABSENTEES,
      (oldData) => {
        return produce(oldData, (draft) => {
          draft!.students[studentIndex].totalAbsentees += isChecked ? -1 : 1;
        }) as IGroupDetailedAbsenteeModel;
      },
    );

    queryClient.setQueryData<any>(
      UseQueryTypes.GROUP_ABSENTEES,
      (oldData: any) => {
        return produce(oldData, (draft: any) => {
          draft!.students[studentIndex].absentees[absentIndex].absent =
            !isChecked;
        }) as any;
      },
    );

    setIsChecked((t) => !t);
  };

  return (
    <Spin spinning={checkboxDisabled}>
      <Checkbox
        defaultChecked={isChecked}
        disabled={checkboxDisabled}
        onChange={handleToggle}
      ></Checkbox>
    </Spin>
  );
};

export const StudentAbsentees = () => {
  const { id } = useParams() as { id: string };
  const { data: groupDetail, ...groupRequest } = useQuery(
    UseQueryTypes.GROUP_ABSENTEES,
    () => getDetailedGroupAbsenteeInfoById(Number(id)),
  );
  const { data: lessonDates, ...lessonRequest } = useQuery(
    UseQueryTypes.LESSONS,
    () => getGroupById(Number(id)),
    { select: (d) => d.lessons },
  );

  const students = groupDetail?.students;

  const columns: ColumnsType<IStudentWithAbsentees> = [
    {
      title: 'Student',
      dataIndex: 'name',
      fixed: 'left',
      key: 'name',
      render: (_, c) => (
        <>
          <Text>{c.name}</Text> <br/>
          <Text type="secondary">{c.email}</Text>
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 300,
    },
    {
      title: 'Lesson Date',
      children: (lessonDates || []).map((lesson, idx) => ({
        title: DateService.getFullDate(lesson.startDate),
        key: lesson.id,
        width: 80,
        render: (_, student, studentIndex) => (
          <StudentAbsenteesCheckBox
            studentIndex={studentIndex}
            absentIndex={idx}
            applicantId={student.id}
            lessonId={student.absentees[idx].lessonId}
            checked={student.absentees[idx].absent}
          />
        ),
      })),
    },
    {
      title: 'Total Absentees',
      dataIndex: 'totalAbsentees',
      key: 'totalAbsentees',
      fixed: 'right',
      width: 150,
    },
  ];

  const groupDetailStudentsCount = groupDetail?.students.length ?? 0;

  return (
    <Spin spinning={lessonRequest.isLoading || groupRequest.isLoading}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text type="secondary" style={{ marginBottom: 20 }}>
          {groupDetailStudentsCount} result found showing
          {groupDetailStudentsCount} of {groupDetailStudentsCount}
        </Text>
      </Flex>
      <Table<IStudentWithAbsentees>
        rowKey={(r) => r.id}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 500 }}
        bordered
        dataSource={students}
        columns={columns}
      />
    </Spin>
  );
};
