import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, CustomDatePickerFormik, Flex, Input, SingleSelectFormik } from '../../../components/general';
import { TASK_STATUS, TASK_TYPE } from '../../../constants/task.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { getAllUser } from '../../../services/user.service';
import { ITransform } from '../../../types/form.types';
import { ITaskInitialValues, TaskSourceEnum } from '../../../types/task.types';
import { TransformTaskFormOwnedSchema, TransformTaskFormSchema } from '../schemas/transformTask.form.schema';
import { ChooseRelatedToField } from './dependentTaskFields';

type TransformTaskType = {
  isEditForm?: boolean;
  sourceType?: TaskSourceEnum;
};

export const TransformTaskForm: React.FC<ITransform<ITaskInitialValues> & TransformTaskType> = ({
  onSubmit,
  initialValues,
  sourceType,
}) => {
  const hasFullAccess = sourceType !== TaskSourceEnum.OWNED;
  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser, {
    select: (response) => {
     return response.filter(user => !user.deactivated)
    }
  });

  const usersContext = useMemo(() => {
    return generateContext(usersQuery.data || [], (user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
  }, [usersQuery.data]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={hasFullAccess ? TransformTaskFormSchema : TransformTaskFormOwnedSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <Form
            name="add-task"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {hasFullAccess && (
              <>
                <Field name="title" label="Title" component={Input} required />
                <ErrorMessage name="title" component="span" className="error" />

                <Field name="description" label="Description" component={Input} required />
                <ErrorMessage name="description" component="span" className="error" />

                <Field name="dueDate" label="Due Date" component={CustomDatePickerFormik} required />
                <ErrorMessage name="dueDate" component="span" className="error" />
              </>
            )}

            <Field
              name="taskStatus"
              label="Task status"
              options={TASK_STATUS}
              component={SingleSelectFormik}
              required
            />
            <ErrorMessage name="taskStatus" component="span" className="error" />

            {hasFullAccess && (
              <>
                <Field name="owner" label="Owner" options={usersContext} component={SingleSelectFormik} required />
                <ErrorMessage name="ownerId" component="span" className="error" />

                <Field name="taskType" label="Related To Type" options={TASK_TYPE} component={SingleSelectFormik} />
                <ErrorMessage name="taskType" component="span" className="error" />
              </>
            )}
            <ChooseRelatedToField />
            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={props.handleReset}>
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Apply
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};
