import { Field } from 'formik';
import { useMemo } from 'react';

import { generateContext } from '../../../helpers/generateContext.helpers';
import { IDependentField } from '../../../types/form.types';
import { MultiselectFormik } from '../../general/Multiselect/Multiselect';

export const DependentField = <T, F>({
  label,
  name,
  changeBy,
  data,
  filterCb,
  generateContextCb,
  ...props
}: IDependentField<T, F>) => {
  const filteredContext = useMemo(() => {
    const filtered = (data || []).filter(filterCb);
    return generateContext(filtered, generateContextCb);
  }, [changeBy, data]);

  return <Field {...props} label={label} name={name} component={MultiselectFormik} options={filteredContext} />;
};
