import { LinkedinFilled } from '@ant-design/icons';
import { Col, Pagination, Row, Spin, Table } from 'antd';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import { Button, Flex, Icons } from '../../components/general';
import { getButtonVariant } from '../../components/general/Button/Button';
import { ChangeView } from '../../components/general/Icons/Icons';
import { Tag } from '../../components/general/Tag/Tag';
import { groupStateOptions } from '../../constants/group.constants';
import { InstructorStatusColor, InstructorStatusOptions } from '../../constants/instructor.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { defaultInstructorGroupsColumns } from '../../schemas/groups/instructorGroups.schema';
import { filterGroups } from '../../services/groups.service';
import { addInstructorComment, getInstructorById } from '../../services/instructors.service';
import { getAllTracks } from '../../services/track.service';
import { GroupState, GroupStateEnum, IGroup } from '../../types/group.types';
import { InstructorStatusEnum } from '../../types/instructor.types';

export default function InstructorPage() {
  const { id } = useParams<{ id: string }>();
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();

  const cache = useQueryClient();
  const groupInstructorTable = useTable(defaultInstructorGroupsColumns);
  const modalAdapter = useColumnModalAdapter(groupInstructorTable.getTableColumnSettings());

  const [activeGroupStatus, setActiveGroupStatus] = useState(GroupStateEnum.PLANNING);

  const instructorQuery = useQuery([UseQueryTypes.INSTRUCTOR, id], () => getInstructorById(Number(id)));
  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);
  const groupsQuery = useQuery([UseQueryTypes.GROUPS, activeGroupStatus, id], () =>
    filterGroups({
      groupState: [activeGroupStatus],
      instructorIds: [Number(id)],
    }),
  );

  const commentMutation = useMutation(addInstructorComment, {
    onSuccess: () => cache.invalidateQueries('instructor'),
  });

  const getButtonType = (state: GroupState) => getButtonVariant(activeGroupStatus, state);

  const handleOpenAddInstructorCommentForm = (id: number) => {
    changeId(id);
    openDrawer(AppFormsEnum.AddInstructorCommentForm, 'Add Instructor comment');
  };

  const handleOpenEditInstructorForm = (id: number) => {
    changeId(id);
    openDrawer(AppFormsEnum.EditInstructorForm, 'Edit Instructor');
  };

  const isScreenLoading =
    instructorQuery.isLoading || tracksQuery.isLoading || groupsQuery.isLoading || commentMutation.isLoading;

  const instructorStatus = instructorQuery.data?.status ?? InstructorStatusEnum.TEACHING_NOW;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row align="middle" gutter={16}>
          <Col span={22} offset={1}>
            <Flex
              alignItems="baseline"
              style={{
                background: '#FFFFFF',
                width: '100%',
                margin: '24px 0px',
                padding: '20px 16px',
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: 32,
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '20px',
                }}
              >
                {instructorQuery.data?.name}{' '}
                <a rel="noreferrer" target="_blank" href={instructorQuery.data?.linkedInProfile}>
                  <LinkedinFilled
                    style={{
                      color: '#0097d3',
                      marginLeft: 7,
                      fontSize: 16,
                      cursor: 'pointer',
                    }}
                  />
                </a>
              </p>
              <p
                style={{
                  margin: 0,
                  marginRight: 52,
                }}
              >
                {instructorQuery.data?.tracks.map(({ name }) => name)}
              </p>
              <Tag
                background={InstructorStatusColor[instructorStatus]}
                children={InstructorStatusOptions[instructorStatus].label}
              />
              <p
                style={{
                  margin: 0,
                  marginLeft: 52,
                  marginRight: 32,
                }}
              >
                {instructorQuery.data?.phoneNumber}
              </p>
              <p
                style={{
                  margin: 0,
                  marginRight: 32,
                }}
              >
                {instructorQuery.data?.email}
              </p>

              <Flex style={{ marginLeft: 'auto' }}>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    border: '1px solid #EDEDED',
                    borderRadius: 4,
                    width: 32,
                    height: 32,
                    marginRight: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenAddInstructorCommentForm(Number(id))}
                >
                  <Icons.Comment />
                </Flex>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    border: '1px solid #EDEDED',
                    borderRadius: 4,
                    width: 32,
                    height: 32,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenEditInstructorForm(Number(id))}
                >
                  <Icons.Edit color="#161616" />
                </Flex>
              </Flex>
            </Flex>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 30, marginBottom: 50 }}>
          <Col span={22} offset={1}>
            <Row justify="space-between">
              <Row>
                <Col>
                  <Button
                    variant={getButtonType(GroupStateEnum.PLANNING)}
                    key={GroupStateEnum.PLANNING}
                    onClick={() => setActiveGroupStatus(GroupStateEnum.PLANNING)}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    {groupStateOptions[GroupStateEnum.PLANNING].label}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={getButtonType(GroupStateEnum.ONGOING)}
                    style={{ borderLeft: 0, borderRight: 0, borderRadius: 0 }}
                    key={GroupStateEnum.ONGOING}
                    onClick={() => setActiveGroupStatus(GroupStateEnum.ONGOING)}
                  >
                    {groupStateOptions[GroupStateEnum.ONGOING].label}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    variant={getButtonType(GroupStateEnum.TERMINATED)}
                    key={GroupStateEnum.TERMINATED}
                    onClick={() => setActiveGroupStatus(GroupStateEnum.TERMINATED)}
                  >
                    {groupStateOptions[GroupStateEnum.TERMINATED].label}
                  </Button>
                </Col>
              </Row>
              <ChangeView onClick={modalAdapter.openSettings} />
            </Row>

            <Table<IGroup>
              {...groupInstructorTable.getTableProps()}
              dataSource={groupsQuery.data?.groups}
              style={{ marginTop: 30 }}
              rowKey={(group) => group.id}
            />
            <Pagination
              {...groupInstructorTable.getPaginationProps()}
              total={groupsQuery.data?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
