import { CourseFormatEnum } from '../types/course.types';
import { GroupStateEnum, InterviewTemplateEnum, SelectionStatusEnum } from '../types/group.types';

export const GroupFormats = {
  [CourseFormatEnum.ONLINE]: {
    value: CourseFormatEnum.ONLINE,
    label: 'online',
  },
  [CourseFormatEnum.OFFLINE]: {
    value: CourseFormatEnum.OFFLINE,
    label: 'offline',
  },
  [CourseFormatEnum.COMBINED]: {
    value: CourseFormatEnum.COMBINED,
    label: 'combined',
  },
} as const;

export const groupStateOptions = {
  [GroupStateEnum.ON_HOLD]: {
    label: 'On Hold',
    value: GroupStateEnum.ON_HOLD,
  },
  [GroupStateEnum.PLANNING]: {
    label: 'Planning',
    value: GroupStateEnum.PLANNING,
  },
  [GroupStateEnum.ONGOING]: {
    label: 'Ongoing',
    value: GroupStateEnum.ONGOING,
  },
  [GroupStateEnum.WAITING_FOR_DEMO]: {
    label: 'Waiting For Demo',
    value: GroupStateEnum.WAITING_FOR_DEMO,
  },
  [GroupStateEnum.TERMINATED]: {
    label: 'Terminated',
    value: GroupStateEnum.TERMINATED,
  },
} as const;

export const selectionStatusOptions = {
  [SelectionStatusEnum.OPEN]: {
    label: 'Open',
    value: SelectionStatusEnum.OPEN,
  },
  [SelectionStatusEnum.CLOSED]: {
    label: 'Closed',
    value: SelectionStatusEnum.CLOSED,
  },
} as const;

export const interviewScheduleTemplateOptions = {
  [InterviewTemplateEnum.INTRO_COURSES_WITH_EXAM]: {
    value: InterviewTemplateEnum.INTRO_COURSES_WITH_EXAM,
    label: 'Intro courses with exam',
  },
  [InterviewTemplateEnum.INTRO_COURSES_NO_EXAM]: {
    value: InterviewTemplateEnum.INTRO_COURSES_NO_EXAM,
    label: 'Intro courses without exam',
  },
  [InterviewTemplateEnum.PRO_COURSES]: {
    value: InterviewTemplateEnum.PRO_COURSES,
    label: 'Pro courses',
  },
  [InterviewTemplateEnum.INTERNSHIPS]: {
    value: InterviewTemplateEnum.INTERNSHIPS,
    label: 'Internships',
  },
} as const;
