import { Col } from 'antd';
import React from 'react';

import { Button } from '../../../../../../components/general';
import { useDrawer } from '../../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../../forms';
import { useRowSelectionAdapter } from '../../../../../../hooks/useRowSelection.adapter';
import { useAppDispatch } from '../../../../../../store';
import { sharedSelectsActions } from '../../../../../../store/features/sharedSelects/sharedSelectsSlice';
import { IStudentBrief } from '../../../../../../types/applicant.types';

export const BulkApplicantUpdate = () => {
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const handleOpenBulkUpdateApplicantForm = () => {
    dispatch(sharedSelectsActions.selectItems(rowSelectionAdapter.selectedRows));
    openDrawer(AppFormsEnum.BulkUpdateApplicantForm, 'Bulk Update Applicant');
  };
  return (
    <Col>
      <Button variant="primary" onClick={handleOpenBulkUpdateApplicantForm}>
        Bulk Update
      </Button>
    </Col>
  )
}
