import classNames from 'classnames';
import { useState } from 'react';

import { Button } from '../../../../components/general';
import MessagesConstants from '../../../../constants/messages.constants';
import useWindowSize from '../../../../hooks/useWindowSize.hooks';
import { ButtonTypeEnum } from '../../../../types/button.types';
import { ScreenSizeBreakPoints } from '../../../student/constants/mediaQuery.constants';
import styles from './SubmitButton.module.css';

export const SubmitButton = ({ disabled = false, text }: { text: string | JSX.Element; disabled?: boolean }) => {
  const [buttonPosition, setButtonPosition] = useState(0);
  const [hackMessage, setHackMessage] = useState('');
  const { width } = useWindowSize();

  const submitStyles = classNames({
    [styles.submit]: true,
    [styles.buttonPosition1]: disabled && buttonPosition,
    [styles.buttonPosition2]: disabled && !buttonPosition,
  });

  const changeButtonPosition = () => {
    setButtonPosition((prev) => {
      return prev === 0 ? 1 : 0;
    });
  };

  const handleMouseEnter = () => {
    if (disabled) changeButtonPosition();
  };

  const handleClick = (e: any) => {
    if (disabled) {
      const mobileSize = width <= ScreenSizeBreakPoints.Mobile;
      e.preventDefault();

      if (!mobileSize) {
        setHackMessage(MessagesConstants.hackMessage);
        return;
      }
      changeButtonPosition();
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        handleMouseEnter={handleMouseEnter}
        className={submitStyles}
        variant={ButtonTypeEnum.primary}
        htmlType="submit"
      >
        {text}
      </Button>
      {hackMessage && <span style={{ color: 'var(--custom-warning-color)' }}>{hackMessage}</span>}
    </>
  );
};
