import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { Button, Flex, Input } from '../../../components/general';
import { useAuth } from '../../../contexts/useAuth.context';
import { withCatch } from '../../../helpers/error.helpers';
import { updateUser } from '../../../services/user.service';
import { IUser } from '../../../types/user.types';
import styles from '../Account.module.css';

interface Props {
  isMobileSize: boolean;
}

export const EmailSettingsPage = ({ isMobileSize }: Props) => {
  const { user } = useAuth() as { user: IUser };

  const editUserMutation = useMutation(updateUser);

  const handleSubmit = async (values: typeof initialValues) => {
    const { id, ...rest } = user;

    await withCatch(() => editUserMutation.mutateAsync({ user: { ...rest, email: values.currentEmail }, id }));
  };

  const initialValues = {
    currentEmail: user.email,
  };

  return (
    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues}>
      <Form style={{ width: isMobileSize ? '100%' : '77%' }}>
        <div className={styles.mainBar}>
          <Flex
            direction={isMobileSize ? 'column' : 'row'}
            alignItems={isMobileSize ? 'flex-start' : 'center'}
            justifyContent="space-between"
            className={styles.titleBar}
          >
            <p className={styles.title}>Change Email</p>
            <Flex
              alignItems="center"
              justifyContent={isMobileSize ? 'flex-end' : 'space-between'}
              className={styles.barBtns}
            >
              <Button style={{ minWidth: 150 }} variant="primary">
                Save Changes
              </Button>
            </Flex>
          </Flex>
          <div className={styles.content}>
            <Row>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field type="email" name="currentEmail" label="Current Email" component={Input} />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field type="email" name="email" label="New Email" component={Input} />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field type="email" name="confirmEmail" label="Confirm Email" component={Input} />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
