import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

export class PhoneService {
  static normalize(phoneNumber: string, defaultCountry: CountryCode = 'AM') {
    const parsed = parsePhoneNumber(phoneNumber, defaultCountry);
    if (parsed) return parsed.number;
  }

  static normalizeForNikita(phone: string) {
    let newPhone = phone.replace('+','')
        .replaceAll(' ','')
        .replace('(','')
        .replace(')','');

    if (!newPhone.includes('374')) {
      newPhone='374' + newPhone
    }
    return newPhone
  }
}
