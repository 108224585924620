import { Col, Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { ImageUpload } from '../../components/display/ImageUpload/ImageUpload';
import { Button, Input as FormInput, TextArea } from '../../components/general';
import { Box } from '../../components/general/Box/Box';
import { NEW_LINE } from '../../constants/constants';
import { downloadByLink } from '../../helpers/download.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { generateCertificates } from '../../services/certificates.service';
import { useAppSelector } from '../../store';
import { selectCertificateFormValues } from '../../store/features/certificates/certificatesSlice';
import { Certificates } from '../../types/certificates.types';
import { CertificateFormSchema } from './certificate.forms.schema';

export const CertificateForm = () => {
  const formInitialValues = useAppSelector(selectCertificateFormValues);
  const certificatesMutation = useMutation(generateCertificates);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: any },
  ) => {
    const certificatePayload: Certificates = {
      ...values,
      applicantNames: values?.applicantNames?.split(NEW_LINE).filter((name: string) => !!name),
    };

    const mutationFn = () =>
      certificatesMutation.mutateAsync(certificatePayload);

    await withCatch(mutationFn, {
      onSuccess: ({ url }) => {
        downloadByLink({ link: url });
        resetForm();
      },
    });
  };

  return (
    <Spin spinning={certificatesMutation.isLoading}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={CertificateFormSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form style={{ minHeight: '100vh' }}>
              <Row style={{ marginBottom: 32 }} justify="end">
                <Col style={{ marginRight: 16 }}>
                  <Button onClick={() => props.resetForm()} htmlType="reset">
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    variant="primary"
                    disabled={!(props.dirty && props.isValid)}
                  >
                    Download Certificates
                  </Button>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Box title="General Info">
                    <Field
                      component={FormInput}
                      name="courseName"
                      label="Course name"
                      placeholder="e.g. JavaScript"
                      required
                    />
                    <ErrorMessage
                      name="courseName"
                      component="span"
                      className="error"
                    />

                    <Field
                      component={FormInput}
                      name="endDate"
                      label="End date"
                      placeholder="e.g. (June 1, 1979)"
                      required
                    />
                    <ErrorMessage
                      name="endDate"
                      component="span"
                      className="error"
                    />

                    <Field
                      component={FormInput}
                      name="descriptionFirstLine"
                      label="Duration and other info"
                      placeholder="A 4 month course"
                      required
                    />
                    <ErrorMessage
                      name="descriptionFirstLine"
                      component="span"
                      className="error"
                    />

                    <Field
                      component={FormInput}
                      name="descriptionSecondLine"
                      label="Copyright and Partners"
                      required
                    />
                    <ErrorMessage
                      name="descriptionSecondLine"
                      component="span"
                      className="error"
                    />
                  </Box>
                </Col>
                <Col span={12}>
                  <Box title="Instructor info">
                    <Field
                      component={FormInput}
                      name="tutorFullName"
                      label="Tutor Full Name"
                      required
                    />
                    <ErrorMessage
                      name="tutorFullName"
                      component="span"
                      className="error"
                    />

                    <ImageUpload
                      name="tutorSignBase64"
                      label="Tutor Signature"
                      required
                    />
                    <ErrorMessage
                      name="tutorSignBase64"
                      component="span"
                      className="error"
                    />

                    <Field
                      component={FormInput}
                      name="taFullName"
                      label="TA Full Name"
                    />
                    <ErrorMessage
                      name="taFullName"
                      component="span"
                      className="error"
                    />

                    <ImageUpload name="taSignBase64" label="TA Signature"/>
                    <ErrorMessage
                      name="taSignBase64"
                      component="span"
                      className="error"
                    />
                  </Box>
                </Col>
              </Row>
              <Box title="Selected Students">
                <Field
                  rows={7}
                  label="Student names"
                  placeholder="e.g  Vrezh Oganisyan"
                  name="applicantNames"
                  component={TextArea}
                />
                <ErrorMessage
                  name="applicantNames"
                  component="span"
                  className="error"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
