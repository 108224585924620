export const OrdinalNumbers = [
  'first',
  'second',
  'third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
];

type ShortOrdinalNumbersKey = number | 'default';
export const shortOrdinalNumbers: { [key in ShortOrdinalNumbersKey]: string } = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  default: '$xth',
};
