import { Tag } from '../../components/general/Tag/Tag';
import { InstructorStatusColor, InstructorStatusOptions } from '../../constants/instructor.constants';
import type { ColumnSchema } from '../../types/column.types';
import type { IInstructor } from '../../types/instructor.types';
import { AvatarColumn, DetailsInstructorColumn, NameInstructorColumn } from './instructor.renderer';

export const defaultInstructorColumns: ColumnSchema<IInstructor> = {
  uuid: 'defaultInstructorColumns',
  schema: {
    avatar: {
      title: 'Instructor',
      dataIndex: 'name',
      render: AvatarColumn,
    },
    name: {
      title: 'Name',
      dataIndex: 'name',
      sorter: () => -1,
      render: NameInstructorColumn,
    },
    track: {
      title: 'Track',
      dataIndex: 'tracks',
      render: (_, instructor) => instructor.tracks.map(({ name }) => name),
    },
    status: {
      title: 'Status',
      render: (_, { status }) =>
        Tag({ background: InstructorStatusColor[status], children: InstructorStatusOptions[status].label }),
    },
    phoneNumber: {
      title: 'Phone Number',
      render: (_, instructor) => instructor.phoneNumber,
    },
    details: {
      title: 'Details',
      dataIndex: 'profile',
      render: DetailsInstructorColumn,
    },
  },
};
