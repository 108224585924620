import React from "react";

import { Flex } from "../../../../../components/general";
import { IAgreementProcess } from "../../../types/studentAgreement.types";

function AgreementCreationController({
  creationProcess,
  nextProcessState,
  backProcessState,

}: { creationProcess: IAgreementProcess, nextProcessState: () => void, backProcessState: () => void }) {

  return (
    <Flex direction='column' alignItems='center' justifyContent='center' style={{ width: '100%' }}>
      {creationProcess.renderer({nextProcessState, backProcessState})}
    </Flex>
  );
}


export default AgreementCreationController;



