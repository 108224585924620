import { Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Button, Flex, Input } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { getTrackById, updateTrack } from '../../services/track.service';
import { IAppForm } from '../../types/form.types';
import { TrackFormSchema } from './schemas/tracks.form.schema';

export const EditTrackForm: IAppForm = () => {
  const cache = useQueryClient();
  const { closeDrawer } = useDrawer();
  const { id: editId } = useEntityId();

  const trackQuery = useQuery([UseQueryTypes.TRACK, editId], () => getTrackById(editId));
  const trackUpdateMutation = useMutation(updateTrack, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.TRACKS),
  });

  const handleUpdateTrack = async ({ name }: typeof initialValues) => {
    const mutationFunc = () =>
      trackUpdateMutation.mutateAsync({
        name,
        id: editId,
      });
    await withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const initialValues = {
    name: trackQuery.data ? trackQuery.data.name : '',
  };

  const isScreenLoading = trackUpdateMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        initialValues={initialValues}
        validationSchema={TrackFormSchema}
        enableReinitialize
        onSubmit={handleUpdateTrack}
      >
        {(props) => (
          <Form
            name="edit-track"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="name" label="Track Name" component={Input} />
            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={props.handleReset}>
                Reset
              </Button>
              <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary">
                Edit Track
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
