import { Avatar, Checkbox, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { Flex } from '../../components/general';
import { gradingType } from '../../constants/exam.constants';
import { getAvatar } from '../../helpers/avatar.helper';
import { RoutesConstants } from '../../helpers/routes.helpers';
import { SourcePreview } from '../../modules/shared/components/SourcePreview/SourcePreview';
import { IApplicantDetailApplication } from '../../types/applicantDetail.types';
import { IExamGradingType } from '../../types/exam.types';

const { Text } = Typography;

type TitleDescriptionParam = string | undefined;

export const TitleWithDescriptionColumn = (title: TitleDescriptionParam, description: TitleDescriptionParam) => {
  return (
    <div>
      {title && <Text>{title}</Text>}
      <br />
      {description && <Text type="secondary">{description}</Text>}
    </div>
  );
};

export const ExamStatusColumn = (grade: number | null, minScore: number) => {
  const currentGradingType =
    grade === null ? IExamGradingType.IN_PROCESS : grade >= minScore ? IExamGradingType.SUCCESS : IExamGradingType.FAIL;
  const { type, text } = gradingType[currentGradingType];

  return (
    <Text type={type} style={{ fontWeight: 600 }}>
      {text}
    </Text>
  );
};

export const GroupColumn = ({groupName, courseName}: { courseName?: string, groupName: string }, groupId: number) => {
  return (
    groupName ? <Link
      style={{
        textDecoration: 'underline',
        color: '#5451FF',
      }}
      to={RoutesConstants.groupPage(groupId)}
    >
      {groupName}
    </Link> : courseName
  );
};

export const AmountStatusColumn = (isPaid: boolean, amount: number) => {
  const isPaidColor = isPaid ? '#84D06C' : '#EB3223';

  return (
    <Flex alignItems="center">
      <div style={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: isPaidColor }} />
      <Text style={{ marginLeft: 10 }}>{amount}</Text>
    </Flex>
  );
};

export const InvoiceColumn = (invoiceLink: string | null, { name }: { name: string }) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <SourcePreview sourceLink={invoiceLink} name={name} />
    </Flex>
  );
};

export const AvatarColumn = (avatar: string, username: string) => {
  return (
    <Flex alignItems="center">
      <Avatar src={getAvatar(avatar)} style={{ marginRight: 7 }} />
      <Text>{`@${username}`}</Text>
    </Flex>
  );
};

export const DescriptionColumn = (description: string) => {
  return <div dangerouslySetInnerHTML={{ __html: description }} />;
};

export const HasAccountColumn = (hasAccount: boolean, { selectApplication }: IApplicantDetailApplication) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Checkbox checked={hasAccount} onChange={selectApplication} />
    </Flex>
  );
};

export const OptionsColumn = function (
  status: keyof typeof Options,
  Options: { [key: string]: { value: string; label: string } },
) {
  return Options[status]?.label ?? 'N/A';
};
