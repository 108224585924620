import { Col, Divider, Pagination, Row, Spin, Table, Typography } from 'antd';
import { useQuery } from 'react-query';

import { ResultSummary } from '../../components/display';
import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import {
  Button,
  FilterButton,
  Flex,
  Search,
} from '../../components/general';
import { ChangeView } from "../../components/general/Icons/Icons";
import { PageSizeOptions } from "../../constants/sorters.constants";
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { defaultSmsColumns } from '../../schemas/sms/sms.schema';
import { filterSms } from '../../services/sms.service';
import { SortDirectionEnum } from "../../types/general.types";
import { MailFilterOptions } from '../../types/mail.types';
import { Sms } from '../../types/sms.types';

const { Title } = Typography;

const defaultSmsFilterOptions: Partial<MailFilterOptions> = {
  sortBy: 'created_at',
  sortDirection: SortDirectionEnum.DESC,
  pageSize: PageSizeOptions.defaultPageSize,
};

export default function SmsHistory() {
  const { openDrawer } = useDrawer();
  const { handleSearch, term } = useSearchHandler();
  const { filterOptions, sorterOptions, resetOptions } = useFilter(
    defaultSmsFilterOptions,
  );

  const smsTable = useTable<Sms>(defaultSmsColumns);
  const modalAdapter = useColumnModalAdapter(
    smsTable.getTableColumnSettings(),
  );

  const smsQuery = useQuery(
    [UseQueryTypes.FILTER_SMS, sorterOptions, filterOptions],
    () => filterSms(filterOptions, sorterOptions),
  );

  const smsTotalSize = !smsQuery.isLoading
    ? smsQuery?.data?.totalElements ?? 0
    : 0;

  const handleOpenFilterMailForm = () =>
    openDrawer(AppFormsEnum.FilterMailForm, 'Filter Sms');

  const handleReset = () => {
    resetOptions();
  };

  const isScreenLoading = smsQuery.isLoading;

  return (
    <>
      <Title level={2}>Sms History</Title>

      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={22}>
            <Row justify="start" gutter={8}>
              <Col span={13}>
                <Row>
                  <Row>
                    <Search
                      style={{ marginRight: 8 }}
                      placeholder="Search"
                      defaultValue={term}
                      onChange={handleSearch}
                    />
                    <Flex alignItems="center" style={{ gap: '0 10px' }}>
                      <FilterButton onClick={handleOpenFilterMailForm}/>
                      <Button onClick={handleReset}>Reset Filters</Button>
                    </Flex>
                  </Row>
                </Row>
              </Col>

            </Row>
            <Divider style={{ margin: '16px 0px' }}/>
          </Col>

          <Col span={22}>
            <Row align="middle" justify="space-between">
              <ResultSummary text={`${smsTotalSize} result found`}/>
            </Row>
            <Row justify="end">
              <ChangeView onClick={modalAdapter.openSettings}/>
            </Row>
          </Col>

          <Col span={24}>
            <Table<Sms>
              {...smsTable.getTableProps()}
              dataSource={smsQuery?.data?.sms}
              rowKey={(email) => email.id}
            />
            <Pagination
              {...smsTable.getPaginationProps()}
              total={smsQuery?.data?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
