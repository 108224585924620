import { Avatar, Input } from 'antd';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';

import { Button, Flex, Icons, TextArea } from '../../components/general';
import { getAvatar } from '../../helpers/avatar.helper';
import EmptyDataComponent from '../../modules/student/components/EmptyData/EmptyDataComponent';
import { DateService } from '../../services/date.service';
import type { IComment } from '../../types/comment.types';
import type { ITransform } from '../../types/form.types';
import { CommentFormSchema } from './comment.form.schema';

interface ICommentForm {
  comments: IComment[];
  entity: Partial<{
    name: string;
    email: string;
  }>;
}

export function AddCommentForm<T>({
  entity,
  comments,
  onSubmit,
  initialValues,
  submitText = 'Add Comment',
  removeComment,
  editComment,
}: ICommentForm & ITransform<T>) {
  const initialCommentData: Partial<{ id: number; value: string }> = {};

  const [commentData, setCommentData] = useState(initialCommentData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setCommentData((prev) => ({ ...prev, value }));
  };

  const handleChangeComment = (id: number, content: string) => {
    editComment?.(Number(id), { content }).then(() => setCommentData({}));
  };

  const handleSubmit = async (values: Partial<T>, { resetForm }: FormikHelpers<Partial<T>>) => {
    await onSubmit(values);
    resetForm();
  }

  return (
    <Flex
      direction="column"
      style={{
        height: 'calc(100vh - 120px)',
      }}
    >
      {entity.name || entity.email ? (
        <div
          style={{
            border: '1px solid #F8F8F8',
            width: 336,
            padding: 12,
          }}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: 16,
              lineHeight: '20px',
              marginBottom: 6,
            }}
          >
            {entity.name}
          </p>
          <p
            style={{
              fontSize: 14,
              lineHeight: '20px',
              color: 'rgba(22, 22, 22, 0.42)',
              marginBottom: 0,
            }}
          >
            {entity.email}
          </p>
        </div>
      ) : null}
      <div
        style={{
          width: 336,
          marginBottom: 200,
          overflowY: 'auto',
        }}
      >
        {comments.length ? (
          comments.map((comment) => (
            <div
              key={comment.id}
              style={{
                borderBottom: '1px solid #F8F8F8',
                padding: '16px 0',
              }}
            >
              <Flex alignItems="center" className={comment.commenter?.deactivated ? `deactivated-avatar` : ''}>
                <Avatar
                  src={getAvatar(comment.commenterAvatar || comment.commenter?.avatar)}
                  style={{
                    cursor: 'pointer',
                    marginRight: 7,
                  }}
                />
                <p
                  style={{
                    margin: 0,
                    lineHeight: 1,
                    fontWeight: 'bold',
                  }}
                >
                  @{comment.commenterUsername || comment.commenter?.username}
                </p>
                <p
                  style={{
                    marginLeft: 'auto',
                    fontSize: 12,
                    lineHeight: '14px',
                    color: '#161616',
                    opacity: 0.4,
                  }}
                >
                  {DateService.getFormattedDateTime(comment.createdAt || comment.created_at)}
                </p>
                {editComment && (
                  <p
                    style={{
                      marginLeft: 20,
                      cursor: 'pointer',
                    }}
                  >
                    <Icons.Edit
                      height={20}
                      onClick={() => setCommentData({ id: comment.id, value: comment.content })}
                    ></Icons.Edit>
                  </p>
                )}
                {removeComment && (
                  <p
                    style={{
                      marginLeft: 20,
                      cursor: 'pointer',
                    }}
                  >
                    <Icons.Delete onClick={() => removeComment(comment.id)}></Icons.Delete>
                  </p>
                )}
              </Flex>

              {commentData.id === comment.id ? (
                <Flex direction="column">
                  <Input defaultValue={comment.content} onChange={handleChange} />
                  <Flex style={{ marginTop: 10 }}>
                    <Button
                      htmlType="button"
                      variant="primary"
                      style={{ minWidth: 160, marginRight: 8 }}
                      onClick={() => handleChangeComment(comment.id, commentData.value ?? comment.content)}
                    >
                      Confirm
                    </Button>
                    <Button
                      htmlType="button"
                      variant="warning"
                      style={{ minWidth: 160, marginRight: 8 }}
                      onClick={() => setCommentData({})}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <p style={{ marginTop: 10, margin: 0 }}>{comment.content}</p>
              )}
            </div>
          ))
        ) : (
          <EmptyDataComponent dataTitle="Comments" />
        )}
      </div>

      <Flex
        style={{
          borderTop: '1px solid #F8F8F8',
          padding: '16px 0px',
          marginTop: 'auto',
          position: 'fixed',
          bottom: 0,
        }}
        direction="column"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={CommentFormSchema}
          enableReinitialize
        >
          {(props) => (
            <Form>
              <Field rows={3} label="Comment" name="comment" component={TextArea} />
              <ErrorMessage name="comment" component="span" className="error" />
              <Flex justifyContent="space-between">
                <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={props.handleReset}>
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  {submitText}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
}
