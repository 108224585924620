import 'firebase/compat/database';
import 'firebase/compat/auth';

import firebase from 'firebase/compat/app';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBOSUMhQSfc4kWy5VGUUZ5ORncZOCSPPU4',
  authDomain: 'backoffice-web-auth.firebaseapp.com',
  projectId: 'backoffice-web-auth',
  storageBucket: 'backoffice-web-auth.appspot.com',
  messagingSenderId: '950137457161',
  appId: '1:950137457161:web:05241597d05ed6524546d8',
};

function firebaseClient() {
  return firebase.initializeApp(FIREBASE_CONFIG);
}

export default firebaseClient();
