import { useMemo } from "react";
import { useQuery } from "react-query";

import { UseQueryTypes } from "../constants/useQuery.constants";
import { useAuth } from "../contexts/useAuth.context";
import { setUserVariables } from "../helpers/mail/replaceAllVariables.helpers";
import { getTeamWithPhoneByTeamId } from "../services/teamPhone";

export const useUserAdditionalData = () => {
  const { user } = useAuth();

  const team  = user?.teams?.[0];

  const teamPhoneQuery = useQuery(
    UseQueryTypes.TEAM_PHONE,
    () => getTeamWithPhoneByTeamId(team?.id as number),
  );

  return useMemo( () => {
    return setUserVariables(teamPhoneQuery?.data?.phoneNumbers || [], user?.email)
  }, [user, teamPhoneQuery?.data]);

}
