import { Spin } from 'antd';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import PlusIcon from '../../../../../assets/images/+.svg';
import { Button } from '../../../../../components/general';
import { ButtonTypeEnum } from '../../../../../types/button.types';
import { IStudentAttachment } from '../../../types/studentAttachment.types';
import styles from './AgreementsRenderer.module.css';

const AgreementMessage = ({ message }: { message: string }) => {
  return (
    <div className={styles.errorContentPage}>
      <div className={styles.errorContent}>
        <p style={{ textAlign: 'center', width: '90%' }}>{message}</p>
      </div>
    </div>
  );
};

export const PdfItemViewer = ({ link, previewBtnTitle }: { link: string, previewBtnTitle: string }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error: Error) => {
    setErrorMessage(error.message);
  };

  const handleClick = () => {
    window.location.assign(link);
  };

  return (
    <>
      {Boolean(errorMessage) ? (
        <AgreementMessage message={'There was a problem to load content'} />
      ) : (
        <div className={styles.agreementItem}>
          <div className={styles.documentWrapper}>
            <Document
              className={styles.document}
              loading={
                <div className={styles.errorContentPage}>
                  <Spin />
                </div>
              }
              onLoadError={handleError}
              file={{ url: link }}
            >
              <Page pageNumber={1} className={styles.page} scale={0.4} />
            </Document>
          </div>
          <div className={styles.hoverContent}>
            <Button
              onClick={handleClick}
              variant={ButtonTypeEnum.primary}
              style={{ width: '75%', transition: '100ms' }}
            >
              {previewBtnTitle}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const AgreementsRenderer = ({
  agreements,
  handleAddAgreement,
}: {
  handleAddAgreement: () => void;
  agreements: IStudentAttachment[];
}) => {
  return (
    <div>
      <div className={styles.wrapper}>
        <div onClick={handleAddAgreement} className={styles.addAgreement}>
          <img src={PlusIcon} alt="...Loading" />
          <h2 className={styles.hintMassage}>Add new agreement</h2>
        </div>
        {agreements.map((agreement) => {
          return <PdfItemViewer link={agreement.link} previewBtnTitle='See the agreement' key={agreement.link} />;
        })}
      </div>
    </div>
  );
};

export default AgreementsRenderer;
