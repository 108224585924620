import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Flex, SingleSelectFormik } from '../../components/general';
import { interviewScheduleTemplateOptions } from '../../constants/group.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { createInterviewSchedule } from '../../services/groups.service';
import { IAppForm } from '../../types/form.types';
import { IInterviewSchedule } from '../../types/group.types';
import { InterviewScheduleSchema } from './schemas/interviewSchedule.schemas';

export const CreateInterviewScheduleForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: groupId } = useEntityId();
  const cache = useQueryClient();
  const createInterviewAgenda = useMutation(createInterviewSchedule, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.GROUPS),
  });

  const handleCreateInterviewSchedule = async (newInterviewSchedule: Partial<IInterviewSchedule>) => {
    const mutationFunc = () => createInterviewAgenda.mutateAsync(newInterviewSchedule as IInterviewSchedule);

    await withCatch(mutationFunc, {
      onSuccess: () => {
        closeDrawer();
        cache.invalidateQueries(UseQueryTypes.GROUP_INTERVIEW);
      },
    });
  };

  const isScreenLoading = createInterviewAgenda.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        initialValues={{ groupId }}
        validationSchema={InterviewScheduleSchema}
        onSubmit={handleCreateInterviewSchedule}
      >
        {({ handleReset, isValid, dirty }) => (
          <Form
            name="create-interview-schedule"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field
              label="Interview Template"
              name="template"
              component={SingleSelectFormik}
              options={interviewScheduleTemplateOptions}
              required
            />
            <ErrorMessage name="template" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={handleReset}>
                Reset
              </Button>
              <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary" disabled={!(dirty && isValid)}>
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
