import { Col, Typography } from 'antd';
import React from 'react';

import { Flex } from '../../../../../components/general';
import { GroupInfoType } from '../types';

const { Text } = Typography;

export const GroupInfoRow: React.FC<GroupInfoType> = React.memo(({ name, value }) => (
  <Flex style={{ padding: '20px 10px' }} alignItems="center">
    <Col span={8}>
      <Text type="secondary" style={{ textTransform: 'uppercase' }}>
        {name}
      </Text>
    </Col>
    <Col span={16}>
      <Text strong>{value}</Text>
    </Col>
  </Flex>
));
