import { stringifyObject } from '../helpers/stringifyObj.helper';
import { ResetPassword } from "../types/auth.types";
import { Sorters } from '../types/general.types';
import {
  AuthResponse,
  IResetPassword,
  IUser,
  IUserLogin,
  UserFilterModel,
  UserFilterResponse,
} from '../types/user.types';
import axios from './config.service';

export const login = async (user: IUserLogin) => {
  const { data } = await axios.post<AuthResponse>('/auth/signin', user);
  axios.defaults.headers.common = {
    Authorization: `Bearer ${data.accessToken}`,
  };

  return data;
};

export const getCurrentUser = (): Promise<{ data: IUser }> => {
  return axios.get('/user/me');
};

export const getAllUser = () => {
  return axios.get('/user').then((r) => r.data) as Promise<IUser[]>;
};

export const getUserById = async (id: number) => {
  const r = await axios.get(`/user/${id}`);
  return r.data;
};

export const createUser = async (user: IUser) => {
  const r = await axios.post(`/user`, user);
  return r.data as Promise<IUser>;
};

export const updateUser = async ({
  user,
  id,
}: {
  user: Partial<IUser>;
  id: number;
}) => {
  const r = await axios.put(`/user/${id}`, user);
  return r.data;
};

export const deleteUserById = async (id: number) => {
  const result = await axios.delete(`/user/${id}`);

  return result.data;
};

export const updateUserPassword = async ({
  id,
  passwords,
}: {
  id: number;
  passwords: IResetPassword;
}) => {
  const r = await axios.put(`/user/${id}/change-password`, passwords);
  return r.data as Promise<IUser>;
};

export function filterUsers(
  filters: Partial<UserFilterModel> = {},
  sorters: Partial<Sorters> = {},
) {
  const queryString = stringifyObject(sorters);
  return axios
    .post(`/user/filter?${queryString}`, filters)
    .then((r) => r.data) as Promise<UserFilterResponse>;
}

export const resetPassword = (data: ResetPassword): Promise<{ data: any }> => {
  return axios.put('/user/reset-password/', data);
};
