import cn from 'classnames';
import React  from 'react';

import { ButtonTypeEnum } from '../../../types/button.types';
import { IButton } from '../../../types/components/general.types';
import styles from './Button.module.css';

export type IButtonType = keyof typeof ButtonTypeEnum;

export function getButtonVariant<T>(activeField: T, field: T) {
  return activeField === field ? ButtonTypeEnum.primary : ButtonTypeEnum.default;
}

const Button: React.FC<IButton> = ({
  htmlType,
  handleMouseEnter,
  handleMouseLeave,
  variant = ButtonTypeEnum.default,
  children,
  className,
  icon,
  style,
  onClick,
  disabled = false,
}) => {
  const classNames = cn(styles.button, styles[variant || ButtonTypeEnum.default], className);

  const iconStyles = cn({
    [styles.icon]: children,
  });

  return (
    <button
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={style}
      type={htmlType}
      className={classNames}
      disabled={disabled}
    >
      {children} {icon && <span className={iconStyles}>{icon}</span>}
    </button>
  );
};
export default Button;
