import { Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  addInstructorComment,
  getInstructorById,
} from '../../services/instructors.service';
import type { IAppForm } from '../../types/form.types';
import { AddCommentForm } from '../shared/comment.form';

export const AddInstructorCommentForm: IAppForm = () => {
  const { id: editId } = useEntityId();
  const initialValues = { comment: '' };
  const cache = useQueryClient();
  const instructorQuery = useQuery([UseQueryTypes.INSTRUCTOR, editId], () =>
    getInstructorById(editId),
  );

  const commentMutation = useMutation(addInstructorComment, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.INSTRUCTOR),
  });

  const handleAddComment = async ({ comment }: typeof initialValues) => {
    const mutationFunc = () =>
      commentMutation.mutateAsync({
        instructorId: editId,
        comment: {
          content: comment,
        },
      });

    await withCatch(mutationFunc, {
      onSuccess: () => cache.invalidateQueries(UseQueryTypes.INSTRUCTOR),
    });
  };

  const instructorComments = (instructorQuery.data?.comments || [])
  .slice()
  .reverse();

  const isScreenLoading =
    commentMutation.isLoading || instructorQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <AddCommentForm
        comments={instructorComments}
        onSubmit={handleAddComment}
        initialValues={initialValues}
        entity={{
          name: instructorQuery.data?.name,
          email: instructorQuery.data?.email,
        }}
      />
    </Spin>
  );
};
