import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';

import { Button, Flex, Input } from '../../../components/general';
import { useAuth } from '../../../contexts/useAuth.context';
import { AbstractUser } from '../../../types/auth.types';
import styles from '../Account.module.css';

type Props = {
  setEditView: (data: boolean) => void;
  isMobileSize: boolean;
};

export const AccountView = ({ setEditView, isMobileSize }: Props) => {
  const { user, isInstructor } = useAuth() as {
    user: AbstractUser;
    refreshUser: () => void;
    isInstructor: () => boolean;
  };

  // eslint-disable-next-line no-console
  console.log('user:::', user);

  const hasSignatureLink = user?.signatureLink !== '0';

  return (
    <Formik onSubmit={() => {}} initialValues={user || {}}>
      <Form style={{ width: isMobileSize ? '100%' : '77%' }}>
        <div className={styles.mainBar}>
          <Flex
            direction={isMobileSize ? 'column' : 'row'}
            alignItems={isMobileSize ? 'flex-start' : 'center'}
            justifyContent="space-between"
            className={styles.titleBar}
          >
            <p className={styles.title}>Personal Information</p>
            <Flex
              alignItems="center"
              justifyContent={isMobileSize ? 'flex-end' : 'space-between'}
              className={styles.barBtns}
            >
              <Button style={{ minWidth: 150 }} htmlType="submit" variant="primary" onClick={() => setEditView(true)}>
                Edit
              </Button>
            </Flex>
          </Flex>
          <div className={styles.content}>
            <Row>
              {user?.hasOwnProperty('firstName') && user?.hasOwnProperty('lastName') && (
                <>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Field name="firstName" label="Name" component={Input} readOnly />
                  </Col>
                  <Col lg={{ span: 7, offset: 1 }} xs={{ span: 24 }}>
                    <Field name="lastName" label="Surname" component={Input} readOnly />
                  </Col>
                </>
              )}
              {user?.hasOwnProperty('name') && (
                <>
                  <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                    <Field name="name" label="Name" component={Input} readOnly />
                  </Col>
                </>
              )}
              {user?.hasOwnProperty('linkedInProfile') && (
                <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                  <Field name="linkedInProfile" label="LinkedIn Profile" component={Input} readOnly />
                </Col>
              )}
              {user?.hasOwnProperty('username') && (
                <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                  <Field name="username" label="Username" component={Input} readOnly />
                </Col>
              )}
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field name="phoneNumber" label="Phone" component={Input} readOnly />
              </Col>

              {isInstructor() && hasSignatureLink && (
                <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                  <label style={{ margin: 'auto 0' }}>Signature</label>
                  <div style={{ margin: '8px auto 0 auto', border: '1px solid rgba(219, 224, 231, 0.7)' }}>
                    <img src={user?.signatureLink} alt="" width="100%" />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
