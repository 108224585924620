import { ColumnSchema } from '../../types/column.types';
import {
  IReminder,
} from '../../types/reminder.types';
import {
  DeleteReminderColumn, DescriptionReminderColumn,
  DynamicReminderRenderer,
  EditReminderColumn,
  ReminderDate,
} from './reminders.renderer';

export const remindersColumn: ColumnSchema<IReminder> = {
  uuid: 'remindersSchema',
  schema: {
    title: {
      title: 'Title',
      width: 200,
      render: DynamicReminderRenderer('title'),
    },
    description: {
      title: 'Description',
      width: 200,
      render: DescriptionReminderColumn,
    },
    type: {
      title: 'Type',
      width: 200,
      render: DynamicReminderRenderer('type'),
    },
    notifyDate: {
      title: 'Notify Date',
      width: 200,
      render: ReminderDate,
    },
    status: {
      title: 'Status',
      width: 200,
      render: DynamicReminderRenderer('status'),
    },
    edit: {
      title: 'Edit',
      width: 50,
      render: EditReminderColumn,
    },
    delete: {
      dataIndex: 'delete',
      title: 'Delete',
      width: 30,
      render: DeleteReminderColumn,
    },
  },
};
