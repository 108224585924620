import { useState } from 'react';

import { ColumnModalProps } from '../components/display/ColumnSettingModal/ColumnModal';
import { ColumnSettingController, ColumnSettings } from '../types/column.types';

export const useColumnModalAdapter = (
  columnSettingController: ColumnSettingController,
) => {
  const [visible, setVisible] = useState(false);

  const openSettings = () => setVisible(true);
  const closeSettings = () => setVisible(false);

  const saveSettings = (settings: ColumnSettings) => {
    columnSettingController.onSettingSave(settings);
    closeSettings();
  };

  const getColumnModalProps = (
    props?: Partial<ColumnModalProps>,
  ): ColumnModalProps => ({
    visible,
    settings: columnSettingController.settings,
    onCancel: closeSettings,
    onSettingSave: saveSettings,
    ...props,
  });

  return { getColumnModalProps, openSettings, closeSettings };
};
