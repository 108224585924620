import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000, // stale data & make another request after 1 second,
      refetchOnWindowFocus: false, //share cache between browser tabs
    },
  },
});

export const QueryProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}> {children}</QueryClientProvider>
  );
};
