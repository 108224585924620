import { TASK_TYPE } from '../../constants/task.constants';
import type { ITaskGET } from '../../types/task.types';
import { RoutesConstants } from '../routes.helpers';

export const getRelatedTo = <T extends any>(
  taskType: ITaskGET['taskType'],
  cbs: { groupCB: () => T; applicantCB: () => T },
): T | null => {
  switch (taskType) {
    case TASK_TYPE.GROUP.value:
      return cbs.groupCB();
    case TASK_TYPE.FIRST_CONTACT_PHASE.value:
    case TASK_TYPE.EXAM_STAGE.value:
    case TASK_TYPE.INTERVIEW_STAGE.value:
    case TASK_TYPE.APPLICANT.value:
      return cbs.applicantCB();
    case TASK_TYPE.OTHER.value:
      return null;
    default:
      throw new Error('Not a valid type');
  }
};

export const getRelatedToRoute = ({ taskType, relatedTo, applicant }: ITaskGET) => {
  return getRelatedTo(taskType, {
    groupCB: () => {
      if (relatedTo?.name) {
        return RoutesConstants.groupPage(relatedTo?.id!);
      }
    },
    applicantCB: () => {
      if (applicant?.name) {
        return RoutesConstants.applicantFilter(applicant?.name!, applicant?.email!);
      }
    },
  });
};
