import cn from 'classnames';

import Flex from '../Flex/Flex';
import styles from './Badge.module.css';

interface IBadgeProps {
  title: string;
  name?: string;
  statistics: string | number | undefined;
  type: string;
  handleBadgeClick?: (name: string) => void
}

export default function Badge({ title, statistics=0, type, handleBadgeClick }: IBadgeProps) {
  const classNames = cn(styles.badge, styles[type]);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={classNames}
      style={{ margin: '3px', pointerEvents: 'auto' }}
      onClick={handleBadgeClick}
    >
      <p className={styles.title}>{title}</p>
      <p className={styles.statistics}>{statistics}</p>
    </Flex>
  );
}
