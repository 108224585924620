import { Outlet, useLocation } from 'react-router-dom';

import { StudentRoutesConstants } from '../../../helpers/routes.helpers';
import SharedLayout from '../../shared/components/Layout/Layout';
import { getPathData } from '../../shared/helpers/path.helpers';
import { StudentDashBoardConditionalLinks, StudentDashBoardLinks } from '../constants/studentNav.constants';
import Agreements from './Agreements';

export default function Home() {
  const { pathname } = useLocation();
  const title = getPathData({ path: pathname, dashboardLinks: {...StudentDashBoardLinks, ...StudentDashBoardConditionalLinks}, defaultLink: 'agreements' });

  const isPathnameEmpty = pathname === StudentRoutesConstants.home();

  return (
    <SharedLayout title={title}>
      <>
        <Outlet />
        {isPathnameEmpty && <Agreements />}
      </>
    </SharedLayout>
  );
}
