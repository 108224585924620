import {
  DayOfWeekOptions,
  DayOfWeekOrder,
} from '../../constants/schedule.constants';
import { DateService } from '../../services/date.service';
import type { ColumnSchema } from '../../types/column.types';
import type { ScheduleModel } from '../../types/general.types';

export const defaultScheduleColumn: ColumnSchema<ScheduleModel> = {
  uuid: 'scheduleSchema',
  schema: {
    dayOfWeek: {
      title: 'Day of Week',
      sorter: (a, b) => {
        if (DayOfWeekOrder[a.dayOfWeek] > DayOfWeekOrder[b.dayOfWeek]) return 1;
        return -1;
      },
      render: (_, schedule) => DayOfWeekOptions[schedule.dayOfWeek].label,
    },
    startHour: {
      title: 'Start Hour',
      dataIndex: 'startHour',
      sorter: (a, b) =>
        DateService.compareDateStringAsc(a.startHour, b.startHour),
    },
    endHour: {
      title: 'End Hour',
      dataIndex: 'endHour',
      sorter: (a, b) => DateService.compareDateStringAsc(a.endHour, b.endHour),
    },
  },
};
