import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { createInstructor } from '../../services/instructors.service';
import { IAppForm } from '../../types/form.types';
import { ICreateInstructor } from '../../types/instructor.types';
import { TransformInstructorForm } from './shared/transformInstructor.form';

export const CreateInstructorForm: IAppForm = () => {
  const cache = useQueryClient();
  const { closeDrawer } = useDrawer();

  const newInstructorMutation = useMutation(createInstructor, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.INSTRUCTORS),
  });

  const handleCreateNewInstructor = async (
    instructor: Partial<ICreateInstructor>,
  ) => {
    const mutationFunc = () =>
      newInstructorMutation.mutateAsync(instructor as ICreateInstructor);

    withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };
  const isScreenLoading = newInstructorMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <TransformInstructorForm
        initialValues={{}}
        onSubmit={handleCreateNewInstructor}
      />
    </Spin>
  );
};
