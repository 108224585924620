import { MutableRefObject, useEffect } from 'react';

import { Anchor } from '../components/general/DrawerContainer/DrawerContainer';

export const useOutsideClickForDrawer = (
  ref: MutableRefObject<any>,
  cb: () => void,
  drawerPosition: Anchor,
  isActive: boolean = true,
) => {
  const handleClickOutside = (event: MouseEvent) => {
    const drawerContainerOffset =
      drawerPosition === Anchor.LEFT
        ? ref?.current?.offsetWidth
        : ref?.current?.offsetLeft;

    const mousePosRelativeAnchor =
      drawerPosition === Anchor.LEFT
        ? event.x > drawerContainerOffset
        : event.x < drawerContainerOffset;

    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      mousePosRelativeAnchor &&
      isActive
    ) {
      cb();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    if (!isActive) {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb, drawerPosition, isActive, handleClickOutside]);
};

export default function useOutsideClick(
  ref: MutableRefObject<any>,
  cb: () => void,
  isActive: boolean = true,
) {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target) && isActive) {
      cb();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    if (!isActive) {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, cb, isActive, handleClickOutside]);
}
