import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SendSms } from '../../../types/sms.types';
import type { RootState } from '../../index';

interface SmsState {
    formValues: Partial<SendSms>;
}

const initialState = {
    formValues: {
        template: '',
        to: '',
    },
} as SmsState;

export const smsSlice = createSlice({
    name: 'massSms',
    initialState,
    reducers: {
        setInitialValues: (
            state,
            action: PayloadAction<Partial<SmsState['formValues']>>,
        ) => {
            state.formValues = {
                ...state.formValues,
                ...action.payload,
            };
        },
    },
});

export const smsActions = smsSlice.actions;

export const selectSmsFormValues = (state: RootState) =>
    state.smsSlice.formValues;
