import { TableRowSelection } from '@ant-design/pro-table/lib/typing';
import { Col, Row, Spin, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { ResultSummary } from '../../../../../components/display';
import { Button, Flex } from '../../../../../components/general';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { sortArrayByProperty } from '../../../../../helpers/array.helpers';
import { useTable } from '../../../../../hooks/table/useTable';
import { useRowSelectionAdapter } from '../../../../../hooks/useRowSelection.adapter';
import { getBootcampApplicantPaymentColumns } from '../../../../../schemas/payments/payments.schema';
import {
  getApplicantPaymentByPaymentId,
  getGroupDetailWithPayment,
  getGroupPayments,
} from '../../../../../services/payment.service';
import { useAppDispatch } from '../../../../../store';
import { sharedSelectsActions } from '../../../../../store/features/sharedSelects/sharedSelectsSlice';
import { IStudentBrief } from '../../../../../types/applicant.types';
import { ColumnSchema } from '../../../../../types/column.types';
import type {
  IExtendedGroupStudents,
  IGroupStudents,
  IPayment,
} from '../../../../../types/payment.types';

const { Text } = Typography;

export const BootcampTable = ({
                                column,
                                dataSource,
                                rowSelection,
                              }: {
  column: ColumnSchema<IExtendedGroupStudents>;
  dataSource: any;
  rowSelection?: TableRowSelection;
}): JSX.Element => {
  const createdTable = useTable(column);

  return (
    <Table
      style={{ margin: '20px 0' }}
      {...createdTable.getTableProps({
        bordered: true,
        rowSelection,
      })}
      dataSource={dataSource}
      scroll={{ y: 500 }}
      rowKey={(r) => r.id}
    />
  );
};

export const BootcampStudentPayments = () => {
  const dispatch = useAppDispatch();
  const { id: groupId } = useParams<{ id: string }>();
  const { openDrawer } = useDrawer();

  const handleOpenBulkUpdatePaymentType = () => {
    dispatch(
      sharedSelectsActions.selectItems(rowSelectionAdapter.selectedRows),
    );
    openDrawer(AppFormsEnum.BulkUpdatePaymentTypeForm, 'Update Payment Type');
  };

  const groupPayments = useQuery([UseQueryTypes.PAYMENT, groupId], () =>
    getGroupPayments(Number(groupId)),
  );

  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();
  const selectedApplicantsCount = rowSelectionAdapter.selectedRows.length;

  const paymentIds: number[] =
    groupPayments.data?.map((payment) => payment.id) ?? [];

  const { data: applicantPayment } = useQuery(
    [UseQueryTypes.APPLICANT_PAYMENT, paymentIds[0]],
    () => getApplicantPaymentByPaymentId(Number(paymentIds[0])),
  );

  const sortedApplicantPayments = sortArrayByProperty(
    applicantPayment || [],
    'applicant',
  );

  const groupPaymentDetailInfo = useQuery(
    [UseQueryTypes.GROUP_PAYMENT, groupId],
    () => getGroupDetailWithPayment(Number(groupId)),
  );

  const groupStudents = (groupPaymentDetailInfo.data?.students || []).map(
    (groupStudent: IGroupStudents): IExtendedGroupStudents => {
      const studentLastComment =
        groupStudent?.comments?.[groupStudent?.comments?.length - 1];

      const groupPaymentMethod = groupStudent?.group?.tuitionFee?.feeType;

      const lastItemPaymentsGroup = groupPayments.data?.[
      groupPayments.data.length - 1
        ] as IPayment;

      return {
        ...groupStudent,
        phoneNumber: groupStudent?.phoneNumber,
        comment: studentLastComment,
        amount: lastItemPaymentsGroup?.amount,
        currency: lastItemPaymentsGroup?.currency,
        method: groupPaymentMethod,
      };
    },
  );

  const isScreenLoading =
    groupPaymentDetailInfo.isLoading || groupPayments.isLoading;

  const groupStudentsLength = groupStudents?.length ?? 0;

  let paymentBootcampTables = sortedApplicantPayments.map(
    (applicantPayment) => {
      let rowSelection;

      if (applicantPayment.length <= 1) {
        rowSelection = rowSelectionAdapter.getProps();
      }

      const dataSource = groupStudents.filter(
        (applicant) => applicant.id === applicantPayment[0].applicant,
      );

      return (
        <>
          <BootcampTable
            column={getBootcampApplicantPaymentColumns(applicantPayment)}
            dataSource={dataSource}
            rowSelection={rowSelection}
          />
        </>
      );
    },
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text type="secondary" style={{ marginBottom: 20 }}>
          {groupStudentsLength} result found showing {groupStudentsLength} of{' '}
          {groupStudentsLength}
        </Text>
      </Flex>
      <Spin spinning={isScreenLoading}>
        <Col span={22} offset={1}>
          <Row justify="end">
            {rowSelectionAdapter.isSelected && (
              <Col style={{ marginRight: '8px' }}>
                <Button
                  variant="primary"
                  onClick={handleOpenBulkUpdatePaymentType}
                >
                  Payment Type Update
                </Button>
              </Col>
            )}
          </Row>
          {selectedApplicantsCount > 0 && (
            <ResultSummary
              text={`Selected ${selectedApplicantsCount} applicants`}
            />
          )}
        </Col>
        {paymentBootcampTables.map((payment) => payment)}
      </Spin>
    </>
  );
};
