import { Modal, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Icons } from '../../components/general';
import { Edit } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { deleteTeamById } from '../../services/team.service';

export const EditTeamColumn = ({ id, teamName }: { id: number; teamName: string } ) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenEditUserForm = () => {
    changeId(id);
    openDrawer(AppFormsEnum.EditTeamForm, `Edit ${teamName}`);
  };

  return (
    <Edit onClick={handleOpenEditUserForm} style={{ cursor: 'pointer' }}/>
  );
};


export const DeleteTeamColumn = ({ id, name }: { id : number, name: string }) => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const cache = useQueryClient();

  const deleteTeamMutation = useMutation(deleteTeamById);

  const handleToggle = () => {
    setIsOpenModal((state) => !state);
  };

  const handleDeleteTeam = (id: number) => {
    return async () => {
      await withCatch(() => deleteTeamMutation.mutateAsync(id), {
        onSuccess: () => cache.invalidateQueries(UseQueryTypes.TEAMS),
      });
      handleToggle();
    }
  };

  return (
    <Spin spinning={deleteTeamMutation.isLoading}>
      <Icons.Delete
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
      />
      <Modal
        visible={isModalOpen}
        onOk={handleDeleteTeam(id)}
        okText={'Yes'}
        okType={'danger'}
        onCancel={handleToggle}
        centered
        width={600}
      >
        <Text>Permanently Delete team {name} with all members ?</Text>
      </Modal>
    </Spin>
  );
};
