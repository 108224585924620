import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Certificates } from '../../../types/certificates.types';
import type { RootState } from '../../index';

interface CertificateState {
  formValues: Certificates;
}

const initialState = {
  formValues: {
    descriptionSecondLine: 'offered by Armenian Code Academy',
  },
} as CertificateState;

export const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    setInitialValues: (
      state,
      action: PayloadAction<CertificateState['formValues']>,
    ) => {
      state.formValues = action.payload;
    },
  },
});

export const certificateActions = certificateSlice.actions;
export const selectCertificateFormValues = (state: RootState) =>
  state.certificate.formValues;
