import './index.css';
import './libs/firebase/firebase.libs';

import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as GlobalStateProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { DefaultErrorBoundary } from './components/general/Error/DefaultErrorBoundary';
import { DrawerProvider } from './contexts/drawer.context';
import { NotificationProvider } from './contexts/notification.context';
import { QueryProvider } from './contexts/react-query.context';
import { ProvideAuth } from './contexts/useAuth.context';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

ReactDOM.render(
  <React.StrictMode>
    <QueryProvider>
      <BrowserRouter>
        <DefaultErrorBoundary>
          <GlobalStateProvider store={store}>
            <ProvideAuth>
              <DrawerProvider>
                <NotificationProvider>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </NotificationProvider>
              </DrawerProvider>
            </ProvideAuth>
          </GlobalStateProvider>
        </DefaultErrorBoundary>
      </BrowserRouter>
    </QueryProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
