import { ErrorMessage, Field, useField, useFormikContext } from 'formik';

import { Input as FormInput } from '../../../components/general';

export const TagsField = () => {
  const [{ value }, , { setValue }] = useField<string[]>('tags');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tags = event.target.value.split(',');
    setValue(tags);
  };

  return (
    <>
      <Field
        component={FormInput}
        label="Tags (up to 3, comma-separated)"
        placeholder="e.g. newsletter, aca-python"
        name="tags"
        value={value.join(',')}
        onChange={handleChange}
      />
      <ErrorMessage name="tags" component="span" className="error" />
    </>
  );
};

export const EmailField = () => {
  const formik = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = formik.getFieldMeta<string>('fullName').value;
    const email = event.target.value;

    formik.setFieldValue('email', email);
    formik.setFieldValue('from', `${fullName} <${email}>`);
  };

  return (
    <>
      <Field
        component={FormInput}
        label="Email"
        placeholder="e.g. dev@aca.am"
        name="email"
        onChange={handleChange}
        required
      />
      <ErrorMessage name="email" component="span" className="error" />
    </>
  );
};

export const FullNameField = () => {
  const formik = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = formik.getFieldMeta<string>('email').value;
    const fullName = event.target.value;

    formik.setFieldValue('fullName', fullName);
    formik.setFieldValue('from', `${fullName} <${email}>`);
  };

  return (
    <>
      <Field
        label="Send From (Full Name)"
        placeholder="e.g. Dev from ACA"
        name="fullName"
        component={FormInput}
        onChange={handleChange}
        required
      />
      <ErrorMessage name="fullName" component="span" className="error" />
    </>
  );
};
