import { PageSizeOptions } from "../../../../constants/sorters.constants";
import { replaceAllVariables } from "../../../../helpers/mail/replaceAllVariables.helpers";
import { normalaizeKeys } from "../../../../helpers/mail/setMailTemplateVariablesAsObjectKeys.helpers";
import { DateService } from "../../../../services/date.service";
import { FilterApplicantOptions, IStudentBrief, IStudentWithAbsentees, StudentStatusEnum } from "../../../../types/applicant.types";
import { CourseLevelEnum } from "../../../../types/course.types";
import { FeeClientTypeEnum, ITuitionFee } from "../../../../types/fee.types";
import { DurationModel } from "../../../../types/general.types";
import { GroupStateEnum, IGroup } from "../../../../types/group.types";

export const getGroupInfo = (group: IGroup) => {
  return [
    {
      name: 'instructor',
      value: `${group?.groupInstructors?.map(({ name }) => name).join('\n')}`,
    },
    {
      name: 'pc',
      value: `${group.projectCoordinator.firstName} ${group.projectCoordinator.lastName}`,
    },
    {
      name: 'start date',
      value: DateService.getFullDate(group.startDate || ''),
    },
    {
      name: 'duration',
      value: `${group.duration.value} ${group.duration.timeScale}`,
    },
    {
      name: 'end date',
      value: DateService.getFullDate(group.endDate || ''),
    },
    {
      name: 'size',
      value: group.size || 'N/A',
    },
    {
      name: 'Agreement 3.1',
      value: group.agreement_3_1_point || '',
    },
  ].filter(item => item.value);
}

export const setTemplateVarsValues = (template: string, objOfVars: any) => {
  const templateConditionsRegex = /\{\{#if NEW_CONTENT}}|\{\{\/if}}/g;
  const clearTemplate = template.replace(templateConditionsRegex, '');

  const normalizedObjOfVars = normalaizeKeys(objOfVars);
  return replaceAllVariables(clearTemplate, normalizedObjOfVars);
};

export const replaceBodyNewContent = ({
  body,
  newContent = '',
  courseName = '',
}: {
  body: string;
  newContent?: string;
  courseName?: string;
}): string => {
  const initialContent = '{{NEW_CONTENT}}';
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  const parsedHtmlBody = parser.parseFromString(body, 'text/html');
  const newContentLi = parsedHtmlBody.getElementById('NEW_CONTENT') as HTMLElement;

  const courseContent = parsedHtmlBody.getElementById('COURSE_NAME') as HTMLElement;

  if (newContentLi.innerText === initialContent || newContent === '') {
    newContentLi.style.display = 'none';
  } else {
    newContentLi.style.display = 'list-item';
  }

  newContentLi.innerText = newContent;
  courseContent.innerText = courseName;

  return serializer.serializeToString(parsedHtmlBody);
};

export const getDefaultFilterOptionsByGroupStatus = (groupIds: number[], groupState: GroupStateEnum, level: CourseLevelEnum) => {
  const options: any = {
    groupIds,
    loadExams: true,
    calcAttachments: true,
    calcFeedbacks: true,
  }
  if(groupState !== GroupStateEnum.TERMINATED) {
    options.calcDebt = true;
  } else {
    options.loadCV = true;
  }
  if(groupState == GroupStateEnum.PLANNING || groupState == GroupStateEnum.ONGOING && level !== CourseLevelEnum.LEVEL_0) {
    options.loadGraduatedGroups = true;
  }

  return options
}

export const getDefaultApplicantFilterOptions = (group: IGroup): Partial<FilterApplicantOptions> => {
  return {
    pageSize: PageSizeOptions.defaultPageSize,
    groupIds: [group.id],
    groupState: group.groupState,
    // statuses: Object.keys(ApplicantStatusOptions).filter(
    //   (key) => key !== ApplicantStatusOptions.DROP_OUT.value,
    // ) as StudentStatusEnum[],
  };
};

export const getCourseFullPayment = (duration: DurationModel, tuitionFee: ITuitionFee) => {
  return !tuitionFee.prePaymentPercent || tuitionFee.feeType === FeeClientTypeEnum.PER_COURSE ? tuitionFee.amount : duration?.value * tuitionFee.amount
};

export const getApplicantWithTotalAbsenteesByGroup = (applicants: IStudentBrief[], groupStudents: IStudentWithAbsentees[]) => {
  return applicants.map((applicant) => {
    const { totalAbsentees } = groupStudents.find(({id}) => id === applicant.id) || {}

    return {...applicant, totalAbsentees: totalAbsentees ?? 0 }
  })
}
