import { FilterOptions, FilterResponse } from './general.types';

export enum UserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MODERATOR = 'ROLE_MODERATOR',
  ROLE_PROJECT_COORDINATOR = 'ROLE_PROJECT_COORDINATOR',
  ROLE_SALES_SUPPORT_SPECIALIST = 'ROLE_SALES_SUPPORT_SPECIALIST',
  ROLE_PROGRAM_MANAGER = 'ROLE_PROGRAM_MANAGER',
}

export interface ITeam {
  id: number;
  teamName: string;
  created_at: string;
  deleted_at: string;
  updated_at: string;
}
export enum UserTeamPosition {
  TEAM_LEAD = 'TEAM_LEAD',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export type UserRoleType = keyof typeof UserRole;

export interface IUser {
  avatar: string;
  colorHex: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  roles: UserRoleType[];
  username: string;
  name?: string;
  password?: string;
  tracks?: string[];
  comments?: string[];
  authGroup?: string[];
  uuid?: string;
  linkedInProfile?: string;
  deactivated?: boolean;
  teams?: ITeam[];
  teamPosition: UserTeamPosition;
  signatureLink?: string;
}

export interface IUserLogin {
  loginTerm: string;
  password: string;
  showPassword?: boolean;
}

export interface ICreateUser {
  avatar: string;
  colorHex: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roles: UserRoleType[];
  username: string;
}

export interface IResetPassword {
  confirmPassword: string;
  newPassword: string;
  oldPassword: string;
}

export type AuthResponse = {
  accessToken: string;
};

export type UserStage = IUser;

export interface UserFilterResponse extends FilterResponse {
  users: IUser[];
}

export interface UserFilterModel {
  roles: UserRoleType[];
}

export type FilterUserOptions = UserFilterModel & FilterOptions;

export interface ICreatePasswordPayload {
  id: string;
  payload: {
    token: string;
    password: string;
  };
}

export interface PassShow {
  confirmPassword: boolean;
  password: boolean;
}

export type IUserTypes = {
  USER: string;
  STUDENT: string;
  INSTRUCTOR: string;
};

export interface IUserVariables {
  [key: string]: string
}

export interface ITeamWithMembers {
  id: number;
  teamName: string;
  members: IUser[]
}

export type helpMergeType = {
  [key: string]: ITeamWithMembers
}

export interface IMember {
  id: number;
  avatar: string;
  username: string;
  email: string;
  deactivated: number;
  firstName: string;
  lastName: string;
  teamPosition: string;
}
