import { INotificationNormalized } from '../types/notification.types';
import { IReminderNormalized } from '../types/reminder.types';

const checkIfItemIsReminder = (
  item: INotificationNormalized | IReminderNormalized,
) => Boolean(item.data.hasOwnProperty('remindingDate'));

export const notificationsSorter = ({
  notifications,
  reminders,
}: {
  notifications: INotificationNormalized[];
  reminders: IReminderNormalized[];
}) => {
  return [...notifications, ...reminders].sort((a, b) => {
    const isAReminder = checkIfItemIsReminder(a);
    const isBReminder = checkIfItemIsReminder(b);

    if (isAReminder && isBReminder) {
      const firstItem = a as IReminderNormalized;
      const secondItem = b as IReminderNormalized;

      return (
        new Date(secondItem.data.remindingDate).getTime() -
        new Date(firstItem.data.remindingDate).getTime()
      );
    }

    if (isAReminder && !isBReminder) {
      const firstItem = a as IReminderNormalized;
      const secondItem = b as INotificationNormalized;

      return (
        new Date(secondItem.data.created_at).getTime() -
        new Date(firstItem.data.remindingDate).getTime()
      );
    }

    if (!isAReminder && isBReminder) {
      const firstItem = a as INotificationNormalized;
      const secondItem = b as IReminderNormalized;

      return (
        new Date(secondItem.data.remindingDate).getTime() -
        new Date(firstItem.data.created_at).getTime()
      );
    }

    if (!isAReminder && !isBReminder) {
      const firstItem = a as INotificationNormalized;
      const secondItem = b as INotificationNormalized;

      return (
        new Date(secondItem.data.created_at).getTime() -
        new Date(firstItem.data.created_at).getTime()
      );
    }

    return 1;
  });
};
