import {
  ICreatePasswordErrorMassage,
  IForgotPasswordErrorMassages
} from '../types/ExpiredToken';

export const CreatePasswordErrorMassages: ICreatePasswordErrorMassage = {
  password: 'The user already has password!',
  token:'This link is no longer valid.'
};


export const ForgotPasswordResponseMassages: IForgotPasswordErrorMassages = {
  USER_DOESNT_EXIST: 'User with this email is not registered in the system!',
  SUCCESS: "Check your email. The link will expire in 1 hour."
}
