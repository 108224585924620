import { useQuery } from 'react-query';

import { Badges } from '../../../components/display';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { getGroupsCount } from '../../../services/groups.service';
import { WedgedLevels, WedgedTypes } from '../../../types/general.types';
import { GroupStateEnum } from '../../../types/group.types';

export default function GroupStatistics({
                                          groupStatus,
                                        }: {
  groupStatus: GroupStateEnum;
}) {
  const countResult = useQuery(
    [UseQueryTypes.GROUP_COUNT_INFO],
    () =>
      getGroupsCount(
        {
          groupBy: 'level',
          groupState: [groupStatus],
        },
      ),
  );

  return (
    <Badges
      wedgedType={WedgedTypes.LEVEL}
      badges={[
        {
          data: countResult?.data?.level_0,
          title: 'Level 0',
          name: WedgedLevels.LEVEL_0,
        },
        {
          data: countResult?.data?.level_1,
          title: 'Level 1',
          name: WedgedLevels.LEVEL_1,
        },
        {
          data: countResult?.data?.level_2,
          title: 'Level 2',
          name: WedgedLevels.LEVEL_2,
        },
      ]}
    />
  );
}
