import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { promoCodeColumns } from '../../../../schemas/applicantDetail/schemas/promoCodeColumns.schema';
import { IApplicantPromoCode } from '../../../../types/applicantDetail.types';

export function PromoCodeTable({ promoCodes }: { promoCodes: IApplicantPromoCode[] }) {
  const table = useTable<IApplicantPromoCode>(promoCodeColumns);

  return (
    <Table<IApplicantPromoCode>
      {...table.getTableProps({
        rowKey: ({ id }: IApplicantPromoCode) => id,
        dataSource: promoCodes,
      })}
    />
  );
}
