import { DateService } from "../../../services/date.service";
import { IApplicantPromoCode } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';

export const promoCodeColumns: ColumnSchema<IApplicantPromoCode> = {
  uuid: 'promoCodeColumns',
  schema: {
    created_at: {
      title: 'Date',
      width: 200,
      render: DateService.getFullDate,
    },
    promoCode: {
      title: 'Promo Code',
      width: 400,
      render: (promoCode) => promoCode,
    },
  },
};
