import { Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, CustomDatePickerFormik, Flex, Input as FormInput } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { DateService } from '../../services/date.service';
import { getAllUser } from '../../services/user.service';
import type { FilterApplicantOptions } from '../../types/applicant.types';
import type { FilterConsultantOptions } from '../../types/consultation.types';
import type { IAppForm } from '../../types/form.types';

const defaultConsultantsFilterOptions: Partial<FilterConsultantOptions> = {};

export const FilterConsultantForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { filterOptions, changeOptions, resetOptions } = useFilter(defaultConsultantsFilterOptions);
  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);
  const usersContext = useMemo(() => {
    const users = usersQuery.data || [];
    return generateContext(users, (user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  }, [usersQuery.data]);

  const initialValues = filterOptions;

  const handleSubmit = (filterOptions: Partial<FilterApplicantOptions>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  const isScreenLoading = usersQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
        {(props) => (
          <Form
            name="filters-1"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field
              name="dateFrom"
              label="Date From"
              parseDate={DateService.getStartOfDay}
              formatDate={(date: Date) => date?.toISOString()}
              component={CustomDatePickerFormik}
            />
            <Field
              name="dateTo"
              label="Date To"
              parseDate={DateService.getEndOfDay}
              formatDate={(date: Date) => date?.toISOString()}
              component={CustomDatePickerFormik}
            />
            <Field name="name" label="Name" component={FormInput} />
            <Field name="email" label="Email" component={FormInput} />
            <Field name="phoneNumber" label="Phone" component={FormInput} />

            <Field label="Created By" name="createdBy" component={MultiselectFormik} options={usersContext} />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button
                htmlType="button"
                style={{ minWidth: 160, marginRight: 8 }}
                onClick={() => {
                  resetOptions();
                  props.handleReset();
                }}
              >
                Reset
              </Button>
              <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary">
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
