import { Avatar, Checkbox } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import { Button, Flex, TextArea } from '../../../../../components/general';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { getAvatar } from '../../../../../helpers/avatar.helper';
import { DateService } from '../../../../../services/date.service';
import { DrawerTypes } from '../../../../../types/drawer.types';
import type { IFeedback } from '../../../../../types/feedback.types';
import type { ITransform } from '../../../../../types/form.types';
import { WarningModal } from '../../../modals/warning.modal';
import { FeedbackFormSchema } from '../schemas/feedback.from.schema';
import styles from './addFeedbackForm.module.css';

interface IFeedbackForm {
  feedbacks: IFeedback[];
  entity: Partial<{
    name: string;
    email: string;
  }>;
}

const FeedbackItem = ({ feedback }: { feedback: IFeedback }) => {
  return (
    <div key={feedback.id} className={styles.feedbackItemContainer}>
      <Flex alignItems="center">
        <Avatar
          src={getAvatar(feedback.ownerAvatar)}
          className={styles.feedbackItemAvatar}
        />
        <p className={styles.feedbackItemUsername}>@{feedback.ownerUsername}</p>
        <p className={styles.feedbackItemDate}>
          {DateService.getFormatDistanceToNow(feedback.created_at)}
        </p>
      </Flex>
      <p className={styles.feedbackItemContent}>{feedback.content}</p>
    </div>
  );
};

export function AddFeedbackForm<T>({
  entity,
  feedbacks,
  onSubmit,
  initialValues,
}: IFeedbackForm & ITransform<T>) {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const { openDrawer } = useDrawer();

  const handleSubmitAddFeedbackForm = async (values: any, formik: any) => {
    if (!values.isPrivate) {
      return setIsOpenModal(true);
    }

    await onSubmit(values);
    formik.resetForm({});
  };

  const submitAfterApprove = (formik: any) => async () => {
    await onSubmit(formik.values);
    formik.resetForm({});
    setIsOpenModal(false);
  };

  const handleToggle = () => {
    setIsOpenModal((state) => !state);
  };

  const feedbackFormText = 'Add Applicant Feedback';

  useEffect(() => {
    if (isModalOpen) {
      openDrawer(
        AppFormsEnum.AddApplicantFeedbackForm,
        feedbackFormText,
        DrawerTypes.Form,
        false,
      );
    } else {
      openDrawer(
        AppFormsEnum.AddApplicantFeedbackForm,
        feedbackFormText,
        DrawerTypes.Form,
        true,
      );
    }
  }, [isModalOpen]);

  return (
    <>
      <Flex
        direction="column"
        style={{
          height: 'calc(100vh - 120px)',
        }}
      >
        <div className={styles.feedbackComponentHeader}>
          <p className={styles.feedbackComponentHeaderName}>{entity.name}</p>
          <p className={styles.feedbackComponentHeaderEmail}>{entity.email}</p>
        </div>
        <div className={styles.feedbackComponentItemsWrapper}>
          {feedbacks.map((feedback) => (
            <FeedbackItem feedback={feedback} />
          ))}
        </div>

        <Flex
          className={styles.feedbackComponentFormWrapper}
          direction="column"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitAddFeedbackForm}
            validationSchema={FeedbackFormSchema}
            enableReinitialize
          >
            {(props) => (
              <>
                <Form>
                  <Field
                    rows={3}
                    label="Feedback"
                    name="feedback"
                    component={TextArea}
                  />
                  <ErrorMessage
                    name="feedback"
                    component="span"
                    className="error"
                  />

                  <p style={{ marginTop: 20 }}>
                    <label htmlFor="isPrivate" style={{ marginRight: 10 }}>
                      Private share (only pms)
                    </label>
                    <Checkbox
                      name="isPrivate"
                      onChange={(e) => {
                        if (e.target.checked)
                          return props.setFieldValue('isPrivate', 1);
                        props.setFieldValue('isPrivate', 0);
                      }}
                      // @ts-ignore
                      checked={Boolean(props.values.isPrivate)}
                    />
                    <ErrorMessage
                      name="isPrivate"
                      component="span"
                      className="error"
                    />
                  </p>

                  <Flex
                    justifyContent="space-between"
                    style={{ marginTop: 20 }}
                  >
                    <Button
                      htmlType="button"
                      style={{ minWidth: 160, marginRight: 8 }}
                      onClick={props.handleReset}
                    >
                      Reset
                    </Button>
                    <Button
                      style={{ minWidth: 160 }}
                      htmlType="submit"
                      variant="primary"
                      disabled={!(props.dirty && props.isValid)}
                    >
                      Add feedback
                    </Button>
                  </Flex>
                </Form>
                <WarningModal
                  isModalOpen={isModalOpen}
                  cancel={handleToggle}
                  submit={submitAfterApprove(props)}
                  text="The feedback will be visible to Applicant!"
                />
              </>
            )}
          </Formik>
        </Flex>
      </Flex>
    </>
  );
}
