import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useAuth } from '../../../contexts/useAuth.context';
import { getApplicantById } from '../../../services/applicant.service';
import { getStudentAttachments } from '../../../services/student.service';
import { getStudentEvaluationFormStatus } from '../../../services/studentGroup.service';
import { AttachmentTypeEnum } from '../../../types/attachment.types';
import CertificateRenderer from '../components/Certificate/certificateRenderer';
import GoogleNavigationWindow from '../components/Certificate/googleFormNavigationWindow';
import EmptyDataComponent from '../components/EmptyData/EmptyDataComponent';
import { StudentQueryConstants } from '../constants/studentQuery.constants';

export const Certificate = () => {
  const { user } = useAuth()
  const [isModalVisible, setModalIsVisible] = useState<boolean>(false)

  const handleToggleModal = (isModalVisible: boolean) => {
    setModalIsVisible(isModalVisible);
  }


  const applicant = useQuery(
    [UseQueryTypes.APPLICANT_ID],
    () => getApplicantById(user?.id as number),
    { enabled: Boolean(user) }
  )

  const isGoogleEvaluationFormSubmitted = useQuery(
    [StudentQueryConstants.STUDENTS_EVALUATION_FORM],
    () => getStudentEvaluationFormStatus(applicant.data?.group.id as number, applicant.data?.id as number),
    {
      enabled: Boolean(applicant.data),
      onSuccess(res) {
       !res.isEvaluationFormCompleted  && setModalIsVisible(true);
      }
    }
  )

  const studentCertificateQuery = useQuery(
    [StudentQueryConstants.STUDENT_ATTACHMENTS],
    () =>
      getStudentAttachments(user?.id as number, AttachmentTypeEnum.CERTIFICATE),
    { enabled: Boolean(user) }
  );

  const studentCertificate = studentCertificateQuery.data?.attachments?.[0];

  return (
    <>
      {
        isModalVisible &&
        <GoogleNavigationWindow
          evaluationLink={applicant?.data?.group.evaluationLink as string}
          isModalVisible={isModalVisible}
          handleToggleModal={handleToggleModal}
        />
      }
      {
        studentCertificate
          ? <CertificateRenderer handleToggleModal={handleToggleModal} isCertificateVisible={!!isGoogleEvaluationFormSubmitted.data?.isEvaluationFormCompleted} studentCertificate={studentCertificate}/>
          : <EmptyDataComponent dataTitle='certificate' hasDescription={true} />
      }
    </>
  );
}
