import { useAuth } from '../../../../contexts/useAuth.context';
import { AuthGroup } from '../../../../types/auth.types';
import { Notification } from '../../../user/components/AccountInfo/notification';
import { AddButtonTypeEnum } from '../../types/button.types';
import { IAccountInfo } from '../../types/dashboard.types';
import { AddButton } from './addButton';

export const accountHeaderItems: IAccountInfo = {
  [AuthGroup.USER]: [() => <AddButton />, Notification],
  [AuthGroup.STUDENT]: [() => <AddButton type={AddButtonTypeEnum.FEEDBACK} />],
  [AuthGroup.INSTRUCTOR]: [],
};

const HeaderElements = () => {
  const { user } = useAuth();
  const elements = accountHeaderItems[user?.authGroup as keyof typeof accountHeaderItems];

  return (
    <>
      {elements.map((Component, index) => (
        <Component key={index} />
      ))}
    </>
  );
};

export default HeaderElements;
