import { Col,Row } from 'antd';

import styles from '../Teams.module.css'

export interface IHeaderItem {
  title: string;
  span: number;
}

const HeaderItem = ({ title, span  }: IHeaderItem) => {
  return (
    <Col className={styles.headerItem} span={span}>
      { title }
    </Col>
  )
}

export const TeamsHeader = ({ headersItem }: { headersItem: IHeaderItem[] }) => {
  return (
    <Row className={styles.customHeader}>
      {
        headersItem.map(({ title, span }) => <HeaderItem title={title} span={span} key={title} />)
      }
    </Row>
  )
}
