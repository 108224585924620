import { Typography } from 'antd';
import { FieldHookConfig, useField } from 'formik';
import { FC, useMemo } from 'react';
import Select from 'react-select';

import { Flex } from '..';
import { IOption } from '../Multiselect/Multiselect';

export interface ISingleselect {
  options: Record<string, IOption>;
  name: string;
  onChange(value: IOption['value'] | null): void;
  onBlur?(): void;
  label?: string;
  value?: IOption['value'];
  defaultValue?: IOption['value'];
  style?: React.CSSProperties;
  required?: boolean | null;
}

export const SingleSelect: React.FC<ISingleselect> = ({
  options = {},
  name,
  onChange,
  label,
  value,
  defaultValue,
  required = false,
  ...props
}) => {
  const { Text } = Typography;
  const computedDefaultValue = useMemo(() => {
    if ((value === 0 || value) && value in options) return options[value];

    return defaultValue && defaultValue in options ? options[defaultValue] : { label: '', value: '' };
  }, [defaultValue, options, value]);

  const labelElement = label && (
    <Text
      style={{
        marginBottom: 4,
      }}
    >
      {label}
    </Text>
  );
  const requiredElement = required && (
    <Text
      style={{
        marginBottom: 4,
        marginLeft: 3,
        color: value ? '#646464' : 'red',
      }}
    >
      *
    </Text>
  );

  return (
    <div style={{ margin: '5px 0' }}>
      <Flex>
        {labelElement}
        {requiredElement}
      </Flex>
      <Select
        styles={{
          input: (providedValue) => ({
            ...providedValue,
            padding: '7px 0',
          }),
          placeholder: (providedValue) => ({
            ...providedValue,
            margin: '0 4px',
          }),
        }}
        name={name}
        options={Object.values(options)}
        isMulti={false}
        isSearchable
        value={computedDefaultValue}
        onChange={(selected) => {
          if (!selected) return onChange(null);
          return onChange(selected.value);
        }}
        {...props}
      />
    </div>
  );
};

export const SingleSelectFormik: FC<ISingleselect & FieldHookConfig<string>> = (props) => {
  // @TODO: figure out type
  // @ts-ignore
  const [field, , helpers] = useField(props.field);

  return (
    <SingleSelect
      {...props}
      value={field.value}
      onBlur={() => {
        helpers.setTouched(true);
        props.onBlur && props.onBlur();
      }}
      onChange={(selectedValues) => {
        helpers.setValue(selectedValues);
        props.onChange && props.onChange(selectedValues);
      }}
    />
  );
};
