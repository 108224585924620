import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { commentColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IComment } from '../../../../types/comment.types';

export function CommentTable({ comments }: { comments: IComment[] }) {
  const table = useTable<IComment>(commentColumns);

  return (
    <Table<IComment>
      {...table.getTableProps({
        rowKey: ({ id }: IComment) => id,
        dataSource: comments,
      })}
    />
  );
}
