import { WedgedDates } from '../constants/wedged.constants';
import { DateService } from '../services/date.service';

export function getFormattedDate(d: string | Date) {
  const date = d ? new Date(d) : new Date();

  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${day}/${month}/${year}`;
}

export const getDateFormsByWidgetType = (timeWidget: string) => {
  const widgetFilterDate = {
    dateFrom: DateService.getServerDate(),
    dateTo: DateService.getServerDate(),
  };
  switch (timeWidget) {
    case WedgedDates.TODAY: {
      return widgetFilterDate;
    }
    case WedgedDates.THIS_WEEK: {
      widgetFilterDate.dateFrom = DateService.getStartOfWeekDate();
      return widgetFilterDate;
    }
    case WedgedDates.THIS_MONTH: {
      widgetFilterDate.dateFrom = DateService.getStartOfMonthDate();
      return widgetFilterDate;
    }
    default:
      return widgetFilterDate;
  }
};

export const getDropOutMonths = (groupStartDate: Date = new Date(), dropOutDate: Date = new Date()): number => {
  const diffMilliseconds = Number(dropOutDate) - Number(groupStartDate);
  const oneMonth = 1000 * 60 * 60 * 24 * 30;

  return Math.ceil(diffMilliseconds / oneMonth);
};
