import { stringifyObject } from '../helpers/stringifyObj.helper';
import { ApiSuccessMassage } from "../modules/shared/types/shared.types";
import { ResetPassword } from "../types/auth.types";
import { IComment, ICreateComment } from '../types/comment.types';
import { Sorters } from '../types/general.types';
import {
  ICreateInstructor,
  IInstructor,
  InstructorFilterModel,
  InstructorFilterResponse,
} from '../types/instructor.types';
import {
  AuthResponse,
  IResetPassword,
  IUser,
  IUserLogin,
} from '../types/user.types';
import axios from './config.service';

export const login = async (instructor: IUserLogin) => {
  const { data } = await axios.post<AuthResponse>(
    '/auth/signin/instructor/',
    instructor,
  );
  axios.defaults.headers.common = {
    Authorization: `Bearer ${data.accessToken}`,
  };

  return data;
};

const signOut = async (): Promise<ApiSuccessMassage> => {
  return await axios.post(`/auth/signout/instructor/`);
};

export const getCurrentUser = (): Promise<{ data: any }> => {
  return axios.get('/instructor/me');
};

export function getAllInstructors() {
  return axios.get(`/instructor`).then((r) => r.data) as Promise<IInstructor[]>;
}

export function filterInstructors(
  filters: Partial<InstructorFilterModel> = {},
  sorters: Partial<Sorters> = {},
) {
  const queryString = stringifyObject(sorters);
  return axios
    .post(`/instructor/filter?${queryString}`, filters)
    .then((r) => r.data) as Promise<InstructorFilterResponse>;
}

export function createInstructor(instructor: ICreateInstructor) {
  return axios
    .post('/instructor', instructor)
    .then((r) => r.data) as Promise<IInstructor>;
}

export function getInstructorById(id: number) {
  return axios
    .get(`/instructor/${id}`)
    .then((r) => r.data) as Promise<IInstructor>;
}

export function updateInstructorById({
  id,
  instructor,
}: {
  id: number;
  instructor: Partial<ICreateInstructor>;
}) {
  return axios
    .put(`/instructor/${id}`, instructor)
    .then((r) => r.data) as Promise<IInstructor>;
}

export function updateInstructor({
  user,
  id,
}: {
  user: Partial<IUser>;
  id: number;
}) {
  return axios
    .put(`/instructor/${id}`, user)
    .then((r) => r.data) as Promise<IInstructor>;
}

interface IAddInstructorComment {
  instructorId: number;
  comment: ICreateComment;
}

export async function addInstructorComment({
  instructorId,
  comment,
}: IAddInstructorComment) {
  const r = await axios.post(`/instructor/${instructorId}/comment`, comment);
  return r.data;
}

export async function updateInstructorComment(comment: IComment) {
  const r = await axios.put(`/instructor/comment/${comment.id}`, comment);
  return r.data;
}

export function countInstructor() {
  return axios
    .get(`/instructor/status/count`)
    .then((r) => r.data) as Promise<any>;
}

export const updateInstructorPassword = async ({
  id,
  passwords,
}: {
  id: number;
  passwords: IResetPassword;
}) => {
  const r = await axios.put(`/instructor/${id}/change-password`, passwords);
  return r.data as Promise<IUser>;
};

export const resetPassword = (data: ResetPassword): Promise<{ data: any }> => {
  return axios.put('/instructor/reset-password/', data);
};

export default signOut;
