import { Flex } from "../../../../components/general";
import { Star } from "../../../../components/general/Icons/Icons";
import { UserDescriptions } from "../../../shared/components/UserProfile/UserDescriptions";
import UserProfileLogo from "../../../shared/components/UserProfile/UserProfileLogo";
import { IStudentFeedback, IStudentGivenFeedback } from "../../types/studentFeedback.types";
import styles from './Feedbacks.module.css';


function FeedbackItem({ feedback }: { feedback: IStudentFeedback | IStudentGivenFeedback }) {

  return (
    <div className={styles.feedbackItemWrapper}>

      <div className={styles.feedbackItemResponsive}>
        <div className={styles.feedbackItemUser}>
          {'avatar' in feedback && <UserProfileLogo avatarLink={feedback.avatar}/>}
          {'name' in feedback && 'role' in feedback && <UserDescriptions header={feedback.name} content={feedback.role}/>}
          {'group' in feedback && <p>{feedback.group?.name ?? 'Group not assigned'}</p>}
        </div>
        
        <Flex justifyContent="space-between" className={styles.contentWrapper}>
          <p style={{ color: '#0F172E', margin: '0' }}>{feedback.content}</p>
          {'rating' in feedback && <p>{feedback.rating} <Star width={10} color='#5451FF' /></p>}
        </Flex>

      </div>
    </div>
  );
}

export default FeedbackItem;
