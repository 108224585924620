import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Citizenship, IAgreementGlobalState } from '../../../../modules/student/types/studentAgreement.types';
import type { RootState } from '../../../index';

const defaultInfo = {
  citizenship: Citizenship.ARMENIA,
  isUnderage: false,
  contractNumber: null,
  contractYear: null,
  address: '',
  nameHy: '',
  passportGivenDate: '',
  passportNumber: '',
  socialCardNumber: null,
  studentNameHy: null,
};

const defaultInfoTemplate = new Uint8Array(0);

export const StudentAgreementInitialValues: IAgreementGlobalState = {
  applicantPersonalInfoRequestModel: { ...defaultInfo },
  agreementTemplate: defaultInfoTemplate,
};

export const studentAgreementSlice = createSlice({
  name: 'studentAgreementSlice',
  initialState: StudentAgreementInitialValues,
  reducers: {
    changeAgreementData: (state, action: PayloadAction<Partial<IAgreementGlobalState>>) => {
      const { applicantPersonalInfoRequestModel, agreementTemplate } = action.payload;
      if (applicantPersonalInfoRequestModel) {
        state.applicantPersonalInfoRequestModel = applicantPersonalInfoRequestModel;
      }
      if (agreementTemplate) {
        state.agreementTemplate = agreementTemplate;
      }
    },
    resetAgreementData: (state) => {
      state.applicantPersonalInfoRequestModel = { ...defaultInfo };
      state.agreementTemplate = defaultInfoTemplate;
    },
  },
});

export const { changeAgreementData, resetAgreementData } = studentAgreementSlice.actions;

export const selectStudentAgreement = (state: RootState) => state.studentAgreement;
