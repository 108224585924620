import { ReminderStatus, ReminderType } from '../types/reminder.types';

export const ReminderTypesOptions = {
  [ReminderType.APPLICANT]: {
    value: ReminderType.APPLICANT,
    label: 'Applicant',
  },
  [ReminderType.GROUP]: {
    value: ReminderType.GROUP,
    label: 'Group',
  },
  [ReminderType.INSTRUCTOR]: {
    value: ReminderType.INSTRUCTOR,
    label: 'Instructor',
  },
  [ReminderType.OTHER]: {
    value: ReminderType.OTHER,
    label: 'Other',
  },
} as const;

export const ReminderStatusOptions = {
  [ReminderStatus.ACTIVE]: {
    value: ReminderStatus.ACTIVE,
    label: 'Active',
  },
  [ReminderStatus.CANCELED]: {
    value: ReminderStatus.CANCELED,
    label: 'Canceled',
  },
  [ReminderStatus.RESOLVED]: {
    value: ReminderStatus.RESOLVED,
    label: 'Resolved',
  },
} as const;
