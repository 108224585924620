import { number, object, string } from 'yup';

import messages from '../../../../constants/messages.constants';

const { required } = messages;

export const CreateStudentAttachmentFormSchema = object().shape({
  name: string().required(required),
  attachment: string().required(required),
  applicantPaymentId: number().nullable(),
});
