import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { Button, Flex, Input } from '../../../components/general';
import { MultiselectFormik } from '../../../components/general/Multiselect/Multiselect';
import { USER_ROLES } from '../../../constants/user.constants';
import { withCatch } from '../../../helpers/error.helpers';
import { createUser } from '../../../services/user.service';
import { IUser } from '../../../types/user.types';
import styles from '../Account.module.css';

interface Props {
  isMobileSize: boolean;
}

export const CreateUserPage = ({ isMobileSize }: Props) => {
  const editUserMutation = useMutation(createUser);

  const handleSubmit = async (values: IUser) => {
    await withCatch(() => editUserMutation.mutateAsync(values));
  };

  return (
    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{} as IUser}>
      <Form style={{ width: isMobileSize ? '100%' : '77%' }}>
        <div className={styles.mainBar}>
          <Flex
            direction={isMobileSize ? 'column' : 'row'}
            alignItems={isMobileSize ? 'flex-start' : 'center'}
            justifyContent="space-between"
            className={styles.titleBar}
          >
            <p className={styles.title}>Create new user</p>
            <Flex
              alignItems="center"
              justifyContent={isMobileSize ? 'flex-end' : 'space-between'}
              className={styles.barBtns}
            >
              <Button style={{ minWidth: 150 }} htmlType="submit" variant="primary">
                Create User
              </Button>
            </Flex>
          </Flex>
          <div className={styles.content}>
            <Row justify="space-between">
              <Col lg={{ span: 7 }} xs={{ span: 24 }}>
                <Field name="firstName" label="Name" component={Input} />
              </Col>
              <Col lg={{ span: 7 }} xs={{ span: 24 }}>
                <Field name="lastName" label="Surname" component={Input} />
              </Col>
              <Col lg={{ span: 7 }} xs={{ span: 24 }}>
                <Field name="username" label="Username" component={Input} />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                <Field name="phoneNumber" label="Phone" component={Input} />
              </Col>
              <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                <Field name="email" label="Email" component={Input} />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                <Field name="roles" label="User Roles" options={USER_ROLES} component={MultiselectFormik} />
              </Col>
              <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                <Field name="password" label="One time password" component={Input} />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
