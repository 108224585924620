import { Spin } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApplicantDetailQueryTypes, ExcludedApplicationDetailTypes } from '../../../constants/useQuery.constants';
import { useApplicantDetailQueries } from '../../../hooks/useApplicantDetailQueries.hooks';
import {
  AbsenteesTable,
  ActionTable,
  AttachmentTable,
  CommentTable,
  ConsultationTable,
  ExamTable,
  FeedbackTable,
  PaymentTable,
  PromoCodeTable
} from '../components/applicantDetailTables/';
import { Applications, General, History } from '../components/sections/applicantDetailSections';

export default function ApplicantDetail() {
  const {
    APPLICANT_DETAILS_EXAMS,
    APPLICANT_DETAILS_CONSULTATIONS,
    APPLICANT_DETAILS_ABSENTEES,
    APPLICANT_DETAILS_FEEDBACKS,
    APPLICANT_DETAILS_PAYMENTS,
    APPLICANT_DETAILS_ATTACHMENTS,
    APPLICANT_DETAILS_COMMENTS,
    APPLICANT_DETAILS_ACTIONS,
    APPLICANT_DETAILS_APPLICATIONS,
    APPLICANT_DETAILS_GENERAL,
    APPLICANT_DETAILS_PROMO_CODE
  } = ApplicantDetailQueryTypes;

  const [activeDetailsTab, setActiveDetailsTab] = useState<ExcludedApplicationDetailTypes>(
    APPLICANT_DETAILS_ACTIONS,
  );
  const { id: applicantId } = useParams<{ id: string }>();

  const {
    consultations,
    exams,
    absentees,
    promoCodes,
    feedbacks,
    payments,
    attachments,
    comments,
    actions,
    applications,
    general,
  } = useApplicantDetailQueries(Number(applicantId), {
    activeQuery: activeDetailsTab,
    enabledQueries: [APPLICANT_DETAILS_APPLICATIONS, APPLICANT_DETAILS_GENERAL],
  });

  const tables = {
    [APPLICANT_DETAILS_ACTIONS]: <ActionTable actions={actions.data || []} />,
    [APPLICANT_DETAILS_COMMENTS]: <CommentTable comments={comments.data || []} />,
    [APPLICANT_DETAILS_EXAMS]: <ExamTable exams={exams.data || []} />,
    [APPLICANT_DETAILS_ABSENTEES]: <AbsenteesTable absentees={absentees.data || []} />,
    [APPLICANT_DETAILS_FEEDBACKS]: <FeedbackTable feedbacks={feedbacks.data || []} />,
    [APPLICANT_DETAILS_PAYMENTS]: <PaymentTable payments={payments.data || []} />,
    [APPLICANT_DETAILS_ATTACHMENTS]: <AttachmentTable attachments={attachments.data || []} />,
    [APPLICANT_DETAILS_CONSULTATIONS]: <ConsultationTable consultations={consultations.data || []} />,
    [APPLICANT_DETAILS_PROMO_CODE]: <PromoCodeTable promoCodes={promoCodes.data || []} />,
  };

  const isScreenLoading =
    consultations.isLoading ||
    exams.isLoading ||
    absentees.isLoading ||
    promoCodes.isLoading ||
    feedbacks.isLoading ||
    payments.isLoading ||
    attachments.isLoading ||
    comments.isLoading ||
    actions.isLoading ||
    applications.isLoading ||
    general.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <General general={general.data} />
      <Applications applications={applications} />
      <History tables={tables} state={[activeDetailsTab, setActiveDetailsTab]} />
    </Spin>
  );
}
