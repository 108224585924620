import { CopyTwoTone } from '@ant-design/icons';
import { Avatar, Spin, Tag } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { Icons } from '../../components/general';
import { Edit } from '../../components/general/Icons/Icons';
import { Tag as RoleTag } from '../../components/general/Tag/Tag';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { USER_ROLES, USER_TEAM_POSITION } from '../../constants/user.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { getAvatar } from '../../helpers/avatar.helper';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { deleteUserById } from '../../services/user.service';
import type { RenderColumn } from '../../types/column.types';
import { IMember,IUser, UserTeamPosition } from '../../types/user.types';
import styles from './user.module.css';

export const EditUserColumn: RenderColumn<IUser> = (_, user) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenEditUserForm = () => {
    changeId(user.id);
    openDrawer(AppFormsEnum.EditUserForm, `Edit ${user.username}`);
  };

  return (
    <Edit onClick={handleOpenEditUserForm} style={{ cursor: 'pointer' }}/>
  );
};

export const EmailUserColumn: RenderColumn<IUser | IMember> = (_, user) => {
  return (
    <>
      <span>{user.email}</span>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(user.email)}
        style={{ marginLeft: 8, fontSize: 16 }}
      />
    </>
  );
};

export const RolesUserColumn: RenderColumn<IUser> = (_, user) => {
  const haveRoles = user.roles.length !== 0;

  return haveRoles && (
    <RoleTag style={{ backgroundColor: '#5451FF', maxHeight: '30px', width: 'max-content', padding: '8px 12px' }}>{`${user?.roles
      ?.map((role) => USER_ROLES[role].label)
      .join(', ')}`}</RoleTag>
  )
};

export const DeleteUserColumn: RenderColumn<IUser> = (_, user) => {
  const cache = useQueryClient();

  const deleteUserMutation = useMutation(deleteUserById);

  const handleDeleteTask = async (id: number) => {
    await withCatch(() => deleteUserMutation.mutateAsync(id), {
      onSuccess: () => cache.invalidateQueries(UseQueryTypes.USERS),
    });
  };

  return (
    <Spin spinning={deleteUserMutation.isLoading}>
      <Icons.Delete
        onClick={() => handleDeleteTask(user.id)}
        style={{ cursor: 'pointer' }}
      />
    </Spin>
  );
};

export const AvatarColumn: RenderColumn<IUser | IMember> = (_, user) => {
  return (
    <div style={{ display: 'flex' }} className={user.deactivated ? `deactivated-avatar` : ''}>
      <Avatar
        src={getAvatar(user?.avatar)}
        style={{ cursor: 'pointer', marginRight: 7 }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.fullName}>{`${user.firstName}  ${user.lastName}`}</div>
        <div className={styles.userName}>{user.username}</div>
      </div>
    </div>
  );
};

export const Team: RenderColumn<IUser> = (_, user) => {
  const team = user?.teams?.[0];
  return (
    <>
      {
        user.teamPosition === UserTeamPosition.TEAM_LEAD &&
        <Tag style={{ padding: '0 3px', fontSize: '9px' }} color='success'>{USER_TEAM_POSITION.TEAM_LEAD.label}</Tag>
      }
      <div className={styles.teamName}>{team?.teamName || 'N/A'}</div>
    </>
  );
};
