import { CommentOutlined } from '@ant-design/icons';
import { Avatar, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { Flex, Icons, PopoverComponent } from '../../components/general';
import { Tag } from '../../components/general/Tag/Tag';
import { TASK_STATUS, TASK_TYPE } from '../../constants/task.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { getLastItem } from '../../helpers/array.helpers';
import { getAvatar } from '../../helpers/avatar.helper';
import { withCatch } from '../../helpers/error.helpers';
import { getRelatedToRoute } from '../../helpers/tasks/relatedTo';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { DateService } from '../../services/date.service';
import { deleteTaskById } from '../../services/tasks.service';
import type { RenderColumn } from '../../types/column.types';
import { ITaskGET, TaskSourceEnum } from '../../types/task.types';

export const TypeTaskColumn: RenderColumn<ITaskGET> = (_, task) => <Tag>{TASK_TYPE[task.taskType].label}</Tag>;

export const StatusTaskColumn: RenderColumn<ITaskGET> = (_, task) => <Tag>{TASK_STATUS[task.taskStatus].label}</Tag>;

export const DeleteTaskColumn: RenderColumn<ITaskGET> = (_, task) => {
  const cache = useQueryClient();
  const deleteTaskMutation = useMutation(deleteTaskById);

  const handleDeleteTask = async (id: number) => {
    await withCatch(() => deleteTaskMutation.mutateAsync(id), {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.CREATED_TASKS);
        cache.invalidateQueries(UseQueryTypes.TASKS);
      },
    });
  };

  return (
    <Spin spinning={deleteTaskMutation.isLoading}>
      <Icons.Delete onClick={() => handleDeleteTask(task.id)} style={{ cursor: 'pointer' }} />
    </Spin>
  );
};

export const AvatarTaskColumn: RenderColumn<ITaskGET & { activeTaskSource: TaskSourceEnum }> = (_, task) => {
  const user = task.activeTaskSource === TaskSourceEnum.CREATED ? task.owner : task.createdBy;

  return (
    <div className={user?.deactivated ? `deactivated-avatar` : ''}>
      <Avatar src={getAvatar(user?.avatar)} style={{ cursor: 'pointer', marginRight: 7 }} />@{user?.username}
    </div>
  );
};

export const EditTaskColumn: RenderColumn<ITaskGET> = (_, task) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenEditTaskForm = () => {
    openDrawer(AppFormsEnum.EditTaskForm, `Edit Task ${task.title}`);
    changeId(task.id);
  };

  return <Icons.Edit onClick={handleOpenEditTaskForm} style={{ cursor: 'pointer' }} />;
};

export const DueDateTaskColumn: RenderColumn<ITaskGET> = (_, task) => DateService.getFullDate(task.dueDate);

export const RelatedToTaskColumn: RenderColumn<ITaskGET> = (_, task) => {
  const route = getRelatedToRoute(task);

  if (!route) return <span>Other</span>;

  let relatedName = task.relatedTo?.name;

  if (task.applicant?.name) {
    relatedName = task.applicant?.name;
  }

  return <Link to={route}>{relatedName}</Link>;
};

export const CommentTaskColumn: RenderColumn<ITaskGET> = (_, task) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenAddApplicantCommentForm = () => {
    changeId(task.id);
    openDrawer(AppFormsEnum.AddTaskCommentForm, 'Add Task Comment');
  };

  const lastComment: string = getLastItem(task.comments)?.content || '';
  const commentCount: number = task.comments.length;

  return (
    <Flex onClick={handleOpenAddApplicantCommentForm} style={{ cursor: 'pointer' }} justifyContent="center">
      <PopoverComponent content={lastComment}>
        <div style={{ width: '30px', position: 'relative' }}>
          <CommentOutlined style={{ fontSize: 18 }} />
          {commentCount ? (
            <span
              style={{
                position: 'absolute',
                top: -10,
                right: 5,
                fontSize: 14,
                color: 'red',
              }}
            >
              {commentCount}
            </span>
          ) : null}
        </div>
      </PopoverComponent>
    </Flex>
  );
};
