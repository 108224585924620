import { StudentStatusEnum } from '../types/applicant.types';

export const MailgunVariables = {
  APPLICANT_NAME: 'APPLICANT_NAME',
  COURSE_NAME: 'COURSE_NAME',
  APPLICANT_EMAIL: 'APPLICANT_EMAIL',
  APPLICANT_PHONE: 'APPLICANT_PHONE',
  COMPANY_EMAIL: 'COMPANY_EMAIL',
  COMPANY_NAME: 'COMPANY_NAME',
  POSITION: 'POSITION',
  TUITION_FEE: 'TUITION_FEE',
  CARD_ACCOUNT: 'CARD_ACCOUNT',
  BANK: 'BANK',
  CONTACT_EMAIL: 'CONTACT_EMAIL',
  START_DATE: 'START_DATE',
  SCHEDULE: 'SCHEDULE',
  FILE_NAME: 'FILE_NAME',
  BRAND_NAME: 'BRAND_NAME',
  COMPANY_PHONE: 'COMPANY_PHONE',
  EMAIL: 'COMPANY_PHONE',
  PHONE_NUMBER1: 'PHONE_NUMBER1',
  PHONE_NUMBER2: 'PHONE_NUMBER2',
  PHONE_NUMBER3: 'PHONE_NUMBER3',
};

export const MassMailTemplates = [
  'aca-data-analytics-intro',
  'enroll-template',
  'registration-confirmation-intro',
  'website-sql-intro',
  'website-aca-data-analytics-intro',
  'website-bever',
  'july_tech_news',
  'website-blockchain',
  'website-content-writing',
  'website-data-science-template',
  'website-default-template',
  'website-design',
  'website-digital-marketing',
  'website-graphic-design',
  'website-ios-fundamentals',
  'website-java',
  'website-node-js',
  'website-omd-cloud-data-engineering',
  'website-pmi-template',
  'website-postponed',
  'website-product-management-introduction',
  'website-project-management',
  'website-react-native',
  'website-rearmenia-template',
  'website-seo-full-course',
  'website-close-app',
];

export const examAutomationTemplates: {
  [key in StudentStatusEnum.EXAM_PASSED | StudentStatusEnum.EXAM_FAILED]: string;
} = {
  [StudentStatusEnum.EXAM_PASSED]: 'exam-passed-template',
  [StudentStatusEnum.EXAM_FAILED]: 'exam-failed-template',
};
