import { Badge, Card, Modal, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { GlobalNotificationOptions } from '../../../constants/notification.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useNotification } from '../../../contexts/notification.context';
import { withCatch } from '../../../helpers/error.helpers';
import { RoutesConstants } from '../../../helpers/routes.helpers';
import { ChangeOptionsType, useFilter } from '../../../hooks/useFilter.hooks';
import useOutsideClick from '../../../hooks/useOutsideClick.hooks';
import {
  bulkUpdateNotificationStatus,
  filterNotifications,
  updateNotification,
} from '../../../services/notification.service';
import { filterReminders, updateReminder } from '../../../services/reminder.service';
import { ButtonTypeEnum } from '../../../types/button.types';
import {
  GlobalNotificationTypes,
  INotification,
  INotificationNormalized,
  INotificationsResponse,
  NotificationStatus,
  RelatedResource,
} from '../../../types/notification.types';
import { IReminder, IReminderNormalized, IRemindersResponse, ReminderStatus } from '../../../types/reminder.types';
import { Button, Flex } from '../../general';
import { Close } from '../../general/Icons/Icons';
import CustomDateAndTimePicker from '../CustomTimePicker/CustomTimePicker';
import styles from './ReminderModal.module.css';

const NotificationModalHeader = ({ notificationsCount }: { notificationsCount: number }) => {
  const { toggleNotification } = useNotification();

  const handleClose = () => {
    toggleNotification();
  };

  return (
    <div>
      <Flex className={styles.reminderHeader} direction="row" justifyContent="space-between">
        <Text>Notifications ({notificationsCount})</Text>
        <Close style={{ cursor: 'pointer' }} onClick={handleClose} />
      </Flex>
    </div>
  );
};

const ReminderItem = ({
  reminderData,
  changeOptions,
}: {
  reminderData: IReminder;
  changeOptions: ChangeOptionsType;
}) => {
  const [isPostponeOpen, setIsPostponeOpen] = useState(false);
  const { changeNotificationOutsideClick } = useNotification();

  const reminderDateObject = new Date(reminderData.remindingDate);
  const reminderDate = format(reminderDateObject, 'dd.MM.yyyy');
  const reminderTime = format(reminderDateObject, 'kk:mm');

  const cache = useQueryClient();

  const editReminderMutation = useMutation(updateReminder, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.REMINDER_COUNT);
      cache.invalidateQueries(UseQueryTypes.FILTER_REMINDER);
    },
  });

  const handleReminderStatus = (status: ReminderStatus) => async () => {
    const updatedReminderData = {
      ...reminderData,
      ownerUuid: undefined,
      status,
    };

    const reminderMutationFunc = () => editReminderMutation.mutateAsync(updatedReminderData);

    await withCatch(reminderMutationFunc);
  };

  const handlePostpone = () => {
    setIsPostponeOpen(true);
  };

  const handleClosePostponeModal = () => {
    setIsPostponeOpen(false);
  };

  useEffect(() => {
    if (isPostponeOpen) {
      changeNotificationOutsideClick(false);
    } else {
      changeNotificationOutsideClick(true);
    }
  }, [isPostponeOpen]);

  const toNavigate = async () => {
    changeOptions(
      { filterOptions: { reminderIds: [reminderData.id] } },
      { isNewOption: true, path: RoutesConstants.reminder() },
    );
  };

  return (
    <>
      <PostponeModal
        reminderData={reminderData}
        handleCloseModal={handleClosePostponeModal}
        isModalOpen={isPostponeOpen}
      />

      <div style={{ margin: '16px 0' }}>
        <Badge.Ribbon text="Reminder" color="magenta">
          <Card title={reminderData.title} size="small">
            <div className={styles.reminderItem}>
              <div
                style={{
                  padding: '8px 16px',
                  flexDirection: 'column',
                  display: 'flex',
                  gap: '10px 0',
                  cursor: 'pointer',
                }}
                onClick={toNavigate}
              >
                <Text style={{ borderBottom: '1px solid #f0f0f0' }}>{reminderData.description}</Text>

                <Text style={{ textAlign: 'right' }}>
                  {reminderDate} at {reminderTime}
                </Text>
              </div>
              <div className={styles.reminderActions}>
                <Flex style={{ gap: '0 16px' }}>
                  <Button onClick={handlePostpone}>Postpone</Button>
                  <Button variant={ButtonTypeEnum.primary} onClick={handleReminderStatus(ReminderStatus.RESOLVED)}>
                    Resolve
                  </Button>
                </Flex>
              </div>
            </div>
          </Card>
        </Badge.Ribbon>
      </div>
    </>
  );
};

const NotificationItem = ({
  notificationData,
  changeOptions,
}: {
  notificationData: INotification;
  changeOptions: ChangeOptionsType;
}) => {
  const notificationDateObject = new Date(notificationData.created_at);
  const notificationDate = format(notificationDateObject, 'dd.MM.yyyy');
  const notificationTime = format(notificationDateObject, 'kk:mm');
  const isNotificationRead = notificationData.status === NotificationStatus.READ;
  const isClickable: boolean =
    notificationData.relatedResource !== RelatedResource.NOTIFICATION && !!notificationData.task?.id;

  const readButtonStyles = isNotificationRead ? { background: 'green', border: '1px solid green' } : {};
  const itemStyles = isNotificationRead ? styles.activeReminderItem : styles.reminderItem;
  const cache = useQueryClient();

  const oppositeNotificationStatus = isNotificationRead ? NotificationStatus.UNREAD : NotificationStatus.READ;

  const editNotificationMutation = useMutation(updateNotification, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.NOTIFICATION_COUNT);
      cache.invalidateQueries(UseQueryTypes.FILTER_NOTIFICATION);
    },
  });

  const handleNotificationStatus = async () => {
    const updatedNotificationData = {
      id: notificationData.id,
      status: oppositeNotificationStatus,
    };

    const notificationMutationFunc = () => editNotificationMutation.mutateAsync(updatedNotificationData);

    await withCatch(notificationMutationFunc);
  };

  const toNavigate = () => {
    if (isClickable) {
      changeOptions(
        { filterOptions: { taskIds: [notificationData?.task?.id] } },
        { isNewOption: true, path: RoutesConstants.tasks() },
      );
    }
  };

  return (
    <div style={{ margin: '16px 0' }}>
      <Badge.Ribbon
        text="Notification"
        color={notificationData.status === NotificationStatus.READ ? `green` : `orange`}
      >
        <Card title={notificationData.title ?? ''} size="small">
          <div className={itemStyles}>
            <div
              style={{
                padding: '8px 16px',
                flexDirection: 'column',
                display: 'flex',
                gap: '10px 0',
                cursor: isClickable ? 'pointer' : 'unset',
              }}
              onClick={toNavigate}
            >
              <Text style={{ borderBottom: '1px solid #f0f0f0' }}>{notificationData.description}</Text>
              <Text style={{ textAlign: 'right' }}>
                {notificationDate} at {notificationTime}
              </Text>
            </div>
            <div className={styles.reminderActions}>
              <Flex style={{ gap: '0 16px' }}>
                <Button style={readButtonStyles} variant={ButtonTypeEnum.primary} onClick={handleNotificationStatus}>
                  Mark {notificationData.status === NotificationStatus.READ ? 'Unread' : 'Read'}
                </Button>
              </Flex>
            </div>
          </div>
        </Card>
      </Badge.Ribbon>
    </div>
  );
};

const GlobalNotificationsItem = ({
  notificationData,
  changeOptions,
}: {
  notificationData: INotificationNormalized | IReminderNormalized;
  changeOptions: ChangeOptionsType;
}) => {
  switch (notificationData.notificationType) {
    case GlobalNotificationTypes.REMINDER:
      return <ReminderItem reminderData={notificationData.data as IReminder} changeOptions={changeOptions} />;
    case GlobalNotificationTypes.NOTIFICATION:
      return (
        <NotificationItem notificationData={notificationData.data as INotification} changeOptions={changeOptions} />
      );
    default:
      return <div>Provide Notification item type</div>;
  }
};

export const NotificationModal = ({
  globalNotifications,
  isLoading,
  onClose,
  hasOutsideClick = true,
}: {
  globalNotifications: (IReminderNormalized | INotificationNormalized)[];
  isLoading: boolean;
  onClose: () => void;
  hasOutsideClick: boolean;
}) => {
  const modalRef = useRef(null);
  const client = useQueryClient();
  const { changeOptions } = useFilter({});

  const filteredRemindersFromCache: IRemindersResponse | undefined = client.getQueryData(UseQueryTypes.FILTER_REMINDER);

  const filteredNotificationsFromCache: INotificationsResponse | undefined = client.getQueryData(
    UseQueryTypes.FILTER_NOTIFICATION,
  );

  const remindersFilterMutation = useMutation(filterReminders);
  const notificationFilterMutation = useMutation(filterNotifications);

  const reminderTotalPages = filteredRemindersFromCache?.totalPages ?? 0;
  const reminderCurrentPage = filteredRemindersFromCache?.currentPage ?? 0;
  const reminderNextPage = reminderCurrentPage + 1;
  const hasReminderNextPage = reminderNextPage < reminderTotalPages;

  const notificationTotalPages = filteredNotificationsFromCache?.totalPages ?? 0;
  const notificationCurrentPage = filteredNotificationsFromCache?.currentPage ?? 0;
  const notificationNextPage = notificationCurrentPage + 1;
  const hasNotificationNextPage = notificationNextPage < notificationTotalPages;

  const existNextPage = hasReminderNextPage || hasNotificationNextPage;

  const handleSeeMore = async () => {
    const reminderNewData = await remindersFilterMutation.mutateAsync({
      filters: {
        ...GlobalNotificationOptions.reminderFilterOptions,
        dateTo: new Date().toISOString(),
      },
      sorters: {
        ...GlobalNotificationOptions.sorterOptions,
        pageNumber: reminderNextPage,
      },
    });

    const notificationNewData = await notificationFilterMutation.mutateAsync({
      filters: {},
      sorters: {
        ...GlobalNotificationOptions.notificationSorterOptions,
        pageNumber: notificationNextPage,
      },
    });

    client.setQueryData(UseQueryTypes.FILTER_NOTIFICATION, (prev: any) => {
      return {
        ...prev,
        ...notificationNewData,
        notifications: [...prev.notifications, ...notificationNewData.notifications],
      };
    });

    client.setQueryData(UseQueryTypes.FILTER_REMINDER, (prev: any) => {
      return {
        ...prev,
        ...reminderNewData,
        reminders: [...prev.reminders, ...reminderNewData.reminders],
      };
    });
  };

  useOutsideClick(modalRef, onClose, hasOutsideClick);

  const bulkUpdateMutation = useMutation(bulkUpdateNotificationStatus);

  const handleMarkAllRead = async () => {
    const updateQuery: Partial<INotification>[] =
      filteredNotificationsFromCache?.notifications.map(({ id }) => ({
        id,
        status: NotificationStatus.READ,
      })) || [];

    await withCatch(() => bulkUpdateMutation.mutateAsync(updateQuery), {
      onSuccess: async () => {
        await client.invalidateQueries(UseQueryTypes.FILTER_NOTIFICATION);
      },
    });
  };

  return (
    <div ref={modalRef} className={styles.reminderModalWrapper}>
      <NotificationModalHeader notificationsCount={globalNotifications.length} />
      <Spin spinning={isLoading}>
        <Flex
          direction="column"
          alignItems="flex-end"
          style={{ margin: '10px 0', padding: '0 10px', overflowY: 'auto' }}
        >
          <Button
            variant={ButtonTypeEnum.link}
            onClick={handleMarkAllRead}
            disabled={!filteredNotificationsFromCache?.notifications.length}
          >
            Mark Notifications Read
          </Button>
        </Flex>
        <div
          style={{ margin: '16px 0', padding: '0 16px', height: '40vh', overflowY: 'auto' }}
        >

          <div>
            {globalNotifications.map((globalNotification) => (
              <GlobalNotificationsItem
                notificationData={globalNotification}
                key={globalNotification.data.id}
                changeOptions={changeOptions}
              />
            ))}
          </div>

          {!globalNotifications.length && (
            <div style={{ textAlign: 'center' }}>
              <Text>Empty</Text>
            </div>
          )}

          {existNextPage && (
            <div style={{ margin: '0 16px' }}>
              <Button onClick={handleSeeMore}>See More</Button>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

function PostponeModal({
  isModalOpen,
  reminderData,
  handleCloseModal,
}: {
  isModalOpen: boolean;
  reminderData: IReminder;
  handleCloseModal: () => void;
}) {
  const [date, setDate] = useState(reminderData.remindingDate);
  const cache = useQueryClient();

  const editReminderMutation = useMutation(updateReminder, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.REMINDER);
      cache.invalidateQueries(UseQueryTypes.FILTER_REMINDER);
      cache.invalidateQueries(UseQueryTypes.REMINDER_COUNT);
    },
  });

  const handleOk = async () => {
    const updatedData = {
      ...reminderData,
      remindingDate: date,
      ownerUuid: undefined,
    };

    const mutationFunc = () => editReminderMutation.mutateAsync(updatedData);

    await withCatch(mutationFunc, {
      onSuccess: () => handleCloseModal(),
    });
  };

  return (
    <Modal
      visible={isModalOpen}
      onOk={handleOk}
      okText={'Change Date'}
      onCancel={handleCloseModal}
      centered
      width={600}
    >
      <CustomDateAndTimePicker
        getDate={(date) => {
          setDate(date);
        }}
      />
    </Modal>
  );
}
