import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { pickChangedValues } from "../../helpers/formValueSerilizer";
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  getInstructorById,
  updateInstructorById,
} from '../../services/instructors.service';
import { IAppForm } from '../../types/form.types';
import { ICreateInstructor } from '../../types/instructor.types';
import { TransformInstructorForm } from './shared/transformInstructor.form';

export const EditInstructorForm: IAppForm = () => {
  const { id: editId } = useEntityId();
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();

  const instructorQuery = useQuery([UseQueryTypes.INSTRUCTOR, editId], () =>
    getInstructorById(editId),
  );

  const instructorUpdateMutation = useMutation(updateInstructorById, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.INSTRUCTOR),
  });

  const handleUpdateInstructor = async (
    instructor: Partial<ICreateInstructor>,
  ) => {
    const data = pickChangedValues(initialValues, instructor);

    const mutationFunc = () =>
      instructorUpdateMutation.mutateAsync({
        instructor: data,
        id: editId,
      });

    withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const initialValues = useMemo<Partial<ICreateInstructor>>(() => {
    const instructor = instructorQuery.data;
    if (!instructor) return {};

    const { id, avatar, comments, ...rest } = instructor;

    return {
      ...rest,
      tracks: instructor.tracks.map(({ id }) => id),
    };
  }, [instructorQuery.data]);

  const isScreenLoading =
    instructorQuery.isLoading || instructorUpdateMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <TransformInstructorForm
        isEditForm
        initialValues={initialValues}
        onSubmit={handleUpdateInstructor}
      />
    </Spin>
  );
};
