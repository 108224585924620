import { InstructorRoutesConstants } from '../../../helpers/routes.helpers';
import { IDashboardLinks } from '../../shared/types/path.types';

export const InstructorDashBoardLinks: IDashboardLinks = {
  Dashboard: {
    id: 0,
    name: 'Dashboard',
    path: InstructorRoutesConstants.dashboard(),
  },
  ongoingGroups: {
    id: 1,
    path: InstructorRoutesConstants.ongoingGroups(),
    name: 'Ongoing Groups',
  },
  planningGroups: {
    id: 2,
    path: InstructorRoutesConstants.planningGroups(),
    name: 'Planning Groups',
  },
  terminatedGroups: {
    id: 3,
    path: InstructorRoutesConstants.terminatedGroups(),
    name: 'Terminated Groups',
  },
};
