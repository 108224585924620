import { Spin } from 'antd';

import { Flex } from '..';
import DrawerContainer, { Anchor } from '../DrawerContainer/DrawerContainer';
import { Close } from '../Icons/Icons';
import styles from './Drawer.module.css';

interface IDrawerProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
  title: string;
  loading?: boolean | undefined;
  hasOutSideClick: boolean;
}

export default function Drawer({
  open,
  onClose,
  children,
  title,
  loading = false,
  hasOutSideClick = true,
}: IDrawerProps) {
  return (
    <DrawerContainer
      hasOutSideClick={hasOutSideClick}
      onClose={onClose}
      open={open}
      anchor={Anchor.RIGHT}
    >
      <Spin spinning={loading}>
        <div className={styles.header}>
          <h2 style={{ margin: '0 10px' }}>{title}</h2>
          <Close className={styles?.icon} onClick={onClose} />
        </div>
        <Flex className={styles.wrapper} direction="column">
          {children}
        </Flex>
      </Spin>
    </DrawerContainer>
  );
}
