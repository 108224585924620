import LoginBg from '../assets/images/loginBg.png';
import LoginBg2 from '../assets/images/loginBg2.png';
import { DynamicWelcome } from '../types/auth.types';
import { Branch } from "../types/general.types";

export const COMMA_SEPARATOR = ', ';
export const NEW_LINE = '\n';
export const IS_NOT_PROD = process.env.NODE_ENV !== 'production';


export const BranchOptions: {  [key: string]: { label: string, value: string } } = {
    [Branch.YEREVAN]: {
        label: 'Yerevan',
        value: Branch.YEREVAN,
    },
    [Branch.VANADZOR]: {
        label: 'Vanadzor',
        value: Branch.VANADZOR,
    },

} as const;

export const dynamicWelcome: DynamicWelcome = {
  USER: {
    img: LoginBg,
    welcome: 'Welcome to Backoffice!',
    title: 'People are important, the rest can be automated'
  },
  STUDENT: {
    img: LoginBg2,
    welcome: 'Welcome to your cabinet',
    title: 'We believe in education’s power and importance to the individual’s life, career, and society.'
  },
  INSTRUCTOR: {
    img: LoginBg2,
    welcome: 'Welcome to your cabinet',
    title: 'Let’s make tech education greater together!'
  }
}
