import { Citizenship } from '../types/studentAgreement.types';

export const CitizenshipOptions: { [key: string]: { label: string; value: string } } = {
  [Citizenship.ARMENIA]: {
    label: 'Armenia',
    value: Citizenship.ARMENIA,
  },
  [Citizenship.RUSSIA]: {
    label: 'Russian Federation',
    value: Citizenship.RUSSIA,
  },
  [Citizenship.USA]: {
    label: 'United States',
    value: Citizenship.USA,
  },
  [Citizenship.GERMANY]: {
    label: 'Germany',
    value: Citizenship.GERMANY,
  },
  [Citizenship.AUSTRIA]: {
    label: 'Austria',
    value: Citizenship.AUSTRIA,
  },
  [Citizenship.GREECE]: {
    label: 'Greece',
    value: Citizenship.GREECE,
  },
  [Citizenship.NETHERLANDS]: {
    label: 'The Netherlands',
    value: Citizenship.NETHERLANDS,
  },
  [Citizenship.GEORGIA]: {
    label: 'Georgia',
    value: Citizenship.GEORGIA,
  },
  [Citizenship.IRAN]: {
    label: 'Iran',
    value: Citizenship.IRAN,
  },
  [Citizenship.LEBANON]: {
    label: 'Lebanon',
    value: Citizenship.LEBANON,
  },
  [Citizenship.SYRIA]: {
    label: 'Syria',
    value: Citizenship.SYRIA,
  },
} as const;
