import {Button} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";

import styles from "../../ForgetPassword/ForrgetPassword.module.css";


const Expired = () => {
  const navigate = useNavigate();

  return (
    <>
      <h2 className={styles.title}>This link has expired. Please fill in your email again.</h2>
      <Button type="link" style={{textDecoration: 'underline'}} block onClick={() => navigate('/forget-password')}>
        Try again
      </Button>
    </>
  );
};

export default Expired;
