import { Spin } from "antd";
import { isEmpty } from "lodash";
import { UseQueryResult } from "react-query";

import { StudentFeedback, StudentGivenFeedback } from "../../types/studentFeedback.types";
import EmptyDataComponent from "../EmptyData/EmptyDataComponent";
import FeedbackItem from "./FeedbackItem";

function FeedbacksRenderer({ feedbacksData }: { feedbacksData: UseQueryResult<StudentFeedback | StudentGivenFeedback> }) {

  const studentFeedbacks = feedbacksData.data && feedbacksData.data.feedbacks

  
  return (
    <Spin spinning={feedbacksData.isLoading}>
      {!isEmpty(studentFeedbacks) ? studentFeedbacks?.map(feedback => (
          <FeedbackItem key={feedback.content} feedback={feedback}/>
        )
      ) : <EmptyDataComponent dataTitle='feedbacks'/>}
    </Spin>
  );
}

export default FeedbacksRenderer;
