import { Col, Row, Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, Flex, SingleSelectFormik } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { BranchOptions } from "../../constants/constants";
import { LevelOptions } from '../../constants/courseInfo.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { getAllTracks } from '../../services/track.service';
import { getAllUser } from '../../services/user.service';
import { CourseFilterOptions } from '../../types/course.types';
import { IAppForm } from '../../types/form.types';

const defaultFilterOptions: Partial<CourseFilterOptions> = {};

export const FilterCourseForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { filterOptions, changeOptions, resetOptions } =
    useFilter(defaultFilterOptions);

  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);
  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);

  const handleFilterSubmit = (filterOptions: Partial<CourseFilterOptions>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  const tracksContext = useMemo(() => {
    return generateContext(tracksQuery.data || [], (track) => ({
      label: track.name,
      value: track.id,
    }));
  }, [tracksQuery.data]);

  const usersContext = useMemo(() => {
    return generateContext(usersQuery.data || [], (user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  }, [usersQuery.data]);

  const initialValues = filterOptions;

  const isScreenLoading = tracksQuery.isLoading || usersQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        onSubmit={handleFilterSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form
              name="filters"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field
                name="defaultProjectCoordinatorIds"
                label="Project Coordinator"
                component={MultiselectFormik}
                options={usersContext}
              />
              <Row gutter={8}>
                <Col span={24}>
                  <Field
                    label="Tracks"
                    name="tracks"
                    component={MultiselectFormik}
                    options={tracksContext}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label="Levels"
                    name="levels"
                    component={MultiselectFormik}
                    options={LevelOptions}
                  />
                </Col>

                <Col span={24}>
                  <Field
                    label="Branches"
                    name="branches"
                    component={SingleSelectFormik}
                    options={BranchOptions}
                  />
                </Col>
              </Row>
              <Flex
                style={{ marginTop: 'auto' }}
                justifyContent="space-between"
              >
                <Button
                  htmlType="button"
                  style={{ minWidth: 160 }}
                  onClick={() => {
                    resetOptions();
                    props.resetForm();
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                >
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
