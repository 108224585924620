import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { getUserById, updateUser } from '../../services/user.service';
import { IAppForm } from '../../types/form.types';
import { ITeam } from '../../types/team.types';
import { IUser } from '../../types/user.types';
import { TransformUserForm } from './shared/transformUser.form';

export const EditUserForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: userId } = useEntityId();
  const cache = useQueryClient();

  const updateUserMutation = useMutation(updateUser);

  const userQuery = useQuery([UseQueryTypes.USER_BY_ID, userId], () =>
    getUserById(userId),
  );

  const initialValues = useMemo<Partial<IUser>>(() => {
    const user = userQuery.data;
    if (!user) return {};
    let init = {...user};
    init.teams = user.teams?.map((team: ITeam) => team.id)
    return {
      ...init
    };
  }, [userQuery.data]);

  const handleSubmit = async (updatedUser: IUser) => {
    const { id, uuid, avatar, colorHex, ...rest } = updatedUser;

    const mutationFunc = () =>
      updateUserMutation.mutateAsync({
        user: { ...rest },
        id
      });

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.USERS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={updateUserMutation.isLoading}>
      <TransformUserForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isEditForm
      />
    </Spin>
  );
};
