import { Col, Row, Table, Typography } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button, Flex, Widget } from '../../../../../components/general';
import { WidgetTypeEnum } from '../../../../../components/general/Widget/Widget';
import { SPREADSHEET_URL } from '../../../../../constants/url.constants';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { RouteParam } from '../../../../../helpers/routes.helpers';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns';
import { useEntityId } from '../../../../../hooks/useFilter.hooks';
import { defaultScheduleColumn } from '../../../../../schemas/schedules/schedules.schema';
import { getInterviewSchedule } from '../../../../../services/groups.service';
import { getGroupPayments } from '../../../../../services/payment.service';
import { CourseCategoryEnum, ICourse } from '../../../../../types/course.types';
import { ScheduleModel } from '../../../../../types/general.types';
import { Widgets } from '../constants';

const { Title } = Typography;

export const Schedule = ({
  schedule,
  course,
  isHiddenCreatingButtons = false,
  widgets,
}: {
  schedule: ScheduleModel[];
  course: ICourse;
  isHiddenCreatingButtons?: boolean;
  widgets?: (id: RouteParam) => { id: number; title: string; bgColor: string; link: string }[];
}) => {
  const { id } = useParams<{ id: string }>();
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();
  const scheduleColumns = useTableColumns(defaultScheduleColumn);

  const { data: paymentsByGroup } = useQuery(UseQueryTypes.PAYMENT, () => getGroupPayments(Number(id)));
  const { data: interviewSchedule } = useQuery(UseQueryTypes.GROUP_INTERVIEW, () => getInterviewSchedule(Number(id)));

  const isShowCreatePaymentButton =
    (course.courseCategory === CourseCategoryEnum.BOOTCAMP ? !paymentsByGroup?.length : true) &&
    !isHiddenCreatingButtons;

  const handleOpenCreateExamForm = () => {
    changeId(Number(id));
    openDrawer(AppFormsEnum.CreateExamForm, 'Create Exam');
  };

  const handleOpenCreatePaymentForm = () => {
    changeId(Number(id));
    openDrawer(AppFormsEnum.CreatePaymentForm, 'Create Payment');
  };

  const handleOpenCreateInterviewAgenda = () => {
    changeId(Number(id));
    openDrawer(AppFormsEnum.CreateInterviewScheduleForm, 'Create Interview Agenda');
  };

  return (
    <Col offset={1} span={11}>
      <Title level={4}>Schedule</Title>
      <Table columns={scheduleColumns.columns} pagination={false} dataSource={schedule} rowKey={(r) => r.id} />

      <>
        <Row style={{ marginBottom: 20 }} justify="space-between">
          <Col span={24}>
            <Flex style={{ marginTop: 10 }} flexWrap="wrap">
              {(widgets ?? Widgets)(id).map((item) => (
                <Widget
                  key={item.id}
                  title={item.title}
                  bgColor={item.bgColor}
                  variant={WidgetTypeEnum.secondary}
                  link={item.link}
                  style={{
                    width: '150px',
                    height: '45px',
                    margin: '5px',
                    marginRight: 20,
                  }}
                />
              ))}
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={10} style={{ marginRight: 30 }}>
            <Button variant="primary" style={{ marginBottom: '5px' }} onClick={handleOpenCreateExamForm}>
              Create Exam
            </Button>
            {isShowCreatePaymentButton && (
              <Button variant="primary" onClick={handleOpenCreatePaymentForm}>
                Create Payment
              </Button>
            )}
          </Col>
          <Col span={12}>
            {!interviewSchedule?.sheetId ? (
              !isHiddenCreatingButtons && (
                <Button variant="warning" style={{ marginBottom: '5px' }} onClick={handleOpenCreateInterviewAgenda}>
                  Create Interview Agenda
                </Button>
              )
            ) : (
              <a href={`${SPREADSHEET_URL}/${interviewSchedule.sheetId}`} target="_blank" rel="noreferrer">
                <Button variant="danger" style={{ marginBottom: '5px' }}>
                  Open Interview Agenda
                </Button>
              </a>
            )}
          </Col>
        </Row>
      </>
    </Col>
  );
};
