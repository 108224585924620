import { Spin } from "antd";
import { ErrorMessage, Form, Formik, } from "formik";
import { useMutation, useQueryClient } from "react-query";

import { Flex } from "../../../../components/general";
import { Signature } from "../../../../components/general/Signiture/Signature";
import { useAuth } from "../../../../contexts/useAuth.context";
import { removeBase64Prefix } from "../../../../helpers/base64.helper";
import { withCatch } from "../../../../helpers/error.helpers";
import { generateStudentAgreement } from "../../../../services/student.service";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  changeAgreementData,
  selectStudentAgreement,
  StudentAgreementInitialValues
} from "../../../../store/features/student/studentAgreement/studentAgreementSlice";
import { SubmitButton } from "../../../shared/components/Buttons/SubmitButton";
import { StudentQueryConstants } from "../../constants/studentQuery.constants";
import { AgreementSignatureSchema } from "../schemas/agreementSignature.schema";
import styles from './StudentSignatureForm.module.css'

const StudentSignatureForm = ({ submitEventHandler }: { submitEventHandler: () => void }) => {
  const { user } = useAuth()
  const { applicantPersonalInfoRequestModel } = useAppSelector(selectStudentAgreement);
  const dispatch = useAppDispatch();
  const cache = useQueryClient()
  const generateStudentAgreementMutation = useMutation(generateStudentAgreement)

  const handleSubmit = async ({ signature }: { signature: string }) => {
    const agreementRequest = {
      userId: user?.id as number,
      applicantPersonalInfoRequestModel: {
        ...applicantPersonalInfoRequestModel,
        // @ts-ignore
        paymentMethod: user?.paymentMethod
      },
      applicantSignature: signature
    }

    await withCatch(() => generateStudentAgreementMutation.mutateAsync(agreementRequest), {
      onSuccess: async () => {
        await cache.invalidateQueries(StudentQueryConstants.STUDENT_AGREEMENTS)
        dispatch(changeAgreementData(StudentAgreementInitialValues))
        submitEventHandler()
      }
    })
  }
  return (
    <Spin spinning={generateStudentAgreementMutation.isLoading}>
      <div style={{ width: '80%', margin: 'auto' }}>
        <div className={styles.description}>
          <p style={{ textAlign: 'center' }}>
            If you are signing with <strong> Safari Browser on iPhone </strong>, you need to follow these steps to
            download the agreement:
            <strong> Settings {'=>'} Safari {'=>'} Block Pop-ups. </strong>
          </p>
        </div>

        <Formik<{ signature: string }>
          enableReinitialize
          onSubmit={handleSubmit}
          validateOnChange
          validationSchema={AgreementSignatureSchema}
          initialValues={{ signature: '' }}
        >
          {({ setFieldValue, dirty, isValid }) => (
            <Form style={{ width: '100%' }}>
              <Signature
                onReset={() => {
                  setFieldValue('signature', '')
                }}
                onSave={(signatureBase64) =>
                  setFieldValue('signature', removeBase64Prefix(signatureBase64))
                }
              />
              <ErrorMessage name="signature" component="span" className="error"/>

              <Flex alignItems='center' direction='column' justifyContent='center' style={{ marginTop: '66px' }}>
                <SubmitButton text={'Confirm'} disabled={!(dirty && isValid)}/>
              </Flex>
            </Form>
          )}
        </Formik>
      </div>
    </Spin>
  )
}

export default StudentSignatureForm
