import { Currency } from './currency.types';

export enum FeeClientTypeEnum {
  MONTHLY = 'MONTHLY',
  PER_COURSE = 'PER_COURSE',
}

export type FeeClientType = keyof typeof FeeClientTypeEnum;

export interface ITuitionFee {
  amount: number;
  currency: Currency;
  feeType: FeeClientType;
  prePaymentPercent: number | null;
}
