import { SendEmailModal } from '../../components/display/SendEmailModal/SendEmailModal';
import type { ICreateStudent, IStudentBrief } from '../../types/applicant.types';
import type { ColumnSchema } from '../../types/column.types';
import {
  ActionsColumn,
  BulkStudentCourseColumn,
  BulkStudentEmailColumn,
  BulkStudentGroupColumn,
  BulkStudentNameColumn,
  BulkStudentPhoneColumn,
  BulkStudentRegistrationSourceColumn,
  BulkStudentStatusColumn,
  CommentApplicantColumn,
  DateApplicantColumn,
  DeleteApplicantColumn,
  DetailApplicantColumn,
  EditApplicantColumn,
  EnrolledDateApplicantColumn,
  GroupApplicantColumn,
  NameApplicantColumn,
  PhoneApplicantColumn,
  RegistrationSourceApplicantColumn,
  SendEmailColumn,
  StartDateApplicantColumn,
  StatusApplicantColumn,
} from './applicant.renderer';

export type IBulkCreateStudent = Partial<ICreateStudent>;

export const bulkCreateStudentsColums: ColumnSchema<IBulkCreateStudent> = {
  uuid: 'bulkCreateStudentsColumns',
  schema: {
    name: {
      title: 'Name',
      width: 200,
      render: BulkStudentNameColumn,
    },
    email: {
      title: 'Email',
      width: 120,
      sorter: () => -1,
      render: BulkStudentEmailColumn,
    },

    phoneNumber: {
      title: 'Phone',
      width: 150,
      render: BulkStudentPhoneColumn,
    },
    course: {
      title: 'Course',
      width: 120,
      sorter: () => -1,
      render: BulkStudentCourseColumn,
    },
    group: {
      title: 'Group',
      width: 250,
      render: BulkStudentGroupColumn,
    },
    status: {
      title: 'Status',
      width: 120,
      sorter: () => -1,
      render: BulkStudentStatusColumn,
    },

    registrationSource: {
      title: 'Registration Source',
      width: 150,
      render: BulkStudentRegistrationSourceColumn,
    },
  },
};
export const defaultStudentsColumns: ColumnSchema<IStudentBrief> = {
  uuid: 'defaultStudentColumns',
  schema: {
    date: {
      title: 'Application Date',
      dataIndex: 'created_at',
      sorter: () => -1,
      width: 220,
      render: DateApplicantColumn,
    },
    startDate: {
      title: 'Group Start Date',
      dataIndex: ['group.startDate', 'relationalSort'],
      sorter: () => -1,
      width: 220,
      render: StartDateApplicantColumn,
    },
    enrolledDate: {
      title: 'Enrolled Date',
      dataIndex: ['applicantActions.created_at', 'relationalSort'],
      sorter: () => -1,
      width: 220,
      render: EnrolledDateApplicantColumn,
    },
    name: {
      title: 'Name',
      width: 220,
      render: NameApplicantColumn,
    },
    phoneNumber: {
      title: 'Phone',
      width: 100,
      render: PhoneApplicantColumn,
    },
    nameHy: {
      title: 'Name (hy)',
      width: 150,
    },
    nameEn: {
      title: 'Name (en)',
      width: 150,
    },
    group: {
      title: 'Group',
      dataIndex: ['group.id', 'relationalSort'],
      sorter: () => -1,
      width: 250,
      render: GroupApplicantColumn,
    },
    comment: {
      title: 'Comment',
      width: 50,
      render: CommentApplicantColumn,
    },
    status: {
      title: 'Status',
      width: 200,
      sorter: () => -1,
      render: StatusApplicantColumn,
    },
    registrationSource: {
      title: 'Registration Source',
      dataIndex: 'registrationSource',
      sorter: () => -1,
      width: 150,
      render: RegistrationSourceApplicantColumn,
    },
    actions: {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: ActionsColumn,
    },
    details: {
      title: 'Details',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: (_, applicant) => DetailApplicantColumn(_, applicant),
    },
  },
  hiddenColumns: ['nameHy', 'nameEn'],
};

export const defaultApplicantColumns: ColumnSchema<IStudentBrief> = {
  uuid: 'defaultApplicantColumns',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: () => -1,
      width: 250,
      render: DateApplicantColumn,
    },
    name: {
      title: 'Name',
      width: 200,
      render: NameApplicantColumn,
    },
    phoneNumber: {
      title: 'Phone',
      width: 200,
      render: PhoneApplicantColumn,
    },
    nameHy: {
      title: 'Name (hy)',
      width: 150,
    },
    nameEn: {
      title: 'Name (en)',
      width: 150,
    },
    group: {
      title: 'Group',
      width: 250,
      render: GroupApplicantColumn,
    },
    comment: {
      title: 'Comment',
      width: 50,
      render: CommentApplicantColumn,
    },
    status: {
      title: 'Status',
      width: 200,
      sorter: () => -1,
      render: StatusApplicantColumn,
    },
    registrationSource: {
      title: 'Registration Source',
      width: 150,
      render: RegistrationSourceApplicantColumn,
    },
    edit: {
      title: 'Edit',
      width: 50,
      render: EditApplicantColumn,
    },
    delete: {
      dataIndex: 'delete',
      title: 'Delete',
      width: 30,
      render: DeleteApplicantColumn,
    },
    sendEmail: {
      dataIndex: 'sendEmail',
      title: 'Send Email',
      width: 120,
      render: (_, student) => SendEmailColumn(_, student, SendEmailModal, student.isRegistrationMailSent),
    },
    actions: {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: ActionsColumn,
    },
    details: {
      title: 'Details',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: (_, applicant) => DetailApplicantColumn(_, applicant),
    },
  },
  hiddenColumns: ['nameHy', 'nameEn'],
};

export const defaultAddApplicantTGroupColumns: ColumnSchema<IStudentBrief> = {
  uuid: 'defaultAddApplicantTGroupColumns',
  schema: {
    name: {
      title: 'Name',
      width: 200,
      render: NameApplicantColumn,
    },
    status: {
      title: 'Status',
      width: 200,
      render: StatusApplicantColumn,
    },
  },
};
