import { Input, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { Flex } from '..';
import styles from './TextArea.module.css';

const { Text } = Typography;

const highlightMatchingText = ({
  text,
  searchValue,
}: {
  text: string;
  searchValue?: string;
}) => {
  const matchRegex = RegExp(searchValue ?? '', 'ig');
  const matchingResultArray = Array.from(text.matchAll(matchRegex));

  const highlightedTextElement = text
    .split(matchRegex)
    .map((regularText, index, arr) => (
      <React.Fragment key={index}>
        <mark style={{ padding: 0, visibility: 'hidden' }}>{regularText}</mark>
        {index + 1 !== arr.length && (
          <mark className={styles.mark}>{matchingResultArray[index]}</mark>
        )}
      </React.Fragment>
    ));

  return searchValue
    ? {
        highlightedTextElement,
        matchCount: matchingResultArray.length,
      }
    : { highlightedTextElement: null, matchCount: 0 };
};

const TextAreaWithSearcher = ({
  style,
  field,
}: {
  style: { [key: string]: any };
  field: { [key: string]: any };
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { highlightedTextElement, matchCount } = highlightMatchingText({
    text: field.value,
    searchValue,
  });
  const highlightedContentRef = useRef<HTMLInputElement>(null);
  const searchTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleSearch = (e: any) => setSearchValue(e.target.value);

  const updateHighlightScrollPosition = (yPos: number) => {
    if (highlightedContentRef.current)
      highlightedContentRef.current.scrollTo(0, yPos);
  };

  const handleScroll = (e: any) => {
    updateHighlightScrollPosition(e.target.scrollTop);
  };

  const updateHighlightPositionInit = () => {
    if (searchTextAreaRef.current) {
      updateHighlightScrollPosition(searchTextAreaRef.current.scrollTop);
    }
  };

  useEffect(updateHighlightPositionInit, [searchValue]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div
          ref={highlightedContentRef}
          className={[styles.input, styles.highlightingContainer].join(' ')}
        >
          {highlightedTextElement}
        </div>
        <textarea
          style={style}
          className={styles.input}
          ref={searchTextAreaRef}
          onScroll={handleScroll}
          {...field}
        />
        <Flex
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ margin: '5px 0' }}
        >
          <span style={{ color: '#737373' }}>{matchCount} result found</span>
        </Flex>
      </div>

      <Input
        allowClear
        value={searchValue}
        onChange={handleSearch}
        placeholder="Search"
      />
    </>
  );
};

export enum TextAreaVariants {
  Regular = 'Regular',
  InputSearcher = 'InputSearcher',
}

interface ITextAreaProps {
  label: string;
  field: { [key: string]: any };
  form: object;
  style: object;
  required?: boolean;
  variant: TextAreaVariants;
  searchValue?: string;
  handleSearch?: () => void;
}

export default function TextArea({
  label,
  field,
  form,
  style,
  required = false,
  variant = TextAreaVariants.Regular,
  ...props
}: ITextAreaProps) {
  const labelElement = label && (
    <Text
      style={{
        marginBottom: '4px',
      }}
    >
      {label}
    </Text>
  );

  const requiredElement = required && (
    <Text
      style={{
        marginBottom: 4,
        marginLeft: 3,
        color: field.value?.length ? '#646464' : 'red',
      }}
    >
      *
    </Text>
  );

  const regularTextAreaElement = (
    <textarea style={style} className={styles.input} {...props} {...field} />
  );

  const renderTextArea = () => {
    switch (variant) {
      case TextAreaVariants.Regular:
        return regularTextAreaElement;

      case TextAreaVariants.InputSearcher:
        return <TextAreaWithSearcher style={style} field={field} />;
      default:
        return regularTextAreaElement;
    }
  };

  return (
    <label className={styles.wrapper}>
      <Flex className={styles.label}>
        {labelElement}
        {requiredElement}
      </Flex>
      {renderTextArea()}
    </label>
  );
}
