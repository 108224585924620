import classNames from "classnames";

import { Button } from "../../../../components/general";
import { Download, PaperClip } from "../../../../components/general/Icons/Icons";
import { useDrawer } from "../../../../contexts/drawer.context";
import { AppFormsEnum } from "../../../../forms";
import { downloadByLink, getExtensionFromString } from "../../../../helpers/download.helpers";
import { useEntityId } from "../../../../hooks/useFilter.hooks";
import useWindowSize from "../../../../hooks/useWindowSize.hooks";
import { DrawerTypes } from "../../../../types/drawer.types";
import { ScreenSizeBreakPoints } from "../../constants/mediaQuery.constants";
import { IStudentPayment } from "../../types/studentPayments.types";
import styles from './Payments.module.css';

export const PaymentActionsRenderer = ({ payment }: { payment: IStudentPayment }) => {
  const { invoice, isPaid } = payment;
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();
  const { width } = useWindowSize();
  const isMobileSize = width <= ScreenSizeBreakPoints.Mobile
  const isInvoiceEmpty = isPaid && !invoice


  const buttonStyles = classNames({
    [styles.paymentActionButton]: true,
    [styles.paymentDownloadButton]: invoice,
    [styles.paymentAttachButton]: !invoice,
  })


  const handlePaymentAction = (invoice: string) => () => {

    if (invoice) {
      const extension = getExtensionFromString(payment.invoice)
      downloadByLink({ link: payment.invoice, extension });
      return
    }

    changeId(payment.id);

    openDrawer(
      AppFormsEnum.CreateStudentPaymentForm,
      'Create Payment',
      DrawerTypes.Form,
    );
  }

  if (isInvoiceEmpty) {
    return null
  }
  
  return isMobileSize ?
    <Button
      onClick={handlePaymentAction(invoice)}
      className={buttonStyles}
    >
      {invoice ? 'Download File' : 'Attach file'}
    </Button> : <Button
      onClick={handlePaymentAction(invoice)}
      className={buttonStyles}
      icon={invoice ? <Download/> : <PaperClip/>}/>
}
