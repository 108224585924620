import { Col } from 'antd';
import React from 'react';
import { useMutation } from 'react-query';

import { Button } from '../../../../../../components/general';
import { exportSuccessHelper } from '../../../../../../helpers/applicants/exportSuccess.helper';
import { withCatch } from '../../../../../../helpers/error.helpers';
import { useRowSelectionAdapter } from '../../../../../../hooks/useRowSelection.adapter';
import { exportApplicants } from '../../../../../../services/applicant.service';
import { IStudentBrief } from '../../../../../../types/applicant.types';

export const ApplicantExport = () => {
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const exportApplicantMutation = useMutation(exportApplicants);

  const handleExportSelectedApplicants = async () => {
    const ids: number[] = rowSelectionAdapter.selectedRows.map((applicant: IStudentBrief) => applicant.id);
    const mutationFunc = () => {
      return exportApplicantMutation.mutateAsync(ids);
    };

    await withCatch(mutationFunc, {
      onSuccess: async (data) => {
        exportSuccessHelper(data);
      },
    });
  };

  return (
    <Col>
      <Button variant="primary" onClick={handleExportSelectedApplicants}>
        Export
      </Button>
    </Col>
  )
}
