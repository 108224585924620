import { Divider } from "antd";
import React from "react";

import { Tag } from "../../../components/general/Tag/Tag";
import { useAppSelector } from "../../../store";
import { selectApplicants } from "../../../store/features/applicants/applicantsSlice";

const SelectedApplicants = () => {
    const selectedApplicants = useAppSelector(selectApplicants);

    return (
        <>
            {!!selectedApplicants.length && <Divider/>}
            {selectedApplicants.map((applicant) => (
                <Tag style={{ marginTop: '10px' }} key={applicant.id}>
                    {applicant.email}
                </Tag>
            ))}
        </>
    )
}

export default SelectedApplicants;