import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { rePhoneNumber } from '../../../constants/phoneValidation.constants';

const { required } = messages;

export const TransformApplicantFormSchema = Yup.object().shape({
  name: Yup.string().required(required),
  email: Yup.string().email('Invalid email').required(required),
  phoneNumber: Yup.string()
    .required(required)
    .matches(rePhoneNumber, 'Phone number is not valid'),
  source: Yup.string().required(required),
});
