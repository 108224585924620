import axios from 'axios';

import { ApiSuccessMassage } from "../modules/shared/types/shared.types";
import { IApplicantAttachment } from '../types/applicant.types';
import {
  ApplicantDetailTypes,
  IApplicantDetailAbsentees,
  IApplicantDetailAction,
  IApplicantDetailApplication,
  IApplicantDetailFeedback,
  IApplicantDetailGeneral,
  IApplicantDetailPayment, IApplicantPromoCode,
} from '../types/applicantDetail.types';
import { IComment } from '../types/comment.types';
import { IConsultant } from '../types/consultation.types';
import { IExam } from '../types/exam.types';

export const getApplicantDetailByType = async function <T>(id: number, type: ApplicantDetailTypes) {
  const r = await axios.get(`/applicantDetail/${id}/${type}`);
  return r.data as Promise<T>;
};

export const getApplicantDetailGeneral = async (id: number) => {
  return getApplicantDetailByType<IApplicantDetailGeneral>(id, ApplicantDetailTypes.general);
};

export const getApplicantDetailApplications = async (id: number) => {
  return getApplicantDetailByType<IApplicantDetailApplication[]>(id, ApplicantDetailTypes.applications);
};

export const getApplicantDetailExams = async (id: number) => {
  return getApplicantDetailByType<Partial<IExam>[]>(id, ApplicantDetailTypes.exams);
};

export const getApplicantDetailConsultations = async (id: number) => {
  return getApplicantDetailByType<IConsultant[]>(id, ApplicantDetailTypes.consultations);
};

export const getApplicantDetailComments = async (id: number) => {
  return getApplicantDetailByType<IComment[]>(id, ApplicantDetailTypes.comments);
};

export const getApplicantDetailFeedbacks = async (id: number) => {
  return getApplicantDetailByType<IApplicantDetailFeedback[]>(id, ApplicantDetailTypes.feedbacks);
};

export const getApplicantDetailActions = async (id: number) => {
  return getApplicantDetailByType<IApplicantDetailAction[]>(id, ApplicantDetailTypes.actions);
};

export const getApplicantDetailPayments = async (id: number) => {
  return getApplicantDetailByType<IApplicantDetailPayment[]>(id, ApplicantDetailTypes.payments);
};

export const getApplicantDetailAttachments = async (id: number) => {
  return getApplicantDetailByType<IApplicantAttachment[]>(id, ApplicantDetailTypes.attachments);
};

export const getApplicantDetailAbsentees = async (id: number) => {
  return getApplicantDetailByType<IApplicantDetailAbsentees[]>(id, ApplicantDetailTypes.absentees);
};

export const setApplicantActiveAccount = async function (idToSet: number) {
  const r = await axios.post(`applicantDetail/changeAccount/${idToSet}`);
  return r.data as Promise<ApiSuccessMassage>;
};

export const getApplicantPromoCodes = async function (id: number) {
  const r = await axios.get(`applicantDetail/${id}/promoCode`);
  return r.data as Promise<IApplicantPromoCode[]>;
};
