import * as Yup from 'yup';

import messages from '../../../../constants/messages.constants';
import { IApplicantPersonalInfo } from '../../../../types/applicant.types';
import { Citizenship } from '../../types/studentAgreement.types';

export const AgreementSchema: Yup.SchemaOf<IApplicantPersonalInfo> = Yup.object().shape({
  citizenship: Yup.mixed<Citizenship>().oneOf(Object.values(Citizenship)).required(messages.requiredArm),
  isUnderage: Yup.boolean().default(false),
  contractNumber: Yup.string().nullable(true),
  contractYear: Yup.string().nullable(true),
  address: Yup.string().required(messages.requiredArm),
  passportGivenDate: Yup.string().required(messages.requiredArm),
  passportNumber: Yup.string().required(messages.requiredArm),
  socialCardNumber: Yup.string().when('citizenship', {
    is: Citizenship.ARMENIA,
    then: Yup.string().required(messages.requiredArm),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  nameHy: Yup.string().required(messages.requiredArm),
  studentNameHy: Yup.string().when('isUnderage', {
    is: true,
    then: Yup.string().required(messages.requiredArm),
    otherwise: Yup.string().nullable().notRequired(),
  }),
});
