import { ApplicantOptionsByDropOutMonth, ApplicantStatusOptions } from '../../constants/applicant.constants';
import { ApplicantDropOutMonths, StudentStatus } from '../../types/applicant.types';
import { GroupApplicantsCountResponse } from "../../types/group.types";

type StatusStatiticsItem = {
  data: number;
  title: string;
  name: string;
};

type IStatisticsByStatus = {
  [key in StudentStatus]: StatusStatiticsItem;
};

interface ContainStatus {
  status: StudentStatus;
}

export function calculateByStatus<T extends ContainStatus>(
  acc: Partial<IStatisticsByStatus>,
  student: T,
) {
  acc[student.status]
    ? (acc[student.status]!.data += 1)
    : (acc[student.status] = {
        title: ApplicantStatusOptions[student.status]?.label,
        data: 1,
        name: student.status
      });

  return acc;
}

export const getStatisticsByApplicantStatus = (applicantsCount: GroupApplicantsCountResponse) => {
  return Object.entries(applicantsCount).reduce((acc, [status, count]) => {
    return {
      ...acc,
      [status]: {
        title: ApplicantStatusOptions[status as keyof typeof ApplicantStatusOptions]?.label,
        data: count,
        name: status
      }
    }
  }, {})
}

interface ContainDropOutMonth {
  dropoutMonth?: ApplicantDropOutMonths
}

export function getStatisticsByDropOutMonths(students: ContainDropOutMonth[]) {
  return students.reduce((acc: any, student: ContainDropOutMonth) => {
    if(student.dropoutMonth) {
      acc[student.dropoutMonth]
          ? (acc[student.dropoutMonth].data += 1)
          : (acc[student.dropoutMonth] = {
            title: ApplicantOptionsByDropOutMonth[student.dropoutMonth]?.label,
            data: 1,
            name: student.dropoutMonth
          });

      return acc;
      }
      return acc;
  }, {});
}
