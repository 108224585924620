import { sorterByStartDate } from "../../helpers/sorting.helpers";
import type { ColumnSchema } from '../../types/column.types';
import type { IGroup } from '../../types/group.types';
import { formatGroup } from '../applicants/applicant.renderer';
import {
  AvatarGroupColumn,
  DetailGroupColumn, EditGroupColumn,
  GroupDescriptionColumn,
  LessonGroupColumn,
  ProgressGroupColumn,
  SlackGroupColumn,
  StartDateGroupColumn,
  TutorGroupColumn,
} from './group.renderer';

export const defaultWaitingForDemoGroupColumns: ColumnSchema<IGroup> = {
  uuid: 'defaultWaitingForDemoGroupColumns',
  schema: {
    projectCoordinator: {
      dataIndex: 'projectCoordinator',
      title: 'Project Coordinator',
      render: (_, group, idx) => AvatarGroupColumn!(null, group, idx),
      width: 200,
    },

    course: {
      title: 'Course',
      dataIndex: 'course',
      render: (_, group) => group.course.name,
    },
    group: {
      title: 'Group',
      dataIndex: 'number',
      render: (_, group) => formatGroup(group),
    },
    tutor: {
      title: 'Tutors',
      dataIndex: 'tutor',
      render: TutorGroupColumn,
    },
    startDate: {
      title: 'Start',
      dataIndex: 'startDate',
      render: StartDateGroupColumn,
      sorter: sorterByStartDate,
    },
    description: {
      title: 'Description',
      dataIndex: 'description',
      render: GroupDescriptionColumn,
    },
    completenessPercentage: {
      title: 'Progress',
      dataIndex: 'completenessPercentage',
      render: ProgressGroupColumn,
    },
    lesson: {
      title: 'Lesson',
      dataIndex: 'Lesson',
      render: LessonGroupColumn,
    },
    slackLink: {
      title: 'Slack',
      dataIndex: 'slackLink',
      align: 'center',
      width: 30,
      render: SlackGroupColumn,
    },
    edit: {
      title: 'Edit',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: EditGroupColumn,
    },
    details: {
      title: 'Details',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: (_, group) => DetailGroupColumn(_, group),
    },
  },
};
