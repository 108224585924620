import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { createCourse } from '../../services/courses.service';
import { ICreateCourse } from '../../types/course.types';
import { EmailTemplatesEnum } from '../../types/email.types';
import { IAppForm } from '../../types/form.types';
import { TransformCourseForm } from './shared/transformCourse.form';

export const CreateCourseForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();

  const createNewCourseMutation = useMutation(createCourse, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.FILTER_COURSES),
  });

  const handleCreateNewCourse = async (course: Partial<ICreateCourse>) => {
    const mutationFunc = () => createNewCourseMutation.mutateAsync(course as ICreateCourse);
    withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };
  const isScreenLoading = createNewCourseMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <TransformCourseForm
        submitText="Create Course"
        initialValues={{
          emailTemplate: EmailTemplatesEnum.DEFAULT_TEMPLATE,
        }}
        onSubmit={handleCreateNewCourse}
      />
    </Spin>
  );
};
