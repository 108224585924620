import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';

import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../components/display/ResultSummary/ResultSummary';
import { Button, FilterButton, Icons } from '../../components/general';
import { ChangeView } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { defaultFilterUserOptions } from '../../constants/user.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { defaultUserColumns } from '../../schemas/users/user.schema';
import { filterUsers } from '../../services/user.service';
import { IUser } from '../../types/user.types';

export const UsersMainPage = () => {
  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions } = useFilter(defaultFilterUserOptions);

  const users = useQuery(
    [UseQueryTypes.USERS, filterOptions, sorterOptions],
    () => filterUsers(filterOptions, sorterOptions),
  );

  const userTable = useTable(defaultUserColumns);
  const rowSelectionAdapter = useRowSelectionAdapter<IUser>();

  const modalAdapter = useColumnModalAdapter(
    userTable.getTableColumnSettings(),
  );

  const handleOpenUserFilterForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.FilterUserForm, 'Filter User');
  };

  const handleOpenCreateUserForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.CreateUserForm, 'Create User');
  };

  const usersTotalSize = users.data?.totalElements ?? 0;
  const isScreenLoading = users.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Row>
                  <Col>
                    <Button
                      onClick={handleOpenCreateUserForm}
                      variant="primary"
                      icon={<Icons.Add/>}
                    >
                      Add a new User
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <FilterButton onClick={handleOpenUserFilterForm}/>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: '16px 0px' }}/>
          <Col span={24} style={{ padding: '16px 0' }}>
            <Row justify="space-between">
              <Col>
                <ResultSummary text={`${usersTotalSize} result found`}/>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Row justify="end">
                      <ChangeView onClick={modalAdapter.openSettings}/>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              {...userTable.getTableProps({
                rowKey: (user) => user.id,
                dataSource: users.data?.users,
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />
            <Pagination
              {...userTable.getPaginationProps({
                total: usersTotalSize,
                style: { marginTop: 20 },
              })}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
};
