import { createContext, useContext } from 'react';

import { useUser } from '../hooks/useUser.hooks';
import { AbstractUser } from '../types/auth.types';
import { IUserLogin, UserRole } from '../types/user.types';

export interface IAuthContext {
  user: Partial<AbstractUser>;
  isInstructor: () => boolean,
  isAdmin: () => boolean,
  logOut: () => void;
  logIn: (user: IUserLogin, authGroup: string) => Promise<void>;
  refreshUser: () => void;
  checkUserRole: (role: UserRole) => boolean;
}

const authContext = createContext<IAuthContext>({
  user: null,
  isInstructor: () => false,
  isAdmin: () => false,
  logOut: () => {
  },
  logIn: async () => {
  },
  refreshUser: () => {
  },
  checkUserRole: () => false,
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that  calls useAuth().
export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useUser();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
