import {useEffect, useState} from "react";

import { Flex } from "../../../../../components/general";
import useWindowSize from "../../../../../hooks/useWindowSize.hooks";
import {useAppDispatch} from "../../../../../store";
import {resetAgreementData} from "../../../../../store/features/student/studentAgreement/studentAgreementSlice";
import { ScreenSizeBreakPoints } from "../../../constants/mediaQuery.constants";
import { StudentAgreementForm } from "../../../forms/StudentAgreementForm/StudentAgreementForm";
import StudentSignatureForm from "../../../forms/StudentSignatureForm/StudentSignatureForm";
import {IAgreementProcess, IAgreementProcessRenderer} from "../../../types/studentAgreement.types";
import PdfRenderer from "../../PdfRenderer/PdfRenderer";
import AgreementCreationController from "../AgreementCreationController/AgreementCreationController";
import AgreementCreationProcessRenderer from "../AgreementCreationProcess/AgreementCreationProcessRenderer";
import Greeting from "../Greeting/Greeting";

const AgreementProcessesSchemas: IAgreementProcess[] = [
  {
    id: 1,
    state: 1,
    text: 'My Data',
    renderer: ({nextProcessState}: IAgreementProcessRenderer) => <StudentAgreementForm handleNext={nextProcessState}/>
  },
  {
    id: 2,
    state: 2,
    text: 'See the Agreement',
    renderer: ({nextProcessState, backProcessState}: IAgreementProcessRenderer) => <PdfRenderer handleBack={backProcessState!} handleNext={nextProcessState}/>
  },
  {
    id: 3,
    state: 3,
    text: 'Sign the Agreement',
    renderer: ({nextProcessState}: IAgreementProcessRenderer) => <StudentSignatureForm submitEventHandler={nextProcessState}/>
  }
]

function AgreementCreator({ lastEvent }: { lastEvent: () => void }) {
  const { width } = useWindowSize();
  const globalDispatch = useAppDispatch();
  const [creationProcessData, setCreationProcessData] = useState<IAgreementProcess>(AgreementProcessesSchemas[0]);

  const nextProcessState = () => {
    const lastProcessIndex = AgreementProcessesSchemas.length
    if (lastProcessIndex === creationProcessData.id) {
      return lastEvent()
    }

    setCreationProcessData(prev => {
      const nextItemId = prev.id + 1;
      return AgreementProcessesSchemas.find(item => item.id === nextItemId) as IAgreementProcess
    })
  };

  const backProcessState = () => {
    setCreationProcessData(prev => {
      const nextItemId = prev.id - 1;
      return AgreementProcessesSchemas.find(item => item.id === nextItemId) as IAgreementProcess
    })
  };

  const cleanUp = () => {
    globalDispatch(resetAgreementData())
  };

  const init = () => cleanUp;

  useEffect(init,[])

  return (
    <Flex direction='column' alignItems='center' justifyContent='center'>
      {width >= ScreenSizeBreakPoints.Mobile && <Greeting/>}
      <AgreementCreationProcessRenderer
        processList={AgreementProcessesSchemas}
        currentProcess={creationProcessData}/>
      <AgreementCreationController creationProcess={creationProcessData} backProcessState={backProcessState} nextProcessState={nextProcessState}/>
    </Flex>
  );
}

export default AgreementCreator;
