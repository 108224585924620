import { notification } from 'antd';

import { formatGroup } from '../schemas/applicants/applicant.renderer';
import { IGroup } from '../types/group.types';
import { NotificationStatus } from '../types/notification.types';

export enum NotificationTypes {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface INotification {
  type: keyof typeof NotificationTypes;
  title: string;
  description: any;
  duration?: number;
  created_at: string;
  status: NotificationStatus;
  owner: string;
}

export const openNotificationWithIcon = ({
  type,
  title,
  description,
  duration,
}: Partial<INotification>) => {
  if (type) {
    notification[type]({
      message: title,
      description,
      duration: duration || 5,
    });
  }
};

export const CustomWarningMessages = {
  groupSchedule: (group: IGroup) => {
    return `${group.name} doesn't have a schedule. Please create a schedule.`;
  }
};
