import { CourseCategoryEnum, CourseTimeScaleEnum } from '../types/course.types';
import { FeeClientTypeEnum } from '../types/fee.types';

export const CourseCategoryOptions = {
  [CourseCategoryEnum.REGULAR]: {
    label: 'Regular',
    value: CourseCategoryEnum.REGULAR,
  },
  [CourseCategoryEnum.BOOTCAMP]: {
    label: 'Bootcamp',
    value: CourseCategoryEnum.BOOTCAMP,
  },
} as const;

export const CourseDurationOptions = {
  [CourseTimeScaleEnum.WEEK]: {
    label: 'Week',
    value: CourseTimeScaleEnum.WEEK,
  },
  [CourseTimeScaleEnum.MONTH]: {
    label: 'Month',
    value: CourseTimeScaleEnum.MONTH,
  },
  [CourseTimeScaleEnum.YEAR]: {
    label: 'Year',
    value: CourseTimeScaleEnum.YEAR,
  },
  [CourseTimeScaleEnum.DAY]: {
    label: 'Day',
    value: CourseTimeScaleEnum.DAY,
  },
} as const;

export const FeeClientTypes = {
  [FeeClientTypeEnum.PER_COURSE]: {
    label: 'per course',
    value: FeeClientTypeEnum.PER_COURSE,
  },
  [FeeClientTypeEnum.MONTHLY]: {
    label: 'per month',
    value: FeeClientTypeEnum.MONTHLY,
  },
} as const;

export const LevelOptions = {
  0: {
    label: 'Level 0',
    value: '0',
  },
  1: {
    label: 'Level 1',
    value: '1',
  },
  2: {
    label: 'Level 2',
    value: '2',
  },
} as const;

export const DEFAULT_EMAIL: string = 'info@aca.am';
export const DEFAULT_PHONE: string = '+37441163204';

