import { Divider, Modal } from 'antd';
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { UseQueryTypes } from "../../../constants/useQuery.constants";
import { applicantBulkUpdateNormalizer } from "../../../helpers/applicants/applicantBulkUpdateNormalizer";
import { withCatch } from "../../../helpers/error.helpers";
import { useRowSelectionAdapter } from "../../../hooks/useRowSelection.adapter";
import { bulkUpdateApplicants } from "../../../services/applicant.service";
import { useAppSelector } from "../../../store";
import { selectApplicants } from "../../../store/features/applicants/applicantsSlice";
import { IStudentBrief, IUpdateStudent } from "../../../types/applicant.types";
import AddApplicantToGroupForm from './addApplicantToGroup.form';
import ApplicantSearchFilter from "./applicantSearchFilter";
import SelectedApplicantsView from "./selectedAplicants";

type Props = {
  modalOpen: boolean,
  setModalOpen: (data: boolean) => void
}

const AddApplicantToGroupModal = ({ modalOpen, setModalOpen }: Props) => {
  const cache = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const selectedApplicants = useAppSelector(selectApplicants);
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>('applicant');

  const [search, setSearch] = useState<string>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((q) => {
      setSearch(q.target.value.trim());
    }, 500),
    [],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    delayedQuery(e);
  };

  const handleCloseModal = () => {
    cache.invalidateQueries(UseQueryTypes.APPLICANTS);
    setModalOpen(false);
  };

  const bulkUpdateMutation = useMutation(bulkUpdateApplicants);

  const handleOk = async () => {
    const applicantState = { groupId: Number(id) };
    const updateQuery: Partial<IUpdateStudent>[] =
      applicantBulkUpdateNormalizer(applicantState, selectedApplicants);

    await withCatch(() => bulkUpdateMutation.mutateAsync(updateQuery), {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        setModalOpen(false);
      },
    });
  };

  const cleanUp = () => {
    setModalOpen(false)
    rowSelectionAdapter.clean();
  }

  const init = () => {
    return cleanUp
  }

  useEffect(init, [])

  return (
    <Modal
      visible={modalOpen}
      onOk={handleOk}
      title="Assign applicants to group"
      okText={'Submit'}
      onCancel={handleCloseModal}
      centered
      width={600}
    >
      <ApplicantSearchFilter search={search} handleSearch={handleSearch}/>
      <Divider/>
      <div style={{ overflow: 'auto', height: '50vh' }}>
        <AddApplicantToGroupForm search={search}/>
      </div>
      <SelectedApplicantsView/>
    </Modal>
  )
}

export default AddApplicantToGroupModal;
