export default function StatusIndicator({
  isSuccess = false,
}: {
  isSuccess?: boolean;
}) {
  return (
    <div
      style={{
        border: '0',
        height: '15px',
        width: '15px',
        borderRadius: '100%',
        backgroundColor: isSuccess
          ? 'var(--custom-success-color)'
          : 'var(--custom-warning-color)',
      }}
    ></div>
  );
}
