import { Spin } from "antd";
import React, { useReducer } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Button, Flex } from "../../../../components/general";
import {downloadByLink} from "../../../../helpers/download.helpers";
import { PdfActionTypes, pdfRendererReducer } from "../../reducers/pdfRenderer.reducer";
import styles from './PdfPreview.module.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

enum PdfZoomVariant {
  Plus = 'Plus',
  Minus = 'Minus'
}


enum PdfPaginationVariant {
  Next = 'Next',
  Prev = 'Prev'
}

const PdfZoomers = ({
  handleZoom,
}: { currentScale: number, handleZoom: (zoomVariant: PdfZoomVariant) => void }) => {
  const handleInnerZoom = (variant: PdfZoomVariant) => () => {
    switch (variant) {
      case PdfZoomVariant.Plus:
        handleZoom(PdfZoomVariant.Plus);
        break
      case PdfZoomVariant.Minus:
        handleZoom(PdfZoomVariant.Minus);
        break
      default:
        handleZoom(PdfZoomVariant.Plus);
    }
  }

  return <Flex style={{ gap: '0 8px' }}>
    <Button onClick={handleInnerZoom(PdfZoomVariant.Plus)} style={{ borderRadius: '10px' }}>+</Button>
    <Button onClick={handleInnerZoom(PdfZoomVariant.Minus)} style={{ borderRadius: '10px' }}>-</Button>
  </Flex>
}

const PdfPagination = ({
  allPages,
  pagesNum,
  handlePagination
}: { allPages: number, pagesNum: number, handlePagination: (paginationVariant: PdfPaginationVariant) => void }) => {

  const handleInnerPagination = (variant: PdfPaginationVariant) => () => {
    switch (variant) {
      case PdfPaginationVariant.Next:
        handlePagination(PdfPaginationVariant.Next);
        break;
      case PdfPaginationVariant.Prev:
        handlePagination(PdfPaginationVariant.Prev);
        break;
      default:
        handlePagination(PdfPaginationVariant.Prev);
    }
  }

  return <Flex alignItems='center' justifyContent='center' className={styles.pdfPaginationWrapper}>
    <Button style={{ border: '0' }} onClick={handleInnerPagination(PdfPaginationVariant.Prev)}>{'<'}</Button>
    <span>{allPages}/{pagesNum}</span>
    <Button style={{ border: '0' }} onClick={handleInnerPagination(PdfPaginationVariant.Next)}>{'>'}</Button>
  </Flex>
}

export const PdfLoader = () => {
  return (
    <Flex alignItems='center' justifyContent='center' style={{ height: '400px' }}>
      <Spin/>
    </Flex>
  )
}


const initialSettings = {
  currentPage: 1,
  allPages: 1,
  scale: 1.2
}

export default function PdfPreview({link,allowDownload}:{link: string | null,allowDownload?: boolean}) {
  const [pdfSettings, dispatch] = useReducer(pdfRendererReducer, initialSettings)
  const {
    currentPage,
    allPages,
    scale,
  } = pdfSettings;


  const onDocumentLoadSuccess = ({ numPages }: any) => {
    if (numPages !== allPages) {
      dispatch({ type: PdfActionTypes.CHANGE_ALL_PAGES, payload: { allPages: numPages } });
    }
  };

  const handleZoom = (zoomVariant: PdfZoomVariant) => {
    switch (zoomVariant) {
      case PdfZoomVariant.Plus:
        dispatch({ type: PdfActionTypes.INCREMENT_ZOOM });
        break
      case PdfZoomVariant.Minus:
        dispatch({ type: PdfActionTypes.DECREMENT_ZOOM });
        break
      default:
        dispatch({ type: PdfActionTypes.INCREMENT_ZOOM });
    }
  };

  const handlePagination = (paginationVariant: PdfPaginationVariant) => {
    switch (paginationVariant) {
      case PdfPaginationVariant.Next:
        dispatch({ type: PdfActionTypes.INCREMENT_PAGE });
        break;
      case PdfPaginationVariant.Prev:
        dispatch({ type: PdfActionTypes.DECREMENT_PAGE });
        break;
      default:
        dispatch({ type: PdfActionTypes.INCREMENT_PAGE });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Flex alignItems='center' justifyContent='center' className={styles.documentBorder}>
        <div className={styles.documentWrapper}>
          <Document
            loading={<PdfLoader/>}
            onLoadSuccess={onDocumentLoadSuccess}
            file={link}
          >
            <Page pageNumber={currentPage} renderMode="canvas" scale={scale}/>
          </Document>
        </div>
      </Flex>
      <Flex className={styles.pdfActionsWrapper} justifyContent='center' alignItems='center'>
        <div style={{ position: 'absolute', left: 0 }}>
          <PdfZoomers currentScale={scale} handleZoom={handleZoom}/>
        </div>
        <PdfPagination allPages={currentPage} pagesNum={allPages} handlePagination={handlePagination}/>
      </Flex>
      {allowDownload && <div style={{float: "right"}}><Button onClick={() => downloadByLink({link: link!})}>Download</Button></div>}
    </div>
  );
}


