import * as Yup from 'yup';

import { TaskStatusEnum, TaskTypeEnum } from '../../../types/task.types';

export const TransformTaskFormSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  dueDate: Yup.string().required('required'),
  taskStatus: Yup.mixed<TaskStatusEnum>().oneOf(Object.values(TaskStatusEnum)).required('Required'),
  taskType: Yup.mixed<TaskTypeEnum>().oneOf(Object.values(TaskTypeEnum)).required('Required'),
  relatedTo: Yup.number().nullable(true),
  owner: Yup.number().required('Required'),
});

export const TransformTaskFormOwnedSchema = Yup.object().shape({
  taskStatus: Yup.mixed<TaskStatusEnum>().oneOf(Object.values(TaskStatusEnum)).required('Required'),
});

export const BulkUpdateTaskStatusFormSchema = Yup.object().shape({
  taskStatus: Yup.string().oneOf(Object.values(TaskStatusEnum)).required(),
});
