import { PdfConstants } from "../../student/constants/pdfRenderer.constants";
import { pdfActionLimiter } from "../../student/helpers/pdf.helpers";

export interface IPdfSettings {
  currentPage: number;
  allPages: number,
  scale: number
}

export enum PdfActionTypes {
  CHANGE_ALL_PAGES = 'CHANGE_ALL_PAGES',
  INCREMENT_PAGE = 'INCREMENT_PAGE',
  DECREMENT_PAGE = 'DECREMENT_PAGE',
  DECREMENT_ZOOM = 'DECREMENT_ZOOM',
  INCREMENT_ZOOM = 'INCREMENT_ZOOM'
}

export interface IPdfSettingsActions {
  type: PdfActionTypes;
  payload?: { allPages: number };
}

export const pdfRendererReducer = (state: IPdfSettings, {type, payload}: IPdfSettingsActions) => {
  const {currentPage, allPages, scale} = state;
  const result = Object.assign({}, state);

  switch (type) {
    case PdfActionTypes.INCREMENT_PAGE:
      result.currentPage = pdfActionLimiter({
        itemNumber: currentPage + PdfConstants.PAGE_STEP,
        maxItem: allPages,
        minItem: PdfConstants.MIN_PAGE
      });
      break;
    case PdfActionTypes.DECREMENT_PAGE:
      result.currentPage = pdfActionLimiter({
        itemNumber: currentPage - PdfConstants.PAGE_STEP,
        maxItem: allPages,
        minItem: PdfConstants.MIN_PAGE
      });
      break;
    case PdfActionTypes.INCREMENT_ZOOM:
      result.scale = pdfActionLimiter({
        itemNumber: scale + PdfConstants.SCALE_STEP,
        maxItem: PdfConstants.MAX_ZOOM,
        minItem: PdfConstants.MIN_ZOOM
      });
      break;
    case PdfActionTypes.DECREMENT_ZOOM:
      result.scale = pdfActionLimiter({
        itemNumber: scale - PdfConstants.SCALE_STEP,
        maxItem: PdfConstants.MAX_ZOOM,
        minItem: PdfConstants.MIN_ZOOM
      });
      break;
    case PdfActionTypes.CHANGE_ALL_PAGES:
      if (payload && payload.allPages) {
        result.allPages = payload.allPages
      }
      break;
    default:
      return result
  }


  return result
}
