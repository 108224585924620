import { Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../../../constants/useQuery.constants';
import { withCatch } from '../../../../helpers/error.helpers';
import { useEntityId } from '../../../../hooks/useFilter.hooks';
import {
  addApplicantFeedback,
  getApplicantFeedbacks,
} from '../../../../services/applicant.service';
import { IStudentFilterResponse } from '../../../../types/applicant.types';
import type { IAppForm } from '../../../../types/form.types';
import { AddFeedbackForm } from './addApplicantFeedbackForm/AddFeedbackForm';

interface IInitialValues {
  feedback: string;
  isPrivate: number;
}

export const AddApplicantFeedbackForm: IAppForm = () => {
  const cache = useQueryClient();
  const { id: applicantId } = useEntityId();

  const applicantsCache: IStudentFilterResponse | undefined =
    cache.getQueryData(UseQueryTypes.APPLICANTS);

  const applicantQuery = applicantsCache?.applicants?.find(
    (ap: { id: number }) => ap.id === applicantId,
  );

  const initialValues: { feedback: string; isPrivate: number } = {
    feedback: '',
    isPrivate: 1,
  };

  const feedbacksQuery = useQuery(UseQueryTypes.APPLICANT_FEEDBACKS, () =>
    getApplicantFeedbacks(applicantId),
  );

  const addApplicantFeedbackMutation = useMutation(addApplicantFeedback, {
    onSuccess: async () => {
      await cache.invalidateQueries(UseQueryTypes.APPLICANT_FEEDBACKS);
      await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
    },
  });

  const handleAddFeedback = async ({ feedback, isPrivate }: IInitialValues) => {
    const mutationFunc = () => {
      return addApplicantFeedbackMutation.mutateAsync({
        applicantId: Number(applicantQuery?.id),
        feedback: {
          content: feedback,
          isPrivate: isPrivate,
        },
      });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        initialValues.feedback = '';
        await cache.invalidateQueries(UseQueryTypes.APPLICANT_FEEDBACKS);
      },
    });
  };

  const applicantFeedbacks = (feedbacksQuery?.data || []).reverse();

  const entity = {
    name: applicantQuery?.name,
    email: applicantQuery?.email,
  };

  const isScreenLoading =
    addApplicantFeedbackMutation.isLoading || feedbacksQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <AddFeedbackForm
        feedbacks={applicantFeedbacks}
        initialValues={initialValues}
        onSubmit={handleAddFeedback}
        entity={entity}
      />
    </Spin>
  );
};
