import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Button,
  Flex,
  SingleSelectFormik,
} from '../../components/general';
import { ApplicantCreateStatusOptions } from '../../constants/applicant.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { studentGroupById, updateStudentGroupById } from '../../services/studentGroup.service';
import { studentGroupTerminatedFormSchema, } from './schemas/transformApplicant.form.schema';

interface IForm {
  status: string
}


export const EditApplicantFormInTerminatedGroup = () => {
  const { closeDrawer } = useDrawer();
  const { id } = useEntityId();
  const cache = useQueryClient();

  const applicantQuery = useQuery([UseQueryTypes.APPLICANT_ID, id], () =>
    studentGroupById(id),
  );

  const initialValues: IForm = useMemo(() => {
    return { status: applicantQuery?.data?.status || ''}
  }, [applicantQuery?.data])


  const updateStudentGroupMutation = useMutation(updateStudentGroupById);

  const handleSubmit = async (studentGroup: { status: string }) => {

    const mutationFunc = () =>
      updateStudentGroupMutation.mutateAsync({id, studentGroup});

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        cache.invalidateQueries(UseQueryTypes.APPLICANT_ID);
        closeDrawer();
      },
    });
  };


  return (
    <Spin spinning={applicantQuery.isLoading}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={studentGroupTerminatedFormSchema}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form
              name="filters-1"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field
                label="Status"
                name="status"
                component={SingleSelectFormik}
                options={ApplicantCreateStatusOptions}
                required
              />
              <ErrorMessage name="status" component="span"  className="error"/>
              <Flex
                style={{ marginTop: 'auto' }}
                justifyContent="space-between"
              >
                <Button
                  htmlType="button"
                  style={{ minWidth: 160, marginRight: 8 }}
                  onClick={() => {
                    return props.handleReset();
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
