import { createContext, useContext } from 'react';


const menuContext = createContext<any>({});

export const MobileMenuProvider = ({ children, value }: { children: JSX.Element ,value: any }) => {
  return (
    <menuContext.Provider value={value}>
      {children}
    </menuContext.Provider>
  )
}

export const useMobileMenu = () => {
  return useContext(menuContext);
}
