import { Col, Modal, Row, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Button,
  Flex,
  Icons,
  Input as FormInput,
} from '../../../components/general';
import AddButton  from '../../../components/general/AddButton/AddButton';
import { MultiselectFormik } from '../../../components/general/Multiselect/Multiselect';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { defaultFilterUserOptions } from '../../../constants/user.constants';
import { withCatch } from '../../../helpers/error.helpers';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { deletePhoneById } from '../../../services/phoneNumbers.service';
import { filterUsers } from '../../../services/user.service';
import { ITransform } from '../../../types/form.types';
import { ICreatePhone } from '../../../types/phone.types';
import { ICreateTeamPhone } from '../../../types/teamPhone.types';
import { TransformTeamPhoneFormSchema } from '../schemas/treansformTeam.form.schema';

type TransformTeamPhoneType = {
  isEditForm?: boolean;
};

const SimplePhoneInput = ({ phone, field, label, onDelete }: { phone: ICreatePhone; field: string; label: string; onDelete: any}) => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const cache = useQueryClient();


  const deletePhoneMutation = useMutation(deletePhoneById);

  const handleToggle = () => {
    setIsOpenModal((state) => !state);
  };


  const handleDeletePhone = () => {
    if(phone.id) {
      return async () => {
        // @ts-ignore
        await withCatch(() => deletePhoneMutation.mutateAsync(phone.id), {
          onSuccess: () => {
            cache.invalidateQueries(UseQueryTypes.PHONE);
            onDelete()
            handleToggle();
          },
        });
      }
    } else {
      return () => {
        onDelete()
        handleToggle();
      }
    }
  };

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={22}>
          <Field
            name={field}
            label={label}
            component={FormInput}
            required
          />
          <ErrorMessage
            name={field}
            component="span"
            className="error"
          />
        </Col>
        <Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Icons.Delete
            onClick={handleToggle}
            style={{ cursor: 'pointer' }}
          />
        </Col>
        <Modal
          visible={isModalOpen}
          onOk={handleDeletePhone()}
          okText={'Yes'}
          okType={'danger'}
          onCancel={handleToggle}
          centered
          width={600}
        >
          <Text>Permanently Delete phone ?</Text>
        </Modal>
      </Row>
    </>
  )
}

export const TransformTeamForm = ({
                                         onSubmit,
                                         initialValues,
                                         submitText = 'Apply',
                                         isEditForm = false,
                                       }: ITransform<ICreateTeamPhone> & TransformTeamPhoneType) => {

  const { filterOptions, sorterOptions } = useFilter(defaultFilterUserOptions);

  const users = useQuery(
    [UseQueryTypes.USERS, filterOptions, sorterOptions],
    () => filterUsers(filterOptions, sorterOptions),
  );


  const userIdContext = useMemo(() => {
    return generateContext(users.data?.users || [], ({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));
  }, [users.data?.users]);

  const handleSubmit = async (teamPhone: Partial<ICreateTeamPhone>) => {
    await onSubmit(teamPhone);
  };

  const isScreenLoading = false;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={TransformTeamPhoneFormSchema}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form
              name="filters-1"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >

              <Field
                name='name'
                label='Name'
                component={FormInput}
                required
              />
              <ErrorMessage
                name='name'
                component="span"
                className="error"
              />

              <FieldArray name="phoneNumbers">
                {({ insert, remove, push }) => (
                  <div>
                    {
                      !!props?.values?.phoneNumbers?.length  &&
                      props.values.phoneNumbers?.map((phone, index) => (
                        <SimplePhoneInput key={index} phone={phone} field={`phoneNumbers[${index}].number`} label={`Phone ${index +1}`} onDelete={() => remove(index)} />
                      ))}
                    <AddButton onClick={() => push({ number: '' })}>Add Phone Number</AddButton>
                  </div>
                )}
              </FieldArray>

              <Field
                name="members"
                label="Members"
                options={userIdContext}
                component={MultiselectFormik}
              />
              <ErrorMessage
                name="teams"
                component="span"
                className="error"
              />

              <Flex
                style={{ marginTop: 'auto' }}
                justifyContent="space-between"
              >
                <Button
                  htmlType="button"
                  style={{ minWidth: 160, marginRight: 8 }}
                  onClick={() => {
                    return props.handleReset();
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  {submitText}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};

