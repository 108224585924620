import { CustomError } from '../types/customError.types';
import {
  NotificationTypes,
  openNotificationWithIcon,
} from './notifications.helpers';

export const handleQueryError = (
  title = 'Error',
  description = 'Error. Please, try later.',
) => {
  openNotificationWithIcon({
    type: NotificationTypes.error,
    title,
    description,
  });
};

export const handleQuerySuccess = (
  title = 'Success!',
  description = 'Request fulfilled',
) => {
  openNotificationWithIcon({
    type: NotificationTypes.success,
    title,
    description,
  });
};

type Message = {
  title: string;
  description: string;
};

type IQueryOptions<T> = {
  onSuccess: (data: T) => void;
  onError: () => void;
  successMessage: Message;
  errorMessage: Message;
  hideMessage: boolean
};

export const withCatch = async <T>(
  promise: () => Promise<T>,
  opt?: Partial<IQueryOptions<T>>,
) => {
  try {
    const data = await promise();
    opt?.onSuccess && opt.onSuccess(data);
    if (!opt?.hideMessage) {
      await handleQuerySuccess(
          opt?.successMessage?.title,
          opt?.successMessage?.description,
      );
    }
  } catch (error: any) {
    if (!opt?.hideMessage){
      const errorInfo = error.response
          ? (error.response.data as CustomError)
          : error.data;
      opt?.errorMessage
          ? handleQueryError(opt.errorMessage.title, opt.errorMessage.description)
          : handleQueryError(errorInfo?.message, errorInfo?.uiMessage);
    }
    opt?.onError && opt.onError();
  }
};
