import { PaymentEnum } from '../types/payment.types';

export const PaymentOptions: {
  [key: string]: { label: string; value: string };
} = {
  [PaymentEnum.PREPAYMENT]: {
    label: 'Pre payment',
    value: PaymentEnum.PREPAYMENT,
  },
  [PaymentEnum.POSTPAYMENT]: {
    label: 'Post payment',
    value: PaymentEnum.POSTPAYMENT,
  },
} as const;
