import { Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { addTaskComment, getTaskComments, updateTaskComment } from '../../services/tasks.service';
import { IComment } from '../../types/comment.types';
import type { IAppForm } from '../../types/form.types';
import { AddCommentForm } from '../shared/comment.form';

export const AddTaskCommentForm: IAppForm = () => {
  const cache = useQueryClient();
  const { id: taskId } = useEntityId();

  const tasksCache: any = cache.getQueryData(UseQueryTypes.TASKS);

  const taskQuery = tasksCache?.tasks?.find((task: { id: number }) => task.id === taskId);

  const initialValues = { comment: '' };

  const commentsQuery = useQuery(UseQueryTypes.TASK_COMMENTS, () => getTaskComments(taskId));

  const addTaskCommentMutation = useMutation(addTaskComment, {
    onSuccess: async () => {
      await cache.invalidateQueries(UseQueryTypes.TASK_COMMENTS);
      await cache.invalidateQueries(UseQueryTypes.TASKS);
    },
  });

  const handleAddComment = async ({ comment }: typeof initialValues) => {
    const mutationFunc = () => {
      return addTaskCommentMutation.mutateAsync({
        taskId: Number(taskQuery?.id || taskId),
        comment: {
          content: comment,
        },
      });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        initialValues.comment = '';
        await cache.invalidateQueries(UseQueryTypes.TASK_COMMENTS);
      },
    });
  };

  const taskComments = (commentsQuery?.data || []).slice().reverse();

  const isScreenLoading = addTaskCommentMutation.isLoading || commentsQuery.isLoading;

  const editTaskCommentMutation = useMutation(updateTaskComment);

  const editComment = async (commentId: number, comment: IComment) => {
    const mutationFunc = () => {
      return editTaskCommentMutation.mutateAsync({ commentId, comment });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.TASK_COMMENTS);
        await cache.invalidateQueries(UseQueryTypes.TASKS);
      },
    });
  };

  return (
    <Spin spinning={isScreenLoading}>
      <AddCommentForm
        comments={taskComments}
        initialValues={initialValues}
        onSubmit={handleAddComment}
        editComment={editComment}
        entity={{
          name: taskQuery?.data?.name,
          email: taskQuery?.data?.email,
        }}
      />
    </Spin>
  );
};
