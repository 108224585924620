import { PaginationProps, TableProps } from 'antd';

import type { ColumnSchema, ColumnSettingController } from '../../types/column.types';
import { useTableColumns } from './useTableColumns';
import { useTablePagination } from './useTablePagination';

export interface ITableData<T> {
  getPaginationProps: (props?: Partial<PaginationProps> | undefined) => PaginationProps;
  columns: any[];
  getTableColumnSettings: () => ColumnSettingController;
  getTableProps: (props?: TableProps<T> | undefined) => TableProps<T>;
}

export const useTable = <T>(defaultColumns: ColumnSchema<T>): ITableData<T> => {
  const { columns, getTableColumnSettings } = useTableColumns(defaultColumns);
  const { getPaginationProps, getTableProps } = useTablePagination(columns);

  return {
    getPaginationProps,
    columns,
    getTableColumnSettings,
    getTableProps,
  };
};
