import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Flex, TextArea } from '../../components/general';
import RatingStars from '../../components/general/RatingStars/RatingStars';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { withCatch } from '../../helpers/error.helpers';
import { createStudentFeedback } from '../../services/student.service';
import { IAppForm } from '../../types/form.types';
import { StudentFeedbackFormSchema } from './studentFeedback.schema';

interface IInitialValues {
  content: string;
  rating: number;
}

export const CreateStudentFeedbackForm: IAppForm = () => {
  const { user } = useAuth();
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient()

  const initialValues: IInitialValues = {
    content: '',
    rating: 1,
  };

  const addStudentFeedbackMutation = useMutation(createStudentFeedback);

  const handleAddFeedback = async ({ content, rating }: IInitialValues, { resetForm }: { resetForm: () => void }) => {
    const mutationFunc = () => {
      return addStudentFeedbackMutation.mutateAsync({
        studentId: user?.id || 0,
        content,
        rating,
      });
    };

    await withCatch(mutationFunc, {
      onSuccess() {
        cache.invalidateQueries(UseQueryTypes.STUDENT_FEEDBACKS);
        closeDrawer();
      },
    });
  };

  const isScreenLoading = addStudentFeedbackMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Flex style={{ width: 400 }} direction="column">
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddFeedback}
          validationSchema={StudentFeedbackFormSchema}
          enableReinitialize
        >
          {({ dirty, isValid, handleReset, setFieldValue, initialValues }) => (
            <>
              <Form>
                <RatingStars
                  name={'feedback'}
                  value={initialValues.rating}
                  starCount={5}
                  onStarClick={(newRating: any) => setFieldValue('rating', newRating)}
                />
                <Field rows={4} label="Feedback" name="content" component={TextArea} />
                <ErrorMessage name="content" component="span" className="error" />

                <Flex justifyContent="space-between" style={{ marginTop: 20 }}>
                  <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={handleReset}>
                    Reset
                  </Button>
                  <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary" disabled={!(dirty && isValid)}>
                    Add feedback
                  </Button>
                </Flex>
              </Form>
            </>
          )}
        </Formik>
      </Flex>
    </Spin>
  );
};
