import generatePicker from 'antd/es/date-picker/generatePicker';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { FieldHookConfig, useField } from 'formik';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React from 'react';

import { DateService } from '../../../services/date.service';
import Flex from '../Flex/Flex';

interface ITimeProps {
  name: string;
  field: string | FieldHookConfig<any>;
  label: string;
}

export const DatePicker = generatePicker(dateFnsGenerateConfig);

const TimePicker = React.forwardRef<any, PickerProps<Date>>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

export default function Time({ name, field, label }: ITimeProps) {
  const [{ value }, , { setValue }] = useField(field);
  //
  const handleChange = (value: string | Date | null) => {
    setValue(DateService.getTimeDate(value));
  };

  return (
    <Flex direction="column">
      <label>{label}</label>
      <TimePicker
        size="large"
        format={'HH:mm'}
        name={name}
        onChange={handleChange}
        value={DateService.getParseDate(value)}
        defaultValue={value}
      />
    </Flex>
  );
}
