import axios from 'axios';

import type { Certificates } from '../types/certificates.types';

export async function generateCertificates(certificatePayload: Certificates) {
  const r = await axios.post('/documents/certificate', certificatePayload);
  return r.data as Promise<{ url: string }>;
}

export async function generateCertificatesForGroup({groupId, studentIds}: {groupId: number, studentIds: number[]}) {
  const r = await axios.post(`/documents/certificate/${groupId}/`, {studentIds});
  return r.data as Promise<{ url: string }>;
}
