import { Button as AntButton, Dropdown, Menu } from 'antd';

import Button from '../../../../components/general/Button/Button';
import Flex from '../../../../components/general/Flex/Flex';
import { Add } from '../../../../components/general/Icons/Icons';
import addButtonStyle from '../../../../components/general/Layout/AddbuttonMenu.module.css';
import { AddButtonMenuItems } from '../../../../constants/addButton.constants';
import { useDrawer } from '../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../forms';
import { AddButtonTypeEnum } from '../../types/button.types';

export const AddButton = ({ type = AddButtonTypeEnum.DEFAULT }: { type?: AddButtonTypeEnum }) => {
  const { openDrawer } = useDrawer();

  const handleOpenAddFeedback = () => {
    openDrawer(AppFormsEnum.CreateStudentFeedbackForm, 'Add a Feedback');
  };

  if (type === AddButtonTypeEnum.FEEDBACK) {
    return (
      <Flex alignItems="center">
        <Button
          variant="primary"
          style={{ padding: 10, width: '32px', height: '32px' }}
          onClick={handleOpenAddFeedback}
        >
          <Add />
        </Button>
      </Flex>
    );
  }

  const AddButtonMenu = () => {
    const handleOpenAddTask = () => {
      openDrawer(AppFormsEnum.CreateTaskForm, 'Add Task');
    };

    const handleOpenAddReminder = () => {
      openDrawer(AppFormsEnum.CreateReminderForm, 'Add a Reminder');
    };

    const actions: {
      [key: string]: any;
    } = {
      task: handleOpenAddTask,
      reminder: handleOpenAddReminder,
    };

    return (
      <Menu className={addButtonStyle.menu}>
        {AddButtonMenuItems.map(({ title, id, method }, index, arr) => {
          const lastItem = index === arr.length - 1;

          return (
            <AntButton
              key={id}
              className={lastItem ? addButtonStyle.lastMenuButton : addButtonStyle.menuButton}
              onClick={actions[method]}
            >
              {title}
            </AntButton>
          );
        })}
      </Menu>
    );
  };

  return (
    <Flex alignItems="center">
      <Dropdown
        overlay={AddButtonMenu}
        placement="bottomRight"
        trigger={['click', 'hover']}
        overlayStyle={{
          padding: '0',
          width: 'max-content',
          zIndex: '5',
        }}
      >
        <Button variant="primary" style={{ padding: 10, width: '32px', height: '32px' }}>
          <Add />
        </Button>
      </Dropdown>
    </Flex>
  );
};
