import type { ColumnSchema } from '../../types/column.types';
import type { ICourse } from '../../types/course.types';
import {
  AvatarCourseColumn,
  DurationCourseColumn,
  FeeCourseColumn,
} from './course.renderer';


export const defaultCourseByTrackColumns: ColumnSchema<ICourse> = {
  uuid: 'defaultCourseByTrackColumns',
  schema: {
    username: {
      title: 'PC',
      render: AvatarCourseColumn,
      width: 200,
    },
    name: {
      title: 'Course',
      sorter: () => -1,
    },
    level: {
      title: 'Level',
      sorter: () => -1,
    },
    track: {
      title: 'Track',
      dataIndex: ['track.name','relationalSort'],
      render: (_, course) => course.track.name,
      sorter: () => -1,
    },
    duration: {
      dataIndex: ['defaultDuration.durationInDays','relationalSort'],
      title: 'Duration',
      sorter: () => -1,
      render: DurationCourseColumn,
    },
    fee: {
      dataIndex: ['defaultTuitionFee.amount','relationalSort'],
      title: 'Fee',
      sorter: () => -1,
      render: FeeCourseColumn,
    },
    expectedNumberOfApplicants: {
      sorter: () => -1,
      title: 'Size',
    },
  },
};
