import { ErrorMessage, Field, Form, Formik } from 'formik';

import { Button, Flex, Input as FormInput } from '../../../components/general';
import { RegistrationSource } from '../../../types/applicant.types';
import type { ICreateConsultation } from '../../../types/consultation.types';
import type { ITransform } from '../../../types/form.types';
import { TransformApplicantFormSchema } from '../schemas/transformConsultant.form.schema';

export const TransformConsultantForm = ({
  onSubmit,
  initialValues = { source: RegistrationSource.WEBSITE },
  submitText = 'Apply',
}: ITransform<ICreateConsultation>) => {
  const handleSubmit = (consultant: Partial<ICreateConsultation>) => {
    onSubmit({
      ...consultant,
    });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={TransformApplicantFormSchema}
      enableReinitialize
    >
      {(props) => (
        <Form
          name="filters-1"
          style={{
            width: '100%',
            height: 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Field name="name" label="Name" component={FormInput} required />
          <ErrorMessage name="name" component="span" className="error" />

          <Field name="email" label="Email" component={FormInput} required />
          <ErrorMessage name="email" component="span" className="error" />

          <Field
            name="phoneNumber"
            label="Phone"
            component={FormInput}
            required
          />
          <ErrorMessage name="phoneNumber" component="span" className="error" />

          <Field
            name="source"
            label="Registration Source"
            placeholder="e.g. Website"
            component={FormInput}
          />
          <ErrorMessage name="source" component="span" className="error" />

          <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
            <Button
              htmlType="button"
              style={{ minWidth: 160, marginRight: 8 }}
              onClick={() => props.handleReset()}
            >
              Reset
            </Button>
            <Button
              style={{ minWidth: 160 }}
              htmlType="submit"
              variant="primary"
              disabled={!(props.dirty && props.isValid)}
            >
              {submitText}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
