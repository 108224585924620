import { IAutomatedExam, ICreateExam, IUpdateExamGrades } from '../types/exam.types';
import axios from './config.service';

export function bulkUpdateExamGrades(updates: IUpdateExamGrades[]) {
  return axios.put(`/exam/grade/bulk`, updates);
}

export function createExam(exam: ICreateExam) {
  return axios.post(`/exam`, exam);
}

export function updateExam({ id, exam }: { id: number; exam: ICreateExam }) {
  return axios.put(`/exam/${id}`, exam);
}

export function updateExamAutomation({ id, exam }: { id: number; exam: IAutomatedExam }) {
  return axios.put(`/examAutomation/${id}`, exam);
}
