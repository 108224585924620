import { IApplicantAttachment } from '../../types/applicant.types';
import { upperFirstLetter } from '../string.helpers';

interface INormalizedAttachment {
  [key: string]: IApplicantAttachment[];
}

export function applicantAttachmentsNormalizer(
  applicantAttachments: IApplicantAttachment[],
): INormalizedAttachment {
  return applicantAttachments.reduce((acc: any, attachment) => {
    const attachmentType = upperFirstLetter(attachment.attachmentType);

    if (acc?.[attachmentType]) {
      acc[attachmentType].push(attachment);
      return acc;
    }

    acc[attachmentType] = [attachment];

    return acc;
  }, {});
}
