import { upperFirstLetter } from '../../helpers/string.helpers';
import type { ColumnSchema } from '../../types/column.types';
import { ExamColumnNames, IExam, IExamColumn } from '../../types/exam.types';
import {
  ExamColumnDynamicRenderer,
  ExamColumnStatus,
  ExamDateColumn,
  StudentExamGradeAndStatusColumn,
  StudentFinalScoreExamsColumn,
  StudentNameExamColumn,
} from './exams.renderer';

export const getDefaultApplicantExamColumns = (
  examsData: IExam[] | undefined,
): ColumnSchema<any> => ({
  uuid: 'defaultApplicantExamColumns',
  schema: {
    student: {
      title: 'Student',

      width: 300,
      fixed: 'left',
      sorter: () => -1,
      render: StudentNameExamColumn,
    },
    phone: {
      title: 'Phone',
      width: 300,
      render: ExamColumnDynamicRenderer(ExamColumnNames.PhoneNumber),
    },
    exams: {
      title: 'Exams',
      children: examsData?.map((examData) => ({
        title: `${upperFirstLetter(examData.examType)} exam`,
        children: [
          {
            title: 'Date',
            width: 150,
            render: ExamDateColumn(examData),
          },
          {
            title: 'Status',
            width: 200,
            render: StudentExamGradeAndStatusColumn(examData),
          },
        ],
      })),
    },

    finalScore: {
      title: 'Final Score',
      width: 200,
      render: StudentFinalScoreExamsColumn(examsData),
    },
    status: {
      title: 'Final Status',
      width: 250,
      render: ExamColumnStatus(examsData),
    },
  },
});
