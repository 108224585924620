import { MinusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { Button, Flex, Icons } from '../../components/general';
import { SlackIcon } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { appendHttpsToUrl } from '../../helpers/appendHttpsToUrl.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { RoutesConstants } from '../../helpers/routes.helpers';
import { DateService } from '../../services/date.service';
import { updateLesson } from '../../services/lesson.service';
import type { RenderColumn } from '../../types/column.types';
import { ILesson, LessonStateEnum } from '../../types/lesson.types';
import { formatGroup } from '../applicants/applicant.renderer';

export const SlackLinkLessonColumn: RenderColumn<ILesson> = (_, lesson) => {
  const slackLink = appendHttpsToUrl(lesson.group.slackLink || '');
  return (
    <a target="_blank" rel="noreferrer" href={slackLink}>
      <SlackIcon width={20}/>
    </a>
  );
};

export const StartDateLessonColumn: RenderColumn<ILesson> = (_, lesson) => {
  if (DateService.getIsSameDay(lesson.startDate)) {
    return 'today';
  }

  return DateService.getFullDate(lesson.startDate);
};

export const TimeLessonColumn: RenderColumn<ILesson> = (_, lesson) => (
  <>
    {DateService.getTimeDateWithTimeZone(lesson.startDate)} -
    {DateService.getTimeDateWithTimeZone(lesson.endDate)}
  </>
);

export const DetailsLessonColumn: RenderColumn<ILesson> = (_, lesson) => {
  return (
    <Link to={RoutesConstants.groupPage(lesson.group.id)}>
      <Icons.ShowBottom style={{ transform: 'rotate(-90deg)' }}/>
    </Link>
  );
};

export const LessonGroupColumn: RenderColumn<ILesson> = (_, lesson) => {
  return formatGroup(lesson.group,{link: true});
};

export const ActionsLessonColumn: RenderColumn<ILesson> = (_, lesson) => {
  const cache = useQueryClient();

  const updateLessonByIdMutation = useMutation(updateLesson, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.LESSONS),
  });

  const handleCompleteLesson = async (id: number) => {
    await withCatch(
      () =>
        updateLessonByIdMutation.mutateAsync({
          id,
          updatedLesson: {
            lessonState: LessonStateEnum.COMPLETED,
          },
        }),
      {
        onSuccess() {
          cache.invalidateQueries(UseQueryTypes.LESSONS);
        },
      },
    );
  };

  const handleMissLesson = async (id: number) => {
    await withCatch(
      () =>
        updateLessonByIdMutation.mutateAsync({
          id,
          updatedLesson: {
            lessonState: LessonStateEnum.MISSED,
          },
        }),
      {
        onSuccess() {
          cache.invalidateQueries(UseQueryTypes.LESSONS);
        },
      },
    );
  };

  return (
    <Spin spinning={updateLessonByIdMutation.isLoading}>
      <Flex>
        <Button onClick={() => handleCompleteLesson(lesson.id)}>
          Complete
          <PlusSquareOutlined
            style={{
              marginLeft: 10,
              fontSize: 20,
              cursor: 'pointer',
              color: '#1890ff',
            }}
          />
        </Button>
        <Button onClick={() => handleMissLesson(lesson.id)}>
          Miss
          <MinusCircleOutlined
            style={{
              fontSize: 20,
              cursor: 'pointer',
              color: 'red',
              marginLeft: 10,
            }}
            color="red"
          />
        </Button>
      </Flex>
    </Spin>
  );
};
