import { TableRowSelection } from 'antd/lib/table/interface';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../store';
import {
  applicantActions,
  selectApplicantKeys,
  selectApplicants,
} from '../store/features/applicants/applicantsSlice';
import {
  selectItemKeys,
  selectItems,
  sharedSelectsActions,
} from '../store/features/sharedSelects/sharedSelectsSlice';

const slices: { [key: string]: any } = {
  applicant: {
    action: applicantActions,
    selectKeys: selectApplicantKeys,
    select: selectApplicants,
  },
  sharedItems: {
    action: sharedSelectsActions,
    selectKeys: selectItemKeys,
    select: selectItems,
  }
}

export const useRowSelectionAdapter = <T>( type: string = 'sharedItems' ) => {
  const dispatch = useDispatch();

  const slice = slices[type];

  const selectedRowsFromStore: any[] = useAppSelector(slice.select);
  const selectedRowKeysFromStore: any[] = useAppSelector(slice.selectKeys);
  const {
    selectItems: selectItemsAction,
    selectItemsKeys: selectItemsKeysAction,
  } = slice.action;

  const getProps = (props?: TableRowSelection<T>): TableRowSelection<T> => ({
    type: 'checkbox',
    selectedRowKeys: selectedRowKeysFromStore,
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(selectItemsAction(selectedRows));
      dispatch(selectItemsKeysAction(selectedRowKeys));
    },
    ...props,
  });

  const setSelectedRowKeys = (rowKeys: number[]) => {
    dispatch(selectItemsKeysAction(rowKeys));
  }

  const isSelected = selectedRowsFromStore
    ? selectedRowsFromStore.length > 0
    : false;

  const clean = () => {
    dispatch(selectItemsAction([]));
    dispatch(selectItemsKeysAction([]));
  };

  return { getProps, selectedRows: selectedRowsFromStore, isSelected, clean, setSelectedRowKeys };
};
