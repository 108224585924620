import { DateService } from '../../../../../services/date.service';
import Flex from '../../../../general/Flex/Flex';

export function Comment({ content, createdAt, ...props }: { content: string, createdAt: string | undefined }) {
  return (
    <Flex
      justifyContent='space-between'
      style={{
        width: '100%',
        padding: '16px 0 24px 8px',
        gap: '24px'
      }}
    >
      <div
        style={{
          fontFamily: 'Barlow',
          fontStyle: 'normal',
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '20px',
          color: 'rgba(15, 23, 46, 0.5)',
        }}
        {...props}
      >
        {content}
      </div>
      <div style={{ minWidth: 'max-content' }}>{DateService.getFormattedMonth(createdAt)}</div>
    </Flex>
  );
}
