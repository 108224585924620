import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';

import { ResultSummary } from '../../../components/display';
import { ColumnModal } from '../../../components/display/ColumnSettingModal/ColumnModal';
import { Button, FilterButton } from '../../../components/general';
import GroupStatistics from '../../../components/general/Group/GroupStatistics';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useDrawer } from '../../../contexts/drawer.context';
import { useAuth } from '../../../contexts/useAuth.context';
import { AppFormsEnum } from '../../../forms';
import { useTable } from '../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { defaultTerminatedGroupColumns } from '../../../schemas/groups/terminatedGroup.schema';
import { filterGroups } from '../../../services/groups.service';
import { IUseAuth } from '../../../types/auth.types';
import { GroupFilterOptions, GroupStateEnum, IGroup } from '../../../types/group.types';
import { UserRole } from '../../../types/user.types';

const defaultGroupFilterOptions: Partial<GroupFilterOptions> = {
  sortBy: 'endDate',
  sortDirection: 'DESC',
};

export default function TerminatedGroups() {
  const { openDrawer } = useDrawer();
  const { sorterOptions, filterOptions } = useFilter(defaultGroupFilterOptions);
  const terminatedGroupTable = useTable<IGroup>(defaultTerminatedGroupColumns);
  const modalAdapter = useColumnModalAdapter(terminatedGroupTable.getTableColumnSettings());
  const { user, checkUserRole } = useAuth() as IUseAuth;

  const projectCoordinatorParam = checkUserRole(UserRole.ROLE_ADMIN) ? {} : { projectCoordinatorIds: [user?.id!] };

  const terminatedGroups = useQuery([UseQueryTypes.TERMINATED, sorterOptions, filterOptions], () =>
    filterGroups(
      {
        ...projectCoordinatorParam,
        ...filterOptions,
        groupState: [GroupStateEnum.TERMINATED],
      },
      sorterOptions,
    ),
  );

  const handleOpenGroupFilter = () => openDrawer(AppFormsEnum.FilterGroupForm, 'Filter Groups');

  const isScreenLoading = terminatedGroups.isLoading;

  const terminatedGroupsFoundCount = terminatedGroups.data?.totalElements ?? 0;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row>
          <Col span={24}>
            <Row align="middle" gutter={8}>
              <Col span={12}>
                <Row>
                  <FilterButton onClick={handleOpenGroupFilter} />
                </Row>
              </Col>

              <GroupStatistics groupStatus={GroupStateEnum.TERMINATED} />
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <ResultSummary text={`${terminatedGroupsFoundCount} result found`} />
              </Col>
              <Col>
                <Button variant="primary" onClick={modalAdapter.openSettings}>
                  Change View
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table<IGroup>
              {...terminatedGroupTable.getTableProps()}
              dataSource={terminatedGroups?.data?.groups}
              style={{ marginTop: 30 }}
              rowKey={(group) => group.id}
            />
            <Pagination
              {...terminatedGroupTable.getPaginationProps()}
              total={terminatedGroups?.data?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
