import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import CustomDateAndTimePicker from '../../components/display/CustomTimePicker/CustomTimePicker';
import { Button, Flex, Input, SingleSelectFormik, TextArea } from '../../components/general';
import { ReminderTypesOptions } from '../../constants/reminder.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId, useFilter } from '../../hooks/useFilter.hooks';
import { updateReminder } from '../../services/reminder.service';
import { IAppForm } from '../../types/form.types';
import { IReminder, IRemindersResponse, ReminderStatus, ReminderType } from '../../types/reminder.types';
import { UpdateReminderFormSchema } from './schemas/reminder.forms.schema';

const reminderFormInitialValues: Partial<IReminder> = {
  id: -1,
  title: '',
  description: '',
  status: ReminderStatus.ACTIVE,
  type: ReminderType.GROUP,
  remindingDate: new Date().toISOString(),
};

export const EditReminderForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { sorterOptions, filterOptions } = useFilter({});
  const { id: entityId } = useEntityId();

  const cache = useQueryClient();

  const remindersCache: IRemindersResponse | undefined = cache.getQueryData([
    UseQueryTypes.REMINDER,
    sorterOptions,
    filterOptions,
  ]);

  let currentReminderData = remindersCache?.reminders.find(({ id }) => id === entityId);

  const editReminderMutation = useMutation(updateReminder, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.FILTER_REMINDER);
      cache.invalidateQueries(UseQueryTypes.REMINDER_COUNT);
    },
  });

  const handleUpdateReminder = async (reminderData: Partial<IReminder>) => {
    const updatedData = {
      ...reminderData,
      ownerUuid: undefined,
    };

    const mutationFunc = () => editReminderMutation.mutateAsync(updatedData);

    await withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const isScreenLoading = editReminderMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        initialValues={currentReminderData ?? reminderFormInitialValues}
        validationSchema={UpdateReminderFormSchema}
        onSubmit={handleUpdateReminder}
      >
        {(props) => {
          return (
            <Form
              name="create-reminder"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field name="title" label="Title" component={Input} required />
              <ErrorMessage name="title" component="span" className="error" />

              <Field
                name="description"
                label="Description"
                placeholder="Enter description"
                component={TextArea}
                required
              />

              <ErrorMessage name="description" component="span" className="error" />

              <Field
                name="type"
                label="Reminder type"
                options={ReminderTypesOptions}
                component={SingleSelectFormik}
                required
              />
              <ErrorMessage name="type" component="span" className="error" />

              <CustomDateAndTimePicker
                getDate={(date) => {
                  props.setFieldValue('remindingDate', date);
                }}
              />

              <ErrorMessage name="remindingDate" component="span" className="error" />

              <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
                <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
