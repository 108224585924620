import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { validateNumber } from "../../../helpers/common.helpers";
import { CurrencyEnum } from '../../../types/currency.types';
import { PaymentEnum } from '../../../types/payment.types';

const { required, number } = messages;

export const CreatePaymentFormSchema = Yup.object().shape({
  description: Yup.string().required(required),
  date: Yup.string().required(required),
  currency: Yup.mixed<CurrencyEnum>().oneOf(Object.values(CurrencyEnum)).required(required),
  /*paymentType: Yup.mixed<PaymentEnum>()
    .oneOf(Object.values(PaymentEnum))
    .required(required),*/
  amount: Yup.string()
    .required(required)
    .test("number", number, validateNumber),
});
