import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { attachmentColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IApplicantAttachment } from '../../../../types/applicant.types';

export function AttachmentTable({ attachments }: { attachments: IApplicantAttachment[] }) {
  const table = useTable<IApplicantAttachment>(attachmentColumns);

  return (
    <Table<IApplicantAttachment>
      {...table.getTableProps({
        rowKey: ({ id }: IApplicantAttachment) => id,
        dataSource: attachments,
      })}
    />
  );
}
