import { IStudentWithAbsentees, IStudentWithExams, StudentStatusEnum } from './applicant.types';
import { CourseDurationModel, CourseFormat, ICourse } from './course.types';
import { IExam } from './exam.types';
import { ITuitionFee } from './fee.types';
import { DurationModel, FilterOptions, FilterResponse, ScheduleModel } from './general.types';
import { IInstructor, InstructorType } from './instructor.types';
import { ILesson } from './lesson.types';
import { ISalary } from './salary.types';
import { IUser } from './user.types';

export enum GroupStateEnum {
  ONGOING = 'ONGOING',
  ON_HOLD = 'ON_HOLD',
  PLANNING = 'PLANNING',
  TERMINATED = 'TERMINATED',
  WAITING_FOR_DEMO = 'WAITING_FOR_DEMO',
}

export enum SelectionStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type GroupState = keyof typeof GroupStateEnum;

export type SelectionStatus = keyof typeof SelectionStatusEnum;

export interface IGroupInstructor extends IInstructor {
  salary: ISalary;
  instructorType: InstructorType;
  instructor: IInstructor;
  name: string;
}

export interface IGroup {
  completenessPercentage: number;
  course: ICourse;
  courseFormat: CourseFormat;
  description: string;
  duration: CourseDurationModel;
  groupInstructors: IGroupInstructor[];
  groupState: GroupState;
  id: number;
  name: string;
  lessons: ILesson[];
  number: number;
  numberOfLessons: number;
  projectCoordinator: IUser;
  schedule: ScheduleModel[];
  slackLink?: string;
  endDate: string;
  startDate: string;
  tuitionFee: ITuitionFee;
  examPassThreshold: number;
  interviewPassThreshold: number;
  interviewFailThreshold: number;
  maxExamGrade: number;
  maxInterviewGrade: number;
  size: number;
  isFinal?: boolean;
  selectionStatus?: SelectionStatus;
  agreement_3_1_point: string;
  evaluationLink: string
}

export interface IGroupName {
  id: number;
  groupName: string;
  course: ICourse;
}

export interface IGroupNameOptions extends Omit<IGroupName, 'course'> {
  course?: ICourse;
}

export interface ICreateGroup {
  courseFormat: CourseFormat;
  courseId: number;
  description: string;
  duration: DurationModel;
  endDate: string;
  examPassThreshold: number;
  groupInstructors: IGroupInstructor[];
  groupState: GroupState;
  interviewFailThreshold: number;
  interviewPassThreshold: number;
  maxExamGrade: number;
  maxInterviewGrade: number;
  numberOfLessons: number;
  projectCoordinatorId: number;
  schedule: ScheduleModel[];
  slackLink?: string;
  startDate: string;
  tuitionFee: ITuitionFee;
  isFinal?: boolean;
  selectionStatus?: SelectionStatus;
  evaluationLink?: string;
  evaluationFormSpreadsheetLink?: string;
}

export interface GroupFilterModel {
  courseIds: number[];
  dateFrom: Date;
  dateTo: Date;
  groupState: GroupState[];
  instructorIds: number[];
  projectCoordinatorIds: number[];
  levels: number[];
}

export interface GroupFilterResponse extends FilterResponse {
  groups: IGroup[];
}

export type GroupFilterOptions = GroupFilterModel & FilterOptions;

export interface IGroupDetailedAbsenteeModel {
  groupId: number;
  lessons: ILesson[];
  students: IStudentWithAbsentees[];
}

export interface IGroupDetailedExamModel {
  groupId: number;
  exams: IExam[];
  students: IStudentWithExams[];
}

export type GroupCountResponse = {
  [key in GroupState]: number;
} & {
  level_0: number;
  level_1: number;
  level_2: number;
};

export type GroupApplicantsCountResponse = {
  [key in StudentStatusEnum]: number;
};

export enum InterviewTemplateEnum {
  PRO_COURSES = 'PRO_COURSES',
  INTERNSHIPS = 'INTERNSHIPS',
  INTRO_COURSES_NO_EXAM = 'INTRO_COURSES_NO_EXAM',
  INTRO_COURSES_WITH_EXAM = 'INTRO_COURSES_WITH_EXAM',
}

export interface IInterviewSchedule {
  groupId: number;
  template: InterviewTemplateEnum;
  sheetId: string;
}
