import { isEqual } from 'lodash';

export const pickChangedValues = (initialValues: { [key: string ]: any }, values: { [key: string ]: any }) => {
    const data: { [key: string ]: any } = { }

    for (const prop in values) {
        if (!isEqual(initialValues[prop], values[prop])){
            data[prop] = values[prop]
        }
    }
    return data;
}