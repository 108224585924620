import cn from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Next } from '../../../../../../components/general/Icons/Icons';
import { IMenuGroups } from '../../../../types/dashboard.types';
import { IDashboardLinkItem } from '../../../../types/path.types';
import MenuItem from './renderer.menu';
import { SimpleLink } from './simpleLink';

type IGroupsProps = {
  menuGroups: IMenuGroups;
  setShowGroups: (name: string) => void
  item: IDashboardLinkItem;
}

export const DashboardMenu = ({ menuGroups, setShowGroups, item }: IGroupsProps) => {
  const { name, items } = item;
  const { pathname } = useLocation();
  const isGroupsPage = pathname.includes(name);

  useEffect(() => {
    (!menuGroups[name as keyof typeof menuGroups] && isGroupsPage) && setShowGroups(name)
  },[])

  return (
    <div
      className={cn('nav-link', 'dropdown-link', {
        active: isGroupsPage,
      })}
      onClick={() => setShowGroups(name)}
    >
      {name[0].toUpperCase() + name.slice(1)}
      <Next
        style={{
          position: 'absolute',
          right: 20,
          transform: menuGroups[name as keyof typeof menuGroups] ? 'rotate(90deg)' : 'none',
        }}
        width={6}
        height={10}
      />
      {menuGroups[name as keyof typeof menuGroups] && (
        <nav
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="dropdown-nav"
        >
          {
            Object.values(items || {}).map((item) => <SimpleLink key={item.id} item={item} isGroupItem={true} />)
          }
          <MenuItem name={name}/>
        </nav>
      )}
    </div>
  )
};

export default DashboardMenu;
