import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Loading } from '../components/general/Loading/Loading';
import { SocketProvider } from '../contexts/socket.context';
import { useAuth } from '../contexts/useAuth.context';
import { RoutesConstants } from '../helpers/routes.helpers';
import StudentAuthenticatedApp from '../modules/student/routes/StudentAuthenticatedApp';
import UserAuthenticatedApp from '../modules/user/routes/UserAutenticatedApp.routes';
import { AuthGroup } from '../types/auth.types';
import InvestorAuthenticatedApp from './roleBase/Instructor.routes';
import UnAuthenticatedApp from './UnAuthenticatedApp.routes';

export const AppRoutes = () => {
  const { user } = useAuth();

  const getAppBasedOnAuth = () => {
    if (user === null) return <Loading />;
    if (user?.authGroup === AuthGroup.INSTRUCTOR) return <InvestorAuthenticatedApp />;
    if (user?.authGroup === AuthGroup.STUDENT) return <StudentAuthenticatedApp />;
    if (!user) return <UnAuthenticatedApp />;
    if (user)
      return (
        <SocketProvider>
          <UserAuthenticatedApp />
        </SocketProvider>
      );
  };

  return (
    <Routes>
      <Route path={RoutesConstants.fallback()} element={getAppBasedOnAuth()} />
    </Routes>
  );
};
