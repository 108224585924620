import { Avatar, Button } from 'antd';

import { getAvatar } from '../../../../helpers/avatar.helper';
import styles from './UserProfile.module.css';

export default function UserProfileLogo({
  avatarLink,
  handleClick,
  style = {},
}: {
  avatarLink?: string;
  handleClick?: () => void;
  style?: React.CSSProperties;
}) {
  return (
    <div>
      <Button onClick={handleClick} className={styles.button} style={style}>
        <Avatar src={getAvatar(avatarLink)} />
      </Button>
    </div>
  );
}
