import { PaginationProps, TableProps } from 'antd';
import { ColumnType, SorterResult } from 'antd/lib/table/interface';
import { produce } from 'immer';

import { sortersDefaultOptions } from '../../constants/sorters.constants';
import { mapSortDirection } from '../../helpers/sortDirection.helper';
import { useFilter } from '../useFilter.hooks';

export const useTablePagination = <T>(defaultColumns?: ColumnType<T>[]) => {
  const { changeOptions, sorterOptions } = useFilter(sortersDefaultOptions);

  const getPaginationProps = (
    props?: Partial<PaginationProps>,
  ): PaginationProps => ({
    size: 'small',
    showSizeChanger: true,
    defaultPageSize: sorterOptions.pageSize,
    current: Number(sorterOptions.pageNumber) + 1, //  add 1 to the idx to get fake page number
    showTotal: (total) => `Total ${total} items`,
    onChange: (pageNumber, pageSize) => {
      const newSorterOptions = produce(sorterOptions, (draft) => {
        draft.pageSize = pageSize;
        draft.pageNumber = Number(pageNumber) - 1; // subtract 1 to get the original idx
      });
      changeOptions({ sorterOptions: newSorterOptions });
    },
    ...props,
  });

  const getTableProps = (props?: TableProps<T>): TableProps<T> => ({
    pagination: false,
    columns: defaultColumns,
    onChange(_antPagination, _antFilters, antSorters) {
      const newSorterOptions = produce(sorterOptions, (draft) => {
        antSorters = antSorters as SorterResult<T>;
        // @TODO: figure out the sortBy type

        if (Array.isArray(antSorters.field)){
          draft.sortBy = antSorters.field[0];
          draft.relationalSort = !!antSorters.field[1];
        } else {
          // @ts-ignore
          draft.sortBy = antSorters.field;
          draft.relationalSort = undefined;
        }

        draft.sortDirection = mapSortDirection(antSorters.order);

        // eslint-disable-next-line no-console
        console.log({_antPagination,_antFilters,antSorters})
      });
      changeOptions({ sorterOptions: newSorterOptions });
    },
    ...props,
  });

  return {
    sorterOptions,
    getPaginationProps,
    getTableProps,
  };
};
