import { IStudentGivenFeedback } from '../../modules/student/types/studentFeedback.types';
import type { ColumnSchema } from '../../types/column.types';
import { DateColumn, RatingColumn,StudentNameColumn } from './studentFeedbacks.renderer';

export const defaultStudentFeedbacksColumns = (
  examsData: IStudentGivenFeedback[] | undefined,
): ColumnSchema<any> => ({
  uuid: 'defaultStudentFeedbacksColumns',
  schema: {
    applicant: {
      title: 'Student',

      width: 150,
      fixed: 'left',
      sorter: () => -1,
      render: StudentNameColumn,
    },
    created_at: {
      title: 'Created Date',
      width: 100,
      render: DateColumn
    },
    content: {
      title: 'Content',
      width: 300,
      render: (content) => content
    },
    rating: {
      title: 'Rating',
      width: 40,
      render: RatingColumn
    }
    
    // phone: {
    //   title: 'Phone',
    //   width: 300,
    //   render: ExamColumnDynamicRenderer(ExamColumnNames.PhoneNumber),
    // },
    // exams: {
    //   title: 'Exams',
    //   children: examsData?.map((examData) => ({
    //     title: `${upperFirstLetter(examData.examType)} exam`,
    //     children: [
    //       {
    //         title: 'Date',
    //         width: 150,
    //         render: ExamDateColumn(examData),
    //       },
    //       {
    //         title: 'Status',
    //         width: 200,
    //         render: StudentExamGradeAndStatusColumn(examData),
    //       },
    //     ],
    //   })),
    // },

    // finalScore: {
    //   title: 'Final Score',
    //   width: 200,
    //   render: StudentFinalScoreExamsColumn(examsData),
    // },
    // status: {
    //   title: 'Final Status',
    //   width: 250,
    //   render: ExamColumnStatus(examsData),
    // },
  },
});
