import { AttachmentTypeEnum, LabelNotes } from '../types/attachment.types';

export const AttachmentTypeOptions: { [key: string]: { value: AttachmentTypeEnum; label: string } } = {
  [AttachmentTypeEnum.AGREEMENT]: {
    value: AttachmentTypeEnum.AGREEMENT,
    label: 'Agreement',
  },
  [AttachmentTypeEnum.CERTIFICATE]: {
    value: AttachmentTypeEnum.CERTIFICATE,
    label: 'Certificate',
  },
  [AttachmentTypeEnum.IMAGE]: {
    value: AttachmentTypeEnum.IMAGE,
    label: 'Image',
  },
  [AttachmentTypeEnum.INVOICE]: {
    value: AttachmentTypeEnum.INVOICE,
    label: 'Invoice',
  },
};

export const AttachmentLabelNotes: LabelNotes  = {
  CV: "(You can upload only PDF files)",
  DEFAULT: '(You can upload PNG, JPG, and PDF files)',
}
