import { RouteParam } from '../helpers/routes.helpers';
import { ITrack } from '../types/track.types';
import axios from './config.service';

export const getAllTracks = () => {
  return axios.get('/track').then((r) => r.data) as Promise<ITrack[]>;
};

export const getTrackById = (id: RouteParam) => {
  return axios.get(`/track/${id}`).then((r) => r.data) as Promise<ITrack>;
};

export const createTrack = async (name: string) => {
  const r = await axios.post('/track', { name });
  return r.data;
};

export const deleteTrack = async (id: RouteParam) => {
  const r = await axios.delete(`/track/${id}`);
  return r.data;
};

export const searchTrack = async (trackName: string) => {
  const r = await axios.get(`/track/search?trackName=${trackName}`);
  return r.data;
};

export const updateTrack = async ({
  id,
  name,
}: {
  id: RouteParam;
  name: string;
}) => {
  const r = await axios.put(`/track/${id}`, {
    name,
  });
  return r.data;
};
