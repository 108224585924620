/* eslint-disable react-hooks/exhaustive-deps */
import { MinusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Col, Pagination, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Button, Flex, Widget } from '../../components/general';
import { getButtonVariant } from '../../components/general/Button/Button';
import { lessonDates } from '../../constants/lesson.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useAuth } from '../../contexts/useAuth.context';
import { withCatch } from '../../helpers/error.helpers';
import { RoutesConstants } from '../../helpers/routes.helpers';
import { useTable } from '../../hooks/table/useTable';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { formatGroup } from '../../schemas/applicants/applicant.renderer';
import { defaultLessonColumns } from '../../schemas/lessons/lesson.schema';
import { filterGroups, getGroupsCount } from '../../services/groups.service';
import {
  bulkUpdateLessons,
  filterLessons,
} from '../../services/lesson.service';
import { countTasks } from '../../services/tasks.service';
import { FilterOptions, PeriodEnum } from '../../types/general.types';
import { GroupStateEnum, IGroup } from '../../types/group.types';
import {
  ILesson,
  ILessonsUpdate,
  LessonStateEnum,
} from '../../types/lesson.types';
import { UserRole } from '../../types/user.types';

const { Title } = Typography;

const WidgetInfo = React.memo(() => {
  const { user } = useAuth();
  const userId = user?.id as number;

  const tasksQuery = useQuery(
    [UseQueryTypes.TASKS_COUNT],
    () => countTasks([userId]),
    {
      enabled: Boolean(userId),
    },
  );

  const countResult = useQuery([UseQueryTypes.GROUP_COUNT_INFO], () =>
    getGroupsCount({
      groupBy: 'groupState',
      groupState: [
        GroupStateEnum.ONGOING,
        GroupStateEnum.PLANNING,
        GroupStateEnum.ON_HOLD,
      ],
    }),
  );

  const widgetData = [
    {
      id: 0,
      title: 'Tasks',
      bgColor: 'blue',
      count: tasksQuery.data?.length ? tasksQuery.data[0]['count'] : 0,
      link: RoutesConstants.tasks(),
    },
    {
      id: 1,
      title: 'Planning Groups',
      count:
        (countResult?.data?.PLANNING || 0) + (countResult?.data?.ON_HOLD || 0),
      bgColor: 'green',
      link: RoutesConstants.planningGroups(),
    },
    {
      id: 2,
      title: 'Ongoing Groups',
      count: countResult.data?.ONGOING ?? 0,
      bgColor: 'violet',
      link: RoutesConstants.ongoingGroups(),
    },
  ];

  return (
    <>
      {widgetData.map((item) => {
        const numberItemCount = Number(item.count);

        return (
          <Widget
            key={item.id}
            title={item.title}
            bgColor={item.bgColor}
            link={item.link}
            count={numberItemCount}
          />
        );
      })}
    </>
  );
});

export default function Dashboard() {
  const cache = useQueryClient();

  const { user, checkUserRole } = useAuth();

  const [lessonFilterPeriod, setLessonFilterPeriod] = useState(
    PeriodEnum.TODAY,
  );

  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const rowSelectionAdapter = useRowSelectionAdapter<ILesson>();

  const [lessonsQuery, setLessonsQuery] = useState<any>();

  const [dateFrom, dateTo] = lessonDates[lessonFilterPeriod].value;

  const { filterOptions, sorterOptions, changeOptions } = useFilter<{
    [key: string]: any;
  }>({
    lessonState: LessonStateEnum.UPCOMING,
    dateFrom: lessonDates[PeriodEnum.TODAY]['value'][0],
    dateTo: lessonDates[PeriodEnum.TODAY]['value'][1],
  });

  const isUserProgramManager = checkUserRole(UserRole.ROLE_PROGRAM_MANAGER);

  const lessonTable = useTable(defaultLessonColumns);

  const userOwnedGroups = useQuery([UseQueryTypes.FILTER_COURSES], () =>
    filterGroups({
      projectCoordinatorIds: [user?.id as number],
      groupState: [GroupStateEnum.ONGOING],
    }),
  );

  const userOwnedGroupsData = userOwnedGroups.data?.groups;

  const lessonQueryMutation = useMutation(filterLessons);

  const defaultGroupListener = () => {
    if (userOwnedGroups.isSuccess && isUserProgramManager) {
      const firstUserOwnedGroupId = userOwnedGroups.data.groups[0]?.id;

      if (firstUserOwnedGroupId) {
        setSelectedGroupId(firstUserOwnedGroupId);
      }
    }
  };

  const lessonListener = () => {
    const options = {
      lessonState: LessonStateEnum.UPCOMING,
      dateFrom,
      dateTo,
    };

    if (isUserProgramManager && selectedGroupId) {
      Object.assign(options, { groupId: selectedGroupId });
    }

    if (
      (isUserProgramManager && selectedGroupId) ||
      (!isUserProgramManager && !selectedGroupId)
    ) {
      changeOptions({
        filterOptions: options,
      });

      lessonRequest(options);
    }
  };

  const lessonRequest = (
    filters: Partial<FilterOptions> | null = null,
    sorters: Partial<FilterOptions> | null = null,
  ) => {
    if (
      (isUserProgramManager && selectedGroupId) ||
      (!isUserProgramManager && !selectedGroupId)
    ) {
      withCatch(
        () =>
          lessonQueryMutation.mutateAsync({
            filters: filters || filterOptions,
            sorters: sorters || sorterOptions,
          }),
        {
          hideMessage: !lessonsQuery?.lessons?.length,
          onSuccess: (data) => setLessonsQuery(data),
        },
      );
    }
  };

  useEffect(defaultGroupListener, [userOwnedGroups.isSuccess]);

  useEffect(lessonListener, [selectedGroupId, lessonFilterPeriod]);

  const updateLessonByIdMutation = useMutation(bulkUpdateLessons, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.LESSONS),
  });

  const handleSelectGroup = (groupData: IGroup) => {
    return () => {
      setSelectedGroupId(groupData.id);
      changeOptions({ filterOptions: { groupId: groupData.id } });
    };
  };

  const handleLessonsState = async (bulkCompletedLessons: ILessonsUpdate[]) => {
    await withCatch(
      () => updateLessonByIdMutation.mutateAsync(bulkCompletedLessons),
      {
        onSuccess() {
          cache.invalidateQueries(UseQueryTypes.LESSONS);
        },
      },
    );

    rowSelectionAdapter.clean();
  };

  const isScreenLoading =
    lessonQueryMutation.isLoading || userOwnedGroups.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Title level={3}>Widgets</Title>
        <Row gutter={24}>
          <WidgetInfo/>
        </Row>

        <Row style={{ marginTop: 50 }} gutter={24}>
          <Col span={24}>
            {userOwnedGroupsData?.length && isUserProgramManager ? (
              <>
                <Title level={3}>Ongoing Groups</Title>
                <Row>
                  {userOwnedGroupsData.map((group) => (
                    <Flex
                      key={group.id}
                      style={{ marginRight: '5px', marginBottom: '5px' }}
                    >
                      <Button
                        onClick={handleSelectGroup(group)}
                        variant={getButtonVariant(selectedGroupId, group.id)}
                      >
                        {formatGroup(group)}
                      </Button>
                    </Flex>
                  ))}
                </Row>
              </>
            ) : null}
            <Title level={3} style={{ marginTop: '10px' }}>
              Upcoming Lessons
            </Title>
            <Row style={{ marginBottom: 20 }}>
              {Object.entries(lessonDates).map(([period, { label }]) => (
                <Col key={period} lg={4} sm={8} style={{ marginRight: 10 }}>
                  <Button
                    variant={getButtonVariant(lessonFilterPeriod, period)}
                    onClick={() => setLessonFilterPeriod(period as PeriodEnum)}
                  >
                    {label}
                  </Button>
                </Col>
              ))}
            </Row>
            {rowSelectionAdapter.isSelected && (
              <Row style={{ marginBottom: 20 }}>
                <Flex>
                  <Button
                    onClick={() =>
                      handleLessonsState(
                        rowSelectionAdapter.selectedRows.map(({ id }) => ({
                          id,
                          lessonState: LessonStateEnum.COMPLETED,
                        })),
                      )
                    }
                  >
                    Complete
                    <PlusSquareOutlined
                      style={{
                        marginLeft: 10,
                        fontSize: 20,
                        cursor: 'pointer',
                        color: '#1890ff',
                      }}
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      handleLessonsState(
                        rowSelectionAdapter.selectedRows.map(({ id }) => ({
                          id,
                          lessonState: LessonStateEnum.MISSED,
                        })),
                      )
                    }
                  >
                    Miss
                    <MinusCircleOutlined
                      style={{
                        fontSize: 20,
                        cursor: 'pointer',
                        color: 'red',
                        marginLeft: 10,
                      }}
                      color="red"
                    />
                  </Button>
                </Flex>
              </Row>
            )}
            <Table<ILesson>
              {...lessonTable.getTableProps({
                rowKey: (lesson) => lesson.id,
                dataSource: lessonsQuery?.lessons,
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />
            <Pagination
              {...lessonTable.getPaginationProps({
                style: { marginTop: 20 },
                total: lessonsQuery?.totalElements,
              })}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
