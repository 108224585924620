import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, Flex, SingleSelectFormik } from '../../components/general';
import { TASK_STATUS } from '../../constants/task.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { bulkUpdateTasks } from '../../services/tasks.service';
import { useAppSelector } from '../../store';
import { selectItems } from '../../store/features/sharedSelects/sharedSelectsSlice';
import type { IAppForm } from '../../types/form.types';
import { ITaskGET } from '../../types/task.types';
import { BulkUpdateTaskStatusFormSchema } from './schemas/transformTask.form.schema';

export const BulkUpdateTaskStatusForm: IAppForm = () => {
  const rowSelectionAdapter = useRowSelectionAdapter<ITaskGET>();
  const selectedReminders = useAppSelector(selectItems);
  const cache = useQueryClient();

  const { closeDrawer } = useDrawer();

  const bulkUpdateMutation = useMutation(bulkUpdateTasks);

  const handleBulkUpdateState = async (
    { taskStatus }: Partial<ITaskGET>,
    formikHelpers: FormikHelpers<Partial<ITaskGET>>,
  ) => {
    const updateQuery: Partial<ITaskGET>[] = selectedReminders.map(({ id }) => ({ id, taskStatus }));

    await withCatch(() => bulkUpdateMutation.mutateAsync(updateQuery), {
      onSuccess: async () => {
        formikHelpers.resetForm();
        await cache.invalidateQueries(UseQueryTypes.TASKS);
        await cache.invalidateQueries(UseQueryTypes.CREATED_TASKS);
        closeDrawer();
      },
      onError: () => {
        closeDrawer();
      },
    });
    await rowSelectionAdapter.clean();
  };

  return (
    <Spin spinning={bulkUpdateMutation.isLoading}>
      <Formik
        initialValues={{}}
        validationSchema={BulkUpdateTaskStatusFormSchema}
        enableReinitialize
        onSubmit={handleBulkUpdateState}
      >
        {({ dirty, isValid, handleReset }) => (
          <Form
            name="bulk-update"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field
              name="taskStatus"
              label="Task Status"
              options={TASK_STATUS}
              component={SingleSelectFormik}
              required
            />
            <ErrorMessage name="taskStatus" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => handleReset()}>
                Reset
              </Button>
              <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary" disabled={!(dirty && isValid)}>
                Save
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
