import { Pagination, Spin, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { useTable } from '../../../../../hooks/table/useTable';
import { defaultStudentFeedbacksColumns } from '../../../../../schemas/studentFeedbacks/studentFeedbacks.schema';
import { getStudentFeedbacksByGroup } from '../../../../../services/student.service';

const { Text } = Typography;

export const StudentFeedbacks = () => {
  const { id } = useParams() as { id: string };

  const detailedGroupFeedbacksInfo = useQuery(
    UseQueryTypes.STUDENT_FEEDBACKS,
    () => getStudentFeedbacksByGroup(Number(id)),
  );


  const feedbacks = detailedGroupFeedbacksInfo.data?.feedbacks;
  const feedbacksTable = useTable(defaultStudentFeedbacksColumns(feedbacks));

  const isScreenLoading =
  detailedGroupFeedbacksInfo.isLoading

  const feedbacksCount =
  feedbacks?.length ?? 0;

  return (
    <Spin spinning={isScreenLoading}>
      <Text type="secondary" style={{ marginBottom: 20 }}>{feedbacksCount} result found</Text>
      <Table<any>
        scroll={{
          x: 1000,
        }}
        {...feedbacksTable.getTableProps({
          rowKey: (r) => r.id,
          bordered: true,
          dataSource: feedbacks,
        })}
      />
      <Pagination
        {...feedbacksTable.getPaginationProps({
          style: { marginTop: 20 },
          total: feedbacks?.length,
        })}
      />
    </Spin>
  );
};
