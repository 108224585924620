import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  getConsultantById,
  updateConsultation,
} from '../../services/consultant.service';
import type { IUpdateConsultation } from '../../types/consultation.types';
import type { IAppForm } from '../../types/form.types';
import { TransformConsultantForm } from './shared/transformConsultant.form';

export const EditConsultantForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: consultantId } = useEntityId();
  const cache = useQueryClient();

  const updateConsultantMutation = useMutation(updateConsultation);

  const consultantQuery = useQuery(
    [UseQueryTypes.CONSULTANT_ID, consultantId],
    () => getConsultantById(consultantId),
  );

  const initialValues = useMemo<Partial<IUpdateConsultation>>(() => {
    const consultant = consultantQuery.data;
    if (!consultant) return {};
    return consultant;
  }, [consultantQuery.data]);

  const handleSubmit = async (
    updatedConsultant: Partial<IUpdateConsultation>,
  ) => {
    // @TODO refactor this sh*t code
    const { name, email, phoneNumber, isDone, source } = updatedConsultant;

    const mutationFunc = () =>
      updateConsultantMutation.mutateAsync({
        id: consultantId,
        consultant: {
          name,
          email,
          phoneNumber,
          isDone,
          source,
        } as IUpdateConsultation,
      });

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.CONSULTANTS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={updateConsultantMutation.isLoading}>
      <TransformConsultantForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};
