import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, CustomDatePickerFormik, Flex } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { updateApplicantPaymentByPaymentId } from '../../services/payment.service';
import { IAppForm } from '../../types/form.types';
import { IEditPaymentDate, IUpdateGroupPayment } from '../../types/payment.types';
import { EditPaymentDateFormSchema } from './schemas/editPaymentDateForm.schema';

export const EditApplicantPaymentDateForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { editId: paymentId, filterOptions } = useFilter({ applicantId: 0 });
  const cache = useQueryClient();
  const applicantPayment = useMutation(updateApplicantPaymentByPaymentId, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.APPLICANT_PAYMENT),
  });

  const handleEditPaymentDate = async (payment: Partial<IEditPaymentDate>) => {
    payment.applicantId = filterOptions.applicantId;
    const mutationFunc = () =>
      applicantPayment.mutateAsync({ payment, id: paymentId } as {
        payment: IUpdateGroupPayment;
        id: number;
      });

    await withCatch(mutationFunc, {
      onSuccess: () => {
        closeDrawer();
        cache.invalidateQueries(UseQueryTypes.APPLICANT_PAYMENT);
        cache.invalidateQueries(UseQueryTypes.GROUP_PAYMENT);
      },
    });
  };

  const isScreenLoading = applicantPayment.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik initialValues={{}} validationSchema={EditPaymentDateFormSchema} onSubmit={handleEditPaymentDate}>
        {(props) => (
          <Form
            name="create-payment"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="date" label="Date of payment" component={CustomDatePickerFormik} required />
            <ErrorMessage name="date" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
