import { Modal } from 'antd';

import { upperFirstLetter } from '../../../helpers/string.helpers';
import { ConfirmModalParameterTypes } from '../../../types/modal.types';

export function ConfirmModal({
  isModalVisible,
  title,
  content,
  handleTxt,
  handleSubmit,
  onCancel,
}: ConfirmModalParameterTypes) {
  const children: JSX.Element[] = Object.keys(content).map((key: string) => (
    <p>
      <span>{upperFirstLetter(key)}:</span>
      <b> {content[key]}</b>
    </p>
  ));

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      onOk={handleSubmit}
      title={<h3>{title}</h3>}
      okText={handleTxt}
      width={600}
    >
      {children}
    </Modal>
  );
}
