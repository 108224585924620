import { Filter } from '../Icons/Icons';
import styles from './FilterButton.module.css';

interface IFilterButtonProps {
  onClick: () => void;
}

export default function FilterButton({ onClick }: IFilterButtonProps) {
  return (
    <button style={{ minHeight: '46px', minWidth: '46px' }} onClick={onClick} className={styles.filter}>
      <Filter />
    </button>
  );
}
