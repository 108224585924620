import { stringifyObject } from '../helpers/stringifyObj.helper';
import { IComment, ICreateComment } from '../types/comment.types';
import { Sorters } from '../types/general.types';
import {
  ICreateTask,
  ITaskBulkUpdate,
  ITaskGET,
  ITaskPOST,
  TaskFilterModel,
  TaskFilterResponse,
} from '../types/task.types';
import axios from './config.service';

export function getAllTasks() {
  return axios.get(`/task`).then((r) => r.data) as Promise<ITaskGET[]>;
}

export function createTask(task: ICreateTask) {
  return axios.post('/task', task).then((r) => r.data) as Promise<ITaskGET>;
}

export function getTaskById(id: number) {
  return axios.get(`/task/${id}`).then((r) => r.data) as Promise<ITaskGET>;
}

export function updateTaskById({ id, task }: { id: number; task: Partial<ITaskPOST> }) {
  return axios.put(`/task/${id}`, task).then((r) => r.data) as Promise<ITaskGET>;
}

export function deleteTaskById(id: number) {
  return axios.delete(`/task/${id}`).then((r) => r.data) as Promise<boolean>;
}

export function bulkUpdateTasks(editedTasks: Partial<ITaskBulkUpdate>[]) {
  return axios.put('/task/bulk', editedTasks).then((r) => r.data) as Promise<ITaskGET>;
}

export function filterTasks(filters: Partial<TaskFilterModel> = {}, sorters: Partial<Sorters> = {}) {
  const queryString = stringifyObject(sorters);
  return axios.post(`/task/filter?${queryString}`, filters).then((r) => r.data) as Promise<TaskFilterResponse>;
}

export function countTasks(ownersIds: number[]) {
  return axios.post(`/task/count`, { ownersIds }).then((r) => r.data) as Promise<{ count: number | boolean }[]>;
}

export function getTaskComments(id: number) {
  return axios.get(`/task/${id}/comments/`).then((r) => r.data) as Promise<any>;
}

export async function addTaskComment({ taskId, comment }: { taskId: number; comment: ICreateComment }) {
  const r = await axios.post(`/task/${taskId}/comment`, comment);
  return r.data as Promise<IComment[]>;
}

export async function updateTaskComment({ commentId, comment }: { commentId: number; comment: ICreateComment }) {
  const r = await axios.put(`/task/comment/${commentId}`, comment);
  return r.data as Promise<IComment[]>;
}

export const exportTasks = async (ids: number[]) => {
  return await axios.post(
    `task/export`,
    { id: ids },
    {
      responseType: 'blob',
    },
  );
};
