import { Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { Button, Flex, Input } from '../../../components/general';
import { useAuth } from '../../../contexts/useAuth.context';
import { withCatch } from '../../../helpers/error.helpers';
import { updateInstructorPassword } from '../../../services/instructors.service';
import { updateUserPassword } from '../../../services/user.service';
import { IResetPassword, IUser } from '../../../types/user.types';
import styles from '../Account.module.css';

const UpdateType: {
  [key: string]: ({ passwords, id }: { passwords: IResetPassword; id: number }) => Promise<any>;
} = {
  USER: updateUserPassword,
  INSTRUCTOR: updateInstructorPassword,
};

type Props = {
  userType: string;
  isMobileSize: boolean;
};

export const PasswordChangePage = ({ userType, isMobileSize }: Props) => {
  const { user, logOut } = useAuth() as { user: IUser; logOut: () => void };
  const updatePasswordMutation = useMutation(UpdateType[userType]);

  const handleUpdatePassword = async (passwords: IResetPassword) => {
    await withCatch(() => updatePasswordMutation.mutateAsync({ passwords, id: user.id }), {
      onSuccess: () => {
        logOut();
      },
    });
  };

  const initialValues: IResetPassword = {
    confirmPassword: '',
    newPassword: '',
    oldPassword: '',
  };

  return (
    <Formik onSubmit={(values) => handleUpdatePassword(values)} enableReinitialize initialValues={initialValues}>
      <Form style={{ width: isMobileSize ? '100%' : '77%' }}>
        <div className={styles.mainBar}>
          <Flex
            direction={isMobileSize ? 'column' : 'row'}
            alignItems={isMobileSize ? 'flex-start' : 'center'}
            justifyContent="space-between"
            className={styles.titleBar}
          >
            <p className={styles.title}>Change Password</p>
            <Flex
              alignItems="center"
              justifyContent={isMobileSize ? 'flex-end' : 'space-between'}
              className={styles.barBtns}
            >
              <Button style={{ minWidth: 150 }} variant="primary">
                Save Changes
              </Button>
            </Flex>
          </Flex>
          <div className={styles.content}>
            <Row>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field type="password" name="oldPassword" label="Current Password" component={Input} />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field type="password" name="newPassword" label="New Password" component={Input} />
              </Col>
              <Col lg={{ span: 16 }} xs={{ span: 24 }}>
                <Field type="password" name="confirmPassword" label="Confirm Password" component={Input} />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
