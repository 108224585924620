import {
  addDays,
  compareAsc,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatDistanceToNow,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { DateFnsFormats, DateRegEx } from '../constants/dateFns.constants';

export class DateService {
  static getServerDate(date?: string) {
    const serverDate = date ? new Date(date) : new Date();
    return format(serverDate, DateFnsFormats.server);
  }

  static getDateFromServer(date?: string) {
    return date ? new Date(date) : new Date();
  }

  static getFullDate(date?: string) {
    const fullDate = date ? new Date(date.replace(DateRegEx, '')) : new Date();
    return format(fullDate, DateFnsFormats.full);
  }

  static getFullDateByFormat(date?: string, dateFormat?: string) {
    const fullDate = date ? new Date(date.replace(DateRegEx, '')) : new Date();
    const fullFormat = dateFormat ?? DateFnsFormats.normal;
    return format(fullDate, fullFormat);
  }

  static getTimeDate(date?: string | Date | null) {
    const fullDate = date ? new Date(date) : new Date();
    return format(fullDate, DateFnsFormats.time);
  }

  static getFormattedDateTime(date?: string | Date | null) {
    const fullDate = date ? new Date(date) : new Date();
    return format(fullDate, DateFnsFormats.fullDate);
  }

  static getFormattedMonth(date?: string | Date | null) {
    const fullDate = date ? new Date(date) : new Date();
    return format(fullDate, DateFnsFormats.month);
  }

  static getDate(date?: Date) {
    const fullDate = date ? date : new Date();
    return format(fullDate, DateFnsFormats.full);
  }

  static getDateServer(date?: Date) {
    const fullDate = date ? date : new Date();
    return format(fullDate, DateFnsFormats.server);
  }

  static getOtherDate(i: number) {
    return format(addDays(new Date(), i), DateFnsFormats.server);
  }

  static getStartOfWeekDate() {
    return format(startOfWeek(new Date(), { weekStartsOn: 1 }), DateFnsFormats.server);
  }

  static getEndOfWeekDate() {
    return format(endOfWeek(new Date(), { weekStartsOn: 1 }), DateFnsFormats.server);
  }

  static getISODate(date: Date | string) {
    return formatISO(new Date(date));
  }

  static getISODateShort(date: Date | string) {
    const formatTime = (timeString: string) => {
      return timeString.split(':').reduce((acc, part, index) => {
        if (!index) {
          acc += part;
        }
        if (index === 1) {
          acc += `:${part}`;
        }
        return acc;
      }, '');
    };

    return formatTime(formatISO(new Date(date)));
  }

  static getStartOfMonthDate() {
    return format(startOfMonth(new Date()), DateFnsFormats.server);
  }

  static getEndOfMonthDate() {
    return format(endOfMonth(new Date()), DateFnsFormats.server);
  }

  static getWeekDays(i: number) {
    return format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), i), DateFnsFormats.week);
  }

  static getIsSameDay(date?: string) {
    const fullDate = date ? new Date(date) : new Date();
    return isSameDay(new Date(fullDate), startOfDay(new Date()));
  }

  static getParseDate(date?: string) {
    const fullDate = date ? date : '';
    return parse(fullDate, DateFnsFormats.time, new Date());
  }

  static getFormatDistanceToNow(date?: string) {
    const fullDate = date ? new Date(date) : new Date();

    return formatDistanceToNow(fullDate, {
      addSuffix: true,
    });
  }

  static getStartOfDay(date?: string) {
    const fullDate = date ? new Date(date) : new Date();
    return startOfDay(new Date(fullDate));
  }

  static getTimeDateWithTimeZone(date?: string | Date | null) {
    const fullDate = date ? new Date(date) : new Date();
    return formatInTimeZone(fullDate, 'GMT', DateFnsFormats.time);
  }

  static getEndOfDay(date?: string) {
    const fullDate = date ? new Date(date) : new Date();
    return endOfDay(new Date(fullDate));
  }

  static compareDateStringAsc(date1: string, date2: string) {
    return compareAsc(DateService.getParseDate(date1), DateService.getParseDate(date2));
  }

  static compareDates(date1: string, date2: string) {
    return compareAsc(new Date(date1), new Date(date2));
  }

  static isBeforeDate(date1: string, date2: string) {
    return isBefore(DateService.getParseDate(date1), DateService.getParseDate(date2));
  }

  static isBetween(date: string, from: string, to: string) {
    const fromDate: Date = new Date(from);
    const toDate: Date = to ? new Date(to) : new Date();
    const currentDate: Date = new Date(date);

    return (
      (isEqual(fromDate, currentDate) || isBefore(fromDate, currentDate)) &&
      (isEqual(toDate, currentDate) || isAfter(toDate, currentDate))
    );
  }
}
