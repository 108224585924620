import { PhoneService } from '../../services/phone.service';
import type { ColumnSchema } from '../../types/column.types';
import { IUser } from '../../types/user.types';
import { customHeading } from "./customHeading";
import styles from './user.module.css'
import {
  AvatarColumn,
  DeleteUserColumn,
  EditUserColumn,
  EmailUserColumn,
  RolesUserColumn, Team
} from './user.renderer';

export const defaultUserColumns: ColumnSchema<IUser> = {
  uuid: 'defaultUserColums',
  schema: {
    avatar: {
      title: customHeading('User'),
      render: AvatarColumn,
      width: 350,
      className: styles.overwrite,
    },
    phoneNumber: {
      title: customHeading('Phone'),
      width: 150,
      render: (_, user) => PhoneService.normalize(user.phoneNumber),
      className: styles.overwrite,
    },
    email: {
      title: customHeading('Email'),
      width: 150,
      render: EmailUserColumn,
      className: styles.overwrite,
    },
    team: {
      title: customHeading('Team'),
      width: 100,
      render: Team,
      className: styles.overwrite,
    },
    roles: {
      title: customHeading('User Roles'),
      width: 200,
      render: RolesUserColumn,
      className: styles.overwrite,
    },
    edit: {
      width: 50,
      render: EditUserColumn,
      className: styles.overwrite,
    },
    delete: {
      dataIndex: 'delete',
      width: 30,
      render: DeleteUserColumn,
      className: styles.overwrite,
    },
  },
};
