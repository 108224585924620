import { Checkbox, Col, Row, Spin } from 'antd';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  Button,
  CustomDatePickerFormik,
  Icons,
  Input,
  SingleSelectFormik,
  TextArea,
  TimePicker,
} from '../../../components/general';
import { Box } from '../../../components/general/Box/Box';
import { CourseDurationOptions } from '../../../constants/courseInfo.constants';
import { CurrencyOptions } from '../../../constants/currency.constants';
import { GroupFormats, groupStateOptions, selectionStatusOptions } from '../../../constants/group.constants';
import { InstructorTypesOptions } from '../../../constants/instructor.constants';
import messages from '../../../constants/messages.constants';
import { SalaryTypes } from '../../../constants/salary.constants';
import { DayOfWeekOptions } from '../../../constants/schedule.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { withCatch } from '../../../helpers/error.helpers';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { generateNewSchedule } from '../../../hooks/useScheduler.hooks';
import { getCourses } from '../../../services/courses.service';
import { getAllInstructors } from '../../../services/instructors.service';
import { getTeams, getTeamsByUserId } from '../../../services/team.service';
import { getAllUser } from '../../../services/user.service';
import { GroupStateEnum, ICreateGroup } from '../../../types/group.types';
import { CreateEditGroupFormSchema } from '../schemas/createEditGroup.form.schema';

export interface IGroupFromProps {
  initialValues: ICreateGroup | {};
  title: string;
  onSubmit: (value: ICreateGroup) => Promise<void>;
  isEditForm?: boolean;
}

export const CreateEditGroupForm: React.FC<IGroupFromProps> = ({
  initialValues = {},
  onSubmit,
  isEditForm = false,
}) => {
  const filteredGroupStateOptions = useMemo(() => {
    const { ONGOING, ...groupStateOptionsForEdit } = groupStateOptions;
    return groupStateOptionsForEdit;
    //return groupStateOptions;
  }, [isEditForm]);

  const users = useQuery(UseQueryTypes.USERS, getAllUser);
  const instructors = useQuery(UseQueryTypes.INSTRUCTORS, getAllInstructors);
  const courses = useQuery(UseQueryTypes.COURSES, getCourses);

  const handleFormSubmit = async (values: Partial<ICreateGroup>, formik: any) => {
    if (values.duration) {
      // @ts-ignore
      values.duration.value = parseFloat(values.duration.value);
    }
    if (!values.tuitionFee?.prePaymentPercent) {
      // @ts-ignore
      values.tuitionFee.prePaymentPercent = null;
    }

    await onSubmit(values as ICreateGroup);
    formik.resetForm({});
  };

  const courseIdContext = useMemo(() => {
    return generateContext(courses.data || [], ({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [courses.data]);

  const projectCoordinatorIdContext = useMemo(() => {
    return generateContext(users.data || [], (user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
  }, [users.data]);

  const instructorContext = useMemo(() => {
    return generateContext(instructors.data || [], (i) => ({
      label: i.name,
      value: i.id,
    }));
  }, [instructors.data]);

  const isScreenLoading = users.isLoading || courses.isLoading || instructors.isLoading;

  const { required } = messages;

  const teamsQuery = useQuery(UseQueryTypes.TEAMS, getTeams);

  const checkAreCertificateFieldsRequired =  useCallback((courseId?: number, groupState?: GroupStateEnum) => {
    if(!isEditForm) return false;

    const courseLevel = (courses.data || []).reduce<{ [key: number]: number }>((acc, { id, level}) => {
      acc[id] = level;
      return acc;
    }, {});

    const courseLevelMap = new Map(Object.entries(courseLevel));
    const level = courseLevelMap.get(`${courseId}`);

    if(!level) return false;

    return groupState === GroupStateEnum.TERMINATED
  }, [courses?.data])


  const teamsIdContext = useMemo(() => {
    return generateContext(teamsQuery.data || [], ({ id, teamName }) => ({
      value: id,
      label: teamName,
    }));
  }, [teamsQuery.data]);

  const userTeamsMutation = useMutation(getTeamsByUserId);

  const handleProjectCoordinatorChange = async (id: number, callback: (id: number) => void) => {
    const mutationFn = () => userTeamsMutation.mutateAsync(id);

    await withCatch(mutationFn, {
      onSuccess: (teams) => {
        callback(teams[0].id);
      },
    });
  };

  return (
    <>
      <Spin spinning={isScreenLoading}>
        {!isScreenLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={CreateEditGroupFormSchema}
            onSubmit={handleFormSubmit}
            setTouched
          >
            {({ values, setFieldValue, resetForm, dirty, isValid, errors, setFieldError }) => {
              return (
                <Form>
                  <Row style={{ marginBottom: 32 }} justify="end">
                    <Col style={{ marginRight: 16 }}>
                      <Button onClick={() => resetForm()} htmlType="reset">
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button htmlType="submit" variant="primary" disabled={!(dirty && isValid)}>
                        Save Group
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} span={24}>
                      <Box title="General">
                        <Row>
                          <Col span={24}>
                            <Field
                              label="Course"
                              name="courseId"
                              value={values.courseId}
                              component={SingleSelectFormik}
                              options={courseIdContext}
                              required
                            />
                            <ErrorMessage name="courseId" component="span" className="error" />
                          </Col>
                        </Row>
                        <Field
                          onChange={(id: any) =>
                            handleProjectCoordinatorChange(id, (id) => {
                              setFieldValue('team', id);
                            })
                          }
                          component={SingleSelectFormik}
                          label="Project Coordinator"
                          name="projectCoordinatorId"
                          options={projectCoordinatorIdContext}
                          required
                        />
                        <ErrorMessage name="projectCoordinatorId" component="span" className="error" />

                        <Field
                          name="team"
                          label="Team"
                          options={teamsIdContext}
                          component={SingleSelectFormik}
                          required
                        />
                        <ErrorMessage name="team" component="span" className="error" />

                        <Row>
                          <Col span={11}>
                            <Field
                              label="State"
                              component={SingleSelectFormik}
                              name="groupState"
                              options={filteredGroupStateOptions}
                              required
                            />
                            <ErrorMessage name="groupState" component="span" className="error" />
                          </Col>
                          <Col offset={2} span={11}>
                            <Field
                              component={SingleSelectFormik}
                              label="Format"
                              name="courseFormat"
                              options={GroupFormats}
                              required
                            />
                            <ErrorMessage name="courseFormat" component="span" className="error" />
                          </Col>
                        </Row>
                        <Col>
                          <Field
                            label="Evaluation form link"
                            component={Input}
                            name="evaluationLink"
                            required={checkAreCertificateFieldsRequired(values.courseId, values.groupState as GroupStateEnum)}
                          />
                        </Col>
                        <ErrorMessage name="evaluationLink" component="span" className="error" />
                        <Col>
                          <Field
                            label="Evaluation spreadsheet link"
                            component={Input}
                            name="evaluationFormSpreadsheetLink"
                            required={checkAreCertificateFieldsRequired(values.courseId, values.groupState as GroupStateEnum)}
                          />
                        </Col>
                        <ErrorMessage name="evaluationFormSpreadsheetLink" component="span" className="error" />
                        <Row>
                          <Field component={Input} label="Group Description" name="description" />
                          <ErrorMessage name="description" component="span" className="error" />
                        </Row>
                      </Box>
                      <Box title="Tutor and Assistant">
                        <FieldArray
                          name="groupInstructors"
                          render={(arrayHelpers) => {
                            return (
                              <>
                                {(values.groupInstructors || []).map((instructor, idx) => {
                                  return (
                                    <div key={instructor.id} style={{ marginBottom: 20 }}>
                                      <Row align="middle" justify="space-between">
                                        <Col span={16}>
                                          <Field
                                            key={instructor.id}
                                            name={`groupInstructors[${idx}].instructorId`}
                                            component={SingleSelectFormik}
                                            label="Instructor"
                                            options={instructorContext}
                                            required
                                          />
                                          <ErrorMessage
                                            name={`groupInstructors[${idx}].instructorId`}
                                            component="span"
                                            className="error"
                                          />
                                        </Col>
                                        <Col span={7}>
                                          <Field
                                            key={instructor.id}
                                            name={`groupInstructors[${idx}].instructorType`}
                                            component={SingleSelectFormik}
                                            label="Instructor Type"
                                            options={InstructorTypesOptions}
                                            required
                                          />
                                          <ErrorMessage
                                            name={`groupInstructors[${idx}].instructorType`}
                                            component="span"
                                            className="error"
                                          />
                                        </Col>
                                      </Row>
                                      <Row justify="space-between">
                                        <Col span={7}>
                                          <Field
                                            name={`groupInstructors[${idx}].salary.currency`}
                                            label="Price"
                                            component={SingleSelectFormik}
                                            options={CurrencyOptions}
                                          />
                                          <ErrorMessage
                                            name={`groupInstructors[${idx}].salary.currency`}
                                            component="span"
                                            className="error"
                                          />
                                        </Col>
                                        <Col span={8}>
                                          <Field
                                            component={Input}
                                            label={'Salary amount'}
                                            name={`groupInstructors[${idx}].salary.amount`}
                                          />
                                          <ErrorMessage
                                            name={`groupInstructors[${idx}].salary.amount`}
                                            component="span"
                                            className="error"
                                          />
                                        </Col>
                                        <Col span={7}>
                                          <Field
                                            component={SingleSelectFormik}
                                            label="Salary Type"
                                            name={`groupInstructors[${idx}].salary.salaryType`}
                                            options={SalaryTypes}
                                          />
                                          <ErrorMessage
                                            name={`groupInstructors[${idx}].salary.salaryType`}
                                            component="span"
                                            className="error"
                                          />
                                        </Col>
                                      </Row>
                                      <Row justify="end">
                                        <Col span={7}>
                                          <Button
                                            htmlType="button"
                                            icon={<Icons.Delete />}
                                            onClick={() => arrayHelpers.remove(idx)}
                                          >
                                            Delete
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                                <Row>
                                  <Button htmlType="button" onClick={() => arrayHelpers.push({})}>
                                    Add a new Instructor
                                  </Button>
                                </Row>
                              </>
                            );
                          }}
                        />
                      </Box>
                    </Col>
                    <Col lg={11} span={24} offset={1}>
                      <Box title="Duration and Price">
                        <Row justify="space-between">
                          <Col span={12}>
                            <Field name="startDate" label="Date" component={CustomDatePickerFormik} required />
                            <ErrorMessage name="startDate" component="span" className="error" />
                          </Col>
                          <Col span={11}>
                            <Field name="size" label="Group Max size" component={Input} required />
                            <ErrorMessage name="size" component="span" className="error" />
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col span={11}>
                            <Field name="duration.value" label="Duration" component={Input} required />
                            <ErrorMessage name="duration.value" component="span" className="error" />
                          </Col>
                          <Col span={12}>
                            <Field
                              name="duration.timeScale"
                              label="Time Scale"
                              component={SingleSelectFormik}
                              options={CourseDurationOptions}
                              required
                            />
                            <ErrorMessage name="duration.timeScale" component="span" className="error" />
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col span={11}>
                            <Field
                              component={SingleSelectFormik}
                              options={CurrencyOptions}
                              name="tuitionFee.currency"
                              label="Price"
                              required
                            />
                            <ErrorMessage name="tuitionFee.currency" component="span" className="error" />
                          </Col>
                          <Col span={12}>
                            <Field name="tuitionFee.amount" component={Input} label="Fee Amount" required />
                            <ErrorMessage name="tuitionFee.amount" component="span" className="error" />
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col span={11}>
                            <Field
                              component={SingleSelectFormik}
                              options={SalaryTypes}
                              label="Client Fee Type"
                              name="tuitionFee.feeType"
                              required
                            />
                            <ErrorMessage name="tuitionFee.feeType" component="span" className="error" />
                          </Col>
                          <Col span={12}>
                            <Field name="tuitionFee.prePaymentPercent" component={Input} label="Pre Payment Percent" />
                            <ErrorMessage name="tuitionFee.prePaymentPercent" component="span" className="error" />
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col span={11}>
                            <Field
                              component={SingleSelectFormik}
                              options={selectionStatusOptions}
                              label="Selection Status"
                              name="selectionStatus"
                            />
                            <ErrorMessage name="selectionStatus" component="span" className="error" />
                          </Col>
                          <Col span={12}>
                            <label style={{ display: 'flex', flexDirection: 'column', margin: '5px 0 ' }}>
                              <span style={{ marginBottom: 4 }}>Is Final?</span>
                              <Field
                                name="isFinal"
                                type="checkbox"
                                component={Checkbox}
                                onChange={(e: any) => {
                                  setFieldValue('isFinal', e?.target?.checked);
                                }}
                              />
                            </label>
                            <ErrorMessage name="isFinal" component="span" className="error" />
                          </Col>
                        </Row>
                        <Row>
                          <Field
                            component={TextArea}
                            rows={7}
                            label="Agreement 3.1 point"
                            placeholder="Type your custom 3.1 point for agreement"
                            name="agreement_3_1_point"
                          />
                        </Row>
                      </Box>
                      <Box title="Schedule" isRequired={errors.schedule === required}>
                        <FieldArray
                          name="schedule"
                          render={(arrHelpers) => {
                            return (
                              <div className="formBody">
                                {(values.schedule || []).map((_, idx: number) => (
                                  <Row align="middle" key={idx}>
                                    <Col span={7}>
                                      <Field
                                        label="Week Day"
                                        name={`schedule[${idx}].dayOfWeek`}
                                        component={SingleSelectFormik}
                                        options={DayOfWeekOptions}
                                        required
                                      />
                                      <ErrorMessage
                                        name={`schedule[${idx}].dayOfWeek`}
                                        component="span"
                                        className="error"
                                      />
                                    </Col>
                                    <Col span={7} offset={1}>
                                      <Field label="From" name={`schedule[${idx}].startHour`} component={TimePicker} />
                                      <ErrorMessage
                                        name={`schedule[${idx}].startHour`}
                                        component="span"
                                        className="error"
                                      />
                                    </Col>
                                    <Col span={7}>
                                      <Field
                                        label="To"
                                        name={`schedule[${idx}].endHour`}
                                        component={TimePicker}
                                        format="HH:mm"
                                      />
                                      <ErrorMessage
                                        name={`schedule[${idx}].endHour`}
                                        component="span"
                                        className="error"
                                      />
                                    </Col>
                                    <Col span={2}>
                                      <Button
                                        style={{
                                          border: '1px solid #EDEDED',
                                          borderRadius: 4,
                                          width: 32,
                                          height: 32,
                                          padding: 8,
                                          cursor: 'pointer',
                                          marginLeft: 10,
                                          marginTop: 15,
                                        }}
                                        onClick={() => arrHelpers.remove(idx)}
                                        htmlType="button"
                                      >
                                        <Icons.Delete />
                                      </Button>
                                    </Col>
                                  </Row>
                                ))}
                                <Row>
                                  <Button htmlType="button" onClick={() => arrHelpers.push(generateNewSchedule())}>
                                    Add a new Day
                                  </Button>
                                </Row>
                              </div>
                            );
                          }}
                        />
                      </Box>
                      <Box title="Slack URL">
                        <Row>
                          <Col span={24}>
                            <Field component={Input} label="Slack" name="slackLink" />
                            <ErrorMessage name="slackLink" component="span" className="error" />
                          </Col>
                        </Row>
                      </Box>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )}
      </Spin>
    </>
  );
};
