import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, CustomDatePickerFormik, Flex } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { LevelOptions } from '../../constants/courseInfo.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { getCourses } from '../../services/courses.service';
import { getAllInstructors } from '../../services/instructors.service';
import { getAllUser } from '../../services/user.service';
import { IAppForm } from '../../types/form.types';
import { GroupFilterModel, GroupFilterOptions } from '../../types/group.types';

const defaultGroupFilterOptions: Partial<GroupFilterOptions> = {};

export const FilterGroupForm: IAppForm = () => {
  const { filterOptions, changeOptions, resetOptions } = useFilter(
    defaultGroupFilterOptions,
  );
  const { closeDrawer } = useDrawer();

  const instructorQuery = useQuery(
    UseQueryTypes.INSTRUCTORS,
    getAllInstructors,
  );
  const instructorsContext = useMemo(() => {
    return generateContext(instructorQuery.data || [], (instructor) => ({
      label: instructor.name,
      value: instructor.id,
    }));
  }, [instructorQuery.data]);

  const projectCoordinatorsQuery = useQuery(
    UseQueryTypes.PROJECT_COORDINATORS,
    getAllUser,
  );
  const projectCoordinatorsContext = useMemo(() => {
    return generateContext(projectCoordinatorsQuery.data || [], (pc) => ({
      label: `${pc.firstName} ${pc.lastName}`,
      value: pc.id,
    }));
  }, [projectCoordinatorsQuery.data]);

  const coursesQuery = useQuery(UseQueryTypes.COURSES, getCourses);
  const coursesContext = useMemo(() => {
    return generateContext(coursesQuery.data || [], (course) => ({
      label: course.name,
      value: course.id,
    }));
  }, [coursesQuery.data]);
  const initialValues = filterOptions;

  const handleFilterFormSubmit = (filterOptions: Partial<GroupFilterModel>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  return (
    <Formik
      onSubmit={handleFilterFormSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {(props) => (
        <Form
          name="group-filter"
          style={{
            width: '100%',
            height: 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Field
            label="Project Coordinator"
            name="projectCoordinatorIds"
            component={MultiselectFormik}
            options={projectCoordinatorsContext}
          />
          {/*<Field
            component={MultiselectFormik}
            options={groupStateOptions}
            label="Group States"
            name="groupState"
          />*/}
          <Field
            component={MultiselectFormik}
            options={instructorsContext}
            label="Instructors"
            name="instructorIds"
          />
          <Field
            component={MultiselectFormik}
            options={coursesContext}
            label="Courses"
            name="courseIds"
          />
          <Field
            component={MultiselectFormik}
            options={LevelOptions}
            label="Levels"
            name="levels"
          />
          <Field
            component={CustomDatePickerFormik}
            label="Date From"
            name="dateFrom"
          />
          <Field
            component={CustomDatePickerFormik}
            label="Date To"
            name="dateTo"
          />

          <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
            <Button
              htmlType="button"
              style={{ minWidth: 160, marginRight: 20 }}
              onClick={() => {
                resetOptions();
                props.resetForm();
              }}
            >
              Reset
            </Button>
            <Button
              style={{ minWidth: 160 }}
              htmlType="submit"
              variant="primary"
            >
              Apply
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
