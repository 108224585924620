import { stringifyObject } from "../helpers/stringifyObj.helper";
import  {Sorters } from "../types/general.types";
import type { MailResponse, SendMail } from '../types/mail.types';
import {MailFilterModel, MailFilterResponse,SaveMail } from "../types/mail.types";
import axios from "./config.service";

const mailServiceURL = process.env.REACT_APP_EMAIL_SERVICE;

export async function getMailTemplates() {
  const r = await fetch(`${mailServiceURL}/prod/templates`, {}).then((res) =>
    res.json(),
  );

  return r as Promise<MailResponse>;
}

export async function sendMassMailing(mailSettings: Partial<SendMail>) {
   return await fetch(`${mailServiceURL}/prod/sendEmail`, {
    method: 'POST',
    credentials: 'include',
    mode: 'no-cors',
    body: JSON.stringify(mailSettings),
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function getMailTemplateByName(name: string) {
  const r = await fetch(`${mailServiceURL}/prod/templates/${name}`)
    .then((r) => r.json())
    .catch((e) => {
      return e.message;
    });
  return r as Promise<any>;
}

export async function createEmail(email: Partial<SaveMail>) {
  const r = await axios.post('/email/', email);
  return r.data as Promise<any>;
}

export function filterEmails(
    filters: Partial<MailFilterModel>,
    sorters: Partial<Sorters> = {},
) {
    const queryString = stringifyObject(sorters);
    return axios
        .post(`/email/filter?${queryString}`, filters)
        .then((r) => r.data) as Promise<MailFilterResponse>;
}
