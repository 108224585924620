import { Spin } from 'antd';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { Button } from '../../../../components/general';
import { ButtonTypeEnum } from '../../../../types/button.types';
import { IStudentAttachment } from '../../types/studentAttachment.types';
import styles from '../Agreements/AgreementsRenderer/AgreementsRenderer.module.css';

const CertificateRenderer = ({ handleToggleModal, studentCertificate, isCertificateVisible }: { handleToggleModal: (isModalVisible: boolean) => void; studentCertificate: IStudentAttachment, isCertificateVisible: boolean }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const handleOpenCertificate = () => {
    if (!isCertificateVisible) {
      handleToggleModal(true);
    } else {
      window.location.assign(studentCertificate.link);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.agreementItem}>
        <div className={styles.documentWrapper}>
          <Document
            className={styles.document}
            loading={
              <div className={styles.errorContentPage}>
                <Spin />
              </div>
            }
            file={studentCertificate.link}
          >
            <Page pageNumber={pageNumber} className={styles.page} scale={0.4}/>
          </Document>
        </div>
        <div className={styles.hoverContent}>
          <Button
            onClick={handleOpenCertificate}
            variant={ButtonTypeEnum.primary}
            style={{ width: '75%', transition: '100ms' }}
          >
            See the Certificate
          </Button>
        </div>
      </div>
    </div>
  )
};

export default CertificateRenderer;
