import axios from 'axios';

import { stringifyObject } from '../helpers/stringifyObj.helper';
import { IApplicantActionsFilterResponse, IFilterApplicantActions } from '../types/applicantActions.types';
import { Sorters } from '../types/general.types';

export async function filterApplicantActions(criterias: Partial<IFilterApplicantActions>, query: Partial<Sorters>) {
  const queryString = stringifyObject(query);
  const r = await axios.post(`/applicantActions/filter?${queryString}`, criterias);
  return r.data as Promise<IApplicantActionsFilterResponse>;
}
