import React, { useState } from 'react';

import { Flex, Icons } from '../../general';
import styles from './Collapse.module.css';

interface ICollapseProps {
  isDefaultOpen?: boolean;
  buttonProps: {
    showText: string;
    hideText: string;
  };
  hasContent: boolean;
  panelStyle?: {};
  panelChildren: JSX.Element;
  children: JSX.Element[];
}

export function Collapse({
  isDefaultOpen = false,
  buttonProps,
  hasContent,
  panelStyle,
  panelChildren,
  children,
  ...props
}: ICollapseProps) {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  return (
    <div className={styles.panel} {...props}>
      <Flex direction="column" alignItems="flex-end" style={{ ...panelStyle }}>
        {panelChildren}
      </Flex>
      {isOpen && (
        <Flex direction="column" className={styles.content}>
          {children}
        </Flex>
      )}
      {hasContent ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <p className={styles.showBtnContent}>{isOpen ? buttonProps.hideText : buttonProps.showText}</p>
          <Icons.ArrowBottom
            width={10}
            style={{ marginLeft: 11, transform: `rotate(${isOpen ? '180deg' : '0deg'})` }}
            color="#5451FF"
          />
        </Flex>
      ) : null}
    </div>
  );
}
