import { Col, Row, Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, Flex } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { InstructorStatusOptions } from '../../constants/instructor.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { getAllTracks } from '../../services/track.service';
import { IAppForm } from '../../types/form.types';
import { InstructorFilterOptions } from '../../types/instructor.types';

const filterDefaultOptions: Partial<InstructorFilterOptions> = {};

export const FilterInstructorsForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { filterOptions, changeOptions, resetOptions } =
    useFilter<Partial<InstructorFilterOptions>>(filterDefaultOptions);

  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);
  const tracksContext = useMemo(() => {
    return generateContext(tracksQuery.data || [], (track) => ({
      label: track.name,
      value: track.id,
    }));
  }, [tracksQuery.data]);

  const initialValues = filterOptions;

  const handleSubmit = (filterOptions: Partial<InstructorFilterOptions>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  return (
    <Spin spinning={tracksQuery.isLoading}>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
        {(props) => (
          <Form
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row>
              <Col span={24}>
                <Field name="trackIds" label="Select Track" component={MultiselectFormik} options={tracksContext} />
              </Col>
              <Col span={24}>
                <Field
                  name="instructorStatus"
                  label="Select Statuses"
                  component={MultiselectFormik}
                  options={InstructorStatusOptions}
                />
              </Col>
            </Row>
            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button
                htmlType="button"
                style={{ minWidth: 160, marginRight: 8 }}
                onClick={() => {
                  props.handleReset();
                  resetOptions();
                }}
              >
                Reset
              </Button>
              <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary">
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
