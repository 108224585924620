import { Flex } from "../../../components/general";
import { DocumentImage, DocumentPaper } from "../../../components/general/Icons/Icons";
import { FileTypes } from "../../../constants/files.constants";
import { getFileTypeFromString } from "../../../helpers/string.helpers";
import { DateService } from "../../../services/date.service";
import { IStudentAttachmentsTableData } from "../types/studentAttachment.types";
import { StudentTableBodySchema } from "../types/studentTable.types";


export const AttachmentsTableHeaderSchema = {
  documentName: {
    title: 'Documents Name',
    key: 'documentName',
  },
  uploadDate: {
    title: 'Upload Date',
    key: 'uploadDate',
  },

};


const AttachmentContent = ({ name, link }: { name: string, link: string }) => {
  const fileType = getFileTypeFromString(link)
  const isPdf = fileType?.includes(FileTypes.PDF)

  return (
    <Flex alignItems='center' justifyContent='flex-start' style={{ gap: '0 10px' }}>
      {isPdf ? <DocumentPaper/> : <DocumentImage/>}
      <a href={link} target="_blank" rel="noreferrer" style={{ color: 'black', textDecoration: 'underline' }}>{name}</a>
    </Flex>
  )
}


export const getAttachmentsSchema = ({
  name,
  link,
  date,
}: IStudentAttachmentsTableData): StudentTableBodySchema =>
  ({
      documentName: { renderer: () => <AttachmentContent name={name} link={link}/> },
      uploadDate: { renderer: () => DateService.getFullDate(date) },
    }
  )
