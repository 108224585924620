import type { ColumnSchema } from '../../types/column.types';
import type { IGroup } from '../../types/group.types';
import { formatGroup } from '../applicants/applicant.renderer';
import {
  ProgressGroupColumn,
  StartDateGroupColumn,
} from './group.renderer';

export const defaultInstructorGroupsColumns: ColumnSchema<IGroup> = {
  uuid: 'defaultInstructorGroupsColumns',
  schema: {
    course: {
      title: 'Course',
      render: (_, group) => group.course.name,
    },
    group: {
      title: 'Group',
      render: (_, group) => formatGroup(group,{link: true}),
    },
    startDate: {
      title: 'Start Date',
      render: StartDateGroupColumn,
    },
    completenessPercentage: {
      title: 'Progress',
      dataIndex: 'completenessPercentage',
      render: ProgressGroupColumn,
    }
  },
};
