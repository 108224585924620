import { debounce } from 'lodash';
import React, { useCallback } from 'react';

import { FilterOptions } from '../types/general.types';
import { useFilter } from './useFilter.hooks';

type SearchFilterOptions = FilterOptions & {
  term: string;
  secondaryTerm: string;
};

export const useSearchHandler = (term = 'term') => {
  const nonCurrent = term === 'term' ? 'secondaryTerm' : 'term';

  const { changeOptions, filterOptions } = useFilter<
    Partial<SearchFilterOptions>
  >({
    [nonCurrent]: '',
    [term]: ''
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((q, old) => {
      const options = { filterOptions: { [term]: q.target.value.trim() } }

      if (old?.[nonCurrent]) {
        options.filterOptions[nonCurrent] = old[nonCurrent]
      }
      changeOptions(options);
    }, 500),
    [filterOptions],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, old?: any) => {
    delayedQuery(e, old);
  };

  return { handleSearch, [term]: filterOptions[term] };
};
