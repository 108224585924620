import { DayOfWeeksAm } from '../../constants/schedule.constants';
import { DayOfWeeks, ScheduleModel } from '../../types/general.types';

const scheduleTableHTMLbody =
  "<table border='1' width='400' height='auto'><tr><th>Օր</th><th>Դասը սկսելու ժամը</th><th>Ավարտելու ժամը</th>rows</table>";

const createScheduleRows = ({
  dayOfWeek,
  startHour,
  endHour,
}: {
  dayOfWeek: DayOfWeeks;
  startHour: string;
  endHour: string;
}) => `<tr><td>${DayOfWeeksAm[dayOfWeek]}</td><td>${startHour}</td><td>${endHour}</td></tr>`;

const removeCommaFromRows = (rows: string) => rows.replaceAll(',', ' ');

export const createScheduleTable = (schedule: ScheduleModel[] | undefined) => {
  const rows = `${schedule?.map(createScheduleRows).toString()}`;
  const normalizedRows = removeCommaFromRows(rows);

  return scheduleTableHTMLbody.replace('rows', normalizedRows);
};
