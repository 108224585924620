import getStudentExamsFinalScore from '../../helpers/getStudentExamsFinalScore';
import { PhoneService } from '../../services/phone.service';
import type { IStudentBrief } from '../../types/applicant.types';
import type { ColumnSchema } from '../../types/column.types';
import {
  ApplicantAttachments, ApplicantCV,
  CertificateGroupColumn,
  CommentApplicantColumn,
  DateApplicantColumn,
  EditApplicantColumn,
  FeedbackApplicantColumn,
  GroupApplicantColumn,
  NameApplicantColumn,
  PaymentGroupColumn,
  RegistrationSourceApplicantColumn,
  StatusApplicantColumn,
} from './applicant.renderer';

export const getDefaultApplicantDetailColumns = (
  exams?: IStudentBrief['applicantExams'],
): ColumnSchema<IStudentBrief> => ({
  uuid: 'defaultApplicantDetailColumns',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: () => -1,
      width: 250,
      render: DateApplicantColumn,
    },
    name: {
      dataIndex: 'name',
      title: 'Student',
      width: 200,
      fixed: 'left',
      sorter: () => -1,
      render: (_, applicant) => NameApplicantColumn(_, applicant,{detailLink: true}),
    },
    phoneNumber: {
      title: 'Phone',
      width: 200,
      sorter: () => -1,
      render: (_, applicant) => PhoneService.normalize(applicant.phoneNumber),
    },
    nameHy: {
      title: 'Name (hy)',
      width: 150,
    },
    nameEn: {
      title: 'Name (en)',
      width: 150,
    },
    group: {
      title: 'Group',
      width: 300,
      render:  GroupApplicantColumn,
    },
    registrationSource: {
      title: 'Registration Source',
      width: 150,
      render: RegistrationSourceApplicantColumn,
    },
    status: {
      title: 'Status',
      width: 170,
      sorter: () => -1,
      render: StatusApplicantColumn,
    },
    totalAbsentees: {
      title: 'Absentees',
      dataIndex: 'totalAbsentees',
      width: 150,
      sorter: () => -1,
      render: (_, applicant) => applicant.totalAbsentees,
    },
    hasDebt: {
      title: 'Payments',
      dataIndex: 'hasDebt',
      width: 150,
      render: PaymentGroupColumn,
    },
    finalScore: {
      title: 'Final Score',
      dataIndex: 'finalScore',
      width: 200,
      render: (_, student) => getStudentExamsFinalScore(student),
    },
    certificate: {
      title: 'Certificate Given',
      dataIndex: 'certificate',
      render: CertificateGroupColumn,
    },
    comment: {
      title: 'Comment',
      width: 50,
      render: CommentApplicantColumn,
    },
    feedback: {
      title: 'Feedback',
      width: 50,
      render: FeedbackApplicantColumn,
    },
    edit: {
      title: 'Edit',
      width: 50,
      render: EditApplicantColumn,
    },
    cv: {
      title: 'CV',
      width: 50,
      render: ApplicantCV,
    },
    attachments: {
      title: 'Attachments',
      width: 50,
      render: ApplicantAttachments,
    },

    /*details: {
      title: 'Details',
      dataIndex: 'profile',
      render: DetailsApplicantColumn,
    },*/
  },
  hiddenColumns: ['nameHy', 'nameEn'],
});
