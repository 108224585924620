import * as Yup from 'yup';

import { IBulkUpdateStudentsFormData, StudentStatusEnum } from '../../../types/applicant.types';

export const BulkUpdateStudentsFormSchema: Yup.SchemaOf<IBulkUpdateStudentsFormData> = Yup.object().shape({
  status: Yup.mixed<StudentStatusEnum>().oneOf(Object.values(StudentStatusEnum)),
  groupId: Yup.number(),
  courseId: Yup.number(),
  scheduledDate: Yup.string().nullable(true),
});
