import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  getApplicantById,
  updateApplicant,
} from '../../services/applicant.service';
import {
  IUpdateStudent,
  StudentAddToVariants,
} from '../../types/applicant.types';
import { IAppForm } from '../../types/form.types';
import { TransformApplicantForm } from './shared/transformApplicant.form';

export const EditApplicantForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: studentId } = useEntityId();
  const cache = useQueryClient();

  const updateApplicantMutation = useMutation(updateApplicant);

  const applicantQuery = useQuery([UseQueryTypes.APPLICANT_ID, studentId], () =>
    getApplicantById(studentId),
  );

  const initialValues = useMemo<Partial<IUpdateStudent>>(() => {
    const applicant = applicantQuery.data;
    if (!applicant) return {};

    return {
      ...applicant,
      groupId: applicant.group.id,
      courseId: applicant.course.id,
      addTo: StudentAddToVariants.GROUP,
    };
  }, [applicantQuery.data]);

  const handleSubmit = async (updatedStudent: IUpdateStudent) => {
    // @TODO refactor this part
    const {
      discount,
      oneTimeDiscount,
      email,
      groupId,
      isCertificateGiven,
      isOpenToWork,
      name,
      nameEn,
      nameHy,
      phoneNumber,
      registrationSource,
      status,
      scheduledDate,
      courseId,
      paymentMethod,
    } = updatedStudent;

    const isOnlyCourseChanged = (initialValues.courseId !== updatedStudent.courseId) && (initialValues.groupId === updatedStudent.groupId)

    const _groupId = (groupId === -1 || isOnlyCourseChanged) ? null : groupId;

    const mutationFunc = () =>
      updateApplicantMutation.mutateAsync({
        id: studentId,
        applicant: {
          discount,
          oneTimeDiscount,
          email,
          groupId: _groupId,
          courseId,
          isCertificateGiven,
          isOpenToWork,
          name,
          nameEn,
          nameHy,
          phoneNumber,
          registrationSource,
          status,
          scheduledDate,
          paymentMethod,
        } as IUpdateStudent,
      });

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        cache.invalidateQueries(UseQueryTypes.APPLICANT_ID);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={updateApplicantMutation.isLoading}>
      <TransformApplicantForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isEditForm
      />
    </Spin>
  );
};
