import { DateService } from '../../../services/date.service';
import { ColumnSchema } from '../../../types/column.types';
import { IConsultant } from '../../../types/consultation.types';
import {
  CommentConsultantColumn,
  CompleteConsultantColumn,
  EditConsultantColumn,
  RegistrationSourceColumn,
} from '../../consultants/consultant.renderer';

export const consultationColumns: ColumnSchema<IConsultant> = {
  uuid: 'consultationColumns',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      width: 175,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    comment: {
      title: 'Comment',
      width: 50,
      render: CommentConsultantColumn,
    },
    isDone: {
      title: 'Completed',
      width: 20,
      render: CompleteConsultantColumn,
    },
    source: {
      title: 'Source',
      width: 100,
      render: RegistrationSourceColumn,
    },
    edit: {
      title: 'Edit',
      width: 50,
      render: EditConsultantColumn,
    },
  },
};
