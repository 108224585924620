export const AddButtonMenuItems = [
  {
    id: 1,
    method: 'reminder',
    title: 'Add a Reminder',
  },
  {
    id: 2,
    method: 'task',
    title: 'Add a Task',
  },
];
