import { Col, Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';

import {
    Button,
    TextArea,
} from '../../components/general';
import { Box } from '../../components/general/Box/Box';
import { withCatch } from '../../helpers/error.helpers';
import { sendBulkSms } from '../../services/sms.service';
import { useAppSelector } from '../../store';
import {selectSmsFormValues} from "../../store/features/sms/smsSlice";
import { SendSms  } from '../../types/sms.types';
// @ts-ignore
import { SmsFormSchema } from './schemas/massSms.forms.schema';

export const MassSmsForm = () => {
  const formInitialValues = useAppSelector(selectSmsFormValues);

  const sendSmsMutation = useMutation(sendBulkSms);

  const handleSendSubmit = async (
    smsOptions: Partial<SendSms>,
    { resetForm }: { resetForm: () => void },
  ) => {
    await withCatch(() => sendSmsMutation.mutateAsync(smsOptions), {
      onSuccess: async () => {
        await resetForm();
      },
    });
  };

  return (
    <Spin spinning={sendSmsMutation.isLoading}>
      <Formik
        onSubmit={handleSendSubmit}
        initialValues={formInitialValues}
        validationSchema={SmsFormSchema}
        enableReinitialize
      >
        {(props) => (
          <Form name="group-filter" style={{ minHeight: '100vh' }}>
            <Row style={{ marginBottom: 32 }} justify="end">
              <Col style={{ marginRight: 16 }}>
                <Button onClick={() => props.resetForm()} htmlType="reset">
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Send Sms
                </Button>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={{ marginTop: '20px' }} span={12}>
                <Box title="Template">
                  <Field
                    rows={7}
                    label="Custom template"
                    name="template"
                    component={TextArea}
                    required
                  />
                  <ErrorMessage
                    name="template"
                    component="span"
                    className="error"
                  />
                </Box>
              </Col>
              <Col style={{ marginTop: '20px' }} span={11}>
                <Box title="Recipients">
                  <Field
                    rows={7}
                    label="Recipients"
                    placeholder="e.g  37477284599, 37477284599"
                    name="to"
                    component={TextArea}
                    required
                  />
                  <ErrorMessage name="to" component="span" className="error" />
                </Box>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
