import { Col, Divider, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button, Flex } from '../../../../../components/general';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { downloadByLink } from '../../../../../helpers/download.helpers';
import { withCatch } from '../../../../../helpers/error.helpers';
import { useEntityId } from '../../../../../hooks/useFilter.hooks';
import { generateGroupCertificate } from '../../../../../services/groups.service';
import { GroupStateEnum, IGroup } from '../../../../../types/group.types';
import { getGroupInfo } from '../helper';
import { GroupInfoRow } from './groupInfoRow';

const { Title, Text } = Typography;

interface GeneralInfoProps {
  group: IGroup
}

export const GeneralInfo = ({ group }: GeneralInfoProps) => {
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();
  const { id } = useParams<{ id: string }>();

  const handleOpenCreateCertificateForm = () => {
    id && changeId(+id);
    openDrawer(AppFormsEnum.CertificateAutomationForm, 'Generate Certificates');
  }


  const groupRowInfo = useMemo(() => getGroupInfo(group), [group]);

  return (
    <Col span={10}>
      <Title style={{ marginBottom: 20 }} level={4}>
        General info
      </Title>

      <Flex justifyContent="space-between" style={{ padding: '0 10px', height: '40px' }}>
        <Text type="secondary">{group?.course.name}</Text>
        <Divider style={{ height: '100%' }} type="vertical" />
        <Text type="secondary">Group #{id}</Text>
        <Divider style={{ height: '100%' }} type="vertical" />
        <Text type="secondary">{group?.courseFormat}</Text>
      </Flex>
      <div style={{ backgroundColor: 'white' }}>
        {
          groupRowInfo.map(info => <GroupInfoRow name={info.name} value={info.value} key={info.name} />)
        }
      </div>
      {group?.groupState === GroupStateEnum.TERMINATED && (
        <Button onClick={handleOpenCreateCertificateForm} style={{ marginTop: '5px' }}>
          Generate Certificates
        </Button>
      )}
    </Col>
  )
}
