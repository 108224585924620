import { Avatar } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { Icons } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { AppFormsEnum } from '../../forms';
import { getAvatar } from '../../helpers/avatar.helper';
import { handleQueryError, withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { deleteCourseById } from '../../services/courses.service';
import { MoneyService } from '../../services/money.service';
import type { RenderColumn } from '../../types/column.types';
import type { ICourse } from '../../types/course.types';
import { IUser } from '../../types/user.types';

export const AvatarCourseColumn: RenderColumn<ICourse> = (_, course) => {
  return (
    <div className={course.defaultProjectCoordinator.deactivated ? `deactivated-avatar` : ''}>
      <Avatar
        src={getAvatar(course.defaultProjectCoordinator?.avatar)}
        style={{ cursor: 'pointer', marginRight: 7 }}
      />
      @{course.defaultProjectCoordinator.username}
    </div>
  );
};

export const EditCourseColumn: RenderColumn<ICourse> = (_, course) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenEditCourseForm = (id: number) => {
    changeId(id);
    openDrawer(AppFormsEnum.EditCourseForm, 'Edit Course');
  };

  return (
    <Icons.Edit
      onClick={() => handleOpenEditCourseForm(course.id)}
      style={{ cursor: 'pointer' }}
    />
  );
};

export const FeeCourseColumn: RenderColumn<ICourse> = (_, course) => {
  return `${course.defaultTuitionFee.currency} ${MoneyService.beautifyAmount(
    course.defaultTuitionFee.amount,
  )}`;
};

export const DurationCourseColumn: RenderColumn<ICourse> = (_, course) => {
  return `${course.defaultDuration.value || ''} ${
    course.defaultDuration.timeScale?.toLowerCase() || 'n/a'
  }`;
};

export const DeleteCourseColumn: RenderColumn<ICourse> = (_, course) => {
  const { user } = useAuth() as { user: IUser };
  const cache = useQueryClient();
  const deleteMutation = useMutation(deleteCourseById, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.COURSES),
    onError: () => {
    },
  });

  const canDeleteCourse = course.defaultProjectCoordinator.id === user.id;

  const handleDeleteCourse = async () => {
    const courseCoordinatorEmail = course.defaultProjectCoordinator.email;
    if (canDeleteCourse) {
      await withCatch(() => deleteMutation.mutateAsync(course.id));
    } else {
      handleQueryError(
        'Wrong action',
        `Only ${courseCoordinatorEmail} can delete course`,
      );
    }
  };

  return (
    <Icons.Delete
      disabled={!canDeleteCourse}
      onClick={handleDeleteCourse}
      style={{ cursor: 'pointer' }}
    />
  );
};
