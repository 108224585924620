import { IStudentBrief } from './applicant.types';
import { IComment } from './comment.types';
import { FilterOptions, FilterResponse } from './general.types';
import { IGroup } from './group.types';
import { IUser } from './user.types';

export enum TaskTypeEnum {
  FIRST_CONTACT_PHASE = 'FIRST_CONTACT_PHASE',
  EXAM_STAGE = 'EXAM_STAGE',
  INTERVIEW_STAGE = 'INTERVIEW_STAGE',
  GROUP = 'GROUP',
  APPLICANT = 'APPLICANT',
  OTHER = 'OTHER',
}

export enum RelatedToTypes {
  APPLICANT = 'APPLICANT',
  GROUP = 'GROUP',
  OTHER = 'OTHER',
}

export enum TaskStatusEnum {
  DONE = 'DONE',
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_INPUT = 'WAITING_FOR_INPUT',
}

export enum TaskSourceEnum {
  OWNED = 'OWNED',
  CREATED = 'CREATED',
}

export type TaskStatusTypes = keyof typeof TaskStatusEnum;
export type TaskType = keyof typeof TaskTypeEnum;
interface ITask {
  dueDate: string;
  relatedTo: number | null;
  taskStatus: TaskStatusTypes;
  taskType: TaskType;
  title: string;
  description: string;
  id: number;
  applicant: number | null;
}

export interface ITaskGET {
  owner: IUser;
  createdBy?: IUser;
  relatedTo: IGroup | null;
  dueDate: string;
  taskStatus: TaskStatusTypes;
  taskType: TaskType;
  title: string;
  description: string;
  id: number;
  applicant: IStudentBrief | null;
  comments: IComment[];
}

export interface ICreateTask extends Omit<ITask, 'id'> {
  owner: number;
  ownerUuid: string;
}

export interface ITaskInitialValues {
  dueDate: string;
  relatedTo: number | null;
  taskStatus: TaskStatusTypes | string;
  taskType: TaskType | string;
  title: string;
  description: string;
  ownerId: number;
}
export interface ITaskPOST extends ITask {
  ownerId: number;
}

export interface ITaskBulkUpdate {
  id: number;
  taskStatus: TaskStatusTypes;
}

export interface TaskFilterResponse extends FilterResponse {
  tasks: ITaskGET[];
}

export interface TaskFilterModel {
  dateFrom: string;
  dateTo: string;
  ownerIds: number[];
  creatorIds: number[];
  taskClientTypes: TaskType[];
  taskStatuses: TaskStatusTypes[];
  term: string;
}

export interface TaskBulkUpdateModel {
  selectedTasks: number[];
}

export type TaskFilterOptions = TaskFilterModel & TaskBulkUpdateModel & FilterOptions;
