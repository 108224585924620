import { Col, Typography } from 'antd';
import { memo } from 'react';

import { IGeneralSection } from '../../../../../../types/applicantDetail.types';

const { Text } = Typography;

const GeneralInfoCol: React.FC<IGeneralSection> = memo(({ content, title, span }) => (
  <Col  style={{ width: 'max-content', padding: '8px'}}>
    <Col>
      <Text type="secondary" style={{ textTransform: 'uppercase' }}>
        {title}
      </Text>
    </Col>
    <Col>
      <Text>{content}</Text>
    </Col>
  </Col>
));

export default GeneralInfoCol;
