import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';

import { Badges, ResultSummary } from '../../components/display';
import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import { Button, FilterButton, Icons, Search } from '../../components/general';
import { ChangeView } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { defaultInstructorColumns } from '../../schemas/instructors/instructors.schema';
import { countInstructor, filterInstructors } from '../../services/instructors.service';
import { getAllTracks } from '../../services/track.service';
import { SortDirectionEnum, WedgedTypes } from '../../types/general.types';
import { IInstructor, InstructorFilterOptions, InstructorStatusEnum } from '../../types/instructor.types';

export const filterDefaultOptions: Partial<InstructorFilterOptions> = {
  sortBy: 'created_at',
  sortDirection: SortDirectionEnum.DESC,
  trackIds: [],
  instructorStatus: [],
  //name: "",
};

export default function Instructors() {
  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions } = useFilter<Partial<InstructorFilterOptions>>(filterDefaultOptions);
  const { handleSearch, term } = useSearchHandler();

  const instructorTable = useTable<IInstructor>(defaultInstructorColumns);
  const modalAdapter = useColumnModalAdapter(instructorTable.getTableColumnSettings());

  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);

  const instructorsStatuses = useQuery(UseQueryTypes.INSTRUCTORS_COUNT, () => countInstructor());
  const instructorsQuery = useQuery([UseQueryTypes.INSTRUCTORS, filterOptions, sorterOptions], () =>
    filterInstructors(filterOptions, sorterOptions),
  );

  const openFilterInstructorsForm = () => openDrawer(AppFormsEnum.FilterInstructorsForm, 'Filter Instructors');
  const handleOpenCreateInstructorsForm = () => openDrawer(AppFormsEnum.CreateInstructorForm, 'Create Instructor');

  const instructorsTotalSize = instructorsQuery.data?.totalElements ?? 0;
  const isScreenLoading = tracksQuery.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row justify="start" gutter={8}>
              <Col span={13}>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Search
                        style={{ marginRight: 8 }}
                        placeholder="Search"
                        defaultValue={term}
                        onChange={handleSearch}
                      />
                      <FilterButton onClick={openFilterInstructorsForm} />
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Badges
                wedgedType={WedgedTypes.INSTRUCTOR_STATUS}
                badges={[
                  {
                    data: instructorsStatuses.data?.NOT_AVAILABLE,
                    title: 'Not Available',
                    name: InstructorStatusEnum.NOT_AVAILABLE,
                  },
                  {
                    data: instructorsStatuses.data?.ON_A_BREAK,
                    title: 'On Break',
                    name: InstructorStatusEnum.ON_A_BREAK,
                  },
                  {
                    data: instructorsStatuses.data?.TEACHING_NOW,
                    title: 'Teaching now',
                    name: InstructorStatusEnum.TEACHING_NOW,
                  },
                ]}
              />
            </Row>
            <Divider style={{ margin: '16px 0px' }} />

            <Row align="middle" justify="space-between">
              <ResultSummary text={`${instructorsTotalSize} result found`} />
              <Row>
                <Col style={{ marginRight: 10 }}>
                  <Button onClick={handleOpenCreateInstructorsForm} variant="primary" icon={<Icons.Add />}>
                    Add Instructor
                  </Button>
                </Col>
                <ChangeView onClick={modalAdapter.openSettings} />
              </Row>
            </Row>

            <Table<IInstructor>
              {...instructorTable.getTableProps()}
              rowKey={(instructor) => instructor.id}
              dataSource={instructorsQuery.data?.instructors}
              style={{ marginTop: 25 }}
            />
            <Pagination
              {...instructorTable.getPaginationProps()}
              total={instructorsTotalSize}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
