import { DateService } from '../services/date.service';
import { PeriodEnum } from '../types/general.types';
import { LessonStateEnum } from '../types/lesson.types';

export const lessonDates = {
  [PeriodEnum.TODAY]: {
    value: [DateService.getServerDate(), DateService.getOtherDate(1)],
    label: 'Today',
  },
  [PeriodEnum.WEEK]: {
    value: [DateService.getServerDate(), DateService.getOtherDate(7)],
    label: 'This Week',
  },
  [PeriodEnum.MONTH]: {
    value: [DateService.getServerDate(), DateService.getOtherDate(31)],
    label: 'This Month',
  },
  [PeriodEnum.ALL]: {
    value: [undefined, undefined],
    label: 'All Time',
  },
};

export const lessonStates = {
  [LessonStateEnum.MISSED]: {
    value: LessonStateEnum.MISSED,
    label: 'Missed',
  },
  [LessonStateEnum.COMPLETED]: {
    value: LessonStateEnum.COMPLETED,
    label: 'Completed',
  },
  [LessonStateEnum.UPCOMING]: {
    value: LessonStateEnum.UPCOMING,
    label: 'Upcoming',
  },
};
