import { Col, Divider, Row } from 'antd';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import ResultSummary from '../../components/display/ResultSummary/ResultSummary';
import { Button, FilterButton, Icons } from '../../components/general';
import { CustomCollapse } from '../../components/general/AdvancedCollapse/Collapse';
import { ChangeView } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { AppFormsEnum } from '../../forms';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { getTeamsWithRelatedResources } from '../../services/team.service';
import { AbstractUser } from '../../types/auth.types';
import { ICollapseDataItem } from '../../types/components/display.types';
import { IUser } from '../../types/user.types';
import { CustomPanelHeader } from './components/CustomPanelHeader';
import { MembersTable } from './components/membersTable';
import { TeamsHeader } from './components/TeamsHeader';
import styles from './Teams.module.css'

const headerItems = [
  {
    title: 'TeamName',
    span: 12
  },
  {
    title: 'Count',
    span: 12
  },
]


export default function Teams() {
  const { user } = useAuth();
  const { openDrawer } = useDrawer();

  const rowSelectionAdapter = useRowSelectionAdapter<IUser>();

  const handleOpenCreateTeamForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.CreateTeamForm, 'Create Team');
  }

  const teams = useQuery(UseQueryTypes.TEAMS, getTeamsWithRelatedResources);

  const data = useMemo(() => {
    if(!teams?.data?.length) return [];
    return  teams?.data?.map(({id, name, members, phoneNumbers}) => {
      return  {
        key: id,
        header: <CustomPanelHeader id={id} teamName={name} membersCount={`${members?.length}` || '0'} user={user as AbstractUser}/>,
        customPanelProps: {
          className: styles.overwritePanel
        },
        panelItemsRendererProps: { members }
      } as ICollapseDataItem
    })
  }, [teams.data])

  return (
    <Col>
      <Col span={24}>
        <Row justify="end">
          {/*<Col>*/}
          {/*  <FilterButton onClick={() => {}}/>*/}
          {/*</Col>*/}
          <Col>
            <Row>
              <Col>
                <Button
                  onClick={handleOpenCreateTeamForm}
                  variant="primary"
                  icon={<Icons.Add/>}
                >
                  Add a team
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Divider style={{ margin: '16px 0px' }}/>

      <Col span={24} style={{ padding: '16px 0' }} >
        <Row justify="space-between" align='middle'>
          <Col>
            <ResultSummary text={`${data?.length} result found`}/>
          </Col>
          <Col>
            <Row>
              <Col>
                {/*<Row justify="end">*/}
                {/*  <ChangeView/>*/}
                {/*</Row>*/}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col>
        <div className={styles.collapseWrapper}>
          <TeamsHeader headersItem={headerItems} />
          <CustomCollapse data={data || []} panelItemsRenderer={MembersTable} className={styles.overwriteCollapse}/>
          </div>
      </Col>
    </Col>
  )
}
