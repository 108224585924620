import { Collapse } from 'antd';
import React from 'react';

import { ICollapse, ICollapseDataItem } from '../../../types/components/display.types';

const { Panel } = Collapse;

export const CustomPanel = ({ children, header, key, ...props }: ICollapseDataItem) => {
  return (
    <Panel header={header} key={key} {...props}>
      { children }
    </Panel>
  )
}
export const CustomCollapse = ({ data, ...props }: ICollapse) => {
  const Component = props.panelItemsRenderer;

  return (
      <Collapse
        defaultActiveKey={['1']}
        {...props}
      >
        {
          !!data.length && data?.map(({ key,header, ...panelProps}) => {
            return (
                <CustomPanel  key={key} header={header} {...panelProps?.customPanelProps}>
                  { panelProps.text && <p>{panelProps.text}</p>}
                  { Component && <Component {...panelProps.panelItemsRendererProps}/> }
                </CustomPanel>
            )
          })
        }
      </Collapse>
  );
};
