import { IStudent } from '../types/applicant.types';
import { ExamTypeEnum, IExamColumn } from '../types/exam.types';

export default function getStudentExamsFinalScore(
  data: IStudent | IExamColumn,
) {
  let midtermExamsCount = 0;
  let applicantMidtermExamsGradeMedian = data.applicantExams?.reduce(
    (acc, examData) => {
      if (examData?.exam?.examType === ExamTypeEnum.MIDTERM && examData.grade) {
        acc += examData.grade;
        midtermExamsCount += 1;
      }

      return acc;
    },
    0,
  );

  const applicantFinalExamGrade = data.applicantExams?.reduce(
    (acc, examData) => {
      if (examData?.exam?.examType === ExamTypeEnum.FINAL && examData.grade) {
        acc += examData.grade;
      }
      return acc;
    },
    0,
  );

  applicantMidtermExamsGradeMedian = applicantMidtermExamsGradeMedian
    ? applicantMidtermExamsGradeMedian / midtermExamsCount
    : 0;

  const finalScore = applicantFinalExamGrade
    ? Math.round(
        (applicantMidtermExamsGradeMedian + applicantFinalExamGrade) / 2,
      )
    : 'N/A';

  return finalScore;
}
