import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { IS_NOT_PROD } from '../constants/constants';
import { applicantsSlice } from './features/applicants/applicantsSlice';
import { certificateSlice } from './features/certificates/certificatesSlice';
import { entityActionSLice } from './features/LastEntityAction/LastEntityActionSlice';
import { mailingSlice } from './features/mailing/mailingSlice';
import { sharedSelectsSlice } from './features/sharedSelects/sharedSelectsSlice';
import { smsSlice } from './features/sms/smsSlice';
import { studentAgreementSlice } from './features/student/studentAgreement/studentAgreementSlice';

export const store = configureStore({
  reducer: {
    massMailing: mailingSlice.reducer,
    smsSlice: smsSlice.reducer,
    certificate: certificateSlice.reducer,
    applicants: applicantsSlice.reducer,
    sharedSelects: sharedSelectsSlice.reducer,
    studentAgreement: studentAgreementSlice.reducer,
    entityAction: entityActionSLice.reducer,
  },
  devTools: IS_NOT_PROD,
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
