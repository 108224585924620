import { stringifyObject } from '../helpers/stringifyObj.helper';
import type { IComment, ICreateComment } from '../types/comment.types';
import type {
  IConsultant,
  IConsultantFilterResponse,
  ICreateConsultation,
  IFilterConsultant,
  IUpdateConsultation,
} from '../types/consultation.types';
import type { Sorters } from '../types/general.types';
import axios from './config.service';

export async function createConsultation(consultant: ICreateConsultation) {
  const r = await axios.post('/consultation', consultant);
  return r.data as Promise<IConsultant>;
}

export async function updateConsultation({ consultant, id }: { id: number; consultant: IUpdateConsultation }) {
  const r = await axios.put(`/consultation/${id}`, consultant);
  return r.data as Promise<IConsultant>;
}

export async function filterConsultant(criterias: Partial<IFilterConsultant>, query: Partial<Sorters>) {
  const queryString = stringifyObject(query);
  const r = await axios.post(`/consultation/filter?${queryString}`, criterias);
  return r.data as Promise<IConsultantFilterResponse>;
}

export const getConsultantById = async (id: number) => {
  const r = await axios.get(`/consultation/${id}`);
  return r.data as Promise<IConsultant>;
};

export const deleteConsultantById = async (id: number) => {
  const r = await axios.delete(`/consultation/${id}`);
  return r.data as Promise<boolean>;
};

export const bulkDeleteConsultation = async (ids: number[]) => {
  return await axios.all(ids.map(deleteConsultantById));
};

export async function addConsultantComment({
  consultantId,
  comment,
}: {
  consultantId: number;
  comment: ICreateComment;
}) {
  const r = await axios.post(`/consultation/${consultantId}/comment`, comment);
  return r.data as Promise<IComment[]>;
}

export const exportConsultations = async (id: number[]) => {
  return await axios.post(
    `consultation/export`,
    { id },
    {
      responseType: 'blob',
    },
  );
};

export const updateConsultationComment = async ({commentId, comment}: {commentId: number, comment: ICreateComment}) => {
  return await axios.put(`/consultation/comment/${commentId}`, comment);
};

export const deleteConsultationComment = async ({commentId}: { commentId: number }) => {
  const r = await axios.delete(`/consultation/comment/${commentId}`);
  return r.data as Promise<boolean>;
};
