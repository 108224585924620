import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';

const { required } = messages;

export const CreateApplicantPaymentFormSchema = Yup.object().shape({
  date: Yup.string().required(required),
  amount: Yup.number().required(required),
});
