import { RoutesConstants } from "../../../helpers/routes.helpers";
import { IDashboardLinks } from "../../shared/types/path.types";

export const conditionalAdminSection: IDashboardLinks = {
  Advanced: {
    id: 3,
    name: 'Advanced',
    type: "SECTION",
    items: {
      students: {
        id: 5,
        name: 'Students',
        path: RoutesConstants.students(),
      },
      user: {
        id: 0,
        name: 'User',
        path: '/advanced/users/',
      },
      team: {
        id: 1,
        name: 'Teams',
        path: '/advanced/teams/',
      }
    }
  }
}

export const UserDashBoardLinks: IDashboardLinks = {
  Dashboard: {
    id: 0,
    name: 'Dashboard',
    path: RoutesConstants.dashboard(),
  },
  infrastructure: {
    id: 1,
    name: 'Infrastructure',
    type: "SECTION",
    items: {
      tracks: {
        id: 0,
        name: 'Tracks',
        path: RoutesConstants.tracks(),
      },
      instructors: {
        id: 1,
        name: 'Instructors',
        path: RoutesConstants.instructors(),
      },
      courses: {
        id: 2,
        name: 'Courses',
        path: RoutesConstants.courses(),
      },
      groups: {
        id: 3,
        name: 'groups',
        type: 'MENU',
        items: {
          planningGroups: {
            id: 0,
            name: 'Planning Groups',
            path: RoutesConstants.planningGroups(),
          },
          ongoingGroups: {
            id: 1,
            name: 'Ongoing Groups',
            path: RoutesConstants.ongoingGroups(),
          },
          waitingForDemoGroups: {
            id: 2,
            name: 'Waiting for Demo Groups',
            path: RoutesConstants.waitingForDemo(),
          },
          terminatedGroups: {
            id: 3,
            name: 'Terminated Groups',
            path: RoutesConstants.terminatedGroups(),
          },
        }
      },
      applicants: {
        id: 4,
        name: 'Applicants',
        path: RoutesConstants.applicants(),
      },
      consultations: {
        id: 6,
        name: 'Consultations',
        path: RoutesConstants.consultations(),
      },
      tasks: {
        id: 7,
        name: 'Tasks',
        path: RoutesConstants.tasks(),
      },
      reminder: {
        id: 8,
        name: 'Reminder',
        path: RoutesConstants.reminder(),
      },
    }
  },
  services: {
    id: 2,
    name: 'Services',
    type: "SECTION",
    items: {
      massMailing: {
        id: 9,
        name: 'Mass mailing',
        path: RoutesConstants.massMailing(),
      },
      MasSms: {
        id: 10,
        name: 'Mass sms',
        path: RoutesConstants.massSms(),
      },
      certificates: {
        id: 11,
        name: 'Certificates',
        path: RoutesConstants.certificates(),
      },
    }
  },
}
