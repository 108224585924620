import { Col, Pagination, Row, Spin, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { RenderBadge } from '../../../../components/display/Badges/Badges';
import { ColumnModal } from '../../../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../../../components/display/ResultSummary/ResultSummary';
import { Button, Flex } from '../../../../components/general';
import { ChangeView } from '../../../../components/general/Icons/Icons';
import { PageSizeOptions } from '../../../../constants/sorters.constants';
import { UseQueryTypes } from '../../../../constants/useQuery.constants';
import { useTable } from '../../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../../../hooks/useRowSelection.adapter';
import { getDefaultApplicantDetailColumns } from '../../../../schemas/applicants/applicantByGroup.schema';
import { filterApplicant, getApplicantCountByCriteriaList } from '../../../../services/applicant.service';
import { getDetailedGroupAbsenteeInfoById, getGroupById } from '../../../../services/groups.service';
import { studentGroupDetail } from '../../../../services/studentGroup.service';
import { selectActionByName } from '../../../../store/features/LastEntityAction/LastEntityActionSlice';
import applicantStatusStyles from '../../../../styles/applicantStatus.module.css';
import type { IStudentBrief } from '../../../../types/applicant.types';
import { GroupStateEnum, IGroup } from '../../../../types/group.types';
import { IStudentGroup } from '../../../../types/studentGroup.types';
import { GeneralInfo } from './components/generalInfo';
import { Schedule } from './components/schedule';
import { Filter } from './components/search';
import Statistics from './components/statistics';
import { groupActions } from './constants';
import styles from './GroupDetail.module.css';
import { getApplicantWithTotalAbsenteesByGroup, getDefaultApplicantFilterOptions, getDefaultFilterOptionsByGroupStatus } from './helper';

export const GROUP_DETAIL = 'GROUP_DETAIL';
const GroupDetail = () => {
  const [isColored, setIsColored] = useState(true);
  const { id } = useParams<{ id: string }>();
  const cache = useQueryClient();

  const groupQuery = useQuery([UseQueryTypes.GROUP_BY_ID, id], () => getGroupById(Number(id)));

  const { sorterOptions, filterOptions, changeOptions } = useFilter(
    getDefaultApplicantFilterOptions(groupQuery.data || ({ id: Number(id) } as IGroup)),
  );

  const filterParams = useMemo(() => {
    if (!groupQuery?.data?.groupState) {
      return {};
    }
    const isTerminated = groupQuery?.data?.groupState === GroupStateEnum.TERMINATED;
    const filter = isTerminated ? studentGroupDetail : filterApplicant;
    const options: any = {
      ...getDefaultFilterOptionsByGroupStatus(
        [Number(id)],
        groupQuery?.data?.groupState as GroupStateEnum,
        groupQuery?.data?.course.level,
      ),
      ...filterOptions,
    };
    isTerminated && delete options.groupState;

    const filterProps = [options];

    !isTerminated && filterProps.push(sorterOptions);

    return {
      groupState: groupQuery?.data?.groupState,
      func: filter,
      props: filterProps,
    };
  }, [id, filterOptions, sorterOptions, groupQuery?.data]);

  const { data: applicantResponse, ...applicantGetter } = useQuery(
    [UseQueryTypes.APPLICANTS, filterParams, id, filterOptions, sorterOptions],
    // @ts-ignore
    () => filterParams.func(...filterParams.props, sorterOptions),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.APPLICANTS, data);
      },
      select: ({ applicants, ...rest }) => {
        const filtered =
          filterParams.groupState === GroupStateEnum.TERMINATED
            ? applicants.map((item: IStudentGroup | IStudentBrief) => {
                // @ts-ignore
                const { student, ...rest } = item;
                return { ...student, ...rest };
              })
            : applicants;

        return {
          applicants: filtered,
          ...rest,
        };
      },
      enabled: !!filterParams.props && !!filterParams.groupState,
    },
  );

  const { data: groupAbsentees } = useQuery(
    UseQueryTypes.GROUP_ABSENTEES,
    () => getDetailedGroupAbsenteeInfoById(Number(id)),
  );

  const applicantTable = useTable(getDefaultApplicantDetailColumns());
  const modalAdapter = useColumnModalAdapter(applicantTable.getTableColumnSettings());

  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const groupSavedAction = selectActionByName(GROUP_DETAIL);

  const handleIsColoredStatus = () => {
    setIsColored((prev) => !prev);
  };

  const restoreLastAction = () => {
    if (groupSavedAction?.data) {
      const { id: actionId, data } = groupSavedAction;
      if (id !== actionId) return;

      data.length && rowSelectionAdapter.setSelectedRowKeys(data);
    }
  };
  useEffect(restoreLastAction, [groupSavedAction, applicantResponse]);

  const isScreenLoading = applicantGetter.isLoading || groupQuery.isLoading;

  const applicantsTotalSize = applicantResponse?.totalElements || 0;

  const applicants = getApplicantWithTotalAbsenteesByGroup(applicantResponse?.applicants || [], groupAbsentees?.students || [])

  const applicantsCountByCriteria =  useQuery(
    [UseQueryTypes.APPLICANTS_COUNT_BY_CRITERIA],
    () => getApplicantCountByCriteriaList( [{ countBy: 'contactLater', criteria: { contactLater: true, groupIds: [Number(id)] }}]),
    {
      enabled: groupQuery.data?.groupState === GroupStateEnum.ONGOING || groupQuery.data?.groupState === GroupStateEnum.PLANNING
    }
  );

  const handleFilterByContactLater = () => {
    changeOptions({
      filterOptions:{
        contactLater: true
      },
    }, { isNewOption: true, path: '' })
  }

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16} style={{ height: '100%', marginTop: '20px' }}>
          {groupQuery?.data && <GeneralInfo group={groupQuery.data} />}
          {groupQuery?.data && <Schedule schedule={groupQuery.data.schedule} course={groupQuery.data.course} />}

          <Col style={{ margin: '20px 0' }} span={24}>
            <Filter />
            <Statistics filterParams={filterParams} />
            {
              (groupQuery.data?.groupState === GroupStateEnum.ONGOING || groupQuery.data?.groupState === GroupStateEnum.PLANNING) &&
              <RenderBadge
                badge={{
                  title: 'Contact later',
                  data: applicantsCountByCriteria?.data?.contactLater || 0,
                }}
                handleBadgeClick={handleFilterByContactLater}
              />
            }
            <Flex flexWrap="wrap" alignItems={'baseline'} style={{ width: '100%', padding: '10px', gap: '8px' }}>
              { groupActions.map(({ id, Component }) => ( <Component key={id} />)) }
            </Flex>

            <Flex justifyContent="space-between" alignItems={'baseline'} style={{ width: '100%', marginBottom: 20 }}>
              <ResultSummary text={`${applicantsTotalSize} result found`} />
              <Col sm={20} xs={24}>
                <Row justify="end" style={{ gap: '8px' }}>
                  <Button onClick={handleIsColoredStatus} variant="primary" className={styles.button}>
                    {isColored ? 'Disable' : 'Enable'} Status Color
                  </Button>
                  <ChangeView onClick={modalAdapter.openSettings} />
                </Row>
              </Col>
            </Flex>

            <Table<IStudentBrief>
              {...applicantTable.getTableProps({
                rowKey: (student) => student.id,
                rowClassName: ({ status }) =>
                  isColored ? applicantStatusStyles[status.toLowerCase()] : applicantStatusStyles.default,
                // @ts-ignore
                dataSource: applicants,
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />
            <Pagination
              {...applicantTable.getPaginationProps({
                style: { marginTop: 20 },
                total: applicantsTotalSize,
                defaultPageSize: PageSizeOptions.defaultPageSize,
              })}
            />

            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default GroupDetail;
