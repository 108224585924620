import React from 'react';

interface IFlexProps {
  direction?: 'row' | 'column';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'space-between'
    | 'flex-end'
    | 'space-around';
  flexWrap?: 'nowrap' | 'wrap';
  style?: React.CSSProperties;
  className?: string;
  onClick?: (e: any) => void;
}

const Flex: React.FC<IFlexProps> = ({
  children,
  direction = 'row',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  flexWrap = 'nowrap',
  style = {},
  className = '',
  ...props
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction,
        alignItems,
        justifyContent,
        flexWrap,
        ...style,
      }}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
};

export default Flex;
