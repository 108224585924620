import getStudentExamsFinalScore from '../../../../helpers/getStudentExamsFinalScore';
import {
  FeedbackApplicantColumn,
  NameApplicantColumn,
} from '../../../../schemas/applicants/applicant.renderer';
import { PhoneService } from '../../../../services/phone.service';
import type { IStudentBrief } from '../../../../types/applicant.types';
import type { ColumnSchema } from '../../../../types/column.types';

export const getDefaultApplicantDetailColumns =
  (): ColumnSchema<IStudentBrief> => ({
    uuid: 'instructorDefaultApplicantDetailColumns',
    schema: {
      name: {
        dataIndex: 'name',
        title: 'Student',
        width: 200,
        fixed: 'left',
        sorter: () => -1,
        render: NameApplicantColumn,
      },
      phoneNumber: {
        title: 'Phone',
        width: 200,
        sorter: () => -1,
        render: (_, applicant) => PhoneService.normalize(applicant.phoneNumber),
      },
      totalAbsentees: {
        title: 'Absentees',
        dataIndex: 'totalAbsentees',
        width: 150,
        sorter: () => -1,
        render: (_, applicant) => applicant.totalAbsentees,
      },
      finalScore: {
        title: 'Final Score',
        dataIndex: 'finalScore',
        width: 200,
        render: (_, student) => getStudentExamsFinalScore(student),
      },
      feedback: {
        title: 'Feedback',
        width: 50,
        render: FeedbackApplicantColumn,
      },
    },
    hiddenColumns: ['nameHy', 'nameEn'],
  });
