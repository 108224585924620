import { ITaskGET } from './task.types';

export enum GlobalNotificationTypes {
  NOTIFICATION = 'NOTIFICATION',
  REMINDER = 'REMINDER',
}

export enum RelatedResource {
  NOTIFICATION = 'NOTIFICATION',
  TASK = 'TASK',
}

export enum NotificationType {
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export interface INotification {
  title: string | null;
  description: string;
  type: NotificationType;
  status: NotificationStatus;
  owner: string;
  id: number;
  created_at: string;
  notifyDate: null;
  relatedResource: RelatedResource;
  task: ITaskGET;
}

export interface INotificationNormalized {
  data: INotification;
  notificationType: GlobalNotificationTypes;
}

export type ICreateOrUpdateNotification = Omit<INotification, 'id' | 'status' | 'ownerUuid'>;

interface INotificationFilterProps {
  status: NotificationStatus;
  type: NotificationType;
  dateFrom: string;
  dateTo: string;
}

export type INotificationFilter = Partial<INotificationFilterProps>;

export interface INotificationsResponse {
  currentPage: number;
  notifications: INotification[];
  status: NotificationStatus;
  totalElements: number;
  totalPages: number;
}
