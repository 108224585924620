import React from 'react';

import { IStudentAttachment } from '../../types/studentAttachment.types';
import { PdfItemViewer } from '../Agreements/AgreementsRenderer/AgreementsRenderer';
import styles from '../Agreements/AgreementsRenderer/AgreementsRenderer.module.css';

const CVRenderer = ({ cv }: { cv: IStudentAttachment }) => {
  return (
      <div className={styles.wrapper}>
          <PdfItemViewer link={cv.link} previewBtnTitle='See the CV' key={cv.link} />
      </div>
  );
};

export default CVRenderer;
