import { Col, Row, Typography } from 'antd';
import React from 'react';

import { generalSectionMapper } from '../../../../../../helpers/applicants/applicantDetail.helper';
import { IApplicantDetailGeneral } from '../../../../../../types/applicantDetail.types';
import GeneralInfoCol from './GeneralInfoCol';

const { Title } = Typography;

export const General = ({ general }: { general: IApplicantDetailGeneral }) => {
  return (
    <Row gutter={16} style={{ height: '100%', marginTop: '20px' }}>
      <Col span={24}>
        <Title style={{ marginBottom: 20 }} level={4}>
          General info
        </Title>
      </Col>
      <Row style={{ columnGap: '16px', rowGap: '16px' }}>
        {generalSectionMapper(general).map((column) => (
          <GeneralInfoCol {...column} />
        ))}
      </Row>
    </Row>
  );
};
