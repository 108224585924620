import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Badges } from '../../../../../components/display';
import { TBadge  } from '../../../../../components/display/Badges/Badges';
import { Flex } from '../../../../../components/general';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import {
  getStatisticsByApplicantStatus,
} from '../../../../../helpers/applicants/calculateByStatus.helper';
import { getGroupApplicantsCount } from '../../../../../services/groups.service';
import { WedgedTypes } from '../../../../../types/general.types';

export default function Statistics({ filterParams }: { filterParams: any }) {
  const { id } = useParams<{ id: string }>();

  const groupApplicantsCount = useQuery(
    [UseQueryTypes.GROUP_APPLICANTS_COUNT, id],
    () => getGroupApplicantsCount(Number(id), filterParams.groupState),
    { enabled: !!filterParams.groupState},
  );

  const applicantsStatuses = useMemo(() => {
    return groupApplicantsCount?.data ? getStatisticsByApplicantStatus(groupApplicantsCount?.data) : {}
  },[groupApplicantsCount?.data])

  return (
    <Flex style={{ marginTop: '15px' }}>
      {applicantsStatuses && (
        <Badges
          wedgedType={WedgedTypes.APPLICANT_STATUS}
          badges={Object.entries(applicantsStatuses).map((i) => i[1]) as TBadge[]}
        />
      )}
    </Flex>
  )
}
