import { StudentTableBodySchema, StudentTableHeaderSchema } from '../../types/studentTable.types';
import styles from './StudentTable.module.css';

const filterRows = (schema: StudentTableHeaderSchema) =>
  Object.values(schema).filter((schemaRow) => !schemaRow.isAction);
const getRowAction = (schema: StudentTableHeaderSchema) =>
  Object.values(schema).find((schemaRow) => schemaRow.isAction);

const StudentTableHeader = ({ tableHeaderSchema }: { tableHeaderSchema: StudentTableHeaderSchema }) => {
  const headerSchemaValues = filterRows(tableHeaderSchema);
  const headerColsCount = headerSchemaValues.length;

  return (
    <div className={styles.headWrapper} style={{ gridTemplateColumns: `1fr repeat(${headerColsCount - 2}, 2fr) 1fr` }}>
      {headerSchemaValues.map((tableHeaderCol) => {
        return (
          <span className={styles.headRow} key={tableHeaderCol.key}>
            {tableHeaderCol.title}
          </span>
        );
      })}
    </div>
  );
};

const TableRowItem = ({ header, children }: { header?: string; children?: string | JSX.Element | number }) => {
  return (
    <div className={styles.bodyRow}>
      <span className={styles.rowDataTitle}>{header}</span>
      <span>{children}</span>
    </div>
  );
};

function TableRow({
  tableData,
  tableHeaderSchema,
}: {
  tableData: StudentTableBodySchema;
  tableHeaderSchema: StudentTableHeaderSchema;
}) {
  const headerRows = filterRows(tableHeaderSchema);
  const rowAction = getRowAction(tableHeaderSchema);

  return (
    <div
      className={styles.bodyRowWrapper}
      style={{ gridTemplateColumns: `1fr repeat(${headerRows.length - 2}, 2fr) 1fr` }}
    >
      {headerRows.map(({ key, title }) => {
        return (
          <TableRowItem key={key} header={title}>
            {tableData[key].renderer()}
          </TableRowItem>
        );
      })}
      {rowAction && <div className={styles.rowActionWrapper}>{tableData[rowAction.key].renderer()}</div>}
    </div>
  );
}

export function StudentTable<T extends { key: string | number }>({
  dataSource,
  renderSchema,
  tableHeaderSchema,
}: {
  dataSource: T[];
  renderSchema: (item: T) => StudentTableBodySchema;
  tableHeaderSchema: StudentTableHeaderSchema;
}) {
  const dataSourceConvertedToSchema = dataSource.map(renderSchema);

  return (
    <div className={styles.wrapper}>
      <StudentTableHeader tableHeaderSchema={tableHeaderSchema} />
      <div className={styles.bodyWrapper}>
        {dataSourceConvertedToSchema.map((table, index) => (
          <TableRow key={index} tableData={table} tableHeaderSchema={tableHeaderSchema} />
        ))}
      </div>
    </div>
  );
}
