import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ImageUpload } from '../../../components/display/ImageUpload/ImageUpload';
import { Button, Flex, Input, SingleSelectFormik } from '../../../components/general';
import { MultiselectFormik } from '../../../components/general/Multiselect/Multiselect';
import { InstructorRoleOptions, InstructorStatusOptions } from '../../../constants/instructor.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { getAllTracks } from '../../../services/track.service';
import type { ITransform } from '../../../types/form.types';
import type { ICreateInstructor } from '../../../types/instructor.types';
import { TransformInstructorFormSchema } from '../schemas/transformInstructor.form.schema';

interface ITransformInstructorForm {
  isEditForm?: boolean;
}

export const TransformInstructorForm: React.FC<ITransform<ICreateInstructor> & ITransformInstructorForm> = ({
  initialValues,
  onSubmit,
  submitText = 'Apply',
  isEditForm = false,
}) => {
  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);

  const tracksContext = useMemo(() => {
    return generateContext(tracksQuery.data || [], (track) => ({
      label: track.name,
      value: track.id,
    }));
  }, [tracksQuery.data]);

  return (
    <Spin spinning={tracksQuery.isLoading}>
      <Formik
        initialValues={initialValues}
        validationSchema={TransformInstructorFormSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(props) => (
          <Form
            name="add-instructor"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="name" label="Full Name" component={Input} required />
            <ErrorMessage name="name" component="span" className="error" />

            <Field name="email" label="Email" component={Input} required />
            <ErrorMessage name="email" component="span" className="error" />

            <Field name="phoneNumber" label="Phone Number" component={Input} required />
            <ErrorMessage name="phoneNumber" component="span" className="error" />
            {!isEditForm && (
              <Field name="password" label="One time password" type="password" component={Input} required />
            )}
            <Field name="linkedInProfile" label="Linkedin" component={Input} />
            <ErrorMessage name="linkedInProfile" component="span" className="error" />

            <Field
              name="status"
              label="Status"
              options={InstructorStatusOptions}
              component={SingleSelectFormik}
              required
            />
            <ErrorMessage name="status" component="span" className="error" />

            <Field name="role" label="Role" options={InstructorRoleOptions} component={SingleSelectFormik} required />
            <ErrorMessage name="role" component="span" className="error" />

            <Field name="tracks" label="Track" options={tracksContext} component={MultiselectFormik} required />
            <ErrorMessage name="tracks" component="span" className="error" />

            <ImageUpload
              name="signatureLink"
              placeholder={`${initialValues.signatureLink ? 'Update' : 'Upload'} Instructor signature`}
              label="Signature"
            />
            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button
                htmlType="button"
                style={{ minWidth: 160, marginRight: 8 }}
                onClick={() => {
                  props.handleReset();
                }}
              >
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                {submitText}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
