import { IStudentByCourse } from '../../types/applicant.types';
import { ColumnSchema } from '../../types/column.types';

export const applicantByCourseColumns: ColumnSchema<IStudentByCourse> = {
  uuid: 'applicantByCourseColumns',
  schema: {
    courseName: {
      title: 'Course Name',
      dataIndex: 'courseName',
    },
    count: {
      title: 'Count',
      dataIndex: 'count',
      sorter: () => 0,
      render: (_, course) => course.applicantCount,
    },
  },
};
