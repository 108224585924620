import { LocationSearch } from "../../../../../../helpers/stringifyObj.helper";
import { IExtendedGroupStudents } from "../../../../../../types/payment.types";

export enum SearchTypesEnum {
  statuses = 'statuses',
  dropoutMonths = 'dropoutMonths'
}

const filterApplicantsByStatus = (groupStudent: IExtendedGroupStudents, search: LocationSearch) =>
    search?.statuses?.includes(groupStudent.status)

const filterApplicantsByDropoutMonth = (groupStudent: IExtendedGroupStudents, search: LocationSearch) =>
    search?.dropoutMonths?.includes(groupStudent?.dropoutMonth)

export const filterApplicants = (search: LocationSearch, groupStudents: IExtendedGroupStudents[]=[]) => {
  const searchType  = search?.dropoutMonths ? SearchTypesEnum.dropoutMonths : search?.statuses ? SearchTypesEnum.statuses : null
  const filterBy = (searchType === SearchTypesEnum.statuses) ?  filterApplicantsByStatus : filterApplicantsByDropoutMonth

  return searchType && groupStudents.filter((item) => filterBy(item, search))
}
