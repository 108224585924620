import { ApplicantCreateStatusOptions, ApplicantRegistrationSource } from '../../../constants/applicant.constants';
import { DateService } from '../../../services/date.service';
import { IApplicantDetailApplication } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';
import { GroupColumn, HasAccountColumn, OptionsColumn } from '../applicantDetail.renderer';

export const applicationsColumns: ColumnSchema<IApplicantDetailApplication> = {
  uuid: 'applicationsColumn',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      width: 100,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    groupName: {
      title: 'Group',
      width: 240,
      render: (groupName, { groupId,courseName }) => GroupColumn({groupName, courseName}, groupId),
    },
    status: {
      title: 'Status',
      width: 120,
      render: (status) => OptionsColumn(status, ApplicantCreateStatusOptions),
    },
    registrationSource: {
      title: 'Registration Source',
      width: 120,
      render: (status) => OptionsColumn(status, ApplicantRegistrationSource),
    },
    hasAccount: {
      title: 'Has Account',
      width: 50,
      render: HasAccountColumn,
    },
  },
};
