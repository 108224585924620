import cn from 'classnames';

import styles from './Progress.module.css';

enum ProgressLevelsVariants {
  First = 'first',
  Second = 'second',
  Third = 'third',
  Forth = 'fourth',
}
interface IProgressLevels {
  [key: string]: ProgressLevelsVariants;
}

interface IProgressProps {
  percent: number;
}

const ProgressLevels: IProgressLevels = {
  1: ProgressLevelsVariants.First,
  2: ProgressLevelsVariants.Second,
  3: ProgressLevelsVariants.Third,
  4: ProgressLevelsVariants.Forth,
};

const getClassNameByPercent = (p: number) => {
  if (p < 25) {
    return ProgressLevels[1];
  } else if (p < 50) {
    return ProgressLevels[2];
  } else if (p < 75) {
    return ProgressLevels[3];
  }

  return ProgressLevels[4];
};

export default function Progress({ percent }: IProgressProps) {
  const classnames = cn(styles.inner, styles[getClassNameByPercent(percent)]);
  return (
    <div className={styles.bar}>
      <div className={classnames} style={{ width: percent }}></div>
      <div className={styles.text}>{percent}%</div>
    </div>
  );
}
