import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import CustomDateAndTimePicker from '../../components/display/CustomTimePicker/CustomTimePicker';
import { Button, Flex, Input, SingleSelectFormik, TextArea } from '../../components/general';
import { ReminderTypesOptions } from '../../constants/reminder.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { createReminder } from '../../services/reminder.service';
import { IAppForm } from '../../types/form.types';
import { ICreateOrUpdateReminder, ReminderType } from '../../types/reminder.types';
import { CreateReminderFormSchema } from './schemas/reminder.forms.schema';

const reminderFormInitialValues: ICreateOrUpdateReminder = {
  title: '',
  description: '',
  type: ReminderType.GROUP,
  remindingDate: new Date().toISOString(),
};

export const CreateReminderForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();

  const cache = useQueryClient();

  const createReminderMutation = useMutation(createReminder, {
    onSuccess: async () => {
      await cache.invalidateQueries(UseQueryTypes.REMINDER);
      await cache.invalidateQueries(UseQueryTypes.FILTER_REMINDER);
      await cache.invalidateQueries(UseQueryTypes.REMINDER_COUNT);
    },
  });

  const handleCreateReminder = async (newReminder: ICreateOrUpdateReminder) => {
    const mutationFunc = () => createReminderMutation.mutateAsync(newReminder);

    await withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const isScreenLoading = createReminderMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        initialValues={reminderFormInitialValues}
        validationSchema={CreateReminderFormSchema}
        onSubmit={handleCreateReminder}
      >
        {(props) => {
          return (
            <Form
              name="create-reminder"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field name="title" label="Title" component={Input} required />
              <ErrorMessage name="title" component="span" className="error" />

              <Field
                name="description"
                label="Description"
                placeholder="Enter description"
                component={TextArea}
                required
              />

              <ErrorMessage name="description" component="span" className="error" />

              <Field
                name="type"
                label="Reminder type"
                options={ReminderTypesOptions}
                component={SingleSelectFormik}
                required
              />
              <ErrorMessage name="type" component="span" className="error" />

              <CustomDateAndTimePicker
                getDate={(date) => {
                  props.setFieldValue('remindingDate', date);
                }}
              />

              <ErrorMessage name="remindingDate" component="span" className="error" />

              <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
                <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
