import { Col, Row } from 'antd';
import React from 'react';

import { Search } from '../../../components/general';

interface Props {
  search: string | undefined;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ApplicantSearchFilter = ({ search, handleSearch }: Props) => {
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[8, 8]} align="middle">
          <Search
            style={{}}
            placeholder="Search"
            defaultValue={search}
            onChange={handleSearch}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default ApplicantSearchFilter;
