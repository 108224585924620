import { IUserTypes } from "../../../types/user.types";

export enum ForgetPasswordViewsEnum {
    SEND_EMAIL = 'SEND_EMAIL',
    FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS',
}

export enum ResetPasswordViewsEnum {
  LOADING = 'LOADING',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_EXPIRED = 'RESET_PASSWORD_EXPIRED',
}

export type userTypeKey = keyof IUserTypes;
