import { notification } from 'antd';
import { createContext, FC, useContext, useState } from 'react';

import * as DrawerComponents from '../components/display/DrawerComponents';
import { DrawerComponentsEnum } from '../components/display/DrawerComponents';
import { Drawer } from '../components/general';
import { AppFormsEnum } from '../forms';
import { DrawerTypes } from '../types/drawer.types';
import * as AppForms from './../forms';

notification.config({
  maxCount: 2,
});

const drawerContext = createContext({
  isDrawerOpen: false,
  openDrawer: (
    componentName: DrawerComponentsEnum | AppFormsEnum,
    title?: string,
    type: DrawerTypes = DrawerTypes.Form,
    hasOutSideClick?: boolean,
  ) => {},
  closeDrawer: () => {},
});

let Component: any;

export const DrawerProvider: FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [hasOutSideClick, setHasOutSideClick] = useState(true);
  const [drawerType, setDrawerType] = useState<DrawerTypes>(DrawerTypes.Form);
  const [componentName, setComponentName] = useState<
    DrawerComponentsEnum | AppFormsEnum
  >(AppFormsEnum.CreateTaskForm);

  const openDrawer = (
    componentName: DrawerComponentsEnum | AppFormsEnum,
    title?: string,
    type: DrawerTypes = DrawerTypes.Form,
    hasOutSideClick: boolean = true,
  ) => {
    setComponentName(componentName);
    setIsDrawerOpen(true);
    setDrawerType(type);
    setHasOutSideClick(hasOutSideClick);
    title && setDrawerTitle(title);
  };

  const closeDrawer = () => setIsDrawerOpen(false);

  switch (drawerType) {
    case DrawerTypes.Component:
      Component = DrawerComponents[componentName as DrawerComponentsEnum];
      break;
    case DrawerTypes.Form:
      // @ts-ignore
      Component = AppForms[componentName];
      break;
    default:
      throw new Error('Choose one of types');
  }

  return (
    <drawerContext.Provider value={{ isDrawerOpen, openDrawer, closeDrawer }}>
      {children}
      <Drawer
        hasOutSideClick={hasOutSideClick}
        title={drawerTitle}
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        <Component />
      </Drawer>
    </drawerContext.Provider>
  );
};

export const useDrawer = () => {
  return useContext(drawerContext);
};
