export const resourceResponseToAdapter = <T>(
    data: T,
): any | undefined => {

    const resultData: any = {};

    if (data) {
        Object.entries(data).forEach(([key, value]) => {
            if (key !== 'id') {
                resultData[key] = value;
            }
        });
        return resultData;
    }
    return undefined;
}

export const validateNumber = (value: any) => {
  return /^[0-9]+$/.test(value)
}
