import { InstructorRoleEnum, InstructorStatusEnum, InstructorTypeEnum } from '../types/instructor.types';

export const InstructorStatusOptions = {
  [InstructorStatusEnum.TEACHING_NOW]: {
    label: 'Teaching Now',
    value: InstructorStatusEnum.TEACHING_NOW,
  },
  [InstructorStatusEnum.ON_A_BREAK]: {
    label: 'On Break',
    value: InstructorStatusEnum.ON_A_BREAK,
  },
  [InstructorStatusEnum.NOT_AVAILABLE]: {
    label: 'Not Available',
    value: InstructorStatusEnum.NOT_AVAILABLE,
  },
  [InstructorStatusEnum.POTENTIAL_INSTRUCTOR]: {
    label: 'Potential instructor',
    value: InstructorStatusEnum.POTENTIAL_INSTRUCTOR,
  },
};

export const InstructorStatusColor = {
  [InstructorStatusEnum.TEACHING_NOW]: '#A8DD8D',
  [InstructorStatusEnum.ON_A_BREAK]: '#FFDF83',
  [InstructorStatusEnum.NOT_AVAILABLE]: '#FFAAB9',
  [InstructorStatusEnum.POTENTIAL_INSTRUCTOR]: '#82C3FF',
};

export const InstructorRoleOptions = {
  [InstructorRoleEnum.INSTRUCTOR]: {
    label: 'Instructor',
    value: InstructorRoleEnum.INSTRUCTOR,
  },
  [InstructorRoleEnum.PRACTISE_LEAD]: {
    label: 'Practise Lead',
    value: InstructorRoleEnum.PRACTISE_LEAD,
  },
};

export const InstructorTypesOptions = {
  [InstructorTypeEnum.ASSISTANT]: {
    label: 'Assistant',
    value: InstructorTypeEnum.ASSISTANT,
  },
  [InstructorTypeEnum.LECTURER]: {
    label: 'Lecturer',
    value: InstructorTypeEnum.LECTURER,
  },
  [InstructorTypeEnum.TUTOR]: {
    label: 'Tutor',
    value: InstructorTypeEnum.TUTOR,
  },
};

export const InstructorRoles = {
  INSTRUCTOR: 'Instructor',
  PRACTISE_LEAD: 'Practise Lead',
};
