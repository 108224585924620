import { USER_TEAM_POSITION } from "../../constants/user.constants";
import type { ColumnSchema } from '../../types/column.types';
import { IMember, UserTeamPosition } from '../../types/user.types';
import { customHeading } from "../users/customHeading";
import styles from '../users/user.module.css'
import {
  AvatarColumn,
  EmailUserColumn,
} from '../users/user.renderer';

export const defaultTeamMembersColumns: ColumnSchema<IMember> = {
  uuid: 'defaultTeamColumns',
  schema: {
    avatar: {
      title: customHeading('Members'),
      render: AvatarColumn,
      width: 350,
      className: styles.overwrite,
    },
    roles: {
      title: customHeading('Role'),
      width: 200,
      render: (_, user) => USER_TEAM_POSITION[user.teamPosition as keyof typeof UserTeamPosition]?.label,
      className: styles.overwrite,
    },
    email: {
      title: customHeading('Email'),
      width: 150,
      render: EmailUserColumn,
      className: styles.overwrite,
    },
  },
};
