export enum StudentQueryConstants {
  STUDENT_FEEDBACKS = 'STUDENT_FEEDBACKS',
  STUDENT_RECEIVED_FEEDBACKS = 'STUDENT_RECEIVED_FEEDBACKS',
  STUDENT_GRADES = 'STUDENT_GRADES',
  STUDENT_ABSENTEES = 'STUDENT_ABSENTEES',
  STUDENT_ATTACHMENTS = 'STUDENT_ATTACHMENTS',
  STUDENT_PAYMENTS = 'STUDENT_PAYMENTS',
  STUDENT_AGREEMENTS = 'STUDENT_AGREEMENTS',
  STUDENT_CV = 'STUDENT_CV',
  STUDENTS_EVALUATION_FORM = 'STUDENTS_EVALUATION_FORM',
}
