import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { loadState, removeState, saveState, } from '../helpers/localStorage.helpers';
import { signOutByOutGroup } from "../services/helper.service";
import {
  getCurrentUser as getCurrentInstructor,
  login as InstructorloginService,
} from '../services/instructors.service';
import {
  getCurrentUser as getCurrentStudent,
  login as studentLoginService
} from '../services/student.service';
import { getCurrentUser, login as userLoginService, } from '../services/user.service';
import { AbstractUser, AuthGroup } from '../types/auth.types';
import { IUserLogin, UserRole } from '../types/user.types';

const loginServices: any = {
  [AuthGroup.INSTRUCTOR]: InstructorloginService,
  [AuthGroup.USER]: userLoginService,
  [AuthGroup.STUDENT]: studentLoginService,
};

const meServices: any = {
  [AuthGroup.INSTRUCTOR]: getCurrentInstructor,
  [AuthGroup.USER]: getCurrentUser,
  [AuthGroup.STUDENT]: getCurrentStudent,
};

export function useUser() {
  const [user, setUser] = useState<Partial<AbstractUser>>(null);

  const navigate = useNavigate();

  const logOut = async () => {
    removeState('token');

    user?.authGroup && await signOutByOutGroup(user?.authGroup);
    setUser(false);
    navigate('/');
  };

  const logIn = (user: IUserLogin, authGroup: string) => {
    saveState('authGroup', authGroup);
    return loginServices[authGroup](user).then(async ({accessToken}: { accessToken: string }) => {
      saveState('token', accessToken);
      const user = await meServices[authGroup]();
      setUser(user.data);
    });
  };

  const refreshUser = () => {
    const authGroup: string = loadState('authGroup') ?? AuthGroup.USER;

    if (typeof meServices[authGroup] === 'function') {
      meServices[authGroup]()
        .then((u: any) => {
          setUser(u.data);
        })
        .catch(() => {
          setUser(false);
        });
    } else {
      logOut()
    }
  };
  useEffect(() => {
    refreshUser();
    return () => {
      setUser(false);
    };
  }, []);

  const checkUserRole = (role: UserRole) => {
    if (user) {
      return Boolean(user.roles?.includes(role));
    }
    return false;
  };

  const isInstructor = () => {
    if (user) {
      return user.authGroup === AuthGroup.INSTRUCTOR;
    }
    return false;
  };

  const isAdmin = () => {
    return checkUserRole(UserRole.ROLE_ADMIN)
  };

  return {
    user,
    logOut,
    logIn,
    refreshUser,
    checkUserRole,
    isInstructor,
    isAdmin,
  };
}
