import { Col, Divider, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ColumnModal } from '../../../../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../../../../components/display/ResultSummary/ResultSummary';
import { Button, FilterButton } from '../../../../../components/general';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { RoutesConstants } from '../../../../../helpers/routes.helpers';
import { useTable } from '../../../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../../../hooks/useFilter.hooks';
import { applicantByCourseColumns } from '../../../../../schemas/applicants/applicantsByCourse.schema';
import type {
  FilterApplicantByCourseOptions,
  IStudentByCourse,
} from '../../../../../types/applicant.types';
import { SortDirectionEnum } from '../../../../../types/general.types';
import {filterStudentByCourse} from "../../../services/userStudent.service";

const defaultFilterApplicantByCourseOptions: Partial<FilterApplicantByCourseOptions> =
  {
    sortDirection: SortDirectionEnum.DESC,
    sortBy:'applicantCount',
    relationalSort: true
  };

export default function ApplicationsByCourse() {
  const navigate = useNavigate();
  const studentTable = useTable<IStudentByCourse>(applicantByCourseColumns);
  const modalAdapter = useColumnModalAdapter(
    studentTable.getTableColumnSettings(),
  );

  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions } = useFilter(
    defaultFilterApplicantByCourseOptions,
  );

  const { term: filterTerm, secondaryTerm, ...tail } = filterOptions;

  const applicantsByCourse = useQuery(
    [UseQueryTypes.STUDENTS_BY_COURSE, filterOptions, sorterOptions],
    () => filterStudentByCourse(filterOptions, sorterOptions),
  );

  const handleOpenApplicantFilterForm = () =>
    openDrawer(AppFormsEnum.FilterApplicantForm, 'Filter Applicant');

  /*const { today, thisWeek, thisMonth } = useApplicantStatistic(
    getStudentCountByDateInterval,
    tail,
  );*/

  const applicantsTotalSize =
    applicantsByCourse.data?.totalElements ?? 0;
  const isScreenLoading = applicantsByCourse.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row justify="start" gutter={8}>
              <Col span={13}>
                <Row gutter={[8, 8]} align="middle">
                  <Col>
                    <FilterButton onClick={handleOpenApplicantFilterForm}/>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => navigate(RoutesConstants.students())}
                    >
                      View students
                    </Button>
                  </Col>
                </Row>
              </Col>
              {/*<StudentsStatistics
                today={today ?? 0}
                thisWeek={thisWeek ?? 0}
                thisMonth={thisMonth ?? 0}
              />*/}
            </Row>
            <Divider style={{ margin: '16px 0px' }}/>
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between">
              <ResultSummary text={`${applicantsTotalSize} result found`}/>
            </Row>
          </Col>

          <Col span={24}>
            <Table<IStudentByCourse>
              {...studentTable.getTableProps()}
              rowKey={(course) => course.courseId}
              dataSource={applicantsByCourse.data?.applicantsByCourse}
              style={{ marginTop: 30 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
