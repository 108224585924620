import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick.hooks';
import { Icons } from '..';
import Flex from '../Flex/Flex';
import styles from './Tabs.module.css';

type option = {
  text: string;
  value: number;
};

interface ITabsProps {
  title: string;
  options: option[];
  onSelect: (param: option) => void;
  onDelete: (param: option) => void;
  onEdit: (param: option) => void;
}

export default function Tabs({
  title,
  options,
  onSelect,
  onDelete,
  onEdit,
}: ITabsProps) {
  const [selectedValue, setSelectedValue] = useState<option>();
  const [showDropDown, setShowDropDown] = useState<option | null>();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setShowDropDown(null);
  });

  useEffect(() => {
    if (!selectedValue && options[0]) {
      setSelectedValue(options[0]);
      onSelect(options[0]);
    }
  }, [onSelect, options, selectedValue]);

  return (
    <div ref={ref} className={styles.wrapper}>
      <Flex alignItems="center" className={styles.title}>
        {title}
      </Flex>
      <Flex style={{ width: '100%' }} direction="column">
        {(options || []).map((item) => (
          <Flex
            key={item.value}
            className={cn(styles.item, {
              [styles.active]: selectedValue?.value === item?.value,
            })}
            onClick={() => {
              setSelectedValue(item);
              onSelect(item);
            }}
            justifyContent="space-between"
          >
            <p className={styles.itemText}>{item.text}</p>
            <Icons.More
              onClick={(e: Event) => {
                e.stopPropagation();
                setShowDropDown((i) => (i ? null : item));
              }}
              color={selectedValue?.value === item?.value ? '#fff' : '#b7b7b7'}
            />
            {showDropDown?.value === item?.value && (
              <Flex
                direction="column"
                className={styles.dropDown}
                onClick={(e: Event) => e.stopPropagation()}
              >
                <p className={styles.dropDownItem} onClick={() => onEdit(item)}>
                  Edit
                </p>
                <p
                  className={styles.dropDownItem}
                  onClick={() => onDelete(item)}
                >
                  Delete
                </p>
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </div>
  );
}
