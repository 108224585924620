import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import type { ApplicantAttachmentRequest } from '../../../types/attachment.types';

// @ts-ignore
export const InvoiceFormSchema: Yup.SchemaOf<ApplicantAttachmentRequest> = Yup.object().shape({
  attachment: Yup.mixed<File>().required(messages.requiredArm),
  name: Yup.string().required(messages.requiredArm),
});

export const PaymentsInvoiceRequestSchema = Yup.object().shape({
  dateFrom: Yup.string().when('dateTo', {
    is: (date: string) => date,
    then: Yup.string().required(messages.required),
    otherwise: Yup.string(),
  }),
  dateTo: Yup.string(),
});
