import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from "../../contexts/useAuth.context";
import { withCatch } from '../../helpers/error.helpers';
import { createApplicant } from '../../services/applicant.service';
import {
  ICreateStudent,
  ICreateStudentFormData,
} from '../../types/applicant.types';
import { IAppForm } from '../../types/form.types';
import { TransformApplicantForm } from './shared/transformApplicant.form';

export const CreateApplicantForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();
  const { user } = useAuth();

  const createApplicantMutation = useMutation(createApplicant);

  const initialValues: Partial<ICreateStudent> = {};

  const handleSubmit = async (
    createdStudent: Partial<ICreateStudentFormData>,
  ) => {
    if (createdStudent.groupId === -1) {
      createdStudent.groupId = null;
    }

    createdStudent.createdBy = user?.id;

    const studentData = { ...createdStudent };

    const createApplicantFunc = () =>
      createApplicantMutation.mutateAsync(studentData as ICreateStudent);

    await withCatch(createApplicantFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={createApplicantMutation.isLoading}>
      <TransformApplicantForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};
