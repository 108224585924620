import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import {useNavigate} from "react-router-dom";

import { ForgetPasswordViewsEnum } from '../../types/forgetPasswordForm.types';
import styles from '../ForgetPassword/ForrgetPassword.module.css';
import { ForgetPasswordSuccessMassage } from "./components/ForgetPasswordSuccess";
import { SendEmailForm } from  './components/SendEmail.form';


const ForgetPassword = () => {
    const navigate = useNavigate();

    const [activeView, setActiveView] = useState<Partial<ForgetPasswordViewsEnum>>(ForgetPasswordViewsEnum.SEND_EMAIL);

    const changeActiveView = useCallback(setActiveView,[]);

    const views = {
        [ForgetPasswordViewsEnum.FORGET_PASSWORD_SUCCESS]: <ForgetPasswordSuccessMassage />,
        [ForgetPasswordViewsEnum.SEND_EMAIL]: <SendEmailForm changeView={changeActiveView}/>
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
              {views[activeView]}
              <Button type="link" style={{textDecoration: 'underline'}} onClick={()=> navigate('/')} block>
                Go Back
              </Button>
            </div>
        </div>
    );
};

export default ForgetPassword;
