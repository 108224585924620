import { stringifyObject } from "../helpers/stringifyObj.helper";
import { Sorters } from "../types/general.types";
import { MailFilterModel } from "../types/mail.types";
import { SendSms, SmsFilterResponse } from "../types/sms.types";
import axios from "./config.service";

export function sendBulkSms(data: Partial<SendSms>) {
    return axios.post('/sms/send', data).then((r) => r.data) as Promise<any>;
}

export function filterSms(
  filters: Partial<MailFilterModel>,
  sorters: Partial<Sorters> = {},
) {
  const queryString = stringifyObject(sorters);
  return axios
    .post(`/sms/filter?${queryString}`, filters)
    .then((r) => r.data) as Promise<SmsFilterResponse>;
}
