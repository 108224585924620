import React from 'react';
import { useMutation } from 'react-query';

import { selectionStatusOptions } from '../../constants/group.constants';
import { withCatch } from '../../helpers/error.helpers';
import { createGroup } from '../../services/groups.service';
import { ICreateGroup } from '../../types/group.types';
import { CreateEditGroupForm } from './shared/createEditGroup.form';

const CreateGroup: React.FC = () => {
  const { mutateAsync: createNewGroup } = useMutation(createGroup);

  const handleFormSubmit = async (group: ICreateGroup) => {
    await withCatch(() => createNewGroup(group));
  };

  return (
    <CreateEditGroupForm
      initialValues={{
        selectionStatus: selectionStatusOptions.OPEN.value,
      }}
      title="Create Group"
      onSubmit={handleFormSubmit}
    />
  );
};

export default CreateGroup;
