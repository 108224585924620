import { stringifyObject } from "../helpers/stringifyObj.helper";
import { Sorters } from "../types/general.types";
import {
  GroupStudents,
  IStudentGroupDetailResponse,
  IStudentGroupFilterResponse,
  StudentEvaluationFormStatus
} from "../types/studentGroup.types";
import axios from "./config.service";

export function studentGroupDetail(filterOptions: any,  query: Partial<Sorters>) {
  const queryString = stringifyObject(query);
  return axios
    .post(`/studentGroup/filter?${queryString}`, filterOptions)
    .then((r) => r.data) as Promise<IStudentGroupFilterResponse>;
}

export function studentGroupById(id: number) {
  return axios
    .get(`studentGroup/${id}`)
    .then((r) => r.data) as Promise<IStudentGroupDetailResponse>;
}

export function updateStudentGroupById({ id, studentGroup }: { id: number; studentGroup: { status: string }}) {
  return axios
    .put(`studentGroup/${id}`, studentGroup)
    .then((r) => r.data) as Promise<void>;
}

export function getGroupStudents(groupId: number, status?: string) {
  return axios
    .get(`studentGroup/group/${groupId}/students?status=${status}`)
    .then((r) => r.data) as Promise<GroupStudents>;
}

export function getStudentEvaluationFormStatus(groupId: number, applicantId: number) {
  return axios
    .get(`studentGroup/group/${groupId}/applicant/${applicantId}/is-evaluation-filled`)
    .then((r) => r.data) as Promise<StudentEvaluationFormStatus>;
}
