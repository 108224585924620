import { Spin, Table } from 'antd';
import { useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useTable } from '../../../hooks/table/useTable';
import { useRowSelectionAdapter } from '../../../hooks/useRowSelection.adapter';
import { defaultAddApplicantTGroupColumns } from '../../../schemas/applicants/applicant.schema';
import { filterApplicant } from '../../../services/applicant.service';
import { FilterApplicantOptions, IStudentBrief } from '../../../types/applicant.types';
import { SortDirectionEnum } from '../../../types/general.types';

type Props = {
  search: string | undefined;
};

const defaultFilterApplicantOptions: Partial<FilterApplicantOptions> = {
  sortBy: 'created_at',
  sortDirection: SortDirectionEnum.DESC,
  pageSize: 100,
};

const AddApplicantToGroupForm = ({ search }: Props) => {
  const cache = useQueryClient();

  const studentTable = useTable<IStudentBrief>(defaultAddApplicantTGroupColumns);

  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>('applicant');

  const applicantsCache: any = cache.getQueryData(UseQueryTypes.GROUP_BY_ID);

  const payload = search
    ? {
        term: search,
        courseIds: [applicantsCache.course.id],
      }
    : { courseIds: [applicantsCache.course.id] };

  const { data, isLoading } = useQuery([UseQueryTypes.APPLICANTS_SELECTS, payload, defaultFilterApplicantOptions], () =>
    filterApplicant(payload, defaultFilterApplicantOptions),
  );

  const filteredApplicants =
    data?.applicants.filter(({ id: applicantId, group }) => {
      const hasApplicantInSelection = rowSelectionAdapter.selectedRows.some(({ id }) => id === applicantId);

      return !hasApplicantInSelection && group.id !== applicantsCache.id;
    }) || [];

  return (
    <Spin spinning={isLoading}>
      <Table<IStudentBrief>
        {...studentTable.getTableProps({
          rowKey: (student) => student.id,
          dataSource: [...rowSelectionAdapter.selectedRows, ...filteredApplicants],
          rowSelection: rowSelectionAdapter.getProps(),
        })}
      />
    </Spin>
  );
};

export default AddApplicantToGroupForm;
