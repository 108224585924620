import { RegexConstants } from '../../constants/regex.constants';
import { MailTemplateVariableTypesEnum } from '../../types/mailTemplate.types';

const setBrackets = (str: string): string => {
  let mailgunKey;
  if (str.includes(MailTemplateVariableTypesEnum.HTML)) {
    const indexOfH = str.indexOf('H');
    mailgunKey = str.substring(0, indexOfH);
    mailgunKey = `{{{${mailgunKey}}}}`;
    return mailgunKey;
  }

  mailgunKey = `{{${str}}}`;

  return mailgunKey;
};

export const normalaizeKeys = (obj: any) => {
  const keys = Object.keys(obj);
  const normalizeObj = keys.reduce((acc: any, v) => {
    let normalizeKey = setBrackets(v);

    normalizeKey = normalizeKey
      .replace(RegexConstants.selectFirsUppercaseLetter, '_$1')
      .trim()
      .toUpperCase();

    acc[normalizeKey] = obj[v];

    return acc;
  }, {});

  return normalizeObj;
};
