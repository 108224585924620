import { IComment } from './comment.types';
import { FilterOptions, FilterResponse } from './general.types';
import { ITrack } from './track.types';

export enum InstructorStatusEnum {
  TEACHING_NOW = 'TEACHING_NOW',
  ON_A_BREAK = 'ON_A_BREAK',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  POTENTIAL_INSTRUCTOR = 'POTENTIAL_INSTRUCTOR',
}

export enum InstructorRoleEnum {
  INSTRUCTOR = 'INSTRUCTOR',
  PRACTISE_LEAD = 'PRACTISE_LEAD',
}

export enum InstructorTypeEnum {
  ASSISTANT = 'ASSISTANT',
  LECTURER = 'LECTURER',
  TUTOR = 'TUTOR',
}

export type InstructorStatus = keyof typeof InstructorStatusEnum;
export type InstructorType = keyof typeof InstructorTypeEnum;

export interface IInstructor {
  id: number;
  avatar: string;
  uuid: string;
  name: string;
  phoneNumber: string;
  email: string;
  role: string;
  linkedInProfile: string;
  status: InstructorStatus;
  tracks: ITrack[];
  comments: IComment[];
  signatureLink: string;
}

export interface ICreateInstructor {
  id: number;
  email: string;
  linkedInProfile: string;
  name: string;
  phoneNumber: string;
  signatureLink?: string;
  status: InstructorStatus;
  tracks: number[];
  password?: string;
}

export type InstructorFilterModel = {
  instructorStatus: InstructorStatus[];
  name: string;
  trackIds: number[];
};

export type InstructorFilterOptions = InstructorFilterModel & FilterOptions;

export interface InstructorFilterResponse extends FilterResponse {
  instructors: IInstructor[];
}
