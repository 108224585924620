import { Modal, Select, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { examAutomationTemplates, MailgunVariables, MassMailTemplates } from '../../../constants/mailgun.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { withCatch } from '../../../helpers/error.helpers';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { mailGunTemplateFilter } from '../../../helpers/mailgunTemplate.helpers';
import { mailTemplateNormalizer } from '../../../helpers/string.helpers';
import { useUserAdditionalData } from '../../../hooks/useUserAdditionalData.hook';
import { updateApplicant } from '../../../services/applicant.service';
import { updateExamAutomation } from '../../../services/exam.service';
import { getMailTemplateByName, getMailTemplates, sendMassMailing } from '../../../services/mail.service';
import { useAppDispatch, useAppSelector } from '../../../store';
import { mailingActions, selectMailingFormValues } from '../../../store/features/mailing/mailingSlice';
import { IStudent, IUpdateStudent, StudentStatusEnum } from '../../../types/applicant.types';
import { IAutomatedExam } from '../../../types/exam.types';
import { SendMail } from '../../../types/mail.types';
import { Flex } from '../../general';

const { Paragraph, Title } = Typography;

type SendEmailModalParameterTypes = {
  student: IStudent;
  isModalVisible: boolean;
  onCancel: () => void;
};

export function SendEmailModal({ isModalVisible, onCancel, student }: SendEmailModalParameterTypes) {
  const [templateBody, setTemplateBody] = useState('');
  const [loading, setLoading] = useState(false);
  const formInitialValues = useAppSelector(selectMailingFormValues);
  const dispatch = useAppDispatch();
  const cache = useQueryClient();
  const variables = useUserAdditionalData();
  const { Option } = Select;
  const sendEmailMutation = useMutation(sendMassMailing);
  const updateApplicantMutation = useMutation(updateApplicant);

  const mailTemplateQuery = useQuery(UseQueryTypes.MAIL_TEMPLATE, getMailTemplates);

  useEffect(() => {
    const mailState: Partial<SendMail> = {
      to: student.email,
      from: 'ACA <info@aca.am>',
      subject: 'ACA Registration',
      tags: ['students', 'registration'],
      variables: {
        APPLICANT_NAME: student.name,
        COURSE_NAME: student.course.name,
        ...variables,
      },
    };

    dispatch(mailingActions.setInitialValues(mailState));
  }, [student, dispatch, variables]);

  const mailTemplatesContext = useMemo(() => {
    const templateItems = mailGunTemplateFilter(mailTemplateQuery?.data?.templates?.items, MassMailTemplates);

    return generateContext(templateItems, (template) => ({
      label: template.name,
      value: template.name,
    }));
  }, [mailTemplateQuery.data?.templates?.items]);

  const handleTemplateSelect = useCallback(
    async (value: any) => {
      setLoading(true);
      dispatch(mailingActions.setInitialValues({ template: value }));

      const templateBodyResponse = await getMailTemplateByName(value);
      const templateBodyResponseContent = templateBodyResponse.template.template.version.template;

      const normalizedTemplateBodyResponseContent = mailTemplateNormalizer({
        mailTemplate: templateBodyResponseContent,
        templateVariables: MailgunVariables,
        values: {
          COURSE_NAME: student.course.name,
          APPLICANT_NAME: student.name,
        },
      });

      setTemplateBody(normalizedTemplateBodyResponseContent);
      setLoading(false);
    },
    [dispatch, student],
  );

  const editApplicant = () => {
    return updateApplicantMutation.mutateAsync({
      id: student.id,
      applicant: {
        isRegistrationMailSent: true,
      } as IUpdateStudent,
    });
  };

  const handleSendMailSubmit = async (mailOptions: Partial<SendMail>) => {
    await withCatch(() => sendEmailMutation.mutateAsync(mailOptions), {
      onSuccess: async () => {
        await editApplicant();
        await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        onCancel();
      },
    });
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      onOk={() => handleSendMailSubmit(formInitialValues)}
      confirmLoading={sendEmailMutation.isLoading}
      title="Send Registration Email"
      okText="Send"
      width={1000}
    >
      <Flex style={{ width: '100%' }} justifyContent={'space-around'}>
        <Select
          showSearch
          style={{ width: 220 }}
          onChange={handleTemplateSelect}
          placeholder="Select Template"
          loading={mailTemplateQuery.isLoading}
        >
          {Object.values(mailTemplatesContext).map(({ value, label }) => {
            return (
              <Option value={`${value}`} key={value}>
                {label}
              </Option>
            );
          })}
        </Select>
        <Paragraph>Send Email to {student.name}</Paragraph>
      </Flex>

      <Spin spinning={loading}>
        <div style={{ marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: templateBody }} />
      </Spin>
    </Modal>
  );
}

export function SendExamEmailModal({ isModalVisible, onCancel, student }: SendEmailModalParameterTypes) {
  const formInitialValues = useAppSelector(selectMailingFormValues);
  const dispatch = useAppDispatch();
  const cache = useQueryClient();
  const variables = useUserAdditionalData();
  const sendEmailMutation = useMutation(sendMassMailing);
  const updateExamAutomationMutation = useMutation(updateExamAutomation);

  useEffect(() => {
    const { EXAM_PASSED, EXAM_FAILED } = examAutomationTemplates;

    const mailState: Partial<SendMail> = {
      to: student.email,
      from: 'ACA <info@aca.am>',
      subject: 'ACA Exam Result',
      tags: ['students', 'exam'],
      template: student.status === StudentStatusEnum.EXAM_PASSED ? EXAM_PASSED : EXAM_FAILED,
      variables: {
        APPLICANT_NAME: student.name,
        COURSE_NAME: student.course.name,
        ...variables,
      },
    };

    dispatch(mailingActions.setInitialValues(mailState));
  }, [student, dispatch, variables]);

  const editApplicant = () => {
    return updateExamAutomationMutation.mutateAsync({
      id: student.admissionExam.id,
      exam: {
        resultMailSent: true,
      } as IAutomatedExam,
    });
  };

  const handleSendMailSubmit = async (mailOptions: Partial<SendMail>) => {
    await withCatch(() => sendEmailMutation.mutateAsync(mailOptions), {
      onSuccess: async () => {
        await editApplicant();
        await cache.invalidateQueries(UseQueryTypes.APPLICANTS);
        onCancel();
      },
    });
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      onOk={() => handleSendMailSubmit(formInitialValues)}
      confirmLoading={sendEmailMutation.isLoading}
      title="Send Registration Email"
      okText="Send"
      width={1000}
    >
      <Paragraph>Send Email to {student.name}</Paragraph>
      <Title level={4}>You want to send email about result of exam.</Title>
    </Modal>
  );
}
