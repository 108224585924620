import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { InterviewTemplateEnum } from '../../../types/group.types';

const { required } = messages;

export const InterviewScheduleSchema = Yup.object().shape({
  groupId: Yup.number().required(),
  template: Yup.mixed<InterviewTemplateEnum>()
    .oneOf(Object.values(InterviewTemplateEnum))
    .default(InterviewTemplateEnum.INTRO_COURSES_WITH_EXAM)
    .required(required),
});
