import { Col, Divider, Pagination, Row, Spin, Table, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Badges } from '../../../../components/display';
import { TBadge } from '../../../../components/display/Badges/Badges';
import { ColumnModal } from '../../../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../../../components/display/ResultSummary/ResultSummary';
import { FilterButton, Flex, Search } from '../../../../components/general';
import { ChangeView } from '../../../../components/general/Icons/Icons';
import { ApplicantStatusOptions } from '../../../../constants/applicant.constants';
import { MAX_SAFE_PAGE_SIZE, PageSizeOptions } from '../../../../constants/sorters.constants';
import { UseQueryTypes } from '../../../../constants/useQuery.constants';
import { useDrawer } from '../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../forms';
import { calculateByStatus } from '../../../../helpers/applicants/calculateByStatus.helper';
import { RouteParam, RoutesConstants } from '../../../../helpers/routes.helpers';
import { useTable } from '../../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../../../hooks/useRowSelection.adapter';
import { useSearchHandler } from '../../../../hooks/useSearchHandler.hooks';
import { Schedule } from '../../../../pages/Groups/pages/GroupDetail/components/schedule';
import { filterApplicant } from '../../../../services/applicant.service';
import { DateService } from '../../../../services/date.service';
import { generateGroupCertificate, getGroupById } from '../../../../services/groups.service';
import type {
  FilterApplicantOptions,
  IStudent,
  IStudentBrief,
  StudentStatusEnum,
} from '../../../../types/applicant.types';
import { WedgedTypes } from '../../../../types/general.types';
import { getDefaultApplicantDetailColumns } from '../../schemas/applicants/applicantByGroup.schema';

const { Title, Text } = Typography;

type GroupInfoType = { name: string; value?: any };

const GroupInfoRow: React.FC<GroupInfoType> = React.memo(({ name, value }) => (
  <Flex style={{ padding: '20px 10px' }} alignItems="center">
    <Col span={8}>
      <Text type="secondary" style={{ textTransform: 'uppercase' }}>
        {name}
      </Text>
    </Col>
    <Col span={16}>
      <Text strong>{value}</Text>
    </Col>
  </Flex>
));

const Widgets = (id: RouteParam) => [
  {
    id: 0,
    title: 'Absentees',
    bgColor: 'blue',
    link: RoutesConstants.groupAbsentees(id),
  },

  {
    id: 1,
    title: 'Exams',
    bgColor: 'violet',
    link: RoutesConstants.groupGrades(id),
  },
];

const getDefaultApplicantFilterOptions = (groupId: number): Partial<FilterApplicantOptions> => {
  return {
    pageSize: PageSizeOptions.defaultPageSize,
    groupIds: [groupId],
    statuses: Object.keys(ApplicantStatusOptions) as StudentStatusEnum[],
  };
};

const GroupDetail = () => {
  const cache = useQueryClient();
  const { openDrawer } = useDrawer();
  const { id } = useParams<{ id: string }>();
  const { sorterOptions, filterOptions } = useFilter(getDefaultApplicantFilterOptions(Number(id)));
  const { term, handleSearch } = useSearchHandler();

  const groupQuery = useQuery(UseQueryTypes.GROUP_BY_ID, () => getGroupById(Number(id)));

  const { data: applicantsData, ...applicantGetter } = useQuery(
    [UseQueryTypes.APPLICANTS, id, sorterOptions, filterOptions],
    () =>
      filterApplicant(
        {
          calcDebt: true,
          calcAttachments: true,
          calcFeedbacks: true,
          loadExams: true,
          ...filterOptions,
        },
        sorterOptions,
      ),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.APPLICANTS, data);
      },
    },
  );

  const groupApplicants = useQuery(
    [UseQueryTypes.APPLICANTS, id],
    () =>
      filterApplicant(
        {
          calcDebt: true,
          loadExams: true,
          calcAttachments: true,
          calcFeedbacks: true,
          groupIds: [Number(id)],
        },
        { pageSize: MAX_SAFE_PAGE_SIZE },
      ),
    { select: (response) => response.applicants },
  );

  const getStatisticByApplicantStatus = useCallback((applicants: IStudent[] | undefined) => {
    if (applicants) {
      return applicants.reduce((acc, curr) => calculateByStatus(acc, curr), {});
    }
  }, []);

  const memorizedStatisticByApplicantsStatus = useMemo(() => {
    return getStatisticByApplicantStatus(groupApplicants.data);
  }, [groupApplicants, getStatisticByApplicantStatus]);

  const applicantTable = useTable(getDefaultApplicantDetailColumns());
  const modalAdapter = useColumnModalAdapter(applicantTable.getTableColumnSettings());
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const groupRowInfo: GroupInfoType[] = [
    {
      name: 'instructor',
      value: `${groupQuery.data?.groupInstructors?.map(({ name }) => name).join('\n')}`,
    },
    {
      name: 'pc',
      value: `${groupQuery.data?.projectCoordinator.firstName} ${groupQuery.data?.projectCoordinator.lastName}`,
    },
    {
      name: 'start date',
      value: DateService.getFullDate(groupQuery.data?.startDate || ''),
    },
    {
      name: 'duration',
      value: `${groupQuery.data?.duration.value} ${groupQuery.data?.duration.timeScale}`,
    },
    {
      name: 'end date',
      value: DateService.getFullDate(groupQuery.data?.endDate || ''),
    },
  ];

  const generateCertificatesMutation = useMutation(generateGroupCertificate);

  const handleOpenApplicantFilterForm = () => {
    openDrawer(AppFormsEnum.FilterApplicantForm, 'Filter Applicant');
  };

  const isScreenLoading = applicantGetter.isLoading || groupQuery.isLoading || generateCertificatesMutation.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16} style={{ height: '100%', marginTop: '20px' }}>
          <Col span={10}>
            <Title style={{ marginBottom: 20 }} level={4}>
              General info
            </Title>

            <Flex justifyContent="space-between" style={{ padding: '0 10px', height: '40px' }}>
              <Text type="secondary">{groupQuery.data?.course.name}</Text>
              <Divider style={{ height: '100%' }} type="vertical" />
              <Text type="secondary">Group #{id}</Text>
              <Divider style={{ height: '100%' }} type="vertical" />
              <Text type="secondary">{groupQuery.data?.courseFormat}</Text>
            </Flex>
            <div style={{ backgroundColor: 'white' }}>
              {groupRowInfo.map(({ name, value }) => (
                <GroupInfoRow name={name} value={value} key={name} />
              ))}
            </div>
          </Col>
          {groupQuery?.data && (
            <Schedule
              schedule={groupQuery.data.schedule}
              course={groupQuery.data.course}
              widgets={Widgets}
              isHiddenCreatingButtons
            />
          )}

          <Col style={{ margin: '20px 0' }} span={24}>
            <Row justify="space-between">
              <Col>
                <Flex>
                  <Search style={{}} placeholder="Search" defaultValue={term} onChange={handleSearch} />
                  <Col style={{ marginLeft: '8px' }}>
                    <FilterButton onClick={handleOpenApplicantFilterForm} />
                  </Col>
                </Flex>
                <Flex style={{ marginTop: '15px' }}>
                  {memorizedStatisticByApplicantsStatus && (
                    <Badges
                      wedgedType={WedgedTypes.APPLICANT_STATUS}
                      badges={Object.entries(memorizedStatisticByApplicantsStatus).map((i) => i[1]) as TBadge[]}
                    />
                  )}
                </Flex>
              </Col>
              <Col></Col>
            </Row>
            <Flex justifyContent="space-between" alignItems={'baseline'} style={{ width: '100%' }}>
              <Col>
                <ResultSummary text={`${applicantsData?.applicants?.length || 0} result found`} />
              </Col>
              <Col>
                <Row>
                  <Col style={{ marginRight: '8px' }}>
                    <ChangeView onClick={modalAdapter.openSettings} />
                  </Col>
                </Row>
              </Col>
            </Flex>

            <Table<IStudentBrief>
              {...applicantTable.getTableProps({
                rowKey: (student) => student.id,
                dataSource: applicantsData?.applicants,
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />
            <Pagination
              {...applicantTable.getPaginationProps({
                style: { marginTop: 20 },
                total: applicantsData?.totalElements,
                defaultPageSize: PageSizeOptions.defaultPageSize,
              })}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default GroupDetail;
