import cn from 'classnames';
import React from 'react';

import styles from './Ticker.module.css';

interface IStyleProps {
  style?: React.CSSProperties;
  className?: string;
}

interface ITickerProps {
  text: string;
  textProps?: IStyleProps;
  containerProps?: IStyleProps;
}

export default function Ticker({ text, containerProps={}, textProps={} }: ITickerProps) {
  const containerClass = cn(styles.scrollContainer, containerProps?.className);
  const textClass = cn(styles.scrollText, textProps?.className);

  return (
    <div style={containerProps.style} className={containerClass}>
      <div style={textProps.style} className={textClass}>{text}</div>
    </div>
  );
}
