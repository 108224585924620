import { Field, Form, Formik } from 'formik';

import { Button, Flex } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { USER_ROLES } from '../../constants/user.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useFilter } from '../../hooks/useFilter.hooks';
import { IAppForm } from '../../types/form.types';
import { FilterUserOptions, UserFilterModel } from '../../types/user.types';

const defaultFilterUserOptions: Partial<FilterUserOptions> = {
  roles: [],
};

export const FilterUserForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { filterOptions, changeOptions, resetOptions } = useFilter(
    defaultFilterUserOptions,
  );

  const handleFilterSubmit = (filterOptions: Partial<UserFilterModel>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  return (
    <Formik
      onSubmit={handleFilterSubmit}
      initialValues={filterOptions}
      enableReinitialize
    >
      {(props) => (
        <Form
          name="filters"
          style={{
            width: '100%',
            height: 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Field
            name="roleClientTypes"
            label="User Roles"
            options={USER_ROLES}
            component={MultiselectFormik}
          />
          <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
            <Button
              htmlType="button"
              style={{ minWidth: 160 }}
              onClick={() => {
                resetOptions();
                props.resetForm();
              }}
            >
              Reset
            </Button>
            <Button
              style={{ minWidth: 160 }}
              htmlType="submit"
              variant="primary"
            >
              Apply
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
