import { Field, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { SingleSelectFormik } from '../../../components/general';
import { TASK_TYPE } from '../../../constants/task.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { filterApplicant } from '../../../services/applicant.service';
import { getGroupNames } from '../../../services/groups.service';
import {SortDirectionEnum} from "../../../types/general.types";
import { GroupField } from '../../applicantForms/shared/GroupField';

export const ChooseGroupField = () => {
  const {
    values: { taskType },
  } = useFormikContext<any>();

  const showing = useMemo(() => {
    if (!taskType) return false;

    return (
      TASK_TYPE.FIRST_CONTACT_PHASE.value === taskType ||
      TASK_TYPE.EXAM_STAGE.value === taskType ||
      TASK_TYPE.INTERVIEW_STAGE.value === taskType
    );
  }, [taskType]);

  if (!showing) return null;

  return <GroupField name="groupId" />;
};

export const ChooseRelatedToField = () => {
  const {
    values: { taskType },
  } = useFormikContext<any>();

  const [applicantName, setApplicantName] = useState<string>();

  const isApplicantEnabled = taskType === TASK_TYPE.APPLICANT.value;
  const isGroupEnabled = taskType === TASK_TYPE.GROUP.value;
  const isRelatedToTypeOther = taskType === TASK_TYPE.OTHER.value;

  const { refetch, data, isLoading } = useQuery(
    UseQueryTypes.TASK_APPLICANTS,
    () => filterApplicant(applicantName ? { name: applicantName } : {}, {
      sortBy: 'id',
      sortDirection: SortDirectionEnum.DESC,
      pageSize: 20
    }),
    {
      enabled: isApplicantEnabled,
    },
  );

  const groupsQuery = useQuery(UseQueryTypes.GROUP_NAMES, getGroupNames, {
    enabled: isGroupEnabled,
  });

  const groupsContext = useMemo(() => {
    return generateContext(groupsQuery.data || [], (group) => ({
      label: group.groupName,
      value: group.id,
    }));
  }, [groupsQuery.data]);

  const applicantsContext = useMemo(() => {
    const generated =  generateContext(data?.applicants || [], (applicant) => ({
      label: applicant.name,
      value: applicant.id,
    }));
    return (Object.values(generated)).sort((a,b)=> +b.value - +a.value);
  }, [data?.applicants]);

  const options = useMemo(() => {
    if (isRelatedToTypeOther) return null;
    if (isGroupEnabled) {
      return groupsContext;
    } else if (isApplicantEnabled) {
      return applicantsContext;
    } else return null;
  }, [isGroupEnabled, isApplicantEnabled, groupsContext, applicantsContext, isRelatedToTypeOther]);

  if (groupsQuery.isLoading || isLoading || !options) return null;

  const fieldInfo = isGroupEnabled
    ? { name: 'relatedTo', label: 'Related To', inputChange: null }
    : {
        name: 'applicant',
        label: 'Applicant',
        inputChange: (e: string) => {
          if (e) {
            setApplicantName(e);
            debounce(refetch, 1000)();
          }
        },
      };

  return (
    <>
      <Field
        name={fieldInfo.name}
        label={fieldInfo.label}
        options={options}
        component={SingleSelectFormik}
        onInputChange={fieldInfo.inputChange}
      />
    </>
  );
};
