export const getLastItem = <T>(array: T[]): T | undefined =>
  array.slice(-1).pop();

export const sortArrayByProperty = <T>(array: T[], property: string): T[][] => {
  const sortedObj = array.reduce((acc: any, item: T) => {
    const currentKey: string = String(item[property as keyof T]);

    if (!acc.hasOwnProperty(currentKey)) {
      acc[currentKey] = [];
    }

    acc[currentKey].push(item);

    return acc;
  }, {});

  return Object.values(sortedObj);
};

export const fillArrayWithSequenceNumbers = ({
  startNumber = 1,
  endNumber
}: { startNumber?: number, endNumber: number }) => {
  let arr = [];

  for (let i = startNumber; i <= endNumber; i++) {
    arr.push(i)
  }

  return arr
}
