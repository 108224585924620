import * as Yup from 'yup';

import { ExamTypeEnum } from '../../../types/exam.types';

export const CreateExamFormSchema = Yup.object().shape({
  title: Yup.string().required('required'),
  date: Yup.string().required('required'),
  examType: Yup.mixed<ExamTypeEnum>().oneOf(Object.values(ExamTypeEnum)).required('required'),
  minScore: Yup.number().required('required'),
  maxScore: Yup.number().required('required'),
});
