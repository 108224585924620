import AbsenteesRenderer from "../components/Absentees/AbsenteesRenderer";

const Absentees = () => {
  return (
    <div>
      <AbsenteesRenderer/>
    </div>
  );
};

export default Absentees;
