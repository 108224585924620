import { Col, Pagination, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ColumnModal } from '../../../../components/display/ColumnSettingModal/ColumnModal';
import { UseQueryTypes } from '../../../../constants/useQuery.constants';
import { useAuth } from '../../../../contexts/useAuth.context';
import { ITableData, useTable } from '../../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../../hooks/useColumnModal.adapter';
import useWindowSize from '../../../../hooks/useWindowSize.hooks';
import { filterGroups } from '../../../../services/groups.service';
import { addInstructorComment, getInstructorById } from '../../../../services/instructors.service';
import { getAllTracks } from '../../../../services/track.service';
import { GroupStateEnum, IGroup } from '../../../../types/group.types';
import { IUser } from '../../../../types/user.types';
import {
  defaultInstructorGroupsColumns,
  defaultInstructorGroupsSmallColumns,
} from '../../schemas/groups/instructorGroups.schema';

export default function GroupsByType({ type }: { type: GroupStateEnum }) {
  const {
    user: { id },
  } = useAuth() as { user: IUser };

  const { width } = useWindowSize();

  const [columnState, setColumnState] = useState(width > 480 ? 'default' : 'small');

  const cache = useQueryClient();

  useEffect(() => {
    setColumnState(width > 480 ? 'default' : 'small');
  }, [width]);

  const groupInstructorTable: { [key: string]: ITableData<IGroup> } = {
    default: useTable(defaultInstructorGroupsColumns),
    small: useTable(defaultInstructorGroupsSmallColumns),
  };

  const modalAdapter = useColumnModalAdapter(groupInstructorTable[columnState].getTableColumnSettings());

  const instructorQuery = useQuery([UseQueryTypes.INSTRUCTOR, id], () => getInstructorById(Number(id)));
  const tracksQuery = useQuery(UseQueryTypes.TRACKS, getAllTracks);
  const groupsQuery = useQuery([UseQueryTypes.GROUPS, type, id], () =>
    filterGroups({
      groupState: [type],
      instructorIds: [Number(id)],
    }),
  );

  const commentMutation = useMutation(addInstructorComment, {
    onSuccess: () => cache.invalidateQueries('instructor'),
  });

  const isScreenLoading =
    instructorQuery.isLoading || tracksQuery.isLoading || groupsQuery.isLoading || commentMutation.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16} style={{ marginTop: 30, marginBottom: 50 }}>
          <Col span={24}>
            <Table<IGroup>
              {...groupInstructorTable[columnState].getTableProps()}
              dataSource={groupsQuery.data?.groups}
              style={{ marginTop: 30 }}
              rowKey={(group) => group.id}
            />
            <Pagination
              {...groupInstructorTable[columnState].getPaginationProps()}
              total={groupsQuery.data?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
