import { Col, Row } from 'antd';
import React from 'react';

import { isAdmin } from '../../../helpers/user.helper';
import { DeleteTeamColumn, EditTeamColumn } from '../../../schemas/team/team.renderer';
import { AbstractUser } from '../../../types/auth.types';

export const CustomPanelHeader = ({ id, teamName, membersCount, user }: { id: number; teamName: string; membersCount: string; user: AbstractUser }) => {
  return (
    <Row style={{ width: '100%' }}>
      <Col span={12}>{teamName}</Col>
      <Col span={8}>{membersCount}</Col>
      {
        isAdmin(user) &&
        <>
          <Col span={2}>
            <EditTeamColumn id={id} teamName={teamName}/>
          </Col>
          <Col span={2}>
            <DeleteTeamColumn id={id} name={teamName}/>
          </Col>
        </>
      }
    </Row>
  )
}
