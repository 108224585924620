import Flex from '../../../../components/general/Flex/Flex';
import { AccountInfo } from '../AccountInfo/AccountInfo';

const TopBar = ({ title }: { title: string }) => {
  return (
    <Flex className="topbar-wrapper" alignItems="center" justifyContent="space-between">
      <div>
        <h1 className="page-title">{title}</h1>
      </div>

      <AccountInfo />
    </Flex>
  );
};

export default TopBar;
