const NavlinkSections: { [key: string]: string } = {
  dashboard: 'Dashboard',
  groups: 'Groups',
  services: 'Services',
  advanced: 'Advanced',
  applicants: 'Applicants',
};

enum Symbols {
  slash = '/',
  dash = '-',
  space = ' ',
  emptySpace = '',
}

const pipe =
  (...fns: any[]) =>
  (x: string) =>
    fns.reduce((v, f) => f(v), x);

const removeSymbolsFromTitle = (title: string) => {
  return title.replaceAll(Symbols.slash, Symbols.space).replaceAll(Symbols.dash, Symbols.space).trim();
};

const capitalizeFirstLetter = (str: string) => {
  return str
    ? str
        .trim()
        .toLowerCase()
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ')
    : '';
};

const removeSectionName = (str: string) => {
  if (str.includes(NavlinkSections.services)) return str.replace(NavlinkSections.services, Symbols.emptySpace);

  if (str.includes(NavlinkSections.advanced)) return str.replace(NavlinkSections.advanced, Symbols.emptySpace);

  return str;
};

type TitleType = string;

export const getNormalizedTitle = (pathname: TitleType) => {
  if (pathname === Symbols.slash) return NavlinkSections.dashboard;

  const mainPath: string = pathname.split(Symbols.slash)[1];

  if (NavlinkSections[mainPath]) {
    return NavlinkSections[mainPath];
  }

  return pipe(removeSymbolsFromTitle, capitalizeFirstLetter, removeSectionName, capitalizeFirstLetter)(pathname);
};
