import { useQuery, UseQueryResult } from 'react-query';

import { generalDefaultData } from '../constants/applicantDetail.constants';
import { ApplicantDetailQueryTypes } from '../constants/useQuery.constants';
import {
  getApplicantDetailAbsentees,
  getApplicantDetailActions,
  getApplicantDetailApplications,
  getApplicantDetailAttachments,
  getApplicantDetailComments,
  getApplicantDetailConsultations,
  getApplicantDetailExams,
  getApplicantDetailFeedbacks,
  getApplicantDetailGeneral,
  getApplicantDetailPayments, getApplicantPromoCodes,
} from '../services/applicantDetail.service';
import { IApplicantDetailGeneral } from '../types/applicantDetail.types';

type IProps = { activeQuery: string; enabledQueries?: ApplicantDetailQueryTypes[] };

export const useApplicantDetailQueries = function (applicantId: number, { activeQuery, enabledQueries = [] }: IProps) {
  const {
    APPLICANT_DETAILS_EXAMS,
    APPLICANT_DETAILS_CONSULTATIONS,
    APPLICANT_DETAILS_ABSENTEES,
    APPLICANT_DETAILS_FEEDBACKS,
    APPLICANT_DETAILS_PAYMENTS,
    APPLICANT_DETAILS_ATTACHMENTS,
    APPLICANT_DETAILS_COMMENTS,
    APPLICANT_DETAILS_ACTIONS,
    APPLICANT_DETAILS_APPLICATIONS,
    APPLICANT_DETAILS_GENERAL,
    APPLICANT_DETAILS_PROMO_CODE
  } = ApplicantDetailQueryTypes;

  const isEnabled = (type: ApplicantDetailQueryTypes): boolean => enabledQueries.includes(type) || activeQuery === type;

  const { data, ...queryResult } = useQuery([APPLICANT_DETAILS_GENERAL], () => getApplicantDetailGeneral(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_GENERAL),
  });

  const general: Omit<UseQueryResult, 'data'> & { data: IApplicantDetailGeneral } = {
    data: data ?? generalDefaultData,
    ...queryResult,
  };

  const applications = useQuery([APPLICANT_DETAILS_APPLICATIONS], () => getApplicantDetailApplications(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_APPLICATIONS),
  });

  const exams = useQuery([APPLICANT_DETAILS_EXAMS], () => getApplicantDetailExams(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_EXAMS),
  });

  const consultations = useQuery(
    [APPLICANT_DETAILS_CONSULTATIONS],
    () => getApplicantDetailConsultations(applicantId),
    {
      enabled: isEnabled(APPLICANT_DETAILS_CONSULTATIONS),
    },
  );

  const absentees = useQuery([APPLICANT_DETAILS_ABSENTEES], () => getApplicantDetailAbsentees(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_ABSENTEES),
  });

  const comments = useQuery([APPLICANT_DETAILS_COMMENTS], () => getApplicantDetailComments(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_COMMENTS),
  });

  const feedbacks = useQuery([APPLICANT_DETAILS_FEEDBACKS], () => getApplicantDetailFeedbacks(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_FEEDBACKS),
  });

  const actions = useQuery([APPLICANT_DETAILS_ACTIONS], () => getApplicantDetailActions(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_ACTIONS),
  });

  const payments = useQuery([APPLICANT_DETAILS_PAYMENTS], () => getApplicantDetailPayments(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_PAYMENTS),
  });

  const attachments = useQuery([APPLICANT_DETAILS_ATTACHMENTS], () => getApplicantDetailAttachments(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_ATTACHMENTS),
  });

  const promoCodes = useQuery([APPLICANT_DETAILS_PROMO_CODE], () => getApplicantPromoCodes(applicantId), {
    enabled: isEnabled(APPLICANT_DETAILS_PROMO_CODE),
  });

  return {
    applications,
    consultations,
    actions,
    exams,
    absentees,
    promoCodes,
    comments,
    feedbacks,
    payments,
    attachments,
    general,
  };
};
