import * as Yup from 'yup';

import messages from '../../constants/messages.constants';

const { required } = messages;

export const CertificateFormSchema = Yup.object().shape({
  courseName: Yup.string().required(required),
  endDate: Yup.string().required(required),
  descriptionFirstLine: Yup.string().required(required),
  descriptionSecondLine: Yup.string().required(required),
  tutorFullName: Yup.string().required(required),
  tutorSignBase64: Yup.string().required(required),
  taFullName: Yup.string(),
  taSignBase64: Yup.string(),
  applicantNames: Yup.string().required(required),
});
