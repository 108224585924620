import { StudentRoutesConstants } from '../../../helpers/routes.helpers';
import { IDashboardLinks } from '../../shared/types/path.types';


export const StudentDashBoardConditionalLinks: IDashboardLinks = {
  certificate: {
    id: 1,
    path: StudentRoutesConstants.certificate(),
    name: 'My Certificate',
  }
}
export const StudentDashBoardLinks: IDashboardLinks = {
  Dashboard: {
    id: 0,
    name: 'Dashboard',
    path: StudentRoutesConstants.dashboard(),
  },
  agreements: {
    id: 1,
    path: StudentRoutesConstants.agreements(),
    name: 'My Agreements',
  },
  payments: {
    id: 2,
    path: StudentRoutesConstants.payments(),
    name: 'My Payments',
  },
  absentees: {
    id: 3,
    path: StudentRoutesConstants.absentees(),
    name: 'My Absentees',
  },
  grades: {
    id: 4,
    path: StudentRoutesConstants.grades(),
    name: 'My Grades',
  },
  feedbacks: {
    id: 5,
    name: 'feedbacks',
    type: 'MENU',
    items: {
      receivedFeedbacks: {
        id: 0,
        name: 'Received Feedbacks',
        path: StudentRoutesConstants.receivedFeedbacks(),
      },
      givenFeedbacks: {
        id: 1,
        name: 'Given Feedbacks',
        path: StudentRoutesConstants.givenFeedbacks(),
      },
    }
  },
  cv: {
    id: 6,
    path: StudentRoutesConstants.cv(),
    name: 'My CV',
  },
  attachments: {
    id: 7,
    path: StudentRoutesConstants.attachments(),
    name: 'Other Attachment',
  },
  // progress: {
  //   id: 6,
  //   path: StudentRoutesConstants.progress(),
  //   name: 'My Progress',
  // },
  // archive: {
  //   id: 7,
  //   path: StudentRoutesConstants.archive(),
  //   name: 'My Archive',
  // },
};
