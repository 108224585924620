import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ResultSummary } from '../../../components/display';
import { ColumnModal } from '../../../components/display/ColumnSettingModal/ColumnModal';
import { Button, FilterButton } from '../../../components/general';
import GroupStatistics from '../../../components/general/Group/GroupStatistics';
import { ChangeView } from '../../../components/general/Icons/Icons';
import { groupStateOptions } from '../../../constants/group.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useDrawer } from '../../../contexts/drawer.context';
import { useAuth } from '../../../contexts/useAuth.context';
import { AppFormsEnum } from '../../../forms';
import { useTable } from '../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { defaultPlanningGroupColumns } from '../../../schemas/groups/planningGroup.schema';
import { filterGroups, updateGroupById } from '../../../services/groups.service';
import { IUseAuth } from '../../../types/auth.types';
import { GroupFilterOptions, GroupStateEnum, IGroup } from '../../../types/group.types';
import { UserRole } from '../../../types/user.types';

const defaultGroupFilterOptions: Partial<GroupFilterOptions> = {
  sortBy: 'startDate',
  sortDirection: 'DESC',
};

export default function PlanningGroups() {
  const cache = useQueryClient();

  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions } = useFilter(defaultGroupFilterOptions);
  const groupTable = useTable<IGroup>(defaultPlanningGroupColumns);
  const modalAdapter = useColumnModalAdapter(groupTable.getTableColumnSettings());

  const { user, checkUserRole } = useAuth() as IUseAuth;

  const [activeGroupStatus, setActiveGroupStatus] = useState<GroupStateEnum.ON_HOLD | GroupStateEnum.PLANNING>(
    GroupStateEnum.PLANNING,
  );

  const projectCoordinatorParam = checkUserRole(UserRole.ROLE_ADMIN) ? {} : { projectCoordinatorIds: [user?.id!] };

  const planningGroups = useQuery([UseQueryTypes.PLANNING, sorterOptions, filterOptions], () =>
    filterGroups(
      {
        ...projectCoordinatorParam,
        groupState: [GroupStateEnum.PLANNING],
        ...filterOptions,
      },
      sorterOptions,
    ),
  );

  const planningGroupTotalElements = planningGroups?.data?.totalElements ?? 0;

  const onHoldGroups = useQuery([UseQueryTypes.ON_HOLD, sorterOptions, filterOptions], () =>
    filterGroups(
      {
        ...projectCoordinatorParam,
        groupState: [GroupStateEnum.ON_HOLD],
        ...filterOptions,
      },
      sorterOptions,
    ),
  );

  const groupDataSource = {
    [GroupStateEnum.ON_HOLD]: onHoldGroups.data,
    [GroupStateEnum.PLANNING]: planningGroups.data,
  };

  const updateGroupMutation = useMutation(updateGroupById, {
    onSuccess: () => cache.invalidateQueries(activeGroupStatus),
  });

  const isScreenReady = !planningGroups.isLoading && !onHoldGroups.isLoading && !updateGroupMutation.isLoading;

  const handleOpenFilterDrawer = () => openDrawer(AppFormsEnum.FilterGroupForm, 'Filter Group');

  return (
    <>
      <Spin spinning={!isScreenReady}>
        <Row>
          <Col span={24}>
            <Row align="middle" gutter={8}>
              <Col span={12}>
                <Row>
                  <Col span={18}>
                    <Row>
                      <Col>
                        <Button
                          variant={GroupStateEnum.PLANNING === activeGroupStatus ? 'primary' : 'default'}
                          onClick={() => {
                            setActiveGroupStatus(GroupStateEnum.PLANNING);
                          }}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            height: '100%',
                          }}
                        >
                          {groupStateOptions[GroupStateEnum.PLANNING].label}
                        </Button>
                      </Col>
                      <Col style={{ marginRight: 16 }}>
                        <Button
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            height: '100%',
                          }}
                          variant={GroupStateEnum.ON_HOLD === activeGroupStatus ? 'primary' : 'default'}
                          onClick={() => {
                            setActiveGroupStatus(GroupStateEnum.ON_HOLD);
                          }}
                        >
                          {groupStateOptions[GroupStateEnum.ON_HOLD].label}
                        </Button>
                      </Col>
                      <FilterButton onClick={handleOpenFilterDrawer} />
                    </Row>
                  </Col>
                </Row>
              </Col>

              <GroupStatistics groupStatus={activeGroupStatus} />
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between">
              <ResultSummary text={`${planningGroupTotalElements} result found`} />
            </Row>
            <Row justify="end">
              <ChangeView onClick={modalAdapter.openSettings} />
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table<IGroup>
              {...groupTable.getTableProps()}
              dataSource={groupDataSource[activeGroupStatus]?.groups}
              rowKey={(group) => group.id}
            />
            <Pagination
              {...groupTable.getPaginationProps()}
              total={groupDataSource[activeGroupStatus]?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
