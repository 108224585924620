export type DayOfWeeks = keyof typeof DayOfWeekEnum;

export enum DayOfWeekEnum {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum TimeScaleEnum {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type TimeScale = keyof typeof TimeScaleEnum;

export type DurationModel = {
  timeScale: TimeScale;
  value: number;
};

export type ScheduleModel = {
  dayOfWeek: DayOfWeeks;
  endHour: string;
  groupId: number;
  id: number;
  startHour: string;
};

export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortDirection = keyof typeof SortDirectionEnum | undefined;

export type EditForms = {
  entityId: number;
};

export enum PeriodEnum {
  TODAY = 'TODAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  ALL = 'ALL',
}

export type PeriodType = keyof typeof PeriodEnum;

export type Sorters = {
  pageNumber: number;
  pageSize: number;
  sortBy: React.Key | readonly React.Key[] | string;
  sortDirection: SortDirection;
  relationalSort: Boolean;
  editId?: number;
  applicantId?: number;
  term?: string;
  commentTerm?: string;
  [key: string]: any;
};

export interface FilterResponse {
  currentPage: number;
  totalElements: number;
  totalPages: number;
  editId?: number;
}

export type FilterOptions = Sorters & EditForms;

export interface IPopoverComponent {
  content: string;
  children: JSX.Element;
}

export enum Branch {
  YEREVAN = 'YEREVAN',
  VANADZOR = 'VANADZOR',
}

export enum WedgedTypes {
  DATE = 'DATE',
  LEVEL = 'LEVEL',
  INSTRUCTOR_STATUS = 'STATUS',
  APPLICANT_STATUS = 'APPLICANT_STATUS',
  APPLICANT_DROPOUT_MONTHS = 'APPLICANT_DROPOUT_MONTHS',
}

export enum WedgedLevels {
  LEVEL_0 = 'LEVEL_0',
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
}
