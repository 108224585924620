import { produce } from 'immer';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { parseLocationSearch, stringifyObject } from '../helpers/stringifyObj.helper';
import { FilterOptions } from '../types/general.types';

const checkType = (value: any) => typeof value;

export type ChangeOptionsType = (
  newOptions: Partial<{
    filterOptions: Partial<FilterOptions>;
  }>,
  params?: {
    isNewOption: boolean;
    path: string;
  },
) => void;

export const useFilter = <T extends Partial<FilterOptions>>(defaultOptions: T) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryOptions = useMemo(() => {
    const currentOptions = parseLocationSearch(location.search);

    let resultOptions = produce(defaultOptions, (draft) => Object.assign(draft, currentOptions));

    const isTermValueTypeNumber = checkType(resultOptions.term) === 'number';

    if (isTermValueTypeNumber)
      resultOptions = {
        ...resultOptions,
        term: String(resultOptions.term),
      };

    const isSecondaryTermValueTypeNumber = checkType(resultOptions.secondaryTerm) === 'number';

    if (isSecondaryTermValueTypeNumber)
      resultOptions = {
        ...resultOptions,
        secondaryTerm: String(resultOptions.secondaryTerm),
      };

    const { pageNumber, pageSize, sortDirection, sortBy, relationalSort, editId, ...rest } = resultOptions;

    const sorterOptions: Partial<FilterOptions> = {
      pageNumber,
      pageSize,
      sortDirection,
      relationalSort,
      sortBy,
    };

    return { filterOptions: rest, sorterOptions, editId };
  }, [defaultOptions, location.search]);

  const changeOptions = useCallback(
    (newOptions: Partial<typeof queryOptions>, params?: { isNewOption: boolean; path: string }) => {
      const currentOptions = params?.isNewOption ? {} : parseLocationSearch(location.search);

      const { editId } = newOptions;

      const resultOptions = produce(currentOptions, (draft) =>
        Object.assign(draft, {
          editId,
          ...newOptions.filterOptions,
          ...newOptions.sorterOptions,
        }),
      );

      const isEmptyArray = Object.entries(resultOptions).reduce((acc: any, [key, value]) => {
        const isItemNotEmptyArray = Array.isArray(value) && !value?.length;

        if (isItemNotEmptyArray || value === undefined) {
          return acc;
        }
        acc[key] = value;
        return acc;
      }, {});

      const search = `${params?.path ?? ''}?${stringifyObject(isEmptyArray)}`;
      navigate(search);
    },
    [location.search, navigate],
  );

  const resetOptions = () => {
    navigate({ search: '' });
  };

  return { ...queryOptions, changeOptions, resetOptions };
};

export const useEntityId = () => {
  const defaultFilterOptions: Partial<FilterOptions> = {};
  const { editId, changeOptions } = useFilter(defaultFilterOptions);

  const changeId = (entityId: number) => changeOptions({ editId: entityId });

  return { id: Number(editId), changeId };
};
