import { createContext, FC, useContext, useState } from 'react';
import { useQuery } from 'react-query';

import { NotificationModal } from '../components/display/ReminderModal/NotificationModal';
import { GlobalNotificationOptions } from '../constants/notification.constants';
import { UseQueryTypes } from '../constants/useQuery.constants';
import { notificationsSorter } from '../helpers/globalNotifications.helper';
import { filterNotifications } from '../services/notification.service';
import { filterReminders } from '../services/reminder.service';
import {
  GlobalNotificationTypes,
  INotificationNormalized,
} from '../types/notification.types';
import { IReminderNormalized } from '../types/reminder.types';

interface INotificationContext {
  isNotificationModalOpen: boolean;
  toggleNotification: (open?: boolean) => void;
  changeNotificationOutsideClick: (hasClick: boolean) => void;
}

const notificationContext = createContext<INotificationContext>({
  isNotificationModalOpen: false,
  toggleNotification: () => {
  },
  changeNotificationOutsideClick: () => {
  },
});

export const NotificationProvider: FC = ({ children }) => {
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [hasOutsideClick, setHasOutsideClick] = useState(true);

  const toggleNotification = (open?: boolean) => {
    if (open !== undefined) {
      setIsNotificationModalOpen(open);
      return;
    }

    setIsNotificationModalOpen((prev) => !prev);
  };

  const changeNotificationOutsideClick = (hasClick: boolean) => {
    setHasOutsideClick(hasClick);
  };

  const remindersQuery = useQuery(
    UseQueryTypes.FILTER_REMINDER,
    () =>
      filterReminders({
        filters: {
          ...GlobalNotificationOptions.reminderFilterOptions,
          dateTo: new Date().toISOString(),
        },
        sorters: GlobalNotificationOptions.sorterOptions,
      }),
    {
      enabled: isNotificationModalOpen,
    },
  );

  const notificationsQuery = useQuery(
    UseQueryTypes.FILTER_NOTIFICATION,
    () =>
      filterNotifications({
        filters: {},
        sorters: GlobalNotificationOptions.notificationSorterOptions,
      }),
    {
      enabled: isNotificationModalOpen,
    },
  );

  const remindersList = remindersQuery.data?.reminders ?? [];
  const normalizedRemindersList: IReminderNormalized[] = remindersList.map(
    (reminder) => ({
      data: reminder,
      notificationType: GlobalNotificationTypes.REMINDER,
    }),
  );

  const notificationList = notificationsQuery.data?.notifications ?? [];
  const normalizedNotificationList: INotificationNormalized[] =
    notificationList.map((notification) => ({
      data: notification,
      notificationType: GlobalNotificationTypes.NOTIFICATION,
    }));

  const globalNotificationsList = notificationsSorter({
    notifications: normalizedNotificationList,
    reminders: normalizedRemindersList,
  });

  const isLoading = remindersQuery.isLoading || notificationsQuery.isLoading;

  return (
    <notificationContext.Provider
      value={{
        isNotificationModalOpen,
        toggleNotification,
        changeNotificationOutsideClick,
      }}
    >
      {children}
      {isNotificationModalOpen && (
        <NotificationModal
          onClose={toggleNotification}
          isLoading={isLoading}
          globalNotifications={globalNotificationsList}
          hasOutsideClick={hasOutsideClick}
        />
      )}
    </notificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(notificationContext);
};
