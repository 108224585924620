import { IInstructor } from './instructor.types';
import { IUser, UserRole, UserStage } from './user.types';

export enum AuthGroup {
  USER = 'USER',
  INSTRUCTOR = 'INSTRUCTOR',
  STUDENT = 'STUDENT',
}

type AbstractProperties = {
  authGroup?: AuthGroup;
};

export type AbstractUser = (AbstractProperties & UserStage & IInstructor & false) | null;

export type ResetPassword = {
  token: string;
  password: string;
  email: string;
};

export type ResetPasswordQueryParams = {
  token: string;
  email: string;
  authGroup: AuthGroup;
};

export type DynamicWelcome = {
  [key in AuthGroup]: {
    img: string;
    welcome: string;
    title: string;
  };
};

export interface IUseAuth {
  user: IUser;
  checkUserRole: (role: UserRole) => boolean;
}
