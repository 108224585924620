import { Modal } from 'antd';

import {
  GOOGLE_FORM_NAVIGATION_MASSAGE,
  NAVIGATE_BUTTON_CANCEL_TITTLE,
  NAVIGATE_BUTTON_TITTLE,
} from '../../constants/studentSertificate.constants';
import styles from '../Agreements/AgreementsRenderer/AgreementsRenderer.module.css';

const GoogleNavigationWindow = (
  {
    evaluationLink,
    isModalVisible,
    handleToggleModal
  }: { evaluationLink: string, isModalVisible: boolean, handleToggleModal: (isModalVisible: boolean) => void }) => {

  const handleCloseModal = () => {
    handleToggleModal(false)
  }
  const handleNavigate = () => {
    window.location.href = evaluationLink;
  }
  return (
    <Modal
      visible={isModalVisible}
      onOk={handleNavigate}
      okText={NAVIGATE_BUTTON_TITTLE}
      cancelText={NAVIGATE_BUTTON_CANCEL_TITTLE}
      onCancel={handleCloseModal}
      centered
      width={600}
    >
      <div className={styles.wrapper}>
        {GOOGLE_FORM_NAVIGATION_MASSAGE}
      </div>
    </Modal>
  );
};

export default GoogleNavigationWindow;
