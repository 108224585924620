import { Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, CustomDatePickerFormik, Flex } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { TASK_STATUS, TASK_TYPE } from '../../constants/task.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { getAllUser } from '../../services/user.service';
import { IAppForm } from '../../types/form.types';
import { TaskFilterModel, TaskFilterOptions } from '../../types/task.types';

const defaultFilterValues: Partial<TaskFilterOptions> = {};

export const FilterTaskForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { isAdmin } = useAuth();
  const { filterOptions, changeOptions, resetOptions } = useFilter(defaultFilterValues);

  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);
  const ownerContext = useMemo(() => {
    const owners = usersQuery.data || [];
    return generateContext(owners, (owner) => ({
      label: `${owner.firstName} ${owner.lastName}`,
      value: owner.id,
    }));
  }, [usersQuery.data]);

  const handleSubmit = (filterOptions: Partial<TaskFilterModel>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  return (
    <Spin spinning={usersQuery.isLoading}>
      <Formik enableReinitialize initialValues={filterOptions} onSubmit={handleSubmit}>
        <Form
          style={{
            width: '100%',
            height: 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isAdmin() && <Field name="ownerIds" label="Owner" options={ownerContext} component={MultiselectFormik} />}
          <Field name="taskClientType" label="Type" options={TASK_TYPE} component={MultiselectFormik} />
          <Field name="taskStatus" label="Stage" options={TASK_STATUS} component={MultiselectFormik} />
          <Field name="dateFrom" label="Start Date" component={CustomDatePickerFormik} />
          <Field name="dateTo" label="End Date" component={CustomDatePickerFormik} />
          <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
            <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={resetOptions}>
              Reset
            </Button>
            <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary">
              Apply
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Spin>
  );
};
