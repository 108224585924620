const messages = {
  required: 'This field is required!',
  number: 'This field must be a number!',
  requiredArm: 'Պարտադիր',
  hackMessage: 'Congrats you hacked button, but it will not work, until every field will be valid.Thank you, commendable (Aբ)',
  agreementAlert: 'Provide accurate data only!',
  agreementAlertArmenian: '(Խնդրում ենք լրացնել բացառապես ճշգրիտ տվյալներ։)',
  url: 'Invalid URL format. Please start with http:// or https://',
  spreadsheetLink: 'The link is not valid google spreadsheet link'
};

export default messages;
