import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import { useAuth } from '../../../../../contexts/useAuth.context';
import { AppFormsEnum } from '../../../../../forms';
import { getStudentAttachments } from '../../../../../services/student.service';
import { StudentQueryConstants } from '../../../constants/studentQuery.constants';
import { studentAttachmentsTableAdapter } from '../../../helpers/student.helpers';
import { AttachmentsTableHeaderSchema, getAttachmentsSchema } from '../../../tableSchemas/GetAttachmentsSchema';
import { IStudentAttachmentResponse, IStudentAttachmentsTableData } from '../../../types/studentAttachment.types';
import EmptyDataComponent from '../../EmptyData/EmptyDataComponent';
import { StudentTable } from '../../Table/StudentTable';
import { AddAttachment } from '../AddAttachment/AddAttachment';


export function AttachmentsRenderer() {
  const { user } = useAuth()


  const studentAttachmentsQuery = useQuery(
    [StudentQueryConstants.STUDENT_ATTACHMENTS],
    () =>
      getStudentAttachments(user?.id as number),
    { enabled: Boolean(user) }
  );


  const studentAttachments: IStudentAttachmentResponse | undefined = studentAttachmentsQuery.data;
  const studentAttachmentsTableData = studentAttachmentsTableAdapter(studentAttachments)


  return (
    <Spin spinning={studentAttachmentsQuery.isLoading}>
      <AddAttachment formName={AppFormsEnum.CreateStudentOtherAttachmentForm}/>
      {!isEmpty(studentAttachments) ?
        <StudentTable<IStudentAttachmentsTableData>
          renderSchema={getAttachmentsSchema}
          tableHeaderSchema={AttachmentsTableHeaderSchema}
          dataSource={studentAttachmentsTableData}/>
        : <EmptyDataComponent dataTitle='attachments'/>}
    </Spin>
  );
}
