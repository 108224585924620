import { Col, Row } from 'antd';
import React from 'react';

import { FilterButton, Flex, Search } from '../../../../../components/general';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { useSearchHandler } from '../../../../../hooks/useSearchHandler.hooks';

export const Filter = () => {
  const { openDrawer } = useDrawer();
  const { term, handleSearch } = useSearchHandler();

  const handleOpenApplicantFilterForm = () => {
    openDrawer(AppFormsEnum.FilterApplicantForm, 'Filter Applicant');
  };

  return (
    <Row justify="space-between">
      <Col>
        <Flex>
          <Search placeholder="Search" defaultValue={term} onChange={handleSearch} />
          <Col style={{ marginLeft: '8px' }}>
            <FilterButton onClick={handleOpenApplicantFilterForm} />
          </Col>
        </Flex>
      </Col>
    </Row>
  )
}
