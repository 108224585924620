import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { createTeam } from '../../services/team.service';
import { IAppForm } from '../../types/form.types';
import { ICreateTeamPhone, ITeamPhone } from '../../types/teamPhone.types';
import { TransformTeamForm } from './shared/transformTeam.form';

export const CreateTeamForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();
  const createTeamMutation = useMutation(createTeam);

  const initialValues: Partial<ICreateTeamPhone> = {
    teamName: '',
    members: [],
    phoneNumbers: []
  };

  const handleSubmit = async (teamPhone: ITeamPhone) => {
    const mutationFunc = () =>
      createTeamMutation.mutateAsync({
        teamName: teamPhone.name,
        phoneNumbers: teamPhone.phoneNumbers,
        members: teamPhone.members,
      });

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.TEAMS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={createTeamMutation.isLoading}>
      <TransformTeamForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};
