import { useMutation, useQueryClient } from 'react-query';

import { useDrawer } from '../../../../contexts/drawer.context';
import { useAuth } from '../../../../contexts/useAuth.context';
import { withCatch } from '../../../../helpers/error.helpers';
import { useEntityId } from '../../../../hooks/useFilter.hooks';
import { createStudentAttachment } from '../../../../services/student.service';
import { AttachmentTypeEnum } from '../../../../types/attachment.types';
import { AuthGroup } from '../../../../types/auth.types';
import { StudentQueryConstants } from '../../constants/studentQuery.constants';
import { CreateStudentAttachmentForm } from '../StudentAttachmentForm/CreateStudentAttachmentForm';

export const CreateStudentCVForm = () => {
  const { closeDrawer } = useDrawer();
  const { user } = useAuth();
  const cache = useQueryClient();
  const { id } = useEntityId();

  const studentAttachment = useMutation(createStudentAttachment, {
    onSuccess: () => {
      cache.invalidateQueries(StudentQueryConstants.STUDENT_CV).then(() => window.location.reload());
    },
  });

  const handleAddCV = async (
    newAttachment: { name: string, attachment: string },
  ) => {
    if (user?.id) {
      const newAttachmentWithUserId = {
        ...newAttachment,
        attachmentType: AttachmentTypeEnum.CV,
        userId: user.authGroup === AuthGroup.STUDENT ? user.id : id
      }

      const mutationFunc = () =>
        studentAttachment.mutateAsync(newAttachmentWithUserId);

      withCatch(mutationFunc, {
        onSuccess: () => {
          closeDrawer();
        },
      });
    }
  };


  return (
    <CreateStudentAttachmentForm
      attachmentTitle='File Name'
      attachmentFileTitle='File Upload'
      attachmentType={AttachmentTypeEnum.CV}
      isLoading={studentAttachment.isLoading} handleSubmit={handleAddCV}
    />
  )
}
