import { SortOrder } from 'antd/lib/table/interface';

import { SortDirection, SortDirectionEnum } from '../types/general.types';

const mappingDirection = {
  descend: SortDirectionEnum.DESC,
  ascend: SortDirectionEnum.ASC,
};

export const mapSortDirection = (dir: SortOrder | undefined): SortDirection => {
  if (!dir) return undefined;
  return mappingDirection[dir];
};
