import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/general/Button/Button';
import { Add } from '../../../components/general/Icons/Icons';
import { RoutesConstants } from '../../../helpers/routes.helpers';

export const CreateGroupBtn = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        navigate(RoutesConstants.createGroup());
      }}
      icon={<Add/>}
      className="nav-bar-btn"
      variant="primary"
      htmlType="button"
    >
      Add a new Group
    </Button>
  )
}
