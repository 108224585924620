import { useState } from 'react';

import { dashboardItemTypes } from '../../constants/dashboard.constants';
import { IDashboardLinks } from '../../types/path.types';
import DashboardMenu from './DashboardMapper/components/menu';
import { DashboardSection } from './DashboardMapper/components/section';
import { SimpleLink } from './DashboardMapper/components/simpleLink';

function DashboardLayout({
  links,
}: {
  links: IDashboardLinks;
}) {

  const [menuGroups, setMenuGroups] = useState({});

  const setShowGroups = (name: string) => {
    setMenuGroups({
      ...menuGroups,
      [name]: !menuGroups[name as keyof typeof menuGroups]
    })
  }

  return (
    <>
      {Object.values(links).map((item) => {
        switch (item.type) {
          case dashboardItemTypes.MENU: {
            return <DashboardMenu key={item.id} menuGroups={menuGroups} setShowGroups={setShowGroups} item={item}/>
          }
          case dashboardItemTypes.SECTION: {
            return <DashboardSection key={item.id} menuGroups={menuGroups} setShowGroups={setShowGroups} item={item} />
          }
          default: {
            return <SimpleLink key={item.id} item={item} />
          }
        }
      })}
    </>
  );
}

export default DashboardLayout;
