import { AuthGroup } from "../types/auth.types";
import signOutUser  from "./auth.service";
import signOutInstructor  from "./instructors.service";
import signOutStudent  from "./student.service";

export const signOutByOutGroup = async (authGroup: AuthGroup) => {
  switch (authGroup) {
    case AuthGroup.USER: {
      return await signOutUser();
    }
    case AuthGroup.STUDENT: {
     return  await signOutStudent();
    }
    case AuthGroup.INSTRUCTOR: {
     return  await signOutInstructor();
    }
    default: {
      throw new Error(`Invalid auth group (${authGroup})!`)
    }
  }
}
