import { CommentOutlined, CopyTwoTone } from '@ant-design/icons';
import { Checkbox, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { Flex, PopoverComponent } from '../../components/general';
import { Edit } from '../../components/general/Icons/Icons';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { formatManualRegistrationSource } from '../../helpers/applicants/formatRegistrationSource.helper';
import { getLastItem } from '../../helpers/array.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { updateConsultation } from '../../services/consultant.service';
import type { RenderColumn } from '../../types/column.types';
import type { IConsultant } from '../../types/consultation.types';

const { Text } = Typography;

export const NameConsultantColumn: RenderColumn<IConsultant> = (_, student) => {
  return (
    <div>
      <Text>{student.name}</Text> <br />
      <Text type="secondary">{student.email}</Text>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(student.email)}
        style={{ marginLeft: 8, fontSize: 16 }}
      />
    </div>
  );
};

export const PhoneApplicantColumn: RenderColumn<IConsultant> = (_, student) => {
  /*const normalizePhone: string =
    PhoneService.normalize(student.phoneNumber)?.toString() || '';*/

  return (
    <Flex alignItems="center">
      <Text>{student.phoneNumber}</Text>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(student.phoneNumber)}
        style={{ marginLeft: 8, fontSize: 16, cursor: 'pointer' }}
      />
    </Flex>
  );
};

export const EditConsultantColumn: RenderColumn<IConsultant> = (_, consultant) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenEditApplicantForm = () => {
    changeId(consultant.id);
    openDrawer(AppFormsEnum.EditConsultantForm, `Edit ${consultant.name}`);
  };

  return <Edit onClick={handleOpenEditApplicantForm} style={{ cursor: 'pointer' }} />;
};

export const CommentConsultantColumn: RenderColumn<IConsultant> = (_, consultant) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenAddConsultantCommentForm = () => {
    changeId(consultant.id);
    openDrawer(AppFormsEnum.AddConsultantCommentForm, 'Add Consultant Comment');
  };

  const lastComment: string = getLastItem(consultant.comments)?.content || '';

  return (
    <Flex justifyContent="center">
      <PopoverComponent content={lastComment}>
        <div onClick={handleOpenAddConsultantCommentForm} style={{ cursor: 'pointer', position: 'relative' }}>
          <CommentOutlined style={{ fontSize: 18 }} />
          {consultant.comments.length ? (
            <span
              style={{
                position: 'absolute',
                top: -10,
                right: -10,
                fontSize: 14,
                color: 'red',
              }}
            >
              {consultant.comments.length}
            </span>
          ) : null}
        </div>
      </PopoverComponent>
    </Flex>
  );
};

export const CompleteConsultantColumn: RenderColumn<IConsultant> = (_, consultant) => {
  const [completed, setCompleted] = useState(consultant.isDone);

  useEffect(() => {
    setCompleted(consultant.isDone);
  }, [consultant]);

  const updateMutation = useMutation(updateConsultation);
  const { name, email, phoneNumber, source } = consultant;

  const handleToggle = async () => {
    const updatedConsultant = {
      name,
      email,
      phoneNumber,
      source,
      isDone: !completed,
    };

    await withCatch(() =>
      updateMutation.mutateAsync({
        id: consultant.id,
        consultant: updatedConsultant,
      }),
    );
    setCompleted(!completed);
  };

  return (
    <Spin spinning={updateMutation.isLoading}>
      <Flex justifyContent="center" style={{ width: '100%' }}>
        <Checkbox checked={completed} onChange={handleToggle} />
      </Flex>
    </Spin>
  );
};

export const EmailConsultantColumn: RenderColumn<IConsultant> = (_, student) => {
  return (
    <>
      <span>{student.email}</span>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(student.email)}
        style={{ marginLeft: 8, fontSize: 16 }}
      />
    </>
  );
};

export const RegistrationSourceColumn: RenderColumn<IConsultant> = (_, student) => {
  return student.createdBy?.username ? formatManualRegistrationSource(student) : student.source;
};
