import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import {useAuth} from "../../../../../contexts/useAuth.context";
import {
  groupStudentAppRoutes,
  RoutesConstants,
} from '../../../../../helpers/routes.helpers';
import { StudentAbsentees } from '../pages/StudentAbsentees';
import { StudentGrades } from '../pages/StudentExams';
import { StudentPayments } from '../pages/StudentPayments';
import { StudentMainPage } from '../StudentMainPage';

export const StudentAppRoutes: React.FC = ({module}: any) => {
  const { id } = useParams() as { id: string };
  const { isInstructor } = useAuth();

  return (
    <Routes>
      <Route path={RoutesConstants.home()} element={<StudentMainPage />}>
        <Route
          path={groupStudentAppRoutes.absentees()}
          element={<StudentAbsentees />}
        />
        <Route
          path={groupStudentAppRoutes.grades()}
          element={<StudentGrades />}
        />
        {!isInstructor() && <Route
            path={groupStudentAppRoutes.payments()}
            element={<StudentPayments/>}
        />}
        <Route
          path={RoutesConstants.groupPage(':id')}
          element={<Navigate replace to={RoutesConstants.groupPayments(id)} />}
        />
      </Route>
    </Routes>
  );
};
