import { EXAM_TYPE_OPTIONS } from '../../../constants/exam.constants';
import { DateService } from '../../../services/date.service';
import { IApplicantDetailExamColumn } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';
import { ExamType } from '../../../types/exam.types';
import { ExamStatusColumn, OptionsColumn, TitleWithDescriptionColumn } from '../applicantDetail.renderer';

export const examColumns: ColumnSchema<Partial<IApplicantDetailExamColumn>> = {
  uuid: 'examColumns',
  schema: {
    date: {
      title: 'Date',
      width: 175,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    groupTutor: {
      title: 'Group & Tutor',
      width: 220,
      render: (_, { groupName, groupTutor }) => TitleWithDescriptionColumn(groupName, groupTutor),
    },
    examType: {
      title: 'Exam Type',
      width: 120,
      render: (examType: ExamType) => OptionsColumn(examType, EXAM_TYPE_OPTIONS),
    },
    status: {
      title: 'Status',
      width: 120,
      render: (_, { grade, minScore }) => ExamStatusColumn(grade ?? null, minScore ?? 0),
    },
    grade: {
      title: 'Grade',
      width: 50,
      render: (grade, { maxScore }) => `${grade ?? '_'} / ${maxScore}`,
    },
  },
};
