import { stringifyObject } from '../helpers/stringifyObj.helper';
import { Sorters } from '../types/general.types';
import {
  ILesson,
  ILessonsUpdate,
  LessonFilterModel,
  LessonFilterResponse,
} from '../types/lesson.types';
import axios from './config.service';

export function getLessons() {
  return axios.get('/lesson').then((r) => r.data) as Promise<ILesson[]>;
}

export function getLessonById(id: number) {
  return axios.get(`/lesson/${id}`).then((r) => r.data) as Promise<ILesson>;
}

export function createLesson(lesson: Partial<ILesson>) {
  return axios.post('/lesson', lesson).then((r) => r.data) as Promise<ILesson>;
}

export function updateLesson({
  id,
  updatedLesson,
}: {
  id: number;
  updatedLesson: Partial<any>;
}) {
  return axios
    .put(`/lesson/${id}`, updatedLesson)
    .then((r) => r.data) as Promise<ILesson>;
}

export async function bulkUpdateLessons(lessons: ILesson[] | ILessonsUpdate[]) {
  const r = await axios.put('/lesson/bulk', lessons);
  return r.data;
}

export function deleteLesson(id: number) {
  return axios.delete(`/lesson/${id}`).then((r) => r.data) as Promise<boolean>;
}

export function filterLessons(
    {
      filters = {},
      sorters = {},
    } : {
      filters: Partial<LessonFilterModel> ,
      sorters: Partial<Sorters>,
}

) {
  const queryString = stringifyObject(sorters);
  return axios
    .post(`/lesson/filter?${queryString}`, filters)
    .then((r) => r.data) as Promise<LessonFilterResponse>;
}

/*export async function getLessonsByDate(criterias = {}) {
  return filterLessons({
    ...criterias,
  });
}*/
