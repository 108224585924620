import { Checkbox, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { Button, CustomDatePickerFormik, Flex } from '../../components/general';
import { exportSuccessHelper } from '../../helpers/applicants/exportSuccess.helper';
import { withCatch } from '../../helpers/error.helpers';
import { exportApplicantPaymentsInvoice } from '../../services/applicant.service';
import { IPaymentsInvoice } from '../../types/applicant.types';
import { PaymentsInvoiceRequestSchema } from './schemas/invoices.form.schema';

export const ExportInvoiceForm = () => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const exportApplicantPaymentsInvoiceMutation = useMutation(exportApplicantPaymentsInvoice);

  const handleSubmit = async (dates: Partial<IPaymentsInvoice>) => {
    setIsScreenLoading(true);
    const mutationFunc = () => {
      return exportApplicantPaymentsInvoiceMutation.mutateAsync(dates);
    };

    await withCatch(mutationFunc, {
      onSuccess: async (data) => {
        exportSuccessHelper(data);
        setIsScreenLoading(false);
      },
      onError: () => setIsScreenLoading(false),
    });
  };

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          areLastMonthPayments: false,
        }}
        validationSchema={PaymentsInvoiceRequestSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, handleReset, isValid }) => {
          return (
            <Form
              name="filters-1"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field name="dateFrom" label="Date From" component={CustomDatePickerFormik} />
              <ErrorMessage name="dateFrom" component="span" className="error" />
              <Field name="dateTo" label="Date To" component={CustomDatePickerFormik} />
              <ErrorMessage name="dateTo" component="span" className="error" />
              <label>
                <span style={{ marginRight: 10 }}>Export Selected Period Invoices</span>
                <Checkbox
                  name="areLastMonthPayments"
                  checked={values.areLastMonthPayments}
                  onChange={({ target }) => {
                    setFieldValue('areLastMonthPayments', target.checked);
                  }}
                />
              </label>

              <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
                <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={handleReset}>
                  Reset
                </Button>
                <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary" disabled={!isValid}>
                  Export
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
