import { Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { withCatch } from '../../helpers/error.helpers';
import { createConsultation } from '../../services/consultant.service';
import type { ICreateStudent } from '../../types/applicant.types';
import type { ICreateConsultation } from '../../types/consultation.types';
import type { IAppForm } from '../../types/form.types';
import { TransformConsultantForm } from './shared/transformConsultant.form';

export const CreateConsultantForm: IAppForm = () => {
  const { user } = useAuth();
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();

  const createConsultantMutation = useMutation(createConsultation);

  const initialValues: Partial<ICreateStudent> = {};

  const handleSubmit = async (
    createdConsultant: Partial<ICreateConsultation>,
  ) => {
    if (user) createdConsultant.createdBy = user.id?.toString();

    const mutationFunc = () =>
      createConsultantMutation.mutateAsync(
        createdConsultant as ICreateConsultation,
      );

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.CONSULTANTS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={createConsultantMutation.isLoading}>
      <TransformConsultantForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};
