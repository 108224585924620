import { useMemo, useState } from 'react';

import { loadState, saveState } from '../../helpers/localStorage.helpers';
import type {
  ColumnSchema,
  ColumnSettingController,
  ColumnSettings,
} from '../../types/column.types';

export const useTableColumns = <T>(defaultColumns: ColumnSchema<T>) => {
  const [columnSettings, setColumnSettings] = useState<ColumnSettings>(() => {
    const userSettings = loadState(defaultColumns.uuid) || {};
    return Object.fromEntries(
      Object.keys(defaultColumns.schema).map((col) => {
        const isColumnVisible =
          col in userSettings
            ? userSettings[col]
            : !defaultColumns?.hiddenColumns?.includes(col);
        return [col, isColumnVisible];
      }),
    );
  });

  const columns = useMemo(() => {
    const settings = Object.entries(columnSettings);
    return settings
      .filter(([_, isColumnShowing]) => isColumnShowing)
      .map(([key]) => ({
        dataIndex: key,
        ...defaultColumns.schema[key],
      }));
  }, [columnSettings, defaultColumns]);

  const saveSettings = (settings: ColumnSettings) => {
    setColumnSettings(settings);
    saveState(defaultColumns.uuid, settings);
  };

  const getTableColumnSettings = (): ColumnSettingController => ({
    settings: columnSettings,
    onSettingSave: saveSettings,
  });

  return {
    columns,
    getTableColumnSettings,
  };
};
