import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';

import { ResultSummary } from '../../../components/display';
import { ColumnModal } from '../../../components/display/ColumnSettingModal/ColumnModal';
import { FilterButton } from '../../../components/general';
import GroupStatistics from '../../../components/general/Group/GroupStatistics';
import { ChangeView } from '../../../components/general/Icons/Icons';
import { sortersDefaultOptions } from '../../../constants/sorters.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useDrawer } from '../../../contexts/drawer.context';
import { useAuth } from '../../../contexts/useAuth.context';
import { AppFormsEnum } from '../../../forms';
import { useTable } from '../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { defaultOngoingGroupColumns } from '../../../schemas/groups/ongoingCroup.schema';
import { filterGroups } from '../../../services/groups.service';
import { IUseAuth } from '../../../types/auth.types';
import { GroupFilterOptions, GroupStateEnum, IGroup } from '../../../types/group.types';
import { UserRole } from '../../../types/user.types';

const defaultGroupFilterOptions: Partial<GroupFilterOptions> = {
  ...sortersDefaultOptions,
  sortBy: 'startDate',
  sortDirection: 'DESC',
};

export default function OngoingGroups() {
  const { openDrawer } = useDrawer();

  const { filterOptions, sorterOptions } = useFilter(defaultGroupFilterOptions);
  const onGoingGroupsTable = useTable<IGroup>(defaultOngoingGroupColumns);
  const modalAdapter = useColumnModalAdapter(onGoingGroupsTable.getTableColumnSettings());
  const { user, checkUserRole } = useAuth() as IUseAuth;

  const projectCoordinatorParam = checkUserRole(UserRole.ROLE_ADMIN) ? {} : { projectCoordinatorIds: [user?.id!] };

  const ongoingGroups = useQuery([UseQueryTypes.ONGOING, sorterOptions, filterOptions], () =>
    filterGroups(
      {
        ...projectCoordinatorParam,
        ...filterOptions,
        groupState: [GroupStateEnum.ONGOING],
      },
      sorterOptions,
    ),
  );

  const handleOpenGroupFilter = () => openDrawer(AppFormsEnum.FilterGroupForm, 'Filter Groups');

  const isScreenLoading = ongoingGroups.isLoading;

  const foundResultCount = ongoingGroups.data?.totalElements ?? 0;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row>
          <Col span={24}>
            <Row align="middle" gutter={8}>
              <Col span={12}>
                <Row>
                  <FilterButton onClick={handleOpenGroupFilter} />
                </Row>
              </Col>

              <GroupStatistics groupStatus={GroupStateEnum.ONGOING} />
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
          </Col>

          <Col span={24}>
            <Row align="middle" justify="space-between">
              <Col>
                <ResultSummary text={`${foundResultCount} result found`} />
              </Col>
            </Row>
            <Row justify="end">
              <ChangeView onClick={modalAdapter.openSettings} />
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Table<IGroup>
              {...onGoingGroupsTable.getTableProps()}
              dataSource={ongoingGroups?.data?.groups}
              scroll={{ x: 500 }}
              rowKey={(group) => group.id}
            />
            <Pagination
              {...onGoingGroupsTable.getPaginationProps()}
              total={ongoingGroups?.data?.totalElements}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
