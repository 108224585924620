import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { examColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IExam } from '../../../../types/exam.types';

export function ExamTable({ exams }: { exams: Partial<IExam>[] }) {
  const table = useTable<Partial<IExam>>(examColumns);

  return (
    <Table<Partial<IExam>>
      {...table.getTableProps({
        rowKey: ({ id }: Partial<IExam>) => id || -1,
        dataSource: exams,
      })}
    />
  );
}
