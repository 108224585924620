import { Checkbox } from 'antd';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, Flex, Input as FormInput, SingleSelectFormik } from '../../../components/general';
import { MultiselectFormik } from '../../../components/general/Multiselect/Multiselect';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { USER_ROLES, USER_TEAM_POSITION } from '../../../constants/user.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { getTeams } from '../../../services/team.service';
import { ITransform } from '../../../types/form.types';
import { IUser } from '../../../types/user.types';

interface TransformUserType {
  isEditForm?: boolean;
}

export const TransformUserForm = ({
  onSubmit,
  initialValues,
  isEditForm = false,
}: ITransform<IUser> & TransformUserType) => {
  const handleSubmit = (user: Partial<IUser>) => {
    onSubmit({
      ...user,
    });
  };

  const teamsQuery = useQuery(UseQueryTypes.TEAMS, getTeams);

  const teamsIdContext = useMemo(() => {
    return generateContext(teamsQuery.data || [], ({ id, teamName }) => ({
      value: id,
      label: teamName,
    }));
  }, [teamsQuery.data]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {(props) => (
        <Form
          name="filters-1"
          style={{
            width: '100%',
            height: 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Field name="firstName" label="Name" component={FormInput} />
          <Field name="lastName" label="Surname" component={FormInput} />
          <Field name="username" label="Username" component={FormInput} />
          <Field name="email" label="Email" component={FormInput} />
          <Field name="phoneNumber" label="Phone" component={FormInput} />
          {!isEditForm && (
            <Field
              name="password"
              label="One time password"
              type="password"
              component={FormInput}
            />
          )}
          <Field
            name="roles"
            label="User Roles"
            options={USER_ROLES}
            component={MultiselectFormik}
          />

          <Field
            name="teams"
            label="User Teams"
            options={teamsIdContext}
            component={MultiselectFormik}
          />
          <ErrorMessage
            name="teams"
            component="span"
            className="error"
          />

          <Field
            name="teamPosition"
            label="User team position"
            options={USER_TEAM_POSITION}
            component={SingleSelectFormik}
          />
          <ErrorMessage
            name="teamPosition"
            component="span"
            className="error"
          />
          <p style={{ marginTop: 20 }}>
            <label htmlFor="deactivated" style={{ marginRight: 10 }}>
              Deactivate User
            </label>
            <Checkbox
              name="deactivated"
              onChange={(e) => {
                if (e.target.checked)
                  return props.setFieldValue('deactivated', true);
                props.setFieldValue('deactivated', false);
              }}

              checked={Boolean(props.values.deactivated)}
            />
            <ErrorMessage
              name="deactivated"
              component="span"
              className="error"
            />
          </p>
          <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
            <Button
              htmlType="button"
              style={{ minWidth: 160, marginRight: 8 }}
              onClick={() => props.handleReset()}
            >
              Reset
            </Button>
            <Button
              style={{ minWidth: 160 }}
              htmlType="submit"
              variant="primary"
            >
              Apply
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
