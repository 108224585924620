import { Col, Divider, Row } from 'antd';
import { useCallback } from 'react';
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  Button,
  FilterButton,
  Flex,
  Icons,
  Search,
} from '../../../../components/general';
import { getButtonVariant } from '../../../../components/general/Button/Button';
import { useAuth } from '../../../../contexts/useAuth.context';
import { InstructorRoutesConstants,RoutesConstants } from '../../../../helpers/routes.helpers';
import { useSearchHandler } from '../../../../hooks/useSearchHandler.hooks';

export const StudentMainPage = () => {
  const { term, handleSearch } = useSearchHandler();
  const { id: groupId } = useParams() as { id: string };
  const { isInstructor } = useAuth();

  const location = useLocation();
  const getButtonType = useCallback(
    (route: string) => getButtonVariant(location.pathname, route),
    [location.pathname],
  );

  const routesConstants = isInstructor() ? InstructorRoutesConstants : RoutesConstants;

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Flex>
            <Flex alignItems="center" style={{ marginRight: '10px' }}>
              <Link
                to={routesConstants.groupPage(groupId)}
                style={{ width: '40px' }}
              >
                <Icons.ArrowBottom
                  style={{ transform: 'rotate(90deg)' }}
                  width={15}
                  color="#000000"
                />
              </Link>
              {!isInstructor() && (
                <NavLink to={RoutesConstants.groupPayments(groupId)}>
                  <Button
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    variant={getButtonType(
                      RoutesConstants.groupPayments(groupId),
                    )}
                  >
                    Payments
                  </Button>
                </NavLink>
              )}
              <NavLink to={RoutesConstants.groupAbsentees(groupId)}>
                <Button
                  style={{ borderRadius: 0 }}
                  variant={getButtonType(
                    RoutesConstants.groupAbsentees(groupId),
                  )}
                >
                  Absentees
                </Button>
              </NavLink>
              <NavLink to={RoutesConstants.groupGrades(groupId)}>
                <Button
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  variant={getButtonType(RoutesConstants.groupGrades(groupId))}
                >
                  Exams
                </Button>
              </NavLink>
            </Flex>
            <Search
              style={{ marginRight: 8 }}
              placeholder="Search"
              defaultValue={term}
              onChange={handleSearch}
            />
            <FilterButton onClick={() => {}} />
          </Flex>
        </Col>
        <Divider />
        <Col span={24}>{<Outlet />}</Col>
      </Row>
    </>
  );
};
