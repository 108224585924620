import { Checkbox, Col, Row, Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { DependentField } from '../../components/display/DependentField/DependentField';
import { Button, CustomDatePickerFormik, Flex, SingleSelectFormik } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import {
  ApplicantPaymentMethodOptions,
  ApplicantRegistrationSource, ApplicantStages,
  ApplicantStatusOptions,
  defaultUserOption,
  StudentStatusOptions,
} from '../../constants/applicant.constants';
import { BranchOptions } from '../../constants/constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { getCourses } from '../../services/courses.service';
import { DateService } from '../../services/date.service';
import { getGroupNames } from '../../services/groups.service';
import { filterLeadGen } from '../../services/leadGen.service';
import { getAllUser } from '../../services/user.service';
import { FilterApplicantOptions } from '../../types/applicant.types';
import { IAppForm } from '../../types/form.types';
import { IGroupNameOptions } from '../../types/group.types';
import { ILeadGen } from '../../types/leadGen.types';

const defaultApplicantsFilterOptions: Partial<FilterApplicantOptions> = {};

export const FilterApplicantForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { pathname } = useLocation();

  const { filterOptions, changeOptions, resetOptions } = useFilter(defaultApplicantsFilterOptions);

  const courses = useQuery(UseQueryTypes.COURSES, getCourses);

  const { data: groupsData } = useQuery(UseQueryTypes.GROUP_NAMES, getGroupNames);

  const { data: leadGenData } = useQuery(UseQueryTypes.LEAD_GEN, () => filterLeadGen({}, {}));

  const coursesContext = useMemo(() => {
    return generateContext(courses.data || [], (course) => ({
      label: course.name,
      value: course.id,
    }));
  }, [courses.data]);

  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);

  const usersContext = useMemo(() => {
    const users = usersQuery.data || [];
    const userOptions = generateContext(users, (user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
    Object.assign(userOptions, defaultUserOption);
    return userOptions;
  }, [usersQuery.data]);

  const initialValues = filterOptions;

  const handleSubmit = (filterOptions: Partial<FilterApplicantOptions>) => {
    if (filterOptions.createdBy === -1) {
      filterOptions.createdBy = null;
    }

    changeOptions({ filterOptions });
    closeDrawer();
  };

  const isScreenLoading = courses.isLoading || usersQuery.isLoading;

  const isGroupsPage = pathname.includes('groups');

  const isApplicantsByCourse = pathname.includes('applicants/course');

  const isStudents = pathname.includes('students');

  const groupData: IGroupNameOptions[] = [...(groupsData || [])];

  if (!isStudents) {
    groupData?.push({ id: -1, groupName: 'Not Assigned' });
  }

  const { INTRO_GRADUATED, ...statusOptions } = ApplicantStatusOptions;
  return (
    <Spin spinning={isScreenLoading}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
        {(props) => {
          const courseIds = props.values.courseIds;

          return (
            <Form
              name="filters-1"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {!isGroupsPage && (
                <Row gutter={8}>
                  <Col span={24}>
                    <Field label="Course" name="courseIds" component={MultiselectFormik} options={coursesContext} />
                  </Col>
                  {!isApplicantsByCourse && (
                    <Col span={24}>
                      <DependentField<IGroupNameOptions, number>
                        changeBy={courseIds}
                        data={groupData}
                        label="Group"
                        name="groupIds"
                        filterCb={({ course, id }: IGroupNameOptions) => {
                          if (!courseIds?.length) return true;
                          return courseIds.some((cId) => course?.id === cId) || id === -1;
                        }}
                        generateContextCb={({ id, groupName }: IGroupNameOptions) => ({
                          value: id,
                          label: groupName,
                        })}
                      />
                    </Col>
                  )}
                </Row>
              )}
              <Field
                name="dateFrom"
                label={isStudents ? 'Application Date From' : 'Date From'}
                parseDate={DateService.getStartOfDay}
                formatDate={(date: Date) => DateService.getDateServer(date)}
                component={CustomDatePickerFormik}
              />
              <Field
                name="dateTo"
                label={isStudents ? 'Application Date To' : 'Date To'}
                parseDate={DateService.getEndOfDay}
                formatDate={(date: Date) => DateService.getDateServer(date)}
                component={CustomDatePickerFormik}
              />
              {isStudents && (
                <>
                  <Field
                    label="Group Start Date From"
                    name="startDateFrom"
                    parseDate={DateService.getEndOfDay}
                    formatDate={(date: Date) => DateService.getDateServer(date)}
                    component={CustomDatePickerFormik}
                  />
                  <Field
                    label="Group Start Date To"
                    name="startDateTo"
                    parseDate={DateService.getEndOfDay}
                    formatDate={(date: Date) => DateService.getDateServer(date)}
                    component={CustomDatePickerFormik}
                  />
                  <Field
                    label="Enrolled Date From"
                    name="enrolledDateFrom"
                    parseDate={DateService.getEndOfDay}
                    formatDate={(date: Date) => DateService.getDateServer(date)}
                    component={CustomDatePickerFormik}
                  />
                  <Field
                    label="Enrolled Date To"
                    name="enrolledDateTo"
                    parseDate={DateService.getEndOfDay}
                    formatDate={(date: Date) => DateService.getDateServer(date)}
                    component={CustomDatePickerFormik}
                  />
                  <Field
                    label="Exam Scheduled Date From"
                    name="scheduledDateFrom"
                    parseDate={DateService.getEndOfDay}
                    formatDate={(date: Date) => DateService.getDateServer(date)}
                    component={CustomDatePickerFormik}
                  />
                  <Field
                    label="Exam Scheduled Date To"
                    name="scheduledDateTo"
                    parseDate={DateService.getEndOfDay}
                    formatDate={(date: Date) => DateService.getDateServer(date)}
                    component={CustomDatePickerFormik}
                  />
                </>
              )}
              <Field
                label="Status"
                name="statuses"
                component={MultiselectFormik}
                options={isStudents ? StudentStatusOptions : statusOptions}
              />
              <Field
                label="Payment Method"
                name="paymentMethods"
                component={MultiselectFormik}
                options={ApplicantPaymentMethodOptions}
              />
              {!isStudents && (
                <Field
                  label="Exam Scheduled Date"
                  name="scheduledDate"
                  parseDate={DateService.getEndOfDay}
                  formatDate={(date: Date) => DateService.getDateServer(date)}
                  component={CustomDatePickerFormik}
                />
              )}
              <Field
                label="Registration Source"
                name="registrationSource"
                component={MultiselectFormik}
                options={ApplicantRegistrationSource}
              />

              <Field label="Created By" name="createdBy" component={MultiselectFormik} options={usersContext} />

              <Field name="branches" label={`${isStudents ? ApplicantStages.STUDENT : ApplicantStages.APPLICANT} Branch`} component={SingleSelectFormik} options={BranchOptions} />
              <ErrorMessage name="branch" component="span" className="error" />

              <DependentField<ILeadGen, number>
                changeBy={courseIds}
                data={leadGenData?.leadGenSources}
                label="Lead Generation"
                name="leadGen"
                filterCb={({ courseId }: ILeadGen) => {
                  if (!courseIds?.length) return true;
                  return courseIds.some((cId) => courseId === cId || courseId === null);
                }}
                generateContextCb={({ id, sourceName }: ILeadGen) => ({
                  value: id,
                  label: sourceName,
                })}
              />

              <label style={{ margin: '10px 0 10px 0' }}>
                <span style={{ marginRight: 10 }}>Has promo code</span>
                <Checkbox
                  name="hasPromoCode"
                  checked={props.values.hasPromoCode}
                  onChange={({ target }) => {
                    props.setFieldValue('hasPromoCode', target.checked);
                  }}
                />
              </label>

              <label style={{ margin: '10px 0 10px 0' }}>
                <span style={{ marginRight: 10 }}>Saved applicants for contact later</span>
                <Checkbox
                  name="contactLater"
                  checked={props.values.contactLater}
                  onChange={({ target }) => {
                    props.setFieldValue('contactLater', target.checked);
                  }}
                />
              </label>

              <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
                <Button
                  htmlType="button"
                  style={{ minWidth: 160, marginRight: 8 }}
                  onClick={() => {
                    resetOptions();
                    props.handleReset();
                  }}
                >
                  Reset
                </Button>
                <Button style={{ minWidth: 160 }} htmlType="submit" variant="primary">
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
