import { Spin, Table, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { TBadge } from '../../../../../../components/display/Badges/Badges';
import { Button, Flex } from '../../../../../../components/general';
import { StatusesPriority } from '../../../../../../constants/applicant.constants';
import { UseQueryTypes } from '../../../../../../constants/useQuery.constants';
import {
  calculateByStatus,
  getStatisticsByDropOutMonths,
} from '../../../../../../helpers/applicants/calculateByStatus.helper';
import { parseLocationSearch } from '../../../../../../helpers/stringifyObj.helper';
import { useTable } from '../../../../../../hooks/table/useTable';
import { getDefaultApplicantPaymentColumns } from '../../../../../../schemas/payments/payments.schema';
import { getGroupDetailWithPayment, getGroupPayments } from '../../../../../../services/payment.service';
import { WedgedTypes } from '../../../../../../types/general.types';
import type { IExtendedGroupStudents, IGroupStudents, IPayment } from '../../../../../../types/payment.types';
import { Statistics } from './components/statistics';
import { GroupPaymentBadgeCollection } from './constants';
import {
  filterApplicants,
} from './helper';

const { Text } = Typography;

export const StudentPayments = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const [filteredStudents, setFilteredStudents] = useState<IExtendedGroupStudents[]>();
  const navigate = useNavigate();
  const location = useLocation();

  const groupPayments = useQuery([UseQueryTypes.PAYMENT, groupId], () => getGroupPayments(Number(groupId)));

  const groupPaymentDetailInfo = useQuery([UseQueryTypes.GROUP_PAYMENT, groupId], () =>
    getGroupDetailWithPayment(Number(groupId)),
  );

  let paymentTable = useTable(getDefaultApplicantPaymentColumns(groupPayments.data));

  const groupStudents = useMemo(() => {
    const combined =(groupPaymentDetailInfo.data?.students || [])
      .map((groupStudent: IGroupStudents): IExtendedGroupStudents => {
        // const studentData = findFromList(
        //   studentsFromPaymentDetailInfo,
        //   'id',
        //   groupStudent.id,
        // );
        const studentLastComment = groupStudent?.comments?.[groupStudent?.comments?.length - 1];

        const groupPaymentMethod = groupStudent?.group?.tuitionFee?.feeType;

        const lastItemPaymentsGroup = groupPayments.data?.[groupPayments.data.length - 1] as IPayment;

        return {
          ...groupStudent,
          dropoutMonth: groupStudent.dropoutMonth,
          phoneNumber: groupStudent?.phoneNumber,
          comment: studentLastComment,
          amount: lastItemPaymentsGroup?.amount,
          currency: lastItemPaymentsGroup?.currency,
          method: groupPaymentMethod,
        };
      });

    return combined.sort(
      ({ status: firstStatus }, { status: secondStatus }) =>
        StatusesPriority[firstStatus] - StatusesPriority[secondStatus],
    );

  }, [groupPaymentDetailInfo.data, groupPayments.data])

  const isScreenLoading = groupPaymentDetailInfo.isLoading || groupPayments.isLoading;

  const getStatisticByApplicantStatus = useCallback((studentPayments: IExtendedGroupStudents[] | undefined) => {
    if (studentPayments) {
      return studentPayments.reduce((acc, curr) => calculateByStatus(acc, curr), {});
    }
  }, []);

  const { applicantsStatuses, applicantDropoutMonths } = useMemo(() => {
    return {
      applicantsStatuses: getStatisticByApplicantStatus(groupStudents.filter(item => GroupPaymentBadgeCollection.includes(item.status))) as TBadge[],
      applicantDropoutMonths: getStatisticsByDropOutMonths(groupStudents || [])
    }
  },[groupStudents]);

  const handleStudentsFilter = useCallback(() => {
    const search = parseLocationSearch(location.search);
    const filteredStudents = filterApplicants(search, groupStudents);

    filteredStudents && setFilteredStudents(filteredStudents);
  },[groupStudents, location.search]);

  const handleResetFilter = () => {
    navigate({ search: ''});
    setFilteredStudents([])
  }

  useEffect(handleStudentsFilter, [handleStudentsFilter]);

  const foundResult = groupStudents?.length || 0
  const showedResultCount = filteredStudents?.length ?  filteredStudents.length : foundResult;


  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text type="secondary" style={{ marginBottom: 20 }}>
          {foundResult} result found showing {showedResultCount}
          of {foundResult}
        </Text>
      </Flex>
      {
        applicantsStatuses &&
        <Statistics badges={applicantsStatuses} wedgedType={WedgedTypes.APPLICANT_STATUS} />
      }
      {
        applicantDropoutMonths &&
        <div style={{ borderRadius: '4px' }}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button style={{ width: 'max-content', display: 'block' }}>Filter Applicants by dropout month</Button>
            <Button style={{ width: 'max-content', display: 'block' }} onClick={handleResetFilter}>Reset Filters</Button>
          </div>
          <Statistics badges={applicantDropoutMonths} wedgedType={WedgedTypes.APPLICANT_DROPOUT_MONTHS} />
        </div>
      }
      <Spin spinning={isScreenLoading}>
        <Table
          style={{ margin: '20px 0' }}
          {...paymentTable.getTableProps({ bordered: true })}
          dataSource={filteredStudents?.length ?  filteredStudents : groupStudents }
          scroll={{ y: 500 }}
          rowKey={(r) => r.id}
        />
      </Spin>
    </>
  );
};
