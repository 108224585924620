import * as Yup from 'yup';

import messages from '../../constants/messages.constants';

const { required } = messages;

export const StudentFeedbackFormSchema = Yup.object().shape({
  rating: Yup.number().nullable(),
  content: Yup.string().required(required),
});
