import { Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Button, CustomDatePickerFormik, Flex } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from "../../contexts/useAuth.context";
import { generateContext } from '../../helpers/generateContext.helpers';
import { useFilter } from '../../hooks/useFilter.hooks';
import { DateService } from "../../services/date.service";
import { getAllUser } from '../../services/user.service';
import { IAppForm } from '../../types/form.types';
import { MailFilterOptions } from '../../types/mail.types';
import { UserRole } from '../../types/user.types';

const defaultFilterOptions: Partial<MailFilterOptions> = {};

export const FilterMailForm: IAppForm = () => {
  const { checkUserRole } = useAuth();
  const { closeDrawer } = useDrawer();
  const { filterOptions, changeOptions, resetOptions } =
    useFilter(defaultFilterOptions);

  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);

  const usersContext = useMemo(() => {
    const users = usersQuery.data || [];
    return generateContext(users, (user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  }, [usersQuery.data]);

  const handleFilterSubmit = (filterOptions: Partial<MailFilterOptions>) => {
    changeOptions({ filterOptions });
    closeDrawer();
  };

  const initialValues = filterOptions;

  const isScreenLoading = usersQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        onSubmit={handleFilterSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {(props) => {
          return (
            <Form
              name="filters"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >

              {checkUserRole(UserRole.ROLE_ADMIN) && <Field
                label="User"
                name="user"
                component={MultiselectFormik}
                options={usersContext}
              />}

              <Field
                name="dateFrom"
                label="Date From"
                parseDate={DateService.getStartOfDay}
                formatDate={(date: Date) => DateService.getDateServer(date)}
                component={CustomDatePickerFormik}
              />

              <Field
                name="dateTo"
                label="Date To"
                parseDate={DateService.getEndOfDay}
                formatDate={(date: Date) => DateService.getDateServer(date)}
                component={CustomDatePickerFormik}
              />

              <Flex
                style={{ marginTop: 'auto' }}
                justifyContent="space-between"
              >
                <Button
                  htmlType="button"
                  style={{ minWidth: 160 }}
                  onClick={() => {
                    resetOptions();
                    props.resetForm();
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ minWidth: 160 }}
                  htmlType="submit"
                  variant="primary"
                >
                  Apply
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
