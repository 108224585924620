import { Flex } from '../../../../components/general';
import { EmptyBox } from '../../../../components/general/Icons/Icons';
import styles from './Emptydata.module.css';

const EmptyDataComponent = ({ dataTitle, hasDescription = true }: { dataTitle: string; hasDescription?: boolean }) => {
  return (
    <Flex justifyContent="center" alignItems="center" className={styles.emptyDataWrapper}>
      <Flex direction="column" justifyContent="center" alignItems="center">
        <EmptyBox />
        <div>
          <span style={{ fontSize: '20px' }}>No data</span>
        </div>
        {hasDescription && (
          <div>
            <span style={{ color: '#A3A7AD' }}>There are no {dataTitle} yet.</span>
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default EmptyDataComponent;
