import * as Yup from 'yup';

import { messages } from '../../../constants/messeges.constants';
import { RegexConstants } from '../../../constants/regex.constants';

export const sendEmailSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .required(messages.required)
      .matches(RegexConstants.emailRegEx, messages.email)
  })
};

export const sendEmailInitialValues = {email: ''};

export const resetPasswordSchema = () => {
  return Yup.object().shape({
    password: Yup
      .string()
      .required(messages.required)
      .min(8, messages.eightOrMore)
      .matches(RegexConstants.passRegex, messages.combine),
    confirmPassword: Yup
      .string()
      .required(messages.required)
      .oneOf(
        [Yup.ref('password'), null],
        messages.confirmation),
  })
};

export const resetPasswordInitialValues = {password: undefined, confirmPassword: undefined};
