import { Table } from 'antd';

import { useTable } from '../../../../hooks/table/useTable';
import { paymentColumns } from '../../../../schemas/applicantDetail/applicantDetail.schema';
import { IApplicantDetailPayment } from '../../../../types/applicantDetail.types';

export function PaymentTable({ payments }: { payments: IApplicantDetailPayment[] }) {
  const table = useTable<IApplicantDetailPayment>(paymentColumns);

  return (
    <Table<IApplicantDetailPayment>
      {...table.getTableProps({
        rowKey: ({ id }: IApplicantDetailPayment) => id,
        dataSource: payments,
      })}
    />
  );
}
