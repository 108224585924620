import { Modal, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

export const WarningModal = ({ submit, text, isModalOpen, cancel }: any) => {
  return (
    <div>
      <Modal
        visible={isModalOpen}
        onOk={submit}
        okText={'Continue'}
        okType={'primary'}
        onCancel={cancel}
        centered
        width={600}
      >
        <Text style={{ fontSize: '16px' }}>{text}</Text>
      </Modal>
    </div>
  );
};
