import { DateService } from '../../../services/date.service';
import { ColumnSchema } from '../../../types/column.types';
import { IComment } from '../../../types/comment.types';
import { AvatarColumn } from '../applicantDetail.renderer';

export const commentColumns: ColumnSchema<IComment> = {
  uuid: 'commentColumn',
  schema: {
    date: {
      title: 'Date',
      dataIndex: 'created_at',
      width: 120,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    commenterAvatar: {
      title: 'Commenter',
      render: (avatar, { commenterUsername }) => AvatarColumn(avatar, commenterUsername),
      width: 120,
    },
    content: {
      title: 'Comment',
      width: 320,
      render: (content) => content,
    },
  },
};
