import GradesRenderer from "../components/Grades/GradesRenderer";

function Grades() {

  return (
    <div>
      <GradesRenderer/>
    </div>
  );
}

export default Grades;
