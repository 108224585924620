import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';

const { required } = messages;
export const TransformTeamPhoneFormSchema = Yup.object().shape({
  name: Yup.string().required(required),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().optional(),
      number: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(8)
        .required("Phone number required")
    })
  )
});
