import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useQuery } from 'react-query';

import { ResultSummary } from '../../../components/display';
import { ColumnModal } from '../../../components/display/ColumnSettingModal/ColumnModal';
import { FilterButton } from '../../../components/general';
import GroupStatistics from '../../../components/general/Group/GroupStatistics';
import { ChangeView } from '../../../components/general/Icons/Icons';
import { sortersDefaultOptions } from '../../../constants/sorters.constants';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useDrawer } from '../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../forms';
import { useTable } from '../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { defaultWaitingForDemoGroupColumns } from '../../../schemas/groups/waitingForDemoGroup.schema';
import { filterGroups } from '../../../services/groups.service';
import { GroupFilterOptions, GroupStateEnum, IGroup } from '../../../types/group.types';

const defaultGroupFilterOptions: Partial<GroupFilterOptions> = {
  ...sortersDefaultOptions,
  sortBy: 'startDate',
  sortDirection: 'DESC',
};

export default function WaitingForDemoGroups() {
  const { sorterOptions, filterOptions } = useFilter(defaultGroupFilterOptions);
  const { openDrawer } = useDrawer();

  const groupTable = useTable<IGroup>(defaultWaitingForDemoGroupColumns);
  const modalAdapter = useColumnModalAdapter(
    groupTable.getTableColumnSettings(),
  );

  const waitingForDemoGroups = useQuery(
    [UseQueryTypes.WAITING_FOR_DEMO, sorterOptions, filterOptions],
    () =>
      filterGroups(
        { ...filterOptions, groupState: [GroupStateEnum.WAITING_FOR_DEMO] },
        sorterOptions,
      ),
  );

  const handleOpenFilterDrawer = () =>
    openDrawer(AppFormsEnum.FilterGroupForm, 'Filter Group');

  const isScreenLoading = waitingForDemoGroups.isLoading;

  const foundResultCount = waitingForDemoGroups.data?.totalElements ?? 0;

  return (
    <div>
      <>
        <Spin spinning={isScreenLoading}>
          <Row>
            <Col span={24}>
              <Row align='middle' gutter={8}>
                <Col span={12}>
                  <Row>
                    <FilterButton onClick={handleOpenFilterDrawer}/>
                  </Row>
                </Col>

                <GroupStatistics
                  groupStatus={GroupStateEnum.WAITING_FOR_DEMO}
                />
              </Row>
              <Divider style={{ margin: '16px 0px' }}/>
            </Col>

            <Col span={24}>
              <Row align='middle' justify='space-between'>
                <ResultSummary text={`${foundResultCount} result found`}/>
              </Row>
              <Row justify='end'>
                <ChangeView onClick={modalAdapter.openSettings}/>
              </Row>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Table<IGroup>
                {...groupTable.getTableProps()}
                dataSource={waitingForDemoGroups?.data?.groups}
                rowKey={(group) => group.id}
              />
              <Pagination
                {...groupTable.getPaginationProps()}
                total={waitingForDemoGroups?.data?.totalElements}
                style={{ marginTop: 20 }}
              />
              <ColumnModal {...modalAdapter.getColumnModalProps()} />
            </Col>
          </Row>
        </Spin>
      </>
    </div>
  );
}
