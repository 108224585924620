import { ConsoleSqlOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../components/general';
import Flex from '../../../../components/general/Flex/Flex';
import { BurgerMenu, LogOut } from '../../../../components/general/Icons/Icons';
import { useMobileMenu } from '../../../../contexts/context';
import { useAuth } from '../../../../contexts/useAuth.context';
import { getEditProfilePathByAuthGroup } from '../../../../helpers/route.helpers';
import MobileMenu from '../MobileMenu/MobileMenu';
import { UserDescriptions } from '../UserProfile/UserDescriptions';
import UserProfileLogo from '../UserProfile/UserProfileLogo';
import HeaderElements from './renderer.accounInfo';

export const AccountInfo = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useMobileMenu();
  const { user, logOut } = useAuth();
  const navigate = useNavigate();

  const handleToggleMenu = () => setIsMobileMenuVisible(!isMobileMenuVisible);

  const navigateToEditProfile = () => {
    const editProfilePath = getEditProfilePathByAuthGroup(user?.authGroup);

    if (editProfilePath) {
      navigate(editProfilePath);
    }
  };

  return (
    <Flex direction="row" alignItems="center" style={{ gap: '8px' }}>
      {isMobileMenuVisible && <MobileMenu handleClose={handleToggleMenu} />}
      <UserProfileLogo handleClick={navigateToEditProfile} avatarLink={user?.avatar} />
      <HeaderElements />
      <Button className="menu-button" onClick={handleToggleMenu}>
        <BurgerMenu />
      </Button>
      <div className="profile-information-responsive">
        <UserDescriptions header={user?.name} content={user?.email} />
        <LogOut style={{ cursor: 'pointer' }} onClick={logOut} />
      </div>
    </Flex>
  );
};
