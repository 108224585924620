import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { Flex } from '../../components/general';
import { useAuth } from '../../contexts/useAuth.context';
import { getAvatar } from '../../helpers/avatar.helper';
import { IRoutes } from '../../helpers/routes.helpers';
import useWindowSize from '../../hooks/useWindowSize.hooks';
import { IUser, UserRole } from '../../types/user.types';
import styles from './Account.module.css';

type Props = {
  RoutesConstants: IRoutes;
};

export default function Account({ RoutesConstants }: Props) {
  const { user } = useAuth() as { user: IUser };
  const { width } = useWindowSize();
  const [isMobileSize, setIsMobileSize] = useState(width <= 590);
  const userFullName = user.firstName && user.lastName ? `${user?.firstName} ${user?.lastName}` : user.name;

  useEffect(() => {
    setIsMobileSize(width <= 590);
  }, [width]);

  return (
    <>
      <Spin spinning={false}>
        <Flex direction={isMobileSize ? 'column' : 'row'}>
          <div>
            <div className={styles.descContainer}>
              <Flex alignItems="center" direction={isMobileSize ? 'row' : 'column'} className={styles.desc}>
                <img className={styles.avatar} src={getAvatar(user.avatar)} alt={userFullName} />
                <Flex direction="column">
                  <p className={styles.descTitle}>{userFullName}</p>
                  <p className={styles.descSubtitle}>@{user?.username || userFullName?.toLocaleLowerCase()}</p>
                </Flex>
              </Flex>
            </div>
            <div>
              <Flex className={styles.nav} alignItems="center" direction="column">
                <NavLink className="nav-link" to={RoutesConstants.accountInfo()} end>
                  Personal Information
                </NavLink>
                <NavLink className="nav-link" to={RoutesConstants.accountPassword()}>
                  Change Password
                </NavLink>
                {RoutesConstants.accountSettings && (
                  <NavLink className="nav-link" to={RoutesConstants.accountSettings()}>
                    Email Settings
                  </NavLink>
                )}
                {(user?.roles || [])?.includes(UserRole.ROLE_ADMIN) && (
                  <NavLink className="nav-link" to={RoutesConstants.accountCreateUser()}>
                    Create User
                  </NavLink>
                )}
              </Flex>
            </div>
          </div>
          <Outlet />
        </Flex>
      </Spin>
    </>
  );
}
