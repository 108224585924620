import { Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { withCatch } from '../../helpers/error.helpers';
import { sendMassMailing } from '../../services/mail.service';
import { createTask } from '../../services/tasks.service';
import { getAllUser } from '../../services/user.service';
import { useAppSelector } from '../../store';
import { selectMailingFormValues } from '../../store/features/mailing/mailingSlice';
import { IAppForm } from '../../types/form.types';
import { SendMail } from '../../types/mail.types';
import { ICreateTask } from '../../types/task.types';
import { IUser } from '../../types/user.types';
import { TransformTaskForm } from './shared/transformTask.form';

export const CreateTaskForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const cache = useQueryClient();

  const formInitialValues = useAppSelector(selectMailingFormValues);

  const { user } = useAuth();
  const sendEmailMutation = useMutation(sendMassMailing);

  const handleEmailSend = async (owner: IUser, newTask: Partial<ICreateTask>) => {
    const mailOptions: Partial<SendMail> = {
      ...formInitialValues,
      to: owner?.email,
      from: 'ACA <info@aca.am>',
      subject: 'ACA task notification',
      tags: ['user', 'notification'],
      template: 'send-task-template',
      variables: {
        DESCRIPTION: newTask.description,
        TITLE: newTask.title,
        RECIPIENT: `${owner?.firstName} ${owner?.lastName}`,
        SENDER: `${user?.firstName} ${user?.lastName}`,
      },
    };
    await sendEmailMutation.mutateAsync(mailOptions);
  };

  const usersQuery = useQuery(UseQueryTypes.USERS, getAllUser);

  const initialValues = {
    dueDate: '',
    owner: 0,
    relatedTo: null,
    taskStatus: '',
    taskType: '',
    title: '',
    applicant: null,
  };

  const createTaskMutation = useMutation(createTask, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.TASKS);
      cache.invalidateQueries(UseQueryTypes.CREATED_TASKS);
    },
  });

  const handleSubmit = async (newTask: Partial<ICreateTask>) => {
    const owner = usersQuery.data?.find((user: IUser) => user.id === newTask.owner);

    const data = {
      ...newTask,
      ownerUuid: owner?.uuid,
    };

    const mutationFunc = () => createTaskMutation.mutateAsync(data as ICreateTask);

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.TASKS);
        cache.invalidateQueries(UseQueryTypes.CREATED_TASKS);
        closeDrawer();
        handleEmailSend(owner!, newTask);
      },
    });
  };

  const isScreenLoading = createTaskMutation.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <TransformTaskForm onSubmit={handleSubmit} initialValues={initialValues} />
    </Spin>
  );
};
