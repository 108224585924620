import { Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useDrawer } from '../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../forms';
import { applicantAttachmentsNormalizer } from '../../../helpers/applicants/applicantAttachmentsNormalizer';
import { withCatch } from '../../../helpers/error.helpers';
import { useEntityId } from '../../../hooks/useFilter.hooks';
import { WarningModal } from '../../../modules/shared/modals/warning.modal';
import { getApplicantAttachment } from '../../../services/applicant.service';
import { deleteAttachmentById } from '../../../services/attachment.service';
import { DrawerTypes } from '../../../types/drawer.types';
import { Button, Flex, Icons } from '../../general';
import { DrawerComponentsEnum } from '.';

export function ApplicantAttachments() {
  const { id } = useEntityId();
  const { openDrawer } = useDrawer();

  const [attachmentData, setAttachmentData] = useState<Partial<{ id: number; name: string }>>({});

  const attachmentByApplicantIdQuery = useQuery(UseQueryTypes.APPLICANT_ATTACHMENTS, () => getApplicantAttachment(id));

  const isScreenLoading = attachmentByApplicantIdQuery.isLoading;

  const normalizedAttachments = applicantAttachmentsNormalizer(attachmentByApplicantIdQuery.data || []);

  const isAttachmentsEmpty = isEmpty(normalizedAttachments);

  const cache = useQueryClient();
  const deleteAttachmentMutation = useMutation(deleteAttachmentById);

  useEffect(() => {
    openDrawer(
      DrawerComponentsEnum.ApplicantAttachments,
      'Student attachments',
      DrawerTypes.Component,
      !attachmentData.id,
    );
  }, [attachmentData]);

  const handlePaymentAction = () => {
    openDrawer(AppFormsEnum.CreateOtherAttachmentForm, 'Create Attachment', DrawerTypes.Form);
  };

  const handleDeleteAttachment = (id: number | undefined) => {
    if (id) {
      return async () => {
        await withCatch(() => deleteAttachmentMutation.mutateAsync(id), {
          onSuccess: () => {
            cache.invalidateQueries(UseQueryTypes.APPLICANT_ATTACHMENTS);
            cache.invalidateQueries(UseQueryTypes.APPLICANTS);

            setAttachmentData({});
          },
        });
      };
    }
  };

  return (
    <Spin spinning={isScreenLoading}>
      <Flex style={{ width: 260, height: 'calc(100vh - 120px)', display: 'flex', flexDirection: 'column' }}>
        {!isAttachmentsEmpty ? (
          Object.entries(normalizedAttachments).map(([attachmentType, attachments]) => (
            <div key={attachmentType} style={{ width: '100%', margin: '10px 0' }}>
              <Text style={{ fontSize: '24px' }}>{attachmentType}</Text>

              {attachments.map(({ link, name, id }) => (
                <Flex key={id} justifyContent="space-between" style={{ width: '100%', margin: '5px 0' }}>
                  <a href={link} rel="noreferrer" target="_blank">
                    {name}
                  </a>

                  <Icons.Delete onClick={() => setAttachmentData({ id, name })} style={{ cursor: 'pointer' }} />
                </Flex>
              ))}
            </div>
          ))
        ) : (
          <Text style={{ fontSize: '18px' }}>User doesn't have attachments.</Text>
        )}
        <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
          <Button variant="primary" onClick={handlePaymentAction}>
            Add Attachment
          </Button>
        </Flex>
      </Flex>

      <WarningModal
        isModalOpen={attachmentData.id}
        cancel={() => setAttachmentData({})}
        submit={handleDeleteAttachment(attachmentData.id)}
        text={`Do you want to delete "${attachmentData.name}" attachment?`}
      />
    </Spin>
  );
}
