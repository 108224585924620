import { Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from '../../../contexts/useAuth.context';
import { AppFormsEnum } from '../../../forms';
import { getStudentAttachments } from '../../../services/student.service';
import { AttachmentTypeEnum } from '../../../types/attachment.types';
import { AddAttachment } from '../components/Attachments/AddAttachment/AddAttachment';
import CVRenderer from '../components/CV/cvRenderer';
import EmptyDataComponent from '../components/EmptyData/EmptyDataComponent';
import { StudentQueryConstants } from '../constants/studentQuery.constants';

export function StudentCV() {
  const { user } = useAuth()

  const studentCVQuery = useQuery(
    [StudentQueryConstants.STUDENT_ATTACHMENTS],
    () =>
      getStudentAttachments(user?.id as number, AttachmentTypeEnum.CV),
    { enabled: Boolean(user) }
  );

  const studentCV = studentCVQuery.data?.attachments;

  const attachmentParams = useMemo(() => {
    const isCVExist = isEmpty(studentCV);
    return {
      cv: studentCV?.[0],
      title: isCVExist? 'Add CV' : 'Change CV' ,
      formName: isCVExist ? AppFormsEnum.CreateStudentCVForm : AppFormsEnum.EditStudentCVForm,
    }
  }, [studentCV]);


  return (
    <Spin spinning={studentCVQuery.isLoading}>
      <AddAttachment
        attachmentId={Number(studentCV?.[0]?.id)}
        btnTitle={attachmentParams.title}
        formTitle={attachmentParams.title}
        formName={attachmentParams.formName}
      />
      {attachmentParams.cv ?
        <CVRenderer cv={attachmentParams.cv}/>
        : <EmptyDataComponent dataTitle='CV'/>}
    </Spin>
  );
}
