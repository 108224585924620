import React from 'react';

import { Flex } from '..';
import styles from './Box.module.css';

interface IBox {
  title: string;
  isRequired?: boolean;
}

export const Box: React.FC<IBox> = ({ title, isRequired, children }) => {
  return (
    <div className={styles.defaultFormWrapper}>
      <Flex className={styles.formWrapperHeader} alignItems="center">
        <p className={styles.formWrapperTitle} style={{ margin: 0 }}>
          {title}
        </p>
        {isRequired && (
          <p
            className={styles.formWrapperTitle}
            style={{ margin: 0, marginLeft: 5, color: 'red' }}
          >
            *
          </p>
        )}
      </Flex>
      <div className={styles.formBody}>{children}</div>
    </div>
  );
};
