/* eslint-disable no-console */
import { Spin } from 'antd';
import { useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../../../constants/useQuery.constants';
import { useEntityId } from '../../../../hooks/useFilter.hooks';
import EmptyDataComponent from '../../../../modules/student/components/EmptyData/EmptyDataComponent';
import { getApplicantComments } from '../../../../services/applicant.service';
import { filterApplicantActions } from '../../../../services/applicantActions.service';
import { DateService } from '../../../../services/date.service';
import { IComment } from '../../../../types/comment.types';
import { Flex } from '../../../general';
import { Collapse } from '../..';
import { Comment } from './section/Comment';
import { Panel } from './section/Panel';

export function ApplicantActions() {
  const cache = useQueryClient();
  const { id: applicantId } = useEntityId();

  const commentsQuery = useQuery(UseQueryTypes.APPLICANT_COMMENTS, () => getApplicantComments(applicantId));

  const applicantActionsQuery = useQuery(
    [UseQueryTypes.APPLICANT_ACTIONS],
    () =>
      filterApplicantActions(
        {
          applicantIds: [+applicantId],
        },
        {},
      ),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.APPLICANTS, data);
      },
    },
  );

  const { applicantActions } = applicantActionsQuery?.data || { applicantActions: [] };

  const applicantComments: IComment[] = (commentsQuery?.data || []).slice().reverse();

  const isScreenLoading = applicantActionsQuery.isLoading || commentsQuery.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Flex
        direction="column"
        style={{
          height: 'calc(100vh - 120px)',
        }}
      >
        <div style={{ width: '100%', overflowY: 'auto' }}>
          {applicantActions.length ? (
            applicantActions.map(({ id, created_at, description, user }, idx) => {
              const filteredComments = applicantComments
                .sort((a, b) => +(a?.created_at || '' < b?.createdAt))
                .filter(({ created_at: commentCreatedAt }) => {
                  return DateService.isBetween(
                    commentCreatedAt || '',
                    created_at,
                    applicantActions[idx + 1]?.created_at,
                  );
                });

              return (
                  <Collapse
                    key={id}
                    hasContent={!!filteredComments.length}
                    panelChildren={<Panel firstName={user.firstName} lastName={user.lastName} avatar={user.avatar} createdAt={created_at} description={description} />}
                    buttonProps={{
                      showText: 'Show Comments',
                      hideText: 'Hide Comments',
                    }}
                  >
                    {filteredComments.map(({ id, content, created_at }) => (
                      <Comment createdAt={created_at} key={id} content={content} />
                    ))}
                  </Collapse>
              );
            })
          ) : (
            <div style={{ width: 300 }}>
              <EmptyDataComponent dataTitle="Applicant Action" hasDescription={false} />
            </div>
          )}
        </div>
      </Flex>
    </Spin>
  );
}
