import { Popover, Typography } from 'antd';

import { IPopoverComponent } from '../../../types/general.types';

export default function PopoverComponent({
  content,
  children,
  ...props
}: IPopoverComponent): JSX.Element {
  const { Text } = Typography;

  return content ? (
    <Popover content={<Text>{content}</Text>} {...props}>
      {children}
    </Popover>
  ) : (
    children
  );
}
