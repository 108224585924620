import { Navigate, Route, Routes } from 'react-router-dom';

import { loadState } from "../helpers/localStorage.helpers";
import { getLoginPathByAuthGroup } from "../helpers/route.helpers";
import { InstructorRoutesConstants, RoutesConstants, StudentRoutesConstants } from '../helpers/routes.helpers';
import ForgetPassword from '../modules/shared/pages/ForgetPassword/ForgetPassword';
import ResetPassword from '../modules/shared/pages/ResetPassword/ResetPassword';
import StudentCreatePassword from '../modules/student/pages/CreatePassword';
import StudentLogin from '../modules/student/pages/Login';
import TutorLogin from '../modules/tutor/pages/Login';
import Login from '../pages/Login/Login';

export default function UnAuthenticatedApp() {
  const authGroup = loadState('authGroup');
  const path = getLoginPathByAuthGroup(authGroup);

  return (
    <Routes>
      <Route path={RoutesConstants.resetPassword()} element={<ResetPassword authGroup={authGroup}/>}/>
      <Route path={RoutesConstants.forgetPassword()} element={<ForgetPassword/>}/>
      <Route path={StudentRoutesConstants.createPassword()} element={<StudentCreatePassword/>}/>
      <Route path={InstructorRoutesConstants.login()} element={<TutorLogin/>}/>
      <Route path={StudentRoutesConstants.login()} element={<StudentLogin/>}/>
      <Route path={RoutesConstants.login()} element={<Login/>}/>
      <Route
        path={RoutesConstants.fallback()}
        element={<Navigate replace to={path}/>}
      />
    </Routes>
  );
}


