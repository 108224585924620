import { DayOfWeeks, FilterOptions, FilterResponse } from './general.types';
import { IGroup } from './group.types';

export enum LessonStateEnum {
  COMPLETED = 'COMPLETED',
  MISSED = 'MISSED',
  UPCOMING = 'UPCOMING',
}

export type LessonState = keyof typeof LessonStateEnum;

export interface ILesson {
  courseNam?: string;
  dayOfWeek: DayOfWeeks;
  endDate: string;
  group: IGroup;
  id: number;
  lessonState: LessonState;
  slackLink?: string;
  startDate: string;
}

export interface ILessonsUpdate {
  id: number;
  lessonState: LessonState;
}

export interface LessonFilterModel {
  courseId: number;
  dateFrom: string;
  dateTo: string;
  groupId: number;
  lessonState: LessonState;
  projectCoordinatorId: number;
}

export type LessonFilterOptions = LessonFilterModel & FilterOptions;

export interface LessonFilterResponse extends FilterResponse {
  lessons: ILesson[];
}
