import { Avatar, Modal, Spin, Table, Typography } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Progress } from '../../components/display';
import { Button, Icons } from '../../components/general';
import { ArrowBottom, Edit, SlackIcon } from '../../components/general/Icons/Icons';
import { GroupFormats } from '../../constants/group.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { appendHttpsToUrl } from '../../helpers/appendHttpsToUrl.helpers';
import { getAvatar } from '../../helpers/avatar.helper';
import { withCatch } from '../../helpers/error.helpers';
import { capitalizeFirstLetter, haveGroupSchedule } from '../../helpers/group.helpers';
import { CustomWarningMessages, NotificationTypes } from '../../helpers/notifications.helpers';
import { InstructorRoutesConstants, RoutesConstants } from '../../helpers/routes.helpers';
import { useTableColumns } from '../../hooks/table/useTableColumns';
import { DateService } from '../../services/date.service';
import { deleteGroupById, launchGroup } from '../../services/groups.service';
import { MoneyService } from '../../services/money.service';
import { RenderColumn } from '../../types/column.types';
import { GroupStateEnum, IGroup } from '../../types/group.types';
import { defaultScheduleColumn } from '../schedules/schedules.schema';

const { Paragraph } = Typography;

export const TutorGroupColumn: RenderColumn<IGroup> = (_, group) => {
  if (!group?.groupInstructors?.length) return 'No instructor';

  return group?.groupInstructors?.map((i, index) => (
    <React.Fragment key={i.instructor.id}>
      <div>
        <Link
          style={{
            textDecoration: 'underline',
            color: '#5451FF',
          }}
          to={RoutesConstants.instructor(i.instructor.id)}
        >
          {i.instructor.name}
        </Link>
        {group.groupInstructors[index + 1] ? ',' : ''}
      </div>
    </React.Fragment>
  ));
};

export const StartDateGroupColumn: RenderColumn<IGroup> = (_, group) => DateService.getFullDate(group.startDate);

export const ProgressGroupColumn: RenderColumn<IGroup> = (_, group) => (
  <Progress percent={group.completenessPercentage} />
);

export const DetailGroupColumn = (_: any, group: IGroup, type: string = '') => {
  const navigate = useNavigate();
  const location = useLocation();
  const path =
    type === 'instructor' ? InstructorRoutesConstants.groupPage(group.id) : RoutesConstants.groupPage(group.id);

  const handleNavigate = () => {
    navigate(path, { state: { isFromTerminated: location.pathname.includes('terminated') } });
  };

  return (
    <ArrowBottom
      style={{ transform: 'rotate(-90deg)', cursor: 'pointer' }}
      width={10}
      color="#161616"
      onClick={handleNavigate}
    />
  );
};

export const InstructorDetailGroupColumn: RenderColumn<IGroup> = (_, group) => {
  return (
    <Link to={InstructorRoutesConstants.groupPage(group.id)}>
      <ArrowBottom style={{ transform: 'rotate(-90deg)' }} width={10} color="#161616" />
    </Link>
  );
};

export const EditGroupColumn: RenderColumn<IGroup> = (_, group) => {
  return (
    <Link to={RoutesConstants.editGroup(group.id)}>
      <Edit width={20} />
    </Link>
  );
};

export const DeletePlanningGroupColumn: RenderColumn<IGroup> = (_, group) => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const cache = useQueryClient();
  const deleteGroupMutation = useMutation(deleteGroupById);

  const handleToggle = () => {
    setIsOpenModal((state) => !state);
  };

  const handleDeleteGroup = (id: number) => {
    return async () => {
      await withCatch(() => deleteGroupMutation.mutateAsync(id), {
        onSuccess: () => cache.invalidateQueries(UseQueryTypes.PLANNING),
      });
      await handleToggle();
    };
  };

  return (
    <Spin spinning={deleteGroupMutation.isLoading}>
      <Icons.Delete onClick={handleToggle} style={{ cursor: 'pointer' }} />
      <Modal
        visible={isModalOpen}
        onOk={handleDeleteGroup(group.id)}
        okText={'Yes'}
        okType={'danger'}
        onCancel={handleToggle}
        centered
        width={600}
      >
        <Text>Permanently Delete group?</Text>
      </Modal>
    </Spin>
  );
};

export const LessonGroupColumn: RenderColumn<IGroup> = (_, group) => {
  const [visible, setVisible] = useState(false);
  const scheduleColumns = useTableColumns(defaultScheduleColumn);

  return (
    <>
      <Button onClick={() => setVisible(true)}>View Schedule</Button>
      <Modal visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)} title="Schedule">
        <Table
          dataSource={group.schedule}
          rowKey={(schedule) => schedule.id}
          pagination={false}
          columns={scheduleColumns.columns}
        />
      </Modal>
    </>
  );
};

export const SlackGroupColumn: RenderColumn<IGroup> = (_, group) => {
  const slackLink = appendHttpsToUrl(group.slackLink || '');
  return (
    <a target="_blank" rel="noreferrer" href={slackLink}>
      <SlackIcon width={20} />
    </a>
  );
};

export const LaunchGroupColumn: RenderColumn<IGroup> = (_, group) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const cache = useQueryClient();
  const navigate = useNavigate();

  const groupLaunchMutation = useMutation(launchGroup, {
    onSuccess: () => {
      cache.invalidateQueries(GroupStateEnum.PLANNING);
      navigate(RoutesConstants.ongoingGroups());
    },
  });

  const handleOnOk = () => setIsModalVisible(false);

  const handleGroupLaunch = async () => {
    const haveSchedule = haveGroupSchedule(group);
    if (!haveSchedule) return setIsModalVisible(true);

    return await withCatch(() => groupLaunchMutation.mutateAsync(group.id));
  };

  const warningMessage = CustomWarningMessages.groupSchedule(group);

  return (
    <>
      <Spin spinning={groupLaunchMutation.isLoading}>
        <Button onClick={handleGroupLaunch}>Launch</Button>
      </Spin>
      <Modal
        onOk={handleOnOk}
        visible={isModalVisible}
        title={NotificationTypes.warning}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Paragraph>{warningMessage}</Paragraph>
      </Modal>
    </>
  );
};

export const SalaryInstructorGroupColumn: RenderColumn<IGroup> = (_, group) => {
  const { id } = useParams<{ id: string }>();
  const salary = group.groupInstructors[0]?.salary;

  const instructor = salary ? { salary } : group.groupInstructors.find((instructor) => instructor?.id === Number(id));

  return (
    <p>
      {instructor?.salary?.amount && MoneyService.beautifyAmount(instructor?.salary?.amount)}
      {instructor?.salary?.currency || 'N/A'}
    </p>
  );
};

export const GroupDescriptionColumn: RenderColumn<IGroup> = (_, group) => {
  const { description } = group;

  return <p>{description || 'N/A'}</p>;
};

export const CourseFormatColumn: RenderColumn<IGroup> = (_, group) => {
  const { courseFormat } = group;
  const capitalizedFormat = capitalizeFirstLetter(GroupFormats[courseFormat].label);

  return <p>{capitalizedFormat}</p>;
};

export const AvatarGroupColumn: RenderColumn<IGroup> = (_, group) => {
  return (
    <div className={group.projectCoordinator?.deactivated ? `deactivated-avatar` : ''}>
      <Avatar src={getAvatar(group.projectCoordinator?.avatar)} style={{ cursor: 'pointer', marginRight: 7 }} />@
      {group.projectCoordinator?.username}
    </div>
  );
};
