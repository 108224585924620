import './Layout.css';
import './MobileLayout.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../components/general';
import Flex from '../../../../components/general/Flex/Flex';
import {
  Logo,
} from '../../../../components/general/Icons/Icons';
import { Loading } from '../../../../components/general/Loading/Loading';
import { MobileMenuProvider } from '../../../../contexts/context';
import { useAuth } from '../../../../contexts/useAuth.context';
import { getHomePathByAuthGroup } from "../../../../helpers/route.helpers";
import { isAdmin } from '../../../../helpers/user.helper';
import { AuthGroup } from "../../../../types/auth.types";
import {
  StudentDashBoardConditionalLinks,
  StudentDashBoardLinks,
} from '../../../student/constants/studentNav.constants';
import { isStudentGraduated } from '../../../student/helpers/student.helpers';
import { InstructorDashBoardLinks } from '../../../tutor/constants/tutorDashboardLinks.constants';
import { conditionalAdminSection, UserDashBoardLinks } from '../../../user/constants/dashboard.constants';
import TopBar from '../TopBar/TopBar';
import DashboardLayout from './DashboardLayout';

interface ILayoutProps {
  children: JSX.Element[] | JSX.Element;
  title: string;
}

export const LayoutByUserGroup = ({
  authGroup,
  // handleClickLink,
}: {
  authGroup?: AuthGroup;
  handleClickLink?: () => void;
}) => {
  const { user } = useAuth();
  let dashboardLinks = null;

  switch (authGroup) {
    case AuthGroup.USER:
      dashboardLinks = isAdmin(user) ? {...UserDashBoardLinks, ...conditionalAdminSection} : UserDashBoardLinks
      break;
    case AuthGroup.STUDENT:
      dashboardLinks =  isStudentGraduated(user) ? { ...StudentDashBoardLinks, ...StudentDashBoardConditionalLinks }:  StudentDashBoardLinks;
      break;
    case AuthGroup.INSTRUCTOR:
      dashboardLinks = InstructorDashBoardLinks;
      break;
  }

  return dashboardLinks ? <DashboardLayout links={dashboardLinks} /> : <div />;
};

export default function SharedLayout({ children, title }: ILayoutProps) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState<boolean>(false)
  const navigateToHome = () => {
    navigate(getHomePathByAuthGroup(user?.authGroup));
  };

  if (user)
    return (
      <MobileMenuProvider value={[isMobileMenuVisible, setIsMobileMenuVisible]}>
        <div>
          <Flex>
            <div className="shared-main-nav-wrapper">
              <Flex direction="column" style={{ width: '100%', }}>
                <Button
                  onClick={navigateToHome}
                  style={{ border: '0', padding: 24 }}
                >
                  <Logo isWithText={false} width={298} color="#fff"/>
                </Button>
                <LayoutByUserGroup authGroup={user?.authGroup}/>
              </Flex>
            </div>
            <div
              className='topbar-and-children-wrapper'
            >
              <TopBar title={title}/>
              <div className='children-wrapper'>
                {children}
              </div>
            </div>

          </Flex>
        </div>
      </MobileMenuProvider>
    );

  return <Loading />;
}
