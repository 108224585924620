import { MassSmsForm } from '../../forms/smsForms/MassSms.form';
import SmsHistory from './SmsHistory';

export const MassSms = () => {
  return (
    <>
      <MassSmsForm />
      <SmsHistory />
    </>
  )
};
