import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ResultSummary } from '../../components/display';
import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import { Button, FilterButton, Flex,Icons, Search } from '../../components/general';
import { ChangeView } from '../../components/general/Icons/Icons';
import SwitchDoubleButton from '../../components/general/SwichDubleButton/SwichDubleButton';
import { PageSizeOptions } from '../../constants/sorters.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { useAuth } from '../../contexts/useAuth.context';
import { AppFormsEnum } from '../../forms';
import { exportSuccessHelper } from '../../helpers/applicants/exportSuccess.helper';
import { withCatch } from '../../helpers/error.helpers';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { createdTasksColumns, defaultTasksColumns } from '../../schemas/tasks/tasks.schema';
import { exportTasks, filterTasks } from '../../services/tasks.service';
import { useAppDispatch } from '../../store';
import { sharedSelectsActions } from '../../store/features/sharedSelects/sharedSelectsSlice';
import { SortDirectionEnum } from '../../types/general.types';
import type { ITaskGET, TaskFilterOptions } from '../../types/task.types';
import { TaskSourceEnum } from '../../types/task.types';

export default function Tasks() {
  const { user } = useAuth();
  const cache = useQueryClient();
  const dispatch = useAppDispatch();
  const defaultFilterOptions: Partial<TaskFilterOptions> = {
    sortBy: 'created_at',
    sortDirection: SortDirectionEnum.DESC,
    pageSize: PageSizeOptions.defaultPageSize,
  };

  const [activeTaskSource, setActiveTaskSource] = useState<TaskSourceEnum>(TaskSourceEnum.OWNED);

  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions, resetOptions } = useFilter(defaultFilterOptions);
  const { handleSearch, term } = useSearchHandler();

  const taskTable = {
    [TaskSourceEnum.CREATED]: useTable<ITaskGET & { activeTaskSource: TaskSourceEnum }>(createdTasksColumns),
    [TaskSourceEnum.OWNED]: useTable<ITaskGET & { activeTaskSource: TaskSourceEnum }>(defaultTasksColumns),
  };

  const modalAdapter = useColumnModalAdapter(taskTable[activeTaskSource].getTableColumnSettings());

  // TODO: move to the separate custom hook like useRowSelector
  const rowSelectionAdapter = useRowSelectionAdapter<ITaskGET>();

  const selectedTasksCount = rowSelectionAdapter.selectedRows.length;

  const tasksQuery = useQuery(
    [UseQueryTypes.TASKS, sorterOptions, filterOptions],
    () =>
      filterTasks(
        {
          ownerIds: [user?.id!],
          ...filterOptions,
        },
        sorterOptions,
      ),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.TASKS, { ...data, dataSource: TaskSourceEnum.OWNED });
      },
    },
  );

  const createdTasksQuery = useQuery(
    [UseQueryTypes.CREATED_TASKS, sorterOptions, filterOptions],
    () =>
      filterTasks(
        {
          creatorIds: [user?.id!],
          ...filterOptions,
        },
        sorterOptions,
      ),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.CREATED_TASKS, { ...data, dataSource: TaskSourceEnum.CREATED });
      },
    },
  );

  const taskDataSource = {
    [TaskSourceEnum.OWNED]:
      tasksQuery.data?.tasks.map((task) => ({ ...task, activeTaskSource: TaskSourceEnum.OWNED })) || [],
    [TaskSourceEnum.CREATED]:
      createdTasksQuery.data?.tasks.map((task) => ({ ...task, activeTaskSource: TaskSourceEnum.CREATED })) || [],
  };

  const handleOpenFilterTasksForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.FilterTaskForm, 'Filter Tasks');
  };

  const handleOpenCreateForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.CreateTaskForm, 'Add Task');
  };

  const handleOpenBulkUpdateTaskForm = async () => {
    dispatch(
      sharedSelectsActions.selectItems(
        rowSelectionAdapter.selectedRows.map(({ id, taskStatus }: ITaskGET) => ({ id, taskStatus })),
      ),
    );
    openDrawer(AppFormsEnum.BulkUpdateTaskStatusForm, 'Bulk Update Task');
  };

  const handleReset = () => {
    resetOptions();
  };

  useEffect(() => {
    return rowSelectionAdapter.clean;
  }, []);

  const tasksTotalSize = taskDataSource[activeTaskSource]?.length ?? 0;
  const isScreenLoading = createdTasksQuery.isLoading || tasksQuery.isLoading;

  const exportTasksMutation = useMutation(exportTasks);

  const handleExportSelectedTasks = async () => {
    const ids: number[] = rowSelectionAdapter.selectedRows.map((task: ITaskGET) => task.id);
    const mutationFunc = () => {
      return exportTasksMutation.mutateAsync(ids);
    };

    await withCatch(mutationFunc, {
      onSuccess: async (data) => {
        exportSuccessHelper(data);
      },
    });
  };

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row style={{ flexDirection: 'column', rowGap: '16px' }}>
              <SwitchDoubleButton
                leftSIde={{
                  title: 'Owned',
                  active: TaskSourceEnum.OWNED === activeTaskSource,
                  onClick: () => setActiveTaskSource(TaskSourceEnum.OWNED)
                }}
                rightSide={{
                  title: 'Created',
                  active: TaskSourceEnum.CREATED === activeTaskSource,
                  onClick: () => setActiveTaskSource(TaskSourceEnum.CREATED)
                }}
              />
              <Row style={{ rowGap: '16px',  columnGap: '8px' }}>
                <Search
                  placeholder="Search"
                  onChange={handleSearch}
                  defaultValue={term}
                />
                <Flex style={{ columnGap: '8px' }}>
                  <FilterButton onClick={handleOpenFilterTasksForm} />
                  <Button style={{ width: 'max-content', height: '46px' }} onClick={handleReset}>Reset Filters</Button>
                </Flex>
              </Row>
            </Row>
            <Divider style={{ margin: '16px 0px' }} />

            <Row justify="space-between">
              <Col flex="auto">
                <ResultSummary text={`${tasksTotalSize} result found`} />
              </Col>
              {/* <Col style={{ marginRight: 16 }}>
                {rowSelectionAdapter.isSelected && (
                  <BulkUpdateTaskForm
                    selectedTasks={rowSelectionAdapter.selectedRows}
                    initialValues={{
                      taskRequestModel: rowSelectionAdapter.selectedRows[0],
                    }}
                  />
                )}
              </Col>*/}
              <Row>
                {rowSelectionAdapter.isSelected && (
                  <>
                    <Col style={{ marginRight: '8px' }}>
                      <Button variant="primary" onClick={handleOpenBulkUpdateTaskForm}>
                        Bulk Status Update
                      </Button>
                    </Col>
                    <Col style={{ marginRight: '8px' }}>
                      <Button variant="primary" onClick={handleExportSelectedTasks}>
                        Export
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              <Col>
                <Button onClick={handleOpenCreateForm} variant="primary" icon={<Icons.Add />}>
                  Add Task
                </Button>
              </Col>
              <Col style={{ marginLeft: 5 }}>
                <ChangeView onClick={modalAdapter.openSettings} />
              </Col>
            </Row>
            {selectedTasksCount > 0 && <ResultSummary text={`Selected ${selectedTasksCount} reminders`} />}
            <Table<ITaskGET & { activeTaskSource: TaskSourceEnum }>
              {...taskTable[activeTaskSource].getTableProps({
                rowKey: (r) => r.id,
                dataSource: taskDataSource[activeTaskSource],
                rowSelection: rowSelectionAdapter.getProps(),
                style: { marginTop: 25 },
              })}
            />
            <Pagination
              {...taskTable[activeTaskSource].getPaginationProps()}
              total={tasksTotalSize}
              style={{ marginTop: 20 }}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
};
