import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Flex, SingleSelectFormik } from '../../components/general';
import { ReminderStatusOptions } from '../../constants/reminder.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { bulkUpdateRemindersStatus } from '../../services/reminder.service';
import { useAppSelector } from '../../store';
import { selectItems } from '../../store/features/sharedSelects/sharedSelectsSlice';
import styles from '../../styles/general.module.css';
import type { IAppForm } from '../../types/form.types';
import { IReminder } from '../../types/reminder.types';
import FormControlButtons from '../shared/components/formControlButtons';
import { BulkUpdateReminderStatusFormSchema } from './schemas/reminder.forms.schema';

export const BulkUpdateReminderStatusForm: IAppForm = () => {
  const rowSelectionAdapter = useRowSelectionAdapter<IReminder>();
  const selectedReminders = useAppSelector(selectItems);
  const cache = useQueryClient();

  const { closeDrawer } = useDrawer();

  const bulkUpdateMutation = useMutation(bulkUpdateRemindersStatus);

  const handleBulkUpdateState = async (
    { status }: Partial<IReminder>,
    formikHelpers: FormikHelpers<Partial<IReminder>>,
  ) => {
    const updateQuery: Partial<IReminder>[] = selectedReminders.map(({ id }) => ({ id, status }));

    await withCatch(() => bulkUpdateMutation.mutateAsync(updateQuery), {
      onSuccess: async () => {
        formikHelpers.resetForm();
        await cache.invalidateQueries(UseQueryTypes.REMINDER);
        closeDrawer();
      },
      onError: () => {
        closeDrawer();
      },
    });
    await rowSelectionAdapter.clean();
  };

  return (
    <Spin wrapperClassName={styles.spinNormalize} spinning={bulkUpdateMutation.isLoading} style={{ width: '100%' }}>
      <Formik
        initialValues={{}}
        validationSchema={BulkUpdateReminderStatusFormSchema}
        enableReinitialize
        onSubmit={handleBulkUpdateState}
      >
        {({ values, dirty, isValid, handleReset }) => (
          <Form
            name="bulk-update"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
          <Flex style={{ width: '100%', maxWidth: '328px', height: '100%' }} justifyContent='space-between' direction='column'>
            <div style={{ width: '100%' }}>
              <Field
                name="status"
                label="Reminder Status"
                options={ReminderStatusOptions}
                component={SingleSelectFormik}
                required
              />
              <ErrorMessage name="status" component="span" className="error" />
            </div>

            <FormControlButtons
              submitBtnProps={{ disabled: !(dirty && isValid)}}
              resetBtnProps={{ onClick: () => handleReset()}}
            />
          </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
