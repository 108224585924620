import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ResultSummary } from '../../components/display';
import { Button, Icons, Search, Tabs } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { withCatch } from '../../helpers/error.helpers';
import { useTable } from '../../hooks/table/useTable';
import { useEntityId, useFilter } from '../../hooks/useFilter.hooks';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { defaultCourseByTrackColumns } from '../../schemas/courses/coursesByTrack.schema';
import { filterCourses } from '../../services/courses.service';
import { deleteTrack, getAllTracks } from '../../services/track.service';
import { CourseFilterOptions, ICourse } from '../../types/course.types';

const defaultOptions: Partial<CourseFilterOptions> = {
  tracks: [],
  //courseName: "",
};

export default function Tracks() {
  const { openDrawer } = useDrawer();
  const { handleSearch, term } = useSearchHandler();
  const { filterOptions, changeOptions, sorterOptions } =
    useFilter<Partial<CourseFilterOptions>>(defaultOptions);
  const { changeId } = useEntityId();

  const courseTable = useTable(defaultCourseByTrackColumns);

  const cache = useQueryClient();
  const tracks = useQuery(UseQueryTypes.TRACKS, getAllTracks);
  const courses = useQuery(
    [UseQueryTypes.COURSES, filterOptions, sorterOptions],
    () => filterCourses(filterOptions, sorterOptions),
  );

  const deleteTrackMutation = useMutation(deleteTrack, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.TRACKS),
  });

  const handleRemoveTrack = async (id: number) => {
    await withCatch(() => deleteTrackMutation.mutateAsync(id));
  };

  const tracksTotalSize = tracks?.data?.length;
  const isScreenLoading =
    tracks.isLoading || deleteTrackMutation.isLoading || courses.isLoading;

  const handleSelectTrack = (id: number) => {
    changeOptions({ filterOptions: { tracks: [id] } });
  };

  const handleEditTrack = (id: number) => {
    changeId(id);
    openDrawer(AppFormsEnum.EditTrackForm, 'Edit Track');
  };

  const tabOptions = useMemo(
    () =>
      (tracks?.data || []).map(({ name, id }) => ({
        text: name,
        value: id,
      })),
    [tracks?.data],
  );

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={8}>
              <Col span={12}>
                <Row>
                  <Col span={18}>
                    <Row>
                      <Search
                        style={{ marginRight: 8 }}
                        placeholder="Search"
                        defaultValue={term}
                        onChange={handleSearch}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider style={{ margin: '16px 0px' }}/>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="space-between">
              <ResultSummary text={`${tracksTotalSize} tracks found`}/>
              <Row>
                <Col>
                  <Button
                    onClick={() =>
                      openDrawer(AppFormsEnum.CreateTracksForm, 'Create Track')
                    }
                    variant="primary"
                    icon={<Icons.Add/>}
                  >
                    Add Track
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Row gutter={24}>
            <Col span={22}></Col>
          </Row>

          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Tabs
                  title="Track"
                  options={tabOptions}
                  onSelect={({ value }: typeof tabOptions[0]) =>
                    handleSelectTrack(value)
                  }
                  onEdit={({ value }: typeof tabOptions[0]) =>
                    handleEditTrack(value)
                  }
                  onDelete={({ value }: typeof tabOptions[0]) =>
                    handleRemoveTrack(value)
                  }
                />
              </Col>
              <Col span={18}>
                <Table<ICourse>
                  {...courseTable.getTableProps({
                    rowKey: (course) => course.id,
                    dataSource: courses?.data?.courses,
                    style: { marginTop: 30 },
                  })}
                />
                <Pagination
                  {...courseTable.getPaginationProps({
                    total: courses?.data?.totalElements,
                    style: { marginTop: 20 },
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </>
  );
}
