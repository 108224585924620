import React, { useEffect, useState } from 'react';

import Flex from '../../general/Flex/Flex';
import { DatePicker } from '../../general/TimePicker/TimePicker';

interface ITimeProps {
  label?: string;
  getDate: (date: string) => void;
  defaultValue?: string;
}

const makeScheduledDate = (date: string, time: string) => {
  const convertedDate = new Date(date);
  const convertedTime = new Date(time);

  let scheduledDate = new Date(
    convertedDate.getFullYear(),
    convertedDate.getMonth(),
    convertedDate.getDate(),
    convertedTime.getHours(),
    convertedTime.getMinutes(),
    0,
    0,
  );

  return scheduledDate.toISOString();
};


export default function CustomDateAndTimePicker({
  label,
  getDate,
  defaultValue,
}: ITimeProps) {
  const currentDate = defaultValue ? new Date(defaultValue) : new Date();
  const currentDateString = currentDate.toISOString();

  const [time, setTime] = useState<string>(currentDateString);
  const [date, setDate] = useState<string>(currentDateString);

  const handleDateOrTime = (setState: any) => {
    return (value: any) => {
      value && setState(value.toISOString());
    };
  };

  useEffect(() => {
    const scheduledDate = makeScheduledDate(date, time);
    getDate(scheduledDate);
  }, [time, date]);

  return (
    <Flex direction="column" style={{ width: '100%' }}>
      {label && <label style={{ marginBottom: '5px' }}>{label}</label>}
      <Flex
        style={{
          width: '100%',
          gap: '0 10px',
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex direction="column">
          <label style={{ marginBottom: '5px' }}>Date</label>
          <DatePicker
            style={{ padding: '10px 20px' }}
            format="YYYY/MM/DD"
            onChange={handleDateOrTime(setDate)}
            defaultValue={currentDate}
          />
        </Flex>
        <Flex direction="column">
          <label style={{ marginBottom: '5px' }}>Time</label>
          <DatePicker
            style={{ padding: '10px 20px' }}
            picker="time"
            format="HH:mm"
            onOk={handleDateOrTime(setTime)}
            defaultValue={currentDate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
