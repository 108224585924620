import { Pagination, Spin, Table, Typography } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Flex } from '../../../../../components/general';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { withCatch } from '../../../../../helpers/error.helpers';
import { useTable } from '../../../../../hooks/table/useTable';
import { getDefaultApplicantExamColumns } from '../../../../../schemas/exams/exams.schema';
import { bulkUpdateExamGrades } from '../../../../../services/exam.service';
import { getDetailedGroupExamInfoById } from '../../../../../services/groups.service';
import type { IGroupStudentExamForm } from '../types/examForm.types';

const { Text } = Typography;

export const StudentGrades = () => {
  const cache = useQueryClient();
  const { id } = useParams() as { id: string };

  const detailedGroupExamInfo = useQuery(
    UseQueryTypes.GROUP_DETAIL_EXAM_INFO,
    () => getDetailedGroupExamInfoById(Number(id)),
  );

  const bulkUpdateExamGradesMutation = useMutation(bulkUpdateExamGrades, {
    onSuccess: () =>
      cache.invalidateQueries(UseQueryTypes.GROUP_DETAIL_EXAM_INFO),
  });

  const handleBulkGradeUpdate = async (
    gradesMap: IGroupStudentExamForm,
    formik: FormikHelpers<IGroupStudentExamForm>,
  ) => {
    const changedExamsGradesArray = Object.entries(gradesMap).map(
      ([id, grade]) => ({ id: Number(id), grade }),
    );
    const mutationFunc = () =>
      bulkUpdateExamGradesMutation.mutateAsync(changedExamsGradesArray);

    await withCatch(mutationFunc, {
      onSuccess: () => formik.resetForm(),
    });
  };

  const exams = detailedGroupExamInfo.data?.exams;
  const examTable = useTable(getDefaultApplicantExamColumns(exams));

  const isScreenLoading =
    detailedGroupExamInfo.isLoading || bulkUpdateExamGradesMutation.isLoading;

  const detailedGroupExamInfoCount =
    detailedGroupExamInfo.data?.students.length ?? 0;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik
        initialValues={{} as IGroupStudentExamForm}
        onSubmit={handleBulkGradeUpdate}
        enableReinitialize
      >
        {() => (
          <Form>
            <Flex
              style={{ marginBottom: 20 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text type="secondary" style={{ marginBottom: 20 }}>
                {detailedGroupExamInfoCount} result found showing
                {detailedGroupExamInfoCount} of
                {detailedGroupExamInfoCount}
              </Text>
              {/*<Flex>*/}
              {/*  <Button*/}
              {/*    style={{ marginRight: '5px' }}*/}
              {/*    variant="default"*/}
              {/*    htmlType="button"*/}
              {/*    onClick={() => props.resetForm()}*/}
              {/*  >*/}
              {/*    Cancel*/}
              {/*  </Button>*/}
              {/*  <Button*/}
              {/*    disabled={bulkUpdateExamGradesMutation.isLoading}*/}
              {/*    htmlType="submit"*/}
              {/*    variant="primary"*/}
              {/*  >*/}
              {/*    Save*/}
              {/*  </Button>*/}
              {/*</Flex>*/}
            </Flex>
            <Table<any>
              scroll={{
                x: 1000,
              }}
              {...examTable.getTableProps({
                rowKey: (r) => r.id,
                bordered: true,
                dataSource: detailedGroupExamInfo.data?.students,
              })}
            />
            <Pagination
              {...examTable.getPaginationProps({
                style: { marginTop: 20 },
                total: detailedGroupExamInfo.data?.students.length,
              })}
            />
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
