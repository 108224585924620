import { Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import {
  addConsultantComment, deleteConsultationComment,
  getConsultantById, updateConsultationComment,
} from '../../services/consultant.service';
import { IComment } from '../../types/comment.types';
import type { IAppForm } from '../../types/form.types';
import { AddCommentForm } from '../shared/comment.form';

export const AddConsultantCommentForm: IAppForm = () => {
  const cache = useQueryClient();
  const { id: consultantId } = useEntityId();

  const initialValues = { comment: '' };

  const consultantQuery = useQuery(UseQueryTypes.CONSULTANT_ID, () =>
    getConsultantById(consultantId),
  );
  const addConsultantCommentMutation = useMutation(addConsultantComment, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.CONSULTANT_ID),
  });

  const handleAddComment = async ({ comment }: typeof initialValues) => {
    const mutationFunc = () => {
      return addConsultantCommentMutation.mutateAsync({
        consultantId,
        comment: {
          content: comment,
        },
      });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        initialValues.comment = '';
        await cache.invalidateQueries(UseQueryTypes.CONSULTANT_ID);
      },
    });
  };
  const consultantComments = (consultantQuery.data?.comments || [])
  .slice()
  .reverse();

  const isScreenLoading =
    addConsultantCommentMutation.isLoading || consultantQuery.isLoading;

  const editConsultationCommentMutation = useMutation(updateConsultationComment);

  const editComment = async (commentId: number, comment: IComment) => {
    const mutationFunc = () => {
      return editConsultationCommentMutation.mutateAsync({ commentId, comment });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.CONSULTANT_ID);
        await cache.invalidateQueries(UseQueryTypes.CONSULTANTS);
      },
    });
  };

  const deleteConsultationCommentMutation = useMutation(deleteConsultationComment);
  const removeComment = async (commentId: number) => {
    const mutationFunc = () => {
      return deleteConsultationCommentMutation.mutateAsync({ commentId });
    };

    await withCatch(mutationFunc, {
      onSuccess: async () => {
        await cache.invalidateQueries(UseQueryTypes.CONSULTANT_ID);
        await cache.invalidateQueries(UseQueryTypes.CONSULTANTS);
      },
    });
  };

  return (
    <Spin spinning={isScreenLoading}>
      <AddCommentForm
        comments={consultantComments}
        initialValues={initialValues}
        onSubmit={handleAddComment}
        entity={{
          name: consultantQuery.data?.name,
          email: consultantQuery.data?.email,
        }}
        editComment={editComment}
        removeComment={removeComment}
      />
    </Spin>
  );
};
