import ContentLoader from 'react-content-loader';

export function FormLoader() {
  return (
    <ContentLoader
      speed={2}
      width={450}
      height={450}
      viewBox="0 0 450 450"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="38" y="33" rx="0" ry="0" width="275" height="40" />
      <rect x="5" y="88" rx="0" ry="0" width="345" height="30" />
      <rect x="5" y="134" rx="0" ry="0" width="215" height="20" />
      <rect x="5" y="166" rx="0" ry="0" width="385" height="45" />
      <rect x="5" y="230" rx="0" ry="0" width="215" height="20" />
      <rect x="5" y="268" rx="0" ry="0" width="385" height="45" />
      <rect x="5" y="333" rx="0" ry="0" width="385" height="75" />
    </ContentLoader>
  );
}

export function ImageLoader() {
  return (
    <ContentLoader
      speed={2}
      width={530}
      height={900}
      viewBox="0 0 530 900"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="530" height="900" />
    </ContentLoader>
  );
}
