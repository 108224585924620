import { useCallback, useMemo } from 'react';

import { setWedgedFilterOptions } from '../../../helpers/wedged.helper';
import { useFilter } from '../../../hooks/useFilter.hooks';
import { GroupFilterOptions } from '../../../types/group.types';
import { Badge, Flex } from '../../general';

export type TBadge = {
  data?: number | string;
  title: string;
  name?: string;
};

interface IRenderBadge  {
  badge: TBadge;
  handleBadgeClick: (name: string) => void;
}

interface IBadgesProps {
  badges: TBadge[];
  wedgedType: string;
}

enum Colors {
  Blue = 'blue',
  Pink = 'pink',
  Green = 'green',
}
interface IColors {
  [key: number]: Colors;
}

const colorByIdx: IColors = {
  0: Colors.Blue,
  1: Colors.Pink,
  2: Colors.Green,
};

const defaultGroupFilterOptions: Partial<GroupFilterOptions> = {};

export const RenderBadge = ({ badge: { title, data, name }, handleBadgeClick }: IRenderBadge) => {
  const colorIdx = useMemo(
    () => Math.floor(Math.random() * Object.keys(colorByIdx).length),
    [],
  );

  return <Badge handleBadgeClick={handleBadgeClick} type={colorByIdx[colorIdx]} title={title} name={name} statistics={data} />;
};

export default function Badges({ badges, wedgedType }: IBadgesProps) {
  const { changeOptions, filterOptions } = useFilter(defaultGroupFilterOptions);

  const handleBadgeClick = (name: string) => {
    const { contactLater, ...otherOptions } = filterOptions;
    changeOptions({
      filterOptions:{
        ...otherOptions,
        ...setWedgedFilterOptions(wedgedType, name),
      },
    }, { isNewOption: true, path: '' });
  };

  return (
    <Flex
      style={{ width: '100%', padding: '20px 0', flexWrap: 'wrap', pointerEvents: 'none'  }}
    >
      {badges.map((badge) => (
        <RenderBadge handleBadgeClick={() => handleBadgeClick(badge.name as string)} key={badge.title} badge={badge}/>
      ))}
    </Flex>
  );
}
