import {
  ICreatePayment,
  IEditPaymentDate,
  IGroupDetailPayment,
  IPayment,
  IUpdateGroupPayment,
  IUpdatePayment,
  IUpdatePaymentType,
} from '../types/payment.types';
import axios from './config.service';

export async function getGroupDetailWithPayment(id: number) {
  const r = await axios.get(`/groupDetail/${id}/payments`);
  return r.data as Promise<IGroupDetailPayment>;
}

export async function getGroupPayments(id: number) {
  const r = await axios.get(`/payment/group/${id}`);
  return r.data as Promise<IPayment[]>;
}

export async function createPaymentsByGroupId(payment: ICreatePayment) {
  const r = await axios.post(`/payment`, payment);
  return r.data as Promise<boolean>;
}

export async function createApplicantPayment(payment: ICreatePayment) {
  const r = await axios.post(`/payment/applicant`, payment);
  return r.data as Promise<boolean>;
}

export async function getApplicantPaymentByPaymentId(paymentId: number) {
  const r = await axios.get(`/payment/payment/${paymentId}`);
  return r.data as Promise<IPayment[]>;
}

export async function updateApplicantPaymentByPaymentId({ payment, id }: { payment: IUpdateGroupPayment; id: number }) {
  const r = await axios.put(`/payment/applicant/${id}`, payment);

  return r.data as Promise<any>;
}

export async function updatePaymentById({ payment, id }: { payment: IUpdateGroupPayment; id: number }) {
  const r = await axios.put(`/payment/${id}`, payment);

  return r.data as Promise<any>;
}

export async function updateGroupPayments(paymentData: Partial<IUpdatePayment>) {
  const { id } = paymentData;

  const r = await axios.put(`/payment/group/${id}`, paymentData);
  return r.data as Promise<IPayment[]>;
}

export async function bulkUpdatePaymentType(applicants: Partial<IUpdatePaymentType>[]) {
  const r = await axios.put(`/payment/bulk`, applicants);
  return r.data as Promise<IPayment[]>;
}
