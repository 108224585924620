import howler from 'howler';

import sound1 from '../../assets/audio/notification_sound.mp3';

const sound = new howler.Howl({
  src: [sound1]
});

export const playSound = () => {
  try {
    sound.play();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('audio error ::',e);
  }
};
