import { Flex } from '../../../components/general';
import { Indicator } from '../../../components/general/Indicator/Indicator';
import { DateService } from '../../../services/date.service';
import { PaymentActionsRenderer } from '../components/Payments/PaymentActionsRenderer';
import { IStudentPayment } from '../types/studentPayments.types';
import { StudentTableBodySchema } from '../types/studentTable.types';

export const PaymentsTableHeaderSchema = {
  date: {
    title: 'Date',
    key: 'date',
  },
  details: {
    title: 'Details',
    key: 'details',
  },
  amount: {
    title: 'Amount',
    key: 'amount',
  },
  action: {
    key: 'action',
    isAction: true,
  },
};

export const getPaymentsSchema = (paymentData: IStudentPayment): StudentTableBodySchema => {
  const { date, isPaid, amount, payment } = paymentData;

  return {
    date: { renderer: () => DateService.getFullDate(date) },
    details: { renderer: () => payment.group?.name },
    amount: {
      renderer() {
        return (
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Flex alignItems="center" justifyContent="center" style={{ gap: '0 5px' }}>
              <Indicator isSuccess={Boolean(isPaid)} />
              <span>{amount}</span>
            </Flex>
          </Flex>
        );
      },
    },
    action: {
      renderer() {
        return <PaymentActionsRenderer payment={paymentData} />;
      },
    },
  };
};
