export enum EnrollmentTemplateActions {
  CHANGE_NEW_CONTENT = 'CHANGE_NEW_CONTENT',
  CHANGE_MODAL_BODY = 'CHANGE_MODAL_BODY',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export interface IEnrollmentTemplateOptions {
  enrollmentModalVisible: boolean;
  enrollmentModalTemplateBody: string;
  enrollmentModalNewContentToSend: string;
  enrollmentModalNewContentView: string;
}

export interface IEnrollmentAction {
  type: EnrollmentTemplateActions;
  data?: string | boolean;
}

export const enrollmentTemplateReducer = (
  state: any,
  action: IEnrollmentAction,
) => {
  switch (action.type) {
    case EnrollmentTemplateActions.CHANGE_NEW_CONTENT:
      if (!action?.data) {
        return {
          ...state,
          enrollmentModalNewContentView: 'Add content',
        };
      }

      return {
        ...state,
        enrollmentModalNewContentToSend: action?.data,
        enrollmentModalNewContentView: action?.data,
      };

    case EnrollmentTemplateActions.CHANGE_MODAL_BODY:
      return {
        ...state,
        enrollmentModalTemplateBody: action.data,
      };

    case EnrollmentTemplateActions.TOGGLE_MODAL:
      return {
        ...state,
        enrollmentModalVisible: !state.enrollmentModalVisible,
      };
    default:
      throw new Error('Provide action');
  }
};
