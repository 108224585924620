import { Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Button, Flex } from '../../components/general';
import { MultiselectFormik } from '../../components/general/Multiselect/Multiselect';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { downloadByLink } from '../../helpers/download.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { generateContext } from '../../helpers/generateContext.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { generateCertificatesForGroup } from '../../services/certificates.service';
import { getGroupStudents } from '../../services/studentGroup.service';
import { StudentStatusEnum } from '../../types/applicant.types';

export const CertificateAutomationForm = () => {
  const { id: groupId } = useEntityId();
  const certificatesMutation = useMutation(generateCertificatesForGroup);

  const students = useQuery(UseQueryTypes.STUDENTS,() => getGroupStudents(groupId, StudentStatusEnum.GRADUATED));
  const studentsContext = useMemo(() => {
    return generateContext(students.data || [], (student) => ({
      label: student.name,
      value: student.id,
    }));
  }, [students.data]);

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: any },
  ) => {
    const mutationFn = () =>
      certificatesMutation.mutateAsync({ groupId, studentIds: values.studentIds });

    await withCatch(mutationFn, {
      onSuccess: ({ url }) => {
        downloadByLink({ link: url });
        resetForm();
      },
    });
  };

  const formInitialValues = {
    studentIds: []
  }

  return(
    <Spin spinning={certificatesMutation.isLoading}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={formInitialValues}
        enableReinitialize
      >
        {({  handleReset, dirty, isValid }) => {
          return (
            <Form
              name="filters-1"
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Field label="Students" name="studentIds" component={MultiselectFormik} options={studentsContext} />
              <Flex
                style={{ marginTop: 'auto' }}
                justifyContent="space-between"
              >
                <Button
                  htmlType="button"
                  style={{ minWidth: 160, marginRight: 8 }}
                  onClick={handleReset}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  variant="primary"
                  disabled={!(dirty && isValid)}
                >
                  Generate
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  )
};

