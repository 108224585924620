import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { useSocket } from '../../../contexts/socket.context';
import { useAuth } from '../../../contexts/useAuth.context';
import CreateGroup from '../../../forms/groupForms/CreateGroup';
import EditGroup from '../../../forms/groupForms/EditGroup';
import { playSound } from '../../../helpers/playSound/notificationSound.helpers';
import { RoutesConstants } from '../../../helpers/routes.helpers';
import { isAdmin } from '../../../helpers/user.helper';
import useWindowSize from '../../../hooks/useWindowSize.hooks';
import Account from '../../../pages/Account/Account';
import { AccountInfoPage } from '../../../pages/Account/page/AccountInfoPage';
import { CreateUserPage } from '../../../pages/Account/page/CreateUserPage';
import { EmailSettingsPage } from '../../../pages/Account/page/EmailSettingsPage';
import { PasswordChangePage } from '../../../pages/Account/page/PasswordChangePage';
import ApplicationsByCourse from '../../../pages/Applicants/pages/ApplicantByCourse';
import ApplicantDetail from '../../../pages/Applicants/pages/ApplicantDetail';
import Applications from '../../../pages/Applicants/pages/Applicants';
import { Certificates } from '../../../pages/Certificates/Certificates';
import Consultation from '../../../pages/Consultation/Consultation';
import Courses from '../../../pages/Courses/Courses';
import DashBoard from '../../../pages/Dashboard/Dashboard';
import GroupDetail from '../../../pages/Groups/pages/GroupDetail/GroupDetail';
import OngoingGroups from '../../../pages/Groups/pages/OngoingGroups';
import PlanningGroups from '../../../pages/Groups/pages/PlanningGroups';
import { StudentAppRoutes } from '../../../pages/Groups/pages/StudentsManagement/routes/StudentApp.routes';
import TerminatedGroups from '../../../pages/Groups/pages/TerminatedGroups';
import WaitingForDemoGroups from '../../../pages/Groups/pages/WaitingForDemo';
import InstructorPage from '../../../pages/InstructorPage/InstructorPage';
import Instructors from '../../../pages/Instructors/Instructors';
import { MassMailing } from '../../../pages/MassMailing/MassMailing';
import { MassSms } from '../../../pages/MassSms/MassSms';
import Reminder from '../../../pages/Reminder/Reminder';
import Tasks from '../../../pages/Tasks/Tasks';
import Teams from '../../../pages/Teams/Teams';
import Tracks from '../../../pages/Tracks/Tracks';
import { UsersMainPage } from '../../../pages/Users/UsersMainPage';
import { AbstractUser, AuthGroup } from '../../../types/auth.types';
import { SOCKET_UPDATES } from '../../../types/socket.types';
import { UserRole } from '../../../types/user.types';
import StudentByCourse from '../../user/pages/Students/pages/StudentByCourse';
import Students from '../../user/pages/Students/pages/Students';
import Home from '../pages/Home';

export default function UserAuthenticatedApp() {
  const userType = AuthGroup.USER;
  const { addSocketEvent } = useSocket();
  const { user } = useAuth() as { user: AbstractUser };
  const { width } = useWindowSize();
  const client = useQueryClient();
  const [isMobileSize, setIsMobileSize] = useState(width <= 590);

  useEffect(() => {
    setIsMobileSize(width <= 590);
  }, [width]);

  useEffect(() => {
    addSocketEvent(SOCKET_UPDATES.reminder, () => {
      client.invalidateQueries(UseQueryTypes.FILTER_REMINDER);
      client.invalidateQueries(UseQueryTypes.REMINDER);
      client.invalidateQueries(UseQueryTypes.REMINDER_COUNT);
    });

    addSocketEvent(SOCKET_UPDATES.notification, () => {
      client.invalidateQueries(UseQueryTypes.FILTER_NOTIFICATION);
      client.invalidateQueries(UseQueryTypes.NOTIFICATION);
      client.invalidateQueries(UseQueryTypes.NOTIFICATION_COUNT);
      playSound();
    });
  }, []);

  return (
    <Routes>
      <Route path={RoutesConstants.home()} element={<Home />}>
        <Route path={RoutesConstants.dashboard()} element={<DashBoard />} />
        <Route path={RoutesConstants.massMailing()} element={<MassMailing />} />

        <Route path={RoutesConstants.massSms()} element={<MassSms />} />

        <Route path={RoutesConstants.account()} element={<Account RoutesConstants={RoutesConstants} />}>
          <Route
            path={RoutesConstants.accountInfo()}
            element={<AccountInfoPage userType={userType} isMobileSize={isMobileSize} />}
          />
          {(user?.roles || [])?.includes(UserRole.ROLE_ADMIN) && (
            <Route
              path={RoutesConstants.accountCreateUser()}
              element={<CreateUserPage isMobileSize={isMobileSize} />}
            />
          )}
          <Route
            path={RoutesConstants.accountPassword()}
            element={<PasswordChangePage userType={userType} isMobileSize={isMobileSize} />}
          />

          <Route path={RoutesConstants.accountSettings()} element={<EmailSettingsPage isMobileSize={isMobileSize} />} />

          <Route path={RoutesConstants.fallback()} element={<Navigate replace to={RoutesConstants.accountInfo()} />} />
        </Route>

        <Route path={RoutesConstants._groups()} element={<Outlet />}>
          <Route path={RoutesConstants.createGroup()} element={<CreateGroup />} />
          <Route path={RoutesConstants.waitingForDemo()} element={<WaitingForDemoGroups />} />
          <Route path={RoutesConstants.ongoingGroups()} element={<OngoingGroups />} />
          <Route path={RoutesConstants.planningGroups()} element={<PlanningGroups />} />
          <Route path={RoutesConstants.terminatedGroups()} element={<TerminatedGroups />} />
          <Route path={RoutesConstants.groupPage(':id')} element={<GroupDetail />} />
          <Route path={RoutesConstants.editGroup(':id')} element={<EditGroup />} />

          <Route path={`${RoutesConstants.groupStudents(':id')}/*`} element={<StudentAppRoutes />} />
        </Route>

        <Route path={RoutesConstants.courses()} element={<Courses />} />
        <Route path={RoutesConstants.applicants()} element={<Outlet />}>
          <Route path={RoutesConstants.applicants()} element={<Applications />} />
          <Route path={RoutesConstants.applicantsByCourse()} element={<ApplicationsByCourse />} />
        </Route>
        <Route path={RoutesConstants.applicantPage(':id')} element={<ApplicantDetail />} />
        {isAdmin(user) && <Route path={RoutesConstants.students()} element={<Students />} />}
        {isAdmin(user) && <Route path={RoutesConstants.studentsByCourse()} element={<StudentByCourse />} />}
        <Route path={RoutesConstants.consultations()} element={<Consultation />} />
        <Route path={RoutesConstants.instructors()} element={<Outlet />}>
          <Route path={RoutesConstants.instructors()} element={<Instructors />} />
          <Route path={RoutesConstants.instructor(':id')} element={<InstructorPage />} />
        </Route>
        <Route path={RoutesConstants.tracks()} element={<Tracks />} />
        <Route path={RoutesConstants.tasks()} element={<Tasks />} />
        <Route path={RoutesConstants.reminder()} element={<Reminder />} />
        <Route path={RoutesConstants.massMailing()} element={<MassMailing />} />
        <Route path={RoutesConstants.massSms()} element={<MassSms />} />
        <Route path={RoutesConstants.certificates()} element={<Certificates />} />
        {isAdmin(user) && <Route path={RoutesConstants.users()} element={<UsersMainPage />} />}
        {isAdmin(user) && <Route path={RoutesConstants.teams()} element={<Teams />} />}
        <Route path={RoutesConstants.fallback()} element={<Navigate replace to={RoutesConstants.dashboard()} />} />
      </Route>
    </Routes>
  );
}
