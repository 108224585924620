import { Currency } from './currency.types';

export enum SalaryTypeEnum {
  PER_COURSE = 'PER_COURSE',
  MONTHLY = 'MONTHLY',
  HOURLY = 'HOURLY',
}

export type SalaryType = keyof typeof SalaryTypeEnum;

export interface ISalary {
  amount: number;
  currency: Currency;
  salaryType: SalaryType;
}
