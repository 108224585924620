import { ExamProcessType } from './exam.types';
import { ITuitionFee } from './fee.types';
import { FilterOptions, FilterResponse } from './general.types';
import { ITrack } from './track.types';
import { IUser } from './user.types';

export enum CourseTimeScaleEnum {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export type CourseTimeScale = keyof typeof CourseTimeScaleEnum;
export type CourseLevelNumbers = 0 | 1 | 2;
export enum CourseLevelEnum {
  LEVEL_0 = 0,
  LEVEL_1 = 1,
  LEVEL_2 = 2,
}

export type CourseDurationModel = {
  timeScale: CourseTimeScale;
  durationInDay?: number;
  value: number;
};

export interface ICourse {
  defaultDuration: CourseDurationModel;
  defaultProjectCoordinator: IUser;
  defaultTuitionFee: ITuitionFee;
  expectedNumberOfApplicants: number;
  id: number;
  level: CourseLevelNumbers;
  name: string;
  track: ITrack;
  uuid: string;
  courseCategory: string;
  branch: string;
}

export interface ICourseLevelsCount {
  count: number;
  level: CourseLevelNumbers;
}

export interface ICreateCourse {
  defaultDuration: CourseDurationModel;
  defaultProjectCoordinatorId: number;
  defaultTuitionFee: ITuitionFee;
  expectedNumberOfApplicants: number;
  level: CourseLevelNumbers;
  name: string;
  trackId: number;
  emailTemplate: string;
  examProcessType: ExamProcessType;
}

export interface IUpdateCourse extends ICreateCourse {}

export enum CourseFormatEnum {
  COMBINED = 'COMBINED',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum CourseCategoryEnum {
  REGULAR = 'REGULAR',
  BOOTCAMP = 'BOOTCAMP',
}

export type CourseFormat = keyof typeof CourseFormatEnum;
export interface CourseFilterResponse extends FilterResponse {
  courses: ICourse[];
}

export interface CourseFilterModel {
  courseName: string;
  defaultProjectCoordinatorIds: number[];
  levels: CourseLevelNumbers[];
  tracks: number[];
}
export type CourseFilterOptions = CourseFilterModel & FilterOptions;
