import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { InstructorRoutesConstants, RoutesConstants } from '../../helpers/routes.helpers';
import useWindowSize from '../../hooks/useWindowSize.hooks';
import { StudentAppRoutes } from '../../modules/shared/pages/StudentsManagement/routes/StudentApp.routes';
import Dashboard from '../../modules/tutor/pages/Dashboard';
import GroupDetail from '../../modules/tutor/pages/Groups/GroupDetail';
import OngoingGroups from '../../modules/tutor/pages/Groups/OngoingGroups';
import PlanningGroups from '../../modules/tutor/pages/Groups/PlanningGroups';
import TerminatedGroups from '../../modules/tutor/pages/Groups/TerminatedGroups';
import Home from '../../modules/tutor/pages/Home';
import Account from '../../pages/Account/Account';
import { AccountInfoPage } from '../../pages/Account/page/AccountInfoPage';
import { PasswordChangePage } from '../../pages/Account/page/PasswordChangePage';
import { AuthGroup } from '../../types/auth.types';

const InstructorRoutes = () => {
  const userType = AuthGroup.INSTRUCTOR;

  const { width } = useWindowSize();

  const [isMobileSize, setIsMobileSize] = useState(width <= 590);

  useEffect(() => {
    setIsMobileSize(width <= 590);
  }, [width]);

  return (
    <Routes>
      <Route path={RoutesConstants.home()} element={<Home />}>
        <Route path={InstructorRoutesConstants.dashboard()} element={<Dashboard />} />
        <Route path={InstructorRoutesConstants.ongoingGroups()} element={<OngoingGroups />} />
        <Route path={InstructorRoutesConstants.planningGroups()} element={<PlanningGroups />} />
        <Route path={InstructorRoutesConstants.terminatedGroups()} element={<TerminatedGroups />} />
        <Route
          path={InstructorRoutesConstants.account()}
          element={<Account RoutesConstants={InstructorRoutesConstants} />}
        >
          <Route
            path={InstructorRoutesConstants.accountInfo()}
            element={<AccountInfoPage userType={userType} isMobileSize={isMobileSize} />}
          />
          <Route
            path={InstructorRoutesConstants.accountPassword()}
            element={<PasswordChangePage userType={userType} isMobileSize={isMobileSize} />}
          />

          <Route path="*" element={<Navigate replace to={InstructorRoutesConstants.accountInfo()} />} />
        </Route>
        {/*<Route path={InstructorRoutesConstants.groups()} element={<Outlet />}>*/}
        <Route path={InstructorRoutesConstants.groupPage(':id')} element={<GroupDetail />} />
        <Route path={`${RoutesConstants.groupStudents(':id')}/*`} element={<StudentAppRoutes />} />
        {/*  </Route>*/}
        <Route
          path={RoutesConstants.fallback()}
          element={<Navigate replace to={InstructorRoutesConstants.dashboard()} />}
        />
      </Route>
    </Routes>
  );
};

export default InstructorRoutes;
