import { FC, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

import { Button, Flex } from '..';
import styles from './signature.module.css';

interface ISignature {
  onSave: (data: string) => void;
  label?: string;
  onReset?: () => void;
  buttons?: { reset: { text: string }, save: { text: string } }
}


export const Signature: FC<ISignature> = ({ onSave, label, onReset, buttons = {} }) => {
  const pad = useRef<ReactSignatureCanvas>(null);

  const handleReset = () => {
    pad.current?.clear();
    if (onReset)
      onReset()
  };

  const handleSubmit = () => {
    const data = pad.current?.toDataURL();

    if (!data || pad.current?.isEmpty()) return;
    onSave(data);
  };

  return (
    <div style={{ margin: '15px 0 0 0' }}>
      {label && (
        <Flex justifyContent="flex-start">
          <label style={{ margin: 'auto 0' }}>{label}</label>
        </Flex>
      )}
      <Flex justifyContent="center" className={styles.wrapper}>
        <ReactSignatureCanvas
          ref={pad}
          maxWidth={2}
          penColor="black"
          canvasProps={{
            style: {
              maxWidth: '560px',
              width: '100%',
              height: '240px',
              backgroundColor: 'white',
              border: '1px solid rgba(219, 224, 231, 0.7)',
              borderRadius: '10px'
            },
          }}
        />
      </Flex>

      <Flex justifyContent="space-around" style={{ margin: '10px auto 0 auto', gap: '0 8px', maxWidth: '560px' }}>
        <Button onClick={handleReset} htmlType="button">
          {buttons.reset?.text || 'Delete'}
        </Button>
        <Button style={{background:'rgba(219, 224, 231, 0.7)'}} onClick={handleSubmit}  htmlType="button">
          {buttons.save?.text || 'Save'}
        </Button>
      </Flex>
    </div>
  );
};
