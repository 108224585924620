import { Spin } from "antd";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";

import { useAuth } from "../../../../contexts/useAuth.context";
import { getStudentAbsentees } from "../../../../services/student.service";
import { StudentQueryConstants } from "../../constants/studentQuery.constants";
import { studentAbsenteesTableAdapter } from "../../helpers/student.helpers";
import { AbsenteesTableHeaderSchema, getAbsenteesSchema } from "../../tableSchemas/GetAbsenteesSchema";
import { IStudentAbsenteesResponse, IStudentAbsenteesTableData } from "../../types/studentAbsentees.types";
import EmptyDataComponent from "../EmptyData/EmptyDataComponent";
import { StudentTable } from "../Table/StudentTable";


function AbsenteesRenderer() {
  const { user } = useAuth()

  const studentAbsenteesQuery = useQuery(
    [StudentQueryConstants.STUDENT_ABSENTEES],
    () =>
      getStudentAbsentees(user?.id as number),
    { enabled: Boolean(user) }
  );


  const studentAbsentees: IStudentAbsenteesResponse | undefined = studentAbsenteesQuery.data;
  const studentAbsenteesNormalized = studentAbsenteesTableAdapter(studentAbsentees)

  
  return (
    <Spin spinning={studentAbsenteesQuery.isLoading}>
      {!isEmpty(studentAbsentees) ?
        <StudentTable<IStudentAbsenteesTableData>
          renderSchema={getAbsenteesSchema}
          tableHeaderSchema={AbsenteesTableHeaderSchema}
          dataSource={studentAbsenteesNormalized}/>
        : <EmptyDataComponent dataTitle='absentees'/>}
    </Spin>
  );
}

export default AbsenteesRenderer;
