import { IStudentExams } from './applicant.types';

export enum ExamTypeEnum {
  FINAL = 'FINAL',
  MIDTERM = 'MIDTERM',
}

export enum ExamStatus {}

export type ExamType = keyof typeof ExamTypeEnum;

export interface IUpdateExamGrades {
  id: number;
  grade: number;
}

export interface IExam {
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  date: string;
  examType: ExamType;
  groupId: number;
  maxScore: number;
  minScore: number;
  id: number;
  title: string;
}

export enum AutomatedExamState {
  PENDING = 'PENDING',
  DONE = 'DONE',
}

export interface IAutomatedExam {
  id: number;
  testId: string;
  maxScore: number;
  score: number;
  source: ExamSource;
  state: AutomatedExamState;
  resultMailSent: boolean;
}

export interface IExamColumn {
  name: string;
  phoneNumber: string;
  email: string;
  applicantExams: IStudentExams[];
  examDate: string;
  maxScore: number;
  minScore: number;
  grade: number;
  id: number;
}

export enum ExamColumnNames {
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  ExamDate = 'examDate',
  MaxScore = 'maxScore',
  Grade = 'grade',
  Id = 'id',
}

export type ICreateExam = Omit<IExam, 'id'>;

export enum IExamGradingType {
  IN_PROCESS = 'secondary',
  SUCCESS = 'success',
  FAIL = 'danger',
}

export interface IGradingType {
  text: string;
  type: IExamGradingType;
}

export enum ExamProcessType {
  AUTOMATED = 'AUTOMATED',
  MANUAL = 'MANUAL',
}

export enum ExamSource {
  CODE_SIGNAL = 'CODE_SIGNAL',
  TEST_PORTAL = 'TEST_PORTAL',
}
