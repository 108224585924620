import React from 'react';

import { IButton } from '../../../types/components/general.types';
import { Icons } from '../index';
import styles from './AddButton.module.css'

const AddButton: React.FC<IButton> = ({
  htmlType,
  handleMouseEnter,
  handleMouseLeave,
  children,
  className=styles.btn,
  style,
  onClick,
  disabled = false,
}) => {
  return (
    <div className={styles.wrapper}>
      <Icons.Add width={11} height={11} color='blue'/>
      <button
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={style}
        type={htmlType}
        className={className}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  )
}

export default AddButton;
