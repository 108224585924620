export const DateFnsFormats = {
  full: 'dd MMM, yyyy',
  server: 'yyyy-MM-dd',
  time: 'HH:mm',
  week: 'EEEE',
  fullDate: "MMM dd, yyyy 'at' HH:mm",
  month: 'MMM dd',
  normal: 'dd.MM.yyyy',
} as const;

export const DateRegEx = /(T.+)/g;
