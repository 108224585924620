import { Modal, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Flex, Icons } from '../../components/general';
import { Calendar, Clock, Edit } from '../../components/general/Icons/Icons';
import Ticker from '../../components/general/Ticker /Ticker';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { deleteReminderById } from '../../services/reminder.service';
import { RenderColumn } from '../../types/column.types';
import { IReminder } from '../../types/reminder.types';

export const DynamicReminderRenderer =
  (title: keyof IReminder): RenderColumn<IReminder> =>
    (_, reminderData) =>
      <Text>{reminderData[title]}</Text>;

export const ReminderDate: RenderColumn<IReminder> = (_, reminderData) => {
  const reminderDateObject = new Date(reminderData.remindingDate);
  const reminderDate = format(reminderDateObject, 'dd.MM.yyyy');
  const reminderTime = format(reminderDateObject, 'kk:mm');

  return (
    <Flex alignItems="center">
      <Flex alignItems="center">
        <Calendar color={'#86898D'}/>
        <span style={{ margin: '0 10px' }}> {reminderDate} </span>
      </Flex>

      <Flex alignItems="center">
        <Clock/>
        <span style={{ margin: '0 10px' }}>{reminderTime}</span>
      </Flex>
    </Flex>
  );
};

export const DescriptionReminderColumn: RenderColumn<IReminder> = (_, reminder) =>
  <Ticker text={reminder.description}/>

export const DeleteReminderColumn: RenderColumn<IReminder> = (_, reminder) => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const cache = useQueryClient();
  const deleteReminderMutation = useMutation(deleteReminderById);

  const handleToggle = () => {
    setIsOpenModal((state) => !state);
  };

  const handleDeleteReminder = (id: number) => {
    return async () => {
      await withCatch(() => deleteReminderMutation.mutateAsync(id), {
        onSuccess: () => cache.invalidateQueries(UseQueryTypes.REMINDER),
      });

      await handleToggle();
    };
  };

  return (
    <Spin spinning={deleteReminderMutation.isLoading}>
      <Icons.Delete onClick={handleToggle} style={{ cursor: 'pointer' }}/>
      <Modal
        visible={isModalOpen}
        onOk={handleDeleteReminder(reminder.id)}
        okText={'Yes'}
        okType={'danger'}
        onCancel={handleToggle}
        centered
        width={600}
      >
        <Text>Permanently Delete Reminder?</Text>
      </Modal>
    </Spin>
  );
};

export const EditReminderColumn: RenderColumn<IReminder> = (_, reminder) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenEditApplicantForm = () => {
    changeId(reminder.id);
    openDrawer(AppFormsEnum.EditReminderForm, `Edit Reminder`);
  };

  return (
    <Edit onClick={handleOpenEditApplicantForm} style={{ cursor: 'pointer' }}/>
  );
};


