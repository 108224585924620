import { Table } from 'antd';

import { useTable } from '../../../hooks/table/useTable';
import { useRowSelectionAdapter } from '../../../hooks/useRowSelection.adapter';
import EmptyDataComponent from '../../../modules/student/components/EmptyData/EmptyDataComponent';
import { defaultTeamMembersColumns } from '../../../schemas/team/teamMembers.schema';
import { IMember } from '../../../types/user.types';
import teamStyles from '../Teams.module.css'

export const MembersTable = ({ members }: { members: IMember[] }) => {
  const userTable = useTable(defaultTeamMembersColumns);
  const rowSelectionAdapter = useRowSelectionAdapter<IMember>();

  return (
  <>
    {
      !!members?.length ?
        <Table
          className={teamStyles.overWriteTable}
          {...userTable.getTableProps({
            rowKey: (user) => user.id,
            dataSource: members,
            rowSelection: rowSelectionAdapter.getProps(),
          })}
        /> :
        <EmptyDataComponent dataTitle="team members" hasDescription={true} />
    }
  </>
  )
}

