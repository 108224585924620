import React from "react";

import styles from './Indicator.module.css'

export const Indicator = ({
  handleClick,
  isSuccess,
  disabled
}: { handleClick?: () => void, isSuccess: boolean, disabled?: boolean }) => {
  const indicatorStyles = isSuccess ? styles.indicatorSuccess : styles.indicatorNegative
  const isDisabled = disabled ?? isSuccess


  return (
    <button
      onClick={handleClick}
      className={indicatorStyles}
      disabled={isDisabled}
    ></button>
  )
}
