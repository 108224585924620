import { useState } from 'react';

import { AccountInfoEdit } from './AccountEdit';
import { AccountView } from './AccountView';

type Props = {
  userType: string;
  isMobileSize: boolean;
};

export const AccountInfoPage = ({ userType, isMobileSize }: Props) => {
  const [editView, setEditView] = useState(false);

  return (
    <>
      {editView ? (
        <AccountInfoEdit userType={userType} setEditView={setEditView} isMobileSize={isMobileSize} />
      ) : (
        <AccountView setEditView={setEditView} isMobileSize={isMobileSize} />
      )}
    </>
  );
};
