import React from "react";

import { ForgotPasswordResponseMassages } from '../../../constants/responseMassages';
import styles from "../ForrgetPassword.module.css";

export const ForgetPasswordSuccessMassage = () => {
  return (
    <>
      <h2 className={styles.title}>Password reset email sent</h2>
      <p className={styles.subtitle}>
        { ForgotPasswordResponseMassages.SUCCESS }
      </p>
    </>
  );
};
