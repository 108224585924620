import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from '../Icons/Icons';
import styles from './Search.module.css';

interface ISearchProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  style?: object;
  defaultValue: string | undefined;
}

export default function Search({
  onChange,
  placeholder,
  style,
  defaultValue = '',
}: ISearchProps) {
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    defaultValue && setSearch(defaultValue);
    !window.location.search && setSearch('');
  }, [defaultValue]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setSearch(e.target.value);
  }

  return (
    <div style={style} className={styles.searchWrapper}>
      <SearchIcon className={styles.icon} />
      <input
        className={styles.search}
        placeholder={placeholder}
        onChange={handleSearch}
        value={search}
      />
    </div>
  );
}
