import { resourceResponseToAdapter } from '../helpers/common.helpers';
import { stringifyObject } from '../helpers/stringifyObj.helper';
import { Sorters } from '../types/general.types';
import {
  ICreateOrUpdateNotification,
  INotification,
  INotificationFilter,
  INotificationsResponse,
} from '../types/notification.types';
import axios from './config.service';

export function createNotification(data: ICreateOrUpdateNotification) {
  return axios.post('/notification', data).then((r) => r.data) as Promise<INotification[]>;
}

export function filterNotifications({
  filters,
  sorters = {},
}: {
  filters: INotificationFilter;
  sorters: Partial<Sorters>;
}) {
  const queryString = stringifyObject(sorters);

  return axios
    .post(`/notification/filter?${queryString}`, filters)
    .then((r) => r.data) as Promise<INotificationsResponse>;
}

export function deleteNotificationById(id: number) {
  return axios.delete(`/notification/${id}`).then((r) => r.data) as Promise<boolean>;
}

export function updateNotification(data: Partial<INotification>) {
  const notificationWithoutId = resourceResponseToAdapter(data);

  return axios.put(`/notification/${data.id}`, notificationWithoutId).then((r) => r.data) as Promise<INotification[]>;
}

export async function bulkUpdateNotificationStatus(notifications: Partial<INotification>[]) {
  const r = await axios.put(`/notification/bulk`, notifications);
  return r.data as Promise<INotification[]>;
}

export function getNotificationCount(data: any) {
  return axios.post(`/notification/count`, data).then((r) => r.data) as Promise<INotification[]>;
}
