import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, CustomDatePickerFormik, Flex, Input } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { createApplicantPayment } from '../../services/payment.service';
import { IAppForm } from '../../types/form.types';
import { ICreateApplicantPayment, ICreatePayment } from '../../types/payment.types';
import { CreateApplicantPaymentFormSchema } from './schemas/createApplicantPaymentForm.schema';

export const CreateApplicantPaymentForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: applicantId } = useEntityId();
  const cache = useQueryClient();
  const applicantPayment = useMutation(createApplicantPayment, {
    onSuccess: () => {
      cache.invalidateQueries(UseQueryTypes.GROUP_PAYMENT);
      cache.invalidateQueries(UseQueryTypes.APPLICANT_PAYMENT);
    },
  });

  const handleCreatePayment = async (newPayment: Partial<ICreateApplicantPayment>) => {
    newPayment.applicantId = applicantId;
    const mutationFunc = () => applicantPayment.mutateAsync(newPayment as ICreatePayment);

    await withCatch(mutationFunc, {
      onSuccess: () => {
        closeDrawer();
      },
    });
  };

  const isScreenLoading = applicantPayment.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik initialValues={{}} validationSchema={CreateApplicantPaymentFormSchema} onSubmit={handleCreatePayment}>
        {(props) => (
          <Form
            name="create-payment"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="date" label="Date of payment" component={CustomDatePickerFormik} required />
            <ErrorMessage name="date" component="span" className="error" />

            <Field name="amount" label="Payments amount" component={Input} required />
            <ErrorMessage name="amount" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
