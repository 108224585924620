import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { ReminderStatus, ReminderType } from '../../../types/reminder.types';

const { required } = messages;

export const CreateReminderFormSchema = Yup.object().shape({
  title: Yup.string().required(required),
  description: Yup.string().required(required),
  type: Yup.mixed<ReminderType>().oneOf(Object.values(ReminderType)).required(),
  remindingDate: Yup.string().required(required),
});

export const UpdateReminderFormSchema = Yup.object().shape({
  id: Yup.string().required(required),
  title: Yup.string().required(required),
  description: Yup.string().required(required),
  type: Yup.mixed<ReminderType>().oneOf(Object.values(ReminderType)).required(),
  remindingDate: Yup.string().required(required),
  status: Yup.string().oneOf(Object.values(ReminderStatus)).required(),
});

export const BulkUpdateReminderStatusFormSchema = Yup.object().shape({
  status: Yup.string().oneOf(Object.values(ReminderStatus)).required(),
});
