import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, CustomDatePickerFormik, Flex, Input, SingleSelectFormik } from '../../components/general';
import { EXAM_TYPE_OPTIONS } from '../../constants/exam.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { createExam } from '../../services/exam.service';
import { ICreateExam } from '../../types/exam.types';
import { IAppForm } from '../../types/form.types';
import { CreateExamFormSchema } from './schemas/createExam.forms.schema';

export const CreateExamForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: groupId } = useEntityId();
  const cache = useQueryClient();
  const createExamMutation = useMutation(createExam, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.EXAMS),
  });
  const handleCreateExam = async (newExam: Partial<ICreateExam>) => {
    newExam.groupId = groupId;
    const mutationFunc = () => createExamMutation.mutateAsync(newExam as ICreateExam);

    withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.EXAMS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={createExamMutation.isLoading}>
      <Formik initialValues={{}} onSubmit={handleCreateExam} validationSchema={CreateExamFormSchema}>
        {(props) => (
          <Form
            name="create-exam"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="title" label="Exam Name" component={Input} />
            <ErrorMessage name="title" component="span" className="error" />

            <Field name="date" label="Date of exam" component={CustomDatePickerFormik} />
            <ErrorMessage name="date" component="span" className="error" />

            <Field name="examType" label="Exam type" options={EXAM_TYPE_OPTIONS} component={SingleSelectFormik} />
            <ErrorMessage name="examType" component="span" className="error" />

            <Field name="minScore" label="Min Score" type="number" component={Input} min={0} />
            <ErrorMessage name="minScore" component="span" className="error" />

            <Field name="maxScore" label="Max Score" type="number" component={Input} min={0} />
            <ErrorMessage name="maxScore" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
