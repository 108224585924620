import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { Loading } from '../../../../components/general/Loading/Loading';
import { withCatch } from "../../../../helpers/error.helpers";
import { saveState } from "../../../../helpers/localStorage.helpers";
import { checkResetPasswordToken } from "../../../../services/auth.service";
import { AuthGroup, ResetPasswordQueryParams } from "../../../../types/auth.types";
import { ResetPasswordViewsEnum } from '../../types/forgetPasswordForm.types';
import styles from '../ForgetPassword/ForrgetPassword.module.css';
import Expired from './components/Expired';
import ResetPasswordForm from './components/ResetPassword.form';
import ResetPasswordSuccess from "./components/ResetPasswordSuccess";

const ResetPassword = ({ authGroup }: { authGroup: AuthGroup } = { authGroup: AuthGroup.USER }) => {
  const { token = '', authGroup: auth_group = '' } = useParams<ResetPasswordQueryParams>();
  let _authGroup = authGroup;

  if (!_authGroup && auth_group) {
    saveState('authGroup', auth_group);
    _authGroup = auth_group
  }

  const [activeView, setActiveView] = useState<Partial<ResetPasswordViewsEnum>>(ResetPasswordViewsEnum.LOADING);

  const changeActiveView = useCallback(setActiveView, []);

  const checkResetPasswordTokenMutation = useMutation(checkResetPasswordToken);

  const checkResetPasswordTokenHandler = async () => {
    const mutationFunc = () => checkResetPasswordTokenMutation.mutateAsync(token);

    await withCatch(mutationFunc, {
      onSuccess: () => changeActiveView(ResetPasswordViewsEnum.RESET_PASSWORD),
      onError: () => changeActiveView(ResetPasswordViewsEnum.RESET_PASSWORD_EXPIRED),
      hideMessage: true
    });
  };

  const views = {
    [ResetPasswordViewsEnum.LOADING]: <Loading/>,
    [ResetPasswordViewsEnum.RESET_PASSWORD]: <ResetPasswordForm authGroup={_authGroup} changeView={changeActiveView}/>,
    [ResetPasswordViewsEnum.RESET_PASSWORD_SUCCESS]: <ResetPasswordSuccess/>,
    [ResetPasswordViewsEnum.RESET_PASSWORD_EXPIRED]: <Expired/>
  };

  const init = () => {
    checkResetPasswordTokenHandler()
  }

  useEffect(init, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        {views[activeView]}
      </div>
    </div>
  );
};

export default ResetPassword;
