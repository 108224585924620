import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import { ColumnModal } from '../../../../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../../../../components/display/ResultSummary/ResultSummary';
import { Button, FilterButton, Flex, Search } from '../../../../../components/general';
import { ChangeView } from '../../../../../components/general/Icons/Icons';
import { COMMA_SEPARATOR } from '../../../../../constants/constants';
import { PageSizeOptions } from '../../../../../constants/sorters.constants';
import { UseQueryTypes } from '../../../../../constants/useQuery.constants';
import { useDrawer } from '../../../../../contexts/drawer.context';
import { AppFormsEnum } from '../../../../../forms';
import { exportSuccessHelper } from '../../../../../helpers/applicants/exportSuccess.helper';
import { withCatch } from '../../../../../helpers/error.helpers';
import { RoutesConstants } from '../../../../../helpers/routes.helpers';
import { useTable } from '../../../../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../../../../hooks/useColumnModal.adapter';
import { useFilter } from '../../../../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../../../../hooks/useRowSelection.adapter';
import { useSearchHandler } from '../../../../../hooks/useSearchHandler.hooks';
import { defaultStudentsColumns } from '../../../../../schemas/applicants/applicant.schema';
import { exportApplicants } from '../../../../../services/applicant.service';
import { PhoneService } from '../../../../../services/phone.service';
import { useAppDispatch } from '../../../../../store';
import { mailingActions } from '../../../../../store/features/mailing/mailingSlice';
import { smsActions } from '../../../../../store/features/sms/smsSlice';
import applicantStatusStyles from '../../../../../styles/applicantStatus.module.css';
import { FilterApplicantOptions, IStudentBrief } from '../../../../../types/applicant.types';
import { SortDirectionEnum } from '../../../../../types/general.types';
import { SendMail } from '../../../../../types/mail.types';
import { SendSms } from '../../../../../types/sms.types';
import { filterStudent } from '../../../services/userStudent.service';
import styles from './students.module.css';

const defaultFilterApplicantOptions: Partial<FilterApplicantOptions> = {
  sortBy: 'created_at',
  sortDirection: SortDirectionEnum.DESC,
  pageSize: PageSizeOptions.defaultPageSize,
};

export default function Students() {
  const [isColored, setIsColored] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cache = useQueryClient();
  const studentTable = useTable<IStudentBrief>(defaultStudentsColumns);
  const modalAdapter = useColumnModalAdapter(studentTable.getTableColumnSettings());

  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const selectedApplicantsCount = rowSelectionAdapter.selectedRows.length;

  const { handleSearch, term } = useSearchHandler();

  const { handleSearch: handleCommentSearch, commentTerm } = useSearchHandler('secondaryTerm');
  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions, resetOptions } = useFilter(defaultFilterApplicantOptions);

  const { term: filterTerm, secondaryTerm, ...tail } = filterOptions;

  const applicants = useQuery(
    [UseQueryTypes.STUDENTS, filterOptions, sorterOptions],
    () => filterStudent(filterOptions, sorterOptions),
    {
      onSuccess: (data) => {
        cache.setQueryData(UseQueryTypes.STUDENTS, data);
      },
    },
  );

  // ToDo logic is not complete because of lack of requirements
  /* const { today, thisWeek, thisMonth } = useApplicantStatistic(getStudentCountByDateInterval, tail);*/

  const handleOpenApplicantFilterForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.FilterApplicantForm, 'Filter Student');
  };

  const handleMassMailSelectedApplicants = () => {
    const mailingFormState: Partial<SendMail> = {
      to: rowSelectionAdapter.selectedRows.map((applicant: IStudentBrief) => applicant.email).join(COMMA_SEPARATOR),
    };

    dispatch(mailingActions.setInitialValues(mailingFormState));
    navigate(RoutesConstants.massMailing());
  };

  const handleMassSmsSelectedApplicants = () => {
    const smsFormState: Partial<SendSms> = {
      to: rowSelectionAdapter.selectedRows
        .map((applicant: IStudentBrief) => PhoneService.normalizeForNikita(applicant.phoneNumber))
        .join(COMMA_SEPARATOR),
    };

    dispatch(smsActions.setInitialValues(smsFormState));
    navigate(RoutesConstants.massSms());
  };

  const exportApplicantMutation = useMutation(exportApplicants);

  const handleExportSelectedApplicants = async () => {
    const ids: number[] = rowSelectionAdapter.selectedRows.map((applicant: IStudentBrief) => applicant.id);
    const mutationFunc = () => {
      return exportApplicantMutation.mutateAsync(ids);
    };

    await withCatch(mutationFunc, {
      onSuccess: async (data) => {
        exportSuccessHelper(data);
      },
    });
  };

  const handleIsColoredStatus = () => {
    setIsColored((prev) => !prev);
  };

  const applicantsTotalSize = applicants.data?.totalElements ?? 0;
  const isScreenLoading = applicants.isLoading;

  const handleReset1 = () => {
    resetOptions();
  };

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={22}>
            <Col span={24}>
              <Row gutter={[8, 8]} align="middle" wrap={false}>
                <Search
                  style={{}}
                  placeholder="Search"
                  defaultValue={term}
                  onChange={(e) => handleSearch(e, filterOptions)}
                />
                <Flex alignItems="center" style={{ gap: '0 10px' }}>
                  <FilterButton onClick={handleOpenApplicantFilterForm} />
                  <Button onClick={handleReset1}>Reset Filters</Button>
                </Flex>
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Row style={{ gap: '8px' }}>
                <Search
                  style={{}}
                  placeholder="Search With Comment"
                  defaultValue={commentTerm}
                  onChange={(e) => handleCommentSearch(e, filterOptions)}
                />
                <Button style={{ maxWidth: '200px' }} onClick={() => navigate(RoutesConstants.studentsByCourse())}>
                  View students by Course
                </Button>
              </Row>
              {/* <StudentsStatistics today={today ?? 0} thisWeek={thisWeek ?? 0} thisMonth={thisMonth ?? 0} />*/}
            </Col>
            <Divider style={{ margin: '16px 0px' }} />
          </Col>

          <Col span={24} offset={1} style={{ margin: '0' }}>
            <Row gutter={[0, 16]} justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
              <Col sm={4} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <ResultSummary text={`${applicantsTotalSize} result found`} />
              </Col>
              <Col sm={20} xs={24}>
                <Row justify="end" style={{ gap: '8px' }}>
                  <Button onClick={handleIsColoredStatus} className={styles.button} variant="primary">
                    {isColored ? 'Disable' : 'Enable'} Status Color
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row justify="end" style={{ width: 'inherit' }}>
              {rowSelectionAdapter.isSelected && (
                <>
                  <Col style={{ marginRight: '8px' }}>
                    <Button variant="primary" onClick={handleExportSelectedApplicants}>
                      Export
                    </Button>
                  </Col>
                  <Col style={{ marginRight: '8px' }}>
                    <Button variant="primary" onClick={handleMassSmsSelectedApplicants}>
                      Mass Sms
                    </Button>
                  </Col>
                  <Col style={{ marginRight: '8px' }}>
                    <Button variant="primary" onClick={handleMassMailSelectedApplicants}>
                      Mass Mail
                    </Button>
                  </Col>
                </>
              )}
              <ChangeView onClick={modalAdapter.openSettings} />
            </Row>
            {selectedApplicantsCount > 0 && <ResultSummary text={`Selected ${selectedApplicantsCount} students`} />}
          </Col>

          <Col span={24}>
            <Table<IStudentBrief>
              {...studentTable.getTableProps({
                rowKey: (student) => student.id,
                rowClassName: ({ status }) =>
                  isColored ? applicantStatusStyles[status.toLowerCase()] : applicantStatusStyles.default,
                dataSource: applicants.data?.students || [],
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />
            <Pagination
              {...studentTable.getPaginationProps({
                total: applicantsTotalSize,
                style: { marginTop: 20 },
                defaultPageSize: PageSizeOptions.defaultPageSize,
              })}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
