export const PageSizeOptions = {
  defaultPageSize: 50,
};

export const sortersDefaultOptions = {
  pageNumber: 0,
  pageSize: PageSizeOptions.defaultPageSize,
  sortBy: 'created_at',
};

export const MAX_SAFE_PAGE_SIZE = 2 ** 20;
