import classNames from 'classnames';
import React, { useRef } from 'react';

import { useOutsideClickForDrawer } from '../../../hooks/useOutsideClick.hooks';
import styles from './DrawerContainer.module.css';

export enum Anchor {
  LEFT = 'left',
  RIGHT = 'right',
}

interface IDrawerContainerProps {
  children: JSX.Element;
  open: boolean;
  anchor: Anchor;
  onClose: () => void;
  hasOutSideClick: boolean;
}

export default function DrawerContainer({
  children,
  open = false,
  anchor,
  onClose,
  hasOutSideClick = true,
}: IDrawerContainerProps) {
  const drawerRef = useRef<HTMLDivElement>(null);

  const drawerContainerChildrenWrapper = classNames({
    drawerContainerChildrenWrapperFromRight: anchor === Anchor.RIGHT,
    drawerContainerChildrenWrapperFromLeft: anchor === Anchor.LEFT,
  });

  useOutsideClickForDrawer(drawerRef, onClose, anchor, hasOutSideClick);

  if (open)
    return (
      <div className={styles.drawerContainerWrapper}>
        <div ref={drawerRef} className={styles[drawerContainerChildrenWrapper]}>
          {children}
        </div>
      </div>
    );

  return null;
}
