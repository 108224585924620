import { Col, Row } from 'antd';
import React  from 'react';

import { IButton } from '../../../types/components/general.types';
import { Button } from '../index';

export type IActive = { active: boolean };
type ISwitchButtonSide = IButton & IActive;

interface ISwitchDoubleButtonProps {
  leftSIde: ISwitchButtonSide,
  rightSide: ISwitchButtonSide,
}

const SwitchDoubleButton: React.FC<ISwitchDoubleButtonProps> = ({ leftSIde, rightSide}) => {
  return (
      <Row style={{ width: 'max-content' }}>
        <Col style={{ marginRight: 10 }}>
          <Button
            onClick={leftSIde.onClick}
            variant={leftSIde.active ? 'primary' : 'default'}
            className={leftSIde.className}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              width: '80px',
              height: '100%',
              ...leftSIde.style
            }}
          >
            { leftSIde.title }
          </Button>
        </Col>
        <Col style={{ marginRight: 16 }}>
          <Button
            onClick={rightSide.onClick}
            variant={rightSide.active ? 'primary' : 'default'}
            className={rightSide.className}
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: '80px',
              height: '100%',
              ...rightSide.style
            }}
          >
            { rightSide.title }
          </Button>
        </Col>
      </Row>
  );
};
export default SwitchDoubleButton;
