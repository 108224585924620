import 'antd/dist/antd.css';

import ScrollToTop from './components/ScrollToTop';
import { AppRoutes } from './routes/App.routes';

export default function App() {
  return (
    <ScrollToTop>
      <AppRoutes />
    </ScrollToTop>
  );
}
