export type RouteParam = number | string | undefined;

export const publicApplicantRoutes = {
  invoices: 'invoices/',
  agreements: 'agreement/',
  verification: 'verification/',
  finalProjects: 'final-projects/',
};

export const groupStudentAppRoutes = {
  absentees: () => 'absentees/',
  payments: () => 'payments/',
  grades: () => 'grades/',
  feedbacks: () => 'feedbacks/',
};

export const InstructorRoutesConstants = {
  login: () => '/login/instructor',
  fallback: () => '*',
  home: () => '/',
  account: () => '/instructor/account/',
  accountInfo: () => `${InstructorRoutesConstants.account()}personal-info/`,
  accountPassword: () => `${InstructorRoutesConstants.account()}password/`,
  groups: () => '/instructor/groups/',
  dashboard: () => '/instructor/dashboard/',
  ongoingGroups: () => `${InstructorRoutesConstants.dashboard()}ongoing/`,
  planningGroups: () => `${InstructorRoutesConstants.dashboard()}planning/`,
  terminatedGroups: () => `${InstructorRoutesConstants.dashboard()}terminated/`,
  groupPage: (id: RouteParam) => `${InstructorRoutesConstants.groups()}${id}/`,
  groupStudents: (id: RouteParam) => `${InstructorRoutesConstants.groups()}${id}/student-management/`,
};

export const StudentRoutesConstants = {
  login: () => '/login/student',
  account: () => '/student/account',
  fallback: () => '*',
  home: () => '/',
  createPassword: () => '/student/create-password/:id/:token/',
  accountInfo: () => `${StudentRoutesConstants.account()}/personal-info/`,
  accountPassword: () => `${StudentRoutesConstants.account()}/password/`,
  dashboard: () => `${StudentRoutesConstants.account()}/dashboard`,
  agreements: () => `${StudentRoutesConstants.account()}/agreements/`,
  _feedbacks: () => `${StudentRoutesConstants.account()}/feedbacks/`,
  receivedFeedbacks: () => `${StudentRoutesConstants._feedbacks()}/received/`,
  givenFeedbacks: () => `${StudentRoutesConstants._feedbacks()}/given/`,
  grades: () => `${StudentRoutesConstants.account()}/grades/`,
  absentees: () => `${StudentRoutesConstants.account()}/absentees/`,
  payments: () => `${StudentRoutesConstants.account()}/payments/`,
  progress: () => `${StudentRoutesConstants.account()}/progress/`,
  archive: () => `${StudentRoutesConstants.account()}/archive/`,
  attachments: () => `${StudentRoutesConstants.account()}/attachments/`,
  certificate: () => `${StudentRoutesConstants.account()}/certificate/`,
  cv: () => `${StudentRoutesConstants.account()}/cv/`,
};

export const RoutesConstants = {
  login: () => '/login/',
  fallback: () => '*',
  account: () => '/account/',
  accountInfo: () => `${RoutesConstants.account()}personal-info/`,
  accountPassword: () => `${RoutesConstants.account()}password/`,
  accountSettings: () => `${RoutesConstants.account()}email-settings/`,
  accountCreateUser: () => `${RoutesConstants.account()}create-user/`,
  forgetPassword: () => '/forget-password/',
  resetPassword: () => '/reset-password/:email/:token/:authGroup',
  home: () => '/',
  dashboard: () => '/dashboard',
  es: () => '/event-scheduler/',
  tracks: () => '/tracks/',
  applicants: () => '/applicants/',
  students: () => '/students/',
  studentsByCourse: () => `${RoutesConstants.students()}course/`,
  reminder: () => '/reminder',
  applicantsByCourse: () => `${RoutesConstants.applicants()}course/`,
  applicant: (name: RouteParam) => `${RoutesConstants.applicants()}?term=${name}`,
  applicantPage: (id: RouteParam) => `/student-detail/${id}/`,
  applicantFilter: (name: RouteParam, email?: RouteParam) =>
    `${RoutesConstants.applicants()}?name=${name}${email ? '&email=' + email : ''}`,
  consultations: () => '/consultations/',
  courses: () => '/courses/',
  instructors: () => '/instructors/',
  instructor: (id: RouteParam) => `/instructors/${id}/`,
  statistics: () => '/statistics/',
  documentBuilder: () => '/documentBuilder/',
  tasks: () => '/tasks/',
  _groups: () => '/groups/',
  planningGroups: () => `${RoutesConstants._groups()}planning/`,
  ongoingGroups: () => `${RoutesConstants._groups()}ongoing/`,
  waitingForDemo: () => `${RoutesConstants._groups()}waitingForDemo/`,
  createGroup: () => `${RoutesConstants._groups()}create/`,
  groupPage: (id: RouteParam) => `${RoutesConstants._groups()}${id}/`,
  editGroup: (id: RouteParam) => `${RoutesConstants._groups()}${id}/edit/`,
  terminatedGroups: () => `${RoutesConstants._groups()}terminated/`,

  groupStudents: (id: RouteParam) => `${RoutesConstants._groups()}${id}/student-management/`,
  groupPayments: (id: RouteParam) => `${RoutesConstants.groupStudents(id)}${groupStudentAppRoutes.payments()}`,
  groupFeedbacks: (id: RouteParam) => `${RoutesConstants.groupStudents(id)}${groupStudentAppRoutes.feedbacks()}`,
  groupAbsentees: (id: RouteParam) => `${RoutesConstants.groupStudents(id)}${groupStudentAppRoutes.absentees()}`,
  groupGrades: (id: RouteParam) => `${RoutesConstants.groupStudents(id)}${groupStudentAppRoutes.grades()}`,
  massMailing: () => `/services/mass-mailing/`,
  massSms: () => `/services/mass-sms/`,
  certificates: () => `/services/certificates/`,
  _public: () => `/public`,
  publicApplicant: () => `${RoutesConstants.applicants()}/:uuid`,
  publicApplicantInvoices: (uuid: RouteParam) =>
    `${RoutesConstants._public()}/${RoutesConstants.applicants()}${uuid}/${publicApplicantRoutes.invoices}`,
  publicApplicantAgreement: (uuid: RouteParam) =>
    `${RoutesConstants._public()}/${RoutesConstants.applicants()}${uuid}/${publicApplicantRoutes.agreements}`,
  publicApplicantVerification: (uuid: RouteParam) =>
    `${RoutesConstants._public()}/${RoutesConstants.applicants()}${uuid}/${publicApplicantRoutes.verification}`,
  publicApplicantFinalProjects: (uuid: RouteParam) =>
    `${RoutesConstants._public()}/${RoutesConstants.applicants()}${uuid}/${publicApplicantRoutes.finalProjects}`,
  publicApplicantNotFound: () => 'not-found/',
  users: () => '/advanced/users/',
  userById: (id: RouteParam) => `${RoutesConstants.users()}${id}/`,
  teams: () => '/advanced/teams/'
};

export interface IRoutes {
  [key: string]: (index?: string) => string;
}
