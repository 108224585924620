import { CommentOutlined, CopyTwoTone, MacCommandOutlined } from '@ant-design/icons';
import { Modal, Spin, Switch, Typography } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import { Status } from '../../components/display';
import { DrawerComponentsEnum } from '../../components/display/DrawerComponents';
import { SendExamEmailModal } from '../../components/display/SendEmailModal/SendEmailModal';
import { Flex, Icons, PopoverComponent } from '../../components/general';
import {
  Action,
  ArrowBottom,
  ClosedEye,
  CVIcon,
  Edit,
  GraduatedStudent,
  OpenedEye,
} from '../../components/general/Icons/Icons';
import {
  ApplicantRegistrationSource,
  ApplicantStatusColors,
  ApplicantStatusOptions,
} from '../../constants/applicant.constants';
import { COMMON_ID } from '../../constants/leadGen.constants';
import { shortOrdinalNumbers } from '../../constants/ordinalNumbers.constatns';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { getLastItem } from '../../helpers/array.helpers';
import { getDropOutMonths } from '../../helpers/date.helpers';
import { withCatch } from '../../helpers/error.helpers';
import { RoutesConstants } from '../../helpers/routes.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { deleteApplicantById, updateApplicant } from '../../services/applicant.service';
import { DateService } from '../../services/date.service';
import { PhoneService } from '../../services/phone.service';
import {
  IStudent,
  IStudentBrief,
  RegistrationSource,
  StudentStatus,
  StudentStatusEnum,
} from '../../types/applicant.types';
import type { RenderColumn } from '../../types/column.types';
import { DrawerTypes } from '../../types/drawer.types';
import { AutomatedExamState } from '../../types/exam.types';
import { GroupStateEnum, IGroup } from '../../types/group.types';
import { IBulkCreateStudent } from './applicant.schema';

const { Text } = Typography;

export const ApplicantAttachments: RenderColumn<IStudentBrief> = (_, student) => {
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();

  const handleClick = () => {
    changeId(student.id);
    openDrawer(DrawerComponentsEnum.ApplicantAttachments, 'Student attachments', DrawerTypes.Component);
  };

  return (
    <Flex onClick={handleClick} style={{ cursor: 'pointer' }} justifyContent="center">
      <div style={{ position: 'relative' }}>
        <button
          style={{
            cursor: 'pointer',
            background: 'transparent',
            border: '1px solid #f2f0f0',
          }}
        >
          View attachments
        </button>
        {student.attachmentsCount ? (
          <span
            style={{
              position: 'absolute',
              top: -16,
              right: -7,
              fontSize: 14,
              color: 'red',
            }}
          >
            {student.attachmentsCount}
          </span>
        ) : null}
      </div>
    </Flex>
  );
};

export const ApplicantCV: RenderColumn<IStudentBrief> = (_, student) => {
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();

  const formName = student.cv ? AppFormsEnum.EditStudentCVForm : AppFormsEnum.CreateStudentCVForm;
  const formTitle = student.cv ? 'Update CV' : 'Create CV'

  const handleCvForm = () => {
      changeId(student.cv ? student.cv.id : student.id);
      openDrawer(formName, formTitle, DrawerTypes.Form);
  }

  return (
    <CVIcon style={{ cursor: 'pointer' }} color={student.cv ? '#5451FF' : '#86898D' } onClick={handleCvForm}/>
  )
}


export const NameApplicantColumn = (_: any, student: any, extra: any = {}) => {
  const path = RoutesConstants.applicantPage(student.id);
  const location = window.location.href

  const updateApplicantsMutation = useMutation(updateApplicant, {
   async onSuccess() {
     setTimeout(() => window.location.reload(), 1000)
    }
  });

  const handleSaveApplicantsForContactLater = async () => {
    const mutationFunc = () => {
      return updateApplicantsMutation.mutateAsync({id: student.id, applicant: { contactLater: !student.contactLater }});
    };
    await withCatch(mutationFunc);
  }

  const showContactLaterButton = location.includes(RoutesConstants.applicants())
    || (location.includes(RoutesConstants._groups()) && (student.group.groupState === GroupStateEnum.ONGOING || student.group.groupState === GroupStateEnum.PLANNING))
  return (
    <Flex direction='column'>
      <Flex justifyContent='space-between' alignItems='center' style={{ width: '100%' }}>
        { student.introGraduated && <GraduatedStudent /> }
        {
          showContactLaterButton && (student.contactLater
            ? <OpenedEye style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => handleSaveApplicantsForContactLater()}/>
            : <ClosedEye style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => handleSaveApplicantsForContactLater()}/>)
        }
      </Flex>
      <div>
        {extra.detailLink ? (
          <Text>
            <Link to={path}>{student.name}</Link>{' '}
          </Text>
        ) : (
          <Text>{student.name} </Text>
        )}{' '}
        <br />
        <Text type="secondary">{student.email}</Text>
        <CopyTwoTone
          onClick={() => navigator.clipboard.writeText(student.email)}
          style={{ marginLeft: 8, fontSize: 16 }}
        />
      </div>
    </Flex>
  );
};

export const PhoneApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  /* const normalizePhone: string =
    PhoneService.normalize(student.phoneNumber)?.toString() || '';*/

  return (
    <Flex alignItems="center">
      <Text>{student.phoneNumber}</Text>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(student.phoneNumber)}
        style={{ marginLeft: 8, fontSize: 16, cursor: 'pointer' }}
      />
    </Flex>
  );
};

export const DeleteApplicantColumn: RenderColumn<IStudentBrief> = (_, applicant) => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const cache = useQueryClient();
  const deleteApplicantMutation = useMutation(deleteApplicantById);

  const handleToggle = () => {
    setIsOpenModal((state) => !state);
  };

  const handleDeleteTask = (id: number) => {
    return async () => {
      await withCatch(() => deleteApplicantMutation.mutateAsync(id), {
        onSuccess: () => cache.invalidateQueries(UseQueryTypes.APPLICANTS),
      });

      await handleToggle();
    };
  };

  return (
    <Spin spinning={deleteApplicantMutation.isLoading}>
      <Icons.Delete onClick={handleToggle} style={{ cursor: 'pointer' }} />
      <Modal
        visible={isModalOpen}
        onOk={handleDeleteTask(applicant.id)}
        okText={'Yes'}
        okType={'danger'}
        onCancel={handleToggle}
        centered
        width={600}
      >
        <Text>Permanently Delete applicant?</Text>
      </Modal>
    </Spin>
  );
};

export const EditApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();
  const location = useLocation();

  const state = location?.state as { isFromTerminated: boolean } | undefined;

  const isGroupTerminated = student?.group?.groupState === GroupStateEnum.TERMINATED && state?.isFromTerminated;

  const id = isGroupTerminated ? student?.studentGroupId : student.id;
  const formName = isGroupTerminated ? 'EditApplicantFormInTerminatedGroup' : 'EditApplicantForm';

  const handleOpenEditApplicantForm = () => {
    changeId(id as number);
    openDrawer(AppFormsEnum[formName], `Edit ${student.name}`);
  };

  return <Edit onClick={handleOpenEditApplicantForm} style={{ cursor: 'pointer' }} />;
};

export const CommentApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenAddApplicantCommentForm = () => {
    changeId(student.id);
    openDrawer(AppFormsEnum.AddApplicantCommentForm, 'Add Applicant Comment');
  };

  const lastComment: string = getLastItem(student.comments)?.content || '';

  return (
    <Flex onClick={handleOpenAddApplicantCommentForm} style={{ cursor: 'pointer' }} justifyContent="center">
      <PopoverComponent content={lastComment}>
        <div style={{ width: '30px', position: 'relative' }}>
          <CommentOutlined style={{ fontSize: 18 }} />
          {student.commentCount ? (
            <span
              style={{
                position: 'absolute',
                top: -10,
                right: 5,
                fontSize: 14,
                color: 'red',
              }}
            >
              {student.commentCount}
            </span>
          ) : null}
        </div>
      </PopoverComponent>
    </Flex>
  );
};

export const ActionsColumn: RenderColumn<IStudentBrief> = (_, student) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenApplicantActionsForm = () => {
    changeId(student.id);
    openDrawer(DrawerComponentsEnum.ApplicantActions, 'Applicant Actions', DrawerTypes.Component);
  };

  return (
    <Flex onClick={handleOpenApplicantActionsForm} style={{ cursor: 'pointer' }} justifyContent="center">
      <Action style={{ transform: 'rotate(-90deg)' }} width={15} color="#161616" />
    </Flex>
  );
};

export const FeedbackApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  const { changeId } = useEntityId();
  const { openDrawer } = useDrawer();

  const handleOpenAddApplicantCommentForm = () => {
    if (student?.id) {
      changeId(student?.id);
      openDrawer(AppFormsEnum.AddApplicantFeedbackForm, 'Add Applicant Feedback');
    }
  };

  const lastFeedback: string = getLastItem(student?.feedbacks || [])?.content || '';

  return (
    <Flex onClick={handleOpenAddApplicantCommentForm} style={{ cursor: 'pointer' }} justifyContent="center">
      <PopoverComponent content={lastFeedback}>
        <div style={{ width: '30px', position: 'relative' }}>
          <MacCommandOutlined style={{ fontSize: 18 }} />
          {student.feedbacksCount ? (
            <span
              style={{
                position: 'absolute',
                top: -10,
                right: 5,
                fontSize: 14,
                color: 'red',
              }}
            >
              {student.feedbacksCount}
            </span>
          ) : null}
        </div>
      </PopoverComponent>
    </Flex>
  );
};

export const EmailApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  return (
    <>
      <span>{student.email}</span>
      <CopyTwoTone
        onClick={() => navigator.clipboard.writeText(student.email)}
        style={{ marginLeft: 8, fontSize: 16 }}
      />
    </>
  );
};

export const DateApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  return (
  <Flex direction='column' flexWrap='nowrap' style={{ width: 'max-content' }}>
    <div>{DateService.getFullDate(student.created_at)}</div>
    <div>at {DateService.getTimeDate(student.created_at)}</div>
  </Flex>
  )
}

export const StartDateApplicantColumn: RenderColumn<IStudentBrief> = (_, student) =>
  DateService.getFullDate(student.group.startDate);

export const EnrolledDateApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  if (!student.applicantActions.length) return 'N/A';
  return DateService.getFullDate(student.applicantActions[0].created_at);
};

export const DetailsApplicantColumn: RenderColumn<IStudentBrief> = (_, { uuid }) => {
  return (
    <Link to={RoutesConstants.publicApplicantAgreement(uuid)} target="_blank">
      <Icons.ShowBottom style={{ transform: 'rotate(-90deg)' }} />
    </Link>
  );
};

export const StatusApplicantColumn: RenderColumn<IStudentBrief> = (_, applicant) => {
  const { group, status, admissionExam, applicantActions = [] } = applicant;
  const LEFT_DATE_AFTER_SECOND = 1;
  const applicantStatus = ApplicantStatusOptions[status]?.label;
  const actionDate = applicantActions[0]?.created_at;
  const isAfterEnrollment =
    actionDate && DateService.compareDates(actionDate, group.startDate) === LEFT_DATE_AFTER_SECOND;

  const isValidDropOut = status === StudentStatusEnum.DROP_OUT && isAfterEnrollment;

  if (isValidDropOut) {
    const groupStartDate = new Date(group.startDate);
    const dropOutDate = new Date(actionDate);

    const dropOutMonths = getDropOutMonths(groupStartDate, dropOutDate);

    return (
      <Status>
        {applicantStatus}{' '}
        <span style={{ color: 'orange' }}>
          {' '}
          from{' '}
          {shortOrdinalNumbers[dropOutMonths] ??
            shortOrdinalNumbers.default.replace('$x', dropOutMonths.toString())}{' '}
          months
        </span>
      </Status>
    );
  }

  const statusColor: { color?: string } = {};

  switch (status) {
    case StudentStatusEnum.EXAM_PASSED:
      statusColor.color = '#84D06C';
      break;
    case StudentStatusEnum.EXAM_FAILED:
      statusColor.color = '#EB3223';
  }

  return (
    <div>
      <Status style={{ color: ApplicantStatusColors[status] ?? ApplicantStatusColors.default }}>
        {applicantStatus}
      </Status>
      {admissionExam?.state === AutomatedExamState.DONE && (
        <Flex alignItems="center" justifyContent="center" style={{ marginTop: 10 }}>
          <span>{admissionExam.score}</span>
          <span>/</span>
          <span>{admissionExam.maxScore}</span>

          <Flex style={{ marginLeft: 5 }}>
            {SendEmailColumn(_, applicant, SendExamEmailModal, applicant.admissionExam.resultMailSent)}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export const RegistrationSourceApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => {
  const label = ApplicantRegistrationSource[student.registrationSource]?.label;
  const taskCreatorName = student.createdBy?.firstName;
  const leadGen = student.leadGen?.sourceName;

  return taskCreatorName
    ? `${label} added by ${taskCreatorName} ${student.createdBy.lastName}`
    : `${label}${student.leadGen?.id === COMMON_ID ? '' : ` / ${leadGen}`}`;
};

export const CourseApplicantColumn: RenderColumn<IStudentBrief> = (_, student) => student.group.course.name;

export const formatGroupApplicant = (student: IStudent, extra: { link: boolean } = { link: false }) => {
  if (student.group?.id) {
    const path = RoutesConstants.groupPage(student.group.id);
    return extra.link ? (
      <Text>
        <Link to={path}>{student.group.name}</Link>{' '}
      </Text>
    ) : (
      student.group.name
    );
  } else {
    return `${student.course.name} - Group not assigned`;
  }
};
export const formatGroup = (group: IGroup, extra: any = {}) => {
  return formatGroupApplicant({ group } as IStudent, extra);
};

export const GroupApplicantColumn: RenderColumn<IStudentBrief> = (_: any, student: IStudent) =>
  formatGroupApplicant(student, { link: true });

export const CertificateGroupColumn: RenderColumn<IStudentBrief> = (_, student) => {
  const [isCertificateGiven, setIsCertificateGiven] = useState(student.isCertificateGiven);
  const updateApplicantMutation = useMutation(updateApplicant);

  const handleUpdate = async (value: boolean) => {
    const mutationFn = () => {
      return updateApplicantMutation.mutateAsync({
        id: student.id,
        applicant: {
          ...student,
          groupId: student.group.id,
          isCertificateGiven: value,
        },
      });
    };

    await withCatch(mutationFn, {
      onSuccess: () => setIsCertificateGiven(value),
    });
  };

  return (
    <Spin spinning={updateApplicantMutation.isLoading}>
      <Switch onChange={handleUpdate} checked={isCertificateGiven} />
    </Spin>
  );
};

export const PaymentGroupColumn: RenderColumn<IStudentBrief> = (_, student) => (
  <div
    style={{
      height: '15px',
      width: '15px',
      borderRadius: '50%',
      backgroundColor: student.hasDebt ? 'red' : 'green',
    }}
  />
);

export const BulkStudentEmailColumn: RenderColumn<IBulkCreateStudent> = (email) => {
  return (
    <>
      <span>{email}</span>
      <CopyTwoTone onClick={() => navigator.clipboard.writeText(email)} style={{ marginLeft: 8, fontSize: 16 }} />
    </>
  );
};

export const BulkStudentNameColumn: RenderColumn<IBulkCreateStudent> = (name) => {
  return (
    <div>
      <Text>{name}</Text>
    </div>
  );
};

export const BulkStudentPhoneColumn: RenderColumn<IBulkCreateStudent> = (phone) => PhoneService.normalize(phone);

export const BulkStudentStatusColumn: RenderColumn<IBulkCreateStudent> = (status: StudentStatus) =>
  ApplicantStatusOptions[status]?.label;

export const BulkStudentCourseColumn: RenderColumn<IBulkCreateStudent> = (course) => course.name;

export const BulkStudentGroupColumn: RenderColumn<IBulkCreateStudent> = (group) => `Group ${group.id} - ${group.name}`;

export const BulkStudentRegistrationSourceColumn: RenderColumn<IBulkCreateStudent> = (
  registrationSource: RegistrationSource,
) => ApplicantRegistrationSource[registrationSource]?.label;

export const SendEmailColumn = (_: any, student: IStudent, Component: any, isOpen: boolean) => {
  let [isSendEmailModalVisible, setSendEmailModalVisible] = useState(false);

  const toggleModal = () => {
    setSendEmailModalVisible((prev) => !prev);
  };

  const EmailIcon = isOpen ? <Icons.SentEmail /> : <Icons.Email onClick={toggleModal} style={{ cursor: 'pointer' }} />;

  return (
    <>
      {EmailIcon}
      {isSendEmailModalVisible && (
        <Component student={student} isModalVisible={isSendEmailModalVisible} onCancel={toggleModal} />
      )}
    </>
  );
};

export const DetailApplicantColumn = (_: any, applicant: IStudentBrief) => {
  const path = RoutesConstants.applicantPage(applicant.id);
  return (
    <Link to={path}>
      <ArrowBottom style={{ transform: 'rotate(-90deg)' }} width={10} color="#161616" />
    </Link>
  );
};
