import { ColumnSchema } from '../../types/column.types';
import { ITaskGET, TaskSourceEnum } from '../../types/task.types';
import {
  AvatarTaskColumn,
  CommentTaskColumn,
  DeleteTaskColumn,
  DueDateTaskColumn,
  EditTaskColumn,
  RelatedToTaskColumn,
  StatusTaskColumn,
  TypeTaskColumn,
} from './tasks.renderer';

export const defaultTasksColumns: ColumnSchema<ITaskGET & { activeTaskSource: TaskSourceEnum }> = {
  uuid: 'defaultTasksColumns',
  schema: {
    title: {
      title: 'Task',
      dataIndex: 'title',
      sorter: () => -1,
    },

    description: {
      title: 'Description',
      dataIndex: 'description',
    },
    taskType: {
      title: 'Type',
      dataIndex: 'taskType',
      sorter: () => -1,
      render: TypeTaskColumn,
    },
    taskStatus: {
      title: 'Stage',
      dataIndex: 'taskStatus',
      render: StatusTaskColumn,
    },
    owner: {
      title: 'Created By',
      dataIndex: 'creatorId',
      width: 200,
      render: AvatarTaskColumn,
    },
    dueDate: {
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: DueDateTaskColumn,
    },
    relatedTo: {
      title: 'Related To',
      dataIndex: 'relatedTo',
      width: 200,
      render: RelatedToTaskColumn,
    },
    comment: {
      title: 'Comment',
      width: 50,
      render: CommentTaskColumn,
    },
    edit: {
      dataIndex: 'edit',
      title: 'Edit',
      width: 30,
      render: EditTaskColumn,
    },
  },
};

export const createdTasksColumns: ColumnSchema<ITaskGET & { activeTaskSource: TaskSourceEnum }> = {
  uuid: 'createdTasksColumns',
  schema: {
    ...defaultTasksColumns.schema,
    owner: {
      title: 'Owner',
      dataIndex: 'ownerId',
      width: 200,
      render: AvatarTaskColumn,
    },
    delete: {
      dataIndex: 'delete',
      title: 'Delete',
      width: 30,
      render: DeleteTaskColumn,
    },
  },
};
