import { GlobalNotificationTypes } from './notification.types';

export enum ReminderType {
  GROUP = 'GROUP',
  APPLICANT = 'APPLICANT',
  INSTRUCTOR = 'INSTRUCTOR',
  OTHER = 'OTHER',
}

export enum ReminderStatus {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
  CANCELED = 'CANCELED',
}

export interface IReminder {
  title: string;
  description: string;
  type: ReminderType;
  status: ReminderStatus;
  remindingDate: string;
  ownerUuid: string;
  id: number;
}

export interface IReminderNormalized {
  data: IReminder;
  notificationType: GlobalNotificationTypes;
}

export type ICreateOrUpdateReminder = Omit<IReminder, 'id' | 'status' | 'ownerUuid'>;

interface IReminderFilterProps {
  status: ReminderStatus;
  type: ReminderType;
  dateFrom: string;
  dateTo: string;
  reminderIds: number[];
}

export type IReminderFilter = Partial<IReminderFilterProps>;

export interface IRemindersResponse {
  currentPage: number;
  reminders: IReminder[];
  status: ReminderStatus;
  totalElements: number;
  totalPages: number;
}
