import { Spin } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, CustomDatePickerFormik, Flex, Input, SingleSelectFormik } from '../../components/general';
import { CurrencyOptions } from '../../constants/currency.constants';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { createPaymentsByGroupId } from '../../services/payment.service';
import { IAppForm } from '../../types/form.types';
import { ICreatePayment } from '../../types/payment.types';
import { CreatePaymentFormSchema } from './schemas/createPayment.forms.schema';

export const CreatePaymentForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: groupId } = useEntityId();
  const cache = useQueryClient();
  const createGroupPayment = useMutation(createPaymentsByGroupId, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.PAYMENT),
  });

  const handleCreatePayment = async (newPayment: Partial<ICreatePayment>) => {
    newPayment.groupId = groupId;
    const mutationFunc = () => createGroupPayment.mutateAsync(newPayment as ICreatePayment);

    await withCatch(mutationFunc, {
      onSuccess: () => closeDrawer(),
    });
  };

  const isScreenLoading = createGroupPayment.isLoading;

  return (
    <Spin spinning={isScreenLoading}>
      <Formik initialValues={{}} validationSchema={CreatePaymentFormSchema} onSubmit={handleCreatePayment}>
        {(props) => (
          <Form
            name="create-payment"
            style={{
              width: '100%',
              height: 'calc(100vh - 120px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field name="description" label="Payments description" component={Input} required />
            <ErrorMessage name="description" component="span" className="error" />

            <Field name="date" label="Date of payment" component={CustomDatePickerFormik} required />
            <ErrorMessage name="date" component="span" className="error" />

            <Field name="amount" label="Payments amount" component={Input} required />
            <ErrorMessage name="amount" component="span" className="error" />

            <Field
              name="currency"
              label="Payments currency"
              options={CurrencyOptions}
              component={SingleSelectFormik}
              required
            />

            <ErrorMessage name="currency" component="span" className="error" />

            <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
              <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={() => props.handleReset()}>
                Reset
              </Button>
              <Button
                style={{ minWidth: 160 }}
                htmlType="submit"
                variant="primary"
                disabled={!(props.dirty && props.isValid)}
              >
                Apply
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
