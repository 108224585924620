import { armenianUnicodeMachRegex, isArmenianLetter } from "../../../helpers/isArmenianLetter.helper";
import { StudentStatusEnum } from '../../../types/applicant.types';
import { AttachmentTypeEnum } from "../../../types/attachment.types";
import { IStudentAbsenteesResponse, IStudentAbsenteesTableData } from "../types/studentAbsentees.types";
import { IStudentAttachmentResponse, IStudentAttachmentsTableData } from "../types/studentAttachment.types";
import { IStudentGrade, IStudentGradeTableData } from "../types/studentGrade.types";
import { IStudentPayment, IStudentPaymentsResponse } from "../types/studentPayments.types";

export const studentGradeTableAdapter = (studentGradeResponse: IStudentGrade | undefined): IStudentGradeTableData[] => {
  if (!studentGradeResponse) return []

  let index = 0;
  return studentGradeResponse.exams.map(({grade, examType, date}) => {
    index += 1
    return {
      grade: grade ?? 'N/A',
      date,
      key: index,
      group: studentGradeResponse.groupName,
      type: examType,
    }
  })
}


export const studentAbsenteesTableAdapter = (response?: IStudentAbsenteesResponse): IStudentAbsenteesTableData[] => {
  if (!response) return []

  return [{...response, key: 0}]
}

export const studentPaymentsTableAdapter = (response?: IStudentPaymentsResponse): IStudentPayment[] => {
  if (!response) return []

  return response.payments.map(studentItem => {
    return {
      ...studentItem,
      courseName: response.courseName,
      key: studentItem.id
    }
  })
}


export const studentAttachmentsTableAdapter = (response?: IStudentAttachmentResponse): IStudentAttachmentsTableData[] => {
  if (!response) return []

  return response.attachments.map((attachment, index) => {
    return {
      ...attachment,
      key: index
    }
  })
}

export const studentNameHyValidator = (currentValue: string) =>
  isArmenianLetter(currentValue) ? currentValue : currentValue.replace(armenianUnicodeMachRegex, '');

export const handleNameHyError = (fullName: string, errorSetter: (error: string | boolean) => void) => {
  const error = nameHyErrorGetter(fullName);
  errorSetter(error);
}

export const nameHyErrorGetter = (fullName: string,) => {
  const [name, surname] = fullName.split(' ');

  if(!name && !surname) {
    return  'Անուն Ազգանուն դաշտը պարտադիր է';
  } else if(!name || !surname) {
    return   'Անուն Ազգանուն դաշտը պետք է կազմված լինի անունից և ազգանունից բաժանված մեկ բացատով';
  } else if(name.length < 2 || surname.length < 2) {
    return 'Անուն Ազգանուն դաշտը պետք է պարունակի առնվազն 6 նիշ';
  } else {
    return false;
  }
}

export const getStudentAttachmentInitialState = (attachmentType: AttachmentTypeEnum): { name: string, attachment: string} => {
  switch (attachmentType) {
    case AttachmentTypeEnum.CV: {
      return {name: 'CV', attachment: ''}
    }
    default: {
      return {name: '', attachment: ''}
    }
  }
}

export const isStudentGraduated = (student: any) => student?.status === StudentStatusEnum.GRADUATED
