import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { SingleSelectFormik } from '../../../components/general';
import { UseQueryTypes } from '../../../constants/useQuery.constants';
import { generateContext } from '../../../helpers/generateContext.helpers';
import { getGroupNames } from '../../../services/groups.service';
import { IBulkCreateStudentsFormData, ICreateStudentFormData } from '../../../types/applicant.types';

export const GroupField = ({ ignoreCourse, name, required, ...props }: any) => {
  const { values } = useFormikContext<IBulkCreateStudentsFormData | ICreateStudentFormData>();
  const [groupsContext, setGroupContext] = useState<any | undefined>();

  const groupsQuery = useQuery(UseQueryTypes.GROUP_NAMES, getGroupNames);

  useEffect(() => {
    let isMounted = true;

    const filtered = ignoreCourse
      ? groupsQuery.data
      : (groupsQuery.data || []).filter((g) => {
          if (!values.courseId) return true;
          return g.course.id === values.courseId;
        });
    let groupsContext = generateContext(filtered || [], (group) => ({
      label: group.groupName,
      value: group.id,
    }));

    const defaultOption = {
      '-1': {
        label: '--Not Assigned--',
        value: -1,
      },
    };

    groupsContext = Object.assign(defaultOption, groupsContext);
    setGroupContext(groupsContext);
    return () => {
      isMounted = false;
    };
  }, [groupsQuery.isSuccess, props.name, values.courseId]);

  return (
    <>
      <Field
        label="Group"
        name={name}
        component={SingleSelectFormik}
        options={groupsContext}
        required={required}
        {...props}
      />
      <ErrorMessage name="groupId" component="span" className="error" />
    </>
  );
};
