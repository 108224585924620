import { DateService } from '../../../services/date.service';
import { IApplicantDetailPayment } from '../../../types/applicantDetail.types';
import { ColumnSchema } from '../../../types/column.types';
import { AmountStatusColumn, GroupColumn, InvoiceColumn } from '../applicantDetail.renderer';

export const paymentColumns: ColumnSchema<IApplicantDetailPayment> = {
  uuid: 'paymentColumn',
  schema: {
    date: {
      title: 'Date',
      width: 175,
      sorter: () => -1,
      render: DateService.getFullDate,
    },
    groupName: {
      title: 'Group',
      render: (groupName, { groupId }) => GroupColumn({groupName}, groupId),
    },
    isPaid: {
      title: 'Status',
      width: 120,
      render: (isPaid, { amount }) => AmountStatusColumn(isPaid, amount),
    },
    invoiceLink: {
      title: 'Invoice',
      width: 175,
      render: InvoiceColumn,
    },
  },
};
