import { Col, Divider, Pagination, Row, Spin, Table } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ColumnModal } from '../../components/display/ColumnSettingModal/ColumnModal';
import ResultSummary from '../../components/display/ResultSummary/ResultSummary';
import { Button, FilterButton, Icons, Search } from '../../components/general';
import { ChangeView } from '../../components/general/Icons/Icons';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { exportSuccessHelper } from '../../helpers/applicants/exportSuccess.helper';
import { withCatch } from '../../helpers/error.helpers';
import { useTable } from '../../hooks/table/useTable';
import { useColumnModalAdapter } from '../../hooks/useColumnModal.adapter';
import { useFilter } from '../../hooks/useFilter.hooks';
import { useRowSelectionAdapter } from '../../hooks/useRowSelection.adapter';
import { useSearchHandler } from '../../hooks/useSearchHandler.hooks';
import { defaultConsultantColumns } from '../../schemas/consultants/consultant.schema';
import { bulkDeleteConsultation, exportConsultations, filterConsultant } from '../../services/consultant.service';
import type { FilterConsultantOptions, IConsultant } from '../../types/consultation.types';
import { SortDirectionEnum } from '../../types/general.types';

const defaultFilterConsultantOptions: Partial<FilterConsultantOptions> = {
  sortBy: 'created_at',
  sortDirection: SortDirectionEnum.DESC,
};

export default function Consultation() {
  const consultantTable = useTable<IConsultant>(defaultConsultantColumns);
  const modalAdapter = useColumnModalAdapter(consultantTable.getTableColumnSettings());
  const { handleSearch, term } = useSearchHandler();

  const rowSelectionAdapter = useRowSelectionAdapter<IConsultant>();
  const selectedApplicantsCount = rowSelectionAdapter.selectedRows.length;

  const cache = useQueryClient();

  const deleteConsultantMutation = useMutation(bulkDeleteConsultation, {
    onSuccess: () => cache.invalidateQueries(UseQueryTypes.CONSULTANTS),
  });

  const handleDeleteConsultant = async () => {
    const mutationFn = () =>
      deleteConsultantMutation.mutateAsync(rowSelectionAdapter.selectedRows.map((el: IConsultant) => el.id));

    await withCatch(mutationFn);
    rowSelectionAdapter.clean();
  };

  const { openDrawer } = useDrawer();
  const { filterOptions, sorterOptions } = useFilter(defaultFilterConsultantOptions);

  const consultant = useQuery([UseQueryTypes.CONSULTANTS, filterOptions, sorterOptions], () =>
    filterConsultant(filterOptions, sorterOptions),
  );

  const handleOpenConsultantFilterForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.FilterConsultantForm, 'Filter Consultant');
  };

  const handleOpenCreateConsultantForm = () => {
    rowSelectionAdapter.clean();
    openDrawer(AppFormsEnum.CreateConsultantForm, 'Create Consultant');
  };

  const exportConsultationMutation = useMutation(exportConsultations);

  const handleExportSelectedConsultations = async () => {
    const ids: number[] = rowSelectionAdapter.selectedRows.map((consultation: IConsultant) => consultation.id);

    const mutationFunc = () => {
      return exportConsultationMutation.mutateAsync(ids);
    };

    await withCatch(mutationFunc, {
      onSuccess: async (data) => {
        exportSuccessHelper(data);
      },
    });
  };

  const consultantsTotalSize = consultant.data?.totalElements ?? 0;
  const isScreenLoading = consultant.isLoading;

  return (
    <>
      <Spin spinning={isScreenLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Row wrap={false} justify="start" style={{ rowGap: '8px', columnGap: '8px' }}>
              <Search style={{}} placeholder="Search" defaultValue={term} onChange={handleSearch} />
              <FilterButton onClick={handleOpenConsultantFilterForm} />
            </Row>
            <Divider style={{ margin: '16px 0px' }} />
          </Col>

          <Col span={24}>
            <Row justify="space-between" style={{ marginBottom: 16, rowGap: '8px' }}>
              <ResultSummary text={`${consultantsTotalSize} result found`} />
              <Row justify="space-between" style={{ columnGap: '8px' }}>
                {rowSelectionAdapter.isSelected && (
                  <Button style={{ width: 'max-content' }} variant="primary" onClick={handleDeleteConsultant}>
                    Delete
                  </Button>
                )}
                <Button
                  style={{ width: 'max-content' }}
                  onClick={handleOpenCreateConsultantForm}
                  variant="primary"
                  icon={<Icons.Add />}
                >
                  Add a new Consultant
                </Button>
              </Row>
            </Row>
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
              {selectedApplicantsCount > 0 && <ResultSummary text={`Selected ${selectedApplicantsCount} consultant`} />}
              <Row style={{ marginLeft: 'auto' }} justify="end">
                {rowSelectionAdapter.isSelected && (
                  <Col style={{ marginRight: '8px' }}>
                    <Button variant="primary" onClick={handleExportSelectedConsultations}>
                      Export
                    </Button>
                  </Col>
                )}
                <ChangeView onClick={modalAdapter.openSettings} />
              </Row>
            </Row>
          </Col>

          <Col span={24}>
            <Table<IConsultant>
              {...consultantTable.getTableProps({
                rowKey: (consultant) => consultant.id,
                dataSource: consultant.data?.consultations,
                rowSelection: rowSelectionAdapter.getProps(),
              })}
            />
            <Pagination
              {...consultantTable.getPaginationProps({
                total: consultantsTotalSize,
                style: { marginTop: 20 },
              })}
            />
            <ColumnModal {...modalAdapter.getColumnModalProps()} />
          </Col>
        </Row>
      </Spin>
    </>
  );
}
