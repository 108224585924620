import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Key } from 'react';

import type { RootState } from '../../index';

export interface ResourceState {
    selected: any[];
    selectedKeys?: Key[];
}

const initialState = {
    selected: [],
    selectedKeys: [],
} as ResourceState;

export const sharedSelectsSlice = createSlice({
    name: 'sharedSelects',
    initialState,
    reducers: {
        selectItems: (
            state,
            action: PayloadAction<ResourceState['selected']>,
        ) => {
            state.selected = action.payload;
        },
        selectItemsKeys: (
            state,
            action: PayloadAction<ResourceState['selectedKeys']>,
        ) => {
            state.selectedKeys = action.payload;
        },
    },
});

export const sharedSelectsActions = sharedSelectsSlice.actions;

export const selectItems = (state: RootState) => state.sharedSelects.selected;
export const selectItemKeys = (state: RootState) =>
    state.sharedSelects.selectedKeys;
