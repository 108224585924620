import { Avatar } from 'antd';

import defaultAvatar from '../../../../../assets/images/avatar.png';
import { DateService } from '../../../../../services/date.service';
import Flex from '../../../../general/Flex/Flex';

interface IPanelProps {
  createdAt: string;
  description: string;
  avatar: string | null;
  firstName: string;
  lastName: string;
}

export function Panel({ createdAt, description, avatar, firstName='', lastName='' }: IPanelProps) {
  return (
    <Flex direction='column'  style={{ width: '100%', paddingBottom: '16px' }}>
      <Flex justifyContent='space-between' style={{ width: '100%', paddingBottom: '16px' }}>
          <Flex style={{ gap: '12px' }}>
            <Avatar src={avatar || defaultAvatar} />
            <div>{`${firstName}  ${lastName}`}</div>
           </Flex>
        <div
          style={{
            fontFamily: 'Barlow',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '20px',
            color: 'rgba(15, 23, 46, 0.5)'
          }}
        >
          {DateService.getFormattedDateTime(createdAt)}
        </div>
      </Flex>
      <div style={{ width: '100%' }}>
        <div
          style={{
            fontFamily: 'Barlow',
            fontStyle: 'italic',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '22px',
            color: '#0F172E'
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Flex>
  );
}
