import { Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';

import { Button, CustomDatePickerFormik, Flex, Input } from '../../components/general';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { upperFirstLetter } from '../../helpers/string.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { DateService } from '../../services/date.service';
import { bulkUpdateExamGrades, updateExam } from '../../services/exam.service';
import { IStudentBrief, IStudentExams } from '../../types/applicant.types';
import { ICreateExam, IExam } from '../../types/exam.types';
import { IAppForm } from '../../types/form.types';
import { IGroupDetailedExamModel } from '../../types/group.types';

interface IFormikInitialValues {
  studentExams: IStudentExams[];
  examContents: IExam[];
}

export const EditExamForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();

  const { id: applicantId } = useEntityId();
  const queryClient = useQueryClient();

  const userData: IStudentBrief | undefined = queryClient.getQueryData([UseQueryTypes.APPLICANT_ID, applicantId]);

  const updateUserGradeMutation = useMutation(bulkUpdateExamGrades);

  const updateExamMutation = useMutation(updateExam);

  const examsData = queryClient.getQueryData(UseQueryTypes.GROUP_DETAIL_EXAM_INFO) as IGroupDetailedExamModel;

  const formik = useFormik({
    initialValues: {
      studentExams: userData?.applicantExams,
      examContents: examsData.exams,
    } as IFormikInitialValues,
    onSubmit: async ({ studentExams, examContents }) => {
      if (studentExams) {
        const changedStudentExams = studentExams
          .filter((studentExamData, index) => {
            const initialData = formik.initialValues.studentExams[index];

            return JSON.stringify(initialData) !== JSON.stringify(studentExamData);
          })
          .map((data) => ({ grade: data.grade ?? 0, id: data.id }));

        const userGradesUpdateMutationFunction = () => updateUserGradeMutation.mutateAsync(changedStudentExams);

        await withCatch(userGradesUpdateMutationFunction, {
          onSuccess: () => {
            queryClient.invalidateQueries(UseQueryTypes.GROUP_DETAIL_EXAM_INFO);
          },
        });
      }

      const examUpdateMutationFunction = (data: { id: number; exam: ICreateExam }) => () =>
        updateExamMutation.mutateAsync(data);

      for (const examContent of examContents) {
        const index = examContents.indexOf(examContent);
        const initialExamData = formik.initialValues.examContents[index];
        const isFormValuesChanged = JSON.stringify(initialExamData) !== JSON.stringify(examContent);

        if (isFormValuesChanged) {
          await withCatch(
            examUpdateMutationFunction({
              id: examContent.id,
              exam: examContent,
            }),
            {
              onSuccess: () => {
                queryClient.invalidateQueries(UseQueryTypes.GROUP_DETAIL_EXAM_INFO);
                closeDrawer();
              },
            },
          );
        }
      }
    },
  });

  const isScreenLoading = updateUserGradeMutation.isLoading || updateExamMutation.isLoading;

  return (
    <FormikProvider value={formik}>
      <Spin spinning={isScreenLoading}>
        <form
          name="create-exam"
          onSubmit={formik.handleSubmit}
          style={{
            width: '100%',
            height: 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {formik.values.examContents
            ? formik.values.examContents.map((examData: IExam, index) => {
                return (
                  <>
                    <Text style={{ fontSize: '24px', textAlign: 'center' }}>
                      {upperFirstLetter(examData.examType)} exam settings
                    </Text>

                    <Field
                      name={`examContents[${index}].date`}
                      label="Date"
                      formatDate={DateService.getISODateShort}
                      component={CustomDatePickerFormik}
                    />

                    <ErrorMessage name={`examContents[${index}].date`} component="span" className="error" />

                    <Field name={`examContents[${index}].minScore`} label="Min score" component={Input} />

                    <ErrorMessage name={`examContents[${index}].minScore`} component="span" className="error" />

                    <Field name={`examContents[${index}].maxScore`} label="Max score" component={Input} />

                    <ErrorMessage name={`examContents[${index}].maxScore`} component="span" className="error" />
                  </>
                );
              })
            : 'Exams are empty'}

          <div style={{ margin: '20px 0' }}>
            {formik.values.studentExams ? (
              formik.values.studentExams.map((studentExam, index) => {
                return (
                  <>
                    <Text style={{ fontSize: '24px', textAlign: 'center' }}>
                      {upperFirstLetter(studentExam.examType)} student exam settings
                    </Text>

                    <Field name={`studentExams[${index}].grade`} label="Grade" component={Input} />

                    <ErrorMessage name={`studentExams[${index}].grade`} component="span" className="error" />
                  </>
                );
              })
            ) : (
              <div style={{ textAlign: 'center' }}>Student exams are empty</div>
            )}
          </div>

          <Flex style={{ marginTop: 'auto' }} justifyContent="space-between">
            <Button htmlType="button" style={{ minWidth: 160, marginRight: 8 }} onClick={(e) => formik.handleReset(e)}>
              Reset
            </Button>
            <Button
              style={{ minWidth: 160 }}
              htmlType="submit"
              variant="primary"
              disabled={!(formik.dirty && formik.isValid)}
            >
              Apply
            </Button>
          </Flex>
        </form>
      </Spin>
    </FormikProvider>
  );
};
