import { Typography } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { Flex } from '../../components/general';
import { Edit } from '../../components/general/Icons/Icons';
import { EditableInput } from '../../components/general/Input/Input';
import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { AppFormsEnum } from '../../forms';
import { handleQueryError, withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { DateService } from '../../services/date.service';
import { bulkUpdateExamGrades } from '../../services/exam.service';
import {
  IStudentWithExams,
} from '../../types/applicant.types';
import type { RenderColumn } from '../../types/column.types';
import {
  ExamTypeEnum,
  IExam,
  IExamColumn,
  IUpdateExamGrades,
} from '../../types/exam.types';
import StatusIndicator from '../statusIndicator/statusIndicator';

const { Text } = Typography;

export const StudentNameExamColumn: RenderColumn<IExamColumn> = (
  _,
  student,
) => {
  return (
    <div>
      <Text>{student.name}</Text> <br/>
      <Text type="secondary">{student.email}</Text>
    </div>
  );
};

export const ExamColumnDynamicRenderer = (
  titleName: keyof IExamColumn,
): RenderColumn<IExamColumn> => {
  return (_, student) => {
    return (
      <div>
        <Text>{student[titleName]}</Text>
      </div>
    );
  };
};

export const ExamColumnStatus = (
  examsData: IExam[] | undefined,
): RenderColumn<IStudentWithExams> => {
  const { openDrawer } = useDrawer();
  const { changeId } = useEntityId();

  const finalExamFindCb = (examData: IExam) =>
    examData.examType === ExamTypeEnum.FINAL;

  const finalExamData = examsData?.find(finalExamFindCb);

  const finalExamDataIndex = examsData?.findIndex(finalExamFindCb);

  const finalExamMaxScore = finalExamData?.maxScore ?? 2;
  const finalExamMinScore = finalExamData?.minScore ?? 1;

  const isFinalExamExist =
    finalExamDataIndex !== undefined && finalExamDataIndex !== -1;

  return (_, student) => {
    const studentGrade = isFinalExamExist
      ? student.applicantExams[finalExamDataIndex]?.grade
      : false;

    const isStudentPassedExam = studentGrade
      ? studentGrade >= finalExamMinScore && studentGrade <= finalExamMaxScore
      : false;

    const handleClick = () => {
      changeId(student.id);
      openDrawer(AppFormsEnum.EditExamForm);
    };

    return (
      <Flex
        style={{ margin: '0 10px' }}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <StatusIndicator isSuccess={isStudentPassedExam}/>
        <div>
          <Edit onClick={handleClick} style={{ cursor: 'pointer' }}/>
        </div>
      </Flex>
    );
  };
};

export const ExamDateColumn = (exam: IExam): RenderColumn<IExamColumn> => {
  return (_) => {
    const formatDate = DateService.getFullDate(exam?.date);

    return <div>{formatDate}</div>;
  };
};

export const StudentFinalScoreExamsColumn = (
  examsData: IExam[] | undefined,
): RenderColumn<IStudentWithExams> => {
  const finalExamDataIndex = examsData?.findIndex(
    (examData) => examData.examType === ExamTypeEnum.FINAL,
  );

  const isFinalExamDataExist =
    finalExamDataIndex !== undefined && finalExamDataIndex !== -1;

  return (_, exam) => {
    const studentFinalExamGrade = isFinalExamDataExist
      ? exam.applicantExams[finalExamDataIndex]?.grade
      : '';

    return (
      <div>
        {studentFinalExamGrade && studentFinalExamGrade > 0
          ? studentFinalExamGrade
          : ''}
      </div>
    );
  };
};

export const StudentExamGradeAndStatusColumn = (
  examData: IExam,
): RenderColumn<IExamColumn> => {
  return function StudentExamGradeAndStatus(_, data) {
    const bulkUpdateExamGradesMutation = useMutation(bulkUpdateExamGrades);
    const queryClient = useQueryClient();

    const bulkUpdateExamGradesFn = (data: IUpdateExamGrades[]) => () =>
      bulkUpdateExamGradesMutation.mutateAsync(data);

    const studentExamData = data.applicantExams?.find(
      (exam) => exam.examId === examData.id,
    );

    const examGrade = studentExamData?.grade;

    const examMaxScore = examData.maxScore;
    const examMinScore = examData.minScore;

    const isExamPassed =
      examGrade && examGrade <= examMaxScore && examGrade >= examMinScore;

    const showExamGrade = examGrade
      ? `${examGrade}/${examMaxScore}`
      : `Provide exam grade`;

    const handleSave = async (e: any, grade: string) => {
      const numberGrade = Number(grade);

      if (numberGrade > examMaxScore || numberGrade <= 0) {
        handleQueryError(
          'Invalid Grade.',
          `The grade must be between 1 to ${examMaxScore}.`,
        );
        return false;
      }

      if (studentExamData?.examId) {
        await withCatch(
          bulkUpdateExamGradesFn([
            {
              id: studentExamData?.id,
              grade: numberGrade,
            },
          ]),
          {
            onSuccess: () => {
              queryClient.invalidateQueries([
                UseQueryTypes.GROUP_DETAIL_EXAM_INFO,
              ]);
            },
          },
        );

        return true;
      }
      return false;
    };

    return (
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <EditableInput text={showExamGrade} onSave={handleSave}/>
        <StatusIndicator isSuccess={Boolean(isExamPassed)}/>
      </Flex>
    );
  };
};
