import * as Yup from 'yup';

import messages from '../../../constants/messages.constants';
import { rePhoneNumber } from '../../../constants/phoneValidation.constants';
import { InstructorRoleEnum, InstructorStatusEnum } from '../../../types/instructor.types';

const { required } = messages;

export const TransformInstructorFormSchema = Yup.object().shape({
  name: Yup.string().required(required),
  email: Yup.string().email('Invalid email').required(required),
  phoneNumber: Yup.string()
    .required(required)
    .matches(rePhoneNumber, 'Phone number is not valid'),
  linkedInProfile: Yup.string(),
  status: Yup.mixed<InstructorStatusEnum>()
    .oneOf(Object.values(InstructorStatusEnum))
    .required(required),
  role: Yup.mixed<InstructorRoleEnum>()
    .oneOf(Object.values(InstructorRoleEnum))
    .required(required),
  tracks: Yup.number().typeError('Invalid tracks').required(required),
  signatureLink: Yup.string().nullable(true),
});
