import { useState } from 'react';
import { useQuery } from 'react-query';

import { Loading } from '../../../components/general/Loading/Loading';
import { useAuth } from '../../../contexts/useAuth.context';
import { getStudentAgreement } from '../../../services/student.service';
import AgreementCreator from '../components/Agreements/AgreementCreator/AgreementCreator';
import AgreementsRenderer from '../components/Agreements/AgreementsRenderer/AgreementsRenderer';
import { StudentQueryConstants } from '../constants/studentQuery.constants';

export default function Agreements() {
  const { user } = useAuth();
  const [showAgreementCreation, setShowAgreementCreation] = useState(false);

  const studentAgreementQuery = useQuery(
    [StudentQueryConstants.STUDENT_AGREEMENTS],
    () => getStudentAgreement(user?.id as number),
    { enabled: Boolean(user) },
  );

  const studentAgreements = studentAgreementQuery.data?.attachments;

  const toggleAgreementEvent = () => {
    setShowAgreementCreation((prev) => !prev);
  };

  const closeAgreementEvent = () => {
    setShowAgreementCreation(false);
  };

  return (
    <>
      {studentAgreementQuery.isLoading && <Loading />}
      {studentAgreements?.length && !showAgreementCreation ? (
        <AgreementsRenderer handleAddAgreement={toggleAgreementEvent} agreements={studentAgreements} />
      ) : (
        <AgreementCreator lastEvent={closeAgreementEvent} />
      )}
    </>
  );
}
