import { handleQueryError } from '../helpers/error.helpers';

export const FilesConstants = {
  imageMaxSize: 2 * 1024 * 1024,
  imageMaxSizeTitle: 'Image size',
  imageMaxSizeMessage: 'Image size must be smaller than 2mb',
  imageTypeErrorTitle: 'Format Error',
  imageTypeErrorMessage: 'Image should be png, jpeg or jpg formats',
};


export const FileTypes = {
  PDF: 'pdf',
  JPG: 'jpg'
}


export const checkFileValidation = (
  condition: boolean,
  error: { title: string; message: string },
) => {
  if (condition) {
    handleQueryError(error.title, error.message);
    return true;
  }
};
