import { formatGroup } from '../../../../schemas/applicants/applicant.renderer';
import {
  DetailGroupColumn,
  SalaryInstructorGroupColumn,
  StartDateGroupColumn,
} from '../../../../schemas/groups/group.renderer';
import type { ColumnSchema } from '../../../../types/column.types';
import type { IGroup } from '../../../../types/group.types';

export const defaultInstructorGroupsSmallColumns: ColumnSchema<IGroup> = {
  uuid: 'defaultInstructorGroupsSmallColumns',
  schema: {
    group: {
      title: 'Group',
      render: (_, group) => formatGroup(group),
    },
    salary: {
      title: 'Salary',
      render: SalaryInstructorGroupColumn,
    },
    details: {
      title: 'Details',
      dataIndex: 'id',
      align: 'center',
      width: 30,
      render: (_, group) => DetailGroupColumn(_, group, 'instructor'),
    },
  },
};

export const defaultInstructorGroupsColumns: ColumnSchema<IGroup> = {
  uuid: 'defaultInstructorGroupsColumns',
  schema: {
    course: {
      title: 'Course',
      render: (_, group) => group.course.name,
    },
    group: defaultInstructorGroupsSmallColumns.schema.group,
    startDate: {
      title: 'Start Date',
      render: StartDateGroupColumn,
    },
    ...defaultInstructorGroupsSmallColumns.schema,
  },
};
