import { useFormik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";

import { Flex, Input } from "../../../../../components/general";
import Button from "../../../../../components/general/Button/Button";
import { handleQueryError } from "../../../../../helpers/error.helpers";
import { resetPassword as resetPasswordInstructor } from "../../../../../services/instructors.service";
import { resetPassword as resetPasswordStudent } from "../../../../../services/student.service";
import { resetPassword as resetPasswordUser } from "../../../../../services/user.service";
import { AuthGroup } from "../../../../../types/auth.types";
import {
  resetPasswordInitialValues as initialValues,
  resetPasswordSchema as validationSchema
} from "../../../schemas/forgetPassword.schema";
import { ResetPasswordViewsEnum } from "../../../types/forgetPasswordForm.types";
import styles from "../../ForgetPassword/ForrgetPassword.module.css";

interface props {
  changeView: Dispatch<SetStateAction<ResetPasswordViewsEnum>>
  authGroup: AuthGroup,
}

const resetServices = {
  [AuthGroup.INSTRUCTOR]: resetPasswordInstructor,
  [AuthGroup.STUDENT]: resetPasswordStudent,
  [AuthGroup.USER]: resetPasswordUser,
}

const ResetPasswordForm = ({ changeView, authGroup }: props) => {
  const { email = '', token = '' } = useParams<{ email: string, token: string }>();

  const onSubmit = async ({ password }: any) => {
    try {
      const payload = {
        password,
        email,
        token
      };
      await resetServices[authGroup](payload);
      changeView(ResetPasswordViewsEnum.RESET_PASSWORD_SUCCESS)
    } catch (e) {
      handleQueryError()
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    dirty,
    isValid,
    isSubmitting
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  return (
    <>
      <h2 className={styles.title}>Reset your password</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          type='text'
          label='Password'
          name='password'
          id='password'
          value={values.password}
          placeholder='Please enter new password'
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.password && touched.password && <div className="error">{errors.password}</div>}
        <Input
          type='text'
          label='Confirm Password'
          name='confirmPassword'
          id='confirmPassword'
          value={values.confirmPassword}
          placeholder='Please confirm password'
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.confirmPassword && touched.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
        <Flex
          style={{ margin: '10px 0' }}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Button
            className={styles.button}
            style={{ padding: '16px 24px' }}
            htmlType='submit'
            variant='primary'
            disabled={isSubmitting || !isValid || !dirty}
          >
            Save
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default ResetPasswordForm;
