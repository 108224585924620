import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Key } from 'react';

import type { RootState } from '../../index';

export interface ApplicantState {
  selected: any[];
  selectedKeys?: Key[];
}

const initialState = {
  selected: [],
  selectedKeys: [],
} as ApplicantState;

export const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    selectItems: (
      state,
      action: PayloadAction<ApplicantState['selected']>,
    ) => {
      state.selected = action.payload;
    },
    selectItemsKeys: (
      state,
      action: PayloadAction<ApplicantState['selectedKeys']>,
    ) => {
      state.selectedKeys = action.payload;
    },
  },
});

export const applicantActions = applicantsSlice.actions;

export const selectApplicants = (state: RootState) => state.applicants.selected;
export const selectApplicantKeys = (state: RootState) =>
  state.applicants.selectedKeys;
