import { stringifyObject } from '../helpers/stringifyObj.helper';
import { Sorters } from '../types/general.types';
import {
  GroupApplicantsCountResponse,
  GroupCountResponse,
  GroupFilterModel,
  GroupFilterResponse,
  GroupStateEnum,
  ICreateGroup,
  IGroup,
  IGroupDetailedAbsenteeModel,
  IGroupDetailedExamModel,
  IGroupName,
  IInterviewSchedule,
} from '../types/group.types';
import axios from './config.service';

export function getGroups() {
  return axios.get('/group').then((r) => r.data) as Promise<IGroup[]>;
}

export function getGroupNames() {
  return axios.get('/group/name').then((r) => r.data) as Promise<IGroupName[]>;
}

export function createGroup(group: ICreateGroup) {
  return axios.post('/group', group).then((r) => {
    return r.data;
  }) as Promise<IGroup>;
}

export function generateGroupCertificate(groupId: number) {
  return axios.post(`/group/${groupId}/certificates/generate`).then((r) => r.data) as Promise<string>;
}

export function getGroupById(id: number) {
  return axios.get(`/group/${id}`).then((r) => r.data) as Promise<IGroup>;
}

export function updateGroupById({ group, id }: { group: Partial<IGroup>; id: number }) {
  return axios.put(`/group/${id}`, group).then((r) => r.data) as Promise<IGroup>;
}

export function deleteGroupById(id: number) {
  return axios.delete(`/group/${id}`).then((r) => r.data) as Promise<boolean>;
}

export function getDetailedGroupAbsenteeInfoById(id: number) {
  return axios.get(`/groupDetail/${id}/absentees`).then((r) => r.data) as Promise<IGroupDetailedAbsenteeModel>;
}

export function getDetailedGroupExamInfoById(id: number) {
  return axios.get(`/groupDetail/${id}/exams`).then((r) => r.data) as Promise<IGroupDetailedExamModel>;
}

export function filterGroups(filters: Partial<GroupFilterModel>, sorters: Partial<Sorters> = {}) {
  const queryString = stringifyObject(sorters);
  return axios.post(`/group/filter?${queryString}`, filters).then((r) => r.data) as Promise<GroupFilterResponse>;
}

export function launchGroup(id: number) {
  return axios.put(`/group/launch/${id}`).then((r) => r.data) as Promise<boolean>;
}

export function getGroupsCount({ groupBy, groupState }: { groupBy: string; groupState: string[] }) {
  return axios.post(`/group/count`, { groupBy, groupState }).then((r) => r.data) as Promise<GroupCountResponse>;
}

export function getGroupApplicantsCount(id: number, groupState: GroupStateEnum) {
  return axios
    .get(`/group/${id}/count?groupState=${groupState}`)
    .then((r) => r.data) as Promise<GroupApplicantsCountResponse>;
}

export function createInterviewSchedule(interviewSchedule: IInterviewSchedule) {
  return axios.post('/group/interviewSchedule', interviewSchedule).then((r) => {
    return r.data;
  }) as Promise<IInterviewSchedule>;
}

export function getInterviewSchedule(groupId: number) {
  return axios.get(`/group/interviewSchedule/${groupId}`).then((r) => {
    return r.data;
  }) as Promise<IInterviewSchedule>;
}
