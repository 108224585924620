import './MobileMenu.css';

import React from 'react';

import { Button, Flex } from '../../../../components/general';
import { Close } from '../../../../components/general/Icons/Icons';
import { useAuth } from '../../../../contexts/useAuth.context';
import { ButtonTypeEnum } from '../../../../types/button.types';
import { LayoutByUserGroup } from '../Layout/Layout';
import { UserDescriptions } from '../UserProfile/UserDescriptions';
import UserProfileLogo from '../UserProfile/UserProfileLogo';

const MobileMenu = ({ handleClose }: { handleClose: () => void }) => {
  const { user, logOut } = useAuth();

  return (
    <div className="mobile-menu-wrapper">
      <div style={{ height: '100vh', overflowY: 'auto' }}>
        <Flex
          style={{ padding: '20px', borderBottom: '1px solid #F0F0F0' }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex>
            <UserProfileLogo avatarLink={user?.avatar} style={{ marginRight: 10 }} />
            <UserDescriptions header={user?.name} content={user?.email} isMobile />
          </Flex>

          <Button className="mobile-menu-close-button" onClick={handleClose}>
            <Close />
          </Button>
        </Flex>

        <div style={{ marginLeft: '24px', marginTop: '36px', paddingBottom: '15px' }}>
          <LayoutByUserGroup authGroup={user?.authGroup} handleClickLink={handleClose} />
          <Button
            variant={ButtonTypeEnum.primary}
            style={{ marginLeft: '24px', marginTop: '20px', border: '0', width: 'auto' }}
            onClick={logOut}
          >
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
