import { Spin } from 'antd';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { UseQueryTypes } from '../../constants/useQuery.constants';
import { useDrawer } from '../../contexts/drawer.context';
import { withCatch } from '../../helpers/error.helpers';
import { useEntityId } from '../../hooks/useFilter.hooks';
import { getTeamWithRelatedResources, updateTeamById } from '../../services/team.service';
import { IAppForm } from '../../types/form.types';
import { ITeamPhone } from '../../types/teamPhone.types';
import { TransformTeamForm } from './shared/transformTeam.form';

export const EditTeamForm: IAppForm = () => {
  const { closeDrawer } = useDrawer();
  const { id: teamId } = useEntityId();
  const cache = useQueryClient();

  const updateTeamMutation = useMutation(updateTeamById);

  const TeamRelatedResourcesQuery = useQuery([UseQueryTypes.TEAMS, teamId], () =>
    getTeamWithRelatedResources(teamId),
  );

  const initialValues = useMemo<Partial<ITeamPhone>>(() => {
    return {
      name: TeamRelatedResourcesQuery.data?.name || '',
      members:TeamRelatedResourcesQuery.data?.members,
      phoneNumbers: TeamRelatedResourcesQuery.data?.phoneNumbers
    };
  }, [TeamRelatedResourcesQuery.data]);

  const handleSubmit = async (updateTeam: ITeamPhone) => {
    const mutationFunc = () =>
      updateTeamMutation.mutateAsync({
        id: teamId,
        updatedTeam: {
          teamName: updateTeam.name,
          members: updateTeam.members || [],
          phoneNumbers: updateTeam.phoneNumbers || []
        }
      });

    await withCatch(mutationFunc, {
      onSuccess: () => {
        cache.invalidateQueries(UseQueryTypes.TEAMS);
        closeDrawer();
      },
    });
  };

  return (
    <Spin spinning={updateTeamMutation.isLoading}>
      <TransformTeamForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isEditForm
      />
    </Spin>
  );
};
