import { Col, Row, Typography } from 'antd';
import { UseQueryResult } from 'react-query';

import { IApplicantDetailApplication } from '../../../../../../types/applicantDetail.types';
import { ApplicationsTable } from '../../../applicantDetailTables';

const { Title } = Typography;

export const Applications = ({ applications }: { applications: UseQueryResult<IApplicantDetailApplication[]> }) => {
  return (
    <Row gutter={16} style={{ height: '100%', marginTop: '20px' }}>
      <Col span={24}>
        <Title style={{ marginBottom: 20 }} level={4}>
          Applications
        </Title>
        <Row style={{ marginBottom: 20 }}>
          <Col span={20}>
            <ApplicationsTable applications={applications.data || []} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
