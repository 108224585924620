import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDrawer } from '../../../../contexts/drawer.context';
import { useAuth } from '../../../../contexts/useAuth.context';
import { withCatch } from '../../../../helpers/error.helpers';
import { useEntityId } from '../../../../hooks/useFilter.hooks';
import { getAttachmentById } from '../../../../services/attachment.service';
import { updateStudentAttachment } from '../../../../services/student.service';
import { AttachmentTypeEnum } from '../../../../types/attachment.types';
import { PdfItemViewer } from '../../components/Agreements/AgreementsRenderer/AgreementsRenderer';
import { StudentQueryConstants } from '../../constants/studentQuery.constants';
import { CreateStudentAttachmentForm } from '../StudentAttachmentForm/CreateStudentAttachmentForm';

export const EditStudentCVForm = () => {
  const { closeDrawer } = useDrawer();
  const { user } = useAuth();
  const cache = useQueryClient();
  const { id } = useEntityId();

  const studentAttachment = useMutation(updateStudentAttachment, {
    onSuccess: () => {
      cache.invalidateQueries(StudentQueryConstants.STUDENT_CV).then(() => window.location.reload());
    },
  });

  const studentCV = useQuery(
    [StudentQueryConstants.STUDENT_ATTACHMENTS],
    () =>
      getAttachmentById(id as number,),
    {
      enabled: Boolean(user),
    }
  );

  const handleUpdateCV = async (
    updatedAttachment: { name: string, attachment: string },
  ) => {
    if (user?.id) {
      const updateParams = {
        attachmentId: id,
        ...updatedAttachment,
        attachmentType: AttachmentTypeEnum.CV,
      }

      const mutationFunc = () =>
        studentAttachment.mutateAsync(updateParams);

      await withCatch(mutationFunc, {
        onSuccess: () => {
          closeDrawer();
        },
      });
    }
  };

  return (
    <CreateStudentAttachmentForm
      attachmentTitle='File Name'
      attachmentFileTitle='File Upload'
      isLoading={studentAttachment.isLoading}
      attachmentType={AttachmentTypeEnum.CV}
      handleSubmit={handleUpdateCV}
    >
      <>{studentCV?.data?.link && <PdfItemViewer link={studentCV.data.link} previewBtnTitle='See the CV' />}</>
    </CreateStudentAttachmentForm>
  )
}
