import { Image, Modal, Spin, Typography } from 'antd';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';

import { Flex } from '../../../../components/general';
import PdfPreview from "../../../shared/components/PdfPreview/PdfPreview";

const { Link } = Typography;

export const SourcePreview = ({ sourceLink, name }: { sourceLink: string | null; name: string }): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const extensionOfFile = sourceLink?.split('.').pop();

  return (
    <>
      <Flex alignItems="center">
        {sourceLink ? (
          <Link onClick={showModal} style={{ cursor: 'pointer' }}>
            {name}
          </Link>
        ) : (
          '-'
        )}
      </Flex>
      <Modal visible={isModalOpen} onCancel={closeModal} width={800} footer={[]}>
        <Flex alignItems="center" justifyContent="center" style={{ marginTop: 30 }}>
          {extensionOfFile === 'pdf' ? (
            <PdfPreview link={sourceLink} allowDownload={true}></PdfPreview>
          ) : (
            <Image preview={false} width={400} src={sourceLink || ''} />
          )}
        </Flex>
      </Modal>
    </>
  );
};
