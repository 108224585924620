import { ICreateTeam, ITeam, ITeamRelatedResources } from "../types/team.types";
import { ICreateTeamPhone } from "../types/teamPhone.types";
import axios from "./config.service";

export const getTeams = () => {
  return axios.get('/teams/').then((r) => r.data) as Promise<ITeam[]>;
}

export const getTeamWithRelatedResources = (id: number) => {
  return axios.get(`/teams/${id}`).then((r) => r.data) as Promise<ITeamRelatedResources>;
}

export const getTeamsWithRelatedResources = () => {
  return axios.get('/teams/info').then((r) => r.data) as Promise<ITeamRelatedResources[]>;
}

export const createTeam = async (team: ICreateTeam ) => {
  const r = await axios.post('/teams', team);
  return r.data as Promise<void>
}

export const updateTeamById = async ({ id, updatedTeam }: { id: number, updatedTeam: ICreateTeamPhone }) => {
  const r = await axios.put(`/teams/${id}`, updatedTeam);
  return r.data
}

export const deleteTeamById = (id: number) => {
  return axios.delete(`/teams/${id}`).then((r) => r.data) as Promise<void>;
}

export const getTeamsByUserId = (id: number) => {
  return axios.get(`/teams/user/${id}`).then((r) => r.data) as Promise<ITeam[]>;
}
