export { AddApplicantFeedbackForm } from '../modules/shared/forms/aplicantForms/AddApplicantFeedback.form';
export { CreateStudentOtherAttachmentForm } from '../modules/student/forms/StudentAttachmentForm/CreateStudentAttachmentForm';
export { CreateStudentCVForm } from '../modules/student/forms/StudentCVForm/CreateStudentCVForm';
export { EditStudentCVForm } from '../modules/student/forms/StudentCVForm/EditStudentCVForm';
export { CreateStudentPaymentForm } from '../modules/student/forms/StudentPaymentForm/CreateStudentPaymentForm';
export { AddApplicantCommentForm } from './applicantForms/addApplicantComment.form';
export { BulkCreateStudentsForm } from './applicantForms/bulkCreateStudents.form';
export { BulkUpdateApplicantForm } from './applicantForms/bulkUpdateApplicant.form';
export { CreateApplicantForm } from './applicantForms/createApplicant.form';
export { EditApplicantForm } from './applicantForms/editApplicant.form';
export { EditApplicantFormInTerminatedGroup } from './applicantForms/EditApplicantFormInTerminatedGroup.form';
export { ExportInvoiceForm } from './applicantForms/exportInvoice.form';
export { FilterApplicantForm } from './applicantForms/filterApplicant.form';
export { CreateOtherAttachmentForm } from './attachmentForms/addAttachment.form';
export { CertificateAutomationForm } from "./certificateForms/cerificate.automation.form";
export { AddConsultantCommentForm } from './consultantForms/addConsultantComment.form';
export { CreateConsultantForm } from './consultantForms/createConsultant.form';
export { EditConsultantForm } from './consultantForms/editConsultant.form';
export { FilterConsultantForm } from './consultantForms/filterConsultant.form';
export { CreateCourseForm } from './courseForms/createCourse.form';
export { EditCourseForm } from './courseForms/editCourse.form';
export { FilterCourseForm } from './courseForms/filterCourse.form';
export { CreateExamForm } from './examForms/createExam.form';
export { EditExamForm } from './examForms/editExam.form';
export { FilterGroupForm } from './groupForms/filterGroup.form';
export { CreateInterviewScheduleForm } from './groupForms/interviewSchedule.forms';
export { CreateEditGroupForm } from './groupForms/shared/createEditGroup.form';
export { AddInstructorCommentForm } from './instructorForms/addInstructorComment.form';
export { CreateInstructorForm } from './instructorForms/createInstructor.form';
export { EditInstructorForm } from './instructorForms/editInstructor.form';
export { FilterInstructorsForm } from './instructorsForms/filterInstructors.form';
export { FilterMailForm } from './mailingForms/filterMail.form';
export { BulkUpdatePaymentTypeForm } from './paymentForms/bulkUpdatePaymentType.form';
export { CreateApplicantPaymentForm } from './paymentForms/createApplicantPayment.form';
export { CreatePaymentForm } from './paymentForms/createPayment.form';
export { EditApplicantPaymentDateForm } from './paymentForms/editApplicantPaymentDate.form';
export { EditPaymentDateForm } from './paymentForms/editPaymentDate.form';
export { BulkUpdateReminderStatusForm } from './reminderForms/bulkUpdateReminderStatus';
export { CreateReminderForm } from './reminderForms/createReminder.forms';
export { EditReminderForm } from './reminderForms/editReminder.forms';
export { CreateStudentFeedbackForm } from './studentForms/CreateStudentFeedbackForm';
export { AddTaskCommentForm } from './taskForms/addTaskComment.form';
export { BulkUpdateTaskStatusForm } from './taskForms/bulkUpdateTask.form';
export { CreateTaskForm } from './taskForms/createTask.form';
export { EditTaskForm } from './taskForms/editTask.form';
export { FilterTaskForm } from './taskForms/filterTask.form';
export { CreateTeamForm } from './teamForms/createTeam.form';
export { EditTeamForm } from './teamForms/editTeam.form';
export { CreateTracksForm } from './trackForms/createTracks.form';
export { EditTrackForm } from './trackForms/editTrack.form';
export { CreateUserForm } from './userForms/createUser.form';
export { EditUserForm } from './userForms/editUser.form';
export { FilterUserForm } from './userForms/filterUser.form';


export enum AppFormsEnum {
  CreateStudentFeedbackForm = 'CreateStudentFeedbackForm',
  AddApplicantCommentForm = 'AddApplicantCommentForm',
  CreateApplicantForm = 'CreateApplicantForm',
  AddInstructorCommentForm = 'AddInstructorCommentForm',
  FilterApplicantForm = 'FilterApplicantForm',
  EditApplicantForm = 'EditApplicantForm',
  EditApplicantFormInTerminatedGroup = 'EditApplicantFormInTerminatedGroup',
  BulkUpdateApplicantForm = 'BulkUpdateApplicantForm',
  BulkUpdatePaymentTypeForm = 'BulkUpdatePaymentTypeForm',
  BulkCreateStudentsForm = 'BulkCreateStudentsForm',
  CreateCourseForm = 'CreateCourseForm',
  EditCourseForm = 'EditCourseForm',
  FilterCourseForm = 'FilterCourseForm',
  EditReminderForm = 'EditReminderForm',
  CreateExamForm = 'CreateExamForm',
  FilterGroupForm = 'FilterGroupForm',
  CreateInstructorForm = 'CreateInstructorForm',
  EditInstructorForm = 'EditInstructorForm',
  FilterInstructorsForm = 'FilterInstructorsForm',
  CreateTaskForm = 'CreateTaskForm',
  EditTaskForm = 'EditTaskForm',
  FilterTaskForm = 'FilterTaskForm',
  CreateReminderForm = 'CreateReminderForm',
  CreateTracksForm = 'CreateTracksForm',
  EditTrackForm = 'EditTrackForm',
  CreatePaymentForm = 'CreatePaymentForm',
  EditConsultantForm = 'EditConsultantForm',
  CreateConsultantForm = 'CreateConsultantForm',
  FilterConsultantForm = 'FilterConsultantForm',
  AddConsultantCommentForm = 'AddConsultantCommentForm',
  CreateUserForm = 'CreateUserForm',
  EditUserForm = 'EditUserForm',
  FilterUserForm = 'FilterUserForm',
  EditExamForm = 'EditExamForm',
  AddApplicantFeedbackForm = 'AddApplicantFeedbackForm',
  CreateApplicantPaymentForm = 'CreateApplicantPaymentForm',
  FilterMailForm = 'FilterMailForm',
  EditApplicantPaymentDateForm = 'EditApplicantPaymentDateForm',
  CreateStudentPaymentForm = 'CreateStudentPaymentForm',
  CreateOtherAttachmentForm = 'CreateOtherAttachmentForm',
  CreateStudentOtherAttachmentForm = 'CreateStudentOtherAttachmentForm',
  EditPaymentDateForm = 'EditPaymentDateForm',
  BulkUpdateReminderStatusForm = 'BulkUpdateReminderStatusForm',
  BulkUpdateTaskStatusForm = 'BulkUpdateTaskStatusForm',
  CreateTeamForm = 'CreateTeamForm',
  EditTeamForm = 'EditTeamForm',
  AddTaskCommentForm = 'AddTaskCommentForm',
  ExportInvoiceForm = 'ExportInvoiceForm',
  CreateInterviewScheduleForm = 'CreateInterviewScheduleForm',
  CreateStudentCVForm = 'CreateStudentCVForm',
  EditStudentCVForm = 'EditStudentCVForm',
  CertificateAutomationForm = 'CertificateAutomationForm'
}
