import { useQuery } from "react-query";

import { useAuth } from "../../../../contexts/useAuth.context";
import { getStudentReceivedFeedbacks } from "../../../../services/student.service";
import FeedbacksRenderer from "../../components/Feedbacks/FeedbacksRenderer";
import { StudentQueryConstants } from "../../constants/studentQuery.constants";

const ReceivedFeedbacks = () => {
  const { user } = useAuth()

  const studentFeedbacksQuery = useQuery(
    [StudentQueryConstants.STUDENT_RECEIVED_FEEDBACKS],
    () =>
    getStudentReceivedFeedbacks(user?.id as number),
    { enabled: Boolean(user) }
  );
  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <FeedbacksRenderer feedbacksData={studentFeedbacksQuery} />
    </div>
  );
};

export default ReceivedFeedbacks;
