import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Button } from '../../../../../../components/general';
import { COMMA_SEPARATOR } from '../../../../../../constants/constants';
import { RoutesConstants } from '../../../../../../helpers/routes.helpers';
import { useRowSelectionAdapter } from '../../../../../../hooks/useRowSelection.adapter';
import { PhoneService } from '../../../../../../services/phone.service';
import { useAppDispatch } from '../../../../../../store';
import {
  lastEntityActions, selectActionByName,
} from '../../../../../../store/features/LastEntityAction/LastEntityActionSlice';
import { mailingActions } from '../../../../../../store/features/mailing/mailingSlice';
import { smsActions } from '../../../../../../store/features/sms/smsSlice';
import { IStudentBrief } from '../../../../../../types/applicant.types';
import { SendMail } from '../../../../../../types/mail.types';
import { SendSms } from '../../../../../../types/sms.types';
import { GROUP_DETAIL } from '../../GroupDetail';

export const MassGroupActions = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const groupSavedAction = selectActionByName(GROUP_DETAIL);
  const [isClearAction, setIsClearAction] = useState(true);
  const rowSelectionAdapter = useRowSelectionAdapter<IStudentBrief>();

  const handleMassMailAllGroupStudents = () => {
    const source = rowSelectionAdapter.selectedRows;

    const mailingFormState: Partial<SendMail> = {
      // @ts-ignore
      to: source.map((applicant) => applicant.email).join(COMMA_SEPARATOR),
    };

    dispatch(mailingActions.setInitialValues(mailingFormState));
    dispatch(lastEntityActions.setAction({
      name: GROUP_DETAIL,
      data: {
        id,
        // @ts-ignore
        data: source.map(({ id }) => id)
      }
    }));
    setIsClearAction(false);
    navigate(RoutesConstants.massMailing())
  };

  const handleMassSmsAllGroupStudents = () => {
    const source = rowSelectionAdapter.selectedRows;

    const smsFormState: Partial<SendSms> = {
      // @ts-ignore
      to: source.map((applicant) => PhoneService.normalizeForNikita(applicant.phoneNumber)).join(COMMA_SEPARATOR),
    };

    dispatch(smsActions.setInitialValues(smsFormState));
    dispatch(lastEntityActions.setAction({
      name: GROUP_DETAIL,
      data: {
        id,
        // @ts-ignore
        data: source.map(({ id }) => id)
      }
    }))

    setIsClearAction(false);
    navigate(RoutesConstants.massSms());
  };

  const cleanUp = () => {
    if(groupSavedAction?.data && isClearAction) {
      dispatch(lastEntityActions.removeAction({ name: GROUP_DETAIL }));
      rowSelectionAdapter.clean();
    }
  };

  const init = () => cleanUp

  useEffect(init, []);

  return (
    <>
      <Col>
        <Button variant="primary" onClick={handleMassMailAllGroupStudents}>
          Mass-mail group applicants
        </Button>
      </Col>
      <Col>
        <Button variant="primary" onClick={handleMassSmsAllGroupStudents}>
          Mass-sms group applicants
        </Button>
      </Col>
    </>
  )
}
